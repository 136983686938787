import moment from 'moment-timezone';

import {
  integerVal,
  invalidDateMessage,
  phoneVal,
  usaDateFormat,
} from '../../../../utils/constants';
import { emailValidation } from '../../../../utils/helpers';

export const validate = (values = {} as any) => {
  const errors = {} as any;

  const optional = [
    'scopeOfWork',
    'projectEstimatedBudget',
    'bidBond',
    'displayBudgetToSuppliers',
    'openedText',
    'pbcMemo',
    'introductorytext',
    'costinformation',
    'duedatetimetext',
    'additionaltext',
    'closingtext',
    'viewsupplierinfortabulation',
    'preBidMandatory',
    'supplierName',
    'contactName',
    'phone',
    'state',
    'city',
    'legalAdIntro',
    'legalAdCostInfo',
    'legalAdDueDateTime',
    'legalAdAdditional',
    'legalAdClosing',
    'plannotes',
    'planemail',
    'bidAmtRequired',
    'supplementalDocRequired',
  ] as any;
  const numbers = ['memberId'];
  const checknumber =
    Object.keys(values)
      .filter(items => numbers.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const errorarr =
    required.filter(items => {
      const returnValue = items.value;
      return typeof returnValue === 'string' ? !returnValue.trim() : !returnValue;
    }) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));
  const errornumarr =
    checknumber.filter(items => items.value && !integerVal.test(items.value)) || [];
  errornumarr.forEach(items => (errors[items.name] = 'Invalid'));

  if (values?.bidName?.length > 255) {
    errors.bidName = 'Maximum length exceeded';
  }

  if (values.phoneNumber && !phoneVal.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid';
  }
  if (values.phone && !phoneVal.test(values.phone)) {
    errors.phone = 'Invalid';
  }
  if (emailValidation(values.email)) {
    errors.email = 'Invalid';
  }

  if (
    values.projectEstimatedBudget &&
    !/^([0-9]\d*)$/.test(values.projectEstimatedBudget) &&
    !/^[-+]?[0-9]+\.[0-9]+$/.test(values.projectEstimatedBudget)
  ) {
    const ItemPrice = values.projectEstimatedBudget
      .toString()
      .replace(/ /g, '')
      .replace('$', '')
      .replace(/,/g, '');
    if (!/^(0|[0-9]\d*)$/.test(ItemPrice) && !/^[-+]?[0-9]+\.[0-9]+$/.test(ItemPrice)) {
      errors.projectEstimatedBudget = 'Invalid';
    }
  }

  if (moment(values.dueDate).isSameOrBefore(values.broadcastDate)) {
    errors.dueDate = 'Due Date should be greater than Broadcast Date';
  }

  if (!values.eBidding) {
    errors.eBidding = 'REQUIRED you must select YES or NO to (eBID)';
  }
  if (values.broadcastDate && !moment(values.broadcastDate, usaDateFormat).isValid()) {
    errors.broadcastDate = invalidDateMessage;
  }
  if (values.dueDate && !moment(values.dueDate, usaDateFormat).isValid()) {
    errors.dueDate = invalidDateMessage;
  }
  return errors;
};
