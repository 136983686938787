import { atom } from 'recoil';

import { addInCompleteRegistrationData } from '../services';
import { initialRegistrationData } from '../reducers/registration';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from '../../types/supplierregistration';

export type RegistrationComponent = keyof typeof registrationComponent & string;
export type RegistrationError = {
  accountExists?: boolean;
  companyExists?: boolean;
  IsBlackList?: boolean;
  planholderEmail?: string;
  planholderExist?: boolean;
};

export const registrationErrorState = atom<RegistrationError>({
  key: 'registrationErrorState',
  default: {},
});

export const currentRegistrationComponentState = atom<RegistrationComponent>({
  key: 'currentRegistrationComponentState',
  default: registrationComponent.CreateAccount,
});

export const incompleteRegistrationState = atom<any>({
  key: 'incompleteRegistrationState',
  default: initialRegistrationData,
});

/**
 * Initialize recoilRegistrationData with value from reducer
 */
export const recoilRegistrationDataState = atom<RegistrationData | any>({
  key: 'recoilRegistrationDataState',
  default: initialRegistrationData,
});

/**
 * registrationInfo is an object that changes shape as the registration process continues.
 * it will be moved into Recoil when we combine subscription renewals/registration
 * For this reason, we decided to keep the `:any` though this should be updated when a strict type is determined
 */
export async function updateIncompleteRegistration(registrationInfo: any) {
  if (registrationInfo.email) {
    try {
      // API GET
      const response = (await addInCompleteRegistrationData(registrationInfo)) || { data: {} };

      // Update data
      response.data = registrationInfo;

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error in 'addInCompleteRegistrationData() for updateIncompleteRegistration': ${error.message}`,
      );
    }
  }
}
