import moment, { Moment } from 'moment-timezone';

import * as actionTypes from '../actionTypes';
import {
  agencyEbidDocumentParamType,
  imageTypeItemParam,
  pageSettingFieldsType,
  publicationItemType,
  existingAgencyBuyerType,
  generalInformationType,
} from '../../types/agencyregistration';
import { countrieslist, Country, legalAdDefault } from '../../utils/constants';
import { WizardPage, WizardStatus } from '../../types/wizard';

import { GeoState } from '../../types/sharedtypedef';
import { payloadTypes } from '../../types/actiontypedef';
import { Selectable } from '../../types/common';

export interface AgencyRegistrationState {
  addDocumentModal?: boolean;
  agBidTypes: { bidType: string }[];
  agencybidTypes?: [];
  agencyEbidDocuments: agencyEbidDocumentParamType[];
  agencyProducts?: { productName: string }[];
  agencypublications: publicationItemType[];
  agencyRegistrationWizard: WizardPage[];
  bidType?: string;
  description?: string;
  ebiddocumentPopupdata?: agencyEbidDocumentParamType;
  ebiddocumentreorder?: boolean;
  editEnabled?: boolean;
  escapedagencyname?: string;
  filetypes: Array<string>;
  guid?: string;
  imageTypes: imageTypeItemParam[];
  legalAds: unknown;
  pagehasValues?: boolean;
  pageSettingFields: pageSettingFieldsType | any;
  publicationId?: number;
  publicationName?: string;
  selectedIndex?: string | number;
  state?: string;
  validateSize: any;
  wizardcurrentpage: number;
  generalInfoFormInitialValues: generalInformationType;
  matchedAgencies?: existingAgencyBuyerType[];
}

const generalInfoFormInitialValues = {
  address1: '',
  address2: '',
  agencyname: '',
  buyerPhone: '',
  city: '',
  contactEmail: '',
  contactPhone: '',
  country: countrieslist.filter(item => item.value === 1)[0],
  county: '',
  firstname: '',
  initialDate: moment(),
  lastname: '',
  loginusername: '',
  memberStatus: 'AC',
  metro: '',
  planholderDisplayType: '',
  state: '',
  timeZone: '',
  website: '',
  zipcode: '',
};

const initialLegalAd = {
  ...legalAdDefault,
};

export const initialStateAgencyRegistration = {
  agBidTypes: [],
  agencyEbidDocuments: [],
  agencypublications: [],
  agencyRegistrationWizard: [
    { status: WizardStatus.Current, id: 1, name: 'General Information' },
    { status: WizardStatus.Unavailable, id: 2, name: 'Publications' },
    { status: WizardStatus.Unavailable, id: 3, name: 'Bid Types' },
    { status: WizardStatus.Unavailable, id: 4, name: 'eBidding Documents' },
    { status: WizardStatus.Unavailable, id: 5, name: 'Legal Ads' },
    { status: WizardStatus.Unavailable, id: 6, name: 'Agency Page Settings' },
  ],
  wizardcurrentpage: 1,
  legalAds: initialLegalAd,
  generalInfoFormInitialValues,
  filetypes: ['.jpg, .png, .jpeg'],
  pageSettingFields: {
    logo: {},
    banner: {},
    theme: { key: 'Gray', label: 'Gray', value: '#bcbcbc' },
    imgPos: { label: 'Center', value: 'center', type: 'position' },
  },
  imageTypes: [
    { label: 'Logo', name: 'logo', error: '' },
    { label: 'Banner', name: 'banner', error: '' },
  ],
  validateSize: {
    logo: { width: 200, size: 500 },
    banner: { width: 1200, height: 400, size: 2048 },
  },
  matchedAgencies: [],
} as any; // TODO: Get this sorted out.

export const reducer = (
  state = initialStateAgencyRegistration,
  { type, payload }: payloadTypes,
) => {
  switch (type) {
    case actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.RESET_AGENCY_DETAILS.TRIGGER:
      state = {
        ...state,
        generalInfoFormInitialValues: generalInfoFormInitialValues,
        agBidTypes: [],
        agencyEbidDocuments: [],
        agencypublications: [],
        legalAds: initialLegalAd,
        wizardcurrentpage: 1,
      };
      break;
    case actionTypes.GET_MATCHED_AGENCIES.SUCCESS:
      state = { ...state, existingAgenyBuyer: { memberId: 2527048, isActive: true } };
      break;
    case actionTypes.UPDATE_AND_GET_BUYER.SUCCESS:
      state = { ...state, ...payload };
      break;
    default:
      break;
  }
  return state;
};
