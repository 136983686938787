import Select, { components } from 'react-select';

import { Colors } from '../../../../shared/styles';

interface SelectInputTypes {
  parentClass: string;
  label: string;
  options: Record<string, unknown>[];
  optional: boolean;
  error: boolean;
  form: any;
  field: any;
  onChange: any;
  onBlur: any;
  disabled: boolean;
  placeholder: string;
  isSearchable: boolean;
  isClearable: boolean;
  dataTestId?: string;
}
/** @deprecated use DSSelect or DSSelectField */
function Selectbox(props: SelectInputTypes) {
  const {
    parentClass,
    label,
    options = [],
    optional = false,
    form = {},
    field = {},
    onChange,
    onBlur,
    disabled = false,
    placeholder,
    isSearchable = true,
    isClearable = false,
    dataTestId,
  } = props;

  const { errors = false, touched = false } = form;
  const { name = '' } = field;
  const testId = dataTestId || name;

  const { Option, SingleValue } = components;

  const IconOption = (props: any) => (
    <Option
      {...props}
      className={
        ![true, false].includes(props.data.ac) || props.data.ac === true ? '' : 'text-red200'
      }
    >
      {(props.data && props.data.value) ===
      (props.selectProps && props.selectProps.value && props.selectProps.value.value) ? (
        <span aria-hidden='true' className='mdi mdi-check-bold'></span>
      ) : (
        <span aria-hidden='true' className='ml-4'></span>
      )}
      {' ' + props.data.label}
    </Option>
  );

  const ValueOption = (props: any) => (
    <SingleValue {...props}>
      {/*{props.data.value && <span aria-hidden='true' className='mdi mdi-check-bold'></span>}*/}
      {' ' + props.data.label}
    </SingleValue>
  );

  const style1 = {
    control: (base: any, state: any) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
    }),
  };

  const reactSelected = {
    option: (styles: any, state: { isSelected: boolean; data: { ac: boolean } }, name: string) => {
      return {
        ...styles,
        backgroundColor: state.isSelected ? 'rgba(51, 149, 164, 0.2)' : null,
        color: Colors.blueGreenMedium,
        fontStyle:
          ![true, false].includes(state.data.ac) || state.data.ac === true ? 'normal' : 'italic',
      };
    },
    singleValue: (provided: any, state: { data: { ac: boolean } }, name: string) => {
      return {
        ...provided,
        color: Colors.blueGreenMedium,
        fontStyle:
          ![true, false].includes(state.data.ac) || state.data.ac === true ? 'normal' : 'italic',
      };
    },
  };

  const arrow = {
    dropdownIndicator: (base: any, state: { selectProps: { menuIsOpen: boolean } }) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };

  return (
    <>
      <div
        className={
          'react-select clearfix ' +
          (disabled ? ' disable ' : '') +
          parentClass +
          (errors[name] && touched[name] ? ' errorWrapper' : '')
        }
      >
        <div data-testid={testId}>
          <label>{label}</label>
          <Select
            options={options}
            name={name}
            {...field}
            className='custom-react-select'
            onChange={value => onChange(name, value)}
            onBlur={onBlur}
            components={{ Option: IconOption, SingleValue: ValueOption }}
            styles={{ ...style1, ...arrow, ...reactSelected }}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={disabled}
            placeholder={placeholder ? placeholder : 'Select...'}
          />
        </div>
        {optional ? <span>(optional)</span> : optional ? <span>({optional})</span> : ''}
        <div className='errorMsg'>
          {errors[name] && touched[name] && <span>{errors[name]}</span>}
        </div>
      </div>
    </>
  );
}

/** @deprecated use DSSelect or DSSelectField */
const renderSelectField = (fieldvalues: any) => <Selectbox {...fieldvalues} />;

/** @deprecated use DSSelect or DSSelectField */
export default renderSelectField;
