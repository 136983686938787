import { CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';

import { ActionColor, Colors } from '../../../shared/styles';
import { FormWrapper } from '../form';

export function renderProgressBar(progress: number, fillColor: string = Colors.blueGreenMedium) {
  return (
    <div style={{ width: '40px', margin: 'auto' }}>
      <CircularProgressbar
        styles={{
          text: {
            fill: ActionColor.primary,
            fontSize: '28px',
          },
          path: {
            stroke: `${fillColor}`,
          },
        }}
        value={progress}
        text={`${progress}%`}
      />
    </div>
  );
}

export const UploadFormWrapper = styled(FormWrapper)`
  padding: 0.75rem;
  position: relative;
`;

export const SubmitButtonWrapper = styled.div`
  padding-top: 1.5rem;
`;

// TODO - why can't SelectBox be styled with `styled-components`?
export const SelectBoxWrapper = styled.div`
  height: 3.5rem;
  margin-bottom: -1rem;
  margin-top: 1rem;
  width: 20rem;
`;
