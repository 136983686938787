import { connect, ConnectedProps } from 'react-redux';
import { memo, useContext, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import DashboardControl from '../../common/breadcrumb/common/dashboardcontrol';
import DashboardLeftPanel from '../../shared/dashboard/dashboardleftpanel';
import Rightside from './rightside';

import * as actionCreators from '../../../store/actions';

import { appstatetokenName } from '../../../utils/constants';
import { dismisstoastAllFn, toastFn } from '../../../utils/helpers';
import { getBidsTypes } from '../../../types/bids';
import { memberinfoTypes } from '../../../types/dashboard';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useGetDashboardData } from '../../../query/queries/getDashboardData';
import { Loader } from 'src/components/customcontrols';
import { useGetDashboardNetworkData } from 'src/query/queries/getDashboardNetworkData';
import { useGetDashboardTrackingData } from 'src/query/queries/getDashboardTrackingData';
import { setDashboardStaticticsData } from 'src/query/helper/setDashboardStaticticsData';
import { AppContext } from 'src/global-context/AppContext';

interface BuyerDashboardProps extends PropsFromRedux {
  getDashboardData: any;
  memberInfo: any;
  memberinfo: memberinfoTypes;
  getDashboardNetwork: any;
  history: any;
  setBidDetails: any;
  setQuotesDetails: any;
  getBids: getBidsTypes;
  getQuotesList: any;
}

function BuyerDashboard(props: BuyerDashboardProps) {
  const {
    memberInfo,
    memberinfo,
    history,
    setBidDetails,
    setQuotesDetails,
    getQuotesList,
    getBids,
  } = props;
  const { mn = '', mi = '' } = memberinfo;

  let statistics = {};
  let dashboardData = [];
  const { fetchDashboardData } = useContext(AppContext);

  useSetBreadcrumb({
    component: <DashboardControl />,
    page: 'Dashboard',
    altname: 'Dashboard',
    title: 'Dashboard',
    name: 'Dashboard',
    menuactive: 'dashboard',
  });

  const {
    isSuccess: dashboardSuccess,
    isError: dashboardDataError,
    isFetching: dashboardDataFetching,
    data: responseData,
    refetch: refetchDashboardData,
  } = useGetDashboardData({});

  const {
    isSuccess: dashboardTrackingSuccess,
    isError: dashboardTrackingError,
    data: responseTrackingData,
    refetch: refetchDashboardTracking,
  } = useGetDashboardTrackingData({});

  const {
    isSuccess: dashboardNetworkSuccess,
    isError: dashboardNetworkError,
    isFetching: dashboardNetworkFetching,
    data: responseNetworkData,
    refetch: refetchDashboardNetwork,
  } = useGetDashboardNetworkData({});

  useEffect(() => {
    if (fetchDashboardData) {
      refetchDashboardTracking();
      refetchDashboardData();
    }
  }, [refetchDashboardTracking, refetchDashboardData, fetchDashboardData]);

  if (dashboardSuccess && dashboardTrackingSuccess && dashboardNetworkSuccess) {
    dashboardData = responseData?.data?.result;
    const data = setDashboardStaticticsData(responseData, responseTrackingData);
    statistics = data?.statistics;
  } // if any api fails then this below toast appears.
  else if (dashboardDataError || dashboardTrackingError || dashboardNetworkError) {
    toastFn('error', 'Failed to retrieve dashboard data.Please try later. ', true);
  }

  useEffect(() => {
    const l_storage = localStorage.getItem(appstatetokenName) || '';
    if (l_storage) {
      const { auth: localauth } = (l_storage && JSON.parse(l_storage)) || {};
      if (localauth && localauth.opi) {
        if (mi && mi !== localauth.mi) {
          memberInfo({ isCurrentMember: true });
        }
      }
    }
  }, [memberInfo, mi, mn]);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  return (
    <div className='container dashboardWrapper'>
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
          <div className='sideBarSpacing dashboardSidebar'>
            <DashboardLeftPanel
              {...props}
              getDashboardBidsList={actionCreators.getDashboardBidsList}
              getDashboardQuotesList={actionCreators.getDashboardQuotesList}
              history={history}
              getBids={getBids}
              setBidDetails={setBidDetails}
              getQuotesList={getQuotesList}
              setQuotesDetails={setQuotesDetails}
              dashboardData={dashboardData}
              statistics={statistics}
            />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='colWrapper'>
            <h2 className='arrowWrapper'>{mn}</h2>
            {!dashboardNetworkFetching && !dashboardDataFetching ? (
              <Rightside
                history={history}
                setBidDetails={setBidDetails}
                setQuotesDetails={setQuotesDetails}
                getBids={getBids}
                getQuotesList={getQuotesList}
                dashboardData={dashboardData}
                dashboardnetworkData={responseNetworkData?.data?.result}
              />
            ) : (
              <Loader loading={dashboardDataFetching} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const connector = connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(BuyerDashboard));
