import { useQuery } from '@tanstack/react-query';
import { toastFn } from 'src/utils/helpers';
import Configurations from '../../../settings';

const getBidTagsData = async (query: string) => {
  try {
    const response = await fetch(
      `${Configurations.COGNITIVE_SEARCH_BID_TAGS_ENDPOINT}/indexes/${Configurations.COGNITIVE_SEARCH_BID_TAGS_INDEX}/docs?api-version=${Configurations.COGNITIVE_SEARCH_BID_TAGS_API_VERSION}=${encodeURIComponent(query)}`,
      {
        headers: {
          'api-key': Configurations.COGNITIVE_SEARCH_BID_TAGS_API_KEY,
        },
      },
    );

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (error) {
    toastFn('error', 'Failed to fetch bid tags data. Please try again later.');
    throw error;
  }
};

export const useGetBidTagsCognitiveSearch = (query: string,options={}) => {
  return useQuery({
    queryKey: ['-COGNITIVE SEARCH-BidTags', query],
    queryFn: async () => {
      if (query === '') {
        return { data: [] };
      } else {
        const response = await getBidTagsData(query);
        return response;
      }
    },
    ...options,
  });
};
