import 'core-js';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import history from './utils/history';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { GlobalStyles } from './shared/styles/GlobalStyles';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
  <Router history={history}>
    <GlobalStyles />
    <App />
  </Router>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
