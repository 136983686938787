import { H4 } from '@demandstar/components/styles';

import { BidDetailsLink } from '../../../common/controls/text/links/BidDetailsLink';
import { LoadableWrapper } from '../../../common/loading/LoadableWrapper';
import { NotificationRow } from './NotificationRow';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { ReviewDocsText } from './../notify-pages/notify-awardees/ReviewDocsText';
import { Section } from './../styled/AwardBidStyled';
import { useBid } from 'src/shared/hooks/useBid';

export const AwardNotificationBidDetails = ({ requiresDocs }: { requiresDocs?: boolean }) => {
  const { bid, bidLoadable } = useBid();

  return (
    <Section>
      <H4>{notifyAwardeeTexts.headers.bidDetails}</H4>
      <LoadableWrapper loadable={bidLoadable}>
        {bid && (
          <div>
            <NotificationRow label={notifyAwardeeTexts.labels.agency}>
              <span>{bid.agencyName}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidWriter}>
              <span>{bid.bidWriter}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidNumber}>
              <span>{bid.bidNumber}</span>
            </NotificationRow>
            <NotificationRow label={notifyAwardeeTexts.labels.bidName}>
              <BidDetailsLink bidId={bid.bidId} newTab>
                {bid.bidName}
              </BidDetailsLink>
            </NotificationRow>
            {requiresDocs && (
              <NotificationRow label={notifyAwardeeTexts.labels.reviewDocs}>
                <ReviewDocsText />
              </NotificationRow>
            )}
          </div>
        )}
      </LoadableWrapper>
    </Section>
  );
};
