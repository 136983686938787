import { memo, useEffect, useState } from 'react';

import { Buttons, DeprecatedInput } from '../../../../customcontrols';
import { commodityselectedTypes, generatenewTypes } from '../../../../../types/broadcastlist';
import { IsIEFn, Searchingfn } from '../../../../../utils/helpers';

import CommoditySearch from '../../../../common/commoditycodeedit/commoditysearch';
import Commodityselected from '../commoditycodes/commoditycodeedit/commodityselected';
import ConfirmCommodity from './confirmcommodity';
import Filtersuppliearlist from '../filtersuppliarlist';

import SupplierList from '../supplierlist';

import { Commoditylist } from '../commoditycodes/commoditycodeedit/commoditylist';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { pageSize } from '../../../../../utils/constants';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useForceUpdate } from '../../../../../utils/helperHooks';

interface propsTypes extends commodityselectedTypes {
  GoBackToPage: any;
  SetSelectedCommodity: any;
  getBroadcastListSearchVendors: any;
}

type combinedProps = generatenewTypes & propsTypes;

function GenerateNewList(props: combinedProps) {
  const forceUpdate = useForceUpdate();
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const {
    addbid,
    broadcastList,
    commoditycodes,
    getAddBidActivePrograms,
    getCommodityCodes,
    ListComponent,
    listpagefor = '',
    pagefor = '',
    setBroadcastListDetails,
    setCommodityCodes,
    Setnewlistmodal = () => {},
    setSelectedCommodityCodes,
    submitBroadcastListAddList,
  } = props;
  const { auth } = useAuthContext();
  const { activeprograms = [], activeprogramsapi = true } = addbid;
  const { broadcastListssearchShow, broadcastListssearch, newlistmodalfor, confirmChanges } =
    broadcastList;
  const { commoditieslist = [] } = commoditycodes || {};
  const type = pagefor;

  const [currentPagesSupplier, setCurrentPagesSupplier] = useState(1);
  const listPerPagesSupplier = pageSize.default;
  const indexOfLastListsSupplier = currentPagesSupplier * listPerPagesSupplier;
  const indexOfFirstListsSupplier = indexOfLastListsSupplier - listPerPagesSupplier;

  const [localCommodity, setLocalCommodity] = useState({
    value: '',
    category: '',
    name: '',
    confirmmodal: false,
  });

  useEffect(() => {
    if (activeprograms.length === 0 && activeprogramsapi && auth?.memberId)
      getAddBidActivePrograms({ isCurrentMember: true });
  }, [auth, activeprograms, activeprogramsapi, getAddBidActivePrograms]);

  const searchCommodityCodes = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (searchText.length >= 3) {
      setShowSearch(true);
      const searchresult = Searchingfn(
        commoditieslist,
        ['commodityDescription', 'formattedCode'],
        searchText,
      );
      setCommodityCodes({ searchedCommodityCodes: searchresult });
    }
    if (!searchText || searchText.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const onChnageSearchCommodityCodes = (value: string) => {
    setSearchText(value);
    if (value.length >= 3) {
      setShowSearch(true);
      setCommodityCodes({
        searchedCommodityCodes: Searchingfn(
          commoditieslist,
          ['commodityDescription', 'formattedCode'],
          value,
        ),
      });
    }
    if (!value || value.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const SetSelectedCommodity = (value: any, items: any, type: string) => {
    setSelectedCommodityCodes({ commoditieslist, value, items, type });
    forceUpdate();
  };

  const CancelConfirm = () => {
    setLocalCommodity({
      value: '',
      category: '',
      name: '',
      confirmmodal: false,
    });
  };

  useEffect(() => {
    let getCommodityCodesAPICALL = true;
    if (getCommodityCodesAPICALL) {
      getCommodityCodes({ auth, pagefor: newlistmodalfor === 'edit' ? 'broadcastlist' : '' });
    }
    return () => {
      getCommodityCodesAPICALL = false;
    };
  }, [auth, getCommodityCodes, newlistmodalfor]);

  useEffect(() => {
    return () => {
      setBroadcastListDetails({
        savebroadcastlist: '',
        broadcastListssearchShow: false,
        broadcastListssearch: [],
        confirmChanges: false,
        broadcastFilter: {
          filterState: [],
          filterCity: [],
          filterDeclaration: [],
          filterPrograms: [],
        },
      });
      setCommodityCodes({
        searchedCommodityCodes: [],
        searchText: '',
        commoditieslist: [],
      });
    };
  }, [setBroadcastListDetails, setCommodityCodes]);

  return (
    <>
      <div className='container m-auto'>
        {broadcastListssearchShow ? (
          <>
            {listpagefor === 'accountSaveBroadcastList' ? (
              <SupplierList
                {...props}
                internallisting={broadcastListssearch}
                currentPagesSupplier={currentPagesSupplier}
                setCurrentPagesSupplier={setCurrentPagesSupplier}
                indexOfLastListsSupplier={indexOfLastListsSupplier}
                indexOfFirstListsSupplier={indexOfFirstListsSupplier}
                listPerPagesSupplier={listPerPagesSupplier}
              />
            ) : (
              ListComponent(broadcastListssearch)
            )}
            <div className='d-flex justify-content-between'>
              <Buttons
                classNames='bttn-secondary mt-5 float-left'
                text='Go Back'
                title='Go Back'
                onClick={() => {
                  setCurrentPagesSupplier(1);
                  setBroadcastListDetails({
                    broadcastListssearchShow: false,
                    broadcastListssearch: [],
                  });
                }}
                type='button'
              />
              <Buttons
                classNames='bttn-primary mt-5 float-right'
                text={newlistmodalfor === 'edit' ? 'Update list' : 'Save List'}
                title={newlistmodalfor === 'edit' ? 'Update list' : 'Save List'}
                onClick={() => {
                  if (newlistmodalfor === 'edit') {
                    setBroadcastListDetails({ confirmChanges: true, actionType: 'save' });
                  } else {
                    setBroadcastListDetails({ savelistmodal: true });
                  }
                }}
                type='button'
              />{' '}
            </div>
          </>
        ) : (
          <div className='row commoditylist generateNewList'>
            <div className='col-lg-3'>
              <div className='sideBarSpacing'>
                <Filtersuppliearlist {...props} pagefor='BroadcastList' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='colWrapper'>
                <div className='innerColIndent'>
                  {IsIEFn() ? (
                    <form>
                      <DeprecatedInput
                        type='text'
                        placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                        label='Search'
                        value={searchText}
                        handleChange={(name: string, value: string) => setSearchText(value)}
                        optional={'e.g "Administrative" or "001-000-00"'}
                        parentClass='mb-0'
                      />
                      <div className='d-flex justify-content-end mb-3'>
                        <Buttons
                          classNames='bttn-secondary mt-2 small'
                          text='Clear'
                          title='Clear'
                          type='button'
                          onClick={() => {
                            setShowSearch(false);
                            setSearchText('');
                            setCommodityCodes({ searchedCommodityCodes: [] });
                          }}
                        />
                        <Buttons
                          classNames='bttn-primary mt-2 ml-2 small'
                          text='Search'
                          title='Search'
                          type='submit'
                          onClick={searchCommodityCodes}
                        />
                      </div>
                    </form>
                  ) : (
                    <DeprecatedInput
                      type='search'
                      placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                      label='Search'
                      value={searchText}
                      handleChange={(name: string, value: string) =>
                        onChnageSearchCommodityCodes(value)
                      }
                      optional={'e.g "Administrative" or "001-000-00"'}
                      parentClass='mb-0'
                    />
                  )}
                </div>
                {showSearch ? (
                  <CommoditySearch
                    {...props}
                    SetSelectedCommodity={SetSelectedCommodity}
                    setLocalCommodity={setLocalCommodity}
                  />
                ) : (
                  <Commoditylist
                    {...props}
                    SetSelectedCommodity={SetSelectedCommodity}
                    pagefor='accountBroadcastList'
                    setLocalCommodity={setLocalCommodity}
                  />
                )}
              </div>
            </div>
            <div className='col-lg-3'>
              <Commodityselected
                {...props}
                GoBackToPage={Setnewlistmodal}
                SetSelectedCommodity={SetSelectedCommodity}
              />
            </div>
          </div>
        )}
      </div>
      <ModalPopUp
        title='Confirm'
        closeModal={CancelConfirm}
        isOpen={localCommodity.confirmmodal}
        backdrop='static'
        size='md'
      >
        <ConfirmCommodity
          {...props}
          CancelConfirm={CancelConfirm}
          SetSelectedCommodity={SetSelectedCommodity}
          localCommodity={localCommodity}
          commoditieslist={commoditieslist}
        />
      </ModalPopUp>
      <ModalPopUp
        title='Confirm Changes'
        closeModal={() => setBroadcastListDetails({ confirmChanges: false })}
        isOpen={confirmChanges}
        size='md'
      >
        <p>
          This will overwrite your previous commodities/suppliers selections. Do you want to proceed
          now?
        </p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={() => setBroadcastListDetails({ confirmChanges: false })}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Confirm'
          title='Confirm'
          type='button'
          onClick={() => {
            submitBroadcastListAddList({ auth, replaceIfExists: true, type });
          }}
        />
      </ModalPopUp>
    </>
  );
}

export default memo(GenerateNewList);
