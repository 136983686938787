import { Field, Formik } from 'formik';
import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import ApplyProgram from '../applyprogram';
import ProgramAttributes from './programattributes';
import renderDateField from '../../../../common/controls/inputs/datepicker';
import renderSelect from '../../../../common/controls/inputs/selectbox';
import SortingIcon from '../../../../common/sortingIcon';

import { Buttons, DeprecatedInput, NoResult, Paging } from '../../../../customcontrols';
import {
  invalidDateMessage,
  pageSize,
  sortDirections,
  usaDateFormat,
} from '../../../../../utils/constants';
import { Searchingfn, toISOString } from '../../../../../utils/helpers';
import { DeprecatedFormikRadio } from '../../../../common/controls/inputs/DeprecatedFormikRadio';
import { ItemRequired } from '../../../../../types/publications';
import { loadingMsg } from '../../../../../utils/texts';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  width: '180px',
};

const pageLimit = pageSize.bidResults;
let programLoaded = false;

interface FilterProps {
  memberId: number;
  buyerName: string;
  statusChangedSince: string;
  expiresAfter: string;
  certificationStatus: string;
  attribMatch: string;
}

const validate = (values: any) => {
  const errors: any = [];
  const optional: any = [
    'statusChangedSince',
    'expiresAfter',
    'certificationStatus',
    'attribMatch',
    'attribMatch',
  ];
  const required: ItemRequired[] =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  const errorarr =
    required.filter(items => {
      const returnValue: boolean | string = items.value;
      return typeof returnValue === 'string' ? !returnValue.trim() : !returnValue;
    }) || [];

  errorarr.forEach(items => (errors[items.name] = 'Required'));
  if (values.statusChangedSince && !moment(values.statusChangedSince, usaDateFormat).isValid()) {
    errors.statusChangedSince = invalidDateMessage;
  }
  if (values.expiresAfter && !moment(values.expiresAfter, usaDateFormat).isValid()) {
    errors.expiresAfter = invalidDateMessage;
  }
  return errors;
};

function Programs(props: any) {
  const [dateshow, setDateshow] = useState(true);
  const {
    searchPrograms,
    memberinfo,
    setAccountInfoDetails,
    programsPageChange,
    reset,
    shared,
    initialValues,
  } = props;

  const { internalLoader, invaliddateerror } = shared;
  const [programClick, setprogramClick] = useState(false);
  const [applyClick, setapplyClick] = useState(false);
  const [programsearchlist, setProgramsearchResult] = useState([]);
  const [programsearchText, setProgramsearchText] = useState('');
  const [selectedProgram, setselectedProgram] = useState(
    {} as { programId: number; programName: string; programDescription: string },
  );
  const {
    certificationStatus,
    programs = [],
    currentPage,
    /* totalPrograms,*/ programAttributes = [],
  } = props.accountinfo;
  const { mi } = memberinfo;

  // Sort
  const [sortvalue, setSortvalue] = useState('programName' as string);
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  useSetBreadcrumb({
    component: '',
    page: 'Account Info',
    title: 'Certifications',
    altname: 'Certifications',
    name: 'Certifications',
    menuactive: '',
  });

  useEffect(() => {
    if (mi && !programLoaded) {
      const filters = {
        memberId: mi,
        buyerName: '',
        statusChangedSince: '',
        expiresAfter: '',
        certificationStatus: '',
        attribMatch: 'SD',
      };
      searchPrograms(filters);
      programLoaded = true;
    }
  }, [mi, searchPrograms]);

  const handleApplyClick = (e: any, program?: any) => {
    e.preventDefault();
    setapplyClick(!applyClick);
    setselectedProgram(program);
  };

  const handleProgramClick = (e: any, program?: any) => {
    const { getProgramAttributes } = props;
    e.preventDefault();
    setprogramClick(!programClick);
    if (program) setselectedProgram(program);
    program && getProgramAttributes(program.programId);
  };

  const ResetSortPaginationFn = () => {
    setSortvalue('programName');
    setSortorder(sortDirections.ASC);
    programsPageChange(1);
  };

  const handlesubmit = (values: any) => {
    const filters = {} as FilterProps;

    filters.memberId = mi;
    filters.buyerName = '';
    filters.statusChangedSince = values.statusChangedSince
      ? toISOString(values.statusChangedSince)
      : '';
    filters.expiresAfter = values.expiresAfter ? toISOString(values.expiresAfter) : '';
    filters.certificationStatus =
      (values.certificationStatus && values.certificationStatus.value === undefined) ||
      values.certificationStatus.value === 'Any'
        ? ''
        : values.certificationStatus.value;
    filters.attribMatch =
      values.attribMatch === undefined || (values.attribMatch && values.attribMatch === 'onlySD')
        ? 'SD'
        : '';
    searchPrograms(filters);
    ResetSortPaginationFn();
  };

  const handleClearFilter = () => {
    setDateshow(false);
    reset();
    ResetSortPaginationFn();
    setTimeout(() => {
      setDateshow(true);
    }, 10);
  };

  const onPagingClick = (pageNumber: number) => {
    programsPageChange(pageNumber);
  };

  const populateResult = (results: any, currentPage: any) => {
    if (results && results.length > 0) {
      const pagedResult = results.slice((currentPage - 1) * pageLimit, currentPage * pageLimit);
      return (
        <>
          <Table responsive className='tableHData'>
            <tr>
              <th style={tdWidth}>
                Certification Name{' '}
                <SortingIcon
                  sortlist={results}
                  initialsortorder={sortorder}
                  currentsortname={sortvalue}
                  changesortname={(value: string) => setSortvalue(value)}
                  sortname='programName'
                  changeSortingFn={(list: any) => setAccountInfoDetails({ results: list })}
                />
              </th>
              <th>
                Buyer Name{' '}
                <SortingIcon
                  sortlist={results}
                  initialsortorder={sortorder}
                  currentsortname={sortvalue}
                  changesortname={(value: string) => setSortvalue(value)}
                  sortname='shortName'
                  changeSortingFn={(list: any) => setAccountInfoDetails({ results: list })}
                />
              </th>
              <th>Status</th>
              <th>Last Changed</th>
              <th>Expired</th>
              <th />
            </tr>
            <tbody>
              {pagedResult.map((v: any, i: any) => (
                <tr key={i}>
                  <td>
                    <span
                      className='staticLink'
                      onClick={e => {
                        handleProgramClick(e, v);
                      }}
                    >
                      {v.programName}
                    </span>
                  </td>
                  <td>{v.shortName}</td>
                  <td>{v.statusName}</td>
                  <td>{v.statusChangedDate}</td>
                  <td>{v.expirationDate}</td>
                  <td>
                    {v.statusName ? (
                      ''
                    ) : (
                      <span className='staticLink' onClick={e => handleApplyClick(e, v)}>
                        Apply
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paging
            totalRecords={results.length}
            currentPage={currentPage}
            onPagingClick={onPagingClick}
            pageLimit={pageLimit}
          />
        </>
      );
    } else {
      return (
        <>
          <NoResult message={internalLoader ? loadingMsg : 'No Certifications Available'} />
        </>
      );
    }
  };

  const applyPopupInitials = { substantiationNotes: '' };
  const handleApplyProgram = (values: any) => {
    setapplyClick(!applyClick);
    const { mi: memberId } = props.memberinfo;
    const { addMemberProgramCertification } = props;

    values.certificationStatus = 'AP';
    values.memberId = memberId;
    values.programId = selectedProgram.programId;

    addMemberProgramCertification(values);
  };
  const applyProgramPopup = (
    <ApplyProgram
      program={selectedProgram}
      handleApplyProgram={handleApplyProgram}
      handleCancelClick={() => {
        setapplyClick(!applyClick);
      }}
      initialValues={applyPopupInitials}
    />
  );
  const programCertificationPopup = (
    <ProgramAttributes
      program={selectedProgram}
      programAttributes={programAttributes}
      handleCloseClick={() => {
        setprogramClick(!programClick);
      }}
    />
  );

  const ProgramSearchFn = (name: string, value: any) => {
    if (value) {
      const list = Searchingfn(
        programs,
        ['programName', 'shortName', 'statusName', 'statusChangedDate', 'expirationDate'],
        value,
      ) as any;
      setProgramsearchResult(list);
      setProgramsearchText(value);
    } else {
      setProgramsearchResult([]);
      setProgramsearchText('');
    }
    ResetSortPaginationFn();
  };

  const finalprogramlist = programsearchText ? programsearchlist : programs;

  return (
    <div className='row'>
      <div className='col-md-9 pr-5'>
        <h3 className='arrowWrapper'>Information</h3>
        <div className='innerColIndent'>
          <p>
            Government Agencies can set up &quot;Certifications&quot;, such as M/WBE, to track
            suppliers that meet specific certification or qualification criteria
          </p>
          <p>
            Search here to see a list of agencies with certifications for which you may be eligible.
          </p>
        </div>
        <h4>Certification ({finalprogramlist.length})</h4>
        <div className='innerColIndent'>
          {programs.length > 0 ? (
            <DeprecatedInput
              label='Search'
              placeholder='Search...'
              name='Search'
              type='search'
              classNames='class'
              value={programsearchText}
              handleChange={ProgramSearchFn}
            />
          ) : null}
          {populateResult(finalprogramlist, currentPage)}
        </div>
      </div>
      <div className='col-md-3'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={validate}
          onSubmit={handlesubmit}
        >
          {formikProps => {
            const { handleSubmit, setFieldValue, resetForm } = formikProps;

            const { attribMatch = 'onlySD' } = formikProps.values;

            return (
              <form onSubmit={handleSubmit}>
                <h4>Filters</h4>
                {dateshow ? (
                  <>
                    <Field
                      label='Status Changed Since'
                      classNames='class'
                      name='statusChangedSince'
                      isOutsideRange={true}
                      parentClass='mb-4'
                      component={renderDateField}
                      showClearDate={true}
                      optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                      onChange={setFieldValue}
                    />
                    <Field
                      label='Expires After'
                      classNames='class'
                      name='expiresAfter'
                      isOutsideRange={true}
                      parentClass='mb-4'
                      component={renderDateField}
                      showClearDate={true}
                      optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                      onChange={setFieldValue}
                    />
                  </>
                ) : null}
                <Field
                  name='certificationStatus'
                  type='select'
                  component={renderSelect}
                  label='Certification Status'
                  classNames='class'
                  options={certificationStatus}
                  onChange={setFieldValue}
                />

                <h6>Attribute Matching</h6>

                <Field
                  name={'attribMatch'}
                  title={'Only show certifications which match my self declared attributes'}
                  component={DeprecatedFormikRadio}
                  type='radio'
                  value={'onlySD'}
                  defaultChecked={attribMatch === 'onlySD' ? true : false}
                  handleSelect={setFieldValue}
                />

                <Field
                  name={'attribMatch'}
                  title={'Show all certifications'}
                  component={DeprecatedFormikRadio}
                  type='radio'
                  value={'showAll'}
                  defaultChecked={attribMatch === 'showAll' ? true : false}
                  handleSelect={setFieldValue}
                />

                <div className='clearfix text-center mt-4'>
                  <Buttons
                    text='Search'
                    type='submit'
                    classNames='bttn bttn-primary mb-3 w-75'
                    disable={invaliddateerror}
                  />
                  <Buttons
                    text='Clear Filter'
                    classNames='bttn bttn-secondary w-75'
                    onClick={() => {
                      const filters = {
                        memberId: mi,
                        buyerName: '',
                        statusChangedSince: '',
                        expiresAfter: '',
                        certificationStatus: '',
                        attribMatch: 'SD',
                      };
                      searchPrograms(filters);
                      resetForm();
                    }}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <ModalPopUp
        title={'Certification Application'}
        closeModal={handleProgramClick}
        isOpen={programClick}
        size='md'
      >
        {programCertificationPopup}
      </ModalPopUp>
      <ModalPopUp
        title={'Certification Application Confirmation'}
        closeModal={handleApplyClick}
        isOpen={applyClick}
        size='lg'
      >
        {applyProgramPopup}
      </ModalPopUp>
    </div>
  );
}

export default memo(Programs);
