import { useEffect, useMemo } from 'react';

import * as texts from './ContractWizard.texts';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';
import { ContractAwardeeDetails } from './contract-awardee-details/ContractAwardeeDetails';
import { ContractWizardId } from './useContractWizard';
import { ManageContractDocuments } from './manage-contract-documents/ManageContractDocuments';
// import { RequestDocumentsFromSupplier } from './request-documents-from-supplier/RequestDocumentsFromSupplier';
import { ReviewYourContract } from './review-your-contract/ReviewYourContract';
import { useContractDetails } from '../../useContractDetails';
import { useContractWizard } from './useContractWizard';
import { useEnforceMemberId } from 'src/shared/hooks/useMemberInfo';
import { VerifyContractDetails } from './verify-contract-details/VerifyContractDetails';
import { WizardContainer } from '../../../../components/customcontrols/wizard/WizardContainer';

const contractWizardComponents: Record<ContractWizardId, JSX.Element> = {
  [ContractWizardId.None]: <></>,
  [ContractWizardId.ContractSummary]: <VerifyContractDetails></VerifyContractDetails>,
  [ContractWizardId.SupplierDetails]: <ContractAwardeeDetails></ContractAwardeeDetails>,
  [ContractWizardId.ManageDocuments]: <ManageContractDocuments></ManageContractDocuments>,
  // [ContractWizardId.RequestDocuments]: (
  //   <RequestDocumentsFromSupplier></RequestDocumentsFromSupplier>
  // ),
  [ContractWizardId.Review]: <ReviewYourContract></ReviewYourContract>,
};

export function ContractWizard(): JSX.Element {
  const { contractWizardPages, contractWizardId, navigateContractWizard, resetWizardId } =
    useContractWizard();
  const { contractDetails } = useContractDetails();

  const currentWizardPage = useMemo(() => {
    return (
      contractWizardPages.find(awardPage => {
        return awardPage.id === contractWizardId;
      }) || contractWizardPages[0]
    );
  }, [contractWizardPages, contractWizardId]);

  useEffect(() => {
    // sets wizardId to 0 onDestroy
    return resetWizardId;
  }, [resetWizardId]);

  useEnforceMemberId(contractDetails?.memberId);

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      <WizardContainer
        navTitle={texts.creationProcess}
        wizard={contractWizardPages}
        currentWizard={currentWizardPage}
        header={contractDetails?.bidId ? texts.verifyAContract : texts.createAContract}
        description={
          contractDetails?.bidId ? texts.wizardVerifyDescription : texts.wizardCreateDescription
        }
        navigateTo={navigateContractWizard}
      >
        {contractWizardComponents[contractWizardId]}
      </WizardContainer>
    </Feature>
  );
}
