//TODO: Delete this. Use library instead.

enum Color {
  // Primary Action
  Purple = '#593E87',
  PurpleDark = '#372458',

  // Secondary Action
  TealLight = '#C3D4D6',
  Teal = '#3395A4',
  TealDark = '#1F6A85',

  // Reds
  Red = '#C7362D',
  Vermilion = '#DC3826',

  // Base
  White = '#FFFFFF',
  Black = '#111111',
}

export const BaseColor = {
  white: Color.White,
  black: Color.Black,
};

export const ActionColor = {
  primary: Color.Purple,
  primaryHighlight: Color.PurpleDark,
  secondary: Color.TealDark,
  secondaryHighlight: Color.TealLight,
  submit: Color.Teal,
};

export const ErrorColor = {
  base: Color.Red,
  highlight: Color.Vermilion,
};

// TODO: Establish a two tiered Color variable system. On one tier, we have all our colors and their hex codes.
// On the next, we have the uses of the color. The uses should not reference a hex code directly,
// but one of the tier one labels for the hexes.

export enum Colors {
  action = '#1F6A85', // blueGreenDark?
  black = '#111111',
  blueGreenArrowHighlight = '#d2dfe1',
  blueGreenLight = '#C3D4D6',
  blueGreenMedium = '#3395A4',
  complete = '#609B58', // dark green
  disabled = '#F5F5F5', // gray
  disabledBackground = '#F5F5F5',
  disabledText = '#7a7a7a',
  error = '#c7362d', // red
  grayBgHighlight = '#EAEAEA',
  grayDark = '#111111',
  grayDarker = '#7a7a7a',
  grayInputBorder = '#b3b3b3',
  grayLight = '#F5F5F5',
  grayMedium = '#dddddd',
  grayMediumDark = '#404040',
  grayMediumLight = '#eaeaea',
  grayTableRowBorder = '#e5e5e5',
  greenMedium = '#3f9421',
  orangeLight = '#ffb84a',
  orangeMedium = '#ff9f0e',
  primaryAction = '#593E87',
  redText = '#DC3826',
  transparent = 'transparent',
  white = '#FFFFFF',
  yellowMedium = '#f8df7b',
}
