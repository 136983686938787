import { memo } from 'react';

import NotificationAccounts from './accounts';
import NotificationBids from './bids';

import { activitiessearchTypes, activitiesTypes } from '../../../../types';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface propsTypes extends activitiessearchTypes {
  activities: activitiesTypes;
}
function Notification(props: propsTypes) {
  const { activities } = props;
  const { currentnotificationcategory } = activities;

  useSetBreadcrumb({
    page: 'Activities',
    altname: 'Notifications',
    title: 'Notifications',
    name: 'Notifications',
    menuactive: 'activities',
  });

  return (
    <>
      {currentnotificationcategory === 'Bids' && <NotificationBids {...props} />}
      {currentnotificationcategory === 'Accounts' && <NotificationAccounts {...props} />}
    </>
  );
}

export default memo(Notification);
