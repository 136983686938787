import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import DeclineModal from '../quotesreply/declinemodal';
import Details from '../../buyer/quotes/quotedetails/tabs/details/index';
import SupplierQuotescontrol from './quotecontrol';

import * as actionCreators from '../../../store/actions';
import { deprecatedCheckTimezoneCountDown, hasPermission, toastFn } from '../../../utils/helpers';
import { MemberType, userPermissionKeys } from '../../../utils/constants';

import { Buttons } from '../../customcontrols';
import { initialStateQuoteSummary } from '../../../store/reducers/quoteSummary';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
import { sharedTypes } from '../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes extends PropsFromRedux, RouteComponentProps {
  history: any;
  getQuoteSummary: any;
  quoteSummary: QuoteSummaryType;
  quoteSummaryGetDocuments: any;
  getQuoteLineItems: any;
  getQuoteView: any;
  setQuoteSummaryDetails: any;
  notSubmitQuote: any;
  getQuotesReply: any;
  match: any;
  shared: sharedTypes;
}

const QuoteDetail = (props: PropsTypes) => {
  const {
    history,
    getQuoteSummary,
    quoteSummary,
    quoteSummaryGetDocuments,
    getQuoteLineItems,
    getQuoteView,
    setQuoteSummaryDetails,
    notSubmitQuote,
    getQuotesReply,
  } = props;
  const { auth } = useAuthContext();
  const { summary, declinequotereplymodal = false } = quoteSummary;
  const {
    quoteName = '',
    statusType = '',
    dueDateTime = '',
    tzfn = '',
    responseStatus = '',
    quoteId = '',
  } = summary;
  const quoteID = parseInt(props.match.params.quoteId);
  const hasQuoteRespondPermission =
    hasPermission(auth?.accountPermissions || '', userPermissionKeys.respondQuotes) ||
    auth?.mainContact;

  useEffect(() => {
    if (Number(quoteId) === quoteID && summary) {
      if (auth?.memberType !== MemberType.AgencyBuyer && !summary.broadcastDateTime)
        history.replace('/');
    }
  }, [auth, history, summary, quoteID, quoteId]);

  useEffect(() => {
    if (auth?.memberType === MemberType.BasicSupplier) {
      history.push(`/suppliers/quotes/unauthorized/${quoteID}`);
    }
  }, [auth, history, quoteID]);

  useEffect(() => {
    if (statusType && Number(quoteId) === quoteID) {
      if (responseStatus === 'PB' || statusType === 'IN') history.replace('/');
    }
  }, [responseStatus, history, statusType, quoteID, quoteId]);

  useSetBreadcrumb({
    page: 'Supplier Quote Details',
    component: (
      <SupplierQuotescontrol
        quoteId={quoteId}
        setQuoteSummaryDetails={setQuoteSummaryDetails}
        notSubmitQuote={notSubmitQuote}
        summary={summary}
        hasQuoteRespondPermission={hasQuoteRespondPermission}
      />
    ),
    altname: quoteName + '- Quote Detail',
    title: quoteName + '- Quote Detail',
    name: quoteName + '- Quote Detail',
    menuactive: 'quotes',
  });

  useEffect(() => {
    if (quoteID) getQuoteSummary({ quoteID });
  }, [getQuoteSummary, quoteID]);

  useEffect(() => {
    if (quoteId) getQuoteView({ quoteId });
  }, [getQuoteView, quoteId]);

  useEffect(() => {
    if (quoteId) quoteSummaryGetDocuments({ quoteId });
  }, [quoteSummaryGetDocuments, quoteId]);

  useEffect(() => {
    if (quoteId) getQuoteLineItems({ quoteId, pagefor: 'quotesummary', pageType: 'supplier' });
  }, [getQuoteLineItems, quoteId, statusType]);

  useEffect(() => {
    if (quoteId) getQuotesReply({ auth, quoteId });
  }, [auth, getQuotesReply, quoteId]);

  useEffect(() => {
    return () => {
      setQuoteSummaryDetails({ summary: '' });
    };
  }, [setQuoteSummaryDetails]);

  useEffect(() => {
    return () => {
      setQuoteSummaryDetails(initialStateQuoteSummary);
    };
  }, [setQuoteSummaryDetails]);

  const handleClick = () => {
    history.push(`/suppliers/quotes/${quoteId}/response`);
  };

  const DeleteModalFn = () => {
    setQuoteSummaryDetails({ declinequotereplymodal: !declinequotereplymodal });
  };

  const RespondButtonComponent = () => {
    return (
      <>
        {statusType === 'OP' && deprecatedCheckTimezoneCountDown(dueDateTime, tzfn) !== 'closed' ? (
          <Buttons
            classNames='bttn-primary mt-4 mb-4 float-right'
            text={responseStatus === 'RS' ? 'Update Quote' : 'Respond To Quote'}
            onClick={handleClick}
            type='button'
          />
        ) : null}
      </>
    );
  };

  return (
    <div className='container'>
      <div className='row detailViewWrapper'>
        <div className='col-lg-12'>
          <div className='colWrapper'>
            <Details {...props} quoteId={quoteId} userpagefor='suppliers' />
            {hasQuoteRespondPermission ? <RespondButtonComponent /> : null}
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Reason For Not Quoting'
        closeModal={DeleteModalFn}
        isOpen={declinequotereplymodal}
        size='md'
      >
        <DeclineModal {...props} pagefor='detail' DeleteModalFn={DeleteModalFn} toastFn={toastFn} />
      </ModalPopUp>
    </div>
  );
};

const connector = connect(
  (state: any) => ({
    quoteSummary: state.quoteSummary,
    shared: state.shared,
    purchaseInfo: state.purchaseInfo,
    addquotes: state.addquotes,
    addbid: state.addbid,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withRouter(memo(QuoteDetail)));
