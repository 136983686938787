import { atom, selector, useRecoilValueLoadable } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';

import { DSSortProps, SortDirection } from '@demandstar/components/sort/DSSort';
import {
  SearchFilter,
  SearchFilters,
  SearchInputType,
  SearchInputWidth,
} from '@demandstar/components/search';
import { getBasicStringUrlHandler } from '@demandstar/components/utils';

import * as texts from './AddContract.texts';
import { getBids } from 'src/store/services';
import { tryCatchLog } from 'src/utils/errors';
import { useContractSearch } from '../../search';

type SearchOptions = {
  searchText: string;
};

export type BidSearchResult = {
  agency: string;
  bidId: number;
  bidIdentifier: string;
  bidName: string;
  broadCastDate: Dayjs;
  dueDate: Dayjs;
};

const awardedBidsSelector = selector<BidSearchResult[]>({
  key: 'awardedBidsSelector',
  get: async () => {
    return await tryCatchLog(async () => {
      const response = await getBids({
        bidStatus: 'AW',
        commodityExists: false,
        myBids: 'true',
        preserveFilters: false,
        showBids: 'Mine',
        sortBy: 'broadCastDate',
        sortOrder: 'DESC',
      });

      return (
        response?.data?.result?.map((bid: any) => {
          return {
            agency: bid.agency,
            bidId: bid.bidId,
            bidIdentifier: bid.bidIdentifier,
            bidName: bid.bidName,
            broadCastDate: dayjs(bid.broadCastDate),
            dueDate: dayjs(bid.dueDate),
          };
        }) ?? []
      );
    }, 'awardedBidsSelector => getBids');
  },
});

const awardedBidsState = atom<BidSearchResult[]>({
  key: 'awardedBidsState',
  default: awardedBidsSelector,
});

export function useAwardedBidSearch() {
  const allAwardedBidsLoadable = useRecoilValueLoadable(awardedBidsState);
  const allAwardedBids = useMemo(
    () => allAwardedBidsLoadable.valueMaybe() || [],
    [allAwardedBidsLoadable],
  );

  const { allContractsLoadable, contractLimitExceeded } = useContractSearch();

  const allContractBidIdsLoadable = useMemo(
    () => allContractsLoadable.map(contracts => contracts.map(c => c.bidId)),
    [allContractsLoadable],
  );

  const awardedBidsWithoutContractsLoadable = useMemo(() => {
    return allAwardedBidsLoadable.map(bids => {
      return allContractBidIdsLoadable.map(ids => {
        return bids.filter(bid => !ids.includes(bid.bidId));
      });
    });
  }, [allAwardedBidsLoadable, allContractBidIdsLoadable]);

  const awardedBidsWithoutContracts = useMemo(
    () => awardedBidsWithoutContractsLoadable.valueMaybe() || [],
    [awardedBidsWithoutContractsLoadable],
  );

  const searchTextFilter: SearchFilter<string, SearchOptions> = {
    inputType: SearchInputType.Text,
    label: texts.filters.search,
    name: 'searchText',
    getDisplayValue: (value?: string | undefined) => value,
    readOnly: contractLimitExceeded,
    width: SearchInputWidth.Full,
    urlHandling: getBasicStringUrlHandler('q'),
  };

  const searchFilters = [searchTextFilter] as SearchFilters<SearchOptions>;

  const search = useCallback(
    async ({ searchText = '' }: Partial<SearchOptions>) => {
      const bids = await awardedBidsWithoutContractsLoadable.toPromise();
      const results = !searchText
        ? bids
        : bids?.filter(
            bid =>
              bid.bidName.toLowerCase().includes(searchText.toLowerCase()) ||
              bid.bidIdentifier.toLowerCase().includes(searchText.toLowerCase()),
          );

      return results ?? [];
    },
    [awardedBidsWithoutContractsLoadable],
  );

  const sort: DSSortProps<BidSearchResult> = {
    sortOptions: [
      {
        label: texts.sortLabels.broadcast,
        sortByKey: 'broadCastDate',
        sortDirection: SortDirection.Descending,
      },
      {
        label: texts.sortLabels.due,
        sortByKey: 'dueDate',
        sortDirection: SortDirection.Descending,
      },
      {
        label: texts.sortLabels.name,
        sortByKey: 'bidName',
        sortDirection: SortDirection.Ascending,
      },
      {
        label: texts.sortLabels.id,
        sortByKey: 'bidIdentifier',
        sortDirection: SortDirection.Ascending,
      },
    ],
    showDirectionToggle: true,
  };

  return {
    allAwardedBids,
    allAwardedBidsLoadable,
    awardedBidsWithoutContracts,
    awardedBidsWithoutContractsLoadable,
    searchFilters,
    search,
    sort,
  };
}
