import { contractManagementLabels } from '../../ContractManagement.texts';
import { defaultMaximumContractCount } from '../../contract-management.helpers';

export const contracts = contractManagementLabels.contracts;
export const filters = {
  search: 'Search',
  supplier: 'Supplier',
  status: 'Status',
  dateRange: {
    fieldLabel: 'Date Range',
    appliedFilterLabel: 'Contract Date Range',
    message: 'Returns any contract that is active for any date in the date range',
  } as const,
} as const;
export const noResults = 'No matching results found';
export const recordLabels = {
  id: 'ID',
  end: 'End Date',
  start: 'Start Date',
  suppliers: 'No. Suppliers',
} as const;
export const sortLabels = {
  created: 'Created Date',
  end: 'End Date',
  id: 'ID',
  name: 'Name',
  start: 'Start Date',
} as const;
export const youAreManaging = (total: number) =>
  `You have ${total} of ${defaultMaximumContractCount} contracts you're managing on DemandStar.`;
