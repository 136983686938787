import { AxiosRequestConfig } from 'axios';

import {
  HistoricalBidAPIRequest,
  HistoricalBidAPIResponse,
  SEOBaseCodeAPIResponse,
} from './BrowseBids.d';

import { axiosPostRequest, axiosRequest } from 'src/service/api';
import { constants } from '../../../utils/settings';
import { Paths } from 'src/utils/constants';

/**
 * Typed Historical Bid request
 * @param {HistoricalBidAPIRequest} payload
 * @returns a {Promise<HistoricalBidAPIRequest>} response
 */
export function getHistoricalBids(payload: HistoricalBidAPIRequest) {
  const { commodityCategory, commodityGroup, msa, state } = payload;
  if (!Object.values(payload).length) {
    return;
  }
  const requestConfig: AxiosRequestConfig<HistoricalBidAPIRequest> = {
    baseURL: constants.api.urlNoAuth,
    data: {
      commodityCategory,
      commodityGroup,
      msa,
      state,
    },
    url: Paths.agencyHistoricalBids,
  };
  return axiosPostRequest<HistoricalBidAPIResponse[]>(requestConfig);
}

/**
 * Typed Commodity Code request
 * @param bidId
 * @returns a {Promise<SEOBaseCodeAPIResponse>} response
 */
export function getSEOBaseCodes() {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.urlNoAuth,
    data: {},
    url: Paths.browseBids,
  };
  return axiosRequest<SEOBaseCodeAPIResponse>(requestConfig);
}
