import dayjs from 'dayjs';
import moment from 'moment-timezone';
import momenttz from 'moment-timezone';

import {
  datePickerYear,
  DeprecatedFullTimeZone,
  numNextFiscalYears,
  numPreviousFiscalYears,
  TimeZone,
  timeZoneList,
  usaDateFormat,
  usaDateTimeFormat,
  validDateFormats,
} from '../constants';
import { Assert } from './testing';
import { CountdownStatus } from 'src/types';
import { currentYear } from './dateTime';
import { getTwoDigit } from '.';
import { timeArrayTypes } from 'src/types/utils';

/**
 * @deprecated We no longer want to make label-value pairs for select boxes.
 * Consider using `getFiscalYearRange` instead and use DSSelect or DSSelectField
 * */
export const GetFiscalYearsFn = () => {
  const years = [];
  for (
    let i = currentYear() - numPreviousFiscalYears;
    i <= currentYear() + numNextFiscalYears;
    i++
  ) {
    years.push({ label: i, value: i });
  }
  return years;
};

/**
 * @deprecated this is not the pattern we want
 * use `<DSDateField showTime/>` instead */
export const getTimeIntervals = (interval?: number) => {
  const timeArray: timeArrayTypes = [];

  const hours = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
  const minutes = interval === 30 ? ['00', '30'] : ['00', '15', '30', '45'];

  hours.forEach(hr => {
    minutes.forEach(min => {
      const time = hr + ':' + min;
      timeArray.push({ key: time, value: time, label: time, title: time });
    });
  });
  return timeArray;
};

/** @deprecated use displayDate instead. (helpers/dateTime) */
export const deprecatedGetDate = (value?: dayjs.ConfigType) => {
  if (value) {
    const returnDate = dayjs(value);
    return returnDate.isValid() ? returnDate.format(usaDateFormat) : '';
  }
  return '';
};

/** @deprecated use displayDateTime instead. (helpers/dateTime) */
export const deprecatedGetDateTime = (value: string | number | Date) => {
  if (value) {
    return dayjs(value).format('MM/DD/YYYY h:mm A');
  }
  return '';
};

/** @deprecated use displayDateTime instead. (helpers/dateTime) */
export const deprecatedGetDateTimeZoneConverted = (
  date: string | number | Date,
  zone?: DeprecatedFullTimeZone | '',
  showSeconds = false,
  dateOnly = false,
) => {
  let convertedDate = '';
  if (date) {
    Assert(!!zone, 'The time zone should not be empty.', 'deprecatedGetDateTimeZoneConverted');
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    let zoneDifferent = timeZoneList[zone];
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    if (dateOnly) {
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY');
    } else if (showSeconds) {
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY h:mm:ss A');
    } else
      convertedDate = momenttz(gmtDate).add(zoneDifferent, 'hours').format('MM/DD/YYYY h:mm A');
  }
  return convertedDate;
};

/** @deprecated use displayDate instead. (helpers/dateTime) */
export const deprecatedGetDateTimeZoneConvertedDate = (
  date: string | number | Date,
  zone?: DeprecatedFullTimeZone | '',
) => {
  if (date) {
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    Assert(!!zone, 'The time zone should not be empty.', 'getTimeZoneConvertedDate');
    let zoneDifferent = timeZoneList[zone] as any;
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    return momenttz(gmtDate).add(zoneDifferent, 'hours').format(usaDateFormat);
  }
  return '';
};

/**
 * @deprecated use formatAsUTC instead. (helpers/dateTime)
 * This function is used primarily to format a date in a certain way
 * to be used by an endpoint. So I am going to leave it untouched, so as not to mess with any endpoints.
 * We should be able to cut this down to one line though, and we should have no need for
 * our timeZoneList, a re-formatted date, or daylight savings.
 *
 * We should not ever change the actual time of the object as we are doing here.
 *
 * We should not even need to know the time zone, so long as we have an offset.
 */
export const deprecatedConvertotherTimezonetoUTC = (
  date: string | number | Date,
  zone: DeprecatedFullTimeZone,
  format = 'MM/DD/YYYY h:mm A',
) => {
  if (date) {
    const gmtDate = momenttz(date).format('YYYY-MM-DD HH:mm:ss');
    let zoneDifferent = timeZoneList[zone] as any;
    const isDST = momenttz(gmtDate).tz(TimeZone.Eastern).isDST();
    zoneDifferent = isDST ? zoneDifferent + 1 : zoneDifferent;
    return momenttz(gmtDate).add(Math.abs(zoneDifferent), 'hours').format(format);
  }
  return '';
};

/** @deprecated use displayDate instead. (helpers/dateTime)*/
export const deprecatedConvertUSAFormat = (date: momenttz.MomentInput) => {
  let value = '';
  if (date && moment(date, validDateFormats).isValid()) {
    value = moment(date).format(usaDateFormat);
  }
  return value;
};

/**
 * @deprecated Please rewrite instead of adding this to new code.
 * - remove use of Date
 * - standardize the format
 */
export const deprecatedGetBroadcastGap = (dueDate: string | number | Date) => {
  return dayjs(new Date(dueDate)).add(1, 'hours').format('ll LTS');
};

/**
 * @deprecated Please rewrite instead of adding this to new code.
 * - remove use of Date
 * - standardize the format
 * - consider replacing with dayjs().isSameOrBefore()
 * - remove pagefor
 */
export const deprecatedCheckCountDown = (dueDate = '', pagefor = '') => {
  let finalvalue: CountdownStatus = '';
  const countDownDate = new Date(dueDate).getTime();
  const current = dayjs(new Date());
  const currentTime = current.tz(TimeZone.Eastern).format('ll LTS');
  const now = new Date(currentTime).getTime();
  const distance = countDownDate - now;
  finalvalue = distance < 0 ? 'closed' : '';

  if (pagefor === 'supplierquote') {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    if (days > 10) {
      finalvalue = 'notstarted';
    }
  }
  return finalvalue;
};

/**
 * @deprecated Please rewrite instead of adding this to new code.
 * - remove pagefor
 * - use dayJS
 * - remove Dates
 * - consider replacing with dayjs().isSameOrBefore()
 */
export const deprecatedCheckTimezoneCountDown = (
  dueDate = '',
  timeZone: DeprecatedFullTimeZone | '',
  pagefor = '',
) => {
  let finalValue: 'notstarted' | 'closed' | '' = '';
  const dueDateTime = deprecatedGetDateTimeZoneConverted(dueDate, timeZone);
  const countDownDate = new Date(dueDateTime).getTime();

  const current = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss');

  const currentTime = deprecatedGetDateTimeZoneConverted(current, timeZone);
  const now = new Date(currentTime).getTime();
  const distance = countDownDate - now;
  finalValue = distance < 0 ? 'closed' : '';

  if (pagefor === 'supplierquote') {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    if (days > 10) {
      finalValue = 'notstarted';
    }
  }

  return finalValue;
};

/**
 *  @deprecated Please rewrite instead of adding this to new code.
 * - use dayJS
 * - remove Dates and Math
 * - consider replacing with Duration and Difference in dayjs.
 * https://day.js.org/docs/en/durations/diffing
 */
export const deprecatedCountDownTimerTimeZone = (
  dueDate: string | number | Date,
  tzfn: DeprecatedFullTimeZone | '' = '',
) => {
  const countDownDateString = deprecatedGetDateTimeZoneConverted(dueDate, tzfn, true);
  const countDownDate = moment(countDownDateString).valueOf();
  const current = moment.utc(new Date()).format('ll LTS').toString();
  const currentTime = deprecatedGetDateTimeZoneConverted(current, tzfn, true);
  const now = momenttz(currentTime).valueOf();

  const distance = countDownDate - now;
  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const dayString =
    days > 0 ? `${getTwoDigit(days)} ${days > 1 ? 'days' : 'day'}${hours > 0 ? ',' : ''} ` : '';
  const hoursString =
    days > 0 || hours > 0 ? `${getTwoDigit(hours)} ${hours > 1 ? 'hours' : 'hour'}, ` : '';

  const minutesString =
    days > 0 || hours > 0 || minutes > 0
      ? `${getTwoDigit(minutes)} ${minutes > 1 ? 'minutes' : 'minute'}, `
      : '';

  const secondsString =
    days > 0 || hours > 0 || minutes > 0 || seconds > 0
      ? `${getTwoDigit(seconds)} ${seconds > 1 ? 'seconds' : 'second'}`
      : '';

  const countDown = `${dayString}${hoursString}${minutesString}${secondsString}` || 'Closed';

  return distance < 0 ? 'Closed' : countDown;
};

/** @deprecated use displayToday instead. (helpers/dateTime) */
export const deprecatedGetTimeZoneDateTime = (
  zone?: DeprecatedFullTimeZone | '',
  format = usaDateTimeFormat,
) => {
  let value: string | moment.Moment = '';
  const defaultzone = TimeZone.Eastern; // define est, pacific timezones in this format eg: TimeZone.Pacific
  if (zone) {
    const zoneDifferent = timeZoneList[zone]; // agency/supplier/bids timezone
    value = momenttz
      .utc()
      .tz(defaultzone)
      .add(5 + zoneDifferent, 'hours');
    // this is actually changing the value of date instead of displaying the correct timezone.
    // we're essentially changing the time,
    // and changing the timezone so they are both incorrect but(hopefully) in sync.
    // two wrongs make a right.
    if (format) value = value.format(format);
    // TODO: Under what circumstances would we not have a 'format'?
  }
  return value;
};

/** @deprecated use dayJS */
export const getNativeDate = (value: string | number | Date | undefined) => {
  if (value) {
    const newDate = new Date(value);
    const year = newDate.getFullYear();
    const month = (1 + newDate.getMonth()).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  }
  return '';
};

/** @deprecated use dayJS*/
export const toISOString = (dateObj: string | number | Date) => {
  const month = new Date(dateObj).getMonth() + 1;
  const date = new Date(dateObj).getDate();
  return (
    new Date(dateObj).getFullYear() +
    '-' +
    (month >= 10 ? month : '0' + month) +
    '-' +
    (date >= 10 ? date : '0' + date)
  );
};

/**
 * @deprecated
 * To get and set Date */
export const SetDateTime = (step: string, count: number, date: string | number | Date) => {
  const day = date ? new Date(date) : new Date();
  const nextDay = new Date(day);
  let tommorow;
  if (step === 'plus') {
    tommorow = new Date(nextDay.setDate(day.getDate() + count));
  } else if (step === 'minus') {
    tommorow = new Date(nextDay.setDate(day.getDate() - count));
  }
  return tommorow;
};

/**
 * @deprecated use <DSDateField showTime /> instead.
 */
export const getTimeZone = () => {
  return [
    { key: 'AM', value: 'AM', label: 'AM', title: 'AM' },
    { key: 'PM', value: 'PM', label: 'PM', title: 'PM' },
  ];
};

/** @deprecated */
export const setTimeToDate = (date: string | number | Date, hoursString: string) => {
  const selectedDate = new Date(date);
  const dateNumber = selectedDate.getDate();
  const monthNumber = selectedDate.getMonth() + 1;
  const yearNumber = selectedDate.getFullYear();
  return `${monthNumber}/${dateNumber}/${yearNumber} ${hoursString}`;
};

/** @deprecated */
export const getTimeFromDate = (date: string | number | Date) => {
  date = new Date(date);
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  //var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? (hours < 10 ? '0' + hours : hours) : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes;
  return { key: strTime, value: strTime, label: strTime, title: strTime };
};

/** @deprecated */
export const getZoneFromDate = (date: string | number | Date) => {
  date = new Date(date);
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return { key: ampm, value: ampm, label: ampm, title: ampm };
};

/** @deprecated */
export const changeDateToString = (inputDate: {
  getDate: () => any;
  getMonth: () => number;
  getFullYear: () => any;
}) => {
  const Day = inputDate.getDate();
  const Month = inputDate.getMonth() + 1;
  const Year = inputDate.getFullYear();
  return `${Month}/${Day}/${Year}`;
};

/** @deprecated */
export const validateTypedDate = (
  inputDate: string,
  minDate: any = '',
  maxDate: any = '',
  isOutsideRange = false,
) => {
  const dateString = getFormattedCustomDateString(inputDate);
  const dateSplit = getFormattedCustomDateString(inputDate).split('/');
  if (dateSplit.length < 3) return false;
  else if (momenttz(dateString).format(usaDateFormat) !== dateString) {
    return false;
  } else {
    if (
      parseInt(dateSplit[0]) > 12 ||
      parseInt(dateSplit[1]) > 31 ||
      parseInt(dateSplit[2]) < momenttz().year() - datePickerYear ||
      parseInt(dateSplit[2]) > momenttz().year() + datePickerYear ||
      dateSplit[2].length !== 4
    ) {
      return false;
    } else if (!isOutsideRange) {
      const selectedDate = momenttz(dateString);

      if (minDate && maxDate) {
        return !(selectedDate.isAfter(maxDate) || selectedDate.isBefore(minDate));
      }
      if (minDate) return !selectedDate.isBefore(minDate);
    }
  }
  return true;
};

/** @deprecated */
export const getFormattedCustomDateString = (dateString: string) => {
  const dateSplit = dateString.split('/');
  if (dateSplit.length === 3) {
    if (dateSplit[0].length === 1) dateSplit[0] = '0' + dateSplit[0];
    if (dateSplit[1].length === 1) dateSplit[1] = '0' + dateSplit[1];
  }
  return dateSplit.join('/');
};

/** @deprecated */
export const validateOnTypeDate = (date: string) => {
  const splittedDate = date.split('/');

  if (splittedDate.length > 3) {
    return true;
  }

  if (splittedDate.length === 3 && splittedDate[2].length >= 4) {
    return !validateTypedDate(date);
  }

  if (splittedDate.length > 0) {
    if (parseInt(splittedDate[0]) > 12) {
      return true;
    } else if (splittedDate[1] && parseInt(splittedDate[1]) > 31) {
      return true;
    }
  }

  return false;
};
