import { createActionTypes, createNoLoadActionTypes } from './utils';

export const LOAD_BID_SUMMARY = createActionTypes('LOAD_BID_SUMMARY');
export const GET_BID_SUMMARY_WATCH_STATUS = createNoLoadActionTypes('GET_BID_SUMMARY_WATCH_STATUS');
export const GET_PLANHOLDERS_DETAILS = createNoLoadActionTypes('GET_PLANHOLDERS_DETAILS');
export const LOAD_BID_STATS = createNoLoadActionTypes('LOAD_BID_STATS');
export const LOAD_BID_DOCUMENTS = createNoLoadActionTypes('LOAD_BID_DOCUMENTS');
export const LOAD_BID_WATCHERS = createNoLoadActionTypes('LOAD_BID_WATCHERS');
export const LOAD_BID_PLANHOLDERS = createNoLoadActionTypes('LOAD_BID_PLANHOLDERS');
export const LOAD_BID_BIDDERS = createNoLoadActionTypes('LOAD_BID_BIDDERS');
export const LOAD_BID_SIMILARBIDS = createNoLoadActionTypes('LOAD_BID_SIMILARBIDS');
export const LOAD_BID_BIDWATCH = createNoLoadActionTypes('LOAD_BID_BIDWATCH');
export const LOAD_BID_LEGALAD = createNoLoadActionTypes('LOAD_BID_LEGALAD');
export const LOAD_BID_EBIDS = createActionTypes('LOAD_BID_EBIDS');
export const LOAD_BID_PURCHASEINFO = createNoLoadActionTypes('LOAD_BID_PURCHASEINFO');
export const LOAD_BID_AUDITTRAIL = createNoLoadActionTypes('LOAD_BID_AUDITTRAIL');
export const DOWNLOAD_DOCUMENT = createActionTypes('DOWNLOAD_DOCUMENT');
export const LOAD_BID_RESPONSE_DOCUMENTS = createNoLoadActionTypes('LOAD_BID_RESPONSE_DOCUMENTS');
export const DOWNLOAD_DOCUMENT_RESET = createNoLoadActionTypes('DOWNLOAD_DOCUMENT_RESET');
export const LOAD_BIDS_SIMILAR_SUPPLIERS = createNoLoadActionTypes('LOAD_BIDS_SIMILAR_SUPPLIERS');
export const LOAD_BID_PLANHOLDER_DOCUMENTS = createNoLoadActionTypes(
  'LOAD_BID_PLANHOLDER_DOCUMENTS',
);
export const SEND_BID_SELECTED_SUPPLIER = createActionTypes('SEND_BID_SELECTED_SUPPLIER');
export const ADD_NEW_SUPPLIER = createActionTypes('ADD_NEW_SUPPLIER');
export const REMOVE_BID_PLANHOLDER_SUPPLIER = createActionTypes('REMOVE_BID_PLANHOLDER_SUPPLIER');
export const CHANGE_ADD_SUPPLIER_ACTIVE = createActionTypes('CHANGE_ADD_SUPPLIER_ACTIVE');
export const GET_BIDS_SEARCH_PLANDER = createActionTypes('GET_BIDS_SEARCH_PLANDER');
export const GET_BIDS_BROADCAST_HISTORY = createNoLoadActionTypes('GET_BIDS_BROADCAST_HISTORY');
export const BIDS_AUDIT_TRIAL = createNoLoadActionTypes('BIDS_AUDIT_TRIAL');
export const RESET_BIDS_SEARCH_PLANDER = createActionTypes('RESET_BIDS_SEARCH_PLANDER');
export const LOAD_BID_PLANDER_DATA = createActionTypes('LOAD_BID_PLANDER_DATA');
export const SET_MODAL_OPEN = createActionTypes('SET_MODAL_OPEN');
export const SET_SUPPLIER_SELF_DECLARATION = createActionTypes('SET_SUPPLIER_SELF_DECLARATION');
export const EDIT_BID_PLANHOLDER = createActionTypes('EDIT_BID_PLANHOLDER');
export const SELECT_COUNTRY_STATE = createNoLoadActionTypes('SELECT_COUNTRY_STATE');
export const SET_FILTERED_AUDIT_TRIAL_LIST = createNoLoadActionTypes(
  'SET_FILTERED_AUDIT_TRIAL_LIST',
);
export const SUBMIT_BIDS_UPDATE_REMINDER = createActionTypes('SUBMIT_BIDS_UPDATE_REMINDER');
export const GET_BIDS_BIDS_REMINDERS = createNoLoadActionTypes('GET_BIDS_BIDS_REMINDERS');
export const SET_BID_SUMMARY_DETAILS = createNoLoadActionTypes('SET_BID_SUMMARY_DETAILS');
export const SET_SELECTED_BID_SUMMARY = createNoLoadActionTypes('SET_SELECTED_BID_SUMMARY');
export const SET_SELECTED_POST_BID_SUPPLIER_DETAILS = createNoLoadActionTypes(
  'SET_SELECTED_POST_BID_SUPPLIER_DETAILS',
);
export const SUPPLIER_CAN_UPGRADE = createNoLoadActionTypes('SUPPLIER_CAN_UPGRADE');
export const LOAD_BID_POSTBIDVIEWERS = createNoLoadActionTypes('LOAD_BID_POSTBIDVIEWERS');
export const SET_POST_BID_SUPPLIER_DETAILS = createNoLoadActionTypes(
  'SET_POST_BID_SUPPLIER_DETAILS',
);
export const GET_BID_COMMODITYCODES = createNoLoadActionTypes('GET_BID_COMMODITYCODES');
export const GET_RESPONSE_EVENTS = createActionTypes('GET_RESPONSE_EVENTS');
export const RESET_BIDS_SUMMARY = createActionTypes('RESET_BIDS_SUMMARY');
export const ADD_BID_SUPPLIER_TABULATION = createActionTypes('ADD_BID_SUPPLIER_TABULATION');
export const EXPORT_PLANHOLDERS_DETAILS = createNoLoadActionTypes('EXPORT_PLANHOLDERS_DETAILS');
export const GET_BID_TABULATION = createNoLoadActionTypes('GET_BID_TABULATION');
export const EXPORT_BROADCAST_LIST = createNoLoadActionTypes('EXPORT_BROADCAST_LIST');
export const EXPORT_SUPPLEMENTAL_SUPPLIERS = createNoLoadActionTypes(
  'EXPORT_SUPPLEMENTAL_SUPPLIERS',
);
export const EXPORT_LEGAL_AD = createNoLoadActionTypes('EXPORT_LEGAL_AD');
export const DOWNLOAD_ALL_DOCUMENT = createNoLoadActionTypes('DOWNLOAD_ALL_DOCUMENT');
export const UPDATE_BID_STATUS = createNoLoadActionTypes('UPDATE_BID_STATUS');
export const GET_PLANHOLDERS_OTHER_DETAILS = createNoLoadActionTypes(
  'GET_PLANHOLDERS_OTHER_DETAILS',
);
export const GET_GENERATE_TAGS = createNoLoadActionTypes('GET_GENERATE_TAGS');
export const UPDATE_GENERATED_TAGS = createNoLoadActionTypes('UPDATE_GENERATED_TAGS');
