import { useCallback, useMemo, useState } from 'react';

import { DSAlert } from '@demandstar/components/alert';
import { DSAlertProps } from '@demandstar/components/alert/DSAlert';
import { DSLink } from '@demandstar/components/link';
import { Status } from '@demandstar/components/constants';

import {
  contractManagementLimitText,
  ContractManagementTestIds,
} from '../../ContractManagement.texts';
import { useContractSearch } from '../../search';

/**
 * Alert for CM Contract Limit Warning/Error, based upon the user's contract count.
 * @returns DSAlert
 */
export function ContractLimitAlert() {
  const { allContractsCount, contractLimitExceeded, contractLimitWarningExceeded } =
    useContractSearch();
  const [alertVisible, setAlertVisible] = useState(true);

  const hideAlert = useCallback(() => {
    setAlertVisible(false);
  }, []);

  const alertSettings: DSAlertProps = useMemo(() => {
    if (contractLimitExceeded) {
      return {
        dataTestId: ContractManagementTestIds.Error,
        header: contractManagementLimitText.headerError,
        onDismiss: hideAlert,
        type: Status.Error,
      };
    }
    if (contractLimitWarningExceeded) {
      return {
        dataTestId: ContractManagementTestIds.Warning,
        header: contractManagementLimitText.headerWarning(allContractsCount),
        onDismiss: hideAlert,
        type: Status.Warning,
      };
    }
    return {} as DSAlertProps;
  }, [allContractsCount, contractLimitExceeded, contractLimitWarningExceeded, hideAlert]);

  const AlertBody = useMemo(() => {
    return (
      <>
        {contractManagementLimitText.linkPrefix}
        <DSLink href={contractManagementLimitText.linkURL}>
          {contractManagementLimitText.linkText}
        </DSLink>
      </>
    );
  }, []);

  return alertSettings.type && alertVisible ? (
    <DSAlert {...alertSettings}>{AlertBody}</DSAlert>
  ) : null;
}
