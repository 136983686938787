import React from 'react';
import Buttons from '../../../../../customcontrols/button';
import { useAuthContext } from 'src/auth/AuthProvider';

interface propsTypes {
  unblockHandler: any;
  unblockUserAccount: any;
  history: any;
}

const UnblockUser = (props: propsTypes) => {
  const { unblockHandler, unblockUserAccount, history } = props;
  const { auth } = useAuthContext();
  const userinfo = (history.location.state && history.location.state.userinfo) || '';
  return (
    <>
      <p>
        This user was blocked due to exceeding the threshold. Please ensure they are using the
        system legitimately before unblocking them. Do you want to Unblock them or add them to the
        Allowlist to prevent them from being blocked in the future?
      </p>
      <div className='d-flex justify-content-between mt-5'>
        <Buttons
          type='button'
          text='No, leave them blocked'
          classNames='bttn-primary'
          onClick={() => {
            unblockHandler();
          }}
        />
        <Buttons
          type='button'
          text='Unblock, but let them get blocked if they exceed the threshold again'
          classNames='bttn-primary mx-2'
          onClick={() => {
            unblockUserAccount({
              accountId: userinfo.accountId,
              memberId: auth?.memberId,
              IsWhitlisted: false,
            });
            unblockHandler();
          }}
        />
        <Buttons
          type='button'
          text='Yes, unblock and add them to the Allowlist'
          classNames='bttn-primary'
          onClick={() => {
            unblockUserAccount({
              accountId: userinfo.accountId,
              memberId: auth?.memberId,
              IsWhitlisted: true,
            });
            unblockHandler();
          }}
        />
      </div>
    </>
  );
};

export default UnblockUser;
