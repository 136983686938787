import React, { memo } from 'react';
import { useAuthContext } from 'src/auth/AuthProvider';
import { Buttons, DeprecatedInput } from '../../../../customcontrols';

interface propsTypes {
  Setsavelistmodal: any;
  setBroadcastListDetails: any;
  newlistname: string;
  submitBroadcastListAddList: any;
  savelistExistmodal: boolean;
  pagefor?: string;
}

const SaveList = (props: propsTypes) => {
  const {
    Setsavelistmodal,
    setBroadcastListDetails,
    newlistname,
    submitBroadcastListAddList,
    savelistExistmodal = false,
    pagefor = '',
  } = props;
  const { auth } = useAuthContext();

  const SetsavelistExistmodal = () => {
    setBroadcastListDetails({ savelistExistmodal: !savelistExistmodal, newlistname: '' });
  };

  const type = pagefor;
  return (
    <>
      {savelistExistmodal ? (
        <>
          <h6 className='p-3 text-center'>
            Broadcast list name({newlistname}) already exists. Do you want to replace?
          </h6>
          <Buttons
            classNames='bttn-primary float-right mt-2'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => submitBroadcastListAddList({ auth, replaceIfExists: true, type })}
            disable={!newlistname}
          />
          <Buttons
            classNames='bttn-secondary mt-2'
            title='Cancel'
            text='Cancel'
            type='button'
            onClick={SetsavelistExistmodal}
          />
        </>
      ) : (
        <>
          <DeprecatedInput
            type='text'
            placeholder='List Name'
            label='List Name'
            value={newlistname}
            handleChange={(name: string, value: any) =>
              setBroadcastListDetails({ newlistname: value })
            }
          />
          <Buttons
            classNames='bttn-primary float-right mt-2'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => submitBroadcastListAddList({ auth, type })}
            disable={!newlistname}
          />
          <Buttons
            classNames='bttn-secondary mt-2'
            text='Cancel'
            title='Cancel'
            type='button'
            onClick={Setsavelistmodal}
          />
        </>
      )}
    </>
  );
};

export default memo(SaveList);
