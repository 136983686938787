import styled from 'styled-components';

import {
  BorderColor,
  BorderThickness,
  Flex,
  FlexContainer,
  Margin,
  Padding,
} from '@demandstar/components/styles';

export const ButtonsFlexContainer = styled(FlexContainer)`
  margin-bottom: ${Margin.Extra};
`;

export const DetailsSection = styled(Flex)`
  margin-bottom: ${Margin.Extra};
  width: 100%;
`;

export const UnderlinedSection = styled(DetailsSection)`
  border-bottom: ${BorderThickness.Base} solid ${BorderColor.Highlight};
  padding-bottom: ${Padding.Base};
`;
