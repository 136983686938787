import { useRecoilValueLoadable } from 'recoil';

import { DSLoadingPanel } from '@demandstar/components/loading-panel';

import { getDefaultLoadingErrorMessage, getDefaultLoadingMessage } from '../../../../utils/message';
import { BidResponseDetailsPanelView } from './BidResponseDetailsPanelView';
import { bidResponseDetailsState } from '../../../../store/recoil/bidResponseState';
import { ErrorPanel } from '../../../../components/common/loading';
import { navigationPaths } from '../../../../shared/constants';
import { RESPONSE_DETAILS_LABEL } from '../../../../shared/constants/bids';
import { useBidResponseStatus } from '../../../../shared/hooks/useBidResponseStatus';
import { useRouter } from '../../../../shared/hooks';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';
import { HighlightedDetailsWrapper } from '@demandstar/components/styles';

export function BidResponseDetailsPanel() {
  // Hooks
  const { navigate } = useRouter();
  const { selectedBidId } = useSelectedBidId();

  // App state
  // Get the response details for the current bidId.
  // Updating the selectedBidId will update this loadable in the store.
  const responseDetailsLoadable = useRecoilValueLoadable(bidResponseDetailsState);

  // const responseStatus = useRecoilValue(responseDetailsStatusState);
  const { bidResponseStatus } = useBidResponseStatus();

  function navigateToResponseEditView() {
    navigate(`${navigationPaths.suppliers.bids}/${selectedBidId}/response`);
  }

  // SHow a loading message by default
  let returnContent = null;
  // Handle Recoil State Loadable states:
  // TODO: Standardize styled components for "hasError" and/or "loading" states.
  if (responseDetailsLoadable.state === 'hasError') {
    returnContent = <ErrorPanel message={getDefaultLoadingErrorMessage(RESPONSE_DETAILS_LABEL)} />;
  }

  if (responseDetailsLoadable.state === 'loading') {
    returnContent = (
      <DSLoadingPanel>{getDefaultLoadingMessage(RESPONSE_DETAILS_LABEL)}</DSLoadingPanel>
    );
  }

  if (responseDetailsLoadable.state === 'hasValue' && responseDetailsLoadable?.contents) {
    const responseDetails = responseDetailsLoadable?.contents;
    if (!responseDetails.agencyName) returnContent = null;
    else
      returnContent = (
        <HighlightedDetailsWrapper>
          <BidResponseDetailsPanelView
            responseDetails={responseDetails}
            handleEditClick={navigateToResponseEditView}
            bidStatus={bidResponseStatus}
          />
        </HighlightedDetailsWrapper>
      );
  }

  return returnContent;
}
