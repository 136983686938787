import { BidExternalStatusType, DeprecatedFullTimeZone } from '../utils/constants';

import { AddBidState } from '../store/reducers/addbid';
import { Selectable } from '../types/common';

export interface UploadedFile {
  lastModified: number;
  name: string;
  size: number;
  type: string; // "application/pdf"
  webkitRelativePath: string;
}

export type DocTypeLabel = 'Bid Document / Specifications' | 'Attachment' | 'Clarification' | '';

export enum DocTypeValue {
  Addendum = 'AD',
  //  AT = 'AT',
  AwardDocument = 'AR',
  //  BD = 'BD',
  BidTabulationSheet = 'BT',
  Clarification = 'CL',
  NoticeOfDelay = 'DE',
  NB = 'NB', // what does this mean?
  NoticeOfIntent = 'NI',
  RecommendationOfAward = 'RA',
  Revision = 'RV',
  ShortList = 'SL',
  SignInSheet = 'SI',
  None = '',
}

export enum DocStatus {
  ApprovalPending = 'RV',
  Complete = 'AC',
  ProcessingError = 'CE',
  ProcessPending = 'NO',
  PdfProcessPending = 'IS',
  None = '',
}

export type StatusLabel = 'Approval Pending' | 'Complete' | 'Processing Error';

export type AddBidDocTypeOption = {
  label: DocTypeLabel;
  value: DocTypeValue;
};

export type BidDocument = {
  bidDocID?: number;
  bidDocId: number | ''; // duplicate

  bidID?: number;
  bidId: number | ''; // duplicate

  docconversion: boolean;
  requireConversion?: boolean; // duplicate

  doctype: DocTypeLabel | AddBidDocTypeOption; // this is the monstrosity. avoid it.
  type?: DocTypeLabel; // this is what we use for the full description string.
  docType: DocTypeValue;

  docfile?: UploadedFile | string; // let's have this be the full file always
  path?: string; // duplicate
  // path will always be just the path.

  doctitle: string;
  fileName?: string; // duplicate

  procProgress?: number; // kill procProgress
  progress?: number; //duplicate

  statusType: DocStatus;
  bidDocStatusName?: DocStatus; //duplicate //TODO: Remove this.
  status?: StatusLabel;

  dc?: boolean;
  docext: string;
  docFormat?: 'PDF ' | 'PLT ' | 'TXT '; // obviously, it would be great for these to be trimmed already.
  duedates?: Date;
  duetimes?: '';
  duetimezones?: '';
  icon?: 'pdf.gif' | 'txt.gif';
  id: string;
  isDocumentProgress?: boolean;
  isExist: boolean;
  mimeType?: 'application/pdf' | 'text/plain';
  modifiedDate?: string;
  originalFileName?: string;
  remainingTime?: number;
  selectduedates?: { label: 'No'; value: 'No' };
  startTime?: number;
  uploadprocess: 'notstarted' | 'started' | 'completed';
  // TODO: remove temporary field once Vendor-specific uploads are refactored
  supplierMemberId?: number;
};

export type addbidParamType = AddBidState;

export type broadcastListItemType = {
  name: string;
  city: string;
  state: string;
  mi: number;
  listId: number;
  selected: boolean;
  createdBy: string;
  noOfMembers: number;
  lastUpdatedOn: string;
  accountId: number;
  broadcasted: boolean;
};

export type AddBidInfo = {
  bidBond: string;
  bidName: string;
  bidNumber: string;
  bidstatus: { value: BidExternalStatusType; label: string }; // more specific label: 'Upcoming'
  bidWriterAccountId?: { label: any; value: any } | string;
  bidYear: { label: any; value: any };
  broadcastDate: string;
  broadcastDateTime: { value: string; label: string };
  broadcastDateTimeZone: { value: string; label: string }; //TODO: Create SelectBox type for items like this;
  displayBudgetToSuppliers: boolean;
  bidAmtRequired: boolean;
  supplementalDocRequired: boolean;
  dueDate: string | Date;
  dueTime: { value: string; label: string; key?: any };
  dueTimeZone: { value: string; label: string; key?: any }; //PM was used as an example, but PM is not a time zone? get more specific?
  eBidding: 'yes' | 'no'; // TODO: Make this a boolean;
  memberBidType?: { label: any; value: any } | string;
  memberId?: number;
  openedText: string;
  projectEstimatedBudget: string;
  scopeOfWork: string;
};

export type BidType = Selectable<string> & {
  bidType: string; // TODO: Convert to enum
  bidTypeDesc: string; // TODO: Convert to enum
  mi: number;
};
export type bidStatusListParamType = Record<string, unknown>;

export type activeprogramsType = {
  programId: number;
  programName: string;
  programDescription: string;
  label: string;
  value: number;
};
export type supplimentalSuppliersItemParamType = {
  companyName: string;
  supplierName: string;
  contactName: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  mi: number;
};

export type bidresponseitemsParamType = {
  BidResponseItemId: number;
  Description: string;
};

export type emitTypes = { (arg0: number): void; (input: unknown): void };

export type addbidpayloadTypes = { payload: Record<string, unknown> | undefined };

export type LatestWizardName = 'Supplemental Suppliers' | 'Manage Documents';

export type resultsTypes = {
  bcs: LatestWizardName; // TODO: What does BCS stand for?
  bidExternalStatusType: BidExternalStatusType;
  eBidding: string;
  commodities: any[];
  filteredStates: any;
  filteredCities: any;
  filteredDeclarations: any;
  filteredPrograms: any;
  fiscalYear: string | any[];
  broadcastDate: string;
  bidName: string;
  bidBond: string;
  displayBudgetToSuppliers: string;
  projectEstimatedBudget: number;
  scopeOfWork: string;
  bidStatusText: string;
  dueDate: string;
  bidNumber: string;
  bidExternalStatus: string;
  bidType: string;
  bidWriterAccountID: string;
  pbcMemo: string;
  blueprintStatusType: string;
  distributedBy: string;
  fulfillmentEmail: string;
  cost: number;
  distributionNotes: string;
  tse: string;
  tzfn: DeprecatedFullTimeZone;
  broadcastDatetzfn: DeprecatedFullTimeZone;
  bidAmtOptional: boolean;
  supplementalDocOptional: boolean;
  bidWriter: string;
};

export type docTypes = {
  id: any;
  bidDocId: any;
  status: any;
  statusType: any;
  uploadprocess: string;
  requireConversion: any;
  docconversion: boolean;
  procProgress: number;
  isDocumentProgress: boolean;
};

export type addsuppulimentalTypes = {
  payload: {
    id: string | number;
    type: string;
    existingsupplier: boolean;
    existsupplierdetails?: {
      mi: string;
      supplierName: string;
      email: string;
      contactName: string;
      phone: string;
      city: string;
      sti: string;
      ci: string;
      state: string;
      us: string;
    };
    allowDuplicate: boolean;
    data: any;
    reBroadcast: boolean;
  };
};

export type BidDocumentResult = {
  fileName: string;
  type: DocTypeLabel;
  path: string;
  bidDocID: number;
  bidID: number;
  docType: DocTypeValue;
  isExist: boolean;
  statusType: DocStatus;
  originalFileName: string;
};

export type planHoldersType = {
  value: string | number;
  mi: number;
  label: string;
  supplierName: string;
  isSelect: boolean;
};

export type updateBidDocumentTitleType = {
  bidDocID: number | '';
  bidID: number | '';
  docFormat?: string
  documentTitle: string;
  oldDocumentTitle?: string;
  statusType: DocStatus;
};
