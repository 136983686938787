import { useRecoilValueLoadable } from 'recoil';

import { DSEmail } from '@demandstar/components/email/DSEmail';
import { DSPhone } from '@demandstar/components/phone/DSPhone';

import { hasValue, isValidEmailInfo } from '../../../../../utils/helpers';
import { MessagePreviewRow, Section } from './../../styled/AwardBidStyled';
import { AwardWizardId } from '../../../../../store/recoil/award-bid';
import { BidEmailPreview } from './../../notify-pages/BidEmailPreview';
import { CustomMessageType } from '../../../../../types/awardBid';
import { EditEmailInfo } from './../EditEmailInfo';
import { Label } from '../../../../common/controls/text/Label';
import { LoadableWrapper } from '../../../../../components/common/loading/LoadableWrapper';
import { MessagePreview } from './../MessagePreview';
import { NotificationRecipients } from './../NotificationRecipients';
import { notifyAwardeesState } from '../../../../../store/recoil/award-bid';
import { notifyAwardeeTexts } from '../../../../../utils/texts/agency/awardBid';
import { ReviewDocsText } from './ReviewDocsText';
import { useAwardBidWizard } from '../../../../../shared/hooks/award-bid';
import { useBid } from 'src/shared/hooks/useBid';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { WizardButtons } from '../../../../customcontrols/wizard/WizardButtons';

export const NotifyAwardees = () => {
  const { saveAwardBidWizard, returnToBidSummary, messagePreview } = useAwardBidWizard();

  const emailInfo = useRecoilValueLoadable(notifyAwardeesState);

  const { bid } = useBid();
  const { bidAwardees } = useBidAwardees();

  const shouldPreview = !messagePreview && hasValue(emailInfo) && emailInfo.contents.shouldNotify;

  function onPrevious() {
    if (messagePreview) {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees);
    } else {
      saveAwardBidWizard(AwardWizardId.AddPublicDocs);
    }
  }

  function onNext() {
    if (shouldPreview) {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees, true);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyUnselected);
    }
  }

  return (
    <>
      <LoadableWrapper loadable={emailInfo}>
        {hasValue(emailInfo) && (
          <>
            {messagePreview ? (
              <MessagePreview
                to={emailInfo.contents.recipients}
                subject={emailInfo.contents.subject}
              >
                <BidEmailPreview
                  emailInfo={emailInfo.contents}
                  addressee={
                    bidAwardees.length === 1 ? bidAwardees[0].supplierName : '{{COMPANY_NAME}}'
                  }
                />
                <br />
                <p>{notifyAwardeeTexts.copy.ifYouHaveQuestions}</p>
                <Label>{emailInfo.contents.contactName}</Label>
                <MessagePreviewRow>
                  <Label>{notifyAwardeeTexts.labels.contactEmail}:</Label>
                  <DSEmail>{emailInfo.contents.contactEmail}</DSEmail>
                </MessagePreviewRow>
                <MessagePreviewRow>
                  <Label>{notifyAwardeeTexts.labels.phoneNumber}:</Label>
                  <DSPhone>{emailInfo.contents.contactPhone}</DSPhone>
                </MessagePreviewRow>
                <MessagePreviewRow>
                  <Label>{notifyAwardeeTexts.labels.jobTitle}:</Label>
                  <span>{emailInfo.contents.contactTitle}</span>
                </MessagePreviewRow>
                {bid && (
                  <Section>
                    <ReviewDocsText />
                  </Section>
                )}
              </MessagePreview>
            ) : (
              <EditEmailInfo
                emailInfoState={notifyAwardeesState}
                includeContactInfo={true}
                customMessageType={CustomMessageType.Awardees}
              >
                <NotificationRecipients recipients={emailInfo.contents.recipients} />
              </EditEmailInfo>
            )}
          </>
        )}
      </LoadableWrapper>

      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={shouldPreview ? 'Preview' : undefined}
        save={returnToBidSummary}
        inactiveNext={
          !hasValue(emailInfo) || !isValidEmailInfo(emailInfo.contents, CustomMessageType.Awardees)
        }
      ></WizardButtons>
    </>
  );
};
