import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { DSCheckbox } from '../..';
import { Inactive } from '../../types';
import { RelativeContainer } from '../../assets/CommonStyles';

const InnerCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 0rem;
`;

type NAWrapperProps = PropsWithChildren<{
  /** required unique name */
  name: string;
  /** optional notApplicable boolean */
  notApplicable: boolean;
  /** function which adds N/A button and controls what happens onClick */
  onNAClick?: () => void;
}> &
  Required<Inactive>;

/** Container to allow a N/A checkbox.
 * @example
 * <NAWrapper
 *  name={name}
 *  notApplicable={notApplicable}
 *  onNAClick={allowNA ? toggleNA : undefined}
 *  inactive={!!inactive}
 * >
 *    <React.Component/>
 * </NAWrapper>
 * */
export const NAWrapper = (props: NAWrapperProps) => {
  const { children, inactive, name, notApplicable, onNAClick } = props;

  if (!onNAClick) {
    return <>{children}</>;
  }

  return (
    <RelativeContainer>
      {children}
      {onNAClick && (
        <InnerCheckbox>
          <DSCheckbox
            checked={!!notApplicable}
            inactive={inactive}
            label='N/A'
            onClick={onNAClick}
            name={`${name}-na`}
            inlineWithInput
          />
        </InnerCheckbox>
      )}
    </RelativeContainer>
  );
};
