import { memo, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Formik } from 'formik';
import { Table } from 'reactstrap';

import { fileUploadMaxSize } from '@demandstar/components/constants/document';

import { Buttons, DeprecatedInput } from '../../customcontrols';
import { generateKey, getFileExtension, SortingFn, toastFn } from '../../../utils/helpers';

import AddQuotesControl from '../breadcrumb/buyer/addquotescontrol';
import DocumentFileTypeError from '../../common/documentfiletypeerror';
import Formbuttons from './formbuttons';
import NoResult from '../../customcontrols/noresult';

import { AcceptedFileFormatsModal } from '../AcceptedFileFormatsModal';
import { ActionColor } from '../../../shared/styles';
import { addquoteParamType } from '../../../types/addquote';
import { DeleteDocumentModal } from '../createbid/modals/DeleteDocumentModal';
import { fileUploadSizeError } from 'src/utils/constants/document';
import { loadingMsg } from '../../../utils/texts';
import { sharedParamType } from '../../../types/commoditycodes';
import { sortDirections } from '../../../utils/constants';
import { useForceUpdate } from '../../../utils/helperHooks';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardPage } from '../../../types/wizard';
import { useDSSelector } from 'src/store/reducers';
import { getaccountinforequiredDocs } from 'src/store/actions';
import { useDispatch } from 'react-redux';

const addquotedocumentuploadToastID = 'da6f43de-9025-46ae-a2cf-2fbd757472f8';

const divStyle = {
  td1: {
    width: '25%',
  },
  td2: {
    width: '10%',
  },
};

interface PropsTypes {
  handleSubmit?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  currentwizard?: WizardPage;
  setAddQuotesDetails?: any;
  bidsResponseDocumentDownload?: any;
  addquotes?: addquoteParamType;
  quotesId?: number | string;
  currentQuotesName?: string;
  submitAddQuotesDocumentUpload: any;
  getAddQuotesDocuments?: any;
  submitAddQuotesMoveNext?: any;
  shared?: sharedParamType;
  getaccountinforequiredDocs?: any;
  accountinfo: any;
}

function QDocumentUpload(props: PropsTypes) {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [docvalidation, setDocvalidation] = useState(false);

  const {
    valid,
    pristine,
    submitting,
    currentwizard,
    setAddQuotesDetails,
    bidsResponseDocumentDownload,
    addquotes,
    quotesId,
    currentQuotesName,
    submitAddQuotesDocumentUpload,
    getAddQuotesDocuments,
    submitAddQuotesMoveNext,
    shared,
  } = props;

  const { /* documentslist = [], */ upload_progress = false, deletedocumentmodal = false } =
    addquotes || {};
  let { documentslist = [] } = addquotes || {};
  const { internalLoader } = shared || {};

  useEffect(() => {
    if (quotesId) getAddQuotesDocuments({ quoteId: quotesId });
  }, [getAddQuotesDocuments, quotesId]);

  useEffect(() => {
    return () => {
      setAddQuotesDetails({ halfWayCancel: false });
    };
  }, [setAddQuotesDetails]);
  useEffect(() => {
    dispatch(getaccountinforequiredDocs()); //For updating docAllowedExt
  }, [dispatch]);

  useSetBreadcrumb({
    component: (
      <AddQuotesControl
        valid={valid}
        pristine={pristine}
        submitting={submitting}
        quotesId={quotesId}
      />
    ),
    page: currentQuotesName ? 'Agency Quote Update' : 'Agency Quote Creation',
    title: currentQuotesName
      ? `Edit Quote - ${currentwizard && currentwizard.name}`
      : `Quote Creation - ${currentwizard && currentwizard.name}`,
    altname: currentQuotesName
      ? `Edit Quote - ${currentwizard && currentwizard.name}`
      : `Quote Creation - ${currentwizard && currentwizard.name}`,
    name: currentQuotesName ? currentQuotesName : 'Quote Creation',
    id: quotesId,
    menuactive: 'quotes',
  });

  const DocAllowedExt = useDSSelector(state => state.accountinfo.agencydocs.DocAllowedExt);
  const [docError, setDocError] = useState([] as any);
  const [FileTypeModalVisible, setFileTypeModalVisible] = useState(false);
  const onChange = (index: number, name: string, value: any) => {
    if (upload_progress) return;
    const currentitem = documentslist.find((item, indexx) => indexx === index) as any;
    if (name === 'docfile') {
      if (value) {
        const docErrArr = [] as any;
        if (value.size > fileUploadMaxSize) {
          return toastFn('error', fileUploadSizeError, addquotedocumentuploadToastID);
        } else {
          const docextension = value ? getFileExtension([value]) : '';
          if (!(DocAllowedExt as string[]).includes(docextension.toLowerCase())) {
            const errorExist = docError.filter((docEr: any) => docEr.row === index);
            if (errorExist.length) {
              errorExist[0].error = `${getFileExtension([value])} is not a supported file type.`;
              setDocError(errorExist.concat(docError));
            } else {
              docErrArr.push({
                row: index,
                error: `${getFileExtension([value])} is not a supported file type.`,
                errortype: 'format',
              });
              setDocError(docErrArr.concat(docError));
            }
            // return toastFn('error', 'Document Type Not Allowed', addquotedocumentuploadToastID);
          } else {
            currentitem.docext = docextension;
            currentitem[name] = value;
            documentslist[index] = currentitem;
            setAddQuotesDetails({ documentslist, halfWayCancel: true });
            const filterError = docError.filter((docEr: any) => docEr.row !== index);
            setDocError(filterError);
          }
        }
      } else {
        currentitem.docext = '';
        currentitem[name] = value;
        documentslist[index] = currentitem;
        setAddQuotesDetails({ documentslist, halfWayCancel: true });
      }
    } else {
      currentitem[name] = value;
      documentslist[index] = currentitem;
      setAddQuotesDetails({ documentslist, halfWayCancel: true });
      forceUpdate();
    }
    if (docvalidation) {
      setDocvalidation(false);
    }
    setTimeout(() => {
      forceUpdate();
    }, 100);
  };

  const SetDeleteDocument = (item: any) => {
    setAddQuotesDetails({
      deletedocumentmodal: !deletedocumentmodal,
      deleteDocumentDetails: { ...item, quotesId },
    });
  };

  const DeleteDocuments = (indexx: number) => {
    setAddQuotesDetails({ documentslist: documentslist.filter((item, index) => index !== indexx) });
    const filterError = docError.filter((docEr: any) => docEr.row !== indexx);
    setDocError(filterError);
    if (docvalidation) {
      setDocvalidation(false);
    }
  };

  const UploadDocumentsFn = () => {
    const list = documentslist.filter(item => item.quoteDocId === '');
    if (list.length > 0) {
      const checkvalidation = list.filter(item => !item.doctitle.trim() || !item.docfile);
      if (checkvalidation.length === 0) {
        submitAddQuotesDocumentUpload({ quoteId: quotesId });
        if (docvalidation) {
          setDocvalidation(false);
        }
      } else {
        setDocvalidation(true);
        toastFn('error', 'Please fill all required fields', addquotedocumentuploadToastID);
      }
    } else {
      toastFn('error', 'No document is available to upload', addquotedocumentuploadToastID);
    }
  };

  const ShowCircular = (item: any) => {
    if (item.uploadprocess === 'notstarted' || item.uploadprocess === 'started') {
      if (item.uploadprocess !== 'completed') {
        if (item.progress < 100) {
          setTimeout(() => {
            forceUpdate();
          }, 500);
        }
      }
    }

    if (item.uploadprocess === 'completed' || item.uploadprocess === 'notstarted') {
      return null;
    }

    return (
      <div style={{ width: '40px' }}>
        <CircularProgressbar
          styles={{
            text: {
              fill: ActionColor.primary,
              fontSize: '28px',
            },
          }}
          value={item.progress}
          text={`${item.progress}%`}
        />
      </div>
    );
  };

  const uploadblelist = documentslist.filter(item => item.quoteDocId === '') || [];

  const AddNewDocumentRow = () => {
    const list = documentslist
      .filter(item => item.quoteDocId === '')
      .filter(item => !item.docfile || !item.doctitle.trim());
    if (list.length > 0) {
      setDocvalidation(true);
      toastFn('error', 'Please Complete Previous', addquotedocumentuploadToastID);
    } else {
      const newlist = documentslist as any;
      newlist.push({
        doctitle: '',
        docfile: '',
        uploadprocess: 'notstarted',
        quoteDocId: '',
        docext: '',
        progress: 0,
        quoteId: '',
        doctype: 'QD',
        id: generateKey('qdoc'),
        isExist: false,
      });
      setAddQuotesDetails({ documentslist: newlist, halfWayCancel: true });
      forceUpdate();
    }
  };

  const onSubmitFn = (data: any) => {
    if (data) {
      submitAddQuotesMoveNext({ id: quotesId, type: 'Quote', quoteStep: 3 });
    }
  };

  documentslist =
    documentslist.length > 0
      ? SortingFn(documentslist, sortDirections.DESC, 'modifiedDate')
      : documentslist;

  return (
    <>
      <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              {upload_progress ? (
                <span
                  title='Add Document'
                  className='bttn-secondary small float-right cursorNotAllowed'
                >
                  <i className='mdi mdi-plus mr-1' />
                  Add Document
                </span>
              ) : (
                <span
                  title='Add Document'
                  onClick={AddNewDocumentRow}
                  className='bttn-secondary small float-right'
                >
                  <i className='mdi mdi-plus mr-1' />
                  Add Document
                </span>
              )}
              <Table className='tableHData'>
                <tr>
                  <th style={divStyle.td1}>Title</th>
                  <th style={divStyle.td2}></th>
                </tr>
                <tbody>
                  {documentslist.length > 0 ? (
                    <>
                      {documentslist.map((item, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              {item.quoteDocId === '' ? (
                                <>
                                  <DeprecatedInput
                                    label='Document Title'
                                    name='doctitle'
                                    type='text'
                                    classNames='class'
                                    value={item.doctitle}
                                    placeholder='Document Title'
                                    handleChange={(name: string, value: any) =>
                                      onChange(index, name, value)
                                    }
                                    meta={{
                                      touched: docvalidation && !item.doctitle,
                                      error: 'Required',
                                    }}
                                    parentClass='d-inline-block mr-2'
                                    title='File upload'
                                    maxLength={100}
                                  />
                                  {item.docfile && item.docfile.name ? (
                                    <span
                                      title='Download'
                                      className='staticLink ml-2'
                                      onClick={() => onChange(index, 'docfile', '')}
                                    >
                                      {item.docfile.name}
                                      <i
                                        className='mdi mdi-close-box-outline mdi-24px ml-1'
                                        title='Cancel/Replace File'
                                      />
                                    </span>
                                  ) : (
                                    <DeprecatedInput
                                      label='Choose File'
                                      type='file'
                                      name='docfile'
                                      handleChange={(name: string, value: any) =>
                                        onChange(index, name, value && value[0])
                                      }
                                      meta={{
                                        touched: docvalidation && !item.docfile,
                                        error: 'Required',
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {item.quoteDocId ? (
                                    <span
                                      title='Download'
                                      className='staticLink'
                                      onClick={e => {
                                        e.preventDefault();
                                        bidsResponseDocumentDownload({
                                          docId: item.quoteDocId,
                                          id: quotesId,
                                          type: 'Quote',
                                        });
                                      }}
                                    >
                                      {item.doctitle}
                                      {item.docFormat && '.'}
                                      {item.docFormat && item.docFormat.toLowerCase()}
                                    </span>
                                  ) : (
                                    <>
                                      {item.doctitle}
                                      {item.docFormat ? '.' : null}
                                      {item.docFormat && item.docFormat.toLowerCase()}
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              {item.uploadprocess === 'started' ? null : (
                                <>
                                  {upload_progress ? (
                                    <span className='mdi mdi-delete-forever-outline mdi-24px cursorNotAllowed ml-3 float-right' />
                                  ) : (
                                    <>
                                      {item.quoteDocId === '' ? (
                                        <span
                                          className='mdi mdi-delete-forever-outline mdi-24px staticLink ml-3 float-right'
                                          title='Delete'
                                          onClick={() => DeleteDocuments(index)}
                                        />
                                      ) : (
                                        <>
                                          <span
                                            className='mdi mdi-delete-forever-outline mdi-24px staticLink ml-3 float-right'
                                            title='Delete'
                                            onClick={() => SetDeleteDocument(item)}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {upload_progress ? ShowCircular(item) : null}
                            </td>
                          </tr>
                          {docError.length ? (
                            <DocumentFileTypeError
                              documentTypeError={docError.find((docEr: any) => docEr.row === index)}
                              setFileTypeModalVisible={(status: boolean) =>
                                setFileTypeModalVisible(status)
                              }
                            />
                          ) : null}
                        </>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <NoResult
                          message={internalLoader ? loadingMsg : 'No Documents Available'}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className='clearfix'>
                <Buttons
                  classNames='bttn-primary float-right'
                  text={uploadblelist.length > 1 ? 'Upload All' : 'Upload'}
                  title={uploadblelist.length > 1 ? 'Upload All' : 'Upload'}
                  type='button'
                  onClick={UploadDocumentsFn}
                  disable={
                    documentslist.length === 0 || upload_progress || uploadblelist.length === 0
                  }
                />
              </div>
              <Formbuttons {...props} />
            </form>
          );
        }}
      </Formik>

      <DeleteDocumentModal
        closeModal={() => SetDeleteDocument('')}
        /** Do not replace with `closeModal={toggleDeleteDocModal}`.
         * The function wants to pass in nothing instead of a ClickEvent */
        isOpen={deletedocumentmodal}
        type='quote'
      />

      <AcceptedFileFormatsModal
        isOpen={FileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      />
    </>
  );
}

export default memo(QDocumentUpload);
