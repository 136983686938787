import { useEffect } from 'react';

import {
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  MemberType,
  Pages,
} from '../../utils/constants';

import Configurations from 'src/settings';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useRouter } from 'src/shared/hooks';

export function PageNotFound() {
  const { history } = useRouter();
  const { location } = history;

  const { auth, token } = useAuthContext();

  // Determine landing page per user type
  const path =
    auth?.memberType === MemberType.AgencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;

  useEffect(() => {
    // If there is a valid token and member info, navigate to landing page
    if (token && auth?.memberType && auth?.memberId) {
      history.push(path);
    } else {
      // Store the url
      sessionStorage.setItem(loginPreviousUrlName, location.pathname);
      sessionStorage.setItem(loginPreviousUrlSearch, location.search);

      const regEx = /^\/(buyers|suppliers)\/(bids)\/[0-9]+/;
      // navigate to bid view if a bidId exists.
      if (regEx.test(location.pathname)) {
        const limitedBidId = location.pathname.split('/')[3];
        window.location.href = `/app/limited/bids/${limitedBidId}/details`;
      } else {
        // Navigate to the login
        window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
      }
    }
  }, [auth, history, location.pathname, location.search, path, token]);

  return null;
}
