import { RecoilState, useRecoilValueLoadable } from 'recoil';
import { getLoadableContents } from '@demandstar/components/utils';

/**
 * Helper fn to save a step for returning contents from a RecoilState object.
 * @description For now, this assumes `RecoilLoadable<T>.state` = `hasValue`.
 * @param recoilState
 * @todo Handle errors and loading
 * @returns {T} typed or optional default value
 */
export function useRecoilLoadableContents<T>(recoilState: RecoilState<T>) {
  return getLoadableContents(useRecoilValueLoadable(recoilState));
}
