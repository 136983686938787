import React, { memo } from 'react';
import { CheckBox } from '../../customcontrols';
import NoResult from '../../customcontrols/noresult';
import { loadingMsg } from '../../../utils/texts';
import { commodityItemsParamType, commodityParamType } from '../../../types/commoditycodes';

interface CommoditySearchProps {
  SetSelectedCommodity: (value: boolean, items: commodityItemsParamType, type: string) => void;
  setLocalCommodity?: any;
  commoditycodes?: commodityParamType;
  pagefor?: string;
  shared?: any;
}

function CommoditySearch(props: CommoditySearchProps) {
  const { SetSelectedCommodity, setLocalCommodity, commoditycodes, pagefor, shared } = props;
  const { searchedCommodityCodes = [] } = commoditycodes || {};
  const { internalLoader } = shared || {};

  return (
    <>
      <h4>Commodity Codes Search Result</h4>
      {searchedCommodityCodes.length > 0 ? (
        <>
          {searchedCommodityCodes.map((code, index) => (
            <div className='quotes-details' key={index}>
              <h6>
                <i>
                  <CheckBox
                    disabled={code.commodityCategory === '000'}
                    disabledText={
                      'Top level commodity codes cannot be added. Try searching for ' +
                      code.commodityGroup +
                      '.'
                    }
                    handleChecked={(name: string, value: any) => {
                      if (
                        pagefor === 'addbid' &&
                        code.commodityCode === '00' &&
                        code.isSelected !== true
                      ) {
                        setLocalCommodity({
                          value: value,
                          category: code,
                          name:
                            code.commodityCode === '00' ? 'commodityCategory' : 'commodityCodes',
                          confirmmodal: true,
                        });
                      } else {
                        SetSelectedCommodity(
                          value,
                          code,
                          code.commodityCode === '00' ? 'commodityCategory' : 'commodityCodes',
                        );
                      }
                    }}
                    checked={code.isSelected}
                  />
                </i>
                {code.commodityDescription} [{code.formattedCode}]
              </h6>
            </div>
          ))}
        </>
      ) : (
        <div className='innerColIndent'>
          <NoResult message={internalLoader ? loadingMsg : 'No Commodities Available'} />
        </div>
      )}
    </>
  );
}

export default memo(CommoditySearch);
