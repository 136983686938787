import { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { smoothScrollTo, useDebouncedState } from '@demandstar/components/utils';
import { DSAccordion } from '@demandstar/components/accordion';
import { DSAlert } from '@demandstar/components/alert';
import { DSSort } from '@demandstar/components/sort';
import { Status } from '@demandstar/components/constants';

import * as texts from './Reminders.texts';
import { Alert } from 'src/components/common/alert';
import { ContractReminder } from './hook/reminders';
import { ReminderCard } from './ReminderCard';
import { ReminderPreviewToggle } from './ReminderPreviewToggle';
import { useReminders } from './hook/useReminders';
import { useReminderSearch } from './hook/useReminderSearch';

interface RemindersProps {
  canHide?: boolean;
  compact?: boolean;
  preview?: boolean;
  reminders?: ContractReminder[];
}

const previewCount = 5;

export function Reminders(props: RemindersProps) {
  const { reminderAlertId, previewVisible, reminders: defaultReminders } = useReminders();
  const { canHide = true, compact, preview, reminders = defaultReminders } = props;
  const { defaultSort, urlReminderId } = useReminderSearch();
  const [trySmoothScroll, setTrySmoothScroll] = useDebouncedState(urlReminderId ? 1 : 0);

  const [current, upcoming] = useMemo(() => {
    if (preview) {
      return [
        reminders
          .filter(
            /** limit to past & current reminders */
            r => !r.dueDate.isAfter(dayjs().endOf('day')),
          )
          .sort(
            /** we have to sort to limit to the top N reminders */
            defaultSort.sort,
          )
          .slice(
            /** take to top N reminders */
            0,
            previewCount,
          ),
        [],
      ];
    }

    return reminders.reduce(
      ([current, upcoming], reminder) => {
        return reminder.dueDate.isAfter(dayjs().endOf('day'))
          ? [current, [...upcoming, reminder]]
          : [[...current, reminder], upcoming];
      },
      [[] as ContractReminder[], [] as ContractReminder[]],
    );
  }, [defaultSort.sort, preview, reminders]);

  const renderReminders = useCallback(
    (reminders: ContractReminder[], title: string) => (
      <DSAccordion title={title} defaultValue={urlReminderId ?? undefined}>
        {reminders.map(reminder => (
          <ReminderCard key={reminder.id} reminder={reminder} compact={compact} />
        ))}
      </DSAccordion>
    ),
    [compact, urlReminderId],
  );

  const renderUpcoming = useCallback(
    (reminders: ContractReminder[]) => {
      return renderReminders(reminders, texts.upcomingReminders);
    },
    [renderReminders],
  );

  const renderCurrent = useCallback(
    (reminders: ContractReminder[]) => {
      return renderReminders(reminders, preview ? texts.reminders : texts.currentReminders);
    },
    [preview, renderReminders],
  );

  useEffect(() => {
    // Cap attempts in case an invalid ID is passed
    const maxAttempts = 10;
    if (urlReminderId && trySmoothScroll) {
      smoothScrollTo(`div[data-testid="ds-accordion-card-${urlReminderId}-header"]`);
      setTrySmoothScroll(
        document.body.scrollTop === 0 &&
          document.documentElement.scrollTop === 0 &&
          trySmoothScroll < maxAttempts
          ? trySmoothScroll + 1
          : 0,
      );
    }
  }, [setTrySmoothScroll, trySmoothScroll, urlReminderId]);

  return (
    <>
      {preview && previewVisible && <Alert id={reminderAlertId} allowDismiss />}
      {current.length > 0
        ? (!preview || previewVisible) && (
            <DSSort data={current} defaultSort={defaultSort} shouldPaginate={!preview}>
              {renderCurrent}
            </DSSort>
          )
        : !preview && <DSAlert header={texts.noReminders} type={Status.Success} />}

      {upcoming.length > 0 && (
        <DSSort data={upcoming} defaultSort={defaultSort} shouldPaginate>
          {renderUpcoming}
        </DSSort>
      )}

      {preview && <ReminderPreviewToggle canHide={canHide && current.length > 0} />}
    </>
  );
}
