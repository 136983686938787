import { atomFamily, selectorFamily } from 'recoil';

import { getBidPlanholderList } from 'src/store/services';
import { Planholder } from 'src/types/biddetail';
import { tryCatchLog } from 'src/utils/errors';

export const planholdersRequestIdState = atomFamily({
  key: 'planholdersRequestIdState',
  default: 0,
});

export const planholdersQuerySelector = selectorFamily<Planholder[], number | undefined>({
  key: 'planholdersQuerySelector',
  get:
    bidId =>
    async ({ get }) => {
      if (!bidId || bidId < 0) {
        return [];
      }
      get(planholdersRequestIdState(bidId)); // Add request ID as a dependency
      return tryCatchLog(async () => {
        const response = await getBidPlanholderList(bidId);
        return response;
      }, 'planholdersQuerySelector');
    },
});
