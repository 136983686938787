import { memo, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import DateRangePickerComponent from '../../../customcontrols/daterangepicker';
import history from '../../../../utils/history';
import SortingIcon from '../../../common/sortingIcon';

import { Buttons, DeprecatedInput, NoResult, Paging } from '../../../customcontrols';
import { deprecatedGetDateTime, GroupSearchingfn } from '../../../../utils/helpers';
import { pageSize, sortDirections } from '../../../../utils/constants';

import { accountinfoType } from '../../../../types/paymentdetails';
import { loadingMsg } from '../../../../utils/texts';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  bidId: {
    width: '25%',
  },
  date: {
    width: '170px',
  },
  bidName: {
    width: '200',
  },
  buyerName: {
    width: '170px',
  },
};

interface PropsTypes {
  accountinfo: accountinfoType;
  setAccountInfoDetails: any;
  setOrderFilter: any;
}

const OrdersList = (props: PropsTypes) => {
  const { accountinfo, setAccountInfoDetails, setOrderFilter } = props;
  const { orders = [], filtered_orders = [], orderFilters, orders_internalLoader } = accountinfo;

  const initialFilter = {
    bidName: '',
    buyerName: '',
    bidIdentifier: '',
    startSentDate: null,
    endSentDate: null,
    sortBy: 'sentDate',
    sortOrder: sortDirections.DESC,
  };

  useSetBreadcrumb({
    component: '',
    page: 'Payments',
    title: 'Orders',
    altname: 'Orders',
    name: 'Orders',
  });

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.bidResults;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const filterBySearchText = (name: string, value: string) => {
    setOrderFilter({ [name]: value });
  };

  /* const [sortvalue, setSortvalue] = useState('sentDate');
  const [sortorder, setSortorder] = useState(sortDirections.DESC); */

  const searchResult = () => {
    let filterResult = orders;

    if (orderFilters.startSentDate && orderFilters.endSentDate) {
      filterResult = filterResult.filter(
        (item: any) =>
          moment(item.sentDate).isSameOrAfter(orderFilters.startSentDate, 'date') &&
          moment(item.sentDate).isSameOrBefore(orderFilters.endSentDate, 'date'),
      );
    }
    filterResult = GroupSearchingfn(
      filterResult,
      ['bidIdentifier', 'bidName', 'agency'],
      [orderFilters.bidIdentifier, orderFilters.bidName, orderFilters.buyerName],
    );

    setAccountInfoDetails({ filtered_orders: filterResult });
    setCurrentPage(1);
  };

  const GoToPage = (bidId: number) => {
    history.push(`/suppliers/bids/${bidId}/details`);
  };

  const clearSearch = () => {
    setAccountInfoDetails({ orderFilters: initialFilter });
    setAccountInfoDetails({ filtered_orders: orders });
    setCurrentPage(1);
  };

  const onInputChange = (name: string, value: React.SetStateAction<moment.Moment>) => {
    setOrderFilter({ [name]: value });
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h3 className='arrowWrapper'>Orders</h3>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-12 col-lg-4'></div>
              <div className='col-12 col-lg-4'></div>
              <div className='col-12 col-lg-3'></div>
            </div>
          </div>

          <div className='col-lg-3'>
            <DeprecatedInput
              label='Bid Name'
              placeholder='Bid Name'
              name='bidName'
              value={orderFilters.bidName}
              handleChange={filterBySearchText}
              parentClass='w-49'
              maxLength={255}
              classNames='mb-2'
            />
            <DeprecatedInput
              label='Buyer Name'
              placeholder='Buyer Name'
              name='buyerName'
              value={orderFilters.buyerName}
              handleChange={filterBySearchText}
              parentClass='w-49'
              maxLength={80}
              classNames='mb-2'
            />
            <DeprecatedInput
              label='Bid Identifier'
              placeholder='Bid Identifier'
              name='bidIdentifier'
              value={orderFilters.bidIdentifier}
              handleChange={filterBySearchText}
              parentClass='w-49'
              classNames='mb-2'
            />

            <DateRangePickerComponent
              label='Date Range'
              startDatename='startSentDate'
              endDatename='endSentDate'
              handleChange={onInputChange}
              isOutsideRange={true}
              startDatevalue={orderFilters.startSentDate}
              endDatevalue={orderFilters.endSentDate}
              showClearDate={true}
              parentClass='w-49'
            />

            <div className='d-block text-right mb-2'>
              <Buttons
                classNames='bttn-primary w-100 mb-3'
                text='Search'
                title='Search'
                type='button'
                onClick={searchResult}
              />
              <Buttons
                classNames='bttn-secondary w-100'
                text='Clear'
                title='Clear'
                type='button'
                onClick={clearSearch}
              />
            </div>
          </div>

          <div className='col-lg-9'>
            {filtered_orders && filtered_orders.length > 0 ? (
              <>
                <Table className='tableFixed tableHData '>
                  <tr>
                    <th style={tdWidth.bidId}>
                      Bid Identifier
                      <SortingIcon
                        sortlist={filtered_orders}
                        initialsortorder={orderFilters.sortOrder}
                        currentsortname={orderFilters.sortBy}
                        changesortname={(value: string) =>
                          setAccountInfoDetails({
                            orderFilters: { ...orderFilters, sortBy: value },
                          })
                        }
                        sortname='bidIdentifier'
                        changeSortingFn={(list: any) =>
                          setAccountInfoDetails({ filtered_orders: filtered_orders })
                        }
                      />
                    </th>
                    <th style={tdWidth.bidName}>
                      Bid Name
                      <SortingIcon
                        sortlist={filtered_orders}
                        initialsortorder={orderFilters.sortOrder}
                        currentsortname={orderFilters.sortBy}
                        changesortname={(value: string) =>
                          setAccountInfoDetails({
                            orderFilters: { ...orderFilters, sortBy: value },
                          })
                        }
                        sortname='bidName'
                        changeSortingFn={(list: any) =>
                          setAccountInfoDetails({ filtered_orders: filtered_orders })
                        }
                      />
                    </th>
                    <th style={tdWidth.buyerName}>
                      Buyer Name
                      <SortingIcon
                        sortlist={filtered_orders}
                        initialsortorder={orderFilters.sortOrder}
                        currentsortname={orderFilters.sortBy}
                        changesortname={(value: string) =>
                          setAccountInfoDetails({
                            orderFilters: { ...orderFilters, sortBy: value },
                          })
                        }
                        sortname='agency'
                        changeSortingFn={(list: any) =>
                          setAccountInfoDetails({ filtered_orders: filtered_orders })
                        }
                      />
                    </th>
                    <th style={tdWidth.date}>
                      Order Date
                      <SortingIcon
                        sortlist={filtered_orders}
                        initialsortorder={orderFilters.sortOrder}
                        currentsortname={orderFilters.sortBy}
                        changesortname={(value: string) =>
                          setAccountInfoDetails({
                            orderFilters: { ...orderFilters, sortBy: value },
                          })
                        }
                        sortname='sentDate'
                        changeSortingFn={(list: any) =>
                          setAccountInfoDetails({ filtered_orders: filtered_orders })
                        }
                      />
                    </th>
                  </tr>
                  {filtered_orders
                    .map((order, index) => (
                      <tr key={index}>
                        <td>
                          <span
                            title={order.bidIdentifier}
                            className='staticLink'
                            onClick={() => GoToPage(order.bidId)}
                          >
                            {order.bidIdentifier}
                          </span>
                        </td>
                        <td title={order.bidName}>
                          <div className='w-95 text-truncate'>{order.bidName}</div>
                        </td>
                        <td title={order.agency}>
                          <div className='w-95 text-truncate'>{order.agency}</div>
                        </td>
                        <td>{deprecatedGetDateTime(order.sentDate)}</td>
                      </tr>
                    ))
                    .slice(indexOfFirstList, indexOfLastList)}
                </Table>
                <Paging
                  totalRecords={filtered_orders.length}
                  currentPage={currentPage}
                  onPagingClick={(value: number) => {
                    if (value !== currentPage) setCurrentPage(Number(value));
                  }}
                  pageLimit={listPerPage}
                />
              </>
            ) : (
              <NoResult message={orders_internalLoader ? loadingMsg : 'No Orders Available'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OrdersList);
