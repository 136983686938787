import {
  DueDateRowValue,
  PanelItemWrapper,
  PanelRow,
  PanelRowHeader,
  PanelRowValue,
} from '../../../shared/styles';
import { BidSummaryLabels } from '../../../shared/constants/bids';
import { commonLabels } from '../../../shared/constants';
import { displayDateTime } from '../../../utils/helpers';
import { EBidResponseFull } from 'src/types/biddetail';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { StatusPill } from '../../../components/common/controls/static/StatusPill';
import { useBid } from 'src/shared/hooks/useBid';

type BidInformationPanelProps = {
  bidId: number;
  eBidResponse?: EBidResponseFull;
};

/**
 * @warning name of component is misleading. It also displays response information.
 * TODO: Consider renaming this component and file to `BidInformationPanel`
 */
export function BidSummaryPanelView(props: BidInformationPanelProps) {
  const { bidId, eBidResponse } = props;

  const { bid, bidLoadable } = useBid(bidId);

  const responseDateDisplay =
    eBidResponse?.responseDateTime && bid?.timeZoneFullName
      ? displayDateTime(eBidResponse?.responseDateTime, bid.timeZoneFullName)
      : commonLabels.dateUnavailable;

  const bidNumberDisplay = bid?.bidNumber || eBidResponse?.bidIdentifier;

  return (
    <LoadableWrapper loadable={bidLoadable}>
      {bid && (
        <PanelItemWrapper>
          {/* Agency name */}
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.agencyName}</PanelRowHeader>
            <PanelRowValue title={bid.agencyName}>{bid.agencyName}</PanelRowValue>
          </PanelRow>
          {/* Bid number */}
          <PanelRow>
            <PanelRowHeader>{BidSummaryLabels.bidNumber}</PanelRowHeader>
            <PanelRowValue title={bidNumberDisplay}>{bidNumberDisplay}</PanelRowValue>
          </PanelRow>
          {/* Bid name */}
          {bid.bidName && (
            <PanelRow>
              <PanelRowHeader>{BidSummaryLabels.bidName}</PanelRowHeader>
              <PanelRowValue title={bid.bidName}>{bid.bidName}</PanelRowValue>
            </PanelRow>
          )}
          {/* Response date */}
          {eBidResponse?.responseDateTime && (
            <PanelRow>
              <PanelRowHeader>{BidSummaryLabels.responseDate}</PanelRowHeader>
              <DueDateRowValue title={responseDateDisplay}>{responseDateDisplay}</DueDateRowValue>
            </PanelRow>
          )}
          {/* Agency state */}
          {eBidResponse?.agencyState && (
            <PanelRow>
              <PanelRowHeader>{BidSummaryLabels.state}</PanelRowHeader>
              <PanelRowValue title={eBidResponse.agencyState}>
                {eBidResponse.agencyState}
              </PanelRowValue>
            </PanelRow>
          )}
          {/* Status */}
          {eBidResponse?.responseStatus && (
            <PanelRow>
              <PanelRowHeader>{BidSummaryLabels.status}</PanelRowHeader>
              <PanelRowValue title={eBidResponse.responseStatus}>
                <StatusPill status={eBidResponse.responseStatus} />
              </PanelRowValue>
            </PanelRow>
          )}
        </PanelItemWrapper>
      )}
    </LoadableWrapper>
  );
}
