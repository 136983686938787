import { css, CSSObject } from 'styled-components';

export enum MediaQueryMinWidths {
  Small = 36,
  Medium = 48,
  Large = 62,
  ExtraLarge = 75,
}

export type MediaQueryMinWidth = keyof typeof MediaQueryMinWidths;

export const mediaQueries = (key: MediaQueryMinWidth) => {
  return (style: TemplateStringsArray | CSSObject) =>
    `@media (max-width: ${MediaQueryMinWidths[key]}rem) { ${css(style)} }`;
};
