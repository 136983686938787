import { PropsWithChildren } from 'react';

import { LayoutType } from '../constants';

import { DSPageLayoutStyled } from './DSPageLayoutStyled';

type DSPageLayoutProps = PropsWithChildren<{
  /** layout used for the page */
  layout?: LayoutType;
}>;

/**
 * @description PageLayout provides the page wrapper, including desired margins and padding for a component on a page
 */
export const DSPageLayout = (props: DSPageLayoutProps) => {
  const { children, layout } = props;

  switch (layout) {
    case LayoutType.Basic:
      return <DSPageLayoutStyled>{children}</DSPageLayoutStyled>;
    default:
      return <>{children}</>;
  }
};
