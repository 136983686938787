import dayjs, { Dayjs } from 'dayjs';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

import {
  convertToICANN,
  DeprecatedFullTimeZone,
  numNextFiscalYears,
  numPreviousFiscalYears,
  TimeZone,
} from '../constants';
import { Assert } from './testing';

/**
 * ! Warning: Do not add objectSupport.
 * It seems to mess with the way that dayJS accepts a dayJS object,
 * and we use that functionality.
 */

/**
 * ! TODO: Determine a solution for how best to extend these plugins.
 * Basically, we want these add-ons to work on their own in our (one day external) library
 * We also want dayJS logic to work outside of the library.
 * Solution may involve having a local file and library file which contain these plugins.
 * */
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(isBetween);

dayjs.updateLocale('en', {
  formats: {
    // abbreviated format options allowing localization
    LTS: 'h:mm:ssa',
    LT: 'h:mma',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mma',
    LLLL: 'dddd, MMMM D, YYYY h:mma',
    // lowercase/short, optional formats for localization
    l: 'M/D/YYYY',
    ll: 'MMM D, YYYY',
    lll: 'MMM D, YYYY h:mma',
    llll: 'ddd, MMM D, YYYY h:mma',
  },
});

export enum DateFormat {
  Base = 'll',
  DateTime = 'lll',
  DateTimeWithZone = 'lll (z)',
  Url = 'MM-DD-YYYY',
  IsoUtc = 'YYYY-MM-DDTHH:mm:ss[Z]',
}

function isDeprecatedTimeZone(timeZone?: string): timeZone is DeprecatedFullTimeZone {
  return !!(timeZone && Object.keys(convertToICANN).includes(timeZone));
}

export function safeTimeZone(timeZone?: string) {
  if (isDeprecatedTimeZone(timeZone)) {
    return convertToICANN[timeZone];
  }
  return timeZone || dayjs.tz.guess();
}

/**
 * @param date - any date format that DayJS can convert
 * @param timeZone - deprecated time zone. To be replaced with ICANN timezone.
 * @param format - optional format
 * @returns date formatted 'MMM D, YYYY' (in America)
 * @example displayDate('2021-07-06T15:47:46Z') // Jul 6, 2021
 */
export function displayDate(date: dayjs.ConfigType, timeZone?: string, format = DateFormat.Base) {
  timeZone = safeTimeZone(timeZone);
  const dateTimeUTC = dayjs.utc(date);
  const dateTimeWithZone = dayjs.tz(dateTimeUTC, timeZone);
  return dateTimeUTC.isValid() ? dateTimeWithZone.format(format) : '';
}

/**
 * @param date - any date format that DayJS can convert
 * @param timeZone - deprecated time zone. To be replaced with ICANN timezone.
 * @param format - optional format
 * @returns date formatted 'MMM D, YYYY h:mma' (in America)
 * @example displayDateTime('2021-07-06T15:47:46Z', TimeZone.Pacific) // Jul 6, 2021 3:47pm (PDT)
 */
export function displayDateTime(
  date: dayjs.ConfigType,
  timeZone: string,
  format = DateFormat.DateTimeWithZone,
) {
  timeZone = safeTimeZone(timeZone);
  const dateTimeUTC = dayjs.utc(date);
  const dateTimeWithZone = dayjs.tz(dateTimeUTC, timeZone);
  return dateTimeUTC.isValid() ? dateTimeWithZone.format(format) : '';
}

/**
 * @param format - optional format
 * @returns today's date in the default date format
 * @example displayToday() // Jul 8, 2021
 */
export function displayToday(format = DateFormat.Base) {
  return dayjs().format(format);
}

/**
 * @returns right now in ISO format with UTC offset
 * @example nowISO() // 2020-04-02T08:02:17+00:00
 */
export function nowISO() {
  const now = dayjs.utc();
  Assert(now.utcOffset() === 0, 'The offset should be 0.');
  return now.format(DateFormat.IsoUtc);
}

/**
 * @param date - any date format that DayJS can convert
 * @param format - optional format
 * @returns ISO formatted date
 * @example formatAsUTC('2021-07-04T21:00:00-07:00') // '2021-07-05T04:00:00+00:00'
 */
export function formatAsUTC(date: dayjs.ConfigType, format?: DateFormat) {
  return dayjs(date).isValid() ? dayjs.tz(date, TimeZone.UTC).format(format) : '';
}

/** gets the current year
 * @returns number
 * @example currentYear() // 2021
 */
export function currentYear() {
  return dayjs().year();
}

export function isTodayOrEarlier(date: Dayjs) {
  return date && date < dayjs().endOf('day');
}

/** simple compare function for DSDateField comparisons.
 * Can be passed as-is to DSDateField to disable all days before today.
 * Or can be used in a function with a specific compareDate.
 * @example disableDate={anyDateBefore}
 * @example function beforeRemindDate(date: DayJs){
 *  return anyDateBefore(date, remindDate);
 * }
 */
export function anyDateBefore(date: Dayjs, compareDate?: Dayjs): boolean {
  return date && date < dayjs(compareDate).startOf('day');
}

/**
 * @param target - any date format that DayJS can convert, the target date
 * @param start - optional start date
 * @returns a string in the format 'in X days'
 * @example getRelativeDate(dayjs().add(3, 'day')) // 'in 3 days'
 */
export function getRelativeDate(target: dayjs.ConfigType, start?: dayjs.ConfigType) {
  return start ? dayjs(target).to(dayjs(start)) : dayjs(target).fromNow();
}
/**
 * @returns an array of years, from two years previous to four years in the future.
 */
export function getFiscalYearRange(): number[] {
  const years: number[] = [];
  for (
    let i = currentYear() - numPreviousFiscalYears;
    i <= currentYear() + numNextFiscalYears;
    i++
  ) {
    years.push(i);
  }
  return years;
}
