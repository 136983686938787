import { pluralize } from 'src/utils/helpers';

export { reminders } from './ContractReminders.texts';

export const currentReminders = 'Current Reminders';
export const hideReminders = 'Hide these reminders';

export const formLabels = {
  date: 'Sort by date',
  alpha: 'Sort alphabetically',
  status: 'Filter by status',
  contract: 'Filter by contract',
  member: 'Who is this reminder for?',
  upcoming: 'See upcoming reminders',
} as const;

export const noReminders = 'Nothing to do today!';
export const showReminders = 'Show your reminders';
export const upcomingReminders = 'Upcoming Reminders';
export const viewAllReminders = 'View all >';
/** @returns 'You have {reminderCt} reminder(s).' */
export const youHave = (reminderCt: number) =>
  `You have ${reminderCt} ${pluralize(reminderCt, 'reminder', 'reminders')}.`;
