import { BaseDSLinkProps, DSLinkProps } from '../link/DSLink';
import { BaseJumpToList, BaseJumpToListItem, BaseJumpToSection } from './styles';
import { DSLink } from '../link';
import { H5 } from '../styles';
import { smoothScrollTo } from '../utils';

type ScrollJumpItem = {
  display: string;
  scrollTo: string;
  onClick?: never;
  href?: never;
  newTab?: never;
} & Omit<BaseDSLinkProps, 'children'>;

type LinkJumpItem = {
  display: string;
  scrollTo?: never;
} & Omit<DSLinkProps, 'children'>;

type JumpToItem = ScrollJumpItem | LinkJumpItem;

export type JumpToSection = {
  items: JumpToItem[];
};

interface DSJumpToProps {
  title?: string;
  top?: string;
  sections: JumpToSection | JumpToSection[];
}

const DSJumpToLink = (item: JumpToItem) => {
  const onClick = item.scrollTo ? () => smoothScrollTo(item.scrollTo) : item.onClick;

  if (onClick !== undefined) return <DSLink onClick={onClick}>{item.display}</DSLink>;
  else if (item.href !== undefined) return <DSLink href={item.href}>{item.display}</DSLink>;
  else return <></>;
};

/**
 * @description Displays a full height "Jump To" list of links that scrolls with page
 * @default { title: 'Jump to:' }
 * @example <DSJumpTo sections={[{ items: [ // JumpToItem[]
 *                          {
 *                              display: 'First Section 1', // Text displayed
 *                              to: '#first1', // Links to
 *                          },
 *                          {
 *                              display: 'First Section 2',
 *                              to: '#first2',
 *                          },
 *                       ]}, // Sections have spacing between them
 *                       { items: [
 *                          {
 *                              display: 'Second Section 1',
 *                              to: '/other/page',
 *                          },
 *                          {
 *                              display: 'Second Section 2',
 *                              to: '/another/page',
 *                          },
 *                       ]}]} />
 * @param {string=} title - overrides the default title on the Jump To section (default: 'Jump to:')
 * @param {string=} top - overrides the default 'top' CSS attr, default is set to work optimally w/ standard header
 * @param { JumpToSection | JumpToSection[] } sections - required, defines the sections & items to display
 */
export const DSJumpTo: React.FunctionComponent<DSJumpToProps> = ({
  title = 'Jump to:',
  top = '8.75rem',
  sections,
}: DSJumpToProps) => {
  let childElements: React.ReactNode;

  if ('items' in sections) {
    childElements = sections.items.map(c => {
      return (
        <BaseJumpToListItem key={c.display}>
          <DSJumpToLink {...c} />
        </BaseJumpToListItem>
      );
    });
  } else {
    const newItems: JSX.Element[] = [];

    sections.forEach(s => {
      s.items.forEach(c => {
        newItems.push(
          <BaseJumpToListItem key={c.display} lastInSection={s.items[s.items.length - 1] === c}>
            <DSJumpToLink {...c} />
          </BaseJumpToListItem>,
        );
      });
    });

    childElements = newItems;
  }

  return (
    <BaseJumpToSection top={top}>
      <H5>{title}</H5>
      <BaseJumpToList>{childElements}</BaseJumpToList>
    </BaseJumpToSection>
  );
};
