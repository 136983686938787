import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'
import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function MapingCertificate() {
  return (
    <>
      <p>
        The improvements made on DemandStar 2020 allow you to much more easily manage your
        certifications across multiple agencies! To manage your certifications across different
        agencies:
      </p>

      <ol>
        <li>Log-in to your DemandStar account</li>
        <li>Click on your name in the upper right hand corner</li>
        <li>
          Click on “Account” – This screen appears:
          <img src={helpImgs.picture3} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Click on the tab for Certifications <br />
          Here you will see a list of agencies that government agencies use to track suppliers that
          meet specific certification or qualification criteria. These will all be listed here and
          you can apply for them here, by clicking on “Apply”
          <img src={helpImgs.picture4} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>Simply click on Apply and follow the prompts.</li>
      </ol>
    </>
  );
}

export default memo(MapingCertificate);
