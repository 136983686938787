import { MouseEvent, useCallback, useMemo } from 'react';

import { BaseButton, DSButtonType } from './';
import { DataTestId, Id, Inactive, OnClick, SVGIcon, Title } from '../types';
import { iconSuffix } from './constants';
import { onEnter } from '../utils';

export type DSButtonProps = DataTestId &
  Inactive &
  OnClick &
  SVGIcon &
  Title &
  Id & {
    /** HTML name attribute */
    name?: string;
    /** determines the button styling, can also serve as the HTML button type */
    type?: DSButtonType;
    /** Text content to display on the button */
    children?: string;
    /** Optional handler for if the user clicks an inactive button */
    onInactiveClick?: () => void;
    /** Inverts the styling of the button */
    contrast?: boolean;
  
    mobileView?:boolean;
  };

export function DSButton(props: DSButtonProps): JSX.Element {
  const {
    type = 'primary',
    children,
    contrast,
    dataTestId,
    icon,
    id,
    inactive,
    name,
    onClick,
    onInactiveClick,
    title = '',
    mobileView ,
  } = props;
  // Optional svg icon or style string for legacy impl.
  // TODO: assumed left abstract icon layout position.
  const iconClassName = typeof icon === 'string' ? `mr-2 mdi ${icon}` : '';
  const IconComponent = typeof icon === 'string' ? 'i' : icon;

  const htmlType = type === 'submit' || type === 'reset' ? type : 'button';
  const appearance = type === 'submit' ? 'primary' : type === 'reset' ? 'secondary' : type;

  const handleClick = useCallback(
    (event?: unknown) => {
      if (inactive && onInactiveClick) {
        onInactiveClick();
      }
      if (!inactive && onClick) {
        onClick(event as MouseEvent);
      }
    },
    [inactive, onClick, onInactiveClick],
  );

  // Build a testId value for the icon by appending the `'-icon'` suffix to the button's dataTestId value.
  const iconDataTestId = `${dataTestId}-${iconSuffix}`;
  const iconTitle = `${title} (${iconSuffix})`;

  /**
   * Duplicated in DSLink
   * Temporary fix, per previous comments:
   * For React components, we want dataTestId, but for HTML elements, we want data-testid.
        This is happening because IconComponent can be
        ComponentType<DSSVGIconProps> (which expects dataTestId)
        or
        <i> (which expects data-testid and logs an error when it gets dataTestId)
        In SVGIcon, we are passing in `dataTestId` to a styled svg component. Then we're translating that property
        to turn dataTestId into data-testid.
        Instead, we should maintain separate props for DSSVGIconProps
        and BaseSVGIconProps. The former should take dataTestId. The latter should take data-testid.
   *
   */
  const iconDisplay = useMemo(() => {
    if (IconComponent) {
      if (iconClassName) {
        return (
          <IconComponent className={iconClassName} title={iconTitle} data-testid={iconDataTestId} />
        );
      } else {
        return <IconComponent title={iconTitle} dataTestId={iconDataTestId} />;
      }
    }
    return [];
  }, [IconComponent, iconClassName, iconDataTestId, iconTitle]);

  return (
    <BaseButton
      appearance={appearance}
      contrast={contrast}
      data-testid={dataTestId}
      id={id}
      inactive={inactive}
      name={name}
      onClick={handleClick}
      title={title ?? children}
      type={htmlType}
      onKeyDown={onEnter(handleClick)}
      mobileView={mobileView}
    >
      {iconDisplay}
      {children}
    </BaseButton>
  );
}
