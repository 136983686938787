// TODO: convert main app imports to use this library declaration.
// storybook breaks when a library component imports from the main app hierarchy
export const defaultNumericId = -1;

export const appText = {
  companyName: 'DemandStar',
  companyFullName: 'DemandStar Corporation',
};

export const commonText = {
  deselect: 'Deselect',
  dismiss: 'Dismiss',
  emptyMessage: 'No records were found',
  hideNavMenu: 'Hide navigation menu',
  select: 'Select',
  showNavMenu: 'Show navigation menu',
  signIn: 'Sign In',
  signUp: 'Sign Up',
};
