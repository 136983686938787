import { selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import {
  NewRequiredDocument,
  RequiredDocument,
  updateRequiredDocuments,
} from '../../../store/services/required-document';
import { convertResponseToRequiredDoc } from '../../../utils/helpers';
import { requiredDocumentResponsesState } from '../../../store/recoil/award-bid';
import { selectedBidIdState } from '../../../store/recoil/bidState';
import { StatusMessage } from '../../../types/shared';
import { useRequiredDocumentResponses } from '.';

const requiredDocsSelector = selector<RequiredDocument[]>({
  key: 'requiredDocsSelector',
  get: ({ get }) => {
    const requiredDocResponses = get(requiredDocumentResponsesState);
    const requiredDocs = requiredDocResponses.map(response => {
      return convertResponseToRequiredDoc(response);
    });
    return requiredDocs;
  },
});

export function useRequiredDocuments() {
  const { refreshRequiredDocumentResponses } = useRequiredDocumentResponses();
  const requiredDocs = useRecoilValue(requiredDocsSelector);

  const validRequiredDocs = useMemo(() => {
    for (const doc of requiredDocs) {
      if (!doc.docTitle) {
        return false;
      }
    }
    return true;
  }, [requiredDocs]);

  /** Update Required Docs (distinct from Required Document Responses)
   * Make a service call to update RequiredDocuments with Responses.
   * @description This is a curried call with `useRecoilCallback`
   */
  const updateRequiredDocs = useRecoilCallback(
    ({ snapshot }) =>
      async (documents: (NewRequiredDocument | RequiredDocument)[]) => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        try {
          const updateResponse = await updateRequiredDocuments({
            bidId,
            documents,
          });
          if (updateResponse.message === StatusMessage.Success) {
            await refreshRequiredDocumentResponses();
          }
        } catch (error) {
          console.error('useRequiredDocuments.updateRequiredDocs() error:', error);
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Add a single new Required Document (distinct from Required Document Response) */
  const addRequiredDoc = useRecoilCallback(
    () => async (document: NewRequiredDocument) => {
      updateRequiredDocs([document]);
    },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Delete a single Required Document (distinct from Required Document Response) */
  const deleteRequiredDoc = useRecoilCallback(
    () => async (doc: RequiredDocument) => {
      const docToDelete = { ...doc, isDelete: true };
      await updateRequiredDocs([docToDelete]);
    },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Looks at our current requiredDocs selector (based off of RequiredDocResponses),
   *  then saves the latest Required Documents */
  const saveRequiredDocs = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const currentRequiredDocs = await snapshot.getPromise(requiredDocsSelector);
        await updateRequiredDocs(currentRequiredDocs);
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    addRequiredDoc,
    deleteRequiredDoc,
    saveRequiredDocs,
    updateRequiredDocs,
    validRequiredDocs,
  };
}
