import { useRecoilCallback, useRecoilValue } from 'recoil';

import { alertState, AlertState, alertVisibleState } from './Alert.state';

export function useAlert(id: string) {
  const alert = useRecoilValue(alertState(id));
  const alertVisible = useRecoilValue(alertVisibleState(id));

  const show = useRecoilCallback(
    ({ set }) =>
      async (alert?: AlertState) => {
        set(alertVisibleState(id), true);
        set(alertState(id), alert);
      },
    [id],
  );

  const hide = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(alertVisibleState(id), false);
        set(alertState(id), undefined);
      },
    [id],
  );

  return {
    /** an optional override for alert message */
    alert,
    /** The current state of the alert w/ the associated ID */
    alertVisible,
    /** Hides the alert w/o additional checks or action (code-based dismissal) */
    hide,
    /** Toggles the alert to be shown, takes an optional message parameter to override message body */
    show,
  };
}
