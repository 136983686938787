export function compareLists<Type>(
  list1: Type[],
  list2: Type[],
  comparator?: (a: Type, b: Type) => boolean | undefined,
) {
  const defaultComparator: (a: Type, b: Type) => boolean = (a, b) => a === b;
  const compare = comparator ?? defaultComparator;

  const firstOnly = list1.filter(item1 => !list2.find(item2 => compare(item1, item2)));

  const secondOnly = list2.filter(item2 => !list1.find(item1 => compare(item2, item1)));

  const intersect = list1.filter(item1 => list2.find(item2 => compare(item1, item2)));

  return {
    firstOnly,
    secondOnly,
    intersect,
  };
}

export function removeDuplicates<T>(duplicateArray: T[], key: keyof T) {
  const lookup = {} as Record<string | number | symbol, T>;

  duplicateArray.forEach((arrObj: T) => {
    const keyVal = arrObj[key];
    if (typeof keyVal === 'string' || typeof keyVal === 'number' || typeof keyVal === 'symbol') {
      lookup[keyVal] = arrObj;
    }
  });

  return Object.keys(lookup).map(key => lookup[key]);
}

 export const getStatusBarTheme = (bidStatus) => {
  switch (bidStatus) {
      case 'CN':
          return 'cancelledbg';
      case 'AW':
          return 'awardedbg';
      case 'AC':
          return 'awardedbg';
      default:
          return '';
  }
};

export const getDisplayText = (bidStatus) => {
  switch (bidStatus) {
      case 'OP':
          return 'Under Evaluation';
      case 'UP':
          return 'Upcoming';
      case 'CN':
          return 'Cancelled';
      case 'AW':
          return 'Awarded';
      case 'AC':
          return 'Active';
      case 'RJ':
          return 'Rejected' ;   
      default:
          return bidStatus;
  }
};

export function sortTagsByFrequency(tagsArray) {
  const tagFrequency = {};

  tagsArray?.forEach(tag => {
      tagFrequency[tag] = (tagFrequency[tag] || 0) + 1;
  });

  const sortedTags = Object.keys(tagFrequency).sort((a, b) => tagFrequency[b] - tagFrequency[a]);

  const maxFrequency = Math.max(...(Object.values(tagFrequency) as number[]));

  const result = sortedTags.map(tag => {
      const frequency = tagFrequency[tag];
      let rating = Math.round((frequency / maxFrequency) * 10);  
      rating = Math.max(rating, 1);  
      return `${tag}`;
  });

  return result;
}
