import { useEffect, useMemo, useState } from 'react';

/**
 * @description Wraps debouncing in a simple hook to be used instead of `useState`
 * @param initialValue - T - the value with which to initialize state
 * @param delay - number - milliseconds to delay state update, defaults to 0.5 seconds
 * if delay === 0, useDebouncedState behaves the same way as useState
 * @param stateCompare - (T, T) => boolean - override for comparing states, returns arg1 === arg2
 * @returns [
 *    debouncedValue: T,
 *    setValue: Dispatch<SetStateAction<T>>,
 *    stateValue: T, interim state value
 * ]
 * @example const [myState, setMyState] = useDebouncedState('Hello', 5000);
 * setMyState('Goodbye'); // Takes 5 seconds to take effect
 */
export function useDebouncedState<T>(
  initialValue: T,
  delay = 500,
  stateCompare?: (state1: T, state2: T) => boolean,
): [T, React.Dispatch<React.SetStateAction<T>>, T] {
  // State and setters for debounced value
  const [stateValue, setStateValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const comparator = useMemo(() => {
    return stateCompare ? stateCompare : (s1: T, s2: T) => s1 === s2;
  }, [stateCompare]);

  useEffect(
    () => {
      const stateUnchanged = delay === 0 || comparator(debouncedValue, stateValue);
      /**
       * We only want to perform a potentially expensive operation if final state differs
       * from current debounced state at the end of the delay period
       */
      if (!stateUnchanged) {
        const handler = setTimeout(() => {
          setDebouncedValue(stateValue);
        }, delay);

        return () => {
          clearTimeout(handler);
        };
      }
    },
    [comparator, debouncedValue, delay, stateCompare, stateValue], // Only re-call effect if value or delay changes
  );

  return [
    /**
     *  Need this condition as otherwise debouncedValue will trail stateValue by 1 render
     *  Imperceptible to humans, but has test implications
     */
    delay === 0 ? stateValue : debouncedValue,
    setStateValue,
    stateValue,
  ];
}
