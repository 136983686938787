import { useEffect, useState } from 'react';

import { DSTabNavigationLabel, DSTabNavigationLabelProps } from './DSTabNavigationLabel';
import { DataTestId } from '../types';
import { defaultDataTestId } from './DSTabNavigation';
import { TabRow } from './DSTabNavigation.styles';

type TabHeaderProps = DataTestId & {
  /** The tabs to display */
  tabs?: DSTabNavigationLabelProps[];
};

/**
 * The header for the tab navigation section, which can be used for more lower-level implementations of Tab Navigation,
 * allows for direct control of tab click handling & does not have a 1:1 mapping for tab labels => content
 * @example <DSTabNavigationHeader
 * {
 *   id: '2edx2wsw',
 *   label: `Tab 1`,
 *   onClick: () => { console.log('Tab 1 Selected'); },
 * },
 * {
 *   id: '2ecddxsw',
 *   label: `Tab 2`,
 *   onClick: () => { console.log('Tab 2 Selected'); },
 * },
 * ]} />
 */
export const DSTabNavigationHeader = ({
  dataTestId = `${defaultDataTestId}.header`,
  tabs = [],
}: TabHeaderProps) => {
  const [selectedTabId, setSelectedTabId] = useState<string | undefined>(undefined);

  const selectTab = ({ id, onClick }: DSTabNavigationLabelProps) => {
    setSelectedTabId(id);
    if (onClick !== undefined) onClick();
  };

  useEffect(() => {
    if (tabs.length) {
      const newTabId = tabs.find(t => t.selected)?.id;

      if (newTabId) {
        setSelectedTabId(newTabId);
      } else if (!selectedTabId) {
        setSelectedTabId(tabs[0].id);
      }
    }
  }, [selectedTabId, tabs]);

  return (
    <TabRow>
      {tabs.map(t => (
        <DSTabNavigationLabel
          dataTestId={t.dataTestId || `${dataTestId}.${t.id}`}
          id={t.id}
          label={t.label}
          key={t.id}
          onClick={() => {
            selectTab(t);
          }}
          selected={selectedTabId === t.id}
        />
      ))}
    </TabRow>
  );
};
