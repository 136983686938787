import React from 'react';

import { H5 } from '@demandstar/components/styles';

import { CardBody, CardHeader, CardHighlightColor, CardWrapper } from './styles';
import { Product } from '../../../types/subscriptions';
import { ProductType } from 'src/types/products';

interface CardProps {
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  header: string;
  level: Product['productType']; // TODO: make a more generic "level" prop & map product types in UpsellList
  linkText: string;
  message: string;
}

// TODO: This component could be converted into a library component "Card"
export const Card = ({ handleClick, header, level, linkText, message }: CardProps) => {
  let highlight = CardHighlightColor.Light;

  switch (level) {
    case ProductType.State:
      highlight = CardHighlightColor.Base;
      break;
    case ProductType.National:
      highlight = CardHighlightColor.Dark;
      break;
  }

  return (
    <CardWrapper highlightColor={highlight} onClick={handleClick}>
      <CardHeader>
        <H5>{header}</H5>
      </CardHeader>
      <CardBody>
        <p>{message}</p>
        <button onClick={handleClick}>{linkText}</button>
      </CardBody>
    </CardWrapper>
  );
};
