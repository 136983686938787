import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useEffect, useState, useRef } from 'react';
//import { SingleDatePicker } from 'react-dates';
import { useDispatch } from 'react-redux';

import * as actionTypes from '../../store/actionTypes';
import { datePickerYear, invalidDateMessage, usaDateFormat } from '../../utils/constants';
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../utils/helpers';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import useOnClickOutside from 'src/utils/helpers/useOutside';

interface PropsTypes {
  handleChange?: (name: string, value: any) => void;
  type?: any;
  disabled?: any;
  label?: string;
  name?: string;
  value?: any;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  reduxform?: any;
  showClearDate?: any;
  showDefaultInputIcon?: any;
  isOutsideRange?: any;
  numberOfMonths?: any;
  maxLength?: any;
  classNames?: any;
  fieldRef?: any; // pass-through for `react-hook-form`
}

/**  Deprecated:
 * Fingers crossed, we will soon we will have a brand new datepicker to replace both this,
 * and DatepickerField from datepicker.tsx .
 * */
export function DeprecatedReduxDatepicker(props: PropsTypes) {
  const {
    handleChange = () => {},
    disabled,
    label,
    name = '',
    value = '',
    meta = {},
    optional,
    input,
    placeholder = usaDateFormat,
    minDate = moment(),
    maxDate,
    parentClass,
    reduxform = false,
    showClearDate = false,
    isOutsideRange = false,
    numberOfMonths = 1,
    fieldRef,
  } = props;
  const { showDefaultInputIcon = true } = props;
  const { touched, error } = meta || {};
  const [focused, setFocused] = useState(false);
  const [internalError, setInternalError] = useState('');
  const idGen = reduxform ? `single_date_redux_${input.name}` : `single_date_${name}`;
  const dispatch = useDispatch();
  const datePickerRef = useRef<any>();
  const [state, setState] = useState({ format: 'MM/DD/YYYY' });
  let dateStringValue = value;
  if (value && moment.isMoment(value)) {
    dateStringValue = value.format(usaDateFormat);
  }

  const [initialDate, setInitialDate] = useState(dateStringValue);

  const handleDatePickerClose = useCallback(
    () => datePickerRef.current.closeCalendar(),
    [datePickerRef],
  );

  useOnClickOutside(datePickerRef, handleDatePickerClose);

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onSingleDateChange = useCallback(
    (selectedDate: any, format = state.format) => {
      if (selectedDate !== null && selectedDate.isValid && selectedDate.year > 2000) {
        const date = new DateObject(selectedDate).format();
        if (reduxform) {
          input.onChange(date);
        } else {
          handleChange(name, date);
        }
        settingErrorFn('');
      } else if (!selectedDate) {
        if (reduxform) {
          input.onChange('');
        } else {
          handleChange(name, '');
        }
      } else if (!selectedDate.isValid && selectedDate.year < 2000) {
        settingErrorFn(invalidDateMessage);
      }
    },
    [handleChange, input, name, reduxform, settingErrorFn, state.format],
  );

  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched && error) || internalError ? ' errorWrapper' : '')
      }
      ref={fieldRef}
    >
      <div title={usaDateFormat} className='inputWrapper'>
        <label title={usaDateFormat}>{label}</label>
        <DatePicker
          ref={datePickerRef}
          id='datepickerId'
          placeholder='MM/DD/YYYY'
          onChange={onSingleDateChange}
          value={initialDate}
          format='MM/DD/YYYY'
          containerClassName='DateRangePicker'
          minDate={minDate}
          maxDate={maxDate}
          highlightToday={false}
          onOpenPickNewDate={false}
        />
        <i className='mdi mdi-calendar'></i>
        {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
        <div className='errorMsg'>
          {(touched && error) || internalError ? (
            <>{<span>{internalError ? internalError : error}</span>}</>
          ) : null}
        </div>
      </div>
    </div>
  );
}
