import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Assert, formatDollar, formatPhone } from '@demandstar/components/utils';
import {
  DSDollarField,
  DSEmailField,
  DSPhoneField,
  DSTextField,
} from '@demandstar/components/fields';
import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { DSSelect } from '@demandstar/components/inputs';

import * as texts from './ContractAwardeeDetails.texts';
import { AwardeeContactDraft } from 'src/features/contract-management/contract-management.d';
import { commonLabels } from 'src/shared/constants';
import { ContractAwardeeTable } from './ContractAwardeeTable';
import { ContractWizardButtons } from '../ContractWizardButtons';
import { ContractWizardId } from '../useContractWizard';
import { hasValue } from 'src/utils/helpers';
import { useBidAwardees } from 'src/store/recoil/bid-awardees/useBidAwardees';
import { useContractDetails } from 'src/features/contract-management/useContractDetails';
import { useEBidResponses } from 'src/store/recoil/ebid-responses/useEBidResponses';
import { usePlanholders } from 'src/store/recoil/planholders/usePlanholders';

type AwardeeContactDraftForm = Omit<AwardeeContactDraft, 'amount'> & {
  amount: string | number;
};

/** A potential contract awardee which has at least a name and memberId,
 * but does necessarily have any other attributes*/
type PotentialContractAwardee = Required<Pick<AwardeeContactDraft, 'memberId' | 'name'>> &
  Partial<Omit<AwardeeContactDraft, 'name' | 'memberId'>>;

const emptyAwardeeContactDraftForm: AwardeeContactDraftForm = {
  email: '',
  phone: '',
  amount: '$0.00',
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  name: '',
};

export const ContractAwardeeDetails = () => {
  const {
    contractDetailsLoadable,
    contractDetails,
    addUpdateAwardeeContact,
    deleteAwardeeContact,
  } = useContractDetails();

  const { planholdersLoadable } = usePlanholders(contractDetails?.bidId);
  const { eBidResponsesLoadable } = useEBidResponses(contractDetails?.bidId);
  const { bidAwardeesLoadable } = useBidAwardees(contractDetails?.bidId);

  const awardeeContacts = contractDetails?.awardeeContacts;

  const methods = useForm<AwardeeContactDraftForm>({
    mode: 'onTouched',
    defaultValues: emptyAwardeeContactDraftForm,
  });
  const { formState, getValues, reset, setValue, trigger, watch } = methods;

  const mustShowNewAwardeeForm =
    hasValue(contractDetailsLoadable) && !contractDetails?.awardeeContacts?.length;

  const [addingAwardee, setAddingAwardee] = useState(mustShowNewAwardeeForm);

  const [editingAwardee, setEditingAwardee] = useState<AwardeeContactDraft | undefined>();

  const [triggerResetCount, setTriggerResetCount] = useState(0);

  const triggerReset = useCallback(() => {
    // reset any selected data in `editingAwardee`
    setEditingAwardee(undefined);
    // trigger the RHF reset
    setTriggerResetCount(val => val + 1);
  }, []);

  const potentialContractAwardees = useMemo(() => {
    const potentials: PotentialContractAwardee[] = [];

    /**
     *  returns a value based on memberId
     * @returns {true} - if there's a duplicate in awardeeContacts
     * @returns {false} - if there are no duplicates anywhere, and we should add to the member to potentials
     * @returns {number} - if there's a duplicate already in potentials,
     * we return the index so we can add additional information
     */
    function checkForDuplicates(memberId: number): boolean | number {
      Assert(
        awardeeContacts,
        'checkForDuplicates should only be called when we have awardeeContacts',
      );
      // is this supplier already selected?
      if (
        awardeeContacts.findIndex(awardee => {
          return awardee.memberId === memberId;
        }) !== -1
      ) {
        return true;
      }

      // is this supplier already an option?

      const index = potentials.findIndex(potential => {
        return potential.memberId === memberId;
      });
      return index === -1 ? false : index;
    }

    if (
      hasValue(bidAwardeesLoadable) &&
      hasValue(planholdersLoadable) &&
      hasValue(eBidResponsesLoadable)
    ) {
      // add all non-duplicate awardees
      for (const awardee of bidAwardeesLoadable.contents) {
        if (checkForDuplicates(awardee.awardedToMemberId) === false) {
          // keep the '==='
          potentials.push({
            amount: awardee.amount,
            email: awardee.email,
            memberId: awardee.awardedToMemberId,
            name: awardee.supplierName,
          });
        }
      }
      // add all non-duplicate planholders
      for (const planholder of planholdersLoadable.contents) {
        const i = checkForDuplicates(planholder.mi);
        switch (i) {
          case true:
            break;
          case false:
            potentials.push({
              amount: 0,
              email: planholder.email,
              memberId: planholder.mi,
              name: planholder.supplierName,
              phone: planholder.phone,
            });
            break;
          default:
            potentials[i] = {
              ...potentials[i],
              /** potentially, a planholder has phone information that an awardee does not have.
               * TODO: In the future, this logic could be simplified by getting phone data from the awardee. */
              phone: planholder.phone,
            };
            break;
        }
      }

      // add all non-duplicate eBid responses
      for (const response of eBidResponsesLoadable.contents) {
        if (checkForDuplicates(response.supplierMemberId) === false) {
          // keep the '==='
          potentials.push({
            memberId: response.supplierMemberId,
            amount: 0,
            name: response.name,
          });
        }
      }
    } else {
      return [];
    }
    return potentials;
  }, [bidAwardeesLoadable, awardeeContacts, eBidResponsesLoadable, planholdersLoadable]);

  const autofillEditAwardee = useCallback((awardee: AwardeeContactDraft) => {
    setAddingAwardee(true);
    setEditingAwardee(awardee);
  }, []);

  const isEmptyForm = useMemo(() => {
    if (!addingAwardee) return true;
    const formValues = watch();
    const hasValue = Object.values(formValues).find(val => {
      return val;
    });
    return !hasValue;
  }, [addingAwardee, watch]);

  const canMoveForward = isEmptyForm || formState.isValid;

  useEffect(() => {
    if (editingAwardee) {
      const { amount, contactEmail, contactName, contactPhone, name, phone, email } =
        editingAwardee;
      setValue('amount', formatDollar(amount));
      setValue('contactEmail', contactEmail || '');
      setValue('contactName', contactName || '');
      setValue('contactPhone', formatPhone(contactPhone));
      setValue('email', email);
      setValue('name', name || '');
      setValue('phone', formatPhone(phone));
      trigger();
    }
  }, [editingAwardee, setValue, trigger]);

  useEffect(() => {
    // if there are no awardees, we open the form automatically.
    if (mustShowNewAwardeeForm) setAddingAwardee(true);
  }, [mustShowNewAwardeeForm]);

  useEffect(() => {
    /** RHF recommends resetting as part of a useEffect hook. To reset, use triggerReset() */
    if (triggerResetCount) {
      reset();
    }
  }, [reset, triggerResetCount]);

  /** show/hide add awardee form */
  function toggleAddNewAwardee() {
    triggerReset();
    setAddingAwardee(!addingAwardee);
  }

  async function submitNewAwardee() {
    if (!addingAwardee) return true;
    let success = true;
    if (!isEmptyForm) {
      const awardee: AwardeeContactDraft = getValues() as AwardeeContactDraft;
      success = await addUpdateAwardeeContact({
        ...awardee,
        memberId: editingAwardee?.memberId,
        id: editingAwardee?.id,
      });
      if (editingAwardee?.id) {
        toggleAddNewAwardee();
      }
    }
    triggerReset();
    return success;
  }

  async function deleteAwardee() {
    Assert(editingAwardee?.id, 'We expect an editing awardee when we delete');
    await deleteAwardeeContact(editingAwardee.id);
    toggleAddNewAwardee();
    triggerReset();
  }

  return (
    <>
      <ContractAwardeeTable onEdit={autofillEditAwardee} />
      {!!potentialContractAwardees.length && (
        <DSSelect
          onSelect={autofillEditAwardee}
          label={texts.addFromPlanholder}
          name='addFromPlanholder'
          options={potentialContractAwardees}
          labelField='name'
          valueField={false}
          keyField='memberId'
        />
      )}
      {addingAwardee ? (
        <FormProvider {...methods}>
          <DSTextField name='name' label={texts.supplierName} message={texts.supplierNameMessage} />
          <DSDollarField
            name='amount'
            label={texts.contractAmount}
            message={texts.contractAmountMessage}
          />
          <DSEmailField name='email' label={texts.email} message={texts.emailMessage} />
          <DSPhoneField
            name='phone'
            label={texts.businessPhone}
            message={texts.businessPhoneMessage}
          />
          <DSTextField name='contactName' label={texts.contactName} optional />
          <DSEmailField name='contactEmail' label={texts.contactEmail} optional />
          <DSPhoneField name='contactPhone' label={texts.contactPhone} optional />
          <FlexContainer justifyContent='right'>
            {editingAwardee?.id && (
              <Flex grow={0}>
                <DSButton type='danger' onClick={deleteAwardee}>
                  {texts.deleteAwardee}
                </DSButton>
              </Flex>
            )}
            <Flex grow={1}></Flex>
            {!!contractDetails?.awardeeContacts.length && (
              <Flex grow={0}>
                <DSButton type='secondary' onClick={toggleAddNewAwardee}>
                  {commonLabels.cancel}
                </DSButton>
              </Flex>
            )}
            <Flex grow={0}>
              <DSButton
                type='tertiary'
                inactive={!formState.isValid}
                onInactiveClick={trigger}
                onClick={submitNewAwardee}
              >
                {editingAwardee ? texts.saveAwardee : texts.saveAndAdd}
              </DSButton>
            </Flex>
          </FlexContainer>
        </FormProvider>
      ) : (
        <FlexContainer justifyContent='center'>
          <Flex grow={0}>
            <DSButton type='tertiary' onClick={toggleAddNewAwardee}>
              {texts.addAnotherAwardee}
            </DSButton>
          </Flex>
        </FlexContainer>
      )}

      <ContractWizardButtons
        previousWizardId={ContractWizardId.ContractSummary}
        nextWizardId={ContractWizardId.ManageDocuments}
        onInactiveClick={trigger}
        onSave={submitNewAwardee}
        inactiveNext={!canMoveForward}
      />
    </>
  );
};
