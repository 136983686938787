import styled from 'styled-components/macro';

import { BackgroundColor, BorderColor } from './colors';
import { FontFamily, FontWeight, LineHeight, ParagraphFontSize } from './typography';
import { Layout } from '../types';
import { LayoutType } from '../constants';
import { Margin } from './spacing';

export enum MaximumPageWidth {
  Default = '98rem',
  SingleContentBlock = '80rem',
}

export function getMaximumLayoutWidth({ layoutType }: Layout) {
  switch (layoutType) {
    case LayoutType.Basic:
      return MaximumPageWidth.SingleContentBlock;
    default:
      return MaximumPageWidth.Default;
  }
}
export const transitions = {
  base: '0.25s',
};

export const FullWidth = styled.div`
  width: 100%;
`;

export const TextOverflowEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
`;

export const ContentRowWrapping = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InnerColumnNarrow = styled.div`
  display: flex;
  flex-basis: 33%;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
`;

export const InnerColumnWide = styled.div`
  display: flex;
  flex-basis: 67%;
  flex-direction: column;
  flex-grow: 2;
  width: 67%;
`;

export const HighlightedDetailsWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  border: solid 0.1875rem ${BorderColor.Highlight};
  border-radius: 0.125rem;
  height: 100%;
  margin-left: -3rem;
  margin-right: -1rem;
  padding: 0.75rem 1rem 0.75rem 3rem;
`;

export const HighlightedListWrapper = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  height: 100%;
  margin-left: -1.5rem;
  padding: 0.75rem 1.5rem 0 1.5rem;
`;

export type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

export interface FlexContainerProps {
  /** defines the alignment along the cross axis
   * @default 'baseline' */
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  /** column gap, includes `Margin.Extra` if true. `Margin.None` if false.
   *  @default: true */
  columnGap?: boolean;
  /** establishes the main-axis, thus defining the direction flex items are placed in the flex container.
   * @default 'row' */
  direction?: FlexDirection;
  /** defines whether the flex items are forced in a single line or can be flowed into multiple lines
   * @default 'nowrap' */
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  /** row gap, includes `Margin.Base` if true. `Margin.None` if false. */
  rowGap?: boolean;
  /** defines the alignment along the main axis
   * @default 'flex-start' */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';
  /** setting flex-start, flex-end doesn't display without 100% width */
  /** optionally include a bottom margin */
  marginBottom?: boolean;
}

/** Simple flex container for <Flex> components
 * @example <FlexContainer>
 *  <Flex>Foo</Flex>
 *  <Flex>Bar</Flex>
 * </FlexContainer>
 */
export const FlexContainer = styled.div<FlexContainerProps>`
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  column-gap: ${({ columnGap = true }) => (columnGap ? Margin.Extra : Margin.None)};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  row-gap: ${({ rowGap }) => (rowGap ? Margin.Base : Margin.None)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? Margin.Base : 0)};
  width: 100%;
`;
export const CountyListFlexContainer = styled.div<FlexContainerProps>`
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  column-gap: ${({ columnGap = true }) => (columnGap ? Margin.Extra : Margin.None)};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  row-gap: ${({ rowGap }) => (rowGap ? Margin.Base : Margin.None)};
  width: 100%;
    @media screen and (max-width: 385px) {
    width: 80%;
  }

`;

export const RegistrationHomeFlexContainerMobile = styled.div<FlexContainerProps>`
  
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  column-gap: ${({ columnGap = true }) => (columnGap ? Margin.Extra : Margin.None)};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  row-gap: ${({ rowGap }) => (rowGap ? Margin.Base : Margin.None)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? Margin.Base : 0)};
  width: 100%;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 490px), (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap:10px
  }
`;

export const PopUpFlexContainerMobile = styled.div<FlexContainerProps>`
  
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  column-gap: ${({ columnGap = true }) => (columnGap ? Margin.Extra : Margin.None)};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  row-gap: ${({ rowGap }) => (rowGap ? Margin.Base : Margin.None)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? Margin.Base : 0)};
  width: 100%;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 992px) {
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    row-gap:30px
  }
`;

export const EndButtonsFlexContainer = styled.div<FlexContainerProps>`
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  column-gap: ${({ columnGap = true }) => (columnGap ? Margin.Extra : Margin.None)};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  row-gap: ${({ rowGap }) => (rowGap ? Margin.Base : Margin.None)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? Margin.Base : 0)};
  width: 100%;
   @media screen and (max-width: 490px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap:10px
  }
`;

/**
 * Helper component for column layout
 */
export const FlexContainerColumn = styled(FlexContainer).attrs((props: FlexContainerProps) => ({
  direction: 'column',
  wrap: 'nowrap',
}))``;

export type FlexProps = {
  /** sets the flex grow factor of a flex item's main size */
  grow?: number;
};

export type CustomFlexProps = FlexProps & {
  /** sets the flex basis factor of a flex item; accepts the same values as the width and height, plus 'content' */
  basis?: string;
  /** sets the flex shrink factor of a flex item's main size */
  shrink?: number;
};

const getFlexValue = (flex: CustomFlexProps) => {
  const grow = flex?.grow ?? 0;
  const shrink = flex?.shrink ?? 1;

  /** Default flex basis is "auto" if grow & shrink are unset and "0" otherwise */
  const defaultBasis = flex?.grow || flex?.shrink ? '0' : 'auto';
  const basis = flex?.basis || defaultBasis;

  return `${grow} ${shrink} ${basis}`;
};

/** Flex div, fits inside <FlexContainer> to space automatically.
 * Good for simple multi-column needs.
 * @example <Flex grow={2}>Foo</Flex>
 * <Flex>Bar</Flex>
 */
export const Flex = styled.div<FlexProps>`
  flex: ${({ grow }) => grow ?? 1} 1;
`;

/** Flex div, fits inside <FlexContainer> and exposes CSS flex attributes
 * Good for complex/unique cases in flex
 * @example <CustomFlex grow={2} shrink={1}>Foo</CustomFlex>
 * <CustomFlex grow={1} basis={'25%'}>Bar</CustomFlex>
 */
export const CustomFlex = styled.div<CustomFlexProps>`
  flex: ${getFlexValue};
`;

/** Flex div that turns the first child into a bolded label &
 *  provides an option for vertically stacking first & subsequent items
 * @example <LabelValuePair><div>Label</div><div>Value</div></LabelValuePair>
 *  */
export const LabelValuePair = styled(Flex)<{ vertical?: boolean }>`
  font-size: ${ParagraphFontSize.Base};
  font-family: ${FontFamily.Base};
  line-height: ${LineHeight.Base};

  > :first-child {
    font-weight: ${FontWeight.Bold};
    margin-right: ${({ vertical }) => (vertical ? Margin.None : Margin.Less)};
    margin-bottom: ${Margin.None};
    width: ${({ vertical }) => (vertical ? '100%' : 'auto')};
  }
`;
