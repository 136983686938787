import { PropsWithChildren } from 'react';

import { arrayToString } from '../../../../utils/helpers';
import { CustomNotificationRecipient } from '../../../../types/awardBid';
import { DemandStarNotificationEmail } from 'src/utils/texts/common';
import { NotificationRow } from './NotificationRow';

type DisplayRecipient = Partial<CustomNotificationRecipient> & {
  email: string;
};

type MessagePreviewProps = PropsWithChildren<{
  to: DisplayRecipient[];
  subject: string;
}>;

export const MessagePreview = (props: MessagePreviewProps) => {
  const recipients = props.to.map(recipient => {
    return `${recipient.contactName || recipient.memberName} <${recipient.email}>`;
  });

  return (
    <div>
      <div>
        <h4>Message Preview</h4>
        <div>
          <div>
            <NotificationRow label='From' condensed>
              {DemandStarNotificationEmail}
            </NotificationRow>
            <NotificationRow label='To' condensed>
              <span>{arrayToString(recipients)}</span>
            </NotificationRow>
            <NotificationRow label='Subject' condensed>
              <span>{props.subject}</span>
            </NotificationRow>
            <h4>{/**/}</h4>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
