import { useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil';
import dayjs from 'dayjs';

import { Status } from '@demandstar/components/constants';

import * as documentServices from '../../../../service/documents';
import * as services from './ContractDocuments.services';

import {
  allContractDocumentsState,
  contractDocumentsState,
  contractDocumentTypesState,
} from './ContractDocuments.state';
import { contractDetailsState, sanitizeContractDetails } from '../../useContractDetails';
import {
  ContractDocument,
  ContractDocumentStatus,
  ContractDocumentType,
} from '../../contract-management.d';
import {
  documentAlertId,
  documentDeletedAlertId,
  documentTypeAlertId,
} from './ContractDocuments.alerts';
import { compareObjectsByKey } from '../../../../utils';
import { documentUploadProgressThrottlePercentage } from 'src/shared/constants';
import { FileUploadRequest } from '../../../../types';
import { tryCatchLog } from '../../../../utils/errors';
import { useAlert } from 'src/components/common/alert';
import { useContractSearch } from '../../search';
import { useRouter } from '../../../../shared/hooks';

/** @param id: contract ID, if not provided defaults to route-matched id */
export function useContractDocuments(id?: string) {
  const contractDocumentTypesLoadable = useRecoilValueLoadable(contractDocumentTypesState);
  const contractDocumentTypes = contractDocumentTypesLoadable.valueMaybe() ?? [];

  const allContractDocumentsLoadable = useRecoilValueLoadable(allContractDocumentsState);
  const allContractDocuments = allContractDocumentsLoadable.valueMaybe() || [];

  const { updateSearchResults } = useContractSearch();
  const { routerParams } = useRouter();
  const contractId = id || routerParams.contractId;

  const contractDocumentsLoadable = useRecoilValueLoadable(contractDocumentsState(contractId));
  const contractDocuments = contractDocumentsLoadable.valueMaybe() ?? [];

  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);

  const uploadInProgress = useMemo(
    () => documentUploadProgress > 0 && documentUploadProgress < 100,
    [documentUploadProgress],
  );

  const documentAlert = useAlert(documentAlertId);
  const documentDeletedAlert = useAlert(documentDeletedAlertId);
  const documentTypeAlert = useAlert(documentTypeAlertId);

  /** Adds or updates the provided document type, updates the types array state */
  const updateDocumentType = useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentType: ContractDocumentType) => {
        documentTypeAlert.hide();
        const isUpdate = documentType.id;
        const type = await tryCatchLog(
          () => {
            return services.updateContractDocumentType(documentType);
          },
          isUpdate
            ? `useContractDocumentTypes => updateContractDocumentType(${documentType.id})`
            : 'useContractDocumentTypes => updateContractDocumentType',
          () => {
            documentTypeAlert.show({
              dataTestId: 'contract-document-type-alert-error',
              message: `An error occurred while ${
                isUpdate ? 'updating' : 'adding'
              } the document type. Please try again.`,
              type: Status.Error,
            });
          },
        );

        let docTypes = await snapshot.getPromise(contractDocumentTypesState);

        if (isUpdate) {
          docTypes = docTypes.filter(dt => dt.id !== documentType.id);
        }

        set(contractDocumentTypesState, [...docTypes, type]);
        documentTypeAlert.show({
          dataTestId: 'contract-document-type-alert-success',
          message: `Document type "${type.title}" has been ${isUpdate ? 'updated' : 'added'}.`,
          type: Status.Success,
        });
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Marks the provided document type as deleted, updates the types array state */
  const deleteDocumentType = useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentType: ContractDocumentType) => {
        documentTypeAlert.hide();
        const type = await tryCatchLog(
          () => {
            return services.updateContractDocumentType({
              ...documentType,
              isDeleted: true,
            });
          },
          `useContractDocumentTypes => deleteDocumentType(${documentType.id})`,
          () => {
            documentTypeAlert.show({
              dataTestId: 'contract-document-type-alert-error',
              message: 'An error occurred while deleting the document type. Please try again.',
              type: Status.Error,
            });
          },
        );
        const docTypes = await snapshot.getPromise(contractDocumentTypesState);
        set(contractDocumentTypesState, [
          ...docTypes.filter(docType => docType.id !== documentType.id),
          type,
        ]);
        documentTypeAlert.show({
          dataTestId: 'contract-document-type-alert-success',
          message: `Document type "${type.title}" has been deleted.`,
          type: Status.Success,
        });
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function handleUploadProgress(progressEvent: ProgressEvent) {
    const progress = (progressEvent.loaded / progressEvent.total) * 100;

    // Only update per >=5% increment to reduce state setting / redraw cycles.
    /* istanbul ignore else */
    if (progress - documentUploadProgress >= documentUploadProgressThrottlePercentage) {
      setDocumentUploadProgress(Math.round(progress));
    }
  }

  /**
   * uploads the provided document, adds a document type if neccessary, and adds the contract metadata
   * Updates the contract details object.
   */
  const uploadContractDocument = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        upload: FileUploadRequest<{
          awardeeId?: string;
          contractId: string;
          documentType: ContractDocumentType;
          isPublic: boolean;
          title?: string;
          whitelistMemberId?: number;
        }>,
      ) => {
        documentAlert.hide();
        let { documentType } = upload;
        const allDocumentsPromise = snapshot.getPromise(allContractDocumentsState);
        const contractDocumentsPromise = snapshot.getPromise(contractDocumentsState(contractId));

        // Flag upload in progress immediately on 'upload' click
        setDocumentUploadProgress(documentUploadProgressThrottlePercentage);

        const documentPromise = tryCatchLog(
          async () => {
            return documentServices.uploadDocument(upload, handleUploadProgress);
          },
          'useContractDocuments.uploadDocument => uploadDocument',
          (error: unknown) => {
            documentAlert.show({
              dataTestId: 'contract-document-uploaded-alert-error',
              message: 'An error occurred while uploading the document. Please try again.',
              type: Status.Error,
            });
            throw error;
          },
        );

        if (!documentType.id) {
          /**
           * TODO: Add some better handling here.
           * Document has been sucessfully uploaded, but type & linking to contract failed
           */
          const documentTypePromise = tryCatchLog(
            async () => {
              return services.updateContractDocumentType(upload.documentType);
            },
            `useContractDocuments.uploadDocument => updateContractDocumentType,
                    contractId: ${upload.contractId},
                    type: ${upload.documentType.title}`,
            (error: unknown) => {
              documentAlert.show({
                dataTestId: 'contract-document-uploaded-alert-error',
                message:
                  'An error occurred while creating the new document type. Please try again.',
                type: Status.Error,
              });
              throw error;
            },
          );

          const docTypes = await snapshot.getPromise(contractDocumentTypesState);
          documentType = await documentTypePromise;
          set(
            contractDocumentTypesState,
            [...docTypes, documentType].sort(compareObjectsByKey('title')),
          );
        }

        const document = await documentPromise;

        const contractDetails = await tryCatchLog(
          async () => {
            return services.updateContractDocument({
              documentId: document.id,
              awardeeContactId: upload.awardeeId,
              contractId: upload.contractId,
              documentTypeId: documentType.id || '',
              isPublic: upload.isPublic,
            });
          },
          `useContractDocuments.uploadDocument => updateContractDocumentType,
                docId: ${document.id},
                contractId: ${upload.contractId},
                type: ${upload.documentType.title}`,
          (error: unknown) => {
            documentAlert.show({
              dataTestId: 'contract-document-uploaded-alert-error',
              message: 'An error occurred while uploading the document. Please try again.',
              type: Status.Error,
            });
            throw error;
          },
        );

        // Refresh documents state
        const addedDocument: ContractDocument = {
          id: document.id,
          contractId: contractDetails.id,
          contract: contractDetails.name,
          public: upload.isPublic,
          source:
            contractDetails.awardeeContacts?.find(awardee => awardee.id === upload.awardeeId)
              ?.name || '',
          status: ContractDocumentStatus.Uploaded,
          type: documentType?.title || 'Other',
          document: document,
          dtCreated: dayjs(document.dtCreated),
          dtUpdated: dayjs(document.dtUpdated),
          title: document.title || document.name,
        };

        set(allContractDocumentsState, (await allDocumentsPromise).concat([addedDocument]));
        set(
          contractDocumentsState(contractId),
          ((await contractDocumentsPromise) || []).concat(addedDocument),
        );
        documentAlert.show({
          dataTestId: 'contract-document-uploaded-alert-success',
          message: `"${addedDocument.title}" has been uploaded.`,
          type: Status.Success,
        });
        setDocumentUploadProgress(0);
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Remove the given id from the contract object's list of document ids.
   * @param {string} documentId of the document to be 'deleted'
   * @param {string} contractId of the contract from which the doc is to be detached
   * @description - make service call, update contractDetails state
   * @returns - {Promise<ContractDetails>} deletionResponse
   */
  const deleteContractDocument = useRecoilCallback(
    ({ set, snapshot }) =>
      async (documentId: string, contractId: string) => {
        documentDeletedAlert.hide();
        if (!contractId) {
          return;
        }
        const allDocuments = await snapshot.getPromise(allContractDocumentsState);
        const contractDocuments = await snapshot.getPromise(contractDocumentsState(contractId));

        const deletionResponse = await tryCatchLog(
          () =>
            services.deleteContractDocument({
              contractId,
              documentId,
            }),
          'useContractDocuments => deleteDocument',
          (error: unknown) => {
            documentDeletedAlert.show({
              dataTestId: 'contract-document-deleted-alert-error',
              message: 'An error occurred while deleting the document. Please try again.',
              type: Status.Error,
            });
            throw error;
          },
        );

        // Update contract state
        const updatedContract = sanitizeContractDetails(deletionResponse);
        set(contractDetailsState(contractId), updatedContract);
        updateSearchResults(updatedContract);

        // Update documents state
        set(
          allContractDocumentsState,
          allDocuments.filter(d => d.id !== documentId),
        );
        set(
          contractDocumentsState(contractId),
          contractDocuments?.filter(d => d.id !== documentId),
        );
        documentDeletedAlert.show({
          dataTestId: 'contract-document-deleted-alert-success',
          message: 'Document has been deleted.',
          type: Status.Success,
        });

        return updatedContract;
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    allContractDocumentsLoadable,
    allContractDocuments,
    contractDocuments,
    contractDocumentsLoadable,
    contractDocumentTypes,
    contractDocumentTypesLoadable,
    deleteContractDocument,
    deleteDocumentType,
    documentUploadProgress,
    updateDocumentType,
    uploadContractDocument,
    uploadInProgress,
  };
}
