import { DSPill, DSPillType } from '../pill';
import { AppliedFilter } from '.';
import { AppliedFiltersStyled } from './styles';
import { H6 } from '../styles';

interface AppliedFilterProps {
  clearFilter?: (name: string) => void;
  appliedFilters?: AppliedFilter[];
}

export const DSAppliedFilters = ({ appliedFilters = [], clearFilter }: AppliedFilterProps) => {
  return (
    <AppliedFiltersStyled wrap={'wrap'}>
      <H6>Applied Filters</H6>
      {appliedFilters?.length ? (
        appliedFilters.map(({ name, displayName, displayValue, dataTestId, readOnly }) => {
          const label = `${displayName || ''}${displayName && displayValue ? ': ' : ''}${
            displayValue || ''
          }`;
          return (
            <DSPill
              dataTestId={dataTestId}
              handleIconClick={clearFilter ? () => clearFilter(name) : undefined}
              icon={'mdi-close'}
              iconLabel={'Clear'}
              key={name}
              showIcon={!readOnly && clearFilter ? true : false}
              showIconRight={true}
            >
              {label}
            </DSPill>
          );
        })
      ) : (
        <DSPill type={DSPillType.Error}>No filters applied</DSPill>
      )}
    </AppliedFiltersStyled>
  );
};
