import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { approveConfirmMsgText } from '../../../../utils/texts';
import { BidDocument } from '../../../../types/addbid';
import { commonLabels } from 'src/shared/constants';
import { submitAddBidDocumentApprove } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { useDSSelector } from '../../../../store/reducers';

interface ApproveDocumentProps {
  SetApproveDocument: (doc?: any) => void;
  bidId: number;
  pagefor?: 'bidssummary';
}

export const ApproveDocument = (props: ApproveDocumentProps) => {
  const dispatch = useDispatch();
  // TODO: Remove setApproveDocument from props (and rename it).
  const { SetApproveDocument, bidId, pagefor = '' } = props;
  const approvedocumentdetails = useDSSelector(state => state.addbid.approvedocumentdetails);
  const {
    bidDocId = '',
    bidDocID = '',
    fileName = '',
    docFormat = '',
    originalFileName = '',
  } = (approvedocumentdetails as BidDocument) || {};

  function approveBidDocument() {
    dispatch(
      submitAddBidDocumentApprove({
        bidId,
        bidDocId: bidDocId || bidDocID,
        pagefor,
      }),
    );
  }

  return (
    <>
      <p data-testid='addbid.documentupload.approve.modal'>{approveConfirmMsgText}</p>
      <p>
        <b>{originalFileName ? originalFileName : fileName + '.' + docFormat.toLowerCase()}</b>
      </p>
      <FlexContainer justifyContent='space-around'>
        <DSButton
          type={'secondary'}
          onClick={() => SetApproveDocument()}
          data-testid='addbid.documentupload.approve.modal.cancelbtn'
        >
          {commonLabels.cancel}
        </DSButton>
        <DSButton
          onClick={approveBidDocument}
          data-testid='addbid.documentupload.approve.modal.approvebtn'
        >
          {commonLabels.approve}
        </DSButton>
      </FlexContainer>
    </>
  );
};
