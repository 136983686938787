import React, { memo } from 'react';

interface DeprecatedInputProps {
  accept?: any;
  autoFocus?: boolean;
  classNames?: string;
  disabled?: any; // should be boolean
  files?: any;
  handleChange?: (name: string, value: any) => void;
  iconType?: any;
  input?: any;
  label?: string;
  labelname?: any;
  maxDate?: any;
  maxLength?: number;
  meta?: any;
  minDate?: any;
  name?: string;
  optional?: boolean | string;
  parentClass?: string;
  placeholder?: string;
  reduxform?: any;
  showMaxLength?: boolean;
  title?: string;
  type?: 'text' | 'search' | 'file' | 'password';
  value?: any;
}

function DeprecatedInputComponent(props: DeprecatedInputProps) {
  const {
    autoFocus,
    handleChange = () => {}, // TODO: Let's rename this to onChange
    type = '', // so that we can treat our Custom input
    files = '', // like a regular input.
    accept,
    classNames,
    disabled,
    input,
    label,
    maxLength,
    meta,
    name,
    optional,
    parentClass,
    placeholder = '',
    reduxform = false,
    showMaxLength = false,
    title,
    value,
  } = props;
  const { touched, error } = meta || {};

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'file'
        ? target.files
        : target.value;
    const name = target.name;

    if (
      !['checkbox', 'file'].includes(target.type) &&
      target.value &&
      !isNaN(target.value as any)
    ) {
      // if this is a number
      const splitted = target.value.split('.'); // split it before and after the decimal
      if (splitted.length > 1) {
        // if there is a value on both sides
        if (splitted[1].toString().length <= 3) {
          // if the decimals are fewer than four characters
          handleChange(name, value); // then handle change
        }
      } else {
        handleChange(name, value); // handle the change in all cases, except where the number has 4+ decimal places.
      }
    } else {
      handleChange(name, value);
    }
  };
  let valueLengthComponent = 0;
  if (maxLength) {
    valueLengthComponent = maxLength - 1 || 0;
    const valueLength = reduxform ? input?.value?.length : value?.length;
    if ((input?.value?.length || value?.length) && maxLength) {
      valueLengthComponent = maxLength - 1 - valueLength;
    }
  }

  return (
    // <div className={inputClass.join(' ')} onClick={this.toggle.bind(this)}>
    <>
      {type === 'file' ? (
        <>
          {files && files.length ? (
            files[0].name
          ) : (
            <>
              <div
                className={
                  'uploadWrapper ' + parentClass + (touched && error ? ' errorWrapper' : '')
                }
              >
                {parentClass === 'icon' ? (
                  <i
                    data-testid='upload.plusicon'
                    className={
                      'mdi mdi-plus-box mdi-36px' +
                      (touched && error ? ' text-danger' : ' staticLink')
                    }
                    title='Upload file'
                  />
                ) : (
                  <button className='bttn bttn-secondary small' disabled={disabled}>
                    {label}
                  </button>
                )}
                <input
                  type={type}
                  name={name}
                  // label={this.labelname}
                  value={value}
                  maxLength={maxLength}
                  onChange={onInputChange}
                  disabled={disabled}
                  accept={accept}
                  title={title}
                  autoFocus={autoFocus}
                />
                {parentClass === 'icon' ? null : (
                  <div className='errorMsg'>
                    {touched && error ? (
                      <>
                        {touched && error && <span data-testid='upload.errormessage'>{error}</span>}
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className={
            'inputWrapper clearfix ' +
            parentClass +
            (touched && error ? ' errorWrapper' : '') +
            (optional ? ' optional' : '')
          }
        >
          <div>
            <label htmlFor={name}>{label}</label>
            <>
              {reduxform ? (
                <input
                  {...input}
                  type={type}
                  className={classNames}
                  name={name}
                  maxLength={maxLength}
                  placeholder={placeholder}
                  disabled={disabled === 'true' ? true : false}
                  autoFocus={autoFocus}
                />
              ) : (
                <input
                  {...input}
                  type={type}
                  className={classNames}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  maxLength={maxLength}
                  onChange={onInputChange}
                  disabled={disabled === 'true' ? true : false}
                  autoFocus={autoFocus}
                />
              )}
            </>
          </div>
          {optional === true ? <span>(optional)</span> : optional ? <span>({optional})</span> : ''}
          {showMaxLength && maxLength ? (
            <span data-testid='maxlengthText'>
              ({valueLengthComponent > 0 ? valueLengthComponent : 0}){' '}
            </span>
          ) : (
            ''
          )}
          {touched && error ? (
            <div data-testid='error.message' className='errorMsg'>
              <>{touched && error && <span>{error}</span>}</>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

/** @deprecated use DSTextInput instead */
export const DeprecatedInput = memo(DeprecatedInputComponent);
