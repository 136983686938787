import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import * as services from './reminders.services';
import { ContractReminder } from './reminders';
import { toReminder } from './useReminders';
import { tryCatchLog } from 'src/utils/errors';

export const contractRemindersQuerySelector = selectorFamily<
  ContractReminder[],
  string | undefined
>({
  key: 'contractRemindersQuerySelector',
  get: contractId => async () => {
    return await tryCatchLog(async () => {
      const apiReminders = await services.searchReminders({ contractId });
      return apiReminders.map(r => toReminder(r));
    }, `contractRemindersQuerySelector(${contractId}) => getReminders`);
  },
});

/**
 * @param {string} contractId
 */
export const contractRemindersState = atomFamily<
  ContractReminder[],
  string | undefined // contractId
>({
  key: 'contractRemindersState',
  default: contractRemindersQuerySelector,
});

export const remindersPreviewQuery = selector<boolean>({
  key: 'remindersPreviewQuery',
  get: async () => {
    return await tryCatchLog(async () => {
      const preferences = await services.getPreviewPreferences();
      return !preferences.hidePreview;
    }, 'remindersPreviewQuery => getPreviewPreferences');
  },
});

/**
 * Whether the reminders component for this path has been hidden
 * @param {string} pathname
 * TODO: This has to be persisted between user sessions, so will need to add some backend handling
 * and a service call
 */
export const remindersPreviewState = atom<boolean>({
  key: 'remindersPreviewState',
  default: remindersPreviewQuery,
});
