import * as texts from '../review-your-contract/ReviewYourContract.texts';
import { commonLabels } from 'src/shared/constants';
import { TermUnit } from '../../../contract-management.d';

export const contractDetailsText = {
  /** Error */
  error: commonLabels.error,
  errorMessage: 'There was an error saving this contract. Please try again.',

  /** Contract Name */
  name: texts.labels.name,
  nameMessage: 'For example: "Runway Paving"',

  /** Contract Id */
  solicitationId: texts.labels.solicitationId,
  solicitationIdMessage: 'How will you identify your contract on DemandStar?',

  /** Contract Amount */
  amount: texts.labels.amount,

  /** Contract Start Date */
  startDate: texts.labels.startDate,

  /** Scope of Work */
  scopeOfWork: texts.labels.scopeOfWork,

  /** Base Period */
  basePeriod: 'Base Period',

  /** Term Units */
  // TODO: 21 July, 2022 - At this point, we do not have a use case making the termUnit lower case. The code below is
  // future-proofing. However, there is missing branch coverage this code is currently unreachable, so when that changes
  // let's also add tests.
  termUnits: (length: number, unit: TermUnit, lowerCase?: boolean) => {
    switch (unit) {
      case TermUnit.Days:
        const days = length === 1 ? 'Day' : 'Days';
        return lowerCase ? days.toLowerCase() : days;
      case TermUnit.Weeks:
        const weeks = length === 1 ? 'Week' : 'Weeks';
        return lowerCase ? weeks.toLowerCase() : weeks;
      case TermUnit.Months:
        const months = length === 1 ? 'Month' : 'Months';
        return lowerCase ? months.toLowerCase() : months;
      case TermUnit.Years:
        const years = length === 1 ? 'Year' : 'Years';
        return lowerCase ? years.toLowerCase() : years;
    }
  },

  /** Multiple Terms */
  multipleTerms: 'This contract has multiple terms or extensions.',

  /** Terms of Extensions Remaining */
  termsRemaining: 'Terms or Extensions Remaining',
  termsRemainingMessage:
    'If this contract is already active, this does not include the current term.',
  termsRemainingLabel: (i: number) => `Term ${i + 1} Length`,
  eachTermIsSameLength: 'Each term or extension is for the same length of time',

  /** Contract End Date */
  endDate: texts.labels.endDate,

  /** Primary Agency Contact */
  primaryAgencyContact: 'Primary Agency Contact',
  contactName: 'Primary Agency Contact Name',
  contactNameMessage: 'Who does the supplier call if they need help with this contract?',
  contactPhone: 'Primary Agency Contact Phone',
  isPhonePublic: 'Make this phone number publicly visible?',
  contactEmail: 'Primary Agency Contact Email',
  isEmailPublic: 'Make this email publicly visible?',
  /** Other Agencies? */
  otherAgencies: [
    'Are other agencies allowed to buy off this contract?',
    "Just to let you know: we're working to build shared contract features into DemandStar. Making a choice here helps us set you up for success when those features come online!",
  ],
} as const; // This assertion allows the developer to easily mouse over these constants to see what they return.
