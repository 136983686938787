import { ReactNode } from 'react';

import { H2, H3, H4 } from '@demandstar/components/styles';

import {
  StyledWizardPage,
  WizardContentPanel,
  WizardDescription,
  WizardHeader,
  WizardNavigationPanel,
} from './WizardStyles';
import { WizardNav, WizardNavProps } from './WizardNav';
import { WizardPage } from '../../../types/wizard';

interface WizardContainerProps extends WizardNavProps {
  children: ReactNode;
  currentWizard: WizardPage; // TODO: replace this with a simple id and add a useMemo hook to get this info
  header: string;
  testId?: string;
  navTitle: string;
  description?: string;
  navComponent?: ReactNode;
  navComponentTitle?: string;
}

export const WizardContainer = (props: WizardContainerProps) => {
  const {
    allowForwardNav,
    children,
    currentWizard,
    description,
    disableNav,
    header,
    navComponent,
    navComponentTitle,
    navigateTo,
    navTitle,
    testId,
    wizard,
  } = props;

  return (
    <StyledWizardPage>
      <WizardHeader>
        <H2 data-testid={testId}>{header}</H2>
        <WizardDescription>{description}</WizardDescription>
      </WizardHeader>
      <WizardNavigationPanel>
        {navComponent ? (
          <>
            <H4>{navComponentTitle}</H4>
            {navComponent}
          </>
        ) : (
          <></>
        )}
        <H4>{navTitle}:</H4>
        <WizardNav
          wizard={wizard}
          navigateTo={navigateTo}
          allowForwardNav={allowForwardNav}
          disableNav={disableNav}
        />
      </WizardNavigationPanel>
      <WizardContentPanel>
        <H3>
          {currentWizard.id} of {wizard.length}: {currentWizard.name}
        </H3>
        {children}
      </WizardContentPanel>
    </StyledWizardPage>
  );
};
