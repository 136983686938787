import React, { useState, useEffect } from 'react';
import { ModalPopUp } from './modals';
import { Buttons } from '../customcontrols';
import { toastFn } from 'src/utils/helpers';

interface TagDetailsPopupProps {
  tag: string;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (updatedTag: string) => void;
  onDelete?: (tag: string) => void;
  popUpFor?: string;
}

const TagDetailsPopup: React.FC<TagDetailsPopupProps> = ({
  tag,
  isOpen,
  onClose,
  onUpdate,
  onDelete,
  popUpFor,
}) => {
  const [tagName, setTagName] = useState('');
  const [rating, setRating] = useState('');

  useEffect(() => {
    const [parsedTagName, parsedRating] = tag.split(':');
    setTagName(parsedTagName || '');
    setRating(parsedRating || '');
  }, [tag]);

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rating = Math.min(Math.max(parseFloat(event.target.value) || 0, 0), 10);
    const formattedRating = rating % 1 === 0 ? `${rating}/10` : `${rating.toFixed(1)}/10`;

    setRating(formattedRating);
  };
  const handleUpdate = () => {
    if (tagName === '' || rating === '') {
      toastFn('error', 'Invalid Tag , Please Fill Tag Name and Weightage');
    } else {
      const updatedTag = `${tagName}:${rating}`;
      onUpdate(updatedTag);
      onClose();
    }
  };
  const handleDelete = () => {
    if (onDelete) onDelete(tag);
    onClose();
  };

  return (
    <ModalPopUp
      title={popUpFor === 'editTag' ? `${tag}` : 'Add Tag'}
      size='md'
      isOpen={isOpen}
      closeModal={onClose}
    >
      <div>
        <label className='font-50'>Tag Name</label>
        <input
          type='text'
          value={tagName}
          onChange={e => setTagName(e.target.value)}
          className='form-control'
        />
      </div>
      <div>
        <label className='mt-2'> Weightage (out of 10)</label>
        <input
          type='number'
          value={parseFloat(rating)}
          onChange={handleRatingChange}
          min='0'
          max='10'
          step='0.1'
          className='form-control'
        />
      </div>
      <div>
        <label className='mt-2'>Final Weightage</label>
        <input type='text' value={rating || 0} readOnly className='form-control' />
      </div>
      {popUpFor === 'editTag' && (
        <Buttons
          classNames='bttn-secondary Tag mt-3 float-left'
          text='Delete Tag'
          type='button'
          onClick={handleDelete}
        />
      )}
      <Buttons
        classNames='bttn-primary Tag mt-3 float-right'
        text={popUpFor === 'editTag' ? `Update Tag` : 'Add Tag'}
        type='button'
        onClick={handleUpdate}
      />
    </ModalPopUp>
  );
};

export default TagDetailsPopup;
