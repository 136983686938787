import { selector, selectorFamily } from 'recoil';

import { AddressCounty, AddressState, MetroDetails } from './address';
import { getAllCountiesByState, getAllStates, getMetroDetails } from 'src/store/services';
import { tryCatchLog } from 'src/utils/errors';
import { zipCodeRegex } from 'src/components/common/formcomponents/AddressLookup/helpers';

export const allStatesQuerySelector = selector<AddressState[]>({
  key: 'allStatesQuerySelector',
  get: async () => {
    return await tryCatchLog(async () => {
      // API call
      const response = await getAllStates();
      return response;
    }, 'allStatesQuerySelector');
  },
});

export const countiesByStateQuerySelector = selectorFamily<AddressCounty[], number | undefined>({
  key: 'countiesByStateQuerySelector',
  get: stateId => async () => {
    if (!stateId) {
      return [];
    }
    return getAllCountiesByState(stateId);
  },
});

export const metroDetailsQuerySelector = selectorFamily<
  MetroDetails | Record<string, never> | null,
  string | undefined
>({
  key: 'countiesByStateQuerySelector',
  get: postalCode => async () => {
    if (postalCode && postalCode.length >= 5 && zipCodeRegex.test(postalCode)) {
      return getMetroDetails(postalCode);
    }
    return null;
  },
});
