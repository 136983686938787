import DropIn, { Options } from 'braintree-web-drop-in-react';

import { appKeys } from '../../../utils/constants';

interface PaymentMethodProps {
  /** if this is a change of existing card-on-file */
  isEditPayment?: boolean;
  /** function called when instance is set by Braintree */
  setPaymentInstance: (instance: PaymentInstance) => void;
  /** Section title */
  title?: string;
}

export interface PaymentInstance {
  requestPaymentMethod: () => { nonce: string };
}

/**
 * @description a wrapper for the Braintree payment entry instance
 * @returns JSX.Element
 *
 * @default { isEditPayment: false, title: '' }
 * @example <PaymentMethod
 * setPaymentInstance={(instance) => {}}
 * title='Payment Method' />
 */
export const PaymentMethod = ({
  isEditPayment = false,
  setPaymentInstance,
  title = '',
}: PaymentMethodProps) => {
  const message = isEditPayment
    ? 'Add a payment method'
    : 'Add a payment method to proceed with your purchase';

  const dropInOptions: Options = {
    translations: {
      chooseAWayToPay: message,
    },
    authorization: appKeys.BTkey,
    card: {
      cardholderName: {
        required: true,
      },
    },
  };

  return (
    <>
      {title && <title>{title}</title>}
      {appKeys && appKeys.BTkey ? (
        <DropIn
          options={dropInOptions}
          onInstance={(instance: PaymentInstance) => setPaymentInstance(instance)}
        />
      ) : (
        <div>
          <h1>Loading...</h1>
        </div>
      )}
    </>
  );
};
