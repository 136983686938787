import { Country, sortDirections } from '../../utils/constants';

import * as actionTypes from '../actionTypes';

import { AcceptedFormat } from '../../types/publications';
import { AllowedExtension } from '../../utils/constants/document';
import { deprecatedGetDate } from '../../utils/helpers';
import { GeoState } from '../../types/sharedtypedef';
import { programsParamType, userAccountCardInfo } from '../../types/accountinfo';
import { Product } from 'src/types/subscriptions';

export interface AccountInfoState {
  accountinfo?: {
    accountinfo: { memberInfo: Record<string, unknown>; products: Product[] };
    commodityCodes: any[];
    userAccounts: any[];
    products: Product[];
  };
  profileInfo?: { card?: any };
  geoStates: GeoState[];
  geoCountries: Country[];
  geoCounties: any[];
  certificationStatus: {
    title: string;
    label: string;
    value: string;
    selected: boolean;
    key: 'certificateStatus';
  }[];
  currentPage: number;
  totalPrograms: number;
  payments: any[];
  addusercheck: boolean;
  membersinfo: {
    companyname: string;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    phoneExtension: string;
    faxNumber: string;
    faxExtension: string;
    country: string;
    website: string;
    state: string | { name: string };
    county: string | { name: string };
  };
  billingmembersinfo: {
    companyname: string;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    phoneExtension: string;
    faxNumber: string;
    faxExtension: string;
    country: string;
    website: string;
    state: string;
    county: string;
  };
  agencypublications: [];
  publicationmodal: boolean;
  publicationName: string;
  editpublication: string;
  agencybidtypes: [];
  bidtypesnmodal: boolean;
  bidtypesType: string;
  bidtypesDesc: string;
  bidtypesdeletemodal: boolean;
  publicationdeletemodal: boolean;
  agencydocs: {
    acceptedFormats: AcceptedFormat[];
    requiredDocuments: { responseItemId: number }[];
    DocAllowedExt: AllowedExtension[];
    convertableTypes: string[];
  };
  savedLegalData: string;
  halfWayCancel: boolean;
  halfWayCancelAllow: boolean;
  paymentvals: {
    activeTab: string; //maybe should be number instead?
    isEditBilling: boolean;
    isPrintReceipt: boolean;
    paymentInfo: Record<string, unknown>;
  };
  reloadBillingInfo: boolean;
  reloadABillingInfo: boolean;
  orderinfo: string;
  orders: [];
  filtered_orders: [];
  orderStatusList: {
    id: number;
    value: string; // value and title appear to be the same each time. kill one?
    title: string; // get more specific? 'All', 'Complete', 'Cancelled', 'In Progress', 'Pending', 'Pending Payment' ?
    key: number; // seems to be the same as id each time? kill it?
    selected: boolean;
  }[];
  orderFilters: {
    bidName: string;
    buyerName: string;
    bidIdentifier: string;
    status: string; // get more specfific? 'All'
    sortBy: string;
    sortOrder: string;
  };
  autoApprovalStatus: boolean;
  payment_history_internalLoader: boolean;
  billing_address_internalLoader: boolean;
  card_internalLoader: boolean;
  orders_internalLoader: boolean;
  subscription_internalLoader: boolean;
  useraccounts_internalLoader: boolean;
  agencySelection: boolean;
  ebiddocumentPopupdata: string;
  ebiddocumentPopup: boolean;
  requiredDocuments_internalLoader: boolean;
  ebiddocumentPopupDelete: boolean;
  ebiddocumentreorder: boolean;
  newSubscriptionAmount: 0.0;
  suppDocDownloadStatus: boolean;
  addUpdateUserAccountResult?: Record<string, unknown>;
  forgotPasswordResult: unknown;
  updateMemberInfoResult: unknown;
  updateBTProfile: unknown;
  updateMemberBillingInfoResult: unknown;
  saveSelfDeclarationsResult: unknown;
  programs?: programsParamType[];
  addMemberProgramCertificationResult: unknown;
  programAttributes?: any[];
  updateAutoRenewalResult: unknown;
  userAccounts?: { accountId: number; active: boolean }[];
  permissions: unknown;
  accountActivationResult: unknown;
  templateList: any[];
  orders_sortBy: string;
  orders_sortOrder: string;
  userAccountCardInfo?: userAccountCardInfo;
  card_info_Loader: boolean;
  mtn?: string;
  isDefaultCard: boolean;
}

export const initialStateAccountInfo: AccountInfoState = {
  accountinfo: {
    accountinfo: { memberInfo: {}, products: [] },
    commodityCodes: [],
    userAccounts: [],
    products: [],
  },
  profileInfo: {},
  geoStates: [],
  geoCountries: [
    {
      title: 'United States of America',
      label: 'United States of America',
      value: 1,
      selected: false,
      key: 'usa',
    },
    {
      title: 'Canada',
      label: 'Canada',
      value: 5,
      selected: false,
      key: 'canada',
    },
  ],
  geoCounties: [],
  certificationStatus: [
    {
      title: 'Any',
      label: 'Any',
      value: 'Any',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Applying',
      label: 'Applying',
      value: 'AP',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Certified',
      label: 'Certified',
      value: 'CT',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Expired',
      label: 'Expired',
      value: 'EX',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Rejected',
      label: 'Rejected',
      value: 'RJ',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Renewing',
      label: 'Renewing',
      value: 'RN',
      selected: false,
      key: 'certificateStatus',
    },
    {
      title: 'Unknown',
      label: 'Unknown',
      value: 'UN',
      selected: false,
      key: 'certificateStatus',
    },
  ],
  currentPage: 1,
  totalPrograms: 0,
  payments: [] as Array<Record<string, unknown>>,
  addusercheck: false,
  membersinfo: {
    companyname: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber: '',
    faxExtension: '',
    country: '',
    website: '',
    state: '',
    county: '',
  },
  billingmembersinfo: {
    companyname: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber: '',
    faxExtension: '',
    country: '',
    website: '',
    state: '',
    county: '',
  },
  agencypublications: [],
  publicationmodal: false,
  publicationName: '',
  editpublication: '',
  agencybidtypes: [],
  bidtypesnmodal: false,
  bidtypesType: '',
  bidtypesDesc: '',
  bidtypesdeletemodal: false,
  publicationdeletemodal: false,
  agencydocs: {
    acceptedFormats: [],
    requiredDocuments: [],
    DocAllowedExt: [],
    convertableTypes: [],
  },
  savedLegalData: '',
  halfWayCancel: false,
  halfWayCancelAllow: false,
  paymentvals: {
    activeTab: '1',
    isEditBilling: false,
    isPrintReceipt: false,
    paymentInfo: {},
  },
  reloadBillingInfo: true,
  reloadABillingInfo: true,
  orderinfo: '',
  orders: [],
  filtered_orders: [],
  orderStatusList: [
    { id: 0, value: 'All', title: 'All', key: 0, selected: true },
    { id: 1, value: 'Complete', title: 'Complete', key: 1, selected: false },
    { id: 2, value: 'Cancelled', title: 'Cancelled', key: 2, selected: false },
    { id: 3, value: 'In Progress', title: 'In Progress', key: 3, selected: false },
    { id: 4, value: 'Pending', title: 'Pending', key: 4, selected: false },
    { id: 5, value: 'Pending Payment', title: 'Pending Payment', key: 5, selected: false },
  ],
  orderFilters: {
    bidName: '',
    buyerName: '',
    bidIdentifier: '',
    status: 'All',
    sortBy: 'sentDate',
    sortOrder: sortDirections.DESC,
  },
  autoApprovalStatus: false,
  payment_history_internalLoader: true,
  billing_address_internalLoader: true,
  card_internalLoader: true,
  orders_internalLoader: true,
  subscription_internalLoader: true,
  useraccounts_internalLoader: true,
  agencySelection: false,
  ebiddocumentPopupdata: '',
  ebiddocumentPopup: false,
  requiredDocuments_internalLoader: true,
  ebiddocumentPopupDelete: false,
  ebiddocumentreorder: false,
  newSubscriptionAmount: 0.0,
  suppDocDownloadStatus: false,
  addUpdateUserAccountResult: undefined,
  forgotPasswordResult: null,
  updateMemberInfoResult: null,
  updateBTProfile: null,
  updateMemberBillingInfoResult: null,
  saveSelfDeclarationsResult: null,
  programs: [],
  addMemberProgramCertificationResult: null,
  programAttributes: [],
  updateAutoRenewalResult: null,
  userAccounts: undefined,
  permissions: undefined,
  accountActivationResult: null,
  templateList: [],
  orders_sortBy: '',
  orders_sortOrder: '',
  card_info_Loader: true,
  isDefaultCard: false,
};

export const reducer = (state = initialStateAccountInfo, action: any) => {
  let userAccounts = [] as Array<{ accountId: number; active: boolean }>;
  switch (action.type) {
    case actionTypes.LOAD_ACCOUNT_INFO.SUCCESS:
      state = {
        ...state,
        accountinfo: {
          ...state.accountinfo,
          ...action.payload,
          subscription_internalLoader: false,
        },
      };
      break;
    /* case actionTypes.LOAD_ACCOUNT_INFO_COMMODITY.SUCCESS:
    state = {
      ...state,
      accountinfo: { ...state.accountinfo, commodityCodes: action.payload } as any,
      // This seems wrong. Here, commodityCodes is referring to state.accountinfo.commodityCodes
      // That doesn't exist (yet)
      // I think it's meant to refer to state.accountinfo.accountinfo.commodityCodes.
      // Fwiw, I think it's worth restructuring this so that we avoid:
      // state.accountinfo.accountinfo.accountinfo which we DO have, right now.
      // I've left this unedited because I'm not familiar with this part of the code,
      // but if this is causing a bug, hopefully this note is helpful.
    };
    break; */
    case actionTypes.LOAD_ACCOUNT_INFO.FAILURE:
      state = { ...state, accountinfo: undefined, subscription_internalLoader: false };
      break;
    case actionTypes.LOAD_PROFILE_INFO.SUCCESS:
      state = {
        ...state,
        profileInfo: action.payload,
        card_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_PROFILE_INFO.FAILURE:
      state = { ...state, profileInfo: undefined, card_internalLoader: false };
      break;
    case actionTypes.ADD_UPDATE_USER_ACCOUNT.SUCCESS:
      state = {
        ...state,
        addUpdateUserAccountResult: action.payload.result,
      };
      break;
    case actionTypes.ADD_UPDATE_USER_ACCOUNT.FAILURE:
      state = { ...state, addUpdateUserAccountResult: action.payload ? action.payload : null };
      break;
    /* case actionTypes.FORGOT_PASSWORD.SUCCESS:
      state = {
        ...state,
        forgotPasswordResult: action.payload,
      };
      break; */
    case actionTypes.FORGOT_PASSWORD.FAILURE:
      state = { ...state, forgotPasswordResult: null };
      break;
    case actionTypes.UPDATE_MEMBER_INFO.SUCCESS:
      state = { ...state, updateMemberInfoResult: action.payload };
      break;
    case actionTypes.UPDATE_MEMBER_INFO.FAILURE:
      state = { ...state, updateMemberInfoResult: null };
      break;
    case actionTypes.ADD_UPDATE_BT_PROFILE.SUCCESS:
      state = {
        ...state,
        updateBTProfile: action.payload,
        paymentvals: { ...state.paymentvals, isEditBilling: false },
      };
      break;
    case actionTypes.ADD_UPDATE_BT_PROFILE.FAILURE:
      state = { ...state, updateBTProfile: null };
      break;
    case actionTypes.UPDATE_MEMBER_BILLING_INFO.SUCCESS:
      state = {
        ...state,
        updateMemberBillingInfoResult: action.payload,
        paymentvals: { ...state.paymentvals, isEditBilling: false },
      };
      break;
    case actionTypes.UPDATE_MEMBER_BILLING_INFO.FAILURE:
      state = { ...state, updateMemberBillingInfoResult: null };
      break;
    case actionTypes.RESET_ACCOUNTINFO_STATE.ACTION:
      state = Object.assign({}, state, action.payload);
      break;
    case actionTypes.SAVE_SELFDECLARATIONS.SUCCESS:
      state = {
        ...state,
        saveSelfDeclarationsResult: action.payload.result,
      };
      break;
    case actionTypes.SAVE_SELFDECLARATIONS.FAILURE:
      state = { ...state, saveSelfDeclarationsResult: null };
      break;
    case actionTypes.SEARCH_PROGRAMS.SUCCESS:
      state = {
        ...state,
        programs: action.payload,
        totalPrograms: action.payload ? action.payload.length : null,
      };
      break;
    case actionTypes.SEARCH_PROGRAMS.FAILURE:
      state = { ...state, programs: undefined };
      break;
    case actionTypes.PROGRAMS_PAGE_CHANGE.ACTION:
      state = { ...state, currentPage: action.payload };
      break;
    case actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.SUCCESS:
      state = {
        ...state,
        ...{
          programs:
            state.programs && state.programs.length > 0
              ? state.programs.map(p => {
                  if (p.programId === action.payload.programId && action.payload.result === true) {
                    p.certificationStatus = 'AP';
                    p.statusName = 'Applying';
                    p.supplierMemberId = action.payload.memberId;
                    p.statusChangedDate = deprecatedGetDate(Date.now());
                  }
                  return p;
                })
              : [],
        },
        //	addMemberProgramCertificationResult: action.payload
      };
      break;
    case actionTypes.ADD_MEMBER_PROGRAM_CERTIFICATION.FAILURE:
      state = { ...state, addMemberProgramCertificationResult: null };
      break;
    case actionTypes.GET_PROGRAM_ATTRIBUTES.SUCCESS:
      state = {
        ...state,
        programAttributes: action.payload,
      };
      break;
    case actionTypes.GET_PROGRAM_ATTRIBUTES.FAILURE:
      state = { ...state, programAttributes: undefined };
      break;

    case actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.SUCCESS:
      state = {
        ...state,
        ...{
          profileInfo: state.profileInfo
            ? {
                ...state.profileInfo,
                card: state.profileInfo
                  ? { ...state.profileInfo.card, isAutoRenewalOn: action.payload.isAutoRenewalOn }
                  : { isAutoRenewalOn: action.payload.isAutoRenewalOn },
              }
            : {},
        },
        updateAutoRenewalResult: action.payload.data,
      };
      break;
    case actionTypes.UPDATE_AUTO_RENEWAL_SUBSCRIPTION.FAILURE:
      state = { ...state, updateAutoRenewalResult: null };
      break;
    case actionTypes.GET_USER_ACCOUNTS.SUCCESS:
      state = {
        ...state,
        userAccounts: action.payload,
        useraccounts_internalLoader: false,
      };
      break;
    case actionTypes.GET_USER_ACCOUNTS.FAILURE:
      state = { ...state, userAccounts: undefined, useraccounts_internalLoader: false };
      break;
    case actionTypes.GET_PERMISSIONS.SUCCESS:
      state = {
        ...state,
        permissions: action.payload,
      };
      break;
    case actionTypes.GET_PERMISSIONS.FAILURE:
      state = { ...state, permissions: undefined };
      break;

    case actionTypes.REACTIVATE_USER_ACCOUNT.SUCCESS:
    case actionTypes.UNBLOCK_USER_ACCOUNT.SUCCESS:
      if (state.userAccounts && state.userAccounts.length > 0) {
        userAccounts = state.userAccounts;
        userAccounts.forEach((item: { accountId: number; active: boolean }) => {
          if (action.payload.accountId === item.accountId) {
            item.active = !item.active;
            //	changedStatus = item.active
          }
        });
      }
      state = {
        ...state,
        ...{ ...state.userAccounts, userAccounts },
        accountActivationResult: action.payload,
      };
      break;
    case actionTypes.REACTIVATE_USER_ACCOUNT.FAILURE:
      state = { ...state, accountActivationResult: null };
      break;
    case actionTypes.GET_PAYMENT_HISTORY.SUCCESS:
      state = { ...state, payments: [...action.payload], payment_history_internalLoader: false };
      break;
    case actionTypes.GET_PAYMENT_HISTORY.FAILURE:
      state = { ...state, payments: [], payment_history_internalLoader: false };
      break;
    case actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.SUCCESS:
    case actionTypes.GET_ACCOUNT_INFO_MEMBER_DETAILS.ACTION:
      state = { ...state, ...action.payload };
      break;
    case actionTypes.SET_ACCOUNT_INFO_DETAILS.TRIGGER:
      state = { ...state, ...action.payload };
      break;
    case actionTypes.GET_ACCOUNT_INFO_PUBLICATIONS.SUCCESS:
    case actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_REMOVE.SUCCESS:
      state = { ...state, agencypublications: action.payload, publicationdeletemodal: false };
      break;
    case actionTypes.SUBMIT_ACCOUNT_INFO_PUBLICATIONS_ADD.SUCCESS:
      state = {
        ...state,
        agencypublications: action.payload,
        publicationmodal: false,
        publicationName: '',
        editpublication: '',
        requiredDocuments_internalLoader: false,
        halfWayCancel: false,
        halfWayCancelAllow: true,
      };
      break;
    case actionTypes.GET_ACCOUNT_INFO_BIDTYPES.SUCCESS:
    case actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_REMOVE.SUCCESS:
      state = {
        ...state,
        agencybidtypes: action.payload,
        bidtypesdeletemodal: false,
        halfWayCancel: false,
        halfWayCancelAllow: true,
      };
      break;
    case actionTypes.SUBMIT_ACCOUNT_INFO_BIDTYPES_ADD.SUCCESS:
      state = {
        ...state,
        agencybidtypes: action.payload,
        bidtypesnmodal: false,
        bidtypesType: '',
        bidtypesDesc: '',
        halfWayCancel: false,
        halfWayCancelAllow: true,
      };
      break;
    case actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.SUCCESS:
      state = {
        ...state,
        halfWayCancel: false,
        halfWayCancelAllow: true,
        ebiddocumentPopupdata: '',
        ebiddocumentPopup: false,
        requiredDocuments_internalLoader: false,
        ebiddocumentPopupDelete: false,
        ebiddocumentreorder: false,
      };
      break;
    case actionTypes.SUBMIT_ACCOUNT_INFO_REQUIRED_DOCS_UPDATE.FAILURE:
      state = { ...state, requiredDocuments_internalLoader: false };
      break;
    case actionTypes.GET_TEMPLATES_LIST.SUCCESS:
      state = { ...state, templateList: action.payload };
      break;
    case actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.SUCCESS:
      state = { ...state, agencydocs: action.payload, requiredDocuments_internalLoader: false };
      break;
    case actionTypes.GET_ACCOUNT_INFO_REQUIRED_DOCS.FAILURE:
      state = { ...state, requiredDocuments_internalLoader: false };
      break;
    case actionTypes.GET_ORDER_INFO.SUCCESS:
      state = { ...state, orderinfo: action.payload };
      break;
    case actionTypes.GET_ORDERS_LIST.SUCCESS:
      state = {
        ...state,
        orders: action.payload,
        filtered_orders: action.payload,
        orders_internalLoader: false,
      };
      break;
    case actionTypes.GET_ORDERS_LIST.FAILURE:
      state = { ...state, orders_internalLoader: false };
      break;
    case actionTypes.SET_ORDER_FILTERS.TRIGGER:
      state = {
        ...state,
        orderFilters: {
          ...state.orderFilters,
          ...action.payload,
        },
      };
      break;
    case actionTypes.RESET_ACCOUNT_INFO.TRIGGER:
      state = initialStateAccountInfo;
      break;
    case actionTypes.TOGGLE_DOC_AUTO_APPROVAL.SUCCESS:
    case actionTypes.GET_DOC_AUTO_APPROVAL_STATUS.SUCCESS:
      state = {
        ...state,
        autoApprovalStatus: action.payload && action.payload === true,
      };
      break;
    case actionTypes.MODIFY_SUBSCRIPTION.SUCCESS:
      state = {
        ...state,
        newSubscriptionAmount: action.payload.isReadOnly ? action.payload.subscriptionAmount : 0.0,
      };
      break;
    case actionTypes.RESET_MODIFY_SUBSCRIPTION.TRIGGER:
      state = { ...state, newSubscriptionAmount: 0.0 };
      break;
    case actionTypes.TOGGLE_SUPP_DOC_DOWNLOAD.SUCCESS:
    case actionTypes.GET_SUPP_DOC_STATUS.SUCCESS:
      state = {
        ...state,
        suppDocDownloadStatus: action.payload && action.payload === true,
      };
      break;
    case actionTypes.LOAD_USERACCOUNT_CARD_INFO.SUCCESS:
      state = {
        ...state,
        userAccountCardInfo: action.payload,
        card_info_Loader: false,
      };
      break;
    case actionTypes.LOAD_USERACCOUNT_CARD_INFO.FAILURE:
      state = { ...state, userAccountCardInfo: undefined, card_info_Loader: false };
      break;
    case actionTypes.RESET_CARD_INFO.TRIGGER:
      state = { ...state, userAccountCardInfo: undefined, card_info_Loader: true };
      break;
    case actionTypes.UPDATE_DEFAULT_CARD.SUCCESS:
      state = { ...state, isDefaultCard: true };
      break;
    case actionTypes.UPDATE_DEFAULT_CARD.FAILURE:
      state = { ...state, isDefaultCard: false };
      break;
    default:
      break;
  }
  return state;
};
