// FIXME: Capitalization throughout this file

import {
  getDefaultLoadingErrorMessage,
  getDefaultLoadingMessage,
  getDefaultNotFoundMessage,
} from '../../utils/message';

import { DETAILS_LABEL } from './messaging';

export const BID_LABEL = 'Bid';
export const BID_DETAILS_LABEL = `${BID_LABEL} ${DETAILS_LABEL}` as const;
export const BID_LABEL_PLURAL = `${BID_LABEL}s` as const;

export const RESPONSE_LABEL = 'Response';
export const RESPONSE_DETAILS_LABEL = `${RESPONSE_LABEL} ${DETAILS_LABEL}` as const;
export const RESPONSE_LABEL_PLURAL = `${RESPONSE_LABEL}s` as const;

export const BID_RESPONSE_LABEL = `${BID_LABEL} ${RESPONSE_LABEL}`;
export const BID_RESPONSE_LABEL_PLURAL = `${BID_RESPONSE_LABEL}s`;
export const BID_RESPONSES_ERROR_MESSAGE = getDefaultLoadingErrorMessage(BID_RESPONSE_LABEL_PLURAL);
export const BID_RESPONSES_LOADING_MESSAGE = getDefaultLoadingMessage(BID_RESPONSE_LABEL_PLURAL);
export const BID_RESPONSES_NOT_FOUND_MESSAGE = getDefaultNotFoundMessage(BID_RESPONSE_LABEL_PLURAL);

/**
 * Bid Management
 */
export enum FileUploadLabels {
  ChooseFile = 'Choose a File',
  FileName = 'Filename',
  FileNameMessage = 'For example: “original_contract_document.” If you leave this blank, we’ll use the filename as uploaded.',
  DocumentTitle = 'Document Title',
  DocumentType = 'Document Type',
  ErrorRequiredDocumentType = 'Document Type is required',
}

export enum BidSummaryLabels {
  agencyName = 'Agency Name',
  bidName = 'Bid Name',
  bidNumber = 'Bid Number',
  dueDate = 'Bid Due Date',
  dueDateCountdown = 'Due Date Countdown',
  dueDatePrefix = 'This bid is due',
  edit = 'Edit',
  responseDate = 'Response Submitted On',
  state = 'State',
  status = 'Status',
  title = 'Bid Summary',
  unwatch = 'Unwatch',
  watch = 'Watch',
}

export enum RequiredActionLabels {
  dueDate = 'Due Date',
  dueDateCountdown = 'Due Date Countdown',
  dueDatePrefix = 'Due',
  dueDateTitlePrefix = 'This item is due',
  id = 'ID',
  message = 'Action',
  overduePrefix = 'Overdue since',
  type = 'Type',
}

export enum BidResponseLabels {
  agencyRequiredDocuments = 'Agency Required Documents',
  closed = 'Closed',
  responseDate = 'Response Date',
  status = 'Status',
  supplementalDocuments = 'Supplemental Documents',
  uploadedDocuments = 'Uploaded Documents',
  viewHistory = 'View History',
}

export enum AwardBidLabels {
  // FIXME: Capitalization
  AddMoreDocuments = 'Add More Documents',
  awardDocsSpecificVendorTitlePrefix = 'Documents for',
  awardDocumentType = 'Award Document Type',
  awardeeRequired = 'You must select an Awardee to continue.',
  dueDate = 'Due Date',
  errorRequiredDocumentType = 'Award Document Type is required',
  progressTitle = 'Award Bid Progress',
  requiredActions = 'Required Actions',
  requiredDocsAllVendorsTitle = 'Award Documents Required from All Vendors',
  RequiredDocsTitlePrefix = 'Documents Requested from',
  responseDate = 'Response Date',
  status = 'Status',
  supplementalDocuments = 'Supplemental Documents',
  type = 'Type',
  uploadedDocuments = 'Uploaded Documents',
  viewHistory = 'View History',
}
