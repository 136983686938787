import { useQuery } from '@tanstack/react-query'; 
import { getdashboarddataTracking } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetDashboardTrackingData = (data: any) => {
  return getdashboarddataTracking(data);
};

export const useGetDashboardTrackingData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardTrackingData'],
    queryFn: () => GetDashboardTrackingData(data), 
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};

