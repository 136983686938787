import { useRecoilValueLoadable } from 'recoil';
import { useState } from 'react';

import { Flex, FlexContainer, P } from '@demandstar/components/styles';
import { DSRadio } from '@demandstar/components/inputs';

import * as texts from './FinalizeAward.texts';
import {
  AwardWizardId,
  notifyAwardeesState,
  notifyColleaguesState,
  notifyUnselectedState,
} from '../../../../store/recoil/award-bid';
import { AwardWizardComponentProps } from '../AwardBid';
import { BoldText } from '../../../../shared/styles';
import { hasValue } from '../../../../utils/helpers';
import { useAwardBidWizard } from '../../../../shared/hooks';
import { useBid } from 'src/shared/hooks/useBid';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

enum FinalizeOption {
  None,
  RecommendationOfAward,
  AwardBid,
}

type FinalizeAwardProps = Pick<AwardWizardComponentProps, 'completeWizard'>;

export const FinalizeAward = (props: FinalizeAwardProps): JSX.Element => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, submitting, recommendAward } =
    useAwardBidWizard();
  const { bid } = useBid();

  // Recoil
  const awardeeEmailInfo = useRecoilValueLoadable(notifyAwardeesState);
  const unselectedEmailInfo = useRecoilValueLoadable(notifyUnselectedState);
  const colleagueEmailInfo = useRecoilValueLoadable(notifyColleaguesState);
  const hasColleagues = !!(
    hasValue(colleagueEmailInfo) && colleagueEmailInfo.contents.recipients.length
  );
  const { shouldNotify: shouldNotifyAwardees } = awardeeEmailInfo.valueMaybe() || {};
  const { shouldNotify: shouldNotifyUnselected } = unselectedEmailInfo.valueMaybe() || {};
  const bidName = bid?.bidName;
  const bidNumber = bid?.bidNumber;
  const { bidAwardees } = useBidAwardees();

  // Props
  const { completeWizard } = props;

  // Local State
  const [finalizeOption, setFinalizeOption] = useState<FinalizeOption>(FinalizeOption.None);

  function onPrevious() {
    if (hasColleagues) {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, true);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues, false);
    }
  }

  function onNext() {
    if (finalizeOption === FinalizeOption.AwardBid) {
      completeWizard();
    } else {
      recommendAward();
    }
  }

  return (
    <div>
      <FlexContainer>
        <Flex>
          <P data-testid='recommend.intro'>
            {texts.changeStatusIntro} <BoldText>{texts.recommendationOfAward}</BoldText>{' '}
            {texts.recommendationCopy[0](bidAwardees.length, shouldNotifyAwardees)}
          </P>
          <P>{texts.recommendationCopy[1]}</P>
          <P>
            <BoldText>{texts.recommendationOfAward}</BoldText> {texts.recommendationCopy[2]}
          </P>
        </Flex>
        <Flex>
          <P data-testid='award.intro'>
            {texts.changeStatusIntro} <BoldText>{texts.awarded}</BoldText>{' '}
            {texts.awardBidCopy[0](bidAwardees.length, shouldNotifyAwardees)}
          </P>
          {shouldNotifyUnselected && <P data-testid='award.outro'>{texts.awardBidCopy[1]}</P>}
        </Flex>
      </FlexContainer>
      <FlexContainer>
        <Flex>
          <DSRadio
            name='recommendAward'
            checked={finalizeOption === FinalizeOption.RecommendationOfAward}
            onSelect={() => setFinalizeOption(FinalizeOption.RecommendationOfAward)}
            label={texts.recommendationOfAward}
            dataTestId={'recommendAward'}
          />
        </Flex>
        <Flex>
          <DSRadio
            name='awardBid'
            checked={finalizeOption === FinalizeOption.AwardBid}
            onSelect={() => setFinalizeOption(FinalizeOption.AwardBid)}
            label={`${texts.award} ${bidNumber}: ${bidName}`}
            dataTestId={'awardBid'}
          />
        </Flex>
      </FlexContainer>
      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={
          finalizeOption === FinalizeOption.RecommendationOfAward
            ? texts.recommendAward
            : texts.awardBid
        }
        inactiveNext={submitting || finalizeOption === FinalizeOption.None}
        inactivePrev={submitting}
        save={returnToBidSummary}
      ></WizardButtons>
    </div>
  );
};
