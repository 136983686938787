import { atomFamily, selectorFamily } from 'recoil';

import { Awardee } from 'src/types/biddetail';
import { getBidAwardeeList } from 'src/store/services';
import { tryCatchLog } from 'src/utils/errors';

export const bidAwardeesRequestIdState = atomFamily({
  key: 'bidAwardeesRequestIdState',
  default: 0,
});

export const bidAwardeesQuerySelector = selectorFamily<Awardee[], number | undefined>({
  key: 'bidAwardeesQuerySelector',
  get:
    bidId =>
    async ({ get }) => {
      if (!bidId || bidId < 0) {
        return [];
      }
      get(bidAwardeesRequestIdState(bidId)); // Add request ID as a dependency
      return tryCatchLog(async () => {
        // API call
        const response = await getBidAwardeeList(bidId);
        const freshBidAwardees = response.map(awardee => {
          return {
            ...awardee,
            amount: awardee.amount ?? NaN,
          };
        });
        return freshBidAwardees;
      }, 'bidAwardeesQuerySelector');
    },
});
