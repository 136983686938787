import { atom, selector, useRecoilValueLoadable } from 'recoil';

import Configurations, { DSEnvTypes } from 'src/settings';
import { getCookie } from 'src/utils/cookie';
import { getTokenData } from 'src/utils/token';
import { tokenCookieName } from 'src/utils/constants';
import { tryCatchLog } from 'src/utils/errors';

export enum FeatureFlagKey {
  ContractManagement = 1,
  ContractSharing = 2,
}

/** Add a FeatureFlagKey to this array to release the feature for all members */
// TODO: for some reason, tests don't recognize this logic as enabling a feature/component:
// i.e. tests fail when expecting features to be enabled using this hook
const releasedFeatureFlags: FeatureFlagKey[] = [FeatureFlagKey.ContractManagement];

/** Add member ID to this list to add a member to Contract Management beta */
let cmMemberIds: number[] = [
  2460105, // agencydstest
  2460274, // City of Metropolis USA (Test)
  2490607, // Town of Vienna, Virginia
  2368737, // Gainesville Regional Utilities - Procurement Division Solicitations
  1161199, // Wisconsin Technical College System Purchasing Consortium
  2513929, // Manassas City Public Schools
  10251, // Jacksonville Aviation
  2353180, // City of St. Charles, IL
];
/** Add member ID to this list to add a member to Contract Sharing beta */
let csMemberIds: number[] = [];

if (Configurations.REACT_APP_DS_ENV !== DSEnvTypes.production) {
  // Add member IDs to this list to add a QA/Dev environment acct to CM beta
  cmMemberIds = [
    2460105, // agencydstest
    2460274, // City of Metropolis USA (Test)
  ];
  // Add member IDs to this list to add a QA/Dev environment acct to contract sharing beta
  csMemberIds = [
    2460105, // agencydstest
  ];
}

// TOREFACTOR: set this up to hit API
async function fakeEndpoint() {
  // Opting to get the token direct from cookie to avoid race condition w/ state
  const token = getCookie(tokenCookieName);
  const { memberId } = getTokenData(token);
  const featuresEnabled = [];
  if (cmMemberIds.includes(memberId)) featuresEnabled.push(FeatureFlagKey.ContractManagement);
  if (csMemberIds.includes(memberId)) featuresEnabled.push(FeatureFlagKey.ContractSharing);
  return featuresEnabled;
}

const enabledFeaturesQuery = selector<FeatureFlagKey[]>({
  key: 'enabledFeaturesQuery',
  get: async () => {
    const enabled = await tryCatchLog(
      async () => await fakeEndpoint(),
      'enabledFeaturesQuery => getEnabledFeatures',
    );
    return enabled.concat(releasedFeatureFlags);
  },
});

const enabledFeaturesState = atom<FeatureFlagKey[]>({
  key: 'enabledFeatureState',
  default: enabledFeaturesQuery,
});

export function useEnabledFeatures() {
  const enabledFeaturesLoadable = useRecoilValueLoadable(enabledFeaturesState);
  const enabledFeatures = enabledFeaturesLoadable.valueMaybe() || [];

  return {
    enabledFeatures,
    enabledFeaturesLoadable,
  };
}
