import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';

import { DSButton } from '@demandstar/components/button';
import { DSTextField } from '@demandstar/components/fields';
import { FlexContainer } from '@demandstar/components/styles';

import * as texts from './ManageDocumentTypes.texts';

import { ContractDocumentType } from 'src/features/contract-management/contract-management';
import { useContractDocuments } from '../useContractDocuments';

interface ManageDocumentTypesFormProps {
  /** if the form allows canceling */
  allowCancel?: boolean;
  /** Default value for title */
  defaultTitle?: string;
  /** An optional function to call after the form is closed (either save or cancel is called)
   * Only used for independent forms */
  onComplete?: () => void;
  /** Whether this component is nested inside of a form, or should be a self-contained form itself */
  withinForm?: boolean;
}

export const ManageDocumentTypesForm = ({
  allowCancel = false,
  defaultTitle = '',
  onComplete,
  withinForm = false,
}: ManageDocumentTypesFormProps) => {
  const { updateDocumentType } = useContractDocuments();

  const methods = useForm();
  const { handleSubmit } = methods;

  const handleComplete = useCallback(() => {
    /* istanbul ignore else */
    if (onComplete) onComplete();
  }, [onComplete]);

  const onSubmit = useCallback(
    (values: { addDocumentTypeDescription: string; addDocumentTypeTitle: string }) => {
      const newDocumentType: ContractDocumentType = {
        description: values['addDocumentTypeDescription'],
        isDeleted: false,
        title: values['addDocumentTypeTitle'],
      };
      updateDocumentType(newDocumentType);
      handleComplete();
    },
    [handleComplete, updateDocumentType],
  );

  const formFields = useMemo(() => {
    return (
      <>
        <DSTextField
          label={texts.newDocumentTypeTitle}
          name='addDocumentTypeTitle'
          defaultValue={defaultTitle}
        />
        <DSTextField label={texts.newDocumentTypeDescription} name='addDocumentTypeDescription' />
      </>
    );
  }, [defaultTitle]);

  const independentForm = useMemo(() => {
    return (
      <>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexContainer>{formFields}</FlexContainer>
            <FlexContainer justifyContent={allowCancel ? 'space-between' : 'flex-end'}>
              {allowCancel && (
                <DSButton type='secondary' onClick={handleComplete}>
                  {texts.cancel}
                </DSButton>
              )}
              <DSButton type='submit'>{texts.saveDocumentType}</DSButton>
            </FlexContainer>
          </form>
        </FormProvider>
      </>
    );
  }, [allowCancel, formFields, handleComplete, handleSubmit, methods, onSubmit]);

  return <>{withinForm ? formFields : independentForm}</>;
};
