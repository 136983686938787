import { ReactElement, ReactFragment } from 'react';

import { ButtonsFlexContainer } from './DSButton.styles';
import { DSButton } from './DSButton';

export type DSButtonGroupProps = {
  /** One or more buttons of same width to fill their container's horizontal space */
  children: ReactElement<typeof DSButton> | (ReactElement<typeof DSButton> | ReactFragment)[];
};

/**
 * @description A flex container to make buttons be of equal width and span the width of their container
 * @example
 * <DSButtonGroup>
 *  <DSButton>first button</DSButton>
 *  <DSButton>second button with long title but same width</DSButton>
 *  <DSButton>3rd</DSButton>
 * </DSButtonGroup>
 */
export const DSButtonGroup = (props: DSButtonGroupProps) => {
  const { children } = props;

  return <ButtonsFlexContainer>{children}</ButtonsFlexContainer>;
};
