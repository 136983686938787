import { useMemo } from 'react';

import { DSDollar } from '@demandstar/components/dollar';
import { DSEmail } from '@demandstar/components/email';
import { DSPhone } from '@demandstar/components/phone';

import { displayDate, hasValue } from 'src/utils/helpers';
import { BidDetailsLink } from 'src/components/common/controls/text/links/BidDetailsLink';
import { ContractDetailsRow } from './ContractDetailsRow';
import { contractDetailsText } from '../verify-contract-details/VerifyContractDetails.texts';
import { getTermArray } from 'src/features/contract-management/contract-management.helpers';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { useBid } from 'src/shared/hooks/useBid';
import { useContractDetails } from '../../../useContractDetails';

export const ContractDetailsSummary = () => {
  const { contractDetails, contractDetailsLoadable } = useContractDetails();
  const { bid } = useBid(contractDetails?.bidId);

  const extensionsString = useMemo(() => {
    let string = '';
    const extensions = contractDetails?.terms || [];
    if (extensions.length === 0) {
      return 'No extensions';
    }
    for (const extension of extensions) {
      const [length, unit] = getTermArray(extension.duration);
      string += `${length} ${contractDetailsText.termUnits(length, unit, true)}, `;
    }
    string = string.substring(0, string.length - 2);
    return string;
  }, [contractDetails?.terms]);

  return (
    <LoadableWrapper loadable={contractDetailsLoadable}>
      {hasValue(contractDetailsLoadable) && contractDetails && (
        <>
          <ContractDetailsRow field={'solicitationId'} />
          <ContractDetailsRow field={'name'} />
          <ContractDetailsRow label={'amount'}>
            <DSDollar>{contractDetails.amount}</DSDollar>
          </ContractDetailsRow>
          <ContractDetailsRow label={'extensions'}>{extensionsString}</ContractDetailsRow>
          <ContractDetailsRow label={'startDate'}>
            {contractDetails.startDate ? displayDate(contractDetails.startDate) : '--'}
          </ContractDetailsRow>
          <ContractDetailsRow label={'endDate'}>
            {contractDetails.endDate ? displayDate(contractDetails.endDate) : '--'}
          </ContractDetailsRow>
          {bid && (
            <ContractDetailsRow label={'bidNumber'}>
              <BidDetailsLink bidId={bid.bidId} newTab>
                {bid.bidNumber}
              </BidDetailsLink>
            </ContractDetailsRow>
          )}
          <ContractDetailsRow label={'contactName'}>
            {contractDetails.agencyContact.name}
          </ContractDetailsRow>
          <ContractDetailsRow label={'contactPhone'}>
            <DSPhone dataTestId='contract.summary.phone'>{`${contractDetails.agencyContact.phone}`}</DSPhone>
          </ContractDetailsRow>
          <ContractDetailsRow label={'contactEmail'}>
            <DSEmail dataTestId='contract.summary.email'>{`${contractDetails.agencyContact.email}`}</DSEmail>
          </ContractDetailsRow>
          <ContractDetailsRow field={'scopeOfWork'} />
        </>
      )}
    </LoadableWrapper>
  );
};
