import { useRecoilCallback } from 'recoil';

import {
  getDefaultEmailInfo,
  notifyAwardeesState,
  notifyUnselectedState,
} from '../../../store/recoil/award-bid';
import { Assert } from '../../../utils/helpers';
import { authState } from 'src/store/recoil/authState';
import { CustomMessageType } from '../../../types/awardBid';
import { getCustomAwardNotification } from '../../../store/services/awardBid';
import { memberInfoSelector } from '../../../store/recoil/memberState';
import { notifyAwardeeTexts } from '../../../utils/texts/agency/awardBid';
import { selectedBidIdState } from '../../../store/recoil/bidState';

export * from './useRequiredDocumentResponses';
export * from './useRequiredDocuments';
export * from './useAwardBidWizard';

export function useUpdateAwardeeAndNonAwardeeEmailInfo() {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const bidId = await snapshot.getPromise(selectedBidIdState);
        const memberInfo = await snapshot.getPromise(memberInfoSelector);
        const auth = await snapshot.getPromise(authState);
        Assert(
          !!memberInfo,
          'We should have memberInfo here',
          'useUpdateAwardeeAndNonAwardeeEmailInfo',
        );
        const awardeeEmailInfo = await getCustomAwardNotification({
          bidId,
          customMessageType: CustomMessageType.Awardees,
        });
        set(
          notifyAwardeesState,
          getDefaultEmailInfo(
            awardeeEmailInfo,
            memberInfo,
            auth.fullName,
            notifyAwardeeTexts.notificationDefaults.subject.awardees,
            notifyAwardeeTexts.notificationDefaults.customMessage.awardees,
          ),
        );
        const unselectedEmailInfo = await getCustomAwardNotification({
          bidId,
          customMessageType: CustomMessageType.NonAwardees,
        });
        set(
          notifyUnselectedState,
          getDefaultEmailInfo(
            unselectedEmailInfo,
            memberInfo,
            auth.fullName,
            notifyAwardeeTexts.notificationDefaults.subject.unselected,
            notifyAwardeeTexts.notificationDefaults.customMessage.unselected,
          ),
        );
      },
    [],
  );
}
