import styled from 'styled-components';

import { FlexContainer, Margin, Padding } from '../styles';

import { SearchInputWidth } from './DSSearch.d';

export const StyledSearchInput = styled.div<{ width: string | SearchInputWidth }>`
  display: inline-flex;
  flex-grow: 4;
  min-width: 10rem;
  width: ${({ width }) => width};
`;

export const StyledSearchInputRow = styled(FlexContainer)`
  row-gap: 0;
  margin-bottom: ${Margin.Base};
`;
// Rotate icon if it's toggled open
export const ToggleAdvancedSearchIcon = styled.div`
  svg {
    transform: rotate(180deg);
  }
`;

export const PaginationContainer = styled.div`
  width: 100%;
  margin: ${Margin.Extra} 0;
  padding: ${Padding.Base} 0;
`;
