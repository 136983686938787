import {
  AwardeeContact,
  ContractDocumentType,
} from 'src/features/contract-management/contract-management';
import { FileUploadRequest } from 'src/types';

type UploadFunction = (
  req: FileUploadRequest<{
    awardeeId?: string;
    contractId: string;
    documentType: ContractDocumentType;
    isPublic: boolean;
    title?: string;
    whitelistMemberId?: number;
  }>,
) => Promise<unknown>;

export type AddDocumentFormValues = {
  addDocumentTypeDescription?: string;
  addDocumentTypeTitle?: string;
  contract: string;
  documentType: ContractDocumentType;
  file: FileList;
  fileName?: string;
  supplier?: AwardeeContact;
  public: boolean;
};

export async function submitAddDocument(
  uploadContractDocument: UploadFunction,
  formValues: AddDocumentFormValues,
  contractId?: string,
) {
  const upload = {
    awardeeId: formValues.supplier?.id,
    contractId: contractId || formValues.contract,
    documentType: formValues.documentType,
    file: formValues.file[0],
    isPublic: formValues.public,
    title: formValues.fileName,
    whitelistMemberId: formValues.supplier?.memberId,
  };

  if (
    !formValues.documentType?.id &&
    formValues.addDocumentTypeDescription &&
    formValues.addDocumentTypeTitle
  ) {
    upload.documentType = {
      description: formValues.addDocumentTypeDescription,
      isDeleted: false,
      title: formValues.addDocumentTypeTitle,
    };
  }
  await uploadContractDocument(upload);
}
