import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../../store/actions';
import { accountinfoType, anyParamType } from '../../../../types/paymentdetails';
import { Buttons, Loader, NoResult } from '../../../customcontrols';
import {
  InternalLoaderNames,
  MemberType,
  relogintokenName,
  userPermissionKeys,
} from '../../../../utils/constants';

import { loadingMsg } from '../../../../utils/texts';
import { sharedParamType } from '../../../../types/commoditycodes';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';
import { hasPermission } from 'src/utils/helpers';
import { Authorization } from 'src/types';
import { useGetAccountInfoCommodity } from 'src/query/queries/getAccountInfoCommodity';
import { AppContext } from 'src/global-context/AppContext';
import { ModalPopUp } from '../../../common/modals';
import { EditCommodityCodesAndTags } from './editTagsAndCommodities';
import { useCommodities } from '../../../../shared/hooks/useCommodity';

interface propsTypes extends PropsFromRedux {
  accountinfo: accountinfoType;
  history: anyParamType;
  shared: sharedParamType;
  getaccountInfoCommodity: any;
  getRefreshToken: any;
  internalLoader: { internalLoaderPages: Array<string> };
}

const CommodityCodes = (props: propsTypes) => {
  const {
    getaccountInfoCommodity,
    history,
    getRefreshToken,
    shared,
    accountinfo,
    internalLoader: internalLoaderReducer,
  } = props;
  const { auth } = useAuthContext();
  const { addAITagsAndCommoditycodes } = useCommodities();
  const { commodityCodes = [] } = accountinfo || {};
  const { internalLoader } = shared;
  const { internalLoaderPages = [] } = internalLoaderReducer || {};
  const [loadingState, setLoadingState] = useState(false);
  const { fetchAccountCommodity, RefetchAccountCommodityData } = useContext(AppContext);
  const [showEditcommodityPopUp, setShowEditcommodityPopUp] = useState(false);
  const [showConfirmDelPopUp, setShowConfirmDelPopUp] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  // TODO: This logic is repeated in several components, should be abstracted into a utility or hook
  const { accountPermissions, mainContact: isPrimaryContact = false } =
    auth || ({} as Authorization);

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact;

  const {
    isSuccess: GetAccountInfoCommoditySuccess,
    isFetching: isAccountInfoCommodityFetching,
    data: myCommodityData,
    refetch: refetchGetAccountInfoCommodity,
  } = useGetAccountInfoCommodity();

  const finalMyTags = myCommodityData?.data?.result?.tags
    ?.split(',')
    .map(tag => tag.trim())
    .filter(tag => tag) || [];

  useEffect(() => {
    if (sessionStorage.getItem(relogintokenName) && auth && auth?.token && auth?.userName) {
      sessionStorage.removeItem(relogintokenName);
      getRefreshToken();
    }
  }, [getRefreshToken, auth]);

  useEffect(() => {
    if (auth?.memberType && auth?.memberType === MemberType.BasicSupplier) history.replace('/');
  }, [auth, history]);

  const GoToPage = useCallback(() => {
    history.push('/suppliers/account/commoditycodes/edit');
  }, [history]);

  const handleTagdeleteFromList = (tag: string) => {
    setSelectedTag(tag);
    setShowConfirmDelPopUp(true);

  };

  const handleConfirmDeleteTag = async () => {
    const updatedTags = finalMyTags.filter(t => t !== selectedTag);
    setShowConfirmDelPopUp(false);
    await addAITagsAndCommoditycodes({ memberId: auth.memberId, tags: updatedTags });
    refetchGetAccountInfoCommodity();
    setShowConfirmDelPopUp(false);
  };

  useEffect(() => {
    if (fetchAccountCommodity) {
      refetchGetAccountInfoCommodity();
      RefetchAccountCommodityData(false);
    }
  }, [fetchAccountCommodity, refetchGetAccountInfoCommodity, RefetchAccountCommodityData]);

  useSetBreadcrumb({
    component: hasEditMemberPermission ? (
      <li>
        <span className='staticLink' onClick={() => GoToPage()}>
          <i className='mdi mdi-square-edit-outline mr-1' /> Edit
        </span>
      </li>
    ) : (
      ''
    ),
    page: 'Supplier Account Commodity Codes',
    title: 'Commodity Codes',
    altname: 'Commodity Codes',
    name: 'Commodity Codes',
  });


  return (
    <>
      {isAccountInfoCommodityFetching && (
        <Loader loading={isAccountInfoCommodityFetching}></Loader>
      )}
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='colWrapper'>
              <div className='row '>
                <div className='col-8'>
                  <h3 className='arrowWrapper'>Commodity Codes</h3>
                  <div className='innerColIndent'>
                    {myCommodityData?.data.result.commodityCodes.length > 0 ? (
                      <>
                        <h4>
                          Selected Codes ({myCommodityData?.data?.result?.commodityCodes.length})
                        </h4>
                        {myCommodityData?.data?.result?.commodityCodes.map(
                          (
                            commodity: {
                              formattedCode: any;
                              fullCode: any;
                              commodityDescription: any;
                            },
                            id: any,
                          ) => (
                            <p key={id} className='mb-3'>
                              <i className='staticTxt'>
                                [{commodity.formattedCode || commodity.fullCode}]
                              </i>{' '}
                              {commodity.commodityDescription}
                            </p>
                          ),
                        )}
                      </>
                    ) : (
                      <NoResult
                        message={
                          internalLoader
                            ? loadingMsg
                            : 'Select Commodity Codes to receive bid notifications.'
                        }
                      />
                    )}
                    {hasEditMemberPermission && (
                      <Buttons
                        classNames='bttn-primary mt-2 float-right'
                        text= {myCommodityData?.data?.result?.commodityCodes.length > 0 ?'Edit Commodity Codes':'Add Commodity Codes'}
                        type='button'
                        onClick={GoToPage}
                      />
                    )}
                  </div>
                </div>
                <div className='col col-4 mt-5'>
                  <div className='innerColIndent mt-1'>
                    <div className='row mt-2'>
                      <div className='col'>
                        <h4>Selected Tags ({finalMyTags?.length}) </h4>
                        <div className='row'>
                          {finalMyTags.map((tag, index) => (
                            <div key={index} className="selected-tag mr-1 ml-1 bold">
                              {tag}
                              <span
                                className="ml-1 remove-tag cursorPointer"
                                onClick={() => handleTagdeleteFromList(tag)}
                              >
                                &#10005;
                              </span>
                            </div>
                          ))}
                          {myCommodityData?.data?.result?.tags?.length === 0 ? (
                            <NoResult
                              message={
                                'No tags added yet. Adding Tags is free, it helps to receive bid notifications that are tailored for you.'
                              }
                            />
                          ) : null}
                        </div>

                      </div>
                    </div>


                    {hasEditMemberPermission && (
                      <Buttons
                        classNames='bttn-primary mt-2 float-right'
                        text={'Add Tags'}
                        type='button'
                        onClick={() => setShowEditcommodityPopUp(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        size='xxl'
        title={'Refine your Bid Notifications'}
        closeModal={() => setShowEditcommodityPopUp(false)}
        isOpen={showEditcommodityPopUp}
        backdrop='static'
      >
        <EditCommodityCodesAndTags setShowEditcommodityPopUp={setShowEditcommodityPopUp} />

      </ModalPopUp>

      <ModalPopUp
        size='md'
        title={`Confirm Delete Tag - ${selectedTag} `}
        closeModal={() => setShowConfirmDelPopUp(false)}
        isOpen={showConfirmDelPopUp}
        backdrop='static'
      >
        <p>Are you sure to delete the tag - {selectedTag}?</p>
        <Buttons
          classNames='bttn-secondary mt-2 float-left'
          text={'cancel'}
          type='button'
          onClick={() => setShowConfirmDelPopUp(false)}
        />
        <Buttons
          classNames='bttn-primary mt-2 float-right'
          text={'Confirm'}
          type='button'
          onClick={() => handleConfirmDeleteTag()}
        />
      </ModalPopUp>

    </>
  );
};

const connector = connect(
  (state: any) => ({
    shared: state.shared,
    accountinfo: state.accountinfo,
    internalLoader: state.internalLoader,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CommodityCodes);
