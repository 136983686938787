import { Provider } from 'react-redux';
import { StrictMode } from 'react';

import amplitude from 'amplitude-js';

import { AmplitudeProvider } from '../components/amplitude';
import { constants } from '../utils/settings';
import { store } from '../store/configureStore';

import App from './App';
import rootSaga from '../store/sagas';
import { RecoilRoot } from 'recoil';

store.runSaga(rootSaga);

const amplitudeInstance = amplitude.getInstance();

const Root = () => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <Provider store={store as any}>
        <>
          <RecoilRoot>
            <AmplitudeProvider
              amplitudeInstance={amplitudeInstance}
              apiKey={constants.amplitudeToken}
            >
              <StrictMode>
                <App />
              </StrictMode>
            </AmplitudeProvider>
          </RecoilRoot>
        </>
      </Provider>
    );
  } else {
    return (
      <Provider store={store as any}>
        <RecoilRoot>
          <AmplitudeProvider
            amplitudeInstance={amplitudeInstance}
            apiKey={constants.amplitudeToken}
          >
            <App />
          </AmplitudeProvider>
        </RecoilRoot>
      </Provider>
    );
  }
};

export default Root;
