import styled from 'styled-components';

import { Margin } from '@demandstar/components/styles';

export const UpsellWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    flex-shrink: 1;
    flex-grow: 3;
    margin: ${Margin.Base};
    min-width: 12.5rem;
    width: 30%;
  }

  & > div:last-child {
    display: inline-flex;
    width: 100%;

    button {
      margin: ${Margin.Extra} auto;
      align-self: center;
    }
  }
`;
