import { HeaderGroup } from 'react-table';
import styled from 'styled-components';

import { BaseTableHeader } from './DSTableStyles';
import { TextColor } from '../styles';

const tableLabels = {
  sortBy: 'Sort by',
};

const ActionIcon = styled.i`
  color: ${TextColor.Action};
  &:hover {
    color: ${TextColor.ActionHover};
  }
`;

export type ColumnHeaderProps<T extends Record<string, unknown>> = {
  /** */
  header: HeaderGroup<T>;
};

/** Column Header for use specifically in DSTable.
 * @example <ColumnHeader header={header} key={header.id} />
 */
export function ColumnHeader<D extends Record<string, unknown>>({ header }: ColumnHeaderProps<D>) {
  const { canSort, getHeaderProps, getSortByToggleProps, isSorted, isSortedDesc, render } = header;

  const headerTitle = `${canSort ? tableLabels.sortBy : ''} ${render('Header')}`;

  return (
    <BaseTableHeader {...getHeaderProps(getSortByToggleProps())} title={headerTitle}>
      {render('Header')}{' '}
      {canSort ? (
        isSorted ? (
          isSortedDesc ? (
            <ActionIcon className='mdi mdi-sort-descending'></ActionIcon>
          ) : (
            <ActionIcon className='mdi mdi-sort-ascending'></ActionIcon>
          )
        ) : (
          <i className='mdi mdi-sort'></i>
        )
      ) : (
        <></>
      )}
    </BaseTableHeader>
  );
}
