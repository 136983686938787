import { useCallback, useEffect, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory, useLocation } from 'react-router-dom';

import { getUrlValue, setUrlValue } from '../utils';
import { PaginationContainer } from './DSPaginateStyles';
import { UrlHandling } from '../types';

export type PaginationControlProps = {
  /** The current active page */
  activePage: number;
  /** Allows for override of the deep-linked query parameter (in case 'page' is used elsewhere)
   * @default 'page' */
  deepLinkParameter?: string;
  /** By default, current page syncs w/ a URL query parameter, this allows for that to be turned off */
  disableDeepLink?: boolean;
  /** Optional override for the number of pages to display for margins @default 2 */
  marginPagesDisplayed?: number;
  /** Function called when a new page is selected */
  onPageChange: (page: { selected: number }) => void;
  /** Optional override for the range of pages displayed @default 3 */
  pageRangeDisplayed?: number;
  /** Optional override items per page @default 10 */
  pageSize?: number;
  /** Optional override to hide the "Showing X of #" label @default true */
  showLabel?: boolean;
  /** The total number of items in the set */
  totalCount: number;
};

/** This is the pagination control & label (w/o data & children management),
 * Use in cases where pagination is controlled by the parent component (e.g. service-level pagination) */
export const DSPaginationControl = ({
  activePage,
  deepLinkParameter = 'page',
  disableDeepLink,
  marginPagesDisplayed = 2,
  onPageChange,
  pageRangeDisplayed = 3,
  pageSize = 10,
  showLabel = true,
  totalCount,
}: PaginationControlProps) => {
  /** NOTE: if upgrading to react-router-dom v6, replace useLocation w/ useSearchParams */
  const history = useHistory();
  const search = history?.location?.search || '';
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);
  const indexes = useMemo(() => {
    return {
      start: activePage * pageSize,
      end: Math.min(activePage * pageSize + pageSize, totalCount),
    };
  }, [activePage, pageSize, totalCount]);

  const nextLabel = useMemo(
    () => (activePage === pageCount - 1 ? '' : '>'),
    [activePage, pageCount],
  );
  const prevLabel = useMemo(() => (activePage === 0 ? '' : '<'), [activePage]);

  /** Create a UrlHandling object for maintaining deep-linking */
  const urlHandling: UrlHandling<number> = useMemo(() => {
    return {
      key: deepLinkParameter,
      getValue: (urlValue: string | null) => (urlValue ? Number(urlValue) - 1 : null),
      setValue: (value?: number | null) => (!value || value <= 0 ? null : (value + 1).toString()),
    };
  }, [deepLinkParameter]);

  /** Handles deep linking & calls the page change fn */
  const handlePageChange = useCallback(
    (page: { selected: number }) => {
      onPageChange(page);

      if (!disableDeepLink) {
        setUrlValue(searchParams, urlHandling, page.selected);
      }
    },
    [disableDeepLink, onPageChange, searchParams, urlHandling],
  );

  /** Deep link handling. Ensures that url provided page is selected & a >0 active page matches the URL */
  useEffect(() => {
    if (!disableDeepLink) {
      const urlPage = getUrlValue(searchParams, urlHandling);
      if (urlPage && urlPage > 0 && urlPage !== activePage) {
        handlePageChange({ selected: urlPage });
      } else if (activePage > 0 && !urlPage) {
        setUrlValue(searchParams, urlHandling, activePage);
      }
    }
  }, [activePage, disableDeepLink, handlePageChange, searchParams, urlHandling]);

  /** Parent component could technically set an invalid active page
   * This useEffect calls the page change fn w/ the bounded page value
   * (0 for negative numbers, pageCount for positive numbers) */
  useEffect(() => {
    if (activePage > pageCount) {
      handlePageChange({ selected: pageCount - 1 });
    } else if (activePage < 0) {
      handlePageChange({ selected: 0 });
    }
  }, [activePage, handlePageChange, pageCount]);

  return (
    <PaginationContainer data-testid='ds-pagination-container'>
      {showLabel && totalCount > 0 && (
        <span className='pagination-label' data-testid='ds-pagination-label'>
          Showing {indexes.start + 1}-{indexes.end} of {totalCount}
        </span>
      )}

      {pageCount > 1 && (
        <div data-testid='ds-pagination-controls'>
          <ReactPaginate
            forcePage={activePage}
            onPageChange={handlePageChange}
            pageCount={pageCount}
            pageRangeDisplayed={pageRangeDisplayed}
            marginPagesDisplayed={marginPagesDisplayed}
            previousLabel={prevLabel}
            nextLabel={nextLabel}
          />
        </div>
      )}
    </PaginationContainer>
  );
};
