import styled from 'styled-components';

import { BorderColor, BorderThickness, Margin, Padding } from '@demandstar/components/styles';

export const DocumentTypeList = styled.ul`
  list-style: none;
  padding: ${Padding.None};

  & > li {
    align-items: center;
    border-bottom: ${BorderThickness.Base} solid ${BorderColor.Highlight};
    display: flex;
    flex: row wrap;
    padding: ${Padding.Base};
    width: 100%;

    & > div:not(:first-of-type) {
      margin-left: ${Margin.Less};
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const NewDocumentTypeContainer = styled.div`
  margin-top: ${Margin.Extra};
  padding-top: ${Padding.Extra};
`;
