import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import ComingSoon from './comingsoon';

import * as actionCreators from '../../../store/actions';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

const Message = () => {
  const { auth } = useAuthContext();
  /* const [currentComponent, setCurrentComponent] = useState('component1') */

  /* const switchComponent = currentComponent => {
    setCurrentComponent(currentComponent)
  } */

  /* const getComponent = currentComponent => {
    const componentList = {
      component1: <Startconverstion {...props} switchComponent={switchComponent} />,
      component2: <Messageconversation {...props} switchComponent={switchComponent} />
    }
    return componentList[currentComponent]
  } */
  useSetBreadcrumb({
    page: 'Message',
    altname: 'Message',
    title: 'Message',
    name: 'Message',
  });

  return (
    <>
      <div className='container'>
        <ComingSoon memberType={auth?.memberType} />
        {/* <div className='row messagebar'>
					<div className='col-lg-4'>
						<div className='sideBarSpacing'>
							<h4>Conversation</h4>
							<div className='innerColIndent'>
								<Autocomplete
									iconType='search'
									label='Search for Location'
									placeholder='city, state, zip, or Country'
								/>

								<div className='sortlist'>
									<Sort
										sortFields={bidSortFields}
									/>
								</div>
							</div>
							<div className='Conversionwrapper'>
								<h6>Previous Conversations</h6>
								<div className='innerColIndent'>
									<div className='conversationtab'>
										<h6>Margaret Henshaw</h6>
										<em>12.14 AM</em>
										<p>City of Missouri City - Purchasing</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>

									<div className='conversationtab'>
										<h6>Gordon schumater</h6>
										<em>12.14 AM</em>
										<p>City of Missouri City - Purchasing</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>
									<div className='conversationtab'>
										<h6>Penelope Kumar</h6>
										<em>12.14 AM</em>
										<p>Floride state university</p>
										<small>Municipal court and alarm collection services for the city of Missouri City, Texas</small>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-8'>
						<div className='colWrapper clearfix '>
							{getComponent(currentComponent)}

						</div>
					</div>
				</div> */}
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({}),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Message));
