import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { ArrayContents, DataTestId } from '../types';
import { DSCheckbox, DSDatePicker, DSDateRange, DSSelect, DSTextInput } from '../inputs';
import { SearchInputType, ValidFilterValue } from './DSSearch.d';
import { compareObjectsByKey } from '../utils';
import { Loadable } from 'recoil';
import { SelectOption } from '../fields/select-field/DSSelectField.d';

export type SearchInputProps<ValueType> = DataTestId & {
  /** The default value for the search input */
  defaultValue?: ValueType;
  /** The type of search input to render */
  inputType: SearchInputType;
  /** The label to use for the search filter */
  label?: string;
  /** The input's name property, as well as the attribute the value will be sent to on the search function */
  name: string;
  /** The object attribute to use as the display, for a select input */
  optionLabel?: keyof ArrayContents<ValueType> & string;
  /** The list of options available, for a select input */
  options?: SelectOption[] | Loadable<SelectOption[]>;
  /** Provided by SearchBase to set the filters */
  setFilterValue: (value: ValueType | undefined) => void | Promise<void>;
  /** The message to use for the search filter (optional) */
  message?: string;
  /** Optionally sets the filter to be read-only, a read-only filter will be visible,
   * but can only be modified/removed if the defaultValue or URL-passed value is set */
  readOnly?: boolean;
};

export function DSSearchInput<ValueType extends ValidFilterValue>({
  dataTestId,
  defaultValue,
  inputType,
  label,
  name,
  optionLabel,
  options,
  setFilterValue,
  message,
  readOnly,
}: SearchInputProps<ValueType>): JSX.Element {
  /**
   * Typescript not playing nice with me here, but `value` will always be of
   * type `ValueType`
   */
  const [value, setValue] = useState(defaultValue);
  const [multiSelectValues, setMultiSelectValues] = useState([defaultValue as ValidFilterValue]);
  const testId = dataTestId || `ds-search-input-${name}`;

  const optionsLoading = useMemo(() => {
    if (![SearchInputType.Select, SearchInputType.MultipleSelect].includes(inputType)) return false;
    if (!options || !('state' in options)) return false;
    return options.state === 'loading';
  }, [inputType, options]);

  /** converts options into a sorted {label, value} array */
  const normalizedOptions: { label: string; value: ValidFilterValue }[] = useMemo(() => {
    if (![SearchInputType.Select, SearchInputType.MultipleSelect].includes(inputType)) return [];

    const loadedOptions = options && 'state' in options ? options.valueMaybe() || [] : options;

    const providedOptions =
      loadedOptions?.map(option => {
        const typedOption = option as Record<string, unknown>;
        if (optionLabel)
          return {
            label: `${typedOption[optionLabel]}`,
            value: option as ValidFilterValue,
          };
        // NOTE: these two type checks exist because the typecasting (line 68) does not actually work
        //       since these values were not objects, there was a typescript error searching the object
        //       despite the valid data type.
        else if (typeof typedOption === 'string') {
          return {
            label: typedOption,
            value: option as ValidFilterValue,
          };
        } else if (typeof typedOption === 'number') {
          return {
            label: String(typedOption),
            value: option as ValidFilterValue,
          };
        } else if ('label' in typedOption && 'value' in typedOption) {
          return {
            label: `${typedOption.label}`,
            value: typedOption.value as ValidFilterValue,
          };
        } else {
          return {
            label: '',
            value: option as ValidFilterValue,
          };
        }
      }) || [] as any;

    return providedOptions.sort(compareObjectsByKey('label'));
  }, [inputType, optionLabel, options]);

  const hasLength = (val: ValidFilterValue) => {
    return !val || typeof val !== 'object' || !('length' in val) || !!val.length;
  };

  const updateFilter = useCallback(
    (newValue?: ValidFilterValue | null) => {
      if (newValue && hasLength(newValue)) {
        if (newValue !== value) {
          setFilterValue(newValue as ValueType);
          setValue(newValue as ValueType);
        }
      } else {
        setFilterValue(undefined);
        setValue(undefined);
      }
    },
    [setFilterValue, value],
  );

  const updateMultiSelectFilter = useCallback(
    (newValue?: { label: string; value: ValidFilterValue }[] | null) => {
      const filterValue = newValue?.map(v => v.value);

      if (newValue && hasLength(newValue)) {
        if (multiSelectValues[0] === undefined || !multiSelectValues.includes(newValue)) {
          setFilterValue(filterValue as ValueType);
          setMultiSelectValues(newValue);
        }
      } else {
        setFilterValue(undefined);
        setMultiSelectValues([]);
      }
    },
    [multiSelectValues, setFilterValue],
  );

  const selectValue = useMemo(() => {
    return normalizedOptions.find(o => {
      return o.value === value;
    });
  }, [normalizedOptions, value]);

  const onSelect = useCallback(
    (option?: { label: string; value: ValidFilterValue }) => {
      updateFilter(option ? option.value : null);
    },
    [updateFilter],
  );

  const multiSelectValue = useMemo(() => {
    return normalizedOptions.filter(v => {
      return multiSelectValues.includes(v);
    });
  }, [normalizedOptions, multiSelectValues]);

  const onMultiSelect = useCallback(
    (values: { label: string; value: ValidFilterValue }[]) => {
      updateMultiSelectFilter(values);
    },
    [updateMultiSelectFilter],
  );

  switch (inputType) {
    case SearchInputType.Checkbox:
      return (
        <>
          {(value === undefined || typeof value === 'boolean') && (
            <DSCheckbox
              dataTestId={testId}
              name={name}
              label={label || ''}
              checked={!!value}
              onClick={() => updateFilter(!value)}
              inactive={readOnly}
              inlineWithInput
            />
          )}
        </>
      );

    case SearchInputType.Date:
      return (
        <>
          {(value === undefined || value instanceof dayjs) && (
            <DSDatePicker
              dataTestId={testId}
              name={name}
              label={label}
              value={value ? (value as dayjs.Dayjs) : undefined}
              onChange={updateFilter}
              message={message}
              inactive={readOnly}
            />
          )}
        </>
      );

    case SearchInputType.DateRange:
      return (
        <>
          {(!value || Array.isArray(value)) && (
            <DSDateRange
              dataTestId={testId}
              name={name}
              label={label}
              value={value ? (value as any) : undefined}
              onChange={updateFilter}
              message={message}
              inactive={readOnly}
            />
          )}
        </>
      );

    case SearchInputType.Text:
      return (
        <>
          {(value === undefined || typeof value === 'string') && (
            <DSTextInput
              dataTestId={testId}
              name={name}
              label={label}
              value={value ?? '' as any}
              onChange={updateFilter}
              placeholder={label}
              message={message}
              inactive={readOnly}
            />
          )}
        </>
      );

    case SearchInputType.Select:
      return (
        <DSSelect
          closeMenuOnSelect={true}
          dataTestId={testId}
          isClearable={!readOnly}
          isDisabled={readOnly || optionsLoading}
          label={label}
          labelField='label'
          message={message}
          name={name}
          onSelect={onSelect}
          options={normalizedOptions}
          placeholder={optionsLoading ? 'Loading...' : undefined}
          value={selectValue}
          valueField={false}
        />
      );

    case SearchInputType.MultipleSelect:
      return (
        <DSSelect
          closeMenuOnSelect={true}
          dataTestId={testId}
          isClearable={!readOnly}
          isDisabled={readOnly || optionsLoading}
          isMulti
          label={label}
          labelField='label'
          message={message}
          name={name}
          onMultiSelect={onMultiSelect}
          options={normalizedOptions}
          placeholder={optionsLoading ? 'Loading...' : undefined}
          value={multiSelectValue}
          valueField={false}
        />
      );

    default:
      return <></>;
  }
}
