import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as Api from '../services/tours';
import { initialBasicTour, ToursState } from '../reducers/tours';
import { SetToursDetailsPayload } from '../actions';

function* watchsetToursDetails() {
  yield takeLatest(actionTypes.SET_TOURS_DETAILS.TRIGGER, setToursDetails);
}

function* setToursDetails(action: { payload: SetToursDetailsPayload }) {
  yield put({ type: actionTypes.SET_TOURS_DETAILS.ACTION, payload: action.payload });
}

function* watchresetBasicTours() {
  yield takeLatest(actionTypes.RESET_BASIC_TOURS.TRIGGER, resetBasicTours);
}

function* resetBasicTours() {
  const tours: ToursState = yield select(state => state.tours);
  const { tourId = '' } = tours;
  yield put({ type: actionTypes.RESET_BASIC_TOURS.ACTION, payload: initialBasicTour });
  if (tourId) {
    yield put({
      type: actionTypes.SUBMIT_TOURS_CONFIGURATIONS.TRIGGER,
      payload: { tourId, completedStep: -1 },
    });
  }
}

function* watchresetTours() {
  yield takeLatest(actionTypes.RESET_TOURS.TRIGGER, resetTours);
}

function* resetTours() {
  yield put({ type: actionTypes.RESET_TOURS.ACTION });
}

function* watchgetToursConfigurations() {
  yield takeLatest(actionTypes.GET_TOURS_CONFIGURATIONS.TRIGGER, getToursConfigurations);
}

function* getToursConfigurations(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_TOURS_CONFIGURATIONS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getToursConfigurations, action.payload);
    yield put({
      type: actionTypes.GET_TOURS_CONFIGURATIONS.SUCCESS,
      payload: response?.data?.result || [],
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOURS_CONFIGURATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitToursConfigurations() {
  yield takeLatest(actionTypes.SUBMIT_TOURS_CONFIGURATIONS.TRIGGER, submitToursConfigurations);
}

function* submitToursConfigurations(action: { payload: any }): any {
  yield put({ type: actionTypes.SUBMIT_TOURS_CONFIGURATIONS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitToursConfigurations, action.payload);
    if (response?.data?.result?.status) {
      yield put({ type: actionTypes.SUBMIT_TOURS_CONFIGURATIONS.SUCCESS });
    } else {
      yield put({ type: actionTypes.SUBMIT_TOURS_CONFIGURATIONS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_TOURS_CONFIGURATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* toursSaga() {
  yield fork(watchresetBasicTours);
  yield fork(watchsetToursDetails);
  yield fork(watchresetTours);
  yield fork(watchgetToursConfigurations);
  yield fork(watchsubmitToursConfigurations);
}
