import styled from 'styled-components';

import { FlexContainer, Margin } from '@demandstar/components/styles';
import { TabRow } from '@demandstar/components/tab-navigation/DSTabNavigation.styles';

export const StyledCMTabContainer = styled(FlexContainer)`
  margin: -${Margin.Base} -4rem ${Margin.Extra} -4rem;
  width: calc(100% + 8rem);

  > ${TabRow} {
    width: 100%;
  }
`;
