import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderThickness,
  FontFamily,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../../styles';

interface BaseDatePickerProps {
  error?: FieldError;
  inactive?: boolean;
}

function getDatePickerStyles(props: BaseDatePickerProps) {
  const { error, inactive } = props;
  return `
  .ant-picker {
    background: ${inactive ? BackgroundColor.Inactive : BackgroundColor.Input};
    border-radius: ${BorderRadius.Base};
    border: ${BorderThickness.Base} solid ${error ? BorderColor.Error : BorderColor.Base};
    box-shadow: none;
    color: ${TextColor.Base};
    font-size: ${ParagraphFontSize.Base};
    height: 2.5rem;
    line-height: 2rem;
    padding: ${Padding.Small} ${Padding.Less};
    width: 100%;
    
    &.ant-picker-disabled {
      border: ${BorderThickness.Base} solid ${error ? BorderColor.Error : BorderColor.Base};
    }
    
    &:hover {
      border: ${BorderThickness.Base} solid ${error ? BorderColor.Error : BorderColor.Base};
    }
    
    input {
      font-size: ${ParagraphFontSize.Base};
      font-family: ${FontFamily.Base};
      color: ${inactive ? TextColor.Inactive : TextColor.Base};
    }
  }
`;
}

/** a single date picker, styled */
export const BaseDatePickerContainer = styled.div<BaseDatePickerProps>`
  ${props => getDatePickerStyles(props)}
  display: block;
  width: 100%;
`;

/** a range date picker, styled */
export const BaseDateRangePickerContainer = styled.div<BaseDatePickerProps>`
  ${props => getDatePickerStyles(props)}
  width: 100%;
  .ant-picker-range-separator {
    line-height: 0;
  }
`;
