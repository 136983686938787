import { DSButton } from '@demandstar/components/button';
import { DSEmail } from '@demandstar/components/email';
import { FlexContainer } from '@demandstar/components/styles';

import { commonEmail, TollFreeNumber } from 'src/utils/texts/common';

import { ModalPopUp } from 'src/components/common/modals';

interface RegistrationErrorModalProps {
  /** if modal is open */
  showModal: boolean;
  /** sets if the modal open state */
  setShowModal: (val: boolean) => void;
}

/**
 * @description a simple modal indicating an error has occurred in registration
 * @returns JSX.Element
 *
 * @example <RegistrationErrorModal
 * setShowModal={(val) => { setShowModal(false); }}
 * showModal={true} />
 */
export const RegistrationErrorModal = ({
  showModal,
  setShowModal,
}: RegistrationErrorModalProps) => {
  return (
    <ModalPopUp
      title='Registration Error'
      size='lg'
      isOpen={showModal}
      closeModal={() => {
        setShowModal(!showModal);
      }}
    >
      <p>{"We've had a problem processing your payment. Please try again in a few minutes."}</p>
      <p>
        {"If you're still having trouble after trying again, please "}
        <DSEmail address={commonEmail.support}>send us an email</DSEmail> or call us at{' '}
        {TollFreeNumber}.
      </p>
      <FlexContainer justifyContent='center'>
        <DSButton
          type='primary'
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          OK
        </DSButton>
      </FlexContainer>
    </ModalPopUp>
  );
};
