import { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import { addbidParamType, broadcastListItemType } from '../../../../types/addbid';
import {
  broadcastcancelText,
  broadcastconfirmText,
  confirmbroadcastsubText,
  confirmbroadcastText,
} from '../../../../utils/texts';
import { Buttons, NoResult, Paging } from '../../../customcontrols';
import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { pageSize } from '../../../../utils/constants';

import GenerateNewList from '../../../common/createbid/modals/generatenewlist';
import Prebuiltlist from '../../../common/createbid/modals/prebuiltlist';
import SaveList from '../../../common/createbid/modals/savelist';
import UploadList from '../../../common/createbid/modals/uploadlist';

interface PropsTypes {
  addbid: addbidParamType;
  setAddBidDetails: any;
  submitAddBidAddList?: any;
  setDeleteBroadcastSuppliers?: any;
  getAddBidBroadcastMembers?: any;
  match?: any;
}

const ReBroadcastList = (props: PropsTypes) => {
  const {
    addbid,
    setAddBidDetails,
    submitAddBidAddList,
    setDeleteBroadcastSuppliers,
    getAddBidBroadcastMembers,
  } = props;
  const {
    prebuiltmodal = false,
    newlistmodal = false,
    broadcastinglist = [],
    uploadlistmodal = false,
    savelistmodal = false,
    newlistname = '',
    savelistExistmodal,
    broadcastListssearch = [],
  } = addbid;
  const bidId = parseInt(props.match.params.bidId);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentPages, setCurrentPages] = useState(1);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState('');
  const [confirmSkip, setConfirmSkip] = useState(false);

  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  useEffect(() => {
    let getAddBidBroadcastMembersAPICALL = true;
    if (bidId && getAddBidBroadcastMembersAPICALL) {
      getAddBidBroadcastMembers({ id: bidId, type: 'Bid' });
    }
    return () => {
      getAddBidBroadcastMembersAPICALL = false;
    };
  }, [getAddBidBroadcastMembers, bidId]);

  const setPrebuiltModal = () => {
    setAddBidDetails({ prebuiltmodal: !prebuiltmodal });
  };

  const Setnewlistmodal = () => {
    setCurrentPages(1);
    setAddBidDetails({ newlistmodal: !newlistmodal });
  };

  const Setuploadlistmodal = () => {
    setAddBidDetails({ uploadlistmodal: !uploadlistmodal });
  };

  const Setsavelistmodal = () => {
    setAddBidDetails({ newlistname: '', savelistmodal: !savelistmodal });
  };

  const onConfirm = () => {
    setConfirmDelete(false);
    setDeleteBroadcastSuppliers({ blockedMemberID: selectedDeleteItem, bidId });
  };

  const deleteBroadcastSupplier = (id: any) => {
    setConfirmDelete(true);
    setSelectedDeleteItem(id);
  };

  function toggleConfirmDelete() {
    setConfirmDelete(!confirmDelete);
  }

  function toggleConfirmSkip() {
    setConfirmSkip(!confirmSkip);
  }

  const ListComponent = (internallisting: broadcastListItemType[] = [], type = '') => {
    return (
      <>
        <div className='d-flex justify-content-between'>
          <h4 className='float-left'>Suppliers ({internallisting.length})</h4>
          {type === 'newlist' ? (
            <h4 className='float-right'>
              New suppliers added : {internallisting.filter(item => !item.broadcasted).length}
            </h4>
          ) : null}
        </div>
        <div className='clearfix'>
          <Table className='tableHData'>
            <tr>
              <th>Supplier</th>
              <th>City, State</th>
              <th></th>
            </tr>
            {internallisting && internallisting.length > 0 ? (
              <>
                {internallisting
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        {item.city}
                        {item.city && item.state && ', '}
                        {item.state}
                      </td>

                      <td>
                        {type === 'newlist' && item.broadcasted === true ? (
                          <span className='float-right'>Broadcast</span>
                        ) : type === 'hidedelete' ? (
                          'Broadcast'
                        ) : (
                          <span
                            className='mdi mdi-delete-forever-outline mdi-24px staticLink ml-3 float-right'
                            title='Delete'
                            onClick={() => deleteBroadcastSupplier(item.mi)}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                  .slice(indexOfFirstLists, indexOfLastLists)}
              </>
            ) : (
              <tr>
                <td colSpan={3}>
                  <NoResult message={'No Suppliers Available'} />
                </td>
              </tr>
            )}
          </Table>

          <Paging
            totalRecords={internallisting.length}
            currentPage={currentPages}
            onPagingClick={(value: number) => {
              if (value !== currentPages) setCurrentPages(Number(value));
            }}
            limitmsg={false}
            pageLimit={listPerPages}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className='clearfix'>
        <div className='d-flex justify-content-around'>
          <Buttons
            classNames='bttn-secondary mt-4 mb-5'
            text={'Add more commodity codes'}
            type='button'
            onClick={Setnewlistmodal}
          />
          <Buttons
            classNames='bttn-secondary mt-4 mb-5'
            text={'Add Supplemental Suppliers'}
            type='button'
            onClick={() => setAddBidDetails({ currentBroadcastPage: 'supplimentalsuppliers' })}
          />
        </div>
        {broadcastListssearch.length > 0
          ? ListComponent(broadcastListssearch, 'newlist')
          : ListComponent(broadcastinglist, 'hidedelete')}
      </div>

      <ModalPopUp
        title='Saved Broadcast Lists'
        closeModal={setPrebuiltModal}
        isOpen={prebuiltmodal}
        backdrop='static'
      >
        <Prebuiltlist {...props} Setprebuiltmodal={setPrebuiltModal} pagefor='addbid' />
      </ModalPopUp>
      <ModalPopUp
        size='xxl'
        title={
          broadcastinglist.length > 0
            ? 'Refine List - Select Commodity Codes'
            : 'Add more commodity codes - Select Commodity Codes'
        }
        closeModal={Setnewlistmodal}
        isOpen={newlistmodal}
        backdrop='static'
        className='selectBroadcast'
        // what does this class name do? consider using a style component
        // and removing from here and from ModalPopUp.
      >
        <GenerateNewList
          {...props}
          Setnewlistmodal={Setnewlistmodal}
          ListComponent={ListComponent}
          bidId={bidId}
          pagefor={'addbid'}
          reBroadcast={true}
          quotesId={''}
          checkedit={false}
          setCurrentPages={setCurrentPages}
        />
      </ModalPopUp>
      <ModalPopUp
        title='Upload A Broadcast List'
        closeModal={Setuploadlistmodal}
        isOpen={uploadlistmodal}
        backdrop='static'
      >
        <UploadList {...props} />
      </ModalPopUp>

      <ModalPopUp
        title='Save Supplier List'
        closeModal={Setsavelistmodal}
        isOpen={savelistmodal}
        backdrop='static'
        size='md'
      >
        <SaveList
          setAddBidDetails={setAddBidDetails}
          newlistname={newlistname}
          Setsavelistmodal={Setsavelistmodal}
          submitAddBidAddList={submitAddBidAddList}
          bidId={bidId}
          savelistExistmodal={savelistExistmodal}
          pagefor={'addbid'}
          quotesId={''}
        />
      </ModalPopUp>
      <ConfirmModal
        onConfirm={onConfirm}
        closeModal={toggleConfirmDelete}
        size='md'
        isOpen={confirmDelete}
        danger='delete'
        title='Confirm Delete'
      >{`This supplier will be removed from broadcasting supplier list for this ${
        bidId ? 'bid' : 'quote'
      }. Are you sure want to delete this supplier?`}</ConfirmModal>
      <ConfirmModal
        subText={confirmbroadcastsubText}
        onConfirm={() => {}}
        closeModal={toggleConfirmSkip}
        confirmText={broadcastconfirmText}
        cancelText={broadcastcancelText}
        size='xl'
        isOpen={confirmSkip}
        title='Confirmation'
      >
        {confirmbroadcastText}
      </ConfirmModal>
    </>
  );
};

export default memo(ReBroadcastList);
