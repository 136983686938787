import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  AwardBidLabels,
  defaultNumericId,
  documentUploadProgressThrottlePercentage,
  uploadMessages,
} from '../../../shared/constants';
import { DocumentUploadFormValues, FileUploadRequest } from '../../../types/document';
import {
  RequiredDocumentResponseGetAPIResponse,
  RequiredDocumentResponseUploadAPIRequest,
} from '../../../types';
import {
  useSelectedBidId,
  useSetSelectedBidIdFromRoute,
} from '../../../shared/hooks/useSelectedBidId';

import { DocumentUploadForm } from '../../../components/common/document';
import { getRequiredActionUniqueId } from '../../../utils/required-action';
import { RequiredActionType } from 'src/types/required-action';
import { RequiredDocumentResponsesTable } from '../../../components/buyer/awardbid/list-required-docs/RequiredDocumentResponsesTable';
import { selectedRequiredDocumentState } from '../../../store/recoil/award-bid';
import { showToastSuccess } from '../../../utils/message';
import { tryCatchLog } from 'src/utils/errors';
import { uploadRequiredDocumentResponse } from '../../../store/services';
import { useRecoilState } from 'recoil';
import { useRequiredActions } from '../../../shared/hooks/award-bid/useRequiredActions';
import { useRequiredDocumentResponses } from '../../../shared/hooks';
import { useSupplierMemberId } from '../../../shared/hooks/useMemberInfo';
import { Visible } from '../../../types/shared';

const ShowHidePanelWrapper = styled.div<Visible>`
  display: ${({ visible }) => (visible ? 'inherit' : 'none')};
  transition: display 0.5s;
  flex-direction: column;
`;

export interface RequiredDocumentUploadPanelProps {
  requiredDocument?: RequiredDocumentResponseGetAPIResponse;
}

export function RequiredDocumentUploadPanel({
  requiredDocument,
}: RequiredDocumentUploadPanelProps) {
  /// Props
  const requiredDocResponseId = requiredDocument?.bidAwardRequiredDocResponseId || defaultNumericId;

  /// Hooks
  // Update the selected bidId from the current url params.
  useSetSelectedBidIdFromRoute();

  // Required Actions
  const { refreshRequiredActions, setSelectedRequiredActionId } = useRequiredActions();
  const { refreshRequiredDocumentResponses } = useRequiredDocumentResponses();

  /// Application state ///
  const selectedBidId = useSelectedBidId().selectedBidId;
  const { supplierMemberId } = useSupplierMemberId();

  const [selectedRequiredDocument, setSelectedRequiredDocument] = useRecoilState(
    selectedRequiredDocumentState,
  );

  useEffect(() => {
    if (
      requiredDocument &&
      requiredDocument.bidAwardRequiredDocId !== selectedRequiredDocument.bidAwardRequiredDocId
    ) {
      setSelectedRequiredDocument(requiredDocument);
    }
  }, [requiredDocument, selectedRequiredDocument, setSelectedRequiredDocument]);

  /// Local state
  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);

  function handleUploadProgress(progressEvent: ProgressEvent) {
    const progress = (progressEvent.loaded / progressEvent.total) * 100;

    // Only update per >=5% increment to reduce state setting / redraw cycles.
    if (progress - documentUploadProgress >= documentUploadProgressThrottlePercentage) {
      setDocumentUploadProgress(Math.round(progress));
    }
  }

  /**
   * Process form values to make the api upload request.
   * @param {DocumentUploadFormValues} formValues
   */
  async function handleUploadFormSubmit(formValues: DocumentUploadFormValues) {
    const uploadFile: File = formValues.files[0];

    const apiRequestParameters: RequiredDocumentResponseUploadAPIRequest = {
      bidId: selectedBidId,
      bidDocId: null,
      bidAwardRequiredDocId: formValues.docReferenceId,
      title: formValues.documentTitle || uploadFile.name,
      docFormatType: uploadFile.type,
      filePath: uploadFile.name,
      fileSize: uploadFile.size,
      isDelete: false,
      isSkip: false,
      memberId: supplierMemberId,
    };

    const data: FileUploadRequest<RequiredDocumentResponseUploadAPIRequest> = {
      ...apiRequestParameters,
      file: uploadFile,
    };

    // Make the upload request.
    return tryCatchLog(async () => {
      const uploadResponse = await uploadRequiredDocumentResponse({ data, handleUploadProgress });

      if (uploadResponse?.bidAwardRequiredDocResponseId) {
        setDocumentUploadProgress(0);

        // Show success Toast
        showToastSuccess({
          message: `"${data.title}" ${uploadMessages.successSuffix}`,
          autoClose: 5000,
        });

        // Refresh the view after the upload.
        await refreshRequiredDocumentResponses();
        await refreshRequiredActions();

        if (requiredDocument?.bidAwardRequiredDocId) {
          setSelectedRequiredActionId(
            getRequiredActionUniqueId(
              RequiredActionType.RequiredDocument,
              requiredDocument.bidAwardRequiredDocId,
            ),
          );
        }
      }
    }, 'AddRequiredDocumentResponseDocs -> handleUploadFormSubmit()');
  }

  const showUploadForm = requiredDocResponseId < 0;

  const referenceId = requiredDocument?.bidAwardRequiredDocId || defaultNumericId;

  const presetDocumentType = requiredDocument?.docTitle || '';

  return (
    <>
      {/* Upload form */}
      <ShowHidePanelWrapper visible={showUploadForm}>
        <DocumentUploadForm
          docReferenceId={referenceId}
          docTypeLabel={AwardBidLabels.awardDocumentType}
          handleFormSubmit={handleUploadFormSubmit}
          presetDocumentType={presetDocumentType}
          uploadProgress={documentUploadProgress}
        />
      </ShowHidePanelWrapper>

      {/* Upload confirmation and document delete option */}
      <ShowHidePanelWrapper visible={!showUploadForm}>
        <RequiredDocumentResponsesTable showSingleRow />
      </ShowHidePanelWrapper>
    </>
  );
}
