import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { paths } from '../ContractManagement.paths';
import { useContractDetails } from '../useContractDetails';

export enum CMTab {
  Contracts = 'Contracts',
  Documents = 'Documents',
  Reminders = 'Reminders',
}

export function useCMNavigation() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { contractDetails } = useContractDetails();

  const selectedTab = useMemo(() => {
    if (pathname.includes('reminders')) return CMTab.Reminders;
    if (pathname.includes('documents')) return CMTab.Documents;
    return CMTab.Contracts;
  }, [pathname]);

  const selectTab = useCallback(
    (tab: CMTab) => {
      switch (tab) {
        case CMTab.Contracts:
          history.push(
            contractDetails?.id
              ? paths.navigation.contractDetails(contractDetails.id)
              : paths.navigation.searchContracts,
          );
          break;
        case CMTab.Documents:
          history.push(paths.navigation.documents(contractDetails?.id));
          break;
        case CMTab.Reminders:
          history.push(paths.navigation.reminders(contractDetails?.id));
          break;
        default:
          break;
      }
    },
    [contractDetails?.id, history],
  );

  const tabs = useMemo(() => {
    return Object.values(CMTab).map(tab => {
      return {
        id: tab.toString(),
        label: tab.toString(),
        onClick: () => {
          selectTab(tab);
        },
        selected: selectedTab === tab,
      };
    });
  }, [selectedTab, selectTab]);

  return {
    selectedTab,
    tabs,
  };
}
