import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Assert, formatDollar } from '@demandstar/components/utils';
import { Divider } from '@demandstar/components/divider';
import { DSButton } from '@demandstar/components/button';
import { DSSelect } from '@demandstar/components/inputs';
import { NADollarDisplay } from '@demandstar/components/fields/dollar-field/DSDollarField';
import { Padding } from '@demandstar/components/styles';

import * as texts from './SelectAwardees.texts';
import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../../shared/hooks/amplitude/awardBid.events';
import { Awardee, AwardeeDraft, useBidAwardees } from 'src/store/recoil/bid-awardees';
import { AwardWizardId, selectedBidAwardeeState } from '../../../../store/recoil/award-bid';
import { useAwardBidWizard, useRouter } from 'src/shared/hooks';
import { AddNewAwardee } from './AddNewAwardee';
import { AwardeeTable } from './AwardeeTable';
import { hasValue } from 'src/utils/helpers';
import { LoadableWrapper } from '../../../common/loading/LoadableWrapper';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { PanelRowRight } from '../../../../shared/styles';
import { useEBidResponses } from 'src/store/recoil/ebid-responses/useEBidResponses';
import { usePlanholders } from 'src/store/recoil/planholders/usePlanholders';
import { useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

export function SelectAwardees() {
  const { logEvent } = useAwardBidEvents();
  const { saveAwardBidWizard, returnToBidSummary } = useAwardBidWizard();
  const { routerParams } = useRouter();
  const bidId = routerParams.bidId && Number(routerParams.bidId);
  Assert(bidId, 'We should always have a bidId from our router.');

  const { bidAwardeesLoadable, updateAwardees } = useBidAwardees(bidId);
  const { planholders } = usePlanholders(bidId);
  const { eBidResponses } = useEBidResponses(bidId);
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const { setSupplierMemberId, supplierMemberId } = useSupplierMemberId();

  // Local state
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  /** Draft Awardees are a local copy of bidAwardees that does not reach recoil. */
  const [awardeeDrafts, setAwardeeDrafts] = useState<AwardeeDraft[]>(
    bidAwardeesLoadable.valueMaybe() || [],
  );

  const hasAwardeeDrafts = useMemo(() => {
    return !!awardeeDrafts.length;
  }, [awardeeDrafts.length]);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { awardees: awardeeDrafts },
  });

  const { getValues, setValue } = methods;

  const potentialAwardees = useMemo(() => {
    const potentials: AwardeeDraft[] = [];

    function isDuplicate(memberId: number) {
      // is this supplier already selected?
      if (
        awardeeDrafts.findIndex(awardeeDrafts => {
          return awardeeDrafts.awardedToMemberId === memberId;
        }) !== -1
      ) {
        return true;
      }

      // is this supplier already an option?
      if (
        potentials.findIndex(potential => {
          return potential.awardedToMemberId === memberId;
        }) !== -1
      ) {
        return true;
      }
      return false;
    }

    // add all non-duplicate planholders
    for (const planholder of planholders) {
      if (!isDuplicate(planholder.mi)) {
        potentials.push({
          supplierName: planholder.supplierName,
          awardedToMemberId: planholder.mi,
          amount: 0,
          email: planholder.email,
        });
      }
    }

    // add all non-duplicate eBid responses
    for (const response of eBidResponses) {
      if (!isDuplicate(response.supplierMemberId)) {
        potentials.push({
          supplierName: response.name,
          awardedToMemberId: response.supplierMemberId,
          amount: 0,
          email: response.accounts[0]?.email,
        });
      }
    }
    return potentials;
  }, [awardeeDrafts, eBidResponses, planholders]);

  const getCompleteAwardeeValues = useCallback(() => {
    const { awardees } = getValues();
    return awardeeDrafts.map((awardee, index) => {
      return {
        ...awardee,
        amount: awardees[index].amount,
      };
    });
  }, [awardeeDrafts, getValues]);

  const saveAwardeeInfoFromForm = useCallback(async () => {
    return updateAwardees(getCompleteAwardeeValues());
  }, [getCompleteAwardeeValues, updateAwardees]);

  const nextPage = useCallback(async () => {
    await saveAwardeeInfoFromForm();
    logEvent({ eventName: AwardBidEventName.SaveAwardee });
    saveAwardBidWizard(
      hasAwardeeDrafts ? AwardWizardId.AddVendorDocs : AwardWizardId.AddPublicDocs,
    );
  }, [hasAwardeeDrafts, logEvent, saveAwardBidWizard, saveAwardeeInfoFromForm]);

  const deleteAwardee = useCallback(
    (memberId: number) => {
      const filteredBidAwardees = getCompleteAwardeeValues().filter(
        awardee => awardee.awardedToMemberId !== memberId,
      );
      setAwardeeDrafts(filteredBidAwardees);
      // Reset the selectedBidAwardee in case the deleted record was selected.
      // The `useEffect` handler will catch and reset this.
      setSelectedBidAwardee(null);
    },
    [getCompleteAwardeeValues, setSelectedBidAwardee],
  );

  const addAwardeeFromSelect = useCallback(
    (awardee?: AwardeeDraft) => {
      if (awardee) {
        setAwardeeDrafts([...getCompleteAwardeeValues(), awardee]);
      }
    },
    [getCompleteAwardeeValues],
  );

  const addAwardeeFromNewPlanholder = useCallback(
    (awardee: AwardeeDraft) => {
      const awardeeDrafts: AwardeeDraft[] = [...getCompleteAwardeeValues()];
      if (
        !awardeeDrafts.find(awardeeDraft => {
          return awardeeDraft.awardedToMemberId === awardee.awardedToMemberId;
        })
      ) {
        setAwardeeDrafts([...getCompleteAwardeeValues(), awardee]);
      }
      setAddSupplierModalOpen(false);
    },
    [getCompleteAwardeeValues],
  );

  const toggleAddSupplierModal = useCallback(() => {
    setAddSupplierModalOpen(state => !state);
  }, []);

  useEffect(() => {
    /** Select a default `selectedBidAwardee` and `selectedMemberId`. */
    if (!selectedBidAwardee && awardeeDrafts[0]) {
      setSelectedBidAwardee(awardeeDrafts[0] as Awardee);
      setSupplierMemberId(awardeeDrafts[0].awardedToMemberId);
    }
  }, [
    awardeeDrafts,
    selectedBidAwardee,
    setSelectedBidAwardee,
    setSupplierMemberId,
    supplierMemberId,
  ]);

  /** keep awardeeDrafts in sync with bidAwardees (API query selector)*/
  useEffect(() => {
    if (hasValue(bidAwardeesLoadable)) {
      setAwardeeDrafts(bidAwardeesLoadable.contents);
    }
  }, [bidAwardeesLoadable]);

  /** If awardees are updated, we update the data we have in the form. */
  useEffect(() => {
    for (let i = 0; i < awardeeDrafts.length; i++) {
      setValue(`awardees.${i}.amount`, formatDollar(awardeeDrafts[i].amount, NADollarDisplay));
    }
  }, [awardeeDrafts, setValue]);

  async function saveAndFinishLater() {
    await saveAwardeeInfoFromForm();
    returnToBidSummary();
  }

  return (
    <>
      <LoadableWrapper loadable={bidAwardeesLoadable}>
        <FormProvider {...methods}>
          <AwardeeTable deleteAwardee={deleteAwardee} awardees={awardeeDrafts} />
        </FormProvider>
        <Divider />
        {!!potentialAwardees.length && (
          <DSSelect
            labelField='supplierName'
            name='awardedToMemberId'
            onSelect={addAwardeeFromSelect}
            options={potentialAwardees}
            placeholder={texts.addAwardeePlaceholder}
            valueField={false}
          />
        )}
      </LoadableWrapper>

      <PanelRowRight paddingHorizontal={'0'} paddingVertical={`${Padding.Base} 0`}>
        <DSButton onClick={toggleAddSupplierModal} type='secondary' dataTestId='addSupplier'>
          {texts.addNewSupplier}
        </DSButton>
      </PanelRowRight>

      <ModalPopUp
        title={'Add Supplier'}
        size={'md'}
        isOpen={addSupplierModalOpen}
        closeModal={toggleAddSupplierModal}
      >
        <AddNewAwardee addAwardee={addAwardeeFromNewPlanholder} />
      </ModalPopUp>

      <WizardButtons onNext={nextPage} save={saveAndFinishLater} />
    </>
  );
}
