/** Color definitions, background and border color classes live here.
 *
 * Naming conventions:
 *  - Base colors objects are named for their color (Teal, CoolGray, etc.)
 *  - All other objects end in 'Color' (TextColor, BackgroundColor, etc.)
 *  - Types (based off the object) end in 'Hex' for easy clarity and identification (ColorHex, ButtonHex, etc.)
 * */

import { PropertyOf } from '../types';

/** Neutral Tones. Do not export. */
export const Neutral = {
  Black: '#111111',
  Darkest: '#3F3F3F',
  Darker: '#4F4F4F',
  Dark: '#7A7A7A',
  Gray: '#B3B3B3',
  Light: '#E0E0E0',
  Lighter: '#EAEAEA',
  Lightest: '#F5F5F5',
  AlmostWhite: '#FAFAFA',
  White: '#FFFFFF',
  Transparent: 'transparent',
} as const;

/** Brand Tones. Do not export. */
const Teal = {
  Lighter: '#6AE9fC',
  Light: '#50BFCF',
  /** Brand Teal */
  Base: '#3395A4',
  Dark: '#1F6A85',
  Darker: '#044266',
} as const;

/** Brand Tones. Do not export. */
const CoolGray = {
  Lighter: '#E1ECED',
  Light: '#D2DFE1',
  Base: '#C3D4D6',
  Dark: '#93A2AD',
  Darker: '#627085',
} as const;

/** Accent Tones. Do not export. */
const Green = {
  Lighter: '#C2E375',
  Light: '#A5D574',
  Base: '#8AC675',
  Dark: '#609B58',
  Darker: '#366F3C',
} as const;

/** Accent Tones. Do not export. */
const Yellow = {
  Lighter: '#FFF0B1',
  Light: '#FFD67D',
  Base: '#FFB84A',
  Dark: '#E39011',
  Darker: '#C16F00',
} as const;

/** Accent Tones. Do not export. */
const Orange = {
  Lighter: '#FDCDA9',
  Light: '#F8AF79',
  Base: '#EB8954',
  Dark: '#C36531',
  Darker: '#914012',
} as const;

/** Accent Tones. Do not export. */
const Red = {
  Lighter: '#FAB2B2',
  Light: '#E88686',
  Base: '#C76060',
  Dark: '#A23D3D',
  Darker: '#5C1515',
} as const;

/** Accent Tones. Do not export. */
const Purple = {
  Lighter: '#DDCBFF',
  Light: '#AF93DA',
  Base: '#7E5CB5',
  Dark: '#593E87',
  Darker: '#372458',
} as const;

/** Unique Cases. One-offs. */
const Unique = {
  /** Unique Red, used for errors and dangerous buttons. */
  Error: '#C76060',
  /** Unique Orange, used as a secondary graphs color*/
  Data: '#FD825B',
} as const;

type NeutralHex = PropertyOf<typeof Neutral>;

type BrandHex = PropertyOf<typeof CoolGray | typeof Teal>;

type AccentHex = PropertyOf<
  typeof Yellow | typeof Orange | typeof Red | typeof Purple | typeof Green
>;

type UniqueHex = PropertyOf<typeof Unique>;

export type ColorHex = AccentHex | BrandHex | NeutralHex | UniqueHex;

export const TextColor = {
  //TODO: Cut down on options.
  Action: Teal.Dark,
  ActionHover: Teal.Base,
  /** FIXME: Too specific. Use existing color. */
  ActionMessage: Neutral.Darker,
  Base: Neutral.Black,
  Contrast: Neutral.AlmostWhite,
  ContrastAction: Teal.Light,
  Error: Unique.Error,
  Helper: Neutral.Dark,
  Inactive: Neutral.Dark,
  Label: Neutral.Black,
} as const;

export type TextHex = PropertyOf<typeof TextColor>;

export const BorderColor = {
  Action: Teal.Dark,
  Base: Neutral.Dark,
  /** To be used for radio, checkbox, wizard, etc. Clickable form components */
  Checked: Teal.Darker,
  CheckedFocus: Teal.Dark,
  Error: Unique.Error,
  Highlight: Neutral.Lighter,
  /** Inactive buttons */
  Inactive: Neutral.Dark,
  None: Neutral.Transparent,
  SubNavigation: CoolGray.Lighter,
  Table: Neutral.Light,
  Wizard: Neutral.Gray,
} as const;

export const BackgroundColor = {
  // TODO: Cut down on options.
  // TODO: Add Highlights.
  BaseBody: Teal.Base,
  BaseGray: Neutral.Gray,
  BaseOrange: Orange.Base,
  BaseTeal: Teal.Darker,
  BaseWhite: Neutral.White,
  Inactive: Neutral.Lightest,
  /** should this be Red.Error? (Yes.)  */
  Error: Red.Dark,
  Footer: Teal.Dark,
  HighlightPurple: Purple.Darker,
  HighlightTeal: Teal.Light,
  Input: Neutral.White,
  /**FIXME: Too specific. */
  LoadingText: Neutral.Lighter,
  Success: Green.Base,
  SubNavigationTab: CoolGray.Lighter,
  SubNavigationTabSelected: Teal.Base,
  Transparent: Neutral.Transparent,
  Warning: Yellow.Light,
} as const;

export const AlertColor = {
  ErrorBody: Red.Lighter,
  ErrorIcon: Red.Base,
  SuccessBody: Green.Lighter,
  SuccessIcon: Green.Dark,
  WarningBody: Yellow.Light,
  WarningIcon: Yellow.Base,
} as const;

export const TableBackground = {
  Base: Neutral.Transparent,
  Footer: CoolGray.Lighter,
} as const;

export const DeprecatedColors = {
  BlueGreenArrowHighlight: CoolGray.Light,
  BlueGreenLight: CoolGray.Base,
  BlueGreenMedium: Teal.Base,
  GrayBgHighlight: Neutral.Lighter,
  GrayLight: Neutral.Lightest,
} as const;

export const ButtonColor = {
  Primary: Purple.Darker,
  Secondary: BackgroundColor.BaseWhite,
  Tertiary: Green.Dark,
  Danger: Unique.Error,
  Inactive: BackgroundColor.Inactive,
} as const;

export type ButtonHex = PropertyOf<typeof ButtonColor>;

export const ButtonHighlight = {
  Primary: Purple.Darker,
  Secondary: BackgroundColor.BaseWhite,
  Tertiary: Green.Darker,
  Danger: Red.Base,
  Inactive: BackgroundColor.Inactive,
} as const;

export type ButtonHighlightHex = PropertyOf<typeof ButtonHighlight>;

export const DataColor = {
  Primary: Yellow.Base,
  Secondary: Unique.Data,
} as const;
