import styled from 'styled-components/macro';

import {
  AlertColor,
  BorderColor,
  FlexContainerColumn,
  Margin,
  Padding,
  TextColor,
} from '../styles';

import { DSSVGIcon } from '../icon';
import { Status } from '../constants';
interface AlertContainerProps {
  type: Status;
}

function iconBackgroundColor(type: Status) {
  switch (type) {
    case Status.Success:
      return AlertColor.SuccessIcon;
    case Status.Error:
      return AlertColor.ErrorIcon;
    default:
      return AlertColor.WarningIcon;
  }
}

function bodyBackgroundColor(type: Status) {
  switch (type) {
    case Status.Success:
      return AlertColor.SuccessBody;
    case Status.Error:
      return AlertColor.ErrorBody;
    default:
      return AlertColor.WarningBody;
  }
}

export const AlertIcon = styled.div<AlertContainerProps>`
  background-color: ${({ type }) => iconBackgroundColor(type)};
  flex: 0 0 5rem;
  text-align: center;

  ${DSSVGIcon} {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const AlertMessage = styled.div`
  width: 100%;
`;

export const AlertBody = styled.div<AlertContainerProps>`
  background-color: ${({ type }) => bodyBackgroundColor(type)};
  color: ${TextColor.Base};
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;
  padding: ${Padding.Base};

  h5 {
    margin-bottom: ${Margin.None};
    color: ${TextColor.Base};
  }

  p {
    margin-bottom: ${Margin.Base};
    padding-top: ${Padding.None};
  }

  button {
    border-color: ${BorderColor.None};
    display: block;
    margin-top: ${Margin.Extra};

    &:hover {
      border-color: ${BorderColor.None};
    }
  }
`;

export const AlertContentColumn = styled(FlexContainerColumn)`
  justify-content: center;
`;

export const AlertContainer = styled.div<AlertContainerProps>`
  display: flex;
  flex-wrap: wrap;
  margin: ${Margin.Extra} ${Margin.None};
  width: 100%;
`;

export const AlertDismissIconContainer = styled.div`
  padding-left: ${Padding.Base};
`;
