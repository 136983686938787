export enum AuthKey {
  AccountCreatedDate = 'acd',
  AccountId = 'us',
  AccountLocked = 'lkd',
  AccountLockedMinutes = 'lm',
  AccountPermissions = 'prms',
  DisabledNotifications = 'dn',
  Email = 'eml',
  FirstName = 'fn',
  FreeMemberId = 'fmi',
  FullName = 'fln',
  HasError = 'her',
  LastLogin = 'll',
  LastName = 'ln',
  MainContact = 'mc',
  MemberBlackListed = 'mbl',
  MemberCreatedDate = 'mcd',
  MemberId = 'mi',
  MembershipLevels = 'ml',
  MemberStatus = 'ms',
  MemberType = 'mt',
  Migrated = 'mgrtd',
  OpsId = 'opi',
  PrimaryMemberId = 'pmid',
  ProductType = 'pt',
  Theme = 'tm',
  Token = 'tk',
  Username = 'un',
  /** @warning this is different from our standard use of userType. Should be typed as AuthUserType */
  AuthUserType = 'ut',
}
/** JSON Web Token keys (currently used in auth token) */
export enum TokenKey {
  /** The "exp" (expiration time) claim identifies the expiration time on
   or after which the JWT MUST NOT be accepted for processing. */
  ExpirationTime = 'exp',
  /** The "iat" (issued at) claim identifies the time at which the JWT was
   issued. */
  IssuedAtClaim = 'iat',
  /** The "iss" (issuer) claim identifies the principal that issued the
   JWT. */
  IssuerClaim = 'iss',
  /** The "nbf" (not before) claim identifies the time before which the JWT
   MUST NOT be accepted for processing. */
  NotBeforeClaim = 'nbf',
}

export const MemberStatus = [
  { label: 'Active', value: 'AC' },
  { label: 'Suspended', value: 'SU' },
];

export enum MemberType {
  AgencyBuyer = 'AB',
  MigratedNonSubscriber = 'MS',
  AgencyCreatedPlanholder = 'PL',
  BasicSupplier = 'SB',
  SubscribingSupplier = 'SS',
  SupplementalSupplier = 'SV',
  SuspendedSupplier = 'SU',
  ContentExtractor = 'CE',
}
