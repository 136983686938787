import {
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef,
} from 'react';
import { Controller, FieldError, FieldValues,RegisterOptions, useFormContext } from 'react-hook-form';

import { FieldContainer, getFieldContainerProps } from '../../field-container';
import getSizingData, { SizingData } from '../../inputs/textarea/getSizingData';
import { TextAreaStyled } from '../../inputs/textarea/DSTextArea.styles';
import { useWindowResizeListener } from '../../inputs/textarea/hooks';

import calculateNodeHeight from '../../inputs/textarea/calculateNodeHeight';

type TextAreaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

type TextAreaHeightChangeMeta = {
  rowHeight: number;
};

export type DSTextAreaFieldProps = Omit<
  TextAreaFieldProps,
  'name' | 'onBlur' | 'onChange' | 'placeholder' | 'style'
> & {
  /** Cache measurements to improve performance */
  cacheMeasurements?: boolean;
  /** Test ID for testing-library */
  dataTestId?: string;
  /** Disable the field */
  disabled?: boolean;
  /** FieldError from react-hook-form */
  error?: FieldError;
  /** Field label displayed above the textarea */
  label?: string;
  /** The maximum length of characters allowed */
  maxLength?: number;
  /** The maximum number of rows the area can expand to */
  maxRows?: number;
  /** Message displayed below the label */
  message?: string;
  /** The minimum number of rows the area starts with */
  minRows?: number;
  /** Field name for the label and input */
  name: string;
  /** onBlur event callback */
  onBlur?: (value: string) => void;
  /** onChange event callback */
  onChange?: (value: string) => void;
  /** Callback that fires when the height changes */
  onHeightChange?: (height: number, meta: TextAreaHeightChangeMeta) => void;
  /** Field is optional */
  optional?: boolean;
  /** Placeholder text that shows when area is empty */
  placeholder?: string;
  /** RHF Rules */
  rules?: RegisterOptions;
};

/**
 * Textarea field with label and error state handling
 * @example <DSTextAreaField name="textarea-example" />
 */
export const DSTextAreaField = (props: DSTextAreaFieldProps) => {
  const {
    cacheMeasurements,
    dataTestId,
    disabled,
    maxLength = 524288, // Per Google, this is the actual default
    maxRows = 5,
    minRows = 2,
    name,
    onChange,
    onHeightChange,
    onBlur,
    optional,
    placeholder,
    rules,
  } = props;
  const methods = useFormContext() as any;
  const { control, formState: { errors }, watch } = methods;
  const error = errors[name] as FieldError;
  const value: string = watch(name);

  const registerOptions: RegisterOptions = {
    required: !optional,
    ...rules,
  };

  const isControlled = value !== undefined;
  let libRef = useRef<HTMLTextAreaElement | null>();
  const heightRef = useRef(0);
  const measurementsCacheRef = useRef<SizingData>();

  const resizeTextarea = () => {
    if (!libRef.current) return;
    const node = libRef.current! as any ;
    const nodeSizingData =
      cacheMeasurements && measurementsCacheRef.current
        ? measurementsCacheRef.current
        : getSizingData(node);

    if (!nodeSizingData) {
      return;
    }

    measurementsCacheRef.current = nodeSizingData;

    const [height, rowHeight] = calculateNodeHeight(
      nodeSizingData,
      node.value || node.placeholder || 'x',
      minRows,
      maxRows,
    );

    if (heightRef.current !== height) {
      heightRef.current = height;
      node.style.setProperty('height', `${height}px`, 'important');
      if (onHeightChange) {
        onHeightChange(height, { rowHeight });
      }
    }
  };

  const change: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!isControlled) {
      resizeTextarea();
    }

    if (onChange) {
      onChange(event.target.value.trimStart());
    }
  };

  const blur: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!isControlled) {
      resizeTextarea();
    }

    if (onBlur) {
      onBlur(event.target.value.trim());
    }
  };

  useLayoutEffect(resizeTextarea);
  useWindowResizeListener(resizeTextarea);

  return (
    <Controller
      control={control}
      defaultValue={value || ''}
      name={name}
      rules={registerOptions}
      render={({ field: { onChange, onBlur, value, name, ref } }) => {
        libRef = ref as any;
        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <TextAreaStyled
              data-testid={dataTestId}
              disabled={disabled}
              error={error}
              maxLength={maxLength}
              name={name}
              onChange={e => {
                change(e);
                onChange(e.target.value);
              }}
              onBlur={e => {
                blur(e);
                onBlur();
              }}
              placeholder={placeholder}
              ref={libRef as any}
              value={value}
            />
          </FieldContainer>
        );
      }}
    ></Controller>
  );
};
