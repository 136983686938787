import { useEffect } from 'react';

import { convertRGBColorToHex } from './conversions';

/**
 * @param {unknown} exp - a truthy/falsy assertion we assume is true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the Assert error.
 * @param {string} file - optional file string for clarity (where is this assert located?)
 * @returns
 *
 * @example Assert(!!response.email, 'All eBid Responses should have an email');
 */
export function Assert(exp: unknown, message: string, file?: string): asserts exp {
  if (exp || process.env.NODE_ENV === 'production') {
    return;
  }
  // Disabled so that we can have open a confirm dialog ONLY in development / QA.
  // eslint-disable-next-line no-restricted-globals
  if (confirm(message + (file ? ' (' + file + ')' : ''))) {
    // Disabled so we can pause on this breakpoint when debugging.
    // eslint-disable-next-line no-debugger
    debugger;
  }
}
/** Assert to be used within React components (as opposed to within functions)
 * @param {unknown} exp - a truthy/falsy assertion we assume is true at this point in the code.
 * @param {string} message - what we want to communicate to the dev who found the Assert error.
 * @param {string} file - optional file string for clarity (where is this assert located?)
 * @returns
 *
 * @example useAssert(!!tzfn, 'Timezone should be defined', 'broadcast.tsx');
 */
export function useAssert(exp: unknown, message: string, file?: string): asserts exp {
  useEffect(() => {
    Assert(exp, message, file);
  }, [exp, file, message]);
}

/** Console.log that updates when dependencies change.
 * @param {string} message - what we want to communicate to the dev who found the Assert error.
 * @param {string} func - error, warn, debug, etc.
 * @returns
 *
 * @example useAssert(!!tzfn, 'Timezone should be defined', 'broadcast.tsx');
 */
export function useConsole(message: string, func?: keyof Console) {
  useEffect(() => {
    switch (func) {
      case 'debug':
      case 'dir':
      case 'error':
      case 'info':
      case 'log':
      case 'warn':
        // eslint-disable-next-line no-console
        console[func](message);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(message);
        break;
    }
  }, [func, message]);
}

export function getBackgroundHexColor(element: HTMLElement) {
  const buttonStyle = window.getComputedStyle(element);
  const rgbColor = buttonStyle.backgroundColor;
  return convertRGBColorToHex(rgbColor);
}

export function getTextHexColor(element: HTMLElement) {
  const buttonStyle = window.getComputedStyle(element);
  const rgbColor = buttonStyle.color;
  return convertRGBColorToHex(rgbColor);
}
