/* eslint-disable max-lines */
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import * as bidApi from '../services/bids';
import * as sharedApi from '../services/shared';
import * as subscriptionApi from '../services/subscriptions';
import {
  BidCategory,
  countrieslist,
  CountryId,
  quoteExternalStatus,
  storedCommoditiestokenName,
  themetokenName,
} from '../../utils/constants';
import { GetFiscalYearsFn, getShowBidsFn } from '../../utils/helpers';
import { sharedactionTypes, trackeventsTypes } from '../../types/shared';
import { dynamicSort } from './utils';
import { Authorization } from 'src/types';
import { queryClient } from 'src/queryClient';

function* watchsetSharedDetails() {
  yield takeLatest(actionTypes.SET_SHARED_DETAILS.TRIGGER, setSharedDetails);
}

function* setSharedDetails(action: { payload: any }) {
  yield put({ type: actionTypes.SET_SHARED_DETAILS.ACTION, payload: action.payload });
}

function* watchgetAgenciesList() {
  yield takeLatest(actionTypes.GET_AGENCIES_LIST.TRIGGER, getAgenciesList);
}

function* getAgenciesList(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_AGENCIES_LIST.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getAgencies);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_AGENCIES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_AGENCIES_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetLocationList() {
  yield takeLatest(actionTypes.GET_LOCATION_LIST.TRIGGER, getLocationList);
}

function* getLocationList(action: { payload: any }): any {
  const agencyList = yield select(state => state.shared.agencies);
  if (agencyList.length <= 0) {
    yield put({ type: actionTypes.GET_LOCATION_LIST.REQUEST, meta: action.payload });
    try {
      const location = yield call(sharedApi.getLocations);
      const locationResult = location.data.result;
      const payload = [
        ...locationResult
          .filter((f: { countyId: number }) => f.countyId === 0)
          .sort(dynamicSort('state')),
        ...locationResult
          .filter((f: { countyId: number }) => f.countyId !== 0)
          .map((m: { county: string; stateAbbreviation: string }) => ({
            ...m,
            county: `${m.county}, ${m.stateAbbreviation}`,
          }))
          .sort(dynamicSort('county')),
      ];
      yield put({ type: actionTypes.GET_LOCATION_LIST.SUCCESS, payload });
    } catch (error) {
      yield put({
        type: actionTypes.GET_LOCATION_LIST.FAILURE,
        payload: { payload: action.payload, error },
      });
    }
  }
}

function* watchClearAppState() {
  yield takeLatest(actionTypes.CLEAR_APP_STATE.TRIGGER, clearAppState);
}

function* clearAppState() {
  yield put({ type: actionTypes.CLEAR_APP_STATE.ACTION });
}

// To get show bids list
export function* watchgetSharedBidStatus() {
  yield takeLatest(actionTypes.GET_SHARED_BID_STATUS.TRIGGER, getSharedBidStatus);
}

function* getSharedBidStatus(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_SHARED_BID_STATUS.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getsharedBidStatus);
    const bidStatus = response.data.result || [];
    if (bidStatus.length > 0) {
      const payload = bidStatus.map((item: { statusName: string; statusType: string }) => ({
        ...item,
        label: item.statusName,
        value: item.statusType,
      }));
      yield put({ type: actionTypes.GET_SHARED_BID_STATUS.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_SHARED_BID_STATUS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SHARED_BID_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// To get show bids list
export function* watchgetShowBids() {
  yield takeLatest(actionTypes.GET_SHOW_BIDS.TRIGGER, getShowBids);
}

function* getShowBids(action: { payload: { auth: Authorization } }): any {
  yield put({ type: actionTypes.GET_SHOW_BIDS.REQUEST, meta: action.payload });

  try {
    const auth = action.payload.auth;
    if (auth) {
      const payload = getShowBidsFn(auth);
      yield put({ type: actionTypes.GET_SHOW_BIDS.SUCCESS, payload });
    } else {
      yield put({ type: actionTypes.GET_SHOW_BIDS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SHOW_BIDS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// To get Commodities list
export function* watchgetCommoditiesList() {
  yield takeLatest(actionTypes.GET_COMMODITIES_LISTS.TRIGGER, getCommoditiesList);
}

function* getCommoditiesList(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_COMMODITIES_LISTS.REQUEST, meta: action.payload });

  try {
    const queryCommodityCodes = queryClient.getQueryData<any>(['CommodityCodes']);
    // const response = yield call(sharedApi.getCommodities);
    const commodities = yield (queryCommodityCodes.data && queryCommodityCodes.data.result) || [];
    if (commodities.length > 0) {
      const userSelectedIndustries = commodities
        .filter((f: { isSelected: boolean }) => f.isSelected)
        .map((m: { commodityId: number }) => m.commodityId);

      yield put({
        type: actionTypes.GET_COMMODITIES_LISTS.SUCCESS,
        payload: { commodities, userSelectedIndustries },
      });
    } else {
      yield put({ type: actionTypes.GET_COMMODITIES_LISTS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_COMMODITIES_LISTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

// To get States list
export function* watchGetStateList() {
  yield takeLatest(actionTypes.GET_STATES_LIST.TRIGGER, getStateList);
}
function* getStateList(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_STATES_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getStatesList, action.payload);
    let payload = yield (response.data && response.data.result) || [];
    if (payload.length) {
      payload = payload.map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }));
    }
    yield put({ type: actionTypes.GET_STATES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_STATES_LIST.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

// To get counties list
export function* watchgetCountiesList() {
  yield takeLatest(actionTypes.GET_COUNTIES_LIST.TRIGGER, getCountiesList);
}
function* getCountiesList(action: { payload: string | undefined }): any {
  yield put({ type: actionTypes.GET_COUNTIES_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getCountiesList, action.payload);
    let payload = [];
    if (action.payload) {
      payload = yield (response.data && response.data.result) || [];
      if (payload.length) {
        payload = payload.map((items: { name: string; title: string; id: number }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
          parentId: action.payload,
        }));
      }
    }
    yield put({ type: actionTypes.GET_COUNTIES_LIST.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUNTIES_LIST.FAILURE,
      payload: { Error, payload: action.payload },
    });
  }
}

export function* watchGetScrapers() {
  yield takeLatest(actionTypes.GET_SCRAPERS.TRIGGER, getScrapers);
}

function* getScrapers(action: sharedactionTypes): any {
  const scrapers = yield select(state => state.shared.scrapers);
  if (scrapers.length <= 0) {
    yield put({ type: actionTypes.GET_SCRAPERS.REQUEST, meta: action.payload });
    try {
      const response = yield call(sharedApi.getScrapers, action.payload);
      yield put({ type: actionTypes.GET_SCRAPERS.SUCCESS, payload: response.data.result });
    } catch (error) {
      yield put({
        type: actionTypes.GET_SCRAPERS.FAILURE,
        payload: { payload: action.payload, error },
      });
    }
  }
}

function* watchSelfDeclarationData() {
  yield takeLatest(actionTypes.GET_SELF_DECLARATION.TRIGGER, getSelfDeclaration);
}

function* getSelfDeclaration(action: { payload: { isCurrentMember: boolean } | undefined }): any {
  try {
    const decResponse = yield call(sharedApi.getselfdeclarations, action.payload);
    const payload =
      decResponse.data.result.map((item: { attributeName: string; attributetype: string }) => ({
        ...item,
        label: item.attributeName,
        value: item.attributetype,
      })) || [];
    yield put({ type: actionTypes.GET_SELF_DECLARATION.SUCCESS, payload });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SELF_DECLARATION.FAILURE,
      payload: { payload: action.payload, error },
    });
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { internalLoader: false } });
  }
}

function* watchSiblingProductData() {
  yield takeLatest(actionTypes.GET_SIBLING_PRODUCTS.TRIGGER, getSiblingProductsData);
}

function* getSiblingProductsData(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_SIBLING_PRODUCTS.REQUEST, meta: action.payload });
  try {
    const resp = yield call(sharedApi.getSiblingProductsData, action.payload);

    if (action.payload.productId) {
      const response = yield call(subscriptionApi.getAllProducts);
      const products = response.data.result;
      const agencyProduct = products.find(
        (item: any) => item.productId === action.payload.productId,
      );
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { sibilingParentCounty: agencyProduct ? agencyProduct.parentName : '' },
      });
    }

    yield put({
      type: actionTypes.GET_SIBLING_PRODUCTS.SUCCESS,
      payload: { siblingProducts: resp.data.result },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SIBLING_PRODUCTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchRelatedBidsData() {
  yield takeLatest(actionTypes.GET_RELATED_BIDS.TRIGGER, getRelatedBidsData);
}

function* getRelatedBidsData(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_RELATED_BIDS.REQUEST, meta: action.payload });
  const resp = yield call(sharedApi.getRelatedBidsData);
  yield put({
    type: actionTypes.GET_RELATED_BIDS.SUCCESS,
    payload: { relatedBids: resp.data.result },
  });
}

function* watchDashboardData() {
  yield takeLatest(actionTypes.GET_DASHBOARD_DATA.TRIGGER, getDashboardData);
}

function* getDashboardData(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_DASHBOARD_DATA.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getdashboarddata, action.payload);
    const responsetracking = yield call(sharedApi.getdashboarddataTracking, action.payload);
    let opensCount = 0,
      Awarded = 0,
      Cancelled = 0,
      MyBids = 0,
      UnderEvaluation = 0,
      incompletecount = 0,
      Completed = 0,
      RecommendationofAward = 0;

    response.data.result.bids.map((item: { categoryType: BidCategory; count: number }) => {
      if (item.categoryType === BidCategory.Active) {
        opensCount = opensCount + item.count;
      } else if (item.categoryType === BidCategory.Awarded) {
        Awarded = Awarded + item.count;
      } else if (item.categoryType === BidCategory.Upcoming) {
        incompletecount = incompletecount + item.count;
      } else if (item.categoryType === BidCategory.Cancelled) {
        Cancelled = Cancelled + item.count;
      } else if (item.categoryType === BidCategory.Completed) {
        Completed = Completed + item.count;
      } else if (item.categoryType === BidCategory.MyBids) {
        MyBids = MyBids + item.count;
      } else if (item.categoryType === BidCategory.UnderEvaluation) {
        UnderEvaluation = UnderEvaluation + item.count;
      } else if (item.categoryType === BidCategory.RecommendationOfAward) {
        RecommendationofAward = RecommendationofAward + item.count;
      }
      return item;
    });

    let qopensCount = 0,
      qAwarded = 0,
      qCancelled = 0,
      MyQuotes = 0,
      qclosed = 0,
      incompleteCount = 0;

    response.data.result.quotes.map((item: { categoryType: string; count: number }) => {
      if (item.categoryType === quoteExternalStatus.open) {
        qopensCount = qopensCount + item.count;
      } else if (item.categoryType === quoteExternalStatus.awarded) {
        qAwarded = qAwarded + item.count;
      } else if (item.categoryType === quoteExternalStatus.incomplete) {
        incompleteCount = incompleteCount + item.count;
      } else if (item.categoryType === quoteExternalStatus.cancelled) {
        qCancelled = qCancelled + item.count;
      } else if (item.categoryType === quoteExternalStatus.closed) {
        qclosed = qclosed + item.count;
      } else if (item.categoryType === 'MQ') {
        MyQuotes = MyQuotes + item.count;
      }
      return item;
    });

    const statistics = {
      bids: [
        { title: 'Active', categoryType: BidCategory.Active, count: opensCount },
        { title: 'Upcoming', categoryType: BidCategory.Upcoming, count: incompletecount },
        {
          title: 'Under Evaluation',
          categoryType: BidCategory.UnderEvaluation,
          count: UnderEvaluation,
        },
        {
          title: 'Recommendation Of Award',
          categoryType: BidCategory.RecommendationOfAward,
          count: RecommendationofAward,
        },
        { title: 'Awarded', categoryType: BidCategory.Awarded, count: Awarded },
        { title: 'Completed', categoryType: BidCategory.Completed, count: Completed },
        { title: 'Cancelled', categoryType: BidCategory.Cancelled, count: Cancelled },
        { title: 'My Bids', categoryType: BidCategory.MyBids, count: MyBids },
      ],
      quotes: [
        { title: 'Open', categoryType: quoteExternalStatus.open, count: qopensCount },
        {
          title: 'Incomplete',
          categoryType: quoteExternalStatus.incomplete,
          count: incompleteCount,
        },
        { title: 'Awarded', categoryType: quoteExternalStatus.awarded, count: qAwarded },
        { title: 'Closed', categoryType: quoteExternalStatus.closed, count: qclosed },
        { title: 'Cancelled', categoryType: quoteExternalStatus.cancelled, count: qCancelled },
        { title: 'My Quotes', categoryType: 'MQ', count: MyQuotes },
      ],
    };

    /* const dashboardData = response.data.result.bids.map(item => {
      if (item.category === 'Watching') {
        item.count = responsetracking.data.result.bids[0].count
      }
      return item
    }) */
    if (responsetracking.data.result.bids.length > 0) {
      response.data.result.bids.push(responsetracking.data.result.bids[0]);
    }

    //response.data.result.bids = dashboardData

    yield put({
      type: actionTypes.GET_DASHBOARD_DATA.SUCCESS,
      payload: { dashboardData: response.data.result, statistics: statistics },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetFiscalYear() {
  yield takeLatest(actionTypes.GET_FISCAL_YEAR.TRIGGER, getFiscalYear);
}

function* getFiscalYear(action: { payload: any }) {
  yield put({ type: actionTypes.GET_FISCAL_YEAR.REQUEST, meta: action.payload });
  try {
    /*	const response = yield call(sharedApi.getAddBidFiscalYear, action.payload)
    if (response.data.result) {
      const payload = response.data.result
      yield put({ type: actionTypes.GET_FISCAL_YEAR.SUCCESS, payload })
    } else {
      yield put({ type: actionTypes.GET_FISCAL_YEAR.FAILURE })
    }*/
    yield put({ type: actionTypes.GET_FISCAL_YEAR.SUCCESS, payload: GetFiscalYearsFn() });
  } catch (error) {
    yield put({
      type: actionTypes.GET_FISCAL_YEAR.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchResponseDisplays() {
  yield takeLatest(actionTypes.GET_RESPONSE_DISPLAYS.TRIGGER, getResponseDisplays);
}

function* getResponseDisplays(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_RESPONSE_DISPLAYS.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getResponseDisplays, action.payload);
    yield put({
      type: actionTypes.GET_RESPONSE_DISPLAYS.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_RESPONSE_DISPLAYS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchDashboardBidsList() {
  yield takeLatest(actionTypes.GET_DASHBOARD_BIDS_LIST.TRIGGER, DashboardBidsList);
}

function* DashboardBidsList(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_DASHBOARD_BIDS_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.dashboardBidsList, action.payload);
    const payload = { dashboardBids: response.data.result };
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.GET_DASHBOARD_BIDS_LIST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_BIDS_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchDashboardQuotesList() {
  yield takeLatest(actionTypes.GET_DASHBOARD_QUOTES_LIST.TRIGGER, DashboardQuotesList);
}

function* DashboardQuotesList(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_DASHBOARD_QUOTES_LIST.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.dashboardQuotesList, action.payload);
    const payload = { dashboardQuotes: response.data.result };
    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.GET_DASHBOARD_QUOTES_LIST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_QUOTES_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetDashboardNetwork() {
  yield takeLatest(actionTypes.GET_DASHBOARD_NETWORK.TRIGGER, getDashboardNetwork);
}

function* getDashboardNetwork(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_DASHBOARD_NETWORK.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getDashboardNetwork, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_DASHBOARD_NETWORK.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_NETWORK.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetdashboardNotifications() {
  yield takeLatest(actionTypes.GET_DASHBOARD_NOTIFICATIONS.TRIGGER, getdashboardNotifications);
}

function* getdashboardNotifications(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_DASHBOARD_NOTIFICATIONS.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getdashboardNotifications, action.payload);
    const accountresponse = yield call(sharedApi.getdashboardNotificationsaccount, action.payload);
    const payload = response.data.result || [];
    const accountpayload = accountresponse.data.result || [];
    let notificationslist = [...payload, ...accountpayload] || [];
    notificationslist =
      notificationslist.length > 0
        ? notificationslist.sort((a, b) => a.type.localeCompare(b.type)) || []
        : notificationslist;
    yield put({
      type: actionTypes.GET_DASHBOARD_NOTIFICATIONS.SUCCESS,
      payload: notificationslist,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_NOTIFICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitDisablenotification() {
  yield takeLatest(
    actionTypes.SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS.TRIGGER,
    submitDisablenotification,
  );
}

function* submitDisablenotification(action: {
  payload: { id: number; notificationId: number };
}): any {
  yield put({
    type: actionTypes.SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS.REQUEST,
    meta: action.payload,
  });

  try {
    const { id = '', notificationId = '' } = action.payload;
    const shared = yield select(state => state.shared);
    const { dashboardnotifications = [] } = shared;
    let notificationslist = dashboardnotifications.filter(
      (item: { id: number }) => Number(item.id) !== Number(id),
    );
    if (id === '' || !id) {
      notificationslist = dashboardnotifications.filter(
        (item: { notificationId: number }) =>
          Number(item.notificationId) !== Number(notificationId),
      );
    }
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { dashboardnotifications: notificationslist },
    });
    yield call(sharedApi.submitDisablenotification, action.payload);
    yield put({ type: actionTypes.SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_DASHBOARD_DISABLE_NOTIFICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetTopCommodities() {
  yield takeLatest(actionTypes.GET_TOP_COMMODITIES.TRIGGER, getTopCommodities);
}

function* getTopCommodities(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_TOP_COMMODITIES.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getTopCommodities, action.payload);
    const payload = {
      dashboardCommodities: response.data.result,
      userCommoditiesCount: response.data.total,
    };
    const storedCommodities = JSON.stringify(response.data.result);
    localStorage.setItem(storedCommoditiestokenName, storedCommodities);
    yield put({ type: actionTypes.GET_TOP_COMMODITIES.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOP_COMMODITIES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetCommodityTags() {
  yield takeLatest(actionTypes.GET_COMMODITY_TAGS.TRIGGER, getCommodityTags);
}

function* getCommodityTags(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_COMMODITY_TAGS.REQUEST, meta: action.payload });

  try {
    const response = yield call(sharedApi.getCommodityTags, action.payload);
    yield put({ type: actionTypes.GET_COMMODITY_TAGS.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COMMODITY_TAGS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetThemes() {
  yield takeLatest(actionTypes.GET_THEMES.TRIGGER, getThemes);
}

function* getThemes(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_THEMES.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getThemes);
    const payload = response.data.result.theme || 'light_DS';
    yield put({ type: actionTypes.GET_THEMES.SUCCESS, payload });
    localStorage.setItem(themetokenName, payload);
  } catch (error) {
    yield put({
      type: actionTypes.GET_THEMES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsetThemes() {
  yield takeLatest(actionTypes.SET_THEMES.TRIGGER, setThemes);
}

function* setThemes(action: { payload: { theme: string } }): any {
  yield put({ type: actionTypes.SET_THEMES.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.setThemes, action.payload);
    const payload = response.data.result.status || '';
    yield put({ type: actionTypes.SET_THEMES.SUCCESS, payload });
    localStorage.setItem(themetokenName, action.payload.theme);
  } catch (error) {
    yield put({
      type: actionTypes.SET_THEMES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetOtherMemberInfo() {
  yield takeLatest(actionTypes.GET_OTHER_MEMBER_INFO.TRIGGER, getOtherMemberInfo);
}

function* getOtherMemberInfo(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_OTHER_MEMBER_INFO.REQUEST, meta: action.payload });
  try {
    const response = yield call(bidApi.getMemberInfo, action.payload);
    yield put({ type: actionTypes.GET_OTHER_MEMBER_INFO.SUCCESS, payload: response.data.result });
  } catch (error) {
    yield put({
      type: actionTypes.GET_OTHER_MEMBER_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchPopulateMetroDetails() {
  yield takeLatest(actionTypes.POPULATE_METRO_DETAILS.TRIGGER, populateMetroDetails);
}

function* populateMetroDetails(action: {
  payload: { zip: any; formData: any; formPage: string; fieldType: string };
}): any {
  yield put({ type: actionTypes.POPULATE_METRO_DETAILS.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.populateMetroDetails, { zip: action.payload.zip });

    if (response.data.result.metroId || response.data.result.countryId === CountryId.Canada) {
      const shared = yield select(state => state.shared);
      const { stateslist = [] } = shared;
      let selectedState = {};
      if (stateslist.length === 0) {
        const stateResponse = yield call(sharedApi.getStatesList, {});
        selectedState = stateResponse.data.result.find(
          (item: { id: number }) => item.id === response.data.result.stateId,
        );
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { stateslist: stateResponse.data.result },
        });
      } else {
        selectedState = stateslist.find(
          (item: { value: number }) => item.value === response.data.result.stateId,
        );
      }

      let selectedCounty = null;
      let countyResponse = yield call(sharedApi.getCountiesList, response.data.result.stateId);
      if (countyResponse.data.result.length) {
        countyResponse = countyResponse.data.result.map(
          (items: { name: string; title: string; id: number }) => ({
            ...items,
            label: items.name || items.title,
            value: items.id,
            parentId: response.data.result.stateId,
          }),
        );
      }
      if (response.data.result.countyId) {
        selectedCounty = countyResponse.find(
          (item: { id: number }) => item.id === response.data.result.countyId,
        );
      }
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { countieslist: countyResponse },
      });

      const selectedCountry = countrieslist.find(
        item => item.value === response.data.result.countryId,
      );

      let contactValues = {};
      let shipValues = {};

      if (action.payload.fieldType === 'contact') {
        contactValues = {
          contactCountryId: selectedCountry,
          contactStateId: selectedState,
          contactCity: response.data.result.city,
        };
      }
      if (action.payload.fieldType === 'ship') {
        shipValues = {
          shipCountryId: selectedCountry,
          shipStateId: selectedState,
          shipCity: response.data.result.city,
        };
      }

      let newFormValues = {} as any;
      if (action.payload.formPage && action.payload.formPage === 'companyAddressForm') {
        newFormValues = {
          ...action.payload.formData,
          country: selectedCountry,
          stateName: selectedState,
          city: response.data.result.city,
          countyName: selectedCounty,
        };
        yield put({
          type: actionTypes.SET_SUBSCRIPTIONS_DETAILS.TRIGGER,
          payload: { addressInitialForm: newFormValues },
        });
      } else if (action.payload.formPage && action.payload.formPage === 'Agency Quote Creation') {
        newFormValues = {
          ...action.payload.formData,
          ...contactValues,
          ...shipValues,
        };
      } else {
        newFormValues = {
          ...action.payload.formData,
          country: selectedCountry,
          state: selectedState,
          metro: response.data.result.metroId && {
            label: response.data.result.metroName,
            value: response.data.result.metroId,
          },
          city: response.data.result.city,
          county: selectedCounty,
        };
      }

      if (action.payload.formPage) {
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { sharedFormInitialValues: newFormValues, formPage: action.payload.formPage },
        });
      } else {
        yield put({
          type: actionTypes.SET_AGENCY_REGISTRATION_DETAILS.TRIGGER,
          payload: { generalInfoFormInitialValues: newFormValues },
        });
      }
    }
    yield put({ type: actionTypes.POPULATE_METRO_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.POPULATE_METRO_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetTimezoneList() {
  yield takeLatest(actionTypes.GET_TIMEZONE_LIST.TRIGGER, GetTimezoneList);
}

function* GetTimezoneList(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_TIMEZONE_LIST.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getTimezoneList, action.payload);
    const payload = response.data.result.map(
      (item: { timeZoneName: string; timeZoneId: number }) => ({
        ...item,
        label: item.timeZoneName,
        value: item.timeZoneId,
      }),
    );

    yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { timeZoneList: payload } });
    yield put({ type: actionTypes.GET_TIMEZONE_LIST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TIMEZONE_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetWatchedBidIds() {
  yield takeLatest(actionTypes.GET_WATCHED_BIDIDS.TRIGGER, getWatchedBidIds);
}

function* getWatchedBidIds(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_WATCHED_BIDIDS.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getWatchedBidIds, action.payload);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { watchedBidIds: response.data.result, watchedBidIdsCall: true },
    });
    yield put({ type: actionTypes.GET_WATCHED_BIDIDS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_WATCHED_BIDIDS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitTrackEvents() {
  yield takeLatest(actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, submitTrackEvents);
}

function* submitTrackEvents(action: { payload: trackeventsTypes }): any {
  yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.submitTrackEvents, action.payload);
    const payload = response?.data?.result?.status || '';
    yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_TRACK_EVENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetContractInterest() {
  yield takeLatest(actionTypes.GET_CONTRACT_INTEREST.TRIGGER, getContractInterest);
}

function* getContractInterest(action: sharedactionTypes): any {
  yield put({ type: actionTypes.GET_CONTRACT_INTEREST.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getContractInterest, action.payload);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { contractInterest: response.data.result },
    });
    yield put({ type: actionTypes.GET_CONTRACT_INTEREST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_CONTRACT_INTEREST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchUpdateContractInterest() {
  yield takeLatest(actionTypes.UPDATE_CONTRACT_INTEREST.TRIGGER, updateContractInterest);
}

function* updateContractInterest(action: sharedactionTypes): any {
  yield put({ type: actionTypes.UPDATE_CONTRACT_INTEREST.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getContractInterest, action.payload);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { contractInterest: response.data.result },
    });
    yield put({ type: actionTypes.UPDATE_CONTRACT_INTEREST.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_CONTRACT_INTEREST.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAmplitudeUserProperties() {
  yield takeLatest(actionTypes.GET_AMPLITUDE_USER_PROPERTIES.TRIGGER, getAmplitudeUserProperties);
}

function* getAmplitudeUserProperties(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getAmplitudeUserProperties);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { ampUserProp: response.data.result },
    });
    yield put({ type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_AMPLITUDE_USER_PROPERTIES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetProductDynamicPrices() {
  yield takeLatest(actionTypes.GET_DYNAMIC_PRODUCT_PRICES.TRIGGER, getProductDynamicPrices);
}

function* getProductDynamicPrices(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_DYNAMIC_PRODUCT_PRICES.REQUEST, meta: action.payload });
  try {
    const response = yield call(sharedApi.getProductDynamicPrices);
    yield put({
      type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
      payload: { productPrices: response.data.result },
    });
    yield put({ type: actionTypes.GET_DYNAMIC_PRODUCT_PRICES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DYNAMIC_PRODUCT_PRICES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* sharedSaga() {
  yield fork(watchsetSharedDetails);
  yield fork(watchClearAppState);
  yield fork(watchGetStateList);
  yield fork(watchgetCountiesList);
  yield fork(watchGetScrapers);
  yield fork(watchSelfDeclarationData);
  yield fork(watchDashboardData);
  yield fork(watchgetFiscalYear);
  yield fork(watchResponseDisplays);
  yield fork(watchDashboardBidsList);
  yield fork(watchDashboardQuotesList);
  yield fork(watchgetDashboardNetwork);
  yield fork(watchgetdashboardNotifications);
  yield fork(watchsubmitDisablenotification);
  yield fork(watchgetTopCommodities);
  yield fork(watchgetShowBids);
  yield fork(watchgetCommoditiesList);
  yield fork(watchgetSharedBidStatus);
  yield fork(watchgetAgenciesList);
  yield fork(watchgetLocationList);
  yield fork(watchgetThemes);
  yield fork(watchsetThemes);
  yield fork(watchgetOtherMemberInfo);
  yield fork(watchPopulateMetroDetails);
  yield fork(watchGetTimezoneList);
  yield fork(watchGetWatchedBidIds);
  yield fork(watchsubmitTrackEvents);
  yield fork(watchGetContractInterest);
  yield fork(watchUpdateContractInterest);
  yield fork(watchSiblingProductData);
  yield fork(watchRelatedBidsData);
  yield fork(watchgetAmplitudeUserProperties);
  yield fork(watchGetProductDynamicPrices);
  yield fork(watchgetCommodityTags);
}
