import styled from 'styled-components';

import {
  FlexContainer,
  FontFamily,
  FontWeight,
  Margin,
  Padding,
  ParagraphFontSize,
} from '@demandstar/components/styles';

export const AgencySelectionList = styled.div`
  padding-top: ${Padding.Base};
  padding-bottom: ${Padding.Extra};

  > label {
    font-family: ${FontFamily.Base};
    font-size: ${ParagraphFontSize.Large};
    font-weight: ${FontWeight.Base};
    margin-top: ${Margin.Extra};

    &:last-child {
      margin-bottom: ${Margin.Extra};
    }
  }
`;

export const SpacedButtonWrapper = styled(FlexContainer)`
  padding-top: ${Padding.Extra};
`;

export const FreeAgencySpacedButtonWrapperMobile = styled(FlexContainer)`
  padding-top: ${Padding.Extra};
   @media screen and (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap:10px;
  }
`;

export const SelectProductSpacedButtonWrapperMobile = styled(FlexContainer)`
  padding-top: ${Padding.Extra};
   justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
   @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap:10px;
  }
`;

export const CommodityCodesSpacedButtonWrapperMobile = styled(FlexContainer)`
  padding-top: ${Padding.Extra};
   @media screen and (max-width: 567px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap:10px;
  }
`;
