import React from 'react';
import { deprecatedGetDate, deprecatedGetDateTimeZoneConverted } from '../../../utils/helpers';
import NoResult from '../../customcontrols/noresult';
import { MemberType } from '../../../utils/constants';
import { loadingMsg } from '../../../utils/texts';
import Paging from '../../customcontrols/paging';
import history from '../../../utils/history';
import { Table } from 'reactstrap';
import { memberinfoTypes } from '../../../types';
import { useAuthContext } from 'src/auth/AuthProvider';

interface propsTypes {
  loadermsg: string;
  InternalLoader: boolean;
  list: any[];
  finallist: any[];
  total: number;
  currentPages: number;
  onPagingClick: any;
  listPerPages: number;
  pagefor: string;
  memberinfo: memberinfoTypes;
}

function ActivitiesList(props: propsTypes) {
  const {
    loadermsg,
    InternalLoader: loader,
    list,
    finallist,
    total,
    currentPages,
    onPagingClick,
    listPerPages,
    pagefor,
    memberinfo,
  } = props;
  const { auth } = useAuthContext();
  const { tzfn, tzn } = memberinfo;

  const GoToPage = (item: { type: string; id: any }) => {
    const user = auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
    const pagefor = item.type === 'Bid' ? 'bids' : item.type === 'Quote' ? 'quotes' : null;
    if (pagefor)
      history.push(
        `/${user}/${pagefor}/${item.id}/${auth?.memberType === MemberType.AgencyBuyer || item.type === 'Bid' ? 'details' : ''
        }`,
      );
  };

  return (
    <>
      <Table className='tableHData tableFixed'>
        <thead>
          <tr>
            {pagefor === 'notificationsbid' ? (
              <>
                <th>Title</th>
                {auth?.memberType === MemberType.AgencyBuyer ? null : <th>Buyer Name</th>}
              </>
            ) : pagefor === 'notificationsaccount' ? (
              <>
                <th>Title</th>
              </>
            ) : pagefor === 'quotes' ? (
              <>
                <th>Quote Name</th>
              </>
            ) : (
              <>
                <th>Bid Name</th>
              </>
            )}
            {pagefor === 'notificationsbid' || pagefor === 'notificationsaccount' ? null : (
              <>
                {auth?.memberType === MemberType.AgencyBuyer ? (
                  <>{pagefor === 'quotes' ? null : <th>Supplier</th>}</>
                ) : (
                  <th>Agency</th>
                )}
              </>
            )}
            {pagefor === 'notificationsbid' ||
              pagefor === 'notificationsaccount' ||
              pagefor === 'bidwatch' ? null : (
              <th>ID</th>
            )}
            {pagefor === 'notificationsbid' ? (
              <>
                <th>ID</th>
                <th>Message</th>
              </>
            ) : pagefor === 'notificationsaccount' ? (
              <th>Message</th>
            ) : (
              <th>Activity</th>
            )}
            <th>{pagefor === 'notificationsaccount' ? 'Date' : 'Date / Time'} </th>
          </tr>
        </thead>
        <tbody>
          {list && list.length > 0 ? (
            <>
              {finallist.length > 0 ? (
                <>
                  {finallist.map((item, index) => (
                    <tr key={index}>
                      <td className='text-truncate'>
                        {item.id && (item.type === 'Bid' || item.type === 'Quote') ? (
                          <span
                            title={item.title}
                            className='staticLink'
                            onClick={() => GoToPage(item)}
                          >
                            {item.title}
                          </span>
                        ) : (
                          item.title
                        )}
                      </td>
                      {pagefor === 'notificationsaccount' ? null : (
                        <>
                          {auth?.memberType === MemberType.AgencyBuyer ? (
                            <>
                              {pagefor === 'notificationsbid' || pagefor === 'quotes' ? null : (
                                <td className='text-truncate'>
                                  <span title={item.supplierName}>
                                    {item.supplierName}
                                  </span>
                                </td>
                              )}{' '}
                            </>
                          ) : (
                            <td>{item.buyerName}</td>
                          )}
                        </>
                      )}
                      {pagefor === 'bidwatch' || pagefor === 'notificationsaccount' ? null : (
                        <td>{item.identifier}</td>
                      )}
                      <td>
                        {item.description}{' '}
                        {auth?.memberType === MemberType.AgencyBuyer
                          ? deprecatedGetDateTimeZoneConverted(item.actionOn, tzfn)
                          : deprecatedGetDateTimeZoneConverted(
                            item.actionOn,
                            'Eastern Time (US & Canada)',
                          )}
                      </td>
                      <td>
                        {auth?.memberType === MemberType.AgencyBuyer ? (
                          <>
                            {deprecatedGetDateTimeZoneConverted(item.dateOfAction, tzfn)} {tzn}
                          </>
                        ) : (
                          <>
                            {pagefor === 'notificationsaccount'
                              ? `${deprecatedGetDate(item.dateOfAction)}`
                              : `${deprecatedGetDateTimeZoneConverted(
                                item.dateOfAction,
                                'Eastern Time (US & Canada)',
                              )} Eastern`}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <NoResult message={loadingMsg} />
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={10}>
                <NoResult message={loader ? loadingMsg : loadermsg} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <footer className='row'>
        <div className='col-12 '>
          <Paging
            directpage={false}
            totalRecords={total}
            currentPage={currentPages}
            onPagingClick={onPagingClick}
            limitmsg={false}
            pageLimit={listPerPages}
          />
        </div>
      </footer>
    </>
  );
}

export default ActivitiesList;
