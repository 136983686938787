import { axiosBarePostAuthenticated, axiosBareRequestAuthenticated } from 'src/store/services/api';
import {
  ContractDetailsAPI,
  ContractDocumentAPIResponse,
  ContractDocumentType,
} from '../../contract-management.d';

import { constants } from 'src/utils/settings';
import { paths } from '../../ContractManagement.paths';

export function updateContractDocument(contractDocument: ContractDocumentAPIResponse) {
  return axiosBarePostAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    data: [contractDocument],
    url: paths.api.documents.update(contractDocument.contractId || ''),
  });
}

export function deleteContractDocument(
  contractDocument: Pick<ContractDocumentAPIResponse, 'contractId' | 'documentId'>,
) {
  return axiosBareRequestAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    method: 'DELETE',
    url: paths.api.documents.delete(contractDocument.contractId || '', contractDocument.documentId),
  });
}
export function getContractDocumentTypes() {
  return axiosBareRequestAuthenticated<ContractDocumentType[]>({
    baseURL: constants.api.contractUrl,
    url: paths.api.documents.types.list,
  });
}

export function updateContractDocumentType(documentType: ContractDocumentType) {
  return axiosBarePostAuthenticated<ContractDocumentType>({
    baseURL: constants.api.contractUrl,
    data: {
      ...documentType,
    },
    url: documentType.id
      ? paths.api.documents.types.update(documentType.id)
      : paths.api.documents.types.create,
  });
}
