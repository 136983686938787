import styled from 'styled-components';

import {
  BorderColor,
  BorderRadius,
  BorderThickness,
  CustomFlex,
  DataColor,
  FontWeight,
  Margin,
  Padding,
} from '@demandstar/components/styles';

export const DashboardContent = styled(CustomFlex)`
  border: ${BorderThickness.Base} solid ${BorderColor.Base};
  border-radius: ${BorderRadius.Base};
  margin-bottom: ${Margin.Extra};
  padding: ${Padding.Base};
  width: 100%;

  div {
    height: 16rem;
  }

  h6 {
    text-align: center;
  }

  p {
    font-size: 2.5rem;
    font-weight: ${FontWeight.Bold};
    text-align: center;

    span {
      border-bottom: ${BorderThickness.Thick} solid ${DataColor.Primary};
      padding-bottom: ${Padding.Base};
    }
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  padding-left: 0;
  max-height: 16.5rem;
  overflow: hidden auto;

  li:not(:last-of-type) {
    margin-bottom: ${Margin.Less};
  }
`;
