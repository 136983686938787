import { createActionTypes, createNoLoadActionTypes } from './utils';

export const LOAD_BID_RESULT = createActionTypes('LOAD_BID_RESULT');
export const LOAD_NO_LOADER_BID_RESULT = createNoLoadActionTypes('LOAD_NO_LOADER_BID_RESULT');
export const LOAD_BID_LIST_ITEMS_DATA = createNoLoadActionTypes('LOAD_BID_LIST_ITEMS_DATA');
export const LOAD_BID_LIST_ITEMS_WATCH_DATA = createNoLoadActionTypes(
  'LOAD_BID_LIST_ITEMS_WATCH_DATA',
);
export const BIDS_PAGE_CHANGE = createNoLoadActionTypes('BIDS_PAGE_CHANGE');
export const BID_LIST_SORTING = createNoLoadActionTypes('BID_LIST_SORTING');
export const BID_LIST_FILTER_SUGGEST = createNoLoadActionTypes('BID_LIST_FILTER_SUGGEST');
export const BID_LIST_FILTER_CHANGE = createNoLoadActionTypes('BID_LIST_FILTER_CHANGE');
export const BID_LIST_CLEAR_FILTER = createNoLoadActionTypes('BID_LIST_CLEAR_FILTER');
export const BID_LIST_SET_FILTER = createNoLoadActionTypes('BID_LIST_SET_FILTER');
export const BID_WATCH = createNoLoadActionTypes('BID_WATCH');
export const BID_LIST_LOAD_FILTERS = createNoLoadActionTypes('BID_LIST_LOAD_FILTERS');
export const DELETE_BID = createActionTypes('DELETE_BID');
export const SET_BID_DETAILS = createNoLoadActionTypes('SET_BID_DETAILS');
export const GET_BID_VIEW = createNoLoadActionTypes('GET_BID_VIEW');
export const GET_BID_AWARDEES = createNoLoadActionTypes('GET_BID_AWARDEES');
export const RESET_BIDS = createNoLoadActionTypes('RESET_BIDS');
export const GET_BIDS_CONDITION_SPENDING = createActionTypes('GET_BIDS_CONDITION_SPENDING');
