import { DSLoadingPanel } from '@demandstar/components/loading-panel';

import { getDefaultLoadingErrorMessage, getDefaultLoadingMessage } from '../../../../utils/message';
import { bidResponseDetailsState } from '../../../../store/recoil/bidResponseState';
import { ContactDetailsPanelView } from './ContactDetailsPanelView';
import { ContactLabels } from '../../../../shared/constants';
import { ErrorPanel } from '../../../../components/common/loading';
import { memberInfoSelector } from '../../../../store/recoil/memberState';
import { useRecoilValueLoadable } from 'recoil';

/**
 * Container Component:
 * Pass app state for Response eDetails and Member Info
 * to the Presentation Component.
 * @returns
 */
export function ContactDetailsPanel() {
  const responseDetailsLoadable = useRecoilValueLoadable(bidResponseDetailsState);
  const memberInfoLoadable = useRecoilValueLoadable(memberInfoSelector);

  // SHow a loading message by default
  let returnContent = null;
  // Handle Recoil State Loadable states:
  // TODO: Standardize styled components for "hasError" and/or "loading" states.

  // TODO: In order to reduce logic for loadable states, as well as provide cleaner error handling,
  //   we should combine separate API calls into a single Recoil selector
  //   (e.g. `ContactState` with ResponseDetails and MemberInfoDetails)

  // if (responseDetailsLoadable.state === 'hasError') {
  if (responseDetailsLoadable.state === 'hasError' || memberInfoLoadable.state === 'hasError') {
    returnContent = <ErrorPanel message={getDefaultLoadingErrorMessage(ContactLabels.label)} />;
  }

  // if (responseDetailsLoadable.state === 'loading') {
  if (responseDetailsLoadable.state === 'loading' || memberInfoLoadable.state === 'loading') {
    returnContent = (
      <DSLoadingPanel>{getDefaultLoadingMessage(ContactLabels.label)}</DSLoadingPanel>
    );
  }

  // if (responseDetailsLoadable.state === 'hasValue' && responseDetailsLoadable?.contents) {
  if (responseDetailsLoadable.state === 'hasValue' && memberInfoLoadable.state === 'hasValue') {
    const responseDetails = responseDetailsLoadable.valueMaybe();

    returnContent = responseDetails ? <ContactDetailsPanelView data={responseDetails} /> : <></>;
  }

  return returnContent;
}
