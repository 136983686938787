import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { H4, Large } from '@demandstar/components/styles';
import { Divider } from '@demandstar/components/divider/Divider';

import * as texts from './ReviewYourContract.texts';
import { newContractIdState, useContractDetails } from '../../../useContractDetails';
import { ContractAwardeeTable } from '../contract-awardee-details/ContractAwardeeTable';
import { ContractDetailsSummary } from './ContractDetailsSummary';
import { ContractDocumentsTable } from '../../documents/ContractDocumentsTable';
import { ContractStatusPill } from 'src/features/contract-management/contract-status-pill/ContractStatusPill';
import { ContractWizardButtons } from '../ContractWizardButtons';
import { ContractWizardId } from '../useContractWizard';
import { hasValue } from 'src/utils/helpers';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { paths } from '../../../ContractManagement.paths';
import { useContractDocuments } from '../../documents/useContractDocuments';

/** Final page of Contract Wizard */
export const ReviewYourContract = () => {
  const { contractDetails, contractDetailsLoadable, submitContract } = useContractDetails();
  const setNewContractId = useSetRecoilState(newContractIdState);
  const { contractDocuments, contractDocumentsLoadable } = useContractDocuments();

  const history = useHistory();

  async function finalizeWizard() {
    // Submit the Contract
    await submitContract();
    setNewContractId(contractDetails?.id);
    history.push(paths.navigation.dashboard);
  }

  return (
    <LoadableWrapper loadable={contractDetailsLoadable}>
      {hasValue(contractDetailsLoadable) && contractDetails && (
        <>
          {/** Contract Details */}
          <H4>{texts.contractDetails}</H4>
          <ContractStatusPill contractId={contractDetails.id} />
          <ContractDetailsSummary />
          <Divider />
          {/** Supplier Details */}
          <H4>{texts.supplierDetails}</H4>
          {contractDetailsLoadable.contents && (
            <Large>
              {texts.supplierDetailsCopy(contractDetailsLoadable.contents.awardeeContacts.length)}
            </Large>
          )}
          <ContractAwardeeTable />
          <Divider />
          <H4>{texts.contractDocuments}</H4>
          <LoadableWrapper loadable={contractDocumentsLoadable}>
            <ContractDocumentsTable data={contractDocuments} itemsPerPage={5} />
          </LoadableWrapper>
        </>
      )}
      <ContractWizardButtons
        previousWizardId={ContractWizardId.ManageDocuments}
        onNext={finalizeWizard}
        altNext={texts.submit}
      />
    </LoadableWrapper>
  );
};
