import { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderThickness,
  FlexContainer,
  Margin,
  Padding,
  TextColor,
} from '../../styles';

import { BaseButton } from '../../button';
import { Inactive } from '../../types';

export const FileInputContainer = styled(FlexContainer)`
  align-items: normal;
  column-gap: ${Margin.Base};
  justify-content: space-between;
  position: relative;
`;

interface FileNameWrapperProps {
  hasError?: boolean;
  inactive?: boolean;
}

export const FileNameWrapper = styled.div<FileNameWrapperProps>`
  background: ${BackgroundColor.BaseWhite};
  border-radius: ${BorderRadius.Base};
  border: ${BorderThickness.Base} solid;
  border-color: ${({ hasError, inactive }) =>
    hasError ? BorderColor.Error : inactive ? BorderColor.Highlight : BorderColor.Base};
  color: ${({ inactive }) => (inactive ? TextColor.Inactive : TextColor.Base)};
  cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
  height: 2.65rem;
  line-height: 2rem;
  margin: ${Margin.Single} 0 0 0;
  min-width: 20rem;
  overflow: hidden;
  /* padding: 0.25rem 0.5rem 0.25rem 1.25rem; */
  padding: ${Margin.Small} ${Margin.Less};
  text-overflow: ellipsis;
  user-select: none;
  white-space: break-spaces;
  width: 100%;
  word-break: break-all;
`;

interface BaseFileInputProps extends HTMLProps<HTMLInputElement>, Inactive {}

export const FileInputButton = styled(BaseButton)`
  padding: 0.525rem ${Padding.Base};
  min-width: 10rem;
`;

/**
 * The FileInput control is hidden and a button is shown instead.
 */
export const BaseFileInput = styled.input<BaseFileInputProps>`
  cursor: ${({ inactive }) =>
    inactive ? 'default' : 'pointer'}; // fully hide the file input when inactive
  display: ${({ inactive }) => (inactive ? 'none' : 'inherit')};
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
`;
