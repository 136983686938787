import { createActionTypes, createNoLoadActionTypes } from './utils';

export const RESET_PAYMENT_PROCESS_STATUS = createNoLoadActionTypes('RESET_PAYMENT_PROCESS_STATUS');
export const FETCH_COMMODITY_CODES = createNoLoadActionTypes('FETCH_COMMODITY_CODES');
export const GET_ALL_PRODUCTS = createActionTypes('GET_ALL_PRODUCTS');
export const SET_SUBSCRIPTIONS_DETAILS = createNoLoadActionTypes('SET_SUBSCRIPTIONS_DETAILS');
export const GET_SUBSCRIPTION_MEMBER_DETAILS = createNoLoadActionTypes(
  'GET_SUBSCRIPTION_MEMBER_DETAILS',
);
export const CALCULATE_SUBSCRIPTION = createActionTypes('CALCULATE_SUBSCRIPTION');
export const PROCESS_PAYMENT = createActionTypes('PROCESS_PAYMENT');
export const POPULATE_POSTALCODE_DATA = createActionTypes('POPULATE_POSTALCODE_DATA');
export const UPDATE_SUBSCRIPTION_MEMBER_ADDRESS = createActionTypes(
  'UPDATE_SUBSCRIPTION_MEMBER_ADDRESS',
);
