import { AwardeeDraft } from 'src/store/recoil/bid-awardees';
import { Commodity } from '../../types/bidssummary';
/**
 * Convert commodities to human readable strings for use with Amplitude.
 * @param  {Commodity[]} commodities -
 */
export function mapAmplitudeCommodities(commodities: Commodity[] = []) {
  return commodities.map(commodity => {
    return `${commodity.formattedCode} ${commodity.commodityDescription}`;
  });
}
/**
 * Convert awardees to supplier names array for use with Amplitude.
 * @param  {AwardeeDraft[]} awardees
 */
export function mapAmplitudeAwardeeNames(awardees: AwardeeDraft[] = []) {
  return awardees.map(awardee => {
    return awardee.supplierName || '';
  });
}
/**
 * Convert awardees to award amount array for use with Amplitude.
 * @param  {AwardeeDraft[]} awardees
 */
export function mapAmplitudeAwardAmounts(awardees: AwardeeDraft[] = []) {
  return awardees.map(awardee => {
    return Number.isNaN(awardee.amount) || awardee.amount === undefined ? 'N/A' : awardee.amount;
  });
}
