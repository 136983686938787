export const maxUploadInMB = 205;
export const fileUploadMaxSize = maxUploadInMB * 1024 * 1024; // 105MB
export const fileUploadMaxSizeDisplay = 200;

export const supportedFileTypes = [
  'PDF',
  'CSV',
  'HTM',
  'HTML',
  'JPEG',
  'JPG',
  'XLSM',
  'XLSB',
  'XLS',
  'XLSX',
  'PPTX',
  'PPT',
  'DOC',
  'DOCX',
  'ODS',
  'ODT',
  'TXT',
  'PNG',
  'TIFF',
  'TIF',
] as const;
