import { memo, useCallback, useEffect } from 'react';

import AddNewTemplate from './addtemplate';

import { Buttons } from '../../../customcontrols';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface PropsTypes {
  memberinfo?: any;
  memberInfo?: any;
  pagefor?: string;
  legalData?: any;
  setAccountInfoDetails?: any;
  getSavedLegal?: any;
  match?: any;
  accountinfo?: {
    addTemplateModal?: boolean;
    selectedTemplateItem?: any;
    savedLegalData?: {
      legalAdIntro?: string;
      legalAdCostInfo?: string;
      legalAdDueDateTime?: string;
      legalAdAdditional?: string;
      legalAdClosing?: string;
      eQuoteIntro?: string;
      eQuoteInsurance?: string;
      eQuoteClose?: string;
    };
    updateType?: number;
  };
}

const LegalAd = (props: PropsTypes) => {
  const {
    accountinfo,
    setAccountInfoDetails,
    memberinfo,
    pagefor = 'legal',
    legalData = {},
    memberInfo,
    getSavedLegal,
  } = props;
  const {
    savedLegalData = {},
    updateType = 1,
    addTemplateModal = false,
    selectedTemplateItem = {},
  } = accountinfo || {};
  // eslint-disable-next-line prefer-const
  //let { qintro = '', qins = '', qclose = '', mi = '' } = memberinfo || {};
  let {
    legalAdIntro = '',
    legalAdCostInfo = '',
    legalAdDueDateTime = '',
    legalAdAdditional = '',
    legalAdClosing = '',
    eQuoteIntro: qintro = '',
    eQuoteInsurance: qins = '',
    eQuoteClose: qclose = '',
  } = savedLegalData;

  if (pagefor === 'summary') {
    legalAdIntro = legalData.legalAdIntro;
    legalAdCostInfo = legalData.legalAdCostInfo;
    legalAdDueDateTime = legalData.legalAdDueDateTime;
    legalAdAdditional = legalData.legalAdAdditional;
    legalAdClosing = legalData.legalAdClosing;
    qintro = legalData.eQuoteIntro;
    qins = legalData.eQuoteInsurance;
    qclose = legalData.eQuoteClose;
  }

  const editTemplate = useCallback(() => {
    const initialData = {
      legalIntro: legalAdIntro,
      legalCostInfo: legalAdCostInfo,
      legalDueDateTime: legalAdDueDateTime,
      legalAdditional: legalAdAdditional,
      legalClosing: legalAdClosing,
      quoteIntro: qintro,
      quoteClose: qclose,
      quoteInsurance: qins,
    };
    setAccountInfoDetails({
      selectedTemplateItem: initialData,
      addTemplateModal: true,
      updateType: 2,
    });
  }, [
    legalAdAdditional,
    legalAdClosing,
    legalAdCostInfo,
    legalAdDueDateTime,
    legalAdIntro,
    qclose,
    qins,
    qintro,
    setAccountInfoDetails,
  ]);

  useEffect(() => {
    let savedLegalAPICALL = true;
    if (!savedLegalData && savedLegalAPICALL) getSavedLegal();
    return () => {
      savedLegalAPICALL = false;
    };
  }, [getSavedLegal, savedLegalData]);

  useSetBreadcrumb(
    {
      component: (
        <li title='Edit Legal Ad'>
          <span
            className='staticLink addBid'
            onClick={event => {
              event.preventDefault();
              editTemplate();
            }}
          >
            <i className='mdi mdi-square-edit-outline mr-1' /> Edit Legal Ad
          </span>
        </li>
      ),
      page: 'Agency Bid Configuration',
      altname: 'Legal Ads',
      title: 'Legal Ads',
      name: 'Legal Ads',
      menuactive: '',
    },
    pagefor !== 'summary',
  );

  return (
    <>
      {pagefor !== 'summary' && <h3 className='arrowWrapper'>Legal Ad</h3>}
      <div className='innerColIndent clearfix'>
        <h6>Introductory Text</h6>
        <p>{legalAdIntro ? legalAdIntro : 'None'}</p>
        <h6>Cost information</h6>
        <p>{legalAdCostInfo ? legalAdCostInfo : 'None'}</p>
        <h6>Due Date/Time Text</h6>
        <p>{legalAdDueDateTime ? legalAdDueDateTime : 'None'}</p>
        <h6>Additional Text</h6>
        <p>{legalAdAdditional ? legalAdAdditional : 'None'}</p>
        <h6>Closing Text</h6>
        <p>{legalAdClosing ? legalAdClosing : 'None'}</p>
        <h6>Quote Introductory Text</h6>
        <p>{qintro ? qintro : 'None'}</p>
        <h6>Quote Terms and Conditions</h6>
        <p>{qclose ? qclose : 'None'}</p>
        <h6>Quote Insurance Requirements</h6>
        <p>{qins ? qins : 'None'}</p>
        {pagefor !== 'summary' && (
          <div className='d-block'>
            <Buttons
              classNames='bttn-primary mt-4 float-right'
              text='Edit Legal Ad'
              title='Edit Legal Ad'
              type='button'
              onClick={editTemplate}
            />
          </div>
        )}
      </div>
      <ModalPopUp
        title={updateType === 1 ? 'Add Template' : 'Edit Legal Ad'}
        size='lg'
        isOpen={addTemplateModal}
        closeModal={() => setAccountInfoDetails({ addTemplateModal: !addTemplateModal })}
      >
        <AddNewTemplate {...props} initialValues={selectedTemplateItem} />
      </ModalPopUp>
    </>
  );
};

export default memo(LegalAd);
