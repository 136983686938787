import { atom, selector } from 'recoil';

import {
  NationalProductId,
  ProductApiResponse,
  ProductType,
  SubscriptionProducts,
} from '../../types/products';

import { getProducts } from '../services/subscriptions';
import { UpsellCardData } from '../../types/registration';

/**
 * Make a service API call to populate a Recoil Selector.
 * TODO: Implement dynamic updates via WebHooks or explicit component re-instantiation.
 */
export const allProductsSelector = selector<ProductApiResponse[]>({
  key: 'allProductsSelector',
  get: async () => {
    try {
      const response = await getProducts();
      return response || [];
    } catch (error: any) {
      throw new Error(`Error in 'getAllProducts() for allProductsSelector': ${error.message}`);
    }
  },
});

/**
 * Initialize the allProducts state with the associated API call.
 */
export const allProductsState = atom<ProductApiResponse[]>({
  key: 'allProductsState',
  default: allProductsSelector,
});

export const cartTotalState = atom<number>({
  key: 'cartTotalState',
  default: 0,
});

export const cartRenewalState = atom<boolean>({
  key: 'cartRenewalState',
  default: false,
});

export const initialAgencyProduct: ProductApiResponse = {
  parentId: 0,
  parentName: '',
  parentPrice: 0,
  parentType: ProductType.County,
  price: 0,
  productId: 0,
  productGroupId: 0,
  productName: '',
  productType: ProductType.FreeAgency,
  sortOrder: 0,
};
export const initialCountyProduct: ProductApiResponse = {
  parentId: 0,
  parentName: '',
  parentPrice: 0,
  parentType: ProductType.State,
  price: 0,
  productId: 0,
  productGroupId: 0,
  productName: '',
  productType: ProductType.County,
  sortOrder: 0,
};
export const initialNationalProduct: ProductApiResponse = {
  price: 0,
  productId: 0,
  productGroupId: 0,
  productName: '',
  productType: ProductType.National,
  sortOrder: 0,
};
export const initialStateProduct: ProductApiResponse = {
  parentId: NationalProductId.UnitedStates,
  parentName: '',
  parentPrice: 0,
  parentType: ProductType.National,
  price: 0,
  productId: 0,
  productGroupId: 0,
  productName: '',
  productType: ProductType.State,
  sortOrder: 0,
};

export const parentStateState = atom<ProductApiResponse>({
  key: 'parentStateState',
  default: initialStateProduct,
});

export const selectedProductsState = atom<SubscriptionProducts>({
  key: 'selectedProductsState',
  // when subscription page goes away, this will have to come from subscriptions API call:
  default: {
    agency: initialAgencyProduct,
    county: [],
    national: 0,
    state: [],
  },
});

export const upsellProductsState = atom<UpsellCardData[]>({
  key: 'upsellProductsState',
  default: [],
});
