import { DSLinkButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { ProductsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';
import { UpsellList } from '../../upsell/UpsellList';
import { UpsellWrapper } from './styles';

/**
 * @description A wrapper for the `UpsellList` that adds an 'Explore all products' button
 * @returns JSX.Element
 * @example <UpsellSection />
 */
export const UpsellSection = () => {
  return (
    <UpsellWrapper>
      <UpsellList />
      <FlexContainer justifyContent='center'>
        <DSLinkButton
          title={ProductsCopy.ExploreAvailableProductsText}
          to={ProductsPaths.ExploreProducts}
        >
          {ProductsCopy.ExploreAvailableProductsText}
        </DSLinkButton>
      </FlexContainer>
    </UpsellWrapper>
  );
};
