import { FieldError } from 'react-hook-form';
import { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderThickness,
  FontFamily,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../../styles';

interface BaseTextInputProps extends HTMLProps<HTMLInputElement> {
  error?: FieldError;
  isNumeric?: boolean;
  hasNA?: boolean;
}

/**
 * This is the base Input control, intended to be themed and extended
 * by specific implementations, e.g. TextInput, PasswordInput.
 */
export const BaseTextInput = styled.input<BaseTextInputProps>`
  border: ${BorderThickness.Base} solid;
  border-color: ${({ error }) => (error ? BorderColor.Error : BorderColor.Base)};
  border-radius: ${BorderRadius.Base};
  background: ${({ readOnly }) => (readOnly ? BackgroundColor.Inactive : BackgroundColor.Input)};
  width: 100%;
  padding: 0.25rem ${({ hasNA }) => (hasNA ? '4.5rem' : '0.5rem')} 0.25rem 0.5rem;
  font-family: ${FontFamily.Base};
  font-weight: 400;
  font-style: normal;
  font-size: ${ParagraphFontSize.Base};
  color: ${({ readOnly }) => (readOnly ? TextColor.Inactive : TextColor.Base)};
  text-overflow: ellipsis;
  word-spacing: normal;
  line-height: 2rem;
  text-align: ${({ isNumeric }) => (isNumeric ? 'right' : 'left')};
  &:focus {
    outline: none;
  }
`;

/** Wraps registration components to override SCSS issues */
export const LibraryStyleWrapper = styled.div`
  ${BaseTextInput} {
    padding-left: ${Padding.Base};
  }
`;
