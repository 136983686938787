import React, { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { BidExternalStatusType, pageSize, sortDirections } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { deprecatedGetDateTimeZoneConvertedDate } from '../../../../../utils/helpers';
import SortingIcon from '../../../../common/sortingIcon';
import { Paging } from '../../../../customcontrols';
import DocumentList from './documents';
import Similarsuppliers from './similarsuppliers';
import Supplierdetails from './supplierdetails';
import { NoResult } from '../../../../customcontrols/index';
import { bidItemParamType, bidssummaryParamType } from '../../../../../types/biddetail';
import { sharedTypes } from '../../../../../types/shared';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes {
  getbidsPostbidViewers?: any;
  setBidSummaryDetails?: any;
  getSelfDeclarations?: any;
  setSharedDetails?: any;
  setPostBidSupplierDetails?: any;
  setSelectedPostbidSupplier?: any;
  bidssummary?: bidssummaryParamType;
  shared: sharedTypes;
  match?: any;
}

function Postviewers(props: PropsTypes) {
  const {
    getbidsPostbidViewers,
    setBidSummaryDetails,
    setSharedDetails,
    setPostBidSupplierDetails,
    setSelectedPostbidSupplier,
    bidssummary,
    shared,
  } = props;
  const { auth } = useAuthContext();

  const bidId = parseInt(props.match.params.bidId);
  const { postbid_internalLoader } = shared || {};
  const { PostbidViewers, currentpostviewer, results } = bidssummary || {};
  const {
    aph: canAddPlanHolder = false,
    bidExternalStatusType = BidExternalStatusType.None,
    memberID: bidMemberId = '',
    tzfn,
  } = (results as bidItemParamType) || {};
  const { result } = PostbidViewers || {};
  const viewers_list = result || [];

  const bidInfo = {
    bidId,
    memberId: auth?.memberId as any,
    bidMemberId,
    bidExternalStatusType,
    canAddPlanHolder,
  };
  useEffect(() => {
    if (bidId) {
      setSharedDetails({ postbid_internalLoader: true });
      getbidsPostbidViewers({ bidId });
    }
  }, [setSharedDetails, bidId, getbidsPostbidViewers]);

  const Permission = ![
    BidExternalStatusType.Awarded,
    BidExternalStatusType.UnderEvaluation,
  ].includes(bidExternalStatusType);

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = Permission ? pageSize.planholders : 15;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  // sort
  const [sortvalue, setSortvalue] = useState('supplierName');
  const [sortorder] = useState(sortDirections.ASC);

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h3 className='mb-3 arrowWrapper'>Postbid Viewers</h3>
        </div>
        {currentpostviewer && viewers_list && viewers_list.length > 0 ? (
          <>
            <div className='col-12 col-lg-6 pr-0'>
              <div className='ulListWrapper'>
                <h4>Supplier ({viewers_list.length})</h4>
                <Table className='tableHData'>
                  <tr>
                    <th>
                      Supplier{' '}
                      <SortingIcon
                        sortlist={viewers_list}
                        initialsortorder={sortorder}
                        currentsortname={sortvalue}
                        changesortname={(value: string) => setSortvalue(value)}
                        sortname='supplierName'
                        changeSortingFn={(list: any) =>
                          setBidSummaryDetails({
                            PostbidViewers: { ...PostbidViewers, result: list },
                          })
                        }
                      />
                    </th>
                    <th>Download Date</th>
                  </tr>
                  {viewers_list
                    .map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (item.mi !== currentpostviewer.mi) {
                            // const selectedSupplier = selectedSuppliers.filter(fitem => fitem.mi === item.mi)
                            // if (selectedSupplier.length === 0) {
                            setSelectedPostbidSupplier(item);
                            setPostBidSupplierDetails({ currentpostviewer: item, bidId });
                            // } else setSelectedPostbidSupplier(selectedSupplier[0])
                            /* loadBidPlanholderDocuments({ supplierId: item.mi, bidId })
														setSelfDeclarationDetail({ memberId: item.mi, isCurrentMember: false }) */
                          }
                        }}
                        className={`${
                          item.mi === currentpostviewer.mi ? 'active' : ''
                        } cursorPointer`}
                      >
                        <td>{item.supplierName}</td>
                        {/* <td>Industry</td> */}
                        <td>
                          {deprecatedGetDateTimeZoneConvertedDate(item.sentDate, tzfn)}
                          {/* <span className='left'></span> */}
                        </td>
                      </tr>
                    ))
                    .slice(indexOfFirstList, indexOfLastList)}
                </Table>
                <Paging
                  totalRecords={viewers_list.length}
                  currentPage={currentPage}
                  pagefor='planholders-supplier'
                  onPagingClick={(value: number) => {
                    if (value !== currentPage) setCurrentPage(Number(value));
                  }}
                  pageLimit={listPerPage}
                />
              </div>
            </div>
            <div className='col-12 col-lg-6 responsecontent'>
              <div className='leftBorder clearfix'>
                <Supplierdetails
                  {...props}
                  currentData={currentpostviewer}
                  pagefor='postbidviewers'
                  bidInfo={bidInfo}
                />
                <DocumentList
                  {...props}
                  planholderDocuments={currentpostviewer.planholderDocuments}
                />
              </div>
              {Permission && (
                <div className='pl-4 clearfix'>
                  <Similarsuppliers {...props} bidId={bidId} pagfor='similarPlanholder' />
                </div>
              )}
            </div>
          </>
        ) : (
          <NoResult
            message={postbid_internalLoader ? loadingMsg : 'No Postbid Viewers Available'}
          />
        )}
      </div>
    </>
  );
}

export default memo(Postviewers);
