import { atom, useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';

import { useSelectedBidId, useSetSelectedBidIdFromRoute } from 'src/shared/hooks/useSelectedBidId';
import { WizardPage, WizardStatus } from '../../../../types/wizard';
import { baseWizardBehavior } from 'src/components/customcontrols/wizard/wizard-helpers';
import { useBid } from 'src/shared/hooks/useBid';
import { useBidAwardees } from 'src/store/recoil/bid-awardees/useBidAwardees';
import { useContractDetails } from '../../useContractDetails';
import { useRouter } from 'src/shared/hooks';

export enum ContractWizardId {
  None,
  ContractSummary,
  SupplierDetails,
  ManageDocuments,
  //RequestDocuments,
  Review,
}

export const contractWizardIdState = atom<ContractWizardId>({
  key: 'contractWizardIdState',
  default: ContractWizardId.ContractSummary,
});

export function useContractWizard() {
  const contractWizardId = useRecoilValue(contractWizardIdState);
  const resetWizardId = useResetRecoilState(contractWizardIdState);
  const { contractDetails } = useContractDetails();
  const { bid, refreshBid } = useBid();
  const { selectedBidId } = useSelectedBidId();
  const { bidAwardees } = useBidAwardees(selectedBidId);
  const { history } = useRouter();

  useSetSelectedBidIdFromRoute();

  const isVerification = !!contractDetails?.bidId;

  const contractWizardBehavior = useCallback(
    (wizardId: ContractWizardId) => {
      return baseWizardBehavior(wizardId, contractWizardId, () => {
        if (contractDetails) {
          return contractWizardId > wizardId ? WizardStatus.Completed : WizardStatus.Available;
        } else {
          return WizardStatus.Unavailable;
        }
      });
    },
    [contractDetails, contractWizardId],
  );

  const contractSummaryStatus = useMemo(() => {
    return contractWizardBehavior(ContractWizardId.ContractSummary);
  }, [contractWizardBehavior]);

  const supplierDetailsStatus = useMemo(() => {
    return contractWizardBehavior(ContractWizardId.SupplierDetails);
  }, [contractWizardBehavior]);

  const manageDocumentsStatus = useMemo(() => {
    return contractWizardBehavior(ContractWizardId.ManageDocuments);
  }, [contractWizardBehavior]);

  // const requestDocumentsStatus = useMemo(() => {
  //   return contractWizardBehavior(ContractWizardId.RequestDocuments);
  // }, [contractWizardBehavior]);

  const reviewStatus = useMemo(() => {
    return contractWizardBehavior(ContractWizardId.Review);
  }, [contractWizardBehavior]);

  const contractWizardPages: WizardPage[] = useMemo(
    () => [
      {
        id: ContractWizardId.ContractSummary,
        name: `${isVerification ? 'Verify' : 'Add'} Contract Details`,
        navName: 'Contract Summary',
        status: contractSummaryStatus,
      },
      {
        id: ContractWizardId.SupplierDetails,
        name: `${isVerification ? 'Verify' : 'Add'} Awardee Details`,
        navName: 'Supplier Details',
        status: supplierDetailsStatus,
      },
      {
        id: ContractWizardId.ManageDocuments,
        name: 'Manage Contract Documents',
        navName: 'Manage Documents',
        status: manageDocumentsStatus,
      },
      // {
      //   id: ContractWizardId.RequestDocuments,
      //   name: 'Request Documents From Supplier',
      //   navName: 'Request Documents',
      //   status: requestDocumentsStatus,
      // },
      {
        id: ContractWizardId.Review,
        name: 'Review Your Contract',
        navName: 'Review',
        status: reviewStatus,
      },
    ],
    [
      contractSummaryStatus,
      isVerification,
      manageDocumentsStatus,
      //requestDocumentsStatus,
      reviewStatus,
      supplierDetailsStatus,
    ],
  );

  /** navigate to new wizard page */
  const navigateContractWizard = useRecoilCallback(
    ({ set }) =>
      async (navTo?: ContractWizardId) => {
        /* istanbul ignore else */
        if (navTo || navTo === ContractWizardId.None) {
          set(contractWizardIdState, navTo);
        }
        if (navTo === ContractWizardId.None) {
          /** TODO: Replace with relevant path (and relevant routing strategy) */
          history.push('/buyers/contract');
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (selectedBidId) {
      refreshBid();
    }
  }, [refreshBid, selectedBidId]);

  function saveAndFinishLater() {
    navigateContractWizard(ContractWizardId.None);
  }

  return {
    bid,
    bidAwardees,
    contractWizardId,
    contractWizardPages,
    navigateContractWizard,
    resetWizardId,
    saveAndFinishLater,
  };
}
