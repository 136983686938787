import { TollFreeNumber } from 'src/utils/texts/common';

import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';

function AgencyGettingHelp() {
  return (
    <>
      Our government help desk is available from 9 a.m. to 9 p.m. Eastern time and we generally
      respond to all inquiries within 2 hours. You can email us at {DemandStarSupportEmail} or
      simply call us at {TollFreeNumber}. We are happy to help you anytime.
    </>
  );
}

export default AgencyGettingHelp;
