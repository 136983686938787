import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import DateRangePickerComponent from '../../../customcontrols/daterangepicker';
import NoResult from '../../../customcontrols/noresult';

import { activitiesTypes, memberinfoTypes } from '../../../../types';
import { Buttons, Paging, ScrollButton, SelectBox } from '../../../customcontrols';
import {
  quoteReportsActivityPaginationsOptions,
  reportbidStatus,
  reportotherquoteStatus,
  reportquoteStatus,
  usaDateFormat,
  bidReportsActivityPaginationsOptions,
  reportotherbidStatus,
} from '../../../../utils/constants';

import { deprecatedConvertotherTimezonetoUTC } from '../../../../utils/helpers';
import { loadingMsg } from '../../../../utils/texts';
import {
  bidreports_quotesInitialValue,
  quotereports_quotesInitialValue,
} from '../../../../store/reducers/activities';
import { reactselectTypes } from '../../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

const style = {
  tdWidth: { width: '140px' },
  tdWidth1: { minWidth: '160px' },
  tdWidth2: { minWidth: '220px' },
};

interface PropTypes {
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
  getQuoteActivityReport: any;
  getBidActivityReport: any;
  exportQuoteActivityReport: any;
  exportBidActivityReport: any;
  setActivitiesDetails: any;
  memberInfo: any;
  report: any;
}

const BidQuoteActivityReports = (props: PropTypes) => {
  const {
    report,
    getQuoteActivityReport,
    setActivitiesDetails,
    getBidActivityReport,
    activities,
    memberinfo,
  } = props;
  const { auth } = useAuthContext();
  const {
    activities_quotereports,
    quotereports_status,
    quotereports_startDate,
    quotereports_endDate,
    quotereport_internalLoader,
    activities_quotereports_search,
    activities_quotereports_search_result,
    quoteReportsActivityPaginations,
    activities_bidreports,
    bidreports_status,
    bidreports_startDate,
    bidreports_endDate,
    bidreport_internalLoader,
    activities_bidreports_search,
    activities_bidreports_search_result,
    bidReportsActivityPaginations,
  } = activities || {};
  const {
    quoteActivity = [],
    cancelledQuotes = 0,
    closedQuotes = 0,
    incompleteQuotes = 0,
    awardedQuotes = 0,
    openQuotes = 0,
  } = activities_quotereports || {};
  const {
    quoteActivity: ResultquoteActivity = [],
    cancelledQuotes: ResultcancelledQuotes = 0,
    closedQuotes: ResultclosedQuotes = 0,
    incompleteQuotes: ResultincompleteQuotes = 0,
    awardedQuotes: ResultawardedQuotes = 0,
    openQuotes: ResultopenQuotes = 0,
  } = activities_quotereports_search_result || {};
  const {
    bidActivity = [],
    upcomingBids = 0,
    activeBids = 0,
    underEvaluationBids = 0,
    awardedBids = 0,
    totalBids = 0,
  } = activities_bidreports || {};
  const {
    bidActivity: ResultbidActivity = [],
    upcomingBids: ResultupcomingBids = 0,
    activeBids: ResultactiveBids = 0,
    underEvaluationBids: ResultunderEvaluationBids = 0,
    awardedBids: ResultawardedBids = 0,
    totalBids: ResulttotalBids = 0,
  } = activities_bidreports_search_result || {};

  //Quote Pagination
  const [quoteCurrentPage, setQuoteCurrentPage] = useState(1);
  const indexOfLastListsQuote = quoteCurrentPage * quoteReportsActivityPaginations?.value;
  const indexOfFirstListsQuote = indexOfLastListsQuote - quoteReportsActivityPaginations?.value;
  //bid Pagination
  const [bidCurrentPage, setBidCurrentPage] = useState(1);
  const indexOfLastListsBid = bidCurrentPage * quoteReportsActivityPaginations?.value;
  const indexOfFirstListBid = indexOfLastListsBid - quoteReportsActivityPaginations?.value;

  const { tzfn } = memberinfo || {};

  useSetBreadcrumb({
    page: 'Activities',
    altname: 'Reports',
    title: 'Reports',
    name: 'Reports',
    menuactive: 'activities',
  });

  const quoteApiCallFn = (
    startDate: string,
    endDate: string,
    statusList: string,
    search: boolean,
    type: string,
  ) => {
    let final_value: any = {
      quotereport_internalLoader: true,
      activities_quotereports_search: search,
      activities_quotereports: quotereports_quotesInitialValue,
      activities_quotereports_search_result: quotereports_quotesInitialValue,
    };
    if (type === 'clear') {
      final_value = {
        ...final_value,
        quotereports_startDate: moment().subtract(1, 'months'),
        quotereports_endDate: moment(),
        quotereports_status: reportbidStatus[0],
      };
    }

    setActivitiesDetails(final_value);
    if (startDate) startDate = deprecatedConvertotherTimezonetoUTC(startDate, tzfn, usaDateFormat);
    if (endDate) endDate = deprecatedConvertotherTimezonetoUTC(endDate, tzfn, usaDateFormat);
    getQuoteActivityReport({
      memberId: auth?.memberId,
      startDate,
      endDate,
      statusList,
      search: search,
    });
    setQuoteCurrentPage(1);
  };

  const bidApiCallFn = (
    startDate: string,
    endDate: string,
    statusList: string,
    search: boolean,
    type: string,
  ) => {
    let final_value: any = {
      bidreport_internalLoader: true,
      activities_bidreports_search: search,
      activities_bidreports: bidreports_quotesInitialValue,
      activities_bidreports_search_result: bidreports_quotesInitialValue,
    };
    if (type === 'clear') {
      final_value = {
        ...final_value,
        bidreports_startDate: moment().subtract(1, 'months'),
        bidreports_endDate: moment(),
        bidreports_status: reportbidStatus[0],
      };
    }
    setActivitiesDetails(final_value);
    if (startDate) startDate = deprecatedConvertotherTimezonetoUTC(startDate, tzfn, usaDateFormat);
    if (endDate) endDate = deprecatedConvertotherTimezonetoUTC(endDate, tzfn, usaDateFormat);

    getBidActivityReport({
      memberId: auth?.memberId,
      startDate,
      endDate,
      statusList,
      search: search,
    });
    setBidCurrentPage(1);
  };

  const onChangeSelectBox = (name: string, value: reactselectTypes) => {
    setActivitiesDetails({ [name]: value });
    if (value?.value === 'quoteReportsActivityPaginations') {
      setQuoteCurrentPage(1);
    }
    if (value?.value === 'bidReportsActivityPaginations') {
      setBidCurrentPage(1);
    }
  };

  const onInputChange = (name: string, value: React.SetStateAction<moment.Moment>) => {
    if (report === 'quotes') {
      name === 'startDate'
        ? setActivitiesDetails({ quotereports_startDate: value })
        : setActivitiesDetails({ quotereports_endDate: value });
    } else {
      name === 'startDate'
        ? setActivitiesDetails({ bidreports_startDate: value })
        : setActivitiesDetails({ bidreports_endDate: value });
    }
  };

  const onSearchFn = () => {
    if (report === 'quotes') {
      const apistartDate = quotereports_startDate
        ? moment(quotereports_startDate).format(usaDateFormat)
        : '';
      const apiendDate = quotereports_endDate
        ? moment(quotereports_endDate).format(usaDateFormat)
        : '';
      const statusList =
        quotereports_status?.value === 'all' ? reportotherquoteStatus : quotereports_status?.value;
      quoteApiCallFn(apistartDate, apiendDate, statusList, true, '');
    } else if (report === 'bids') {
      const apistartDate = bidreports_startDate
        ? moment(bidreports_startDate).format(usaDateFormat)
        : '';
      const apiendDate = bidreports_endDate ? moment(bidreports_endDate).format(usaDateFormat) : '';
      const statusList =
        bidreports_status?.value === 'all' ? reportotherbidStatus : bidreports_status?.value;
      bidApiCallFn(apistartDate, apiendDate, statusList, true, '');
    }
  };

  const onClearFn = () => {
    const apistartDate = moment().subtract(1, 'months').format(usaDateFormat);
    const apiendDate = moment().format(usaDateFormat);
    report === 'quotes'
      ? quoteApiCallFn(apistartDate, apiendDate, reportotherquoteStatus, false, 'clear')
      : bidApiCallFn(apistartDate, apiendDate, reportotherbidStatus, false, 'clear');
  };

  const quoteReports_list = activities_quotereports_search ? ResultquoteActivity : quoteActivity;
  const finalincompleteQuotes = activities_quotereports_search
    ? ResultincompleteQuotes
    : incompleteQuotes;
  const finalcancelledQuotes = activities_quotereports_search
    ? ResultcancelledQuotes
    : cancelledQuotes;
  const finalclosedQuotes = activities_quotereports_search ? ResultclosedQuotes : closedQuotes;
  const finalawardedQuotes = activities_quotereports_search ? ResultawardedQuotes : awardedQuotes;
  const finalopenQuotes = activities_quotereports_search ? ResultopenQuotes : openQuotes;

  const bidReports_list = activities_bidreports_search ? ResultbidActivity : bidActivity;
  const finalupcomingBids = activities_bidreports_search ? ResultupcomingBids : upcomingBids;
  const finalactiveBids = activities_bidreports_search ? ResultactiveBids : activeBids;
  const finalunderEvaluationBids = activities_bidreports_search
    ? ResultunderEvaluationBids
    : underEvaluationBids;
  const finalawardedBids = activities_bidreports_search ? ResultawardedBids : awardedBids;
  const finaltotalBids = activities_bidreports_search ? ResulttotalBids : totalBids;

  const quotenodata_text = activities_quotereports_search
    ? 'No Search Result Available'
    : 'No Data Available';
  const bidnodata_text = activities_bidreports_search
    ? 'No Search Result Available'
    : 'No Data Available';

  const nodata_component = (
    <tr>
      <td colSpan={12}>
        {report === 'quotes' ? (
          <NoResult message={quotereport_internalLoader ? loadingMsg : quotenodata_text} />
        ) : (
          <NoResult message={bidreport_internalLoader ? loadingMsg : bidnodata_text} />
        )}
      </td>
    </tr>
  );

  return (
    <>
      <div className='row mb-3 '>
        <div className='col-lg-12'>
          <div
            className='clearfix d-flex align-items-center py-3 activitiesFilter'
            data-testid='activities.bids.reports.inputs'
          >
            <SelectBox
              reactselect={true}
              label='Status'
              name={report === 'quotes' ? 'quotereports_status' : 'bidreports_status'}
              options={report === 'quotes' ? reportquoteStatus : reportbidStatus}
              handleSelect={onChangeSelectBox}
              value={report === 'quotes' ? quotereports_status : bidreports_status}
            />
            <DateRangePickerComponent
              label='Date Range'
              startDatename='startDate'
              endDatename='endDate'
              handleChange={onInputChange}
              isOutsideRange={true}
              startDatevalue={report === 'quotes' ? quotereports_startDate : bidreports_startDate}
              endDatevalue={report === 'quotes' ? quotereports_endDate : bidreports_endDate}
              showClearDate={true}
              parentClass='clearfix mr-3 mb-0'
            />
            <Buttons
              text='Search'
              title='Search'
              classNames='bttn-primary'
              onClick={onSearchFn}
              disable={
                report === 'quotes'
                  ? !quotereports_startDate || !quotereports_endDate
                  : !bidreports_startDate || !bidreports_endDate
              }
            />
            <Buttons
              classNames='bttn-secondary'
              text='Reset'
              title='Reset'
              type='button'
              onClick={onClearFn}
            />
          </div>
        </div>
      </div>
      {report === 'quotes' ? (
        <>
          <div className='row mb-3 countsWrapper'>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.under'>
              <h2>{finalincompleteQuotes}</h2>
              <p>Incomplete</p>
            </div>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.total'>
              <h2>{finalopenQuotes}</h2>
              <p>Open</p>
            </div>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.award'>
              <h2>{finalawardedQuotes}</h2>
              <p>Awarded</p>
            </div>
            <div
              className='col text-center'
              data-testid='activities.bids.reports.table.head.active'
            >
              <h2>{finalclosedQuotes}</h2>
              <p>Closed</p>
            </div>
            <div
              className='col text-center'
              data-testid='activities.bids.reports.table.head.upcoming'
            >
              <h2>{finalcancelledQuotes}</h2>
              <p>Cancelled</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='d-block clearfix mb-4' />
              <div className='bidReportScrollContainer'>
                <ScrollButton direction='left' elementId='bidReportTable'></ScrollButton>
                <div className='table-responsive' id='bidReportTable'>
                  <Table className='tableHData' data-testid='activities.bids.reports.maintable.div'>
                    <thead>
                      <tr data-testid='activities.bids.reports.maintable.head'>
                        <th style={style.tdWidth}>QuoteID</th>
                        <th style={style.tdWidth1}>Quote Number</th>
                        <th style={style.tdWidth2}>Quote Name</th>
                        <th style={style.tdWidth1}>Buyer Account Initials</th>
                        <th style={style.tdWidth1}>Broadcast Date</th>
                        <th style={style.tdWidth1}>Due Date</th>
                        <th style={style.tdWidth}>Status</th>
                        <th style={style.tdWidth2}>Number of Suppliers Broadcast To</th>
                        <th style={style.tdWidth2}>Supplier with Certifications</th>
                        <th style={style.tdWidth2}>Number of Submitted Quotes</th>
                        <th style={style.tdWidth2}>Number of Line Items</th>
                        <th style={style.tdWidth2}>Total Awarded Amount</th>
                      </tr>
                    </thead>
                    <tbody data-testid='activities.bids.reports.main.table.body.list'>
                      {quoteReports_list && quoteReports_list.length > 0 ? (
                        <>
                          {quoteReports_list
                            .map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{item.quoteId}</td>
                                <td>{item.quoteNo}</td>
                                <td>{item.quoteTitle}</td>
                                <td>{item.buyerAccountInitials}</td>
                                <td>
                                  {item.status !== 'Incomplete' && item.broadcastDate
                                    ? moment(item.broadcastDate).format(usaDateFormat)
                                    : ''}
                                </td>
                                <td>
                                  {item.dueDate ? moment(item.dueDate).format(usaDateFormat) : ''}
                                </td>
                                <td>{item.status}</td>
                                <td>{item.numberofBroadcasts}</td>
                                <td>{item.noOfSuppliersPossessedCertificate}</td>
                                <td>{item.numberOfResponses}</td>
                                <td>{item.numberOfLineItems}</td>
                                <td>{item.awardAmount ? `$${item.awardAmount}` : ''}</td>
                              </tr>
                            ))
                            .slice(indexOfFirstListsQuote, indexOfLastListsQuote)}
                        </>
                      ) : (
                        nodata_component
                      )}
                    </tbody>
                  </Table>
                </div>
                <ScrollButton direction='right' elementId='bidReportTable'></ScrollButton>
              </div>
              <Paging
                totalRecords={quoteReports_list.length}
                currentPage={quoteCurrentPage}
                onPagingClick={(value: number) => {
                  if (value !== quoteCurrentPage) setQuoteCurrentPage(Number(value));
                }}
                pageLimit={quoteReportsActivityPaginations?.value}
              />
              <div className='row mt-2'>
                <div className='col'>
                  <div className='d-flex justify-content-end align-items-center'>
                    Display{' '}
                    <SelectBox
                      reactselect={true}
                      label='Rows'
                      name='quoteReportsActivityPaginations'
                      options={quoteReportsActivityPaginationsOptions}
                      handleSelect={onChangeSelectBox}
                      value={quoteReportsActivityPaginations}
                      parentClass='clearfix small mx-2'
                      disabled={quoteReports_list.length === 0 ? 'true' : 'false'}
                    />
                    Results
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row mb-3 countsWrapper'>
            <div
              className='col text-center'
              data-testid='activities.bids.reports.table.head.upcoming'
            >
              <h2>{finalupcomingBids}</h2>
              <p>Upcoming</p>
            </div>
            <div
              className='col text-center'
              data-testid='activities.bids.reports.table.head.active'
            >
              <h2>{finalactiveBids}</h2>
              <p>Active</p>
            </div>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.under'>
              <h2>{finalunderEvaluationBids}</h2>
              <p>Under Evaluation</p>
            </div>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.award'>
              <h2>{finalawardedBids}</h2>
              <p>Awarded</p>
            </div>
            <div className='col text-center' data-testid='activities.bids.reports.table.head.total'>
              <h2>{finaltotalBids}</h2>
              <p>Total Bids Broadcast in this Period</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='d-block clearfix mb-4' />
              <div className='bidReportScrollContainer'>
                <ScrollButton direction='left' elementId='bidReportTable'></ScrollButton>
                <div className='table-responsive' id='bidReportTable'>
                  <Table
                    className='tableHData stickTop'
                    data-testid='activities.bids.reports.maintable.div'
                  >
                    <thead>
                      <tr data-testid='activities.bids.reports.maintable.head'>
                        <th style={style.tdWidth}>BidID</th>
                        <th style={style.tdWidth1}>Bid Identifier</th>
                        <th style={style.tdWidth2}>Bid Name</th>
                        <th style={style.tdWidth1}>Buyer Account Initials</th>
                        <th style={style.tdWidth1}>Broadcast Date</th>
                        <th style={style.tdWidth1}>Due Date</th>
                        <th style={style.tdWidth}>Status</th>
                        <th style={style.tdWidth2}>Number of Suppliers Broadcast To</th>
                        <th style={style.tdWidth2}>Supplier with Certifications</th>
                        <th style={style.tdWidth}>Number of Planholders</th>
                        <th style={style.tdWidth2}>Number of Submitted Responses</th>
                        <th style={style.tdWidth}>Estimated Budget</th>
                        <th style={style.tdWidth}>Award</th>
                        <th style={style.tdWidth}>Awardee</th>
                      </tr>
                    </thead>
                    <tbody data-testid='activities.bids.reports.main.table.body.list'>
                      {bidReports_list && bidReports_list.length > 0 ? (
                        <>
                          {bidReports_list
                            .map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{item.bidId}</td>
                                <td>{item.bidName}</td>
                                <td>{item.bidIdentifier}</td>
                                <td>{item.agency}</td>
                                <td>
                                  {item.broadCastDate
                                    ? moment(item.broadCastDate).format(usaDateFormat)
                                    : ''}
                                </td>
                                <td>
                                  {item.dueDate ? moment(item.dueDate).format(usaDateFormat) : ''}
                                </td>
                                <td>{item.status}</td>
                                <td>{item.numberofBroadcasts}</td>
                                <td>{item.noOfSuppliersPossessedCertificate}</td>
                                <td>{item.numberofPlanholders}</td>
                                <td>{item.numberofResponses}</td>
                                <td>{item.estimatedBudget ? item.estimatedBudget : ''}</td>
                                <td>{item.awards}</td>
                                <td>{item.awardee}</td>
                              </tr>
                            ))
                            .slice(indexOfFirstListBid, indexOfLastListsBid)}
                        </>
                      ) : (
                        nodata_component
                      )}
                    </tbody>
                  </Table>
                </div>
                <ScrollButton direction='right' elementId='bidReportTable'></ScrollButton>
              </div>
              <Paging
                totalRecords={bidReports_list.length}
                currentPage={bidCurrentPage}
                onPagingClick={(value: number) => {
                  if (value !== bidCurrentPage) setBidCurrentPage(Number(value));
                }}
                pageLimit={bidReportsActivityPaginations?.value}
              />
              <div className='row mt-2'>
                <div className='col'>
                  <div className='d-flex justify-content-end align-items-center'>
                    Display{' '}
                    <SelectBox
                      reactselect={true}
                      label='Rows'
                      name='bidReportsActivityPaginations'
                      options={bidReportsActivityPaginationsOptions}
                      handleSelect={onChangeSelectBox}
                      value={bidReportsActivityPaginations}
                      parentClass='clearfix small mx-2'
                      disabled={bidReports_list.length === 0 ? 'true' : 'false'}
                    />
                    Results
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(BidQuoteActivityReports);
