import React, { memo } from 'react';
import { nargsfnTypes } from '../../../types/functions';
import { BidExternalStatusType } from '../../../utils/constants';
// from Notifications stored procedures in both Content and UserDBs
type NotificationType = 'Account' | 'Bid' | 'Quote' | 'BidCloseInWeek' | 'QuoteCloseInWeek';

type NotificationClass = 'purple' | 'bidBG' | 'quoteBG' | 'defaultBG';

export interface Notification {
  type: NotificationType;
  description: string;
  id: number | null | undefined;
  notificationId: number;
  title: string;
  status: string;
}

interface PropsTypes {
  notification?: Notification;
  submitDisablenotification: any;
  history: any;
  setSharedDetails: nargsfnTypes;
}

function getClass(tpe: NotificationType): NotificationClass {
  switch (tpe) {
    case 'Account':
      return 'purple';
    case 'Bid':
      return 'bidBG';
    case 'Quote':
      return 'quoteBG';
    default:
      return 'defaultBG';
  }
}

export function getLink(notification: Notification): string {
  let { notificationId: nid } = notification;
  const { id } = notification;
  nid = Number(nid);
  if (nid === 1 || nid === 2) {
    return '/suppliers/account/subscriptions';
  } else if (nid === 3 || nid === 6) {
    return `/suppliers/bids/${id}/details`;
  } else if (nid === 4 || nid === 7) {
    return `/suppliers/quotes/${id}`;
  } else if (nid === 5) {
    return '/suppliers/account/commoditycodes/edit';
  } else {
    return '';
  }
}

function Notifications(props: PropsTypes) {
  if (!props.notification) return null;
  const { notification, submitDisablenotification, history, setSharedDetails } = props;
  const { title, type: tpe, description, notificationId, id, status } = notification;
  const statusClass = getClass(tpe);
  const link = getLink(notification);
  const classNames = ['notificationList'];
  if (link) {
    classNames.push('staticLink');
  }

  const notificationClickFn = () => {
    if (!link) return;
    if (Number(notificationId) === 5) {
      setSharedDetails({ pathfromdashboard: true });
    }
    history.push(link);
  };

  return (
    <div className={classNames.join(' ')} onClick={notificationClickFn}>
      <span className={`tag ml-0 text ${statusClass}`}>{tpe}</span>
      {status === BidExternalStatusType.Awarded && (
        <span className='tag ml-0 text awardedbg'>Awarded</span>
      )}
      <i
        className='mdi mdi-close'
        title='Close'
        onClick={() => submitDisablenotification({ notificationId, id })}
      />
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  );
}

export default memo(Notifications);
