import styled from 'styled-components';

import {
  FlexContainerColumn,
  FontWeight,
  Margin,
  Padding,
  ParagraphFontSize,
} from '@demandstar/components/styles';
import { BaseLinkSpan } from '@demandstar/components/link/DSLinkStyles';

import { Colors } from 'src/shared/styles';

export const CartWrapper = styled.div<{
  isProrated?: boolean;
}>`
  background: ${Colors.grayLight};
  left: -3rem;
  margin: 2rem -3rem 2rem 0;
  padding: 2rem 2rem 2rem 5rem;
  position: relative;
   @media screen and (max-width: 600px) {
     margin: 2rem -6rem 2rem 0;
     padding: 1rem 1rem 2rem 1rem;
}     

  > span {
    padding: ${Padding.Extra} 0;
  }

  > h6 {
    display: flex;
    justify-content: space-between;
    padding-top: ${Padding.Base};
    margin-bottom: ${Margin.Base};

    ${({ isProrated }) => {
      return isProrated
        ? `
      > span {
        margin-right: 2rem;
      }`
        : `
      > span {
        max-width: 8rem;
        text-align: left;
        width: 29%;
      }`;
    }}
  }

  // TODO: remove classes from DSAlert
  & .container {
    padding: 0;
  }

  & .reg-alert {
    margin: ${Margin.Less} 0;
    & .alert-iconbox {
      padding: 0;
    }
  }

  > ${FlexContainerColumn} {
    row-gap: ${Margin.Less};
  }
`;

// TODO: Create a basic @demandstar/components UnstyledList component
export const CartItemList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
`;

export const CartItemColumn = styled.p<{
  isBold?: boolean;
  isProrated?: boolean;
}>`
  flex-grow: 3;
  font-weight: ${({ isBold }) => (isBold ? `${FontWeight.Bold}` : `${FontWeight.Base}`)};
  text-align: left;
  width: 30%;
  margin-bottom: 0;

  :last-child {
    font-weight: ${FontWeight.Bold};
    max-width: ${({ isProrated }) => (isProrated ? '11rem' : '8rem')};
    width: 30%;
  }

  & svg {
    float: right;
    margin: ${Margin.Less} 0 0 ${Margin.Less};
  }

  ${BaseLinkSpan} {
    font-size: ${ParagraphFontSize.Large};
    font-weight: ${FontWeight.Base};
    text-decoration: underline;
  }
`;

export const CartItemWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top:1rem;

  & p {
    padding-top: 0;
    margin-bottom: 0;
  }
`;
