import { useCallback, useEffect, useMemo } from 'react';

import {
  DSSearch,
  SearchFilter,
  SearchFilters,
  SearchInputType,
  SearchInputWidth,
  SearchProps,
} from '@demandstar/components/search';
import { getBasicStringUrlHandler } from '@demandstar/components/utils';

import {
  ContractDocument,
  ContractDocumentStatus,
  ContractSearchResult,
} from '../../contract-management.d';
import { cmFormLabels } from '../../ContractManagement.texts';
import { ContractDocumentsTable } from './ContractDocumentsTable';
import { useContractDetails } from '../../useContractDetails';
import { useContractDocuments } from './useContractDocuments';
import { useContractSearch } from '../../search';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';

type SearchFilterContractDocument = Pick<ContractDocument, 'status' | 'type' | 'source'> & {
  searchText: string;
  contract: ContractSearchResult;
};

type DocumentFilterSearchProps = Omit<
  SearchProps<SearchFilterContractDocument, ContractDocument>,
  'filters' | 'recordKey' | 'renderResult' | 'renderResultSet' | 'search'
>;

export const DocumentFilterSearchForm = (props: DocumentFilterSearchProps) => {
  const { allContractsLoadable, allSuppliersLoadable } = useContractSearch();
  const { contractDetailsLoadable } = useContractDetails();
  const { contractId } = useIdFromRoute();
  const { allContractDocumentsLoadable, contractDocumentsLoadable, contractDocumentTypes } =
    useContractDocuments();

  const loadableData = useMemo(() => {
    return (contractId ? contractDocumentsLoadable : allContractDocumentsLoadable) ?? [];
  }, [allContractDocumentsLoadable, contractDocumentsLoadable, contractId]) as any;

  const sourceOptions = useMemo(() => {
    return contractId
      ? contractDetailsLoadable.map(
          cd => cd?.awardeeContacts?.map(s => ({ label: s.name, value: s.name })) ?? [],
        )
      : allSuppliersLoadable.map(suppliers =>
          suppliers.map(s => ({ label: s.name, value: s.name })),
        );
  }, [allSuppliersLoadable, contractDetailsLoadable, contractId]);

  const typeOptions = useMemo(
    () => contractDocumentTypes?.map(docType => docType.title) ?? [],
    [contractDocumentTypes],
  );

  const statusOptions = Object.keys(ContractDocumentStatus);

  const filters = useMemo(() => {
    const contractDocumentTextSearch: SearchFilter<string, SearchFilterContractDocument> = {
      getDisplayValue: (value?: string | undefined) => value,
      inputType: SearchInputType.Text,
      label: 'Search',
      name: 'searchText',
      urlHandling: getBasicStringUrlHandler('q'),
      width: SearchInputWidth.Full,
    };

    const sourceFilter: SearchFilter<string, SearchFilterContractDocument> = {
      getDisplayValue: value => value,
      inputType: SearchInputType.Select,
      label: cmFormLabels.FilterSource,
      name: 'source',
      options: sourceOptions,
      urlHandling: getBasicStringUrlHandler('source'),
      width: SearchInputWidth.Half,
    };

    const contractFilter: SearchFilter<ContractSearchResult, SearchFilterContractDocument> = {
      defaultValue: allContractsLoadable?.valueMaybe()?.find(x => x.id === contractId),
      getDisplayValue: value => value?.name,
      inputType: SearchInputType.Select,
      label: cmFormLabels.FilterContract,
      name: 'contract',
      optionLabel: 'name',
      options: allContractsLoadable,
      urlHandling: {
        key: 'contract',
        getValue: (id: string | null) => allContractsLoadable?.valueMaybe()?.find(x => x.id === id),
        setValue: (contract?: ContractSearchResult | null) => contract?.id,
      },
      readOnly: !!contractId,
      width: SearchInputWidth.Half,
    };

    const documentTypeFilter: SearchFilter<string, SearchFilterContractDocument> = {
      getDisplayValue: value => value,
      inputType: SearchInputType.Select,
      label: cmFormLabels.FilterDocType,
      name: 'type',
      options: typeOptions.map((con, index) => ({ label: con || '', value: con || '' })),
      urlHandling: getBasicStringUrlHandler('type'),
      width: SearchInputWidth.Half,
    };

    const statusFilter: SearchFilter<string, SearchFilterContractDocument> = {
      getDisplayValue: value => value,
      inputType: SearchInputType.Select,
      label: cmFormLabels.FilterStatus,
      name: 'status',
      options: statusOptions.map(con => ({ label: con, value: con })),
      urlHandling: getBasicStringUrlHandler('status'),
      width: SearchInputWidth.Half,
    };

    return [
      contractDocumentTextSearch,
      sourceFilter,
      contractFilter,
      documentTypeFilter,
      statusFilter,
    ] as SearchFilters<SearchFilterContractDocument>;
  }, [contractId, allContractsLoadable, sourceOptions, statusOptions, typeOptions]);

  const search = useCallback(
    async ({
      searchText,
      source,
      contract,
      type,
      status,
    }: Partial<SearchFilterContractDocument>) => {
      const data = (await loadableData.toPromise()) || [] as any;

      const filterByText = (contractDoc: ContractDocument) => {
        if (!searchText) {
          return true;
        }

        // check from shortest to longest field in hopes of overloading
        return (
          // contractDoc.type?.title?.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
          contractDoc.type?.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
          contractDoc.status.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
          contractDoc.source.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
          contractDoc.contract.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
          (contractDoc.title &&
            contractDoc.title.toLocaleLowerCase().includes(searchText.toLowerCase()))
        );
      };

      return data.filter(
        contractDoc =>
          filterByText(contractDoc) &&
          (!source || contractDoc.source.toLocaleLowerCase().includes(source.toLowerCase())) &&
          (!contract || contractDoc.contractId === contract?.id) &&
          (!type ||
            // contractDoc.type?.title?.toLocaleLowerCase().includes(type?.title?.toLowerCase())) &&
            contractDoc.type?.toLocaleLowerCase().includes(type?.toLowerCase())) &&
          (!status || contractDoc.status.toLocaleLowerCase().includes(status.toLowerCase())),
      );
    },
    [loadableData],
  );

  const searchProps: SearchProps<SearchFilterContractDocument, ContractDocument> = {
    filters: filters,
    recordKey: 'id',
    renderResultSet: ContractDocumentsTable,
    search,
    ...props,
  };

  return <DSSearch {...searchProps} />;
};
