import { PropsWithChildren, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { BaseFieldContainer, FormFieldError, FormFieldMessage } from './FieldContainer.styles';
import { DataTestId } from '../types';
import { DSLink } from '../link';
import { getErrorMessage } from '../utils';
import { Helper } from '../styles';
import { Label } from '../label/Label';

export type FieldContainerProps = DataTestId &
  PropsWithChildren<{
    /** Required name for all fields and inputs */
    name: string;
    /** RHF error with type and message */
    error?: FieldError;
    /** optional object to make the error clickable. */
    errorClick?: Record<string, () => void>;
    /** optional boolean to shorten width to 400px */
    fixedWidth?: boolean;
    /** HTML id */
    id?: string;
    /** optional boolean to make display `inline-block` */
    inline?: boolean;
    /** Label displayed above the field or input
     * @todo: separate this value from default `marginBottom` value? */
    label?: string;
    /** Optional {boolean} to remove bottom margin when `false`
     * @default: true - to be set to `false`, optionally, when not in a traditional vertical form */
    marginBottom?: boolean;
    /** Optional message displayed below the field or input */
    message?: string;
    /** determines if the field is optional  */
    optional?: boolean;
  }>;

export type FieldProps = Omit<FieldContainerProps, 'children' | 'error'>;

/** function to pass on FieldContainerProps to FieldContainer */
export function getFieldContainerProps(props: FieldProps, error?: FieldError): FieldContainerProps {
  const {
    dataTestId,
    errorClick,
    fixedWidth,
    id,
    inline,
    label,
    marginBottom = true, // default applied to prevent value changes to existing usages, where this field is determined only by the presence/absence of a `label` value.
    message,
    name,
    optional,
  } = props;
  return {
    dataTestId,
    error,
    errorClick,
    fixedWidth,
    inline,
    id,
    label,
    marginBottom,
    message,
    name,
    optional,
  };
}

export const FieldContainer = (props: FieldContainerProps) => {
  const {
    children,
    dataTestId,
    error,
    errorClick,
    fixedWidth,
    label,
    marginBottom,
    message,
    name,
    optional,
  } = props;

  const errorMsg = getErrorMessage(error, label);
  const errorNode = useMemo(() => {
    return error?.type && errorClick && errorClick[error?.type] ? (
      <DSLink onClick={errorClick[error?.type]} type='error'>
        {errorMsg}
      </DSLink>
    ) : (
      errorMsg
    );
  }, [error?.type, errorClick, errorMsg]);

  const id = props.id || name;

  return (
    <BaseFieldContainer
      dataTestId={`${dataTestId}-container`}
      error={error}
      marginBottom={!!label && marginBottom}
      fixedWidth={fixedWidth}
    >
      {label && (
        <Label htmlFor={id} dataTestId={dataTestId && `${dataTestId}-label`}>
          {label} {optional && <Helper>(Optional)</Helper>}
        </Label>
      )}
      {message && (
        <FormFieldMessage dataTestId={`${dataTestId}-message`}>{message}</FormFieldMessage>
      )}
      {children}
      {errorMsg && (
        <FormFieldError dataTestId={`${dataTestId}-error`} id={`${id}-error`}>
          {errorNode}
        </FormFieldError>
      )}
    </BaseFieldContainer>
  );
};
