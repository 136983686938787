import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import { DataTestId, Id, SVGIcon, Title } from '../types';
import { DSButton, DSButtonProps, DSButtonType } from './';

type BaseButtonProps = DSButtonProps &
  DataTestId &
  SVGIcon &
  Title &
  Id & {
    children?: string;
    name?: string;
    type?: DSButtonType;
  };

type HrefButtonProps = BaseButtonProps & {
  href: string;
  newTab?: boolean;
  to?: never;
};

type ToButtonProps = BaseButtonProps & {
  href?: never;
  newTab?: never;
  to: string;
};

export type DSLinkButtonProps = HrefButtonProps | ToButtonProps;

export function DSLinkButton(props: DSLinkButtonProps): JSX.Element {
  const {
    type = 'primary',
    children,
    dataTestId,
    href,
    icon,
    id,
    inactive,
    name,
    newTab,
    title = '',
    to,
  } = props;

  const button = useMemo(
    () => (
      <DSButton
        type={type}
        dataTestId={dataTestId}
        icon={icon}
        id={id}
        inactive={inactive}
        name={name}
        title={title}
      >
        {children}
      </DSButton>
    ),
    [children, dataTestId, icon, id, inactive, name, title, type],
  );

  const target = newTab ? '_blank' : '_self';

  const renderButtonWrapper = useMemo(() => {
    if (inactive) {
      return button;
    }
    if (href) {
      return (
        <a href={href} title={children} target={target}>
          {button}
        </a>
      );
    }
    if (to) {
      return <Link to={to}>{button}</Link>;
    }
    return button;
  }, [button, children, href, inactive, target, to]);

  return renderButtonWrapper;
}
