import { useCallback, useEffect, useMemo, useState } from 'react';

import { BaseSVG } from '@demandstar/components/icon';

import { limitedPaths } from 'src/shared/constants';
import { mapDataUS } from './MapItem.constants';
import { MapItem } from '.';
import { MapItemPath } from './MapItemPath';
import { useBrowseBids } from '../useBrowseBids';
import { useRouter } from 'src/shared/hooks';

export function MapUSFull() {
  const {
    buildGeoLinkURI,
    geoCodes = [],
    geoCodesMap,
    parseGeoCodesMap,
    urlSelectedSEOIDs,
  } = useBrowseBids();
  const { history } = useRouter();
  const { state } = urlSelectedSEOIDs;
  const [validStates, setValidStates] = useState<string[]>([]);
  const [mapTitle, setMapTitle] = useState<string>('');

  useEffect(() => {
    if (geoCodes.length) {
      const seoStates = geoCodes.map(({ link }) => link);
      setValidStates(seoStates);
      if (!geoCodesMap?.length) parseGeoCodesMap();
    }
  }, [geoCodes, geoCodesMap?.length, parseGeoCodesMap]);

  const handlePathClick = useCallback(
    (seoId: string) => () => {
      if (seoId) {
        const path = buildGeoLinkURI(limitedPaths.browseBids, seoId, '');
        history.push(path);
      }
    },
    [buildGeoLinkURI, history],
  );

  const isSelected = useCallback(
    (id: string) => {
      return state === id;
    },
    [state],
  );

  /**
   * Set hover title based upon SVG path mouseOver event.
   */
  const handleItemMouseOver = useCallback(
    (seoId: string) => () => {
      const title = geoCodesMap[seoId] ? `Browse awarded bids in ${geoCodesMap[seoId].label}` : '';
      setMapTitle(title);
    },
    [geoCodesMap],
  );

  const renderItem = useCallback(
    ({ seoId, pathData }: MapItem, index: number) => {
      const inactive = !validStates.includes(seoId);
      return (
        <MapItemPath
          dataTestId={`map-item-path-${seoId}-${index}`}
          inactive={inactive}
          key={`${seoId}-${index}`}
          onClick={handlePathClick(seoId)}
          onMouseOver={handleItemMouseOver(seoId)}
          pathData={pathData}
          selected={isSelected(seoId)}
          seoId={seoId}
        />
      );
    },
    [handleItemMouseOver, handlePathClick, isSelected, validStates],
  );

  const mapItemPaths = useMemo(() => {
    return mapDataUS.mapItems?.map(renderItem);
  }, [renderItem]);

  return (
    <BaseSVG
      color='none'
      height='350'
      viewBox='0 0 129.71 75.22'
      width='550'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{mapTitle}</title>
      {mapItemPaths}
    </BaseSVG>
  );
}
