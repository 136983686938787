import { Loadable } from 'recoil';

/**
 * Get the contents from a Recoil Loadable object, if the `Loadable.state` is `hasValue`,
 * otherwise returns null.
 * @warning it's recommended to pass in a fallback as well.
 * @example getLoadableContents(loadable) // T | null
 * @example getLoadableContents(loadable, []) // T
 */
export function getLoadableContents<T>(loadable: Loadable<T>): T | null;
/**
 * Get the contents from a Recoil Loadable object, if the `Loadable.state` is `hasValue`,
 * otherwise returns the fallback.
 * @example getLoadableContents(loadable, []) // T
 */
export function getLoadableContents<T>(loadable: Loadable<T>, fallback: T): T;
export function getLoadableContents<T>(loadable: Loadable<T>, fallback?: T): T | null {
  return loadable.state === 'hasValue' ? loadable.contents : fallback || null;
}
