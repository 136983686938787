/**
 * @description function wrapper to easily trigger an function when the user clicks Enter while focused.
 * @param func the function you want to fire onEnter
 * @returns function that triggers only on Enter keydown
 * @example
 * <InvisibleInput
    onChange={onChange}
    onKeyDown={onEnter(onChange)} />
 */
export function onEnter(func: () => void) {
  return function (event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Enter') {
      func();
    }
  };
}
