import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { displayDate } from '../../utils/helpers';
import { MemberType } from '../../utils/constants';
import { quoteItemsParamType } from '../../types/quotesdetails';

const ListItem = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <li className='list-inline-item'>
      <i>{label}:</i> {value}
    </li>
  );
};

interface PropsTypes {
  memberType: string;
  item: quoteItemsParamType;
}

function QuoteList(props: PropsTypes) {
  const { memberType, item } = props;

  const {
    quoteId,
    quoteNumber,
    quoteName,
    dueDate,
    quoteYear,
    status,
    statusTheme,
    showMinimum,
    agency,
    broadcastDate = '',
  } = item;

  return (
    <>
      <div className='listGroupWrapper clearfix '>
        <h5
          className={showMinimum ? 'mw-100 text-truncate' : 'mw-100 text-truncate'}
          title={quoteName}
        >
          <Link
            className={showMinimum ? 'w-95 text-truncate' : 'w-75 text-truncate float-left'}
            to={`/${
              memberType === MemberType.AgencyBuyer
                ? 'buyers/quotes/' + quoteId + '/details'
                : 'suppliers/quotes/' + quoteId
            }`}
          >
            {quoteName}
          </Link>
          {showMinimum ? null : (
            <span className={statusTheme && statusTheme === 'RED' ? 'draft' : ''}>{status}</span>
          )}
        </h5>
        <p>{agency}</p>
        {showMinimum ? null : (
          <ul className='list-inline'>
            {showMinimum ? null : <ListItem label='ID' value={quoteNumber} />}
            {showMinimum ? null : <ListItem label='Year' value={quoteYear} />}
            {showMinimum ? null : (
              <ListItem
                label='Broadcast'
                value={broadcastDate ? displayDate(broadcastDate) : ' - '}
              />
            )}
            {showMinimum ? null : <ListItem label='Due' value={displayDate(dueDate)} />}
          </ul>
        )}
      </div>
    </>
  );
}

export default memo(QuoteList);
