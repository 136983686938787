import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import {
  allProductsState,
  cartRenewalState,
  cartTotalState,
  selectedProductsState,
} from 'src/store/recoil/productState';
import {
  calculateMaxCartDiscount,
  calculateProratedProductPrices,
} from 'src/utils/helpers/proration';
import {
  CartProduct,
  NationalProductId,
  NationalProductPrice,
  ProductApiResponse,
  ProductType,
} from 'src/types/products';

import { alertPercentage } from 'src/utils/constants';
import { CartAlertType } from 'src/types/registration';
import { compareObjectsByKey } from 'src/utils';
import { removeDuplicates } from 'src/utils/helpers';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { UserType } from 'src/types/accountinfo';
import { useSelectedProducts } from 'src/shared/hooks/useSelectedProducts';

/**
 * @description serves as a wrapper for Cart functionality and values
 * @example const { cartItems, discountedCartTotal, recurringCartTotal } = useCart();
 */
export function useCart() {
  const allProducts = useRecoilValueLoadable<ProductApiResponse[]>(allProductsState);
  const cartRenewal = useRecoilValue(cartRenewalState);
  const cartTotal = useRecoilValue<number>(cartTotalState);
  const { selectedProducts, subscribedProducts } = useSelectedProducts();

  const {
    accountInfo: { expiryDate: acctExpiryDate, userType },
  } = useAccountInfo();

  /** Memoized boolean indicating whether user has an active subscription */
  const hasActiveSubscription = useMemo(() => {
    return (
      acctExpiryDate >= dayjs() &&
      subscribedProducts?.length > 0 &&
      userType === UserType.PaidSupplier
    );
  }, [acctExpiryDate, subscribedProducts?.length, userType]);

  /** Memoized expiration date */
  const expiryDate = useMemo(() => {
    return !hasActiveSubscription
      ? dayjs().add(1, 'year')
      : !cartRenewal
      ? acctExpiryDate
      : acctExpiryDate.add(1, 'year');
  }, [acctExpiryDate, cartRenewal, hasActiveSubscription]);

  /** Memoized national product retrieved from loadable state */
  const nationalProduct: ProductApiResponse | undefined = useMemo(() => {
    if (allProducts.state === 'hasValue') {
      return allProducts.contents.find(x => x.productId === NationalProductId.UnitedStates);
    }
    return undefined;
  }, [allProducts.contents, allProducts.state]);

  /** Memoized boolean indicating a new product is selected */
  const showCart = useMemo(() => {
    return cartRenewal ||
      selectedProducts.county?.length ||
      selectedProducts.state?.length ||
      selectedProducts?.national
      ? true
      : false;
  }, [cartRenewal, selectedProducts]);

  /**
   * Memoized conversion of selected products to a filtered list of items in the cart (based on product hierarchy)
   * Prorated prices for each product are also calculated here
   */
  const cartItems: CartProduct[] = useMemo(() => {
    let items = [] as ProductApiResponse[];

    if (cartRenewal)
      return subscribedProducts.map(p => {
        return {
          ...p,
          calculatedPrice: p.price,
        };
      });

    if (selectedProducts.national) {
      items = nationalProduct ? [nationalProduct] : [];
    }

    if (!selectedProducts.national && selectedProducts.state?.length) {
      items = [...selectedProducts.state].sort(compareObjectsByKey('productName'));
    }

    if (!selectedProducts.national && selectedProducts.county?.length) {
      const counties = selectedProducts.county
        .filter(c => {
          return c.parentId && !items.find(i => i.productId === c.parentId);
        })
        .sort(compareObjectsByKey('productName'));
      items = items.concat(counties);
    }

    return !hasActiveSubscription
      ? items.map(p => {
          return {
            ...p,
            calculatedPrice: p.price,
          };
        })
      : calculateProratedProductPrices(items, subscribedProducts, expiryDate);
  }, [
    cartRenewal,
    subscribedProducts,
    expiryDate,
    hasActiveSubscription,
    nationalProduct,
    selectedProducts.county,
    selectedProducts.national,
    selectedProducts.state,
  ]);

  /** A list of products to which the user is subscribed & which remain unchanged by their additions */
  const unchangedSubscribedProducts = useMemo(() => {
    return subscribedProducts.filter(product => {
      if (cartItems.filter(p => p.productType === ProductType.National)?.length) return false;

      if (
        [ProductType.County, ProductType.FreeAgency].includes(product.productType) &&
        product.parentId
      ) {
        return cartItems.filter(p => p.productId === product.parentId)?.length ? false : true;
      }

      return true;
    });
  }, [cartItems, subscribedProducts]);

  /**
   * The maximum amount of discount to be applied to the cart based on "removed" products for which the member has
   * previously paid
   */
  const maximumCartDiscount = useMemo(() => {
    return calculateMaxCartDiscount(
      [...cartItems, ...unchangedSubscribedProducts],
      subscribedProducts,
      expiryDate,
    );
  }, [cartItems, subscribedProducts, expiryDate, unchangedSubscribedProducts]);

  /** The cart total less the maximum discount amount, limited at being >= 0 */
  const discountedCartTotal = useMemo(() => {
    if (maximumCartDiscount >= cartTotal) return 0;

    return Math.round((cartTotal - maximumCartDiscount) * 100) / 100.0;
  }, [cartTotal, maximumCartDiscount]);

  /** The projected subscription amount at the time of renewal */
  const recurringTotal = useMemo(() => {
    const unchangedSubscribedProductSum = unchangedSubscribedProducts
      .map(p => p.price)
      .reduce((last, curr) => last + curr, 0);

    const unChangedProductIds = unchangedSubscribedProducts.map(p => p.productId);

    const newCartItem = cartItems.filter(p => !unChangedProductIds.includes(p.productId));
    const cartSum = newCartItem.map(p => p.price).reduce((last, curr) => last + curr, 0);

    return unchangedSubscribedProductSum + cartSum;
  }, [cartItems, unchangedSubscribedProducts]);

  /** The distinct list of State Products for which there are selected County Products in the cart */
  const stateUpgrades = useMemo(() => {
    if (selectedProducts.county && allProducts.state === 'hasValue') {
      // Get all unique states that are parents of selected counties
      const upgrades: ProductApiResponse[] = removeDuplicates(
        selectedProducts.county
          .filter(c => c.parentId)
          .map(c => {
            const state = allProducts.contents.filter(s => c.parentId === s.productId)[0];
            return state;
          })
          .filter(s => s),
        'productId',
      );

      // Only return states for which the selected counties pass the threshold for an alert
      return upgrades.filter(({ productId, price }) => {
        const selectedCountyPrice =
          selectedProducts.county
            ?.filter(county => county.parentId === productId)
            .map(county => county.price)
            .reduce((last, current) => last + current, 0) ?? 0;

        const alreadySubscribedCountyPrice =
          unchangedSubscribedProducts
            .filter(p => p.parentId === productId && p.productType === ProductType.County)
            .map(county => county.price)
            .reduce((last, current) => last + current, 0) ?? 0;

        return (
          ((selectedCountyPrice + alreadySubscribedCountyPrice) / (Number(price) || 1)) * 100 >=
          alertPercentage
        );
      });
    }

    return [];
  }, [
    allProducts.contents,
    allProducts.state,
    selectedProducts.county,
    unchangedSubscribedProducts,
  ]);

  /** The type of `CartAlert` to display, if any */
  const alertType = useMemo(() => {
    if (cartRenewal) return CartAlertType.None;

    if (selectedProducts?.national) {
      if (selectedProducts.county?.length || selectedProducts.state?.length)
        return CartAlertType.National;
      else return CartAlertType.None;
    }

    if ((recurringTotal / NationalProductPrice.UnitedStates) * 100 >= alertPercentage) {
      return CartAlertType.NationalUpgrade;
    }

    if (stateUpgrades?.length) {
      return CartAlertType.StateUpgrade;
    }

    return CartAlertType.None;
  }, [
    cartRenewal,
    recurringTotal,
    selectedProducts.county?.length,
    selectedProducts?.national,
    selectedProducts.state?.length,
    stateUpgrades?.length,
  ]);

  /** clears selected products & set cart renewal state as true */
  const setCartRenewalOn = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cartRenewalState, true);
        set(selectedProductsState, {
          county: [],
          state: [],
          national: NationalProductId.None,
        });
      },
    [],
  );

  /**
   * @description updates the cart total based on the selected products
   * @returns void
   * @example refreshCartTotal()
   */
  const refreshCartTotal = useRecoilCallback(
    ({ set }) =>
      async () => {
        let proratedTotal = 0;

        if (cartItems?.length) {
          proratedTotal = cartItems
            .map(ci => ci.calculatedPrice)
            .filter(ci => ci)
            .reduce((sum, number) => {
              return (sum ?? 0) + (number ?? 0);
            }, 0);
        }

        if (proratedTotal !== cartTotal) {
          set(cartTotalState, Math.round(proratedTotal * 100) / 100.0);
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems],
  );

  return {
    /** Indicates what type of `CartAlert` should be displayed */
    alertType,
    /** A filtered list of products that should appear in the cart */
    cartItems,
    /** Whether the cart represents a renewal of current products */
    cartRenewal,
    /** The total cost of all products in the cart */
    cartTotal,
    /** The discounted total of the cart (cartTotal - maximumCartDiscount), guaranteed >= 0 */
    discountedCartTotal,
    /** The calculated expiration day for new products
     * From account info if there are active subscriptions or 1 year from today
     */
    expiryDate,
    /** The maximum amount the cart total can be reduced due to "removed" child products */
    maximumCartDiscount,
    /** The total annual renewal fee for newly selected products and subscribed products, accounting for
     * newly added parent products & national subscriptions
     */
    recurringTotal,
    /** Re-calculates the cart total based on selected products */
    refreshCartTotal,
    /** clears selected products & sets cart renewal state as true */
    setCartRenewalOn,
    /** Contains a boolean indicating if the cart should be shown */
    showCart,
    /** Contains a list of state products that meet the conditions for cart upsell */
    stateUpgrades,
    /** Contains a list of subscribed products that are not impacted by items in cart */
    unchangedSubscribedProducts,
  };
}
