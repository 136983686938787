import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useState } from 'react';

import {
  DSCheckField,
  DSDateField,
  DSTextAreaField,
  DSTextField,
} from '@demandstar/components/fields';
import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DataTestId } from '@demandstar/components/types';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';

import * as texts from './AddReminder.texts';
import { getEmailArray, validateEmailArray } from './AddReminder.utils';
import { ContractReminder } from './hook/reminders';
import { useReminders } from './hook/useReminders';

type EditReminderForm = Omit<ContractReminder, 'id' | 'remindDate' | 'emails' | 'contractId'> & {
  remindDate: dayjs.Dayjs | null;
};

type EditReminderProps = DataTestId & {
  /** toggle function to stop editing */
  toggleEditing: () => void;
  reminder: ContractReminder;
};

export const EditReminder = ({ dataTestId, toggleEditing, reminder }: EditReminderProps) => {
  const { body, dueDate, emails, id, remindDate, sendCopy, subject } = reminder;
  const baseTestId = dataTestId || `edit-reminder-${id}`;
  const { updateReminder } = useReminders();
  const [saving, setSaving] = useState(false);

  const methods = useForm<EditReminderForm>({
    mode: 'onTouched',
    defaultValues: {
      body,
      remindDate,
      sendCopy,
      subject,
      dueDate,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values: EditReminderForm) => {
    setSaving(true);
    try {
      await updateReminder({
        ...reminder,
        ...values,
      });
      toggleEditing();
    } finally {
      setSaving(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <FlexContainer wrap='wrap'>
        <Flex grow={1}>
          <DSDateField
            dataTestId={`${baseTestId}-remindDate`}
            defaultValue={remindDate}
            name='remindDate'
            label={texts.form.labels.remindDate}
          />
        </Flex>
        <Flex grow={1}>
          <DSDateField
            dataTestId={`${baseTestId}-dueDate`}
            defaultValue={dueDate}
            name='dueDate'
            label={texts.form.labels.dueDate}
          />
        </Flex>
      </FlexContainer>
      <DSTextField
        dataTestId={`${baseTestId}-emails`}
        defaultValue={emails.join(',')}
        name='emails'
        label={texts.form.labels.sendEmail}
        message={texts.form.messages.sendEmail}
        rules={{
          setValueAs: getEmailArray,
          validate: {
            checkEmails: validateEmailArray,
          },
        }}
      />
      <DSCheckField
        dataTestId={`${baseTestId}-sendCopy`}
        name='sendCopy'
        label={texts.form.labels.sendCopy}
      />
      <DSTextField
        dataTestId={`${baseTestId}-subject`}
        defaultValue={subject}
        name='subject'
        label={texts.form.labels.subject}
        message={texts.form.messages.subject}
      />
      <DSTextAreaField
        dataTestId={`${baseTestId}-emails`}
        defaultValue={body}
        name='body'
        optional
        label={texts.form.labels.body}
      />
      <FlexContainer justifyContent='end'>
        <DSLink dataTestId={`${baseTestId}-cancel`} onClick={toggleEditing}>
          {texts.form.cancel}
        </DSLink>
        <DSButton
          dataTestId={`${baseTestId}-submit`}
          inactive={saving}
          onClick={handleSubmit(onSubmit)}
        >
          {texts.form.update}
        </DSButton>
      </FlexContainer>
    </FormProvider>
  );
};
