// TOREFACTOR: Delete this.

import { requiredErrorMsg } from '@demandstar/components/utils';

import { FormFieldError, FormFieldLabel, FormFieldMessage } from './styled/FormFieldStyled';

export interface FormFieldLabelsProps {
  errorMsg?: string;
  label?: string;
  message?: string;
  name: string;
  required?: boolean;
  showError?: boolean;
  value: string;
  touched: boolean;
  maxLength?: number;
}

/** @deprecated */
export const FormFieldLabels = (props: FormFieldLabelsProps) => {
  const { errorMsg, label, name, required, value, touched, maxLength = 256 } = props;

  const requiredAndEmpty = required && !value;
  const remainingChar = maxLength - value.length;
  const message = remainingChar <= 10 ? `${value.length}/${maxLength}` : props.message;

  const showError = (touched || props.showError) && (errorMsg || requiredAndEmpty);

  return (
    <>
      {label && (
        <FormFieldLabel htmlFor={name} aria-labelledby={name}>
          {/*TODO: Make use of existing Label*/}
          {label}
        </FormFieldLabel>
      )}
      {!showError && label && <FormFieldMessage>{message}</FormFieldMessage>}
      {showError && (
        <FormFieldError>{requiredAndEmpty ? requiredErrorMsg : errorMsg}</FormFieldError>
      )}
    </>
  );
};
