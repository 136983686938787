import { DSSVGIcon, DSSVGIconProps } from '@demandstar/components/icon';

export const NavigationMenuToggleIcon = ({
  color,
  dataTestId,
  height,
  onClick,
  title,
  viewBox = '0 0 24 24',
  width,
}: DSSVGIconProps) => (
  <DSSVGIcon
    height={height}
    onClick={onClick}
    viewBox={viewBox}
    width={width}
    dataTestId={dataTestId}
    title={title}
  >
    <path d='M0 0h24v24H0V0z' fill={'none'} />
    <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' />
  </DSSVGIcon>
);
