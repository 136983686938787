import { memo } from 'react';
import { Table } from 'reactstrap';

import { displayDateTime, formatPrice } from 'src/utils/helpers';
import { loadingMsg } from '../../../../../utils/texts';
import { NoResult } from '../../../../customcontrols';
import { paymentsType } from 'src/types/accountinfo';

interface PropsTypes {
  transactions: paymentsType;
  card_info_Loader: boolean;
}

const PaymentTransactions = (props: PropsTypes) => {
  const { transactions, card_info_Loader } = props;

  return (
    <div>
      <div className='card-transaction'>
        {transactions && transactions.length > 0 ? (
          <>
            <h4>Previous Transactions</h4>
            <Table className='tableHData'>
              <tr>
                <th>Order ID</th>
                <th>Purchase Date</th>
                <th>Paid Amount</th>
                <th />
              </tr>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.trackingNumber}</td>
                  <td>{displayDateTime(transaction.date, 'Eastern Time (US & Canada)')}</td>
                  {typeof transaction.paidAmount === 'number' ? (
                    <td>${formatPrice(transaction.paidAmount, 2)}</td>
                  ) : (
                    <td />
                  )}
                </tr>
              ))}
            </Table>
          </>
        ) : (
          <NoResult message={card_info_Loader ? loadingMsg : 'No Payment Transactions Available'} />
        )}
      </div>
    </div>
  );
};

export default memo(PaymentTransactions);
