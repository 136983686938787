import styled from 'styled-components';

import {
  BackgroundColor,
  BorderRadius,
  BorderThickness,
  ButtonColor,
  FontFamily,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../styles';

import { DSPillType } from './DSPill';

interface DSPillContainerProps {
  type: DSPillType;
  contrast: boolean;
}

export const DSPillColor = {
  Primary: ButtonColor.Primary,
  Success: BackgroundColor.Success,
  Warning: BackgroundColor.Warning,
  Error: BackgroundColor.Error,
  Default: BackgroundColor.BaseGray,
  Contrast: BackgroundColor.BaseWhite,
};

export const DSPillTextColor = {
  Light: TextColor.Contrast,
  Dark: TextColor.Base,
};

function getTextColor({ type, contrast }: DSPillContainerProps) {
  if (!contrast) {
    return [DSPillType.Default, DSPillType.Warning].includes(type)
      ? DSPillTextColor.Dark
      : DSPillTextColor.Light;
  }

  switch (type) {
    case DSPillType.Default:
      return DSPillColor.Default;
    case DSPillType.Primary:
      return DSPillColor.Primary;
    case DSPillType.Success:
      return DSPillColor.Success;
    case DSPillType.Warning:
      return DSPillColor.Warning;
    case DSPillType.Error:
      return DSPillColor.Error;
  }
}

function getBackgroundColor({ type, contrast }: DSPillContainerProps) {
  if (contrast) return DSPillColor.Contrast;

  switch (type) {
    case DSPillType.Default:
      return DSPillColor.Default;
    case DSPillType.Primary:
      return DSPillColor.Primary;
    case DSPillType.Success:
      return DSPillColor.Success;
    case DSPillType.Warning:
      return DSPillColor.Warning;
    case DSPillType.Error:
      return DSPillColor.Error;
  }
}

function getBorderColor(props: DSPillContainerProps) {
  return props.contrast ? getTextColor(props) : getBackgroundColor(props);
}

function getBorderThickness({ contrast }: DSPillContainerProps) {
  return contrast ? BorderThickness.Base : BorderThickness.None;
}

export const DSPillContainer = styled.div<DSPillContainerProps>`
  align-items: center;
  background: ${getBackgroundColor};
  border-radius: ${BorderRadius.Rounded};
  border: ${getBorderThickness} solid ${getBorderColor};
  color: ${getTextColor};
  display: inline-flex;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Small};
  font-style: normal;
  font-weight: ${FontWeight.Base};
  height: ${LineHeight.Base};
  justify-content: center;
  letter-spacing: 0em;
  margin: ${Margin.None} ${Margin.Less} ${Margin.None} ${Margin.None};
  min-width: auto;
  padding: ${Padding.None} ${Padding.Less};
  text-align: left;
  vertical-align: top;
  width: auto;

  & span {
    flex-grow: 0;
    flex: none;
    line-height: ${LineHeight.Base};
    margin: ${Margin.None} ${Margin.Less};
    order: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const DSPillIcon = styled.i<{ order: number }>`
  cursor: pointer;
  order: ${props => props.order};
`;
