import { DSPageLayout } from '@demandstar/components/page-layout';
import { LayoutType } from '@demandstar/components/constants';

import { Footer, Header } from '../layout';

//TODO: can this any be {children: ReactNode} or PropsWithChildren<unknown>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Default = (props: any) => {
  return <>{props.children}</>;
};

interface LayoutProps {
  //TODO: can this any be {children: ReactNode} or PropsWithChildren<unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  layout?: LayoutType;
}

export const LoggedIn = (props: LayoutProps) => {
  return (
    <>
      <Header />
      {props.layout !== LayoutType.None ? (
        <DSPageLayout layout={props.layout}>{props.children}</DSPageLayout>
      ) : (
        <>{props.children}</>
      )}
      <Footer />
    </>
  );
};
