import * as services from './Documents.services';

import { DocumentAPIResponse } from './documents';
import { tryCatchLog } from 'src/utils/errors';

export function useDocuments() {
  const downloadDocument = async (doc: DocumentAPIResponse) => {
    const result = await tryCatchLog(
      async () => await services.downloadDocument(doc.id),
      `useDocuments => downloadDocument(${doc.id})`,
    );

    const blob = new Blob([result], {
      type: doc.format,
    });

    const a = document.createElement('a');
    a.download = doc.title || 'file';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', e => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  return {
    downloadDocument,
  };
}
