export const BROADCAST_CHANNEL_AUTH_ID = 'authenticationStatus';
export const BROADCAST_CHANNEL_CODE = {
  logout: 'logout',
  loginError: 'loginError',
  reload: 'reload',
};

// Communicate auth state across tabs/iframes
const bcAuth = new BroadcastChannel(BROADCAST_CHANNEL_AUTH_ID);
bcAuth.onmessage = event => {
  console.log('utils.helpers.broadcast-channel.bcAuth onMessage:', event);
};

export function broadcastChannelLogout(bcInstance: BroadcastChannel) {
  bcInstance.postMessage(BROADCAST_CHANNEL_CODE.logout);
}

export function broadcastChannelLoginError(bcInstance: BroadcastChannel) {
  bcInstance.postMessage(BROADCAST_CHANNEL_CODE.loginError);
}

export function broadcastChannelReload(bcInstance: BroadcastChannel) {
  bcInstance.postMessage(BROADCAST_CHANNEL_CODE.reload);
}
