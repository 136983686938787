import { DSEmail } from '@demandstar/components/email';

export const commonEmail = {
  agencyServices: 'agencyservices@demandstar.com',
  demandStar: 'demandstar@demandstar.com', // is this email deprecated?
  hello: 'hello@demandstar.com',
  notification: 'notifications@demandstar.com',
  supplierServices: 'supplierservices@demandstar.com',
  support: 'support@demandstar.com',
} as const;

export const DemandStarNotificationEmail = <DSEmail>{commonEmail.notification}</DSEmail>;

export const DemandStarSupportEmail = <DSEmail>{commonEmail.support}</DSEmail>;
