import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect } from 'react';

import { bindActionCreators } from 'redux';

import { addUpdateMetaTag, guidValidation, setCookie } from '../../../utils/helpers';

import * as actionCreators from '../../../store/actions';

import { Buttons } from '../../customcontrols';
import { commonLabels } from '../../../shared/constants';
import { constants } from '../../../utils/settings';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { LimitedBidList } from './bidlist';
import { LimitedFooter } from '../footer';
import { LimitedHeader } from '../header';
import { sharedTypes } from '../../../types/shared';

interface propsTypes extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  shared: sharedTypes;
  history: any;
  match: any;
  getLimitedAgencyLanding: any;
}

function AgencyLanding(props: propsTypes) {
  const { limited, history, getLimitedAgencyLanding, getLimitedAgencyDetails } = props;
  const { limitedAgencyDetails, limitedBidsList = [] } = limited;
  const guid = props.match.params.Id;
  const bgImg = {
    backgroundImage: `url(${constants.contentBlobUrl}banners/${
      limitedAgencyDetails.banner ? limitedAgencyDetails.banner : 'defaultbanner.jpg'
    })`,
    backgroundPosition: `center ${limitedAgencyDetails.position}`,
  };

  const agencyName = limitedAgencyDetails.agencyName;
  const AgencyMemberId = limitedAgencyDetails.agencyId;
  const stateAbbr = limitedAgencyDetails.stateAbbr;

  useEffect(() => {
    document.title = `${agencyName} Procurement  - Bids & RFPs | DemandStar`;
    addUpdateMetaTag('og:title', `Bid Opportunities in ${agencyName}, ${stateAbbr} - DemandStar`);
    addUpdateMetaTag('og:url', document.location.href);
    addUpdateMetaTag(
      'description',
      `${agencyName} , ${stateAbbr} is looking to buy goods and services. Discover all bid opportunities, RFPs, and government contracts with ${agencyName}`,
    );
    addUpdateMetaTag(
      'keywords',
      `Procurement, bid, RFP, quote, government, RFQ, RFI, bids, ${agencyName}`,
    );
    const fileref = document.createElement('link');
    fileref.setAttribute('rel', 'canonical');
    fileref.setAttribute('href', document.location.href);
  }, [agencyName, stateAbbr]);

  useEffect(() => {
    if (guidValidation(guid.toLowerCase())) getLimitedAgencyDetails({ guid });
    else history.push('/login');
  }, [getLimitedAgencyDetails, guid, history]);

  useEffect(() => {
    if (AgencyMemberId) getLimitedAgencyLanding({ guid });
  }, [AgencyMemberId, getLimitedAgencyLanding, guid]);

  const loginAndSearchSupplier = () => {
    setCookie('redirectUrl', '/suppliers/bids', 1);
    setCookie('AgencyMemberName', agencyName, 1);
    setCookie('AgencyMemberId', AgencyMemberId, 1);
    history.push('/login');
  };

  return (
    <>
      <LimitedHeader />
      {
        <div className='agencyLandingWrapper'>
          <div className='alBannerWrapper' style={bgImg}>
            <div className='alTitleLogo'>
              <div className='container'>
                <div className='alInnerCol'>
                  <div
                    className='alLogo'
                    style={
                      limitedAgencyDetails.theme ? { background: limitedAgencyDetails.theme } : {}
                    }
                  >
                    <div>
                      <img
                        src={`${constants.contentBlobUrl}logos/${
                          limitedAgencyDetails.logo ? limitedAgencyDetails.logo : 'defaultlogo.png'
                        }`}
                        alt={limitedAgencyDetails.agencyName}
                        title={limitedAgencyDetails.agencyName}
                      />
                    </div>
                  </div>
                  {/* <div className='alName'>
                    <h3>{limitedAgencyDetails.agencyName}</h3>
                  </div> */}
                </div>
                <div className='poweredBy'>
                  Powered by{' '}
                  <img
                    width='94px'
                    src={`${constants.contentBlobUrl}logos/ds_logo_white.png`}
                    alt='DemandStar'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='alContentWrapper'>
            <div className='container'>
              <div className='alInnerCol'>
                <h1>
                  {agencyName} Procurement - Bids & RFPs | DemandStar {/* {stateAbbr} */}
                </h1>
                {limitedBidsList.length > 0 ? (
                  <>
                    <LimitedBidList {...props} />
                    <div className='text-center'>
                      <Buttons
                        classNames='bttn-primary mt-5'
                        text={commonLabels.viewMore}
                        title={commonLabels.viewMore}
                        type='button'
                        onClick={loginAndSearchSupplier}
                      />
                    </div>
                  </>
                ) : (
                  <h3 className='text-center noresultText'>
                    Currently there are no procurement opportunities.
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      <LimitedFooter />
    </>
  );
}

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(AgencyLanding));
