import { Controller, get, RegisterOptions,FieldError, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import { FieldContainer, FieldProps, getFieldContainerProps } from '../../field-container';
import { formatPhone, numericPhoneVal } from '../../utils';
import { BaseTextInput } from '../..';
import { Inactive } from '../../types/shared';

const phoneMaxLength = 17; // The amount of space it takes to type +1 (425) 867-5309

export type DSPhoneFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus'> &
  Inactive;

/** Phone field which converts text into valid phone number.
 * @example
 * <FormProvider>
 * <DSPhoneField
 *    name={fieldName}
 *  />
 * */
export const DSPhoneField = (props: DSPhoneFieldProps) => {
  const { autoFocus, dataTestId, inactive, name, optional } = props;

  const methods = useFormContext() as any;
  const { control, formState: { errors }, setValue, watch,updateValue } = methods;
  const [preventUpdate, setPreventUpdate] = useState(autoFocus);

  const setDisplayValue = useCallback(
    (value: string) => {
      // console.log(name,value)
      setValue(name, value);
    },
    [name, setValue],
  );

  /** the phone number value of the input */
  const value: string | undefined = watch(name);

  /** The correct string value we should be displaying when the input is not focused. */
  const trueDisplay = useMemo(() => {
    if (!value) {
      return '';
    }
    return formatPhone(value);
  }, [value]);

  const registerOptions: RegisterOptions = {
    required: !optional,
    validate: {
      numericPhoneVal: (v: string) => {
        if (optional && !v) {
          return true;
        }
        return numericPhoneVal.test(getPhoneValue(v)) || 'Please enter a 10 digit phone number';
      },
    },
    // setValueAs: getPhoneValue,
    maxLength: phoneMaxLength,
  };

  const error = errors[name] as FieldError;

  useEffect(() => {
    /** keeps the display in sync after blurs.
     * Does not get triggered while the user is typing. */
    if (!preventUpdate) {
      setDisplayValue(trueDisplay);
    }
  }, [preventUpdate, setDisplayValue, trueDisplay]);

  /** Converts the string value into a proper number (still a string).
   * @param   {string} value
   * @returns {number}
   * @example getPhoneValue() // ''
   * @example getPhoneValue("425.867.5309") // '4258675309'
   */
  function getPhoneValue(value?: string): string {
    if (!value) return '';

    return String(value).replace(/[^\d]/g, ''); // strips out all but numbers.
  }

  /** sets preventUpdate to true, which prevents the value from formatting
   * while the user is typing. */
  function onFocus() {
    setPreventUpdate(true);
  }

  return (
    <Controller
      control={control}
      defaultValue={trueDisplay}
      name={name}
       rules={registerOptions}
      render={({ field }) => {
        function formatPhone() {
          setPreventUpdate(false);
          setDisplayValue(trueDisplay);
          field.onBlur();
        }

        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <BaseTextInput
              autoFocus={autoFocus}
              data-testid={dataTestId}
              error={error}
              id={name}
              maxLength={phoneMaxLength}
              name={name}
              onBlur={formatPhone}
              onChange={field.onChange}
              onFocus={onFocus}
              readOnly={inactive}
              ref={field.ref}
              value={value}
            />
          </FieldContainer>
        );
      }}
    />
  );
};
