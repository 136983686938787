import React from 'react';
import { useQuery } from '@tanstack/react-query'; 
import { dashboardBidsList } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetDashboardBidsListData = (data: any) => {
  return dashboardBidsList(data);
};

export const useGetDashboardBidsListData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardBids'],
    queryFn: () => GetDashboardBidsListData(data), 
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
