import { CustomMessageType, CustomNotificationRecipient, EmailInfo } from '../../types/awardBid';
import { isValidEmail, isValidPhone } from '.';
import { RequiredDocument } from '../../store/services';
import { RequiredDocumentResponseGetAPIResponse } from '../../types';

export const defaultEmailInfo: EmailInfo = {
  subject: '',
  introduction: '',
  customMessage: '',
  contactName: '',
  contactTitle: '',
  contactPhone: '',
  contactEmail: '',
  recipients: [],
  shouldNotify: false,
};

/** helper function for checking whether email info is valid based on the information the user has passed in */
export function isValidEmailInfo(emailInfo: EmailInfo, customMessageType: CustomMessageType) {
  const { customMessage, subject, recipients } = emailInfo;
  return !!(
    customMessage &&
    subject &&
    // We are only checking for valid contact info if the user has submitted it themselves (for Awardees)
    (customMessageType !== CustomMessageType.Awardees || hasValidContactInfo(emailInfo)) &&
    // We are only checking for valid recipients if the user submitted it themselves (for Colleagues)
    (customMessageType !== CustomMessageType.Colleagues || hasValidRecipients(recipients))
  );
}

/** helper function for checking if all recipients are valid */
function hasValidRecipients(recipients: CustomNotificationRecipient[]) {
  return !recipients.some(recipient => {
    return (
      !isValidEmail(recipient.email.trim()) || (!recipient.contactName && !recipient.memberName)
    );
  });
}

/** helper function for checking if all contact information is valid */
function hasValidContactInfo(emailInfo: EmailInfo) {
  const { contactEmail, contactName, contactPhone, contactTitle } = emailInfo;
  return contactName && contactTitle && isValidPhone(contactPhone) && isValidEmail(contactEmail);
}

export function convertResponseToRequiredDoc(
  response: RequiredDocumentResponseGetAPIResponse,
): RequiredDocument {
  const { bidAwardRequiredDocId, docTitle, dueDate, isDelete } = response;
  return {
    bidAwardRequiredDocId,
    docTitle,
    dueDate,
    isDelete,
    docName: '',
    docDescription: '',
  };
}
