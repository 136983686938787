import { Cell, Column } from 'react-table';
import styled from 'styled-components';
import React, { useMemo } from 'react';

import { Margin, P } from '@demandstar/components/styles';
import { DSDollar } from '@demandstar/components/dollar';
import { DSEmail } from '@demandstar/components/email';
import { DSLink } from '@demandstar/components/link';
import { DSPhone } from '@demandstar/components/phone';
import { DSTable } from '@demandstar/components/table';

import * as texts from './ContractAwardeeTable.texts';
import { AwardeeContact } from 'src/features/contract-management/contract-management.d';
import { useContractDetails } from 'src/features/contract-management/useContractDetails';

const MarginBottomDiv = styled.div`
  margin-bottom: ${Margin.Base};
`;

interface ContractAwardeeTableProps {
  onEdit?: (awardee: AwardeeContact) => void;
}

export const ContractAwardeeTable = (props: ContractAwardeeTableProps) => {
  const { onEdit } = props;

  const { contractDetails } = useContractDetails();

  const columns: Column<AwardeeContact>[] = useMemo(() => {
    const cols: Column<AwardeeContact>[] = [
      {
        accessor: 'name',
        Header: texts.supplier,
      },
      {
        accessor: 'email',
        disableSortBy: true,
        Header: texts.businessContact,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cell: any) => {
          const { email, phone } = cell.row.original;
          return (
            <>
              <P lineBreak>
                <DSEmail>{email}</DSEmail>
              </P>
              <P lineBreak>
                <DSPhone>{phone}</DSPhone>
              </P>
            </>
          );
        },
      },
      {
        accessor: 'contactName',
        disableSortBy: true,
        Header: texts.primaryContact,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cell: any) => {
          const primaryContact = cell.row.original;
          return (
            <>
              <P lineBreak>{primaryContact.contactName}</P>
              <P lineBreak>
                <DSEmail>{primaryContact.contactEmail}</DSEmail>
              </P>
              <P lineBreak>
                <DSPhone>{primaryContact.contactPhone}</DSPhone>
              </P>
            </>
          );
        },
      },
      {
        accessor: 'amount',
        Header: texts.contractAmount,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cell: any) => {
          const primaryContact = cell.row.original;
          return <DSDollar>{primaryContact.amount}</DSDollar>;
        },
      },
    ];
    if (onEdit) {
      cols.push({
        accessor: 'id',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cell: any) => {
          const awardee = cell.row.original;
          return <DSLink onClick={() => onEdit(awardee)}>Edit</DSLink>;
        },
        disableSortBy: true,
      });
    }

    return cols;
  }, [onEdit]);

  return (
    <MarginBottomDiv>
      {contractDetails && (
        <DSTable
          data={contractDetails.awardeeContacts}
          columns={columns}
          sort='amount'
          rowKey='id'
          emptyMessage={texts.noAwardees}
        />
      )}
    </MarginBottomDiv>
  );
};
