import { useQuery } from '@tanstack/react-query';
import { getCommodities } from 'src/store/services';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetCommodityCodes = (data:any) => {
  return getCommodities(data);
};

export const useGetCommodityCodes = (data:any) => {
  return useQuery({
    queryKey: ['CommodityCodes'],
    queryFn: () => GetCommodityCodes(data),
    staleTime: STALE_TIMES.ONE_DAY,
  });
};
