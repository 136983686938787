import React, { createContext, ReactNode, useState } from 'react';

interface AppContextProps {
  fetchDashboardData: boolean;
  fetchDashboardTrackingData: boolean;
  fetchAccountCommodity: boolean;
  showCommodityPopUpinDashboard:boolean;
  RefetchDashboardData: (newData: boolean) => void;
  RefetchDashboardTrackingData: (newData: boolean) => void;
  RefetchAccountCommodityData: (newData: boolean) => void;
  changeShowCommodityPopupinDashboard:(newData: boolean) => void;
}

const AppContext = createContext<AppContextProps>({
  fetchDashboardData: false,
  fetchDashboardTrackingData: false,
  fetchAccountCommodity: false,
  showCommodityPopUpinDashboard:true,
  RefetchDashboardData: () => {},
  RefetchDashboardTrackingData: () => {},
  RefetchAccountCommodityData: () => {},
  changeShowCommodityPopupinDashboard:()=>{},
});

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [fetchDashboardData, setRefetchDashboardData] = useState(false);
  const [fetchDashboardTrackingData, setfetchDashboardTrackingData] = useState(false);
  const [fetchAccountCommodity, setFetchAccountCommodityData] = useState(false);
  const [showCommodityPopUpinDashboard,setChangeShowCommodityPopupinDashboard]=useState(true);

  const RefetchDashboardData = (newData: boolean) => {
    setRefetchDashboardData(newData);
  };
  const RefetchDashboardTrackingData = (newData: boolean) => {
    setfetchDashboardTrackingData(newData);
  };
  const RefetchAccountCommodityData = (newData: boolean) => {
    setFetchAccountCommodityData(newData);
  };

  const changeShowCommodityPopupinDashboard = (newData: boolean) => {
    setChangeShowCommodityPopupinDashboard(newData);
  };
  return (
    <AppContext.Provider
      value={{
        fetchDashboardData,
        fetchDashboardTrackingData,
        fetchAccountCommodity,
        showCommodityPopUpinDashboard,
        RefetchDashboardData,
        RefetchDashboardTrackingData,
        RefetchAccountCommodityData,
        changeShowCommodityPopupinDashboard,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
