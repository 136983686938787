import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from '../../../common/modals';

interface AutoRenewalModalProps {
  /** whether autorenewal is currently set as "on" */
  isAutoRenewalOn: boolean;
  /** whether modal is open */
  isOpen?: boolean;
  /** function to call to proceed with updating autorenewal */
  onChangeAutoRenewal: (e?: React.FormEvent<HTMLFormElement>) => Promise<void>;
  /** function to call when cancelling */
  onCancel: (e?: React.MouseEvent<HTMLElement>) => void;
}

/**
 * @description confirmation modal for updating auto-renewal
 * @returns JSX.Element
 *
 * @example <AutoRenewal
 *  isAutoRenewalOn={true}
 * />
 */
export const AutoRenewalModal = ({
  isAutoRenewalOn,
  isOpen = false,
  onChangeAutoRenewal,
  onCancel,
}: AutoRenewalModalProps) => {
  return (
    <ModalPopUp title={'Auto Renewal'} size={'md'} isOpen={isOpen} closeModal={onCancel}>
      <p>Are you sure you want to turn {!isAutoRenewalOn ? 'on' : 'off'} auto renewal?</p>
      <FlexContainer justifyContent='space-between'>
        <DSButton type={'secondary'} onClick={() => onCancel()}>
          {commonLabels.no}
        </DSButton>
        <DSButton onClick={() => onChangeAutoRenewal()}>{commonLabels.yes}</DSButton>
      </FlexContainer>
    </ModalPopUp>
  );
};
