import { DSButton } from '@demandstar/components/button';

import { BidSummaryLabels, navigationPaths } from '../../../../shared/constants';
import { EditIcon } from 'src/components/common/icons';
import { useBidResponseStatus } from '../../../../shared/hooks/useBidResponseStatus';
import { useRouter } from '../../../../shared/hooks';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';

/**
 * Show an "Edit" button to navigate to the build details/edit view,
 * unless no bid has been selected
 * or the current bid has a `closed` response status.
 * @returns JSX
 *
 */
export function EditBidResponseButton() {
  // Hooks
  const { navigate } = useRouter();
  const { selectedBidId } = useSelectedBidId();
  const { bidResponseStatus } = useBidResponseStatus();

  function navigateToResponseEditView() {
    navigate(`${navigationPaths.suppliers.bids}/${selectedBidId}/response`);
  }

  /** // ! FIXME: `!== closed` includes '', which causes a flicker of the edit button.
   * Consider using responseStatus?
   */
  return selectedBidId > 0 && bidResponseStatus !== 'closed' ? (
    <DSButton icon={EditIcon} onClick={navigateToResponseEditView} type='submit'>
      {BidSummaryLabels.edit}
    </DSButton>
  ) : null;
}
