import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { DSTextInput } from '@demandstar/components/inputs';

import { Cell, NotificationFormRow } from '../../styled/AwardBidStyled';
import { CustomNotificationRecipient, InternalRecipient } from '../../../../../types/awardBid';
import { EmailInput } from '../../../../forms';
import { Helper } from '../../../../common/controls/text/Helper';
import { isValidEmail } from '../../../../../utils/helpers';
import { Label } from '../../../../common/controls/text/Label';
import { notifyColleaguesState } from '../../../../../store/recoil/award-bid';
import { TextInput } from '../../../../forms/TextInput';

interface AddColleaguesRowProps {
  recipient: CustomNotificationRecipient;
  updateRow: (recipient: CustomNotificationRecipient) => void;
  deleteEmptyOnBlur: () => void;
}
const NotificationRecipientRow = (props: AddColleaguesRowProps) => {
  const { deleteEmptyOnBlur, recipient, updateRow } = props;

  function editName(contactName: string) {
    updateRow({ ...recipient, contactName });
  }

  function editRole(role: string) {
    updateRow({ ...recipient, role });
  }

  function editEmail(email: string) {
    updateRow({ ...recipient, email });
  }

  return (
    <NotificationFormRow>
      <Cell width='33%'>
        <TextInput
          required
          name='name'
          value={recipient.contactName}
          onChange={editName}
          autoFocus={true}
          fullWidth={true}
          onBlur={deleteEmptyOnBlur}
        />
      </Cell>
      <Cell width='33%'>
        <TextInput
          name='role'
          value={recipient.role}
          onChange={editRole}
          onBlur={deleteEmptyOnBlur}
          fullWidth={true}
        />
      </Cell>
      <Cell width='33%'>
        <EmailInput
          name='email'
          value={recipient.email}
          onChange={editEmail}
          onBlur={deleteEmptyOnBlur}
          fullWidth={true}
        />
      </Cell>
    </NotificationFormRow>
  );
};
/**
 * Used to add recipients to the emailInfo object
 */
export const AddColleagues = () => {
  const [defaultEmailInfo, saveEmailInfo] = useRecoilState(notifyColleaguesState);
  const [emailInfo, setEmailInfo] = useState(defaultEmailInfo);

  useEffect(() => {
    saveEmailInfo(emailInfo);
  }, [emailInfo, saveEmailInfo]);

  const allRowsAreValid = useMemo(() => {
    for (const recipient of emailInfo.recipients) {
      if (!recipient.contactName || !isValidEmail(recipient.email)) {
        return false;
      }
    }
    return true;
  }, [emailInfo.recipients]);

  function updateRow(recipient: CustomNotificationRecipient, index: number) {
    const tempRecipients = [...emailInfo.recipients];
    tempRecipients[index] = recipient;
    setEmailInfo({ ...emailInfo, recipients: tempRecipients });
  }

  function addNewRow(value: string) {
    const tempRecipients = [
      ...emailInfo.recipients,
      {
        contactName: value,
        email: '',
        role: '',
        accountId: -1,
        memberId: -1,
        memberName: '',
      } as InternalRecipient,
    ];
    setEmailInfo({ ...emailInfo, recipients: tempRecipients });
  }

  function deleteRowIfEmpty(index: number) {
    const recipients = [...emailInfo.recipients];
    if (!recipients[index].contactName && !recipients[index].email && !recipients[index].role) {
      const tempRecipients = [...recipients];
      tempRecipients.splice(index, 1);
      setEmailInfo({ ...emailInfo, recipients: tempRecipients });
    }
  }

  return (
    <>
      <NotificationFormRow header>
        <Cell width='33%'>
          <Label required>Recipient</Label>
        </Cell>
        <Cell width='33%'>
          <Label>Role</Label> <Helper>(optional)</Helper>
        </Cell>
        <Cell width='33%'>
          <Label required>Email</Label>
        </Cell>
      </NotificationFormRow>

      {emailInfo.recipients.map((recipient, index) => {
        return (
          <NotificationRecipientRow
            key={index}
            recipient={recipient}
            updateRow={recipient => updateRow(recipient, index)}
            deleteEmptyOnBlur={() => {
              deleteRowIfEmpty(index);
            }}
          />
        );
      })}

      {allRowsAreValid && (
        <NotificationFormRow>
          <Cell width='33%'>
            <DSTextInput name='name-placeholder' value={''} onChange={addNewRow} />
          </Cell>
          <Cell width='33%'>
            <DSTextInput name='role-placeholder' value={''} inactive />
          </Cell>
          <Cell width='33%'>
            <DSTextInput name='email-placeholder' value={''} inactive />
          </Cell>
        </NotificationFormRow>
      )}
    </>
  );
};
