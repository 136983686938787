// TOREFACTOR: This is much more complicated that it needs to be.

import { memo, useEffect, useState } from 'react';

import { deprecatedCountDownTimerTimeZone } from '../../utils/helpers';
import { DeprecatedFullTimeZone } from '../../utils/constants';
import { useForceUpdate } from '../../utils/helperHooks';

interface CountDownTimerProps {
  dueDate?: string | Date;
  component?: any;
  custom?: boolean;
  timerCompletedAction?: any;
  pageFor?: string;
  tzfn?: DeprecatedFullTimeZone | '';
}

function CountDownTimer(props: CountDownTimerProps) {
  const forceUpdate = useForceUpdate();
  const {
    dueDate = '',
    component = () => {},
    custom = false,
    timerCompletedAction = () => {},
    pageFor = '',
    tzfn = '',
  } = props;
  const [countDownString, setcountDownString] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const value = deprecatedCountDownTimerTimeZone(dueDate, tzfn);
      setcountDownString(value);
      if (value !== 'Closed') {
        forceUpdate();
      } else {
        timerCompletedAction();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [dueDate, forceUpdate, timerCompletedAction, tzfn]);

  return (
    <>
      {countDownString === 'Closed' ? (
        pageFor === 'ebidresponse' ? (
          component('closed')
        ) : null
      ) : (
        <>{custom ? component(countDownString) : <>{`${countDownString} remaining`}</>}</>
      )}
    </>
  );
}

export default memo(CountDownTimer);
