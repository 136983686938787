import { commonLabels } from 'src/shared/constants';
import { pluralize } from 'src/utils/helpers';

/** Labels that relate 1-1 with ContractDetails fields, listed in display order. */
export const labels = {
  /** This will be based on number of planholders. We may need to rename down the line. */
  awardType: 'Award Type',
  bidNumber: 'Original Bid Number',
  contactEmail: 'Primary Email',
  contactName: 'Primary Contact',
  contactPhone: 'Primary Phone',
  amount: 'Contract Amount',
  endDate: 'Contract End Date',
  solicitationId: 'Contract ID',
  name: 'Contract Name',
  startDate: 'Contract Start Date',
  extensions: 'Terms Remaining',
  scopeOfWork: 'Scope of Work',
} as const;

export const contractDetails = 'Contract Details';
export const supplierDetails = 'Supplier Details';
export const contractDocuments = 'Contract Documents';
export const supplierDetailsCopy = (suppliers: number) => {
  return `This contract is serviced by ${suppliers} ${pluralize(
    suppliers,
    'supplier',
    'suppliers',
  )}.`;
};

export const submit = commonLabels.submit;
