import { useState } from 'react';

import { Assert } from '@demandstar/components/utils';

import { DocStatus, DocTypeValue } from '../../../types/addbid';
import { agencyTexts } from '../../../utils/texts';
import { AwardWizardId } from '../../../store/recoil/award-bid';
import { BidExternalStatusType } from 'src/utils/constants';
import { ConfirmModal } from '../../common/modals/ConfirmModal';
import { DocumentUploadTable } from '../../common/DocumentUploadTable';
import { useAwardBidWizard } from '../../../shared/hooks';
import { useBid } from 'src/shared/hooks/useBid';
import { useDSSelector } from '../../../store/reducers';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';
import { WizardButtons } from '../../customcontrols/wizard/WizardButtons';

export const AddPublicDocs = () => {
  const { saveAwardBidWizard, returnToBidSummary, hasAwardees } = useAwardBidWizard();

  const { bidId } = useIdFromRoute();
  Assert(bidId, 'The route must have a bidId', 'AddPublicDocs');

  const { bid } = useBid();
  const isAwarded = bid?.bidExternalStatusType === BidExternalStatusType.Awarded;

  const documentList = useDSSelector(state => state.addbid.documentslist);
  const yetToProcessDocs = !!documentList.filter(doc =>
    [DocStatus.ProcessPending, DocStatus.PdfProcessPending].includes(doc.statusType),
  ).length;
  const yetToApproveDocs = !!documentList.filter(
    doc =>
      doc.statusType === DocStatus.ApprovalPending && doc.docType !== DocTypeValue.AwardDocument,
  ).length;

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  function checkNextPage() {
    if (yetToApproveDocs) {
      toggleConfirmModal();
    } else {
      nextPage();
    }
  }

  function prevPage() {
    saveAwardBidWizard(hasAwardees ? AwardWizardId.ListRequiredDocs : AwardWizardId.SelectAwardees);
  }

  function nextPage() {
    if (isAwarded) {
      returnToBidSummary();
    } else {
      saveAwardBidWizard(
        hasAwardees ? AwardWizardId.NotifyAwardees : AwardWizardId.NotifyColleagues,
      );
    }
  }

  function toggleConfirmModal() {
    setConfirmModalVisible(!confirmModalVisible);
  }

  return (
    <>
      <DocumentUploadTable bidId={bidId} type='Award' />

      <WizardButtons
        altNext={isAwarded ? agencyTexts.awardBid.returnToBid : undefined}
        onPrevious={prevPage}
        onNext={checkNextPage}
        save={returnToBidSummary}
        inactiveNext={yetToProcessDocs}
      />
      <ConfirmModal
        isOpen={confirmModalVisible && yetToApproveDocs}
        title='Confirmation'
        onConfirm={toggleConfirmModal}
        confirmText={agencyTexts.documents.approveDocsButton}
        closeModal={toggleConfirmModal}
        onlyConfirm={true}
      >
        {agencyTexts.awardBid.approveDocsWarning}
      </ConfirmModal>
    </>
  );
};
