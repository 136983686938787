import { Assert } from '@demandstar/components/utils';
import { DSButton } from '@demandstar/components/button';

import {
  BidResponseLabels,
  BidSummaryLabels,
  RESPONSE_DETAILS_LABEL,
} from '../../../../shared/constants/bids';
import { PanelItemWrapper, PanelLabel, PanelRow, PanelRowRight } from '../../../../shared/styles';
import { BidSummaryPanelView } from '../../../bids/management/BidSummaryPanelView';
import { ContactDetailsPanel } from './ContactDetailsPanel';
import { ContactLabels } from '../../../../shared/constants';
import { DocumentsPanel } from './DocumentsPanel';
import { EBidResponseFull } from 'src/types/biddetail';
import { ResponseHistory } from './ResponseHistory';

interface BidResponseDetailsPanelViewProps {
  bidStatus?: string;
  handleEditClick?(): void;
  responseDetails: EBidResponseFull;
}

export function BidResponseDetailsPanelView(props: BidResponseDetailsPanelViewProps) {
  const { bidStatus, responseDetails, handleEditClick } = props;

  const { bidId, responseDocuments, requiredDocuments, responseId, supplementalDocuments } =
    responseDetails;

  Assert(
    !responseId || bidId,
    'If we have a responseId, we must always have a valid bidId',
    'BidResponseDetailsPanelView',
  );

  return (
    <>
      <PanelLabel>{RESPONSE_DETAILS_LABEL}</PanelLabel>
      <ResponseHistory responseId={responseId} bidId={bidId} />
      <PanelItemWrapper>
        <PanelRow>
          {<BidSummaryPanelView bidId={Number(bidId)} eBidResponse={responseDetails} />}
        </PanelRow>
      </PanelItemWrapper>

      <PanelLabel>{ContactLabels.label}</PanelLabel>
      <PanelItemWrapper>
        <PanelRow>
          <ContactDetailsPanel />
        </PanelRow>
      </PanelItemWrapper>

      {requiredDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.agencyRequiredDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={requiredDocuments}
                bidId={bidId}
                documentTitle={''}
                documentType='1'
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}

      {supplementalDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.supplementalDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={supplementalDocuments}
                bidId={bidId}
                documentTitle={''}
                documentType='1'
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}

      {responseDocuments?.length > 0 && (
        <>
          <PanelLabel>{BidResponseLabels.uploadedDocuments}</PanelLabel>
          <PanelItemWrapper>
            <PanelRow>
              <DocumentsPanel
                documents={responseDocuments}
                documentType='2'
                documentTitle={''}
                bidId={bidId}
              />
            </PanelRow>
          </PanelItemWrapper>
        </>
      )}
      {/**
       * // ! FIXMME: `!== closed` includes '', which causes a flicker of the edit button.
       * Consider using responseStatus?
       */}
      {bidStatus !== 'closed' && (
        <PanelItemWrapper>
          <PanelRowRight>
            <DSButton onClick={handleEditClick}>{BidSummaryLabels.edit}</DSButton>
          </PanelRowRight>
        </PanelItemWrapper>
      )}
    </>
  );
}
