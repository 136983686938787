import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { DSSelectField, DSTextField } from '@demandstar/components/fields';

import { AddressInfo } from './AddressLookup.d';
import { allCountries } from 'src/store/recoil/address/address.consts';
import { isValidRequiredTrimmingSpace } from 'src/utils/helpers';
import { useAddress } from 'src/store/recoil/address/useAddress';
import { zipCodeRegex } from './helpers';

/* This is a react-hook-form reusable component
 * and requires a parent <FormProvider> from react-hook-form
 * See `components/products/CompleteProfile.tsx` as an example
 */
export const AddressLookup = () => {
  const { setValue, watch } = useFormContext<{ addressInfo: AddressInfo }>();

  const postalCode: string = watch('addressInfo.postalCode');
  const stateId: number = watch('addressInfo.stateId');
  const countyId: number = watch('addressInfo.countyId');
  const countryId: number = watch('addressInfo.countryId');

  const {
    allAddressStates,
    stateCounties,
    loadedAllAddressInfo,
    postalCountryId,
    postalStateId,
    postalCountyId,
    postalCity,
  } = useAddress(postalCode, stateId);

  const trimInputValue = (value: string) => value.trim();

  /** prevent stale data by checking that the selected countyId
   * cannot belong to a state that is no longer selected */
  useEffect(() => {
    if (countyId && loadedAllAddressInfo) {
      const selectedStateCounty = stateCounties.find(county => {
        return county.id === countyId;
      });
      if (!selectedStateCounty) {
        setValue('addressInfo.countyId', null);
      }
    }
  }, [countyId, loadedAllAddressInfo, setValue, stateCounties]);

  /** when postal code changes, set countryId to the one matching the postal code */
  useEffect(() => {
    if (postalCountryId) {
      setValue('addressInfo.countryId', postalCountryId);
    }
  }, [postalCountryId, setValue]);

  /** when postal code changes, set stateId to the one matching the postal code */
  useEffect(() => {
    if (postalStateId) {
      setValue('addressInfo.stateId', postalStateId);
    }
  }, [postalStateId, setValue]);

  /** when postal code changes, set countyId to the one matching the postal code */
  useEffect(() => {
    if (postalCountyId) {
      setValue('addressInfo.countyId', postalCountyId);
    }
  }, [postalCountyId, setValue]);

  /** when postal code changes, set city to the one matching the postal code */
  useEffect(() => {
    if (postalCity) {
      setValue('addressInfo.city', postalCity);
    }
  }, [postalCity, setValue]);

  return (
    <>
      <DSTextField
        label='Address'
        id='address1'
        name='addressInfo.address1'
        rules={{
          maxLength: 50,
          setValueAs: trimInputValue,
          validate: isValidRequiredTrimmingSpace,
        }}
      />

      <DSTextField
        optional
        label='Suite, Floor, etc.'
        id='address2'
        name='addressInfo.address2'
        dataTestId='suite-floor'
        rules={{
          maxLength: 50,
          setValueAs: trimInputValue,
        }}
      />

      <DSTextField
        label='Postal Code'
        id='postalCode'
        name='addressInfo.postalCode'
        data-testid='suite-floor'
        rules={{
          maxLength: 7,
          setValueAs: trimInputValue,
          pattern: {
            message: 'Invalid Postal Code',
            value: zipCodeRegex,
          },
        }}
      />

      <DSTextField
        label='City'
        id='city'
        name='addressInfo.city'
        inactive={!loadedAllAddressInfo}
        rules={{
          maxLength: 50,
          setValueAs: trimInputValue,
          validate: isValidRequiredTrimmingSpace,
        }}
      />

      <DSSelectField
        closeMenuOnSelect={true}
        isDisabled={!loadedAllAddressInfo}
        label='State'
        name={'addressInfo.stateId'}
        labelField={'name'}
        valueField={'id'}
        options={allAddressStates}
      />

      <DSSelectField
        closeMenuOnSelect={true}
        isDisabled={!loadedAllAddressInfo}
        label='County'
        name='addressInfo.countyId'
        options={stateCounties}
        labelField='name'
        valueField='id'
        optional={countryId === 1 ? false : true}
      />

      <DSSelectField
        closeMenuOnSelect={true}
        isDisabled={!loadedAllAddressInfo}
        label='Country'
        name='addressInfo.countryId'
        options={allCountries}
        valueField='id'
        labelField='name'
      />
    </>
  );
};
