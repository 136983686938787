import styled from 'styled-components';

import { choiceRem, halfChoiceRem } from './../../assets/ChoiceStyles';

import { BackgroundColor, BorderColor } from '../../styles';
import { Inactive } from '../../types';
interface BaseRadioProps extends Inactive {
  checked?: boolean;
}

function hoverColor({ inactive }: BaseRadioProps) {
  return inactive ? BorderColor.Base : BorderColor.CheckedFocus;
}

export const BaseRadio = styled.span<BaseRadioProps>`
  background-color: ${({ inactive }) =>
    inactive ? BackgroundColor.Inactive : BackgroundColor.BaseWhite};
  border-radius: 50%;
  border: 2px solid
    ${({ checked, inactive }) => (checked && !inactive ? BorderColor.Checked : BorderColor.Base)};
  height: ${choiceRem};
  left: 0;
  position: absolute;
  top: 0;
  width: ${choiceRem};

  &:after {
    background: ${({ inactive }) => (inactive ? BorderColor.Base : BorderColor.Checked)};
    border-radius: 50%;
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    height: ${halfChoiceRem};
    left: 3px;
    position: absolute;
    top: 3px;
    width: ${halfChoiceRem};
  }

  &:hover,
  input:focus + &,
  input:active + &,
  label:hover & {
    border-color: ${hoverColor};
    &:after {
      background: ${hoverColor};
    }
  }
`;
