import { Link } from 'react-router-dom';
import { memo } from 'react';

import {
  DropDownMenuItems,
  MemberType,
  OpsDropDownMenuItems,
  planMenus,
} from '../../utils/constants';
import { logOutFn, openHelpPage } from '../../utils/helpers';
import { DropdownMenu } from 'reactstrap';
import { Permission } from '../../types/auth';
import { useAuthContext } from 'src/auth/AuthProvider';

const HeaderDropDown = () => {
  const { auth } = useAuthContext();
  const permissions = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];

  const pointingTo =
    !auth?.memberType && auth?.userType && permissions.includes(Permission.Ops)
      ? '/ops'
      : auth?.memberType === MemberType.AgencyBuyer
      ? '/buyers'
      : '/suppliers';

  const logOut = () => {
    const isOps = auth && !auth?.memberType ? true : false;
    logOutFn(isOps);
  };

  const menuItems = DropDownMenuItems.map(item => {
    if (planMenus.includes(item.Title) && auth?.memberType === MemberType.BasicSupplier) {
      return {
        ...item,
        Url: '/suppliers/plans',
      };
    } else {
      return item;
    }
  });

  return (
    <DropdownMenu>
      {auth && auth?.memberType
        ? menuItems.map((item, index) =>
            item.showTo.includes(auth?.memberType) || item.showTo.includes('All') ? (
              <Link
                key={index}
                className='dropdown-item'
                to={item.showTo.includes('All') ? `${pointingTo}${item.Url}` : item.Url}
              >
                <i className={item.classNames} /> {item.Title}
              </Link>
            ) : null,
          )
        : OpsDropDownMenuItems.map((item, index) => (
            <a key={index} className='dropdown-item' href={item.Url}>
              <i className={item.classNames} /> {item.Title}
            </a>
          ))}
      {auth?.memberType ? (
        <>
          <span className='staticLink dropdown-item' onClick={() => openHelpPage(auth?.memberType)}>
            <i className='mdi mdi-help' />
            Help
          </span>
        </>
      ) : null}
      <span className='staticLink dropdown-item' onClick={logOut}>
        <i className={`mdi ${auth?.memberType ? 'mdi-logout' : ''}`} /> Log Out
      </span>
    </DropdownMenu>
  );
};

export default memo(HeaderDropDown);
