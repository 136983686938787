import { Cell } from 'react-table';

import { Assert } from '@demandstar/components/utils';
import { DSLink } from '@demandstar/components/link';

import { GenericFunction, NoOpFunction } from '../../../../types/shared';

import { buildRouteLink } from '../../../../utils/route';
import { DocumentDownloadLinkProps } from 'src/types';

/**
 * This type assumes a URL will be constructed as follows:
 * `${baseUrl}/${id}/${subUrl}?{queryString}`
 *   e.g. "suppliers/bids/6523465/details?actionId=87665"
 */
export type LinkTextCellProps<T> = DocumentDownloadLinkProps & {
  /** URL  */
  baseUrl?: string;
  /** URL sub path  */
  subUrl?: string;
  /** {object} field to render the unique id  */
  idField: string;
  /** {string} field to render the label  */
  labelField: string;
  /** optional additional click-handler */
  onClick?: NoOpFunction;
  /** optional getter to construct itemId */
  getItemId?: (item: T) => string;
  /** optional custom {string} label rendering {function} */
  renderLabelPrefix?: GenericFunction<string>;
  /** optional custom {string} title rendering {function} */
  renderLabelTitle?: GenericFunction<string>;
};

/**
 * This cell renders a `<DSLink>` element
 * with the `to` (href) constructed from the baseUrl and id
 * and the text and title from the label.
 * Alternately, if a `baseUrl` is not provided, the link uses
 * the void `onClick` handler without routing.
 * The curried function allows custom params to be sent along with
 * the field values from `react-table`.
 * @param initialProps
 */
export function LinkTextCell<T extends Record<string, unknown>>(
  initialProps: LinkTextCellProps<T>,
) {
  // `props` are typed as `any` from `react-table`
  return (props: Cell<T>) => {
    const {
      baseUrl,
      subUrl = '',
      onClick,
      idField,
      labelField,
      getItemId,
      renderLabelPrefix,
      renderLabelTitle,
    } = initialProps;
    const { row } = props;
    const { original } = row;
    const idValue = original[idField] as string | number | undefined;
    Assert(
      typeof idValue === 'string' || typeof idValue === 'number' || typeof idValue === 'undefined',
      'idValue should be string | number | undefined',
    );
    const itemId = getItemId ? getItemId(original) : '';

    // An empty route (determined by an empty `baseUrl` value)
    // will render text with an onCLick handler rather than an actual link.
    const route = baseUrl ? buildRouteLink({ baseUrl, id: idValue, subUrl, itemId }) : '';

    const labelPrefix = renderLabelPrefix ? renderLabelPrefix(original) : '';
    const label = original[labelField] || '';
    const labelDisplay = labelPrefix + label;
    const labelTitle = renderLabelTitle ? renderLabelTitle(original) : labelDisplay;

    /**
     * Optional click handler for functionality beyond routing,
     * e.g. setting app state.
     * Passes the `original` row data object back to the callback.
     */
    const handleLinkClick = () => {
      if (onClick) {
        onClick(original);
      }
    };

    // Switch props passed to DSLink by href vs onClick values.
    return route ? (
      <DSLink to={route} title={labelTitle}>
        {labelDisplay}
      </DSLink>
    ) : (
      <DSLink onClick={handleLinkClick} title={labelTitle}>
        {labelDisplay}
      </DSLink>
    );
  };
}
