import { Dayjs } from 'dayjs';

import { Assert } from '@demandstar/components/utils';
import { TermUnit } from './contract-management.d';

const durationValidation = /P[1-9][\d]*[DWMY]/;

export const defaultMaximumContractCount = 50;
export const defaultMaximumContractWarningPercentage = 0.8;
export const defaultMaximumContractWarningCount = Math.round(
  defaultMaximumContractCount * defaultMaximumContractWarningPercentage,
);

export function isValidContractDuration(duration?: string, allowEmpty?: boolean) {
  if (!duration) {
    return allowEmpty;
  }
  return durationValidation.test(duration);
}

export function addContractDuration(startDate: Dayjs, duration?: string) {
  Assert(duration, 'If our duration is valid, then it must exist');
  return startDate
    .add(getDurationValue(duration, 'D'), 'days')
    .add(getDurationValue(duration, 'W'), 'weeks')
    .add(getDurationValue(duration, 'M'), 'months')
    .add(getDurationValue(duration, 'Y'), 'years');
}

function getDurationValue(
  duration: string,
  designator: 'Y' | 'M' | 'W' | 'D' | 'H' | 'S',
  time?: boolean,
) {
  // TODO: 21 July, 2022 - At this point, we are not using `time` anywhere in the code, but the lines below are
  // future-proofing duration. However, there is missing branch coverage in the tests because it's currently unreachable
  // so when time is added, let's also add tests.
  const timeIndex = duration.indexOf('T');
  const timeString = timeIndex > -1 ? duration.substring(timeIndex) : '';
  const regex = new RegExp(`[\\d]*${designator}`);
  const matches = time ? timeString.match(regex) : duration.match(regex);
  if (matches?.length) {
    return parseInt(matches[0]);
  } else {
    return 0;
  }
}

/** @example
 * getTermArray('P1W'); // [1, 'W']
 */
export function getTermArray(duration?: string): [number, TermUnit] {
  if (!duration) {
    return [0, TermUnit.Days];
  }

  const length = duration.replace(/[^\d]/g, '');
  const unit = duration.replace(/[^DWMY]/g, '');
  Assert(unit.length === 1, 'We should have exactly one value for unit');
  return [Number(length), unit as TermUnit];
}
