import { commonLabels } from 'src/shared/constants';

export const cancel = commonLabels.cancel;
export const confirmDelete = 'Are you sure you want to delete this reminder?';
export const confirmDeleteTitle = 'Delete Reminder';
export const contract = 'Associated Contract';
export const deleteReminder = 'Delete Reminder';
export const dismiss = 'Dismiss Reminder';
export const edit = commonLabels.edit;
export const remindOn = 'Remind on:';
export const save = commonLabels.save;
export const seeDetails = 'See reminder details';
export const sendAgain = 'Send Reminder Again';
export const sendNow = 'Send Reminder Now';
export const sentTo = 'Reminder sent to:';
/** Returns "You can send again ${sendIn}" */
export const sendAgainIn = (sendIn: string) => `You can send again ${sendIn}`;
export const yourself = 'yourself';
