import { DataTestId, EmptyMessage, Selected, SVGIcon } from '../types';
import {
  ToggleIconWrapper,
  TreeContainer,
  TreeItemLink,
  TreeItemWrapper,
  TreeTitle,
  TreeWrapper,
} from './DSTree.styles';

import { commonText } from '../constants';
import { DSTreeNode } from './DSTree.d';
import { iconSuffix } from '../button/constants';

export const defaultTreeNode: DSTreeNode = {
  id: '',
  link: '',
  label: '',
};

export interface DSTreeProps extends DataTestId, EmptyMessage, SVGIcon {
  /** base {string} url for link construction */
  baseUrl: string;
  /** Callback to construct link URI for deep link/seo URL display */
  buildLink: (baseUrl: string, link: string, parentId?: string, selected?: boolean) => string;
  /** {Record<string, string>} SEO IDs currently selected (based upon route paramters) */
  openNodes?: Record<string, string>;
  /** optional {string} title to be displayed above the tree */
  title?: string;
  /** Array of {DSTreeNode} items to be rendered */
  treeData: DSTreeNode[];
}

interface RenderTreeProps extends Selected {
  /** Array of {DSTreeNode} items to be rendered */
  data: DSTreeNode[];
  /** {string} parentId for link construction */
  parentId: string;
}
interface RenderItemProps extends Selected {
  /** {DSTreeNode} item to be rendered */
  item: DSTreeNode;
  /** optional {string} parentId for link construction */
  parentId?: string;
}

/**
 * Base DSTree component:
 * @description - This component wraps expandable/collapsible nested item lists by label click
 *              - `DSTreeCardHeader` and `DSTreeCardContent` components can be styled or configured in `DSTreeProps`
 * @param {DSTreeProps} props: data of items to be rendered
 * @example -       <DSTree
                      buildLink={buildLinkURI}
                      data={formattedTreeCodes}
                      icon={ToggleExpandIcon}
                      openNodes={openNodes}
                      title={texts.commodityCodeTreeTitle}
                    />
 * @returns DSTree component
 */

export function DSTree({
  baseUrl,
  buildLink,
  dataTestId = 'ds-tree',
  icon,
  emptyMessage = commonText.emptyMessage,
  openNodes = {},
  title = '',
  treeData,
}: DSTreeProps) {
  // Optional svg icon or style string for legacy impl.
  // TODO: assumed left abstract icon layout position.
  const iconClassName = typeof icon === 'string' ? `mr-2 mdi ${icon}` : '';
  const IconComponent = typeof icon === 'string' ? 'i' : icon;

  function renderItem({ item, parentId }: RenderItemProps) {
    const { label, link } = item;
    // check for id field to accommodate different types
    const id = item?.id;
    // check for items field to accommodate different types
    const items = item?.items;
    // Build a testId value for the icon by appending the `'-icon'` suffix to the link's dataTestId value.
    const iconDataTestId = `${dataTestId}-${id}-${iconSuffix}`;
    const iconTitle = `${title} (${iconSuffix})`;
    const selected = (Object.values(openNodes) || []).includes(link);
    const itemLinkUri = buildLink(baseUrl, link, parentId, selected);
    const titleDisplay = selected
      ? `${commonText.deselect} ${label}`
      : `${commonText.select} ${label}`;
    return (
      <TreeItemWrapper isChild={!!parentId} key={id}>
        <TreeItemLink to={itemLinkUri} title={titleDisplay} data-testid={`${dataTestId}-${id}`}>
          {IconComponent && (
            <ToggleIconWrapper selected={selected} title={`${titleDisplay}-${iconSuffix}`}>
              <IconComponent
                className={iconClassName}
                title={iconTitle}
                dataTestId={iconDataTestId}
              />
            </ToggleIconWrapper>
          )}
          {label}
        </TreeItemLink>

        {items && items.length > 0 && renderTree({ parentId: link || '', data: items, selected })}
      </TreeItemWrapper>
    );
  }

  function renderTree({ parentId, data, selected }: RenderTreeProps) {
    if (!data?.length) {
      return emptyMessage;
    }
    return (
      <TreeWrapper
        data-testid={`${dataTestId}${parentId ? `-${parentId}` : ''}-tree-wrapper`}
        selected={selected}
      >
        {
          // Pass this item's link as the parentId for each sub item
          data.map(item => renderItem({ item, parentId }))
        }
      </TreeWrapper>
    );
  }

  return (
    <TreeContainer data-testid={dataTestId}>
      {title && <TreeTitle data-testid={`${dataTestId}-title`}>{title}</TreeTitle>}
      {renderTree({ parentId: '', data: treeData, selected: true })}
    </TreeContainer>
  );
}
