import { Field, Formik } from 'formik';
import { memo } from 'react';

import renderCheckbox from '../../../common/controls/inputs/checkbox';
import SelfDeclarationControl from '../../../common/breadcrumb/accountinfo/selfdeclaration';

import { Buttons } from '../../../customcontrols';
import { loadingMsg } from '../../../../utils/texts';
import { NoResult } from '../../../customcontrols/index';
import { sharedTypes } from '../../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { hasPermission } from 'src/utils/helpers';
import { userPermissionKeys } from 'src/utils/constants';
import { useDSSelector } from 'src/store/reducers';
import { Authorization } from 'src/types';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes {
  memberId: number;
  GoBackToPage: () => void;
  key?: number;
  saveSelfDeclarationsResult?: any;
  resetAccountInfoState: any;
  shared: sharedTypes;
  pristine?: any;
  initialValues: any;
  onSubmit: any;
}

function SelfDeclaration(props: PropsTypes) {
  const {
    GoBackToPage,
    saveSelfDeclarationsResult,
    resetAccountInfoState,
    shared,
    pristine,
    initialValues,
    onSubmit,
  } = props;
  const { selfdeclaredattributes, internalLoader } = shared;
  const memberinfo = useDSSelector(state => state.memberinfo);
  const { prms } = memberinfo;
  const { auth } = useAuthContext();

  const { accountPermissions: authPerms, mainContact: isPrimaryContact = false } =
    auth || ({} as Authorization);
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact;

  useSetBreadcrumb({
    component: hasEditMemberPermission ? (
      <SelfDeclarationControl
        submit={() => document.getElementById('selfDeclSubmitBtn')?.click()}
        pristine={pristine}
        cancel={GoBackToPage}
      />
    ) : (
      ''
    ),
    page: 'Account Info',
    title: 'Self Declarations',
    altname: 'Self Declarations',
    name: 'Self Declarations',
    menuactive: '',
  });

  if (saveSelfDeclarationsResult) {
    //GoBackToPage()
    resetAccountInfoState({ saveSelfDeclarationsResult: undefined });
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {formikProps => {
        const {
          values: any,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValidating,
          validateForm,
          isValid,
          dirty,
        } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-md-5'>
                <h3 className='arrowWrapper'>Self Declared Attributes</h3>
                <div className='row'>
                  <div className='col-12'>
                    <p>
                      You are encouraged to self-declare any of the attributes that apply to your
                      business, as agencies may perform searches to actively seek out businesses
                      meeting this criteria.
                    </p>
                  </div>
                  <div className='col-12 col-md-6'>
                    {selfdeclaredattributes.length > 0 ? (
                      <>
                        {hasEditMemberPermission ? (
                          <>
                            {selfdeclaredattributes.map((item: any, index: any) => (
                              <Field
                                name={item.attributeType}
                                component={renderCheckbox}
                                title={item.attributeName}
                                classNames='class'
                                key={index}
                                checked={
                                  formikProps.values[`${item.attributeType}`] === true
                                    ? true
                                    : false
                                }
                                handleChange={setFieldValue}
                              />
                            ))}
                          </>
                        ) : (
                          <>
                            {selfdeclaredattributes.map((item: any, index: any) => (
                              <Field
                                name={item.attributeType}
                                component={renderCheckbox}
                                title={item.attributeName}
                                classNames='class'
                                key={index}
                                checked={
                                  formikProps.values[`${item.attributeType}`] === true
                                    ? true
                                    : false
                                }
                                handleChange={setFieldValue}
                                disabled
                              />
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <NoResult
                        message={
                          internalLoader ? loadingMsg : 'No Self Declared Attributes Available'
                        }
                      />
                    )}
                  </div>
                </div>
                {hasEditMemberPermission && (
                  <div className='d-block mt-3'>
                    <Buttons
                      text='Save Changes'
                      title='Save Changes'
                      type='submit'
                      classNames='bttn bttn-primary float-right'
                      id='selfDeclSubmitBtn'
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default memo(SelfDeclaration);
