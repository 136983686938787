import { useCallback, useMemo } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { addBidPlanholder, AddBidPlanholderPayload } from 'src/store/services';
import { planholdersQuerySelector, planholdersRequestIdState } from './planholders.recoil';
import { useRouter } from 'src/shared/hooks';

/** hook for accessing planholders from a single bidId. */
export function usePlanholders(id?: number) {
  const { routerParams } = useRouter();
  const bidId = id || (routerParams.bidId && Number(routerParams.bidId)) || undefined;

  const setPlanholdersRequestId = useSetRecoilState(planholdersRequestIdState(bidId));
  const planholdersLoadable = useRecoilValueLoadable(planholdersQuerySelector(bidId));
  const planholders = useMemo(() => {
    return planholdersLoadable.valueMaybe() || [];
  }, [planholdersLoadable]);

  /**
   * manually update planholders of the bidId passed to usePlanholders
   */
  const refreshPlanholders = useCallback(() => {
    setPlanholdersRequestId(requestId => requestId + 1);
  }, [setPlanholdersRequestId]);

  const addPlanholder = useCallback(
    async (planholder: AddBidPlanholderPayload) => {
      await addBidPlanholder(planholder);
      refreshPlanholders();
    },
    [refreshPlanholders],
  );

  return {
    planholders,
    planholdersLoadable,
    refreshPlanholders,
    addPlanholder,
  };
}
