import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import UserActivitiesList from './UserActivitiesList';
import ActivitiesSearch from './search';

import { activitiessearchTypes, activitiesTypes, memberinfoTypes } from '../../../types';
import { checkPaginationCallFn } from '../../../utils/helpers';
import { pageSize } from '../../../utils/constants';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useDSSelector } from '../../../store/reducers';
import { getActivitiesUsers } from '../../../store/actions';

interface propsTypes extends activitiessearchTypes {
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
}

function UserActivity(props: propsTypes) {
  const dispatch = useDispatch();
  const activities = useDSSelector(
    state => state.activities,
  );
  const memberinfo = useDSSelector(
    state => state.memberinfo,
  );
  const { tzfn } = memberinfo;
  const {
    activities_users,
    users_internalLoader,
    search_internalLoader,
    activitiesSearch,
    activities_users_search,
  } = activities;
  const { /* page,*/ result: userActivitiesList, /*size,*/ total } = activities_users;
  const { /* page,*/ result: userActivitiesList_search, /*size,*/ total: total_search } =
    activities_users_search;

  const totalCount = activitiesSearch ? total_search : total;

  useSetBreadcrumb({
    page: 'Activities',
    altname: 'UserActivity',
    title: 'UserActivity',
    name: 'UserActivity',
    menuactive: 'activities',
  });

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPage * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const onPagingClick = (value: number) => {
    if (value !== currentPage) {
      if (checkPaginationCallFn(value, listPerPages, userActivitiesList, total)) {
        dispatch(getActivitiesUsers({
          page: Math.ceil(value / listPerPages) + 1,
          size: 100,
          pagination: true,
        }));
      }
      setCurrentPage(Number(value));
    }
  };

  const finalUserActivitiesList = activitiesSearch ? userActivitiesList_search.slice(indexOfFirstLists, indexOfLastLists) || [] : userActivitiesList.slice(indexOfFirstLists, indexOfLastLists) || [];

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className='arrowWrapper'>User Activities ({totalCount})</h2>
        </div>
      </div>
      <div className='row'>
        <ActivitiesSearch {...props} pagefor='users' canSelectCategory={false} setCurrentPages={setCurrentPage} />
      </div>

      <UserActivitiesList
        InternalLoader={activitiesSearch ? search_internalLoader : users_internalLoader}
        loadermsg='No User Activities Available'
        userActivitiesList={activitiesSearch ? userActivitiesList_search : userActivitiesList}
        finalUserActivitiesList={finalUserActivitiesList}
        total={activitiesSearch ? total_search : total}
        currentPage={currentPage}
        onPagingClick={onPagingClick}
        listPerPages={listPerPages}
        tzfn={tzfn}
      />
    </>
  );
}

export default memo(UserActivity);
