import { useEffect } from 'react';

import { AwardWizardId } from '../../../../store/recoil/award-bid';
import { defaultNumericId } from '../../../../shared/constants';
import { RequiredDocumentsPanel } from './RequiredDocumentsPanel';
import { useAwardBidWizard } from '../../../../shared/hooks';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { useSetSelectedBidIdFromRoute } from '../../../../shared/hooks/useSelectedBidId';
import { useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';
import { WizardButtons } from '../../../customcontrols/wizard/WizardButtons';

export function ListRequiredDocuments() {
  /// Application state

  const { bidAwardees } = useBidAwardees();
  const { setSupplierMemberId } = useSupplierMemberId();

  /// Hooks
  const { saveAwardBidWizard, returnToBidSummary, invalidAwardWizard } = useAwardBidWizard();
  useSetSelectedBidIdFromRoute();

  // Auto-select the memberId of the first awardee. This is required for the requiredDocuments query.
  useEffect(() => {
    if (bidAwardees[0]) {
      setSupplierMemberId(bidAwardees[0].awardedToMemberId || defaultNumericId);
    }
  }, [bidAwardees, setSupplierMemberId]);

  return (
    <>
      <RequiredDocumentsPanel />

      <WizardButtons
        onPrevious={() => {
          saveAwardBidWizard(AwardWizardId.AddVendorDocs);
        }}
        onNext={() => {
          saveAwardBidWizard(AwardWizardId.AddPublicDocs);
        }}
        inactiveNext={invalidAwardWizard}
        inactivePrev={invalidAwardWizard}
        save={returnToBidSummary}
      ></WizardButtons>
    </>
  );
}
