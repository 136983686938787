/**
 * Navigation Buttons
 * Currently only used in WizardButtons
 */
export const nextPage = 'Continue';
export const previousPage = 'Go Back';
export const saveAndFinishLater = 'Save and Finish Later';

/**
 * Support navigation Link
 */

export const supportPageLink =
  'https://vendorsupport.gobonfire.com/hc/en-us/categories/12067759723031';
