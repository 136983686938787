export const paths = {
  navigation: {},
  api: {
    documents: {
      /** @returns '/documents/download/${documentId}' */
      download: (documentId: string) => `/documents/download/${documentId}`,
      /** @returns '/documents/${documentId}' */
      get: (documentId: string) => `/documents/${documentId}`,
      /** @returns '/documents?ids=${ids.join('&ids=')}' */
      getList: (ids: string[]) => `/documents?ids=${ids.join('&ids=')}`,
      /** @returns '/documents/${documentId}' */
      update: (documentId: string) => `/documents/${documentId}`,
      upload: '/documents/upload',
    },
  },
} as const;
