import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { MemberType } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { Buttons } from '../../../../customcontrols/index';
import NoResult from '../../../../customcontrols/noresult';
import { sharedTypes } from '../../../../../types/shared';
import { formattedPhoneNumber } from '../../../../../utils/helpers';
import { useDSSelector } from 'src/store/reducers';
import Rightsidebottom from 'src/components/supplier/dashboard/rightsidebottom';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { shouldAllowDirectDownload } from 'src/components/common/breadcrumb/common/bidSummaryControlsInner';
import { MemberTypeName } from 'src/types/dashboard';
import {
  bidInfoParamType,
  bidItemParamType,
  supplierItemFields,
} from '../../../../../types/biddetail';
import { useAuthContext } from 'src/auth/AuthProvider';

const tdWidth = {
  width: '180px',
};

const UpgradeSubscriptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  p:first {
    margin-top: 2rem;
  }
`;

interface PropsTypes {
  currentsupplier?: supplierItemFields;
  bidInfo: bidInfoParamType;
  removeBidPlanholderSupplier?: any;
  editBidPlanHolder?: any;
  setModelOpen?: any;
  changeAddSupplierActive?: any;
  pagefor?: string;
  currentData: supplierItemFields;
  shared?: sharedTypes;
  setBidSummaryDetails?: any;
}

function Supplierdetails(props: PropsTypes) {
  const {
    bidInfo,
    removeBidPlanholderSupplier,
    editBidPlanHolder,
    setModelOpen,
    changeAddSupplierActive,
    pagefor = 'planholders',
    currentData,
    shared,
    setBidSummaryDetails,
  } = props;
  const { PlanholderDetail_internalLoader = false } = shared || {};
  const {
    mi = '',
    supplierName = '',
    memberType: supplierType,
    fbi: forBidId,
    firstName = '',
    lastName = '',
    city = '',
    state = '',
    address1 = '',
    address2 = '',
    postalCode = '',
    selfDeclarations: declaredAttributes = '',
    uniqueRequests = '',
    agencyRequests = '',
    agencyAddedPlanholder = false,
    industries = '',
    fti = '',
    phone = '',
    email = '',
  } = currentData || {};

  const { memberId = 0, bidMemberId = 0, bidId = 0 } = bidInfo || {};

  const bidsSummary = useDSSelector(state => state.bidssummary);

  const { purchaseInfo, planHolders, results } = bidsSummary || {};
  const { isPurchased = false } = purchaseInfo || {};
  const { memberID = 0 } = (results as bidItemParamType) || {};

  const memberinfo = useDSSelector(state => state.memberinfo);
  const { mtn: memberTypeName = '' } = memberinfo || {};

  const { auth } = useAuthContext();
  const { bidAwardees } = useBidAwardees(bidId || undefined);
  const currentMemberIsAwardee = useMemo(() => {
    return !!bidAwardees.find(({ awardedToMemberId }) => awardedToMemberId === auth?.memberId);
  }, [auth, bidAwardees]);

  const planholderIds: number[] = planHolders?.ids || [];
  const allowDirectDownload = shouldAllowDirectDownload(
    planholderIds,
    memberTypeName as MemberTypeName,
    !!isPurchased,
    memberID,
    currentMemberIsAwardee,
  );

  const RemoveSupplier = () => {
    removeBidPlanholderSupplier({ supplierId: mi, bidId });
  };

  const editPlanholder = () => {
    editBidPlanHolder({ ...currentData, isEdit: true, editedMemberId: currentData.mi });
    setBidSummaryDetails({ addSupplierModalOpen: true, modalType: 'edit' });
    setModelOpen({ openType: true, modalType: 'edit' });
    changeAddSupplierActive({ addSupplierActive: 'add' });
  };

  return (
    <>
      <h4 data-testid='biddetails.planholders.tab.suppliersdetails.head'>Supplier Details</h4>
      {currentData && currentData.email ? (
        <div className='innerColIndent'>
          <Table borderless className='tableData'>
            <tbody data-testid='biddetails.planholders.tab.suppliersdetails.body'>
              <tr>
                <th style={tdWidth}>Supplier Name</th>
                <td>{supplierName}</td>
              </tr>
              {allowDirectDownload && (
                <>
                  <tr>
                    <th>Contact Name</th>
                    <td>
                      {firstName} {lastName}
                    </td>
                  </tr>
                  {address1 && city && state && postalCode && (
                    <tr>
                      <th>Address</th>
                      <td>
                        {address1} {address2}, {city}, {state} {postalCode}
                      </td>
                    </tr>
                  )}

                  {industries ? (
                    <tr>
                      <th>Industry</th>
                      <td>{industries}</td>
                    </tr>
                  ) : null}
                  {email ? (
                    <tr>
                      <th>Email</th>
                      <td>{email}</td>
                    </tr>
                  ) : null}
                  {phone ? (
                    <tr>
                      <th>Phone Number</th>
                      <td>{formattedPhoneNumber(phone)}</td>
                    </tr>
                  ) : null}
                  {declaredAttributes ? (
                    <tr>
                      <th>Self Declarations</th>
                      <td>{declaredAttributes}</td>
                    </tr>
                  ) : null}
                  {fti ? (
                    <tr>
                      <th>Federal Tax ID</th>
                      <td>{fti}</td>
                    </tr>
                  ) : null}
                </>
              )}
            </tbody>
          </Table>

          {!allowDirectDownload && (
            <UpgradeSubscriptionWrapper>
              <p>
                You could see supplier contact details and so much more by becoming a DemandStar
                subscriber!
              </p>
              <Rightsidebottom />
            </UpgradeSubscriptionWrapper>
          )}

          {memberId === bidMemberId &&
            (uniqueRequests === agencyRequests || agencyAddedPlanholder === true) && (
              <>
                {pagefor === 'planholders' && (
                  <Buttons
                    text='Remove'
                    title='Remove'
                    onClick={RemoveSupplier}
                    classNames='bttn bttn-secondary'
                  />
                )}
                {supplierType === MemberType.AgencyCreatedPlanholder &&
                  forBidId === bidId &&
                  pagefor === 'planholders' && (
                    <Buttons
                      text='Edit'
                      title='Edit'
                      onClick={editPlanholder}
                      classNames='bttn bttn-secondary float-right'
                    />
                  )}
              </>
            )}
        </div>
      ) : (
        <NoResult
          message={PlanholderDetail_internalLoader ? loadingMsg : 'No Supplier Details Available'}
        />
      )}
    </>
  );
}

export default memo(Supplierdetails);
