import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil';

import { DSSortProps, SortDirection } from '@demandstar/components/sort/DSSort';
import {
  SearchFilter,
  SearchFilters,
  SearchInputType,
  SearchInputWidth,
} from '@demandstar/components/search';
import { getBasicStringUrlHandler } from '@demandstar/components/utils';

import * as texts from './ContractSearch.texts';
import { allContractsState, allSuppliersState } from './ContractSearch.state';
import {
  AwardeeContact,
  ContractDetails,
  ContractSearchResult,
  ContractStatus,
} from '../../contract-management.d';
import { capitalize, displayDate, hasPermission } from 'src/utils/helpers';
import {
  defaultMaximumContractCount,
  defaultMaximumContractWarningCount,
} from '../../contract-management.helpers';

export type ContractFilterOptions = {
  bidId: string | number; // TODO: bidId on bidInfo component is sometimes a string
  searchText: string;
  supplier: AwardeeContact;
  status: ContractStatus;
  dateRange: any;
};

export function useContractSearch() {
  const allContractsLoadable = useRecoilValueLoadable(allContractsState);
  const allContracts = useMemo(
    () => allContractsLoadable.valueMaybe() || [],
    [allContractsLoadable],
  );
  const allContractsCount = useMemo(() => allContracts.length, [allContracts.length]);

  const contractLimitExceeded = useMemo(
    () => allContractsCount >= defaultMaximumContractCount,
    [allContractsCount],
  );
  const contractLimitWarningExceeded = useMemo(
    () =>
      allContractsCount >= defaultMaximumContractWarningCount &&
      allContractsCount < defaultMaximumContractCount,
    [allContractsCount],
  );

  const contractLimitOK = useMemo(
    () => !contractLimitWarningExceeded && !contractLimitExceeded,
    [contractLimitExceeded, contractLimitWarningExceeded],
  );

  const allSuppliersLoadable = useRecoilValueLoadable(allSuppliersState);

  const filters = useMemo(() => {
    const searchText: SearchFilter<string, ContractFilterOptions> = {
      inputType: SearchInputType.Text,
      label: texts.filters.search,
      name: 'searchText',
      getDisplayValue: (value?: string | undefined) => value,
      width: SearchInputWidth.Full,
      urlHandling: getBasicStringUrlHandler('q'),
    };

    const searchSupplier: SearchFilter<AwardeeContact, ContractFilterOptions> = {
      displayName: texts.filters.supplier,
      inputType: SearchInputType.Select,
      label: texts.filters.supplier,
      name: 'supplier',
      options: allSuppliersLoadable,
      optionLabel: 'name',
      getDisplayValue: value => value?.name,
      urlHandling: {
        key: 'sp',
        getValue: (val: string | null) =>
          allSuppliersLoadable?.valueMaybe()?.find(s => s.name === val),
        setValue: (supplier?: AwardeeContact | null) => supplier?.name,
      },
    };

    const statuses = Object.values(ContractStatus).map(status => ({
      label: capitalize(status.toString()),
      value: status,
    }));

    const searchStatus: SearchFilter<ContractStatus, ContractFilterOptions> = {
      inputType: SearchInputType.Select,
      label: texts.filters.status,
      name: 'status',
      options: statuses,
      getDisplayValue: value => {
        return value && capitalize(value?.toString());
      },
      urlHandling: {
        key: 'st',
        getValue: (val: string | null) => val as ContractStatus,
        setValue: (status?: ContractStatus | null) => status?.toString(),
      },
    };

    const searchDateRange: SearchFilter<any, ContractFilterOptions> = {
      displayName: texts.filters.dateRange.fieldLabel,
      inputType: SearchInputType.DateRange,
      label: texts.filters.dateRange.appliedFilterLabel,
      name: 'dateRange',
      getDisplayValue: (value?: any) =>
        `${displayDate(value && value[0])} - ${displayDate(value && value[1])}`,
      message: texts.filters.dateRange.message,
      urlHandling: {
        getValue: (url: URLSearchParams) => {
          const start = url.get('fr');
          const end = url.get('to');
          return [
            (start && dayjs(start)) || null,
            (end && dayjs(end)) || null,
          ] as any;
        },
        setValue: (url: URLSearchParams, range?: any) => {
          if (range && range[0]) url.set('fr', range[0].format('LL'));
          else url.delete('fr');

          if (range && range[1]) url.set('to', range[1].format('LL'));
          else url.delete('to');
        },
      },
      width: SearchInputWidth.Half,
    };

    return [
      searchText,
      searchSupplier,
      searchStatus,
      searchDateRange,
    ] as SearchFilters<ContractFilterOptions>;
  }, [allSuppliersLoadable]);

  const search = useCallback(
    ({
      bidId,
      dateRange,
      searchText,
      status,
      supplier,
    }: Partial<ContractFilterOptions>): ContractSearchResult[] => {
      const filterByText = (contract: ContractSearchResult) => {
        if (!searchText) {
          return true;
        }

        const searchTerm = searchText.toLowerCase();

        // check from shortest to longest field in hopes of overloading
        return (
          (contract.solicitationId && contract.solicitationId.toLowerCase().includes(searchTerm)) ||
          (contract.name && contract.name.toLowerCase().includes(searchTerm)) ||
          (contract.scopeOfWork && contract.scopeOfWork.toLowerCase().includes(searchTerm))
        );
      };

      const filterBySupplier = (contract: ContractSearchResult) => {
        if (!supplier?.id) {
          return true;
        }

        return contract.awardeeContacts?.find(aw => aw.name === supplier.name) ? true : false;
      };

      const filterByStatus = (contract: ContractSearchResult) => {
        if (!status) {
          return true;
        }

        return contract.status === status;
      };

      const filterByDateRange = (contract: ContractSearchResult) => {
        const startDate = dateRange && dateRange[0];
        const endDate = dateRange && dateRange[1];

        if (!startDate && !endDate) {
          return true;
        }

        // Right now doing a simple date range "overlaps" check
        if (
          //if the endDate of a contract is before the search's start date, return false;
          startDate &&
          contract.endDate.endOf('day').isBefore(startDate)
        ) {
          return false;
        }

        if (
          //if the startDate of a contract is after the search's end date, return false;
          endDate &&
          contract.startDate.startOf('day').isAfter(endDate)
        ) {
          return false;
        }

        return true;
      };

      const filterByBidId = (contract: ContractSearchResult) => {
        if (!bidId) {
          return true;
        } else {
          return contract.bidId === bidId;
        }
      };

      return allContracts
        .map(contract => contract)
        .filter(
          contract =>
            filterByText(contract) &&
            filterBySupplier(contract) &&
            filterByStatus(contract) &&
            filterByDateRange(contract) &&
            filterByBidId(contract),
        );
    },
    [allContracts],
  );

  /** pass a contract to keep contract search results up to date with latest data.
   * @param {ContractDetails} contractDetails
   */
  const updateSearchResults = useRecoilCallback(
    ({ set, snapshot }) =>
      async (contractDetails: ContractDetails) => {
        const allContracts = [...(await snapshot.getPromise(allContractsState))];
        const index = allContracts.findIndex(contract => {
          return contractDetails.id === contract.id;
        });
        if (index > -1) {
          allContracts[index] = contractDetails;
        } else {
          allContracts.push(contractDetails);
        }
        set(allContractsState, allContracts);
      },
    [],
  );

  const sort: DSSortProps<ContractSearchResult> = {
    sortOptions: [
      {
        label: texts.sortLabels.created,
        sortByKey: 'dtCreated',
        sortDirection: SortDirection.Descending,
      },
      {
        label: texts.sortLabels.start,
        sortByKey: 'startDate',
        sortDirection: SortDirection.Descending,
      },
      {
        label: texts.sortLabels.end,
        sortByKey: 'endDate',
        sortDirection: SortDirection.Descending,
      },
      {
        label: texts.sortLabels.name,
        sortByKey: 'name',
        sortDirection: SortDirection.Ascending,
      },
      {
        label: texts.sortLabels.id,
        sortByKey: 'solicitationId',
        sortDirection: SortDirection.Ascending,
      },
    ],
    showDirectionToggle: true,
  };

  return {
    allContracts,
    allContractsCount,
    allContractsLoadable,
    allSuppliersLoadable,
    contractLimitExceeded,
    contractLimitOK,
    contractLimitWarningExceeded,
    filters,
    search,
    sort,
    updateSearchResults,
  };
}
