import {
  cmButtonTexts,
  cmFormLabels,
} from 'src/features/contract-management/ContractManagement.texts';
import { commonLabels } from 'src/shared/constants';

export const addDocumentType = cmButtonTexts.addNewDocumentType;
export const cancel = commonLabels.cancel;
export const confirmDelete =
  'You will not be able to assign this document type to any new documents, but existing documents will be unaffected. Are you sure you would like to delete this document type?';
export const deleteDocumentType = commonLabels.delete;
export const documentType = cmFormLabels.DocumentType;
export const documentTypeDescription = 'Description';
export const documentTypeTitle = 'Title';
export const editDocumentType = commonLabels.edit;
export const newDocumentTypeDescription = cmFormLabels.NewDocumentDescription;
export const newDocumentTypeTitle = cmFormLabels.NewDocumentType;
export const saveDocumentType = commonLabels.save;
export const selectDocTypePlaceHolder = 'Select or Add Document Type';
