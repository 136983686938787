import { Table } from 'reactstrap';
import { useMemo } from 'react';

import { Flex, FlexContainer, H6 } from '@demandstar/components/styles';
import { Assert } from '@demandstar/components/utils';
import { DSButton } from '@demandstar/components/button';
import { fileUploadMaxSizeDisplay } from '@demandstar/components/constants/document';

import imgsCdn from '../../assets/images/imagescdn';

import { GroupedFileFormat } from '../../types/publications';
import { ModalPopUp } from './modals/ModalPopUp';
import { useAcceptedFileFormats } from 'src/store/recoil/accepted-files-formats/useAcceptedFileFormats';

import { commonLabels } from 'src/shared/constants';

const docFormats = imgsCdn.docFormats;

const ShowIMG = (group: GroupedFileFormat) => {
  const key = group.icon.substring(0, group.icon.indexOf('.')) as keyof typeof docFormats;
  const icon: string = docFormats[key];
  Assert(icon, 'We should have an icon for a given grouped file format');
  return <img width='30px' alt='logo' src={icon} className='d-inline-block' />;
};

type AcceptedFileFormatsModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const AcceptedFileFormatsModal = (props: AcceptedFileFormatsModalProps) => {
  const { acceptedFileFormats } = useAcceptedFileFormats();
  const { isOpen, closeModal } = props;

  const finalGroupedItems: GroupedFileFormat[] = useMemo(() => {
    const groupedFileFormats: GroupedFileFormat[] = [];

    acceptedFileFormats.forEach(format => {
      let parentProgram = format.parentProgram;
      const fileExt = format.docFormatType; // .JPEG
      if (!parentProgram) {
        const splitFormats = format.description.split('(*');
        parentProgram = splitFormats[0].trimEnd();
      }
      Assert(fileExt, 'We should have a fileExt');
      const parentIndex = groupedFileFormats.findIndex(group => group.title === parentProgram);
      if (parentIndex > -1) {
        groupedFileFormats[parentIndex] = {
          ...groupedFileFormats[parentIndex],
          formatItems: [...groupedFileFormats[parentIndex].formatItems, `*.${fileExt}`],
        };
      } else {
        groupedFileFormats.push({
          title: parentProgram,
          icon: format.icon,
          formatItems: [`*.${fileExt}`],
        });
      }
    });
    return groupedFileFormats.map(item => {
      return {
        ...item,
        formatItems: item.formatItems.sort((a, b) => a.localeCompare(b)),
      };
    });
  }, [acceptedFileFormats]);

  const acceptedFile1 = finalGroupedItems.slice(0, Math.ceil(finalGroupedItems.length / 3));
  const acceptedFile2 = finalGroupedItems.slice(
    Math.ceil(finalGroupedItems.length / 3),
    Math.ceil(finalGroupedItems.length / 3) * 2,
  );
  const acceptedFile3 = finalGroupedItems.slice(
    Math.ceil(finalGroupedItems.length / 3) * 2,
    finalGroupedItems.length,
  );

  const renderDataTable = (contentData: GroupedFileFormat[]) => {
    return (
      <Table className='tableHData w-auto float-left mb-0'>
        {contentData.map((item: GroupedFileFormat, index: number) => (
          <tr key={index}>
            <td>
              {ShowIMG(item)}
              <span className='flex1 ml-1'>
                {item.title} ({item.formatItems.join(', ')})
              </span>
            </td>
          </tr>
        ))}
      </Table>
    );
  };

  return (
    <ModalPopUp title='Accepted File Formats' size='xl' isOpen={isOpen} closeModal={closeModal}>
      <div className='noteImportant clearfix m-0'>
        <div className=' d-md-block d-lg-flex justify-content-between'>
          {finalGroupedItems.length > 0 && (
            <>
              {renderDataTable(acceptedFile1)}
              {renderDataTable(acceptedFile2)}
              {renderDataTable(acceptedFile3)}
            </>
          )}
        </div>
      </div>
      <div className='d-block my-4'>
        <FlexContainer>
          <Flex>
            <H6>Maximum file size: {fileUploadMaxSizeDisplay}MB </H6>
          </Flex>
          <Flex grow={0}>
            {' '}
            <DSButton title={commonLabels.close} onClick={closeModal}>
              {commonLabels.close}
            </DSButton>
          </Flex>
        </FlexContainer>
      </div>
    </ModalPopUp>
  );
};
