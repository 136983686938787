import { ContractWizardId, useContractWizard } from './useContractWizard';
import {
  WizardButtons,
  WizardButtonsProps,
} from '../../../../components/customcontrols/wizard/WizardButtons';

type ContractWizardButtonProps = Partial<Omit<WizardButtonsProps, 'save' | 'inactiveAll'>> & {
  nextWizardId?: ContractWizardId;
  previousWizardId?: ContractWizardId;
  onSave?: () => unknown | Promise<unknown>;
};

export function ContractWizardButtons(props: ContractWizardButtonProps) {
  const {
    onPrevious,
    onNext,
    inactiveNext,
    altNext,
    altPrev,
    inactivePrev,
    onInactiveClick,
    onSave,
    nextWizardId,
    previousWizardId,
  } = props;
  const { navigateContractWizard, saveAndFinishLater } = useContractWizard();

  function previous() {
    if (onPrevious) {
      onPrevious();
    }
    previousWizardId !== undefined && navigateContractWizard(previousWizardId);
  }

  async function next() {
    if (onSave) {
      const valid = await onSave();
      if (!valid) return;
    }
    if (onNext) {
      const wizardValid = await onNext();
      /* istanbul ignore else */
      if (!wizardValid) {
        return;
      }
    }
    /* istanbul ignore else */
    if (nextWizardId) {
      navigateContractWizard(nextWizardId);
    }
  }

  async function save() {
    if (onSave) {
      const valid = await onSave();
      if (!valid) return;
    }
    saveAndFinishLater();
  }

  return (
    <WizardButtons
      onPrevious={previous}
      onNext={next}
      save={save}
      inactiveNext={inactiveNext}
      altNext={altNext}
      altPrev={altPrev}
      inactivePrev={inactivePrev}
      onInactiveClick={onInactiveClick}
    />
  );
}
