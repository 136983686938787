import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';

import { DSLink } from '@demandstar/components/link';
import { DSTextField } from '@demandstar/components/fields';
import { Flex } from '@demandstar/components/styles';

import * as texts from './ManageDocumentTypes.texts';

import { ConfirmModal } from 'src/components/common/modals';
import { ContractDocumentType } from '../../../contract-management.d';
import { useContractDocuments } from '../useContractDocuments';

interface ManageDocumentTypeRowProps {
  documentType: ContractDocumentType;
}

export const ManageDocumentTypeRow = ({ documentType }: ManageDocumentTypeRowProps) => {
  const [editing, setEditing] = useState(false);
  const { deleteDocumentType, updateDocumentType } = useContractDocuments();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const methods = useForm();
  const { handleSubmit } = methods;

  const deleteType = async () => {
    setConfirmDeleteOpen(false);
    setEditing(false);
    /* istanbul ignore else */
    if (documentType) await deleteDocumentType(documentType);
  };

  const onSubmit = async (values: {
    addDocumentTypeDescription: string;
    addDocumentTypeTitle: string;
  }) => {
    await updateDocumentType({
      ...documentType,
      title: values.addDocumentTypeTitle,
      description: values.addDocumentTypeDescription,
      isDeleted: false,
    });
    setEditing(false);
  };

  const displayRow = () => {
    return (
      <>
        <Flex grow={1}>{documentType?.title}</Flex>
        <Flex grow={1}>{documentType?.description}</Flex>
        <Flex grow={0}>
          <DSLink onClick={() => setEditing(true)}>{texts.editDocumentType}</DSLink>
        </Flex>
        <Flex grow={0}>
          <DSLink type='error' onClick={() => setConfirmDeleteOpen(true)}>
            {texts.deleteDocumentType}
          </DSLink>
        </Flex>
        <ConfirmModal
          onConfirm={deleteType}
          closeModal={() => setConfirmDeleteOpen(false)}
          title='Confirm Delete'
          isOpen={confirmDeleteOpen}
          danger='delete'
        >
          {texts.confirmDelete}
        </ConfirmModal>
      </>
    );
  };

  const editRow = () => {
    return (
      <FormProvider {...methods}>
        <Flex grow={1}>
          <DSTextField
            defaultValue={documentType.title}
            label={texts.documentTypeTitle}
            name='addDocumentTypeTitle'
          />
        </Flex>
        <Flex grow={1}>
          <DSTextField
            defaultValue={documentType.description}
            label={texts.documentTypeDescription}
            name='addDocumentTypeDescription'
          />
        </Flex>
        <Flex grow={0}>
          <DSLink onClick={() => setEditing(false)}>{texts.cancel}</DSLink>
        </Flex>
        <Flex grow={0}>
          <DSLink onClick={handleSubmit(onSubmit)}>{texts.saveDocumentType}</DSLink>
        </Flex>
      </FormProvider>
    );
  };

  return editing ? editRow() : displayRow();
};
