import { atom, useRecoilCallback, useRecoilState } from 'recoil';
import { useCallback, useMemo } from 'react';

import {
  addCustomAwardNotification,
  getCustomAwardNotification,
  markBidAsRecommended,
} from '../../../store/services/awardBid';
import { Assert, isValidEmailInfo } from '../../../utils/helpers';
import {
  AwardWizardId,
  awardWizardIdState,
  getDefaultEmailInfo,
  messagePreviewState,
} from '../../../store/recoil/award-bid';
import { BidExternalStatusType, Pages } from '../../../utils/constants';
import { CustomMessageType, EmailInfo } from '../../../types/awardBid';
import {
  newUnselectedPlanholders,
  notifyAwardeesState,
  notifyColleaguesState,
  notifyUnselectedState,
} from '../../../store/recoil/award-bid';
import { useRequiredDocuments, useUpdateAwardeeAndNonAwardeeEmailInfo } from '.';
import { authState } from '../../../store/recoil/authState';
import { baseWizardBehavior } from '../../../components/customcontrols/wizard/wizard-helpers';
import { defaultNumericId } from '../../constants';
import { memberInfoSelector } from '../../../store/recoil/memberState';
import { notifyAwardeeTexts } from '../../../utils/texts/agency/awardBid';
import { useBid } from '../useBid';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { useIdFromRoute } from '../useIdFromRoute';
import { usePlanholders } from 'src/store/recoil/planholders/usePlanholders';
import { useRouter } from '../useRouter';
import { WizardStatus } from '../../../types/wizard';
import { useAuthContext } from 'src/auth/AuthProvider';

const submittingAwardState = atom<boolean>({
  key: 'submittingAwardState',
  default: false,
});

export function useAwardBidWizard() {
  const { addPlanholder } = usePlanholders();
  const { validRequiredDocs, saveRequiredDocs } = useRequiredDocuments();
  const updateAwardeeAndNonAwardeeEmailInfo = useUpdateAwardeeAndNonAwardeeEmailInfo();

  const [awardWizardId, setAwardWizardId] = useRecoilState(awardWizardIdState);
  const [messagePreview, setMessagePreview] = useRecoilState(messagePreviewState);

  const [submitting, setSubmitting] = useRecoilState(submittingAwardState);

  const { history } = useRouter();

  const { bidId } = useIdFromRoute();
  Assert(bidId, 'We must have a bidId from route if using `useAwardBidWizard`');

  const { bid } = useBid();
  const { refreshBid } = useBid();

  const { bidAwardees } = useBidAwardees(bidId);
  const hasAwardees = bidAwardees.length > 0;

  const invalidAwardWizard = useMemo(() => {
    return !validRequiredDocs;
  }, [validRequiredDocs]);
  const { auth } = useAuthContext();

  const awardWizardBehavior = useCallback(
    (wizardId: AwardWizardId, requiresAwardees?: boolean, allowEdit = true) => {
      return baseWizardBehavior(wizardId, awardWizardId, () => {
        const sufficientAwardees = !requiresAwardees || hasAwardees;
        const canModify =
          allowEdit ||
          bid?.bidExternalStatusType === BidExternalStatusType.RecommendationOfAward ||
          bid?.bidExternalStatusType === BidExternalStatusType.UnderEvaluation;
        if (sufficientAwardees && canModify) {
          return awardWizardId > wizardId ? WizardStatus.Completed : WizardStatus.Available;
        } else {
          return WizardStatus.Unavailable;
        }
      });
    },
    [awardWizardId, bid?.bidExternalStatusType, hasAwardees],
  );

  const selectAwardeeStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.SelectAwardees);
  }, [awardWizardBehavior]);

  const addVendorDocsStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.AddVendorDocs, true);
  }, [awardWizardBehavior]);

  const listRequiredDocsStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.ListRequiredDocs, true);
  }, [awardWizardBehavior]);

  const addPublicDocsStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.AddPublicDocs);
  }, [awardWizardBehavior]);

  const notifyAwardeeStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.NotifyAwardees, true, false);
  }, [awardWizardBehavior]);

  const notifyUnselectedStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.NotifyUnselected, true, false);
  }, [awardWizardBehavior]);

  const notifyInternalStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.NotifyColleagues, false, false);
  }, [awardWizardBehavior]);

  const finalizeAwardStatus = useMemo(() => {
    return awardWizardBehavior(AwardWizardId.FinalizeAward, false, false);
  }, [awardWizardBehavior]);

  const saveEmailInfo = useRecoilCallback(
    ({ set }) =>
      async (emailInfo: EmailInfo, customMessageType: CustomMessageType) => {
        if (isValidEmailInfo(emailInfo, customMessageType)) {
          await addCustomAwardNotification({
            bidId,
            customMessageType,
            ...emailInfo,
          });
          const response = await getCustomAwardNotification({
            bidId,
            customMessageType,
          });

          Assert(
            response.contactName && response.subject,
            'We expect to have a contact name from response at this point',
          );
          const emailState = {
            subject: response.subject || '',
            introduction: response.introduction || '',
            customMessage: response.customMessage || '',
            contactName: response.contactName || '',
            contactPhone: response.contactPhone || '',
            contactTitle: response.contactTitle || '',
            contactEmail: response.contactEmail || '',
            recipients: response.recipients.map(recipient => ({
              ...recipient,
              /** Emails should already be trimmed, but there's a chance we have stale data. */
              email: recipient.email.trim(),
            })),
            shouldNotify: response.shouldNotify,
          };

          switch (customMessageType) {
            case CustomMessageType.Awardees:
              set(notifyAwardeesState, emailState);
              break;
            case CustomMessageType.NonAwardees:
              set(notifyUnselectedState, emailState);
              break;
            case CustomMessageType.Colleagues:
              set(notifyColleaguesState, emailState);
              break;
            default:
              break;
          }
        }
      },
    [bidId],
  );

  const saveAwardBidWizard = useRecoilCallback(
    ({ set, snapshot }) =>
      async (navTo?: AwardWizardId, preview?: boolean) => {
        const currentWizardId = await snapshot.getPromise(awardWizardIdState);

        switch (currentWizardId) {
          case AwardWizardId.ListRequiredDocs:
            saveRequiredDocs();
            break;
          case AwardWizardId.NotifyAwardees:
            const awardeeEmailInfo = await snapshot.getPromise(notifyAwardeesState);
            saveEmailInfo(awardeeEmailInfo, CustomMessageType.Awardees);
            break;
          case AwardWizardId.NotifyUnselected:
            const unselectedEmailInfo = await snapshot.getPromise(notifyUnselectedState);
            const suppliers = await snapshot.getPromise(newUnselectedPlanholders);
            for (const supplier of suppliers) {
              if (supplier.name && supplier.email) {
                await addPlanholder({
                  ...supplier,
                  bidId,
                });
              }
            }
            set(newUnselectedPlanholders, []);
            await saveEmailInfo(unselectedEmailInfo, CustomMessageType.NonAwardees);
            updateAwardeeAndNonAwardeeEmailInfo();
            break;
          case AwardWizardId.NotifyColleagues:
            const colleagueEmailInfo = await snapshot.getPromise(notifyColleaguesState);
            saveEmailInfo(colleagueEmailInfo, CustomMessageType.Colleagues);
            break;
          default:
            break;
        }
        if (navTo || navTo === AwardWizardId.None) {
          set(awardWizardIdState, navTo);
          set(messagePreviewState, !!preview);
        }
        if (navTo === AwardWizardId.None) {
          history.push(`${Pages.buyerBids}/${bidId}`);
        }
      },
    [
      addPlanholder,
      bidId,
      history,
      saveEmailInfo,
      saveRequiredDocs,
      updateAwardeeAndNonAwardeeEmailInfo,
    ],
  );

  const refreshAwardBidWizard = useRecoilCallback(
    ({ set, snapshot }) =>
      async (bidId: number) => {
        // fix error in retaining snapshot values (https://recoiljs.org/docs/api-reference/core/Snapshot/#:~:text=Snapshots%20are%20only%20retained%20for,explicitly%20retained%20using%20retain()%20.)
        const release = snapshot.retain();

        try {
          const memberInfo = await snapshot.getPromise(memberInfoSelector);
          // const auth = await snapshot.getPromise(authState);

          Assert(auth?.fullName, 'fullName should be defined', 'useRefreshAwardBidWizard');
          Assert(memberInfo, 'memberInfo should be defined', 'useRefreshAwardBidWizard');
          if (bidId !== defaultNumericId && memberInfo?.mi) {
            // Email Info
            const awardeeEmailInfo = await getCustomAwardNotification({
              bidId,
              customMessageType: CustomMessageType.Awardees,
            });

            const safeAwardeeEmailInfo = getDefaultEmailInfo(
              awardeeEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.awardees,
              notifyAwardeeTexts.notificationDefaults.customMessage.awardees,
            );

            if (awardeeEmailInfo.contactName === null) {
              saveEmailInfo(safeAwardeeEmailInfo, CustomMessageType.Awardees);
            } else {
              set(notifyAwardeesState, safeAwardeeEmailInfo);
            }

            const unselectedEmailInfo = await getCustomAwardNotification({
              bidId,
              customMessageType: CustomMessageType.NonAwardees,
            });

            const safeUnselectedEmailInfo = getDefaultEmailInfo(
              unselectedEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.unselected,
              notifyAwardeeTexts.notificationDefaults.customMessage.unselected,
            );

            if (unselectedEmailInfo.contactName === null) {
              saveEmailInfo(safeUnselectedEmailInfo, CustomMessageType.NonAwardees);
            } else {
              set(notifyUnselectedState, safeUnselectedEmailInfo);
            }

            const colleagueEmailInfo = await getCustomAwardNotification({
              bidId,
              customMessageType: CustomMessageType.Colleagues,
            });

            const safeColleagueEmailInfo = getDefaultEmailInfo(
              colleagueEmailInfo,
              memberInfo,
              auth.fullName,
              notifyAwardeeTexts.notificationDefaults.subject.colleagues,
              notifyAwardeeTexts.notificationDefaults.customMessage.colleagues,
            );
            if (unselectedEmailInfo.contactName === null) {
              saveEmailInfo(safeColleagueEmailInfo, CustomMessageType.Colleagues);
            } else {
              set(notifyColleaguesState, safeColleagueEmailInfo);
            }
          }
        } finally {
          release();
        }
      },
    [saveEmailInfo, auth],
  );

  const returnToBidSummary = useCallback(() => {
    saveAwardBidWizard(AwardWizardId.None);
  }, [saveAwardBidWizard]);

  const recommendAward = useRecoilCallback(
    ({ set }) =>
      async () => {
        setSubmitting(true);
        await markBidAsRecommended(bidId);
        refreshBid();
        set(submittingAwardState, false);
        returnToBidSummary();
      },
    [bidId, refreshBid, returnToBidSummary, setSubmitting],
  );

  return {
    addPublicDocsStatus,
    addVendorDocsStatus,
    awardWizardId,
    finalizeAwardStatus,
    hasAwardees,
    invalidAwardWizard,
    listRequiredDocsStatus,
    messagePreview,
    notifyAwardeeStatus,
    notifyInternalStatus,
    notifyUnselectedStatus,
    recommendAward,
    refreshAwardBidWizard,
    returnToBidSummary,
    saveAwardBidWizard,
    selectAwardeeStatus,
    setAwardWizardId,
    setMessagePreview,
    setSubmitting,
    submitting,
  };
}
