import { cmButtonTexts, cmFormLabels, cmFormMessages } from '../../../ContractManagement.texts';

export const chooseFile = cmFormLabels.ChooseFile;
export const formLabels = {
  filename: cmFormLabels.Filename,
  contract: cmFormLabels.Contract,
  supplier: cmFormLabels.Supplier,
  public: cmFormLabels.Public,
};
export const formMessages = {
  filename: cmFormMessages.Filename,
  contract: cmFormMessages.Contract,
  supplier: cmFormMessages.Supplier,
};
export const saveAndAdd = cmButtonTexts.saveAndAdd;
export const saveDoc = cmButtonTexts.saveDoc;
export const uploading = 'Upload in progress...';
