import { Controller,get, RegisterOptions, useForm ,FieldError, useFormContext} from 'react-hook-form';
import { DSRadio, DSRadioProps, RadioValue } from '../../inputs';
import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import { Flex, FlexContainer } from '../..';

export type DSRadioGroupOptions = Omit<
  DSRadioProps<RadioValue>,
  'name' | 'onSelect' | 'checked'
> & {
  value: RadioValue;
};
export type DSRadioGroupFieldProps = FieldProps & {
  horizontal?: boolean;
  /** onSelect event callback */
  onSelect?: (value?: RadioValue) => void;
  /** values required for each radio input */
  options: DSRadioGroupOptions[];
  /** Validation rules, these are sent to the Controller */
  rules?: RegisterOptions;
};

/**
 * @description Radio Group for use with React Hook Form. Each input gets the same name and action
 * @example
 * const options = [
 *  { label: 'Black', value: '111' },
 *  { label: 'Purple', value: '593e87' },
 *  { label: 'Red', value: 'c7362d' },
 *  { label: 'Teal', value: '3395a4' },
 *  { label: 'White', value: 'fff' },
 * ];
 * <FormProvider {...methods}>
 *  <DSRadioGroupField name='color' options={options} onSelect={onSelectFunction} />;
 * </FormProvider>
 */
export const DSRadioGroupField = (props: DSRadioGroupFieldProps) => {
  const { horizontal, label, name, onSelect, optional, options, rules } = props;
  const methods = useFormContext() as any;


  const { clearErrors, control, formState: { errors }, watch } = methods;
  const error = get(errors, name);

  /** the value of the selected radio input */
  const value: RadioValue | undefined = watch(name);

  const onCheck = (value?: RadioValue) => {
    clearErrors(name);
    if (onSelect) onSelect(value);
  };

  return (
    <Controller
      control={control}
      defaultValue={value || null}
      name={name}
      render={({ field }) => (
        <FieldContainer {...getFieldContainerProps(props, error)}>
          <FlexContainer direction={horizontal ? 'row' : 'column'}>
            {options.map((option: DSRadioGroupOptions) => (
              <Flex key={String(option.value)}>
                <DSRadio
                  aria-labelledby={`${label}-id`}
                  checked={option.value === field.value}
                  inlineWithInput={horizontal}
                  id={option.id}
                  inactive={option.inactive}
                  label={option.label}
                  name={field.name}
                  onSelect={(value) => {
                    field.onChange(value);
                    onCheck(value);
                  }}
                  ref={field.ref}  
                   value={option.value}
                />
              </Flex>
            ))}
          </FlexContainer>
        </FieldContainer>
      )}
      rules={{
        required: !optional,
        ...rules,
      }}
    />
  );
};
