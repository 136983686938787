import { useCallback, useMemo } from 'react';

import { BackgroundColor, BorderColor } from '@demandstar/components/styles';
import { BaseSVGPath } from '@demandstar/components/icon';

import { MapItemPathProps } from '.';

export function MapItemPath({
  dataTestId,
  fill = BackgroundColor.BaseWhite,
  fillActive = BackgroundColor.BaseTeal,
  fillHover = BackgroundColor.Footer,
  fillInactive = BackgroundColor.Inactive,
  fillSelected = BackgroundColor.HighlightTeal,
  inactive,
  onClick,
  onMouseOver,
  pathData,
  selected,
  stroke = BorderColor.Inactive,
}: MapItemPathProps) {
  const fillColor = useMemo(() => {
    return selected ? fillSelected : fill;
  }, [fill, fillSelected, selected]);

  const handleClick = useCallback(
    e => {
      if (!inactive && onClick) onClick(e);
    },
    [inactive, onClick],
  );

  const handleMouseOver = useCallback(() => {
    if (onMouseOver) onMouseOver();
  }, [onMouseOver]);

  return (
    <BaseSVGPath
      d={pathData}
      data-testid={dataTestId}
      fill={fillColor}
      fillActive={fillActive}
      fillHover={fillHover}
      fillInactive={fillInactive}
      fillSelected={fillSelected}
      inactive={inactive}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      stroke={stroke}
      strokeMiterlimit='10'
      strokeWidth='.5'
    />
  );
}
