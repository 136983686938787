import { END, eventChannel } from 'redux-saga';
import { call, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  deprecatedGetDateTimeZoneConverted,
  formatPhoneNumber,
  generateKey,
  getTimeFromDate,
  getZoneFromDate,
  scrollToTop,
  setTimeToDate,
  setWizardStatus,
  toastFn,
} from '../../utils/helpers';
import history from '../../utils/history';
import { constants } from '../../utils/settings';
import * as actionTypes from '../actionTypes';
import { initiallinewires } from '../reducers/addquotes';
import * as Api from '../services/addquotes';
import * as bidApi from '../services/bids';
import * as quoteApi from '../services/quotes';
import * as sharedApi from '../services/shared';
import moment from 'moment-timezone';
import { quotespayloadTypes } from '../../types/quotes';
import { documentListTypes } from '../../types/limited';
import { userEvent } from '../../utils/constants';
import { SummaryType } from '../../types/quotedetailstypedef';

const addquotetoastID = '445db19f-9be9-4e0e-8115-93c543966c21';

function createUploader(files: any) {
  let emit: { (arg0: number): void; (input: unknown): void };
  const chan = eventChannel((emitter: any) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = (updata: { loaded: number; total: number }) => {
    const percentage = Math.round((updata.loaded * 100) / updata.total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const config = {
    onUploadProgress: uploadProgressCb,
    baseURL: constants.api.documentUrl,
  };
  const uploadPromise = Api.submitAddQuotesDocumentUpload(files, config);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan: any, id: any, list = []): any {
  while (true) {
    const progress = yield take(chan);
    const statelist = list.map((item: any, index) => {
      if (item.id === id) {
        item.progress = progress;
        if (progress < 100) {
          item.uploadprocess = 'started';
        }
        if (progress === 100) {
          item.uploadprocess = 'completed';
        }
      }
      return item;
    });
    yield put({
      type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
      payload: { documentslist: statelist },
    });
  }
}

function* watchsubmitAddQuotesInfo() {
  yield takeLatest(actionTypes.SUBMIT_ADD_QUOTES_INFO.TRIGGER, submitAddQuotesInfo);
}

function* submitAddQuotesInfo(action: { payload: { quotesId?: '' | undefined; data: any } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_INFO.REQUEST, meta: action.payload });
  try {
    const { quotesId = '', data: values = {} } = action.payload;
    const addquotes = yield select(state => state.addquotes);
    const { addquoteswizard, addquoteswizardpartial } = addquotes;
    const currentwizard =
      addquoteswizard.find((item: { status: string }) => item.status === 'current') || {};

    let contactStateId = '';
    if (values.contactStateId) {
      contactStateId = values.contactStateId.value
        ? values.contactStateId.value
        : values.contactStateId.id;
    }

    const finaldata = {
      quoteId: quotesId ? quotesId : 0,
      quoteTitle: values.quoteTitle && values.quoteTitle.trim(),
      quoteNo: values.quoteNo && values.quoteNo.trim(),
      quoteYear: values.quoteYear && values.quoteYear.value,
      dueDateTime: setTimeToDate(
        values.quotedueDate,
        `${values.quotedueTime.value} ${values.quotedueTimeZone.value}`,
      ),
      deliveryARO: values.deliveryARO,
      shipVia: values.shipVia,
      contactName: values.contactName && values.contactName.trim(),
      contactDepartment: values.contactDepartment && values.contactDepartment.trim(),
      contactAddress1: values.contactAddress1 && values.contactAddress1.trim(),
      contactAddress2: values.contactAddress2 && values.contactAddress2.trim(),
      contactCountryId: values.contactCountryId && values.contactCountryId.value,
      contactStateId: contactStateId,
      contactCity: values.contactCity && values.contactCity.trim(),
      contactPostalCode: values.contactPostalCode,
      contactPhone: formatPhoneNumber(values.contactPhone),
      contactEmail: values.contactEmail,
      shipName: values.shipName.trim(),
      shipDepartment: values.shipDepartment && values.shipDepartment.trim(),
      shipAddress1: values.shipAddress1 && values.shipAddress1.trim(),
      shipAddress2: values.shipAddress2 && values.shipAddress2.trim(),
      shipCountryId: values.shipCountryId && values.shipCountryId.value,
      shipStateId: values.shipStateId && values.shipStateId.value,
      shipCity: values.shipCity && values.shipCity.trim(),
      shipPostalCode: values.shipPostalCode,
      shipPhone: formatPhoneNumber(values.shipPhone),
    };
    if (values.sameascontactinformation) {
      finaldata.shipName = values.contactName && values.contactName.trim();
      finaldata.shipDepartment = values.contactDepartment && values.contactDepartment.trim();
      finaldata.shipAddress1 = values.contactAddress1 && values.contactAddress1.trim();
      finaldata.shipAddress2 = values.contactAddress2 && values.contactAddress2.trim();
      finaldata.shipCountryId = values.contactCountryId && values.contactCountryId.value;
      finaldata.shipStateId = contactStateId;
      finaldata.shipCity = values.contactCity && values.contactCity.trim();
      finaldata.shipPostalCode = values.contactPostalCode;
      finaldata.shipPhone = formatPhoneNumber(values.contactPhone);
    }
    const response = yield call(Api.submitAddQuotesInfo, finaldata);
    const data = response.data.result || {};
    if (data) {
      let newpayload;
      if (data.dup) {
        toastFn('error', 'Duplicate Quote Number found', addquotetoastID);
        yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_INFO.FAILURE });
      } else if (data.status) {
        if (addquoteswizardpartial) {
          newpayload = { addquoteswizardpartial: false, halfWayCancelAllow: true };
          history.goBack();
        } else {
          newpayload = {
            addquotesinfo: values,
            addquoteswizard: setWizardStatus(addquoteswizard, currentwizard.id + 1),
          };
        }
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { commodityAlert: true },
        });
        yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_INFO.SUCCESS, payload: newpayload });
        if (!addquoteswizardpartial && !quotesId) {
          const quoteIds = response.data.result.quoteId;
          yield put({
            type: actionTypes.SET_QUOTES_DETAILS.TRIGGER,
            payload: { quotesreload: true },
          });
          history.replace(`/buyers/quotes/${quoteIds}/creation`);
        }
        toastFn('success', 'Saved', addquotetoastID);
        // Tracking API call
        if (quotesId) {
          yield put({
            type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
            payload: { eventId: userEvent.UpdateQuoteInfo, parentId: quotesId, value: finaldata },
          });
        }
        if (addquoteswizardpartial) {
          yield put({
            type: actionTypes.SET_QUOTES_DETAILS.TRIGGER,
            payload: { halfWayCancel: false },
          });
        }
      } else {
        toastFn('error', 'Failed', addquotetoastID);
        yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_INFO.FAILURE });
      }
      scrollToTop();
    } else {
      toastFn('error', 'Failed', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_INFO.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addquotetoastID);
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddQuotesWire() {
  yield takeLatest(actionTypes.SUBMIT_ADD_QUOTES_WIRE.TRIGGER, submitAddQuotesWire);
}

function* submitAddQuotesWire(action: { payload: { quotesId?: '' | undefined; data: any } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_WIRE.REQUEST, meta: action.payload });
  try {
    const { quotesId = '', data: values = {} } = action.payload;
    const addquotes = yield select(state => state.addquotes);
    const { addquoteswizard, addquoteswizardpartial = false } = addquotes;
    const currentwizard =
      addquoteswizard.find((item: { status: string }) => item.status === 'current') || {};
    const linewire = [...values.linewires]
      .filter(item => item.description)
      .map((item, index) => ({
        rowNo: index + 1,
        description: item.description.trim(),
        quantity: Number(item.quantity),
        uom: item.uom.trim(),
      }));

    const finaldata = {
      quoteId: quotesId ? quotesId : 0,
      specifications: values.additionalspecifications.trim(),
      insuranceReqs: values.insuranceandadditional.trim(),
      termsandConditions: values.termsandconditions.trim(),
      lineItems: linewire,
    };

    const response = yield call(Api.submitAddQuotesWire, finaldata);
    const data = response.data.result;
    if (data && data.status) {
      let payload: any = {
        addquoteswire: { ...values },
        addquoteswizard: setWizardStatus(addquoteswizard, currentwizard.id + 1),
      };
      if (addquoteswizardpartial) {
        payload = { addquoteswizardpartial: false, halfWayCancel: false, halfWayCancelAllow: true };
      }
      const quoteSummary = yield select(state => state.quoteSummary);
      const { summary = {} } = quoteSummary;
      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: {
          summary: {
            ...summary,
            specifications: values.additionalspecifications.trim(),
            insuranceReqs: values.insuranceandadditional.trim(),
            termsandConditions: values.termsandconditions.trim(),
          },
        },
      });

      scrollToTop();
      toastFn('success', 'Saved', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_WIRE.SUCCESS, payload });
      if (addquoteswizardpartial) {
        history.goBack();
      }
      // Tracking API call
      if (quotesId) {
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: { eventId: userEvent.UpdateQuoteWire, parentId: quotesId, value: finaldata },
        });
      }
    } else {
      toastFn('error', 'Failed', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_WIRE.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addquotetoastID);
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_WIRE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddQuotesItems() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_ITEMS.TRIGGER, getAddQuotesItems);
}

function* getAddQuotesItems(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_ITEMS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddQuotesItems, action.payload);
    const payload = response.data.result;
    if (payload) {
      let list = payload;
      if (payload.length === 0) {
        list = initiallinewires;
      }
      yield put({ type: actionTypes.GET_ADD_QUOTES_ITEMS.SUCCESS, payload: list });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_ITEMS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddQuotesInfo() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_INFO.TRIGGER, getAddQuotesInfo);
}

function* getAddQuotesInfo(action: { payload: { summary: SummaryType } }): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_INFO.REQUEST, meta: action.payload });
  try {
    const { summary } = action.payload;
    const { commodities = [] } = summary;
    const payload = summary;
    const quotesId = summary.quoteId;
    if (payload) {
      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: { summary: payload },
      });
      const shared = yield select(state => state.shared);
      // eslint-disable-next-line prefer-const
      let { countrieslist, stateslist, fiscalYear } = shared;
      let fiscalYearlist = fiscalYear;
      if (fiscalYear.length === 0) {
        const response = yield call(sharedApi.getAddBidFiscalYear, { quoteId: quotesId });
        fiscalYearlist = response.data.result;
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { fiscalYear: fiscalYearlist },
        });
      }
      if (stateslist.length === 0) {
        const response = yield call(sharedApi.getStatesList, action.payload);
        const Statepayload = yield (response.data && response.data.result) || [];
        if (Statepayload.length) {
          stateslist = Statepayload.map((items: { name: string; title: string; id: number }) => ({
            ...items,
            label: items.name || items.title,
            value: items.id,
          }));
          yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { stateslist } });
        }
      }

      const quoteYear =
        fiscalYearlist.find((item: { value: any }) => item.value === payload.quoteYear) || '';
      if (!quoteYear) {
        fiscalYearlist = [
          ...fiscalYearlist,
          { label: payload.quoteYear, value: Number(payload.quoteYear) },
        ].sort((a, b) => a.value - b.value);
        yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { fiscalYear } });
      }

      const dueDateTime = deprecatedGetDateTimeZoneConverted(payload.dueDateTime, summary.tzfn);

      const addquotesinfo = {
        quoteYear: fiscalYearlist.find((item: { value: any }) => item.value === payload.quoteYear),
        quotedueDate: moment(dueDateTime),
        quotedueTime: getTimeFromDate(dueDateTime),
        quotedueTimeZone: getZoneFromDate(dueDateTime),
        contactName: payload.contactName && payload.contactName.trim(),
        quoteTitle: payload.quoteName && payload.quoteName.trim(),
        quoteNo: payload.quoteNumber && payload.quoteNumber.trim(),
        deliveryARO: payload.deliveryARO,
        shipVia: payload.shipVia && payload.shipVia.trim(),
        contactDepartment: payload.contactDepartment && payload.contactDepartment.trim(),
        contactAddress1: payload.contactAddress1 && payload.contactAddress1.trim(),
        contactAddress2: payload.contactAddress2 && payload.contactAddress2.trim(),
        contactCountryId:
          payload.contactCountryId &&
          countrieslist.find((item: { value: any }) => item.value === payload.contactCountryId),
        contactStateId:
          payload.contactStateId &&
          stateslist.find((item: { id: any }) => item.id === payload.contactStateId),
        contactCity: payload.contactCity && payload.contactCity.trim(),
        contactPostalCode: payload.contactPostalCode,
        contactPhone: payload.contactPhone,
        contactEmail: payload.contactEmail,
        shipName: payload.shipName && payload.shipName.trim(),
        shipDepartment: payload.shipDepartment && payload.shipDepartment.trim(),
        shipAddress1: payload.shipAddress1 && payload.shipAddress1.trim(),
        shipAddress2: payload.shipAddress2 && payload.shipAddress2.trim(),
        shipCountryId:
          payload.shipCountryId &&
          countrieslist.find((item: { value: any }) => item.value === payload.shipCountryId),
        shipStateId:
          payload.shipStateId &&
          stateslist.find((item: { id: any }) => item.id === payload.shipStateId),
        shipCity: payload.shipCity && payload.shipCity.trim(),
        shipPostalCode: payload.shipPostalCode,
        shipPhone: payload.shipPhone,
      };

      const commodityAlert = commodities.length === 0 ? true : false;

      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { commodityAlert: commodityAlert },
      });

      yield put({ type: actionTypes.GET_ADD_QUOTES_INFO.SUCCESS, payload: { addquotesinfo } });
    } else {
      yield put({ type: actionTypes.GET_ADD_QUOTES_INFO.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchaddQuoteUpdateMemberInfo() {
  yield takeLatest(actionTypes.ADD_QUOTE_UPDATE_MEMBER_INFO.TRIGGER, addQuoteUpdateMemberInfo);
}

function* addQuoteUpdateMemberInfo(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.ADD_QUOTE_UPDATE_MEMBER_INFO.REQUEST, meta: action.payload });
  try {
    const shared = yield select(state => state.shared);
    // eslint-disable-next-line prefer-const
    let { countrieslist, stateslist } = shared;
    const memberinfo = yield select(state => state && state.memberinfo);
    let addressvalue = memberinfo;
    if (!memberinfo.mi) {
      const response = yield call(bidApi.getMemberInfo, { isCurrentMember: true });
      addressvalue = response.data.result;
    }

    if (stateslist.length === 0) {
      const response = yield call(sharedApi.getStatesList, action.payload);
      const payload = yield (response.data && response.data.result) || [];
      if (payload.length) {
        stateslist = payload.map((items: { name: string; title: string; id: any }) => ({
          ...items,
          label: items.name || items.title,
          value: items.id,
        }));
        yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { stateslist } });
      }
    }
    const addquotes = yield select(state => state.addquotes);
    const auth = action.payload.auth;
    const { addquotesinfo } = addquotes;
    addquotesinfo.contactName = auth?.firstName + ' ' + auth?.lastName;
    addquotesinfo.contactEmail = auth?.email;
    addquotesinfo.contactDepartment = addressvalue.mn;
    addquotesinfo.contactAddress1 = addressvalue.a1;
    addquotesinfo.contactAddress2 = addressvalue.a2;
    addquotesinfo.contactCountryId =
      addressvalue.ci &&
      countrieslist.find((item: { value: any }) => item.value === addressvalue.ci);
    addquotesinfo.contactStateId =
      addressvalue.sti && stateslist.find((item: { id: any }) => item.id === addressvalue.sti);
    addquotesinfo.contactCity = addressvalue.ct;
    addquotesinfo.contactPostalCode = addressvalue.pc;
    addquotesinfo.contactPhone = addressvalue.p;

    yield put({ type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER, payload: { addquotesinfo } });
    yield put({ type: actionTypes.ADD_QUOTE_UPDATE_MEMBER_INFO.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.ADD_QUOTE_UPDATE_MEMBER_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addquotetoastID);
  }
}

function* watchgetAddQuotesWire() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_WIRE.TRIGGER, getAddQuotesWire);
}

function* getAddQuotesWire(action: { payload: { quoteId: string } }): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_WIRE.REQUEST, meta: action.payload });
  try {
    const quoteSummary = yield select(state => state.quoteSummary);
    const { summary = {} } = quoteSummary;
    const { quoteId = '' } = summary;
    let payload = summary;
    if (Number(quoteId) !== Number(action.payload.quoteId)) {
      const response = yield call(quoteApi.getQuoteSummary, action.payload);
      payload = response.data.result;
    }
    if (payload) {
      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: { summary: payload },
      });
      const addquoteswire = {
        additionalspecifications: payload.specifications ? payload.specifications.trim() : '',
        insuranceandadditional: payload.insuranceReqs ? payload.insuranceReqs.trim() : '',
        termsandconditions: payload.termsandConditions ? payload.termsandConditions.trim() : '',
      };
      const memberinfo = yield select(state => state.memberinfo);
      const { qintro = '', qins = '' } = memberinfo || {};
      if (!payload.insuranceReqs) {
        addquoteswire.insuranceandadditional = qins;
      }
      if (!payload.termsandConditions) {
        addquoteswire.termsandconditions = qintro;
      }
      yield put({ type: actionTypes.GET_ADD_QUOTES_WIRE.SUCCESS, payload: addquoteswire });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_WIRE.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addquotetoastID);
  }
}

function* watchsubmitAddQuotesDocumentUpload() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD.TRIGGER,
    submitAddQuotesDocumentUpload,
  );
}

function* submitAddQuotesDocumentUpload(action: { payload: { quoteId: number } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD.REQUEST, meta: action.payload });

  try {
    yield put({
      type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
      payload: { upload_progress: true },
    });

    const { quoteId } = action.payload;
    const addquotes = yield select(state => state.addquotes);
    const { documentslist } = addquotes;
    const responses = [];
    const doc = documentslist
      .map((item: any) => ({ ...item, quoteId }))
      .filter((item: { quoteDocId: string }) => item.quoteDocId === '');

    if (doc.length > 0) {
      yield put({
        type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
        payload: {
          documentslist: documentslist.map(
            (item: { quoteDocId: string; progress: number; uploadprocess: string }) => {
              if (item.quoteDocId === '') {
                item.progress = 0;
                item.uploadprocess = 'started';
              }
              return item;
            },
          ),
        },
      });
      for (const i in doc) {
        if ({}.hasOwnProperty.call(doc, i)) {
          const [uploadPromise, chan] = yield call(createUploader, doc[i]);
          yield fork(uploadProgressWatcher, chan, doc[i].id, documentslist);
          const uploadrest = yield call(() => uploadPromise);
          responses.push(uploadrest);
          if (uploadrest.data.result && uploadrest.data.result.quoteDocId) {
            const stateslist = documentslist.map(
              (item: { id: any; quoteDocId: any; uploadprocess: string }, index: any) => {
                if (item.id === doc[i].id) {
                  item.quoteDocId = uploadrest.data.result.quoteDocId;
                  item.uploadprocess = 'completed';
                }
                return item;
              },
            );
            yield put({
              type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
              payload: { documentslist: stateslist },
            });
          }
        }
      }
    }

    yield put({
      type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
      payload: { upload_progress: false, halfWayCancel: false },
    });
    const success = responses.filter(item => item.data && item.data.result.quoteDocId);

    if (success.length === doc.length) {
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD.SUCCESS });
      toastFn('success', 'Saved', addquotetoastID);
    } else {
      yield put({
        type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
        payload: { upload_progress: false },
      });
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD.FAILURE });
      toastFn('error', 'Failed', addquotetoastID);
    }
    yield put({
      type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
      payload: { upload_progress: false },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
      payload: { upload_progress: false },
    });
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_UPLOAD.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addquotetoastID);
  }
}

function* watchgetAddQuotesDocuments() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_DOCUMENTS.TRIGGER, getAddQuotesDocuments);
}

function* getAddQuotesDocuments(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getAddQuotesDocuments, action.payload);
    const payload = response.data.result;
    if (payload) {
      let documentslist = [
        {
          doctitle: '',
          docfile: '',
          uploadprocess: 'notstarted',
          quoteDocId: '',
          docext: '',
          progress: 0,
          quoteId: '',
          doctype: 'QD',
          id: generateKey('qdoc'),
          isExist: false,
        },
      ];
      if (payload.length > 0) {
        documentslist = payload.map((item: documentListTypes) => {
          return {
            ...item,
            doctitle: item.fileName.trim(),
            docfile: item.path,
            uploadprocess: 'notstarted',
            quoteDocId: item.quoteDocID,
            docext: '',
            progress: 0,
            quoteId: item.quoteID,
            doctype: item.docType,
            id: generateKey('qdoc'),
            isExist: true,
          };
        });
      }
      yield put({ type: actionTypes.GET_ADD_QUOTES_DOCUMENTS.SUCCESS, payload: documentslist });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddQuotesDocumentDelete() {
  yield takeLatest(
    actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.TRIGGER,
    submitAddQuotesDocumentDelete,
  );
}

function* submitAddQuotesDocumentDelete(action: { payload: { quoteDocId: number } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitAddQuotesDocumentDelete, action.payload);
    const payload = response.data.result;
    if (payload && payload.status) {
      const addquotes = yield select(state => state.addquotes);
      const { documentslist } = addquotes;
      const list = documentslist.filter(
        (item: { quoteDocId: any }) => item.quoteDocId !== action.payload.quoteDocId,
      );
      toastFn('success', 'Deleted', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.SUCCESS, payload: list });
      // Tracking API call
      if (action.payload.quoteDocId) {
        const finaldata = documentslist.filter(
          (item: { quoteDocId: any }) => item.quoteDocId === action.payload.quoteDocId,
        );
        finaldata.status = 'deleted';
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: {
            eventId: userEvent.UpdateQuoteDocument,
            parentId: finaldata.quoteId,
            value: finaldata,
          },
        });
      }
    } else {
      toastFn('error', 'Failed', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addquotetoastID);
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_DOCUMENT_DELETE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddQuotesMoveNext() {
  yield takeLatest(actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.TRIGGER, submitAddQuotesMoveNext);
}

function* submitAddQuotesMoveNext(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitAddQuotesMoveNext, action.payload);
    const payload = response.data.result;
    if (payload && payload.status) {
      const addquotes = yield select(state => state.addquotes);
      const { addquoteswizardpartial, addquoteswizard, wizardcurrentpage } = addquotes;
      const currentwizard =
        addquoteswizard.find((item: { status: string }) => item.status === 'current') || {};
      let payload: any = {
        addquoteswizard: setWizardStatus(addquoteswizard, currentwizard.id + 1),
      };
      if (addquoteswizardpartial) {
        payload = { addquoteswizardpartial: false, halfWayCancel: false };
        history.goBack();
      }
      const { quoteStep } = action.payload as any;
      if (Number(quoteStep) > Number(wizardcurrentpage)) {
        payload.wizardcurrentpage = quoteStep;
      }
      scrollToTop();
      toastFn('success', 'Saved', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.SUCCESS, payload });
    } else {
      toastFn('error', 'Failed', addquotetoastID);
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addquotetoastID);
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_MOVE_NEXT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddQuotesCompletedPage() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.TRIGGER, getAddQuotesCompletedPage);
}

function* getAddQuotesCompletedPage(action: { payload: { quoteId: number } }): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.REQUEST, meta: action.payload });
  try {
    const quoteSummary = yield select(state => state.quoteSummary);
    const { summary = {} } = quoteSummary;
    const { quoteId = '' } = summary;
    let payload = summary;
    if (Number(quoteId) !== Number(action.payload.quoteId)) {
      const response = yield call(quoteApi.getQuoteSummary, action.payload);
      payload = response.data.result;
    }
    yield put({ type: actionTypes.GET_ADD_QUOTES_INFO.TRIGGER, payload: { summary: payload } });
    if (payload && payload.qcs) {
      const addquotes = yield select(state => state.addquotes);
      const { addquoteswizard } = addquotes;
      const current =
        addquoteswizard.find((item: { name: string }) => item.name === payload.qcs) || {};
      const currentpage = current.id + 1;
      const data = {
        wizardcurrentpage: currentpage,
        addquoteswizard: setWizardStatus(addquoteswizard, currentpage),
      };
      yield put({ type: actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.SUCCESS, payload: data });
    } else {
      yield put({ type: actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_COMPLETED_PAGE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddQuoteCanComplete() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.TRIGGER, getAddQuoteCanComplete);
}

function* getAddQuoteCanComplete(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.REQUEST, meta: action.payload });

  try {
    const response = yield call(Api.getAddQuoteCanComplete, action.payload);
    if (response) {
      yield put({
        type: actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.SUCCESS,
        payload: response.data.result.status || false,
      });
    } else {
      yield put({ type: actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchsubmitAddQuoteComplete() {
  yield takeLatest(actionTypes.SUBMIT_ADD_QUOTES_COMPLETE.TRIGGER, submitAddQuoteComplete);
}

function* submitAddQuoteComplete(action: { payload: { pagefor: string; quoteId: any } }): any {
  yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_COMPLETE.REQUEST, meta: action.payload });

  try {
    const { pagefor = '', quoteId } = action.payload;
    const response = yield call(Api.submitAddQuoteComplete, { quoteId });
    const payload = response.data.result;
    if (payload && payload.status) {
      if (pagefor === 'summary') {
        yield put({ type: actionTypes.GET_ADD_QUOTES_CAN_COMPLETE.TRIGGER, payload: { quoteId } });
        yield put({ type: actionTypes.GET_QUOTE_SUMMARY.TRIGGER, payload: { quoteId } });
        yield put({
          type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES.TRIGGER,
          payload: { quoteId },
        });
        yield put({
          type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.TRIGGER,
          payload: { quoteId },
        });
      }
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_COMPLETE.SUCCESS });
      yield put({
        type: actionTypes.SET_ADD_QUOTES_DETAILS.TRIGGER,
        payload: { halfWayCancelAllow: true, halfWayCancel: false },
      });
      yield put({ type: actionTypes.SET_QUOTES_DETAILS.TRIGGER, payload: { quotesreload: true } });
      yield history.push(`/buyers/quotes/${quoteId}/details`);
      yield toastFn('success', 'Saved', addquotetoastID);
      yield delay(600);
      yield toastFn(
        '',
        'Your quote request has been queued for broadcast. Please check back shortly to view the Quote Details',
        addquotetoastID,
      );
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_QUOTES_COMPLETE.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', addquotetoastID);
    yield put({
      type: actionTypes.SUBMIT_ADD_QUOTES_COMPLETE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetAddQuoteNumbers() {
  yield takeLatest(actionTypes.GET_ADD_QUOTES_NUMBERS.TRIGGER, getAddQuoteNumbers);
}

function* getAddQuoteNumbers(action: { payload: { quoteNumber: string } }): any {
  yield put({ type: actionTypes.GET_ADD_QUOTES_NUMBERS.REQUEST, meta: action.payload });

  try {
    const { quoteNumber = '' } = action.payload;
    const response = yield call(Api.getAddQuoteNumbers, action.payload);
    if (response) {
      let list = response.data.result || [];
      if (quoteNumber) {
        list = response.data.result.filter(
          (item: { quoteNumber: string }) => item.quoteNumber !== quoteNumber,
        );
      }
      yield put({ type: actionTypes.GET_ADD_QUOTES_NUMBERS.SUCCESS, payload: list });
    } else {
      yield put({ type: actionTypes.GET_ADD_QUOTES_NUMBERS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADD_QUOTES_NUMBERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* addQuotesSaga() {
  yield fork(watchsubmitAddQuotesInfo);
  yield fork(watchsubmitAddQuotesWire);
  yield fork(watchgetAddQuotesItems);
  yield fork(watchgetAddQuotesInfo);
  yield fork(watchgetAddQuotesWire);
  yield fork(watchsubmitAddQuotesDocumentUpload);
  yield fork(watchgetAddQuotesDocuments);
  yield fork(watchsubmitAddQuotesDocumentDelete);
  yield fork(watchsubmitAddQuotesMoveNext);
  yield fork(watchgetAddQuotesCompletedPage);
  yield fork(watchgetAddQuoteCanComplete);
  yield fork(watchsubmitAddQuoteComplete);
  yield fork(watchgetAddQuoteNumbers);
  yield fork(watchaddQuoteUpdateMemberInfo);
}
