import { formatPrice, formattedPhoneNumber } from '../../../../utils/helpers';
import {
  PanelItemWrapper,
  PanelRow,
  PanelRowHeader,
  PanelRowValue,
} from '../../../../shared/styles';

import { ContactLabels } from '../../../../shared/constants';
import { Data } from '../../../../types/shared';
import { ResponseDetails } from 'src/store/recoil/bidResponseState';

export type ContactDetailsPanelProps = Data<ResponseDetails>;

export function ContactDetailsPanelView(props: ContactDetailsPanelProps) {
  const { data } = props;
  const {
    a1: address1,
    a2: address2,
    altTotalPrice,
    ct: city,
    contactName,
    ca: country,
    notes,
    st: state,
    p: phone,
    phoneNumber,
    phoneExtension,
    pc: postalCode,
    responseId,
    totalPrice,
  } = data;

  const phoneNumberDisplay = formattedPhoneNumber(phone || phoneNumber, phoneExtension);

  const priceDisplay = formatPrice(totalPrice, 2);
  const altPriceDisplay = formatPrice(altTotalPrice, 2);

  return (
    <>
      <PanelItemWrapper>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.contactName}</PanelRowHeader>
          <PanelRowValue title={contactName}>{contactName}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.address1}</PanelRowHeader>
          <PanelRowValue title={address1}>{address1}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.address2}</PanelRowHeader>
          <PanelRowValue title={address2}>{address2}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.city}</PanelRowHeader>
          <PanelRowValue title={city}>{city}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.postalCode}</PanelRowHeader>
          <PanelRowValue title={postalCode}>{postalCode}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.state}</PanelRowHeader>
          <PanelRowValue title={state}>{state}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.responseId}</PanelRowHeader>
          <PanelRowValue title={responseId}>{responseId}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.phoneNumber}</PanelRowHeader>
          <PanelRowValue title={phoneNumberDisplay}>{phoneNumberDisplay}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.countryName}</PanelRowHeader>
          <PanelRowValue title={country}>{country}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.totalPrice}</PanelRowHeader>
          <PanelRowValue title={priceDisplay}>{priceDisplay}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.altTotalPrice}</PanelRowHeader>
          <PanelRowValue title={altPriceDisplay}>{altPriceDisplay}</PanelRowValue>
        </PanelRow>
        <PanelRow>
          <PanelRowHeader>{ContactLabels.notes}</PanelRowHeader>
          <PanelRowValue title={notes}>{notes}</PanelRowValue>
        </PanelRow>
      </PanelItemWrapper>
    </>
  );
}
