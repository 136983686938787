import React from 'react';

import { DSPillContainer, DSPillIcon } from './DSPill.styles';
import { DataTestId } from '../types';

export enum DSPillType {
  Primary,
  Success,
  Warning,
  Error,
  Default,
}

type DSPillProps = {
  contrast?: boolean;
  icon?: string;
  iconLabel?: string;
  children: string;
  handleIconClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  showIcon?: boolean;
  showIconRight?: boolean;
  type?: DSPillType;
} & DataTestId;

/**
 * ##Shows a pill for status with an optional icon & action.
 *
 * ###Used in:
 * - Bids and Quotes pages tables, to indicate status
   - Contract Management tables of contracts as well as on Contract Details view, to indicate status
 *
 * ###Notes:
 * This has the same form factor as DSAppliedFilters, minus the icon `x`
 * @example <DSPill label='Test DSPill' showIcon={true} icon='mdi-close' iconLabel='clear' onIconClick={() => {}} />
 * @default{ icon = '', iconLabel = '', label = '', showIcon = false, showIconRight = false }
 * @param {string | undefined}  icon - class name for pill icon
 * @param {string | undefined}  iconLabel - title/aria-title for pill icon
 * @param {string}  label - text that will show on the pill
 * @param {function} onIconClick - function to be called if the pill icon is clicked
 * @param {boolean?} showIcon - whether to show the pill icon or not
 * @param {boolean?} showIconRight - whether to show the pill icon on the right, false = left
 * @param {string} status - 'success' | 'warning' | 'error' determines pill color, leave undefined for base styling
 */
export const DSPill: React.FunctionComponent<DSPillProps> = ({
  contrast = false,
  icon = '',
  iconLabel = '',
  children,
  handleIconClick,
  showIcon = false,
  showIconRight = false,
  type = DSPillType.Default,
  dataTestId,
}: DSPillProps) => {
  const iconOrder = showIconRight ? 2 : 0;

  return (
    <DSPillContainer type={type} contrast={contrast} data-testid={`${dataTestId}`}>
      {showIcon && (
        <DSPillIcon
          className={`mdi ${icon}`}
          title={iconLabel}
          aria-label={iconLabel}
          onClick={handleIconClick}
          order={iconOrder}
          data-testid={`${dataTestId}.icon`}
        />
      )}
      <span data-testid={`${dataTestId}.text`} title={children}>
        {children}
      </span>
    </DSPillContainer>
  );
};
