import styled from 'styled-components';

import { DataTestId, Inactive, OnClick, OnMouseOver, Selected, Title } from '../types';
import { ColorHex } from '../styles';

/**
 * Assume numeric px height and width values.
 * Convert to rems
 */
// TOREFACTOR - tighten width/height types, use style enum to ensure only accepted integer values
/** Since this is a styled svg, it should only take properties
 * that React SVGs understand.
 * We should remove DataTestId and use existing data-testid
 */

export type DSSVGIconProps = DataTestId &
  OnClick &
  Title & {
    color?: ColorHex | 'currentColor';
    height?: number;
    viewBox?: string;
    width?: number;
  };

/**
 * Base SVG tags
 */
export const BaseSVG = styled.svg.attrs<DSSVGIconProps>(
  ({ dataTestId, color, onClick, title }: DSSVGIconProps) => ({
    'data-testid': `${dataTestId}`,
    fill: `${color}`,
    onClick,
    title: title || '',
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
  }),
)`
  cursor: pointer;
`;

export type BaseSVGPathProps = Inactive &
  OnClick &
  OnMouseOver &
  Selected & {
    fill?: ColorHex;
    fillActive?: ColorHex;
    fillInactive?: ColorHex;
    fillHover?: ColorHex;
    fillSelected?: ColorHex;
    pathData?: string;
    stroke?: ColorHex;
  };

/**
 * Base SVG path tags
 */
export const BaseSVGPath = styled.path<BaseSVGPathProps>`
  cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
  fill: ${({ fill, fillInactive, inactive }) => (inactive ? fillInactive : fill)};
  stroke: ${({ stroke }) => stroke};
  &:hover {
    fill: ${({ fillHover, fillInactive, inactive }) => (inactive ? fillInactive : fillHover)};
  }
  &:active {
    fill: ${({ fillActive, fillInactive, inactive }) => (inactive ? fillInactive : fillActive)};
  }
  pointer-events: all;
`;
