import { Controller, get, RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import { AntDatePicker } from '../../inputs/date-picker/AntDatePickerConfig';
import { BaseDatePickerContainer } from '../../inputs/date-picker/DSDatePicker.styles';
import { Inactive } from '../../types';

export type DSDateFieldProps = FieldProps &
  Pick<PickerProps<Dayjs>, 'allowClear' | 'autoFocus' | 'disabledDate' | 'id'> &
  Inactive & {
    /** Default value needed for RHF's controller */
    defaultValue?: Dayjs;
    /** Rules in the format of RHF `RegisterOptions` */
    rules?: RegisterOptions;
    /** showTime */
    showTime?: boolean;
    /** timeZone
     * @warning MUST BE in ICANN format.
     * use `safeTimeZone()` if unsure.
     */
    timeZone?: string;
  };

/** Date field for use in React-Hook-Form, requires `<FormProvider>`
 * @example
 * <FormProvider {...methods}>
 *  <DSDateField name='birthDate' label='Birth Date' required>
 * </FormProvider>
 */
export const DSDateField = (props: DSDateFieldProps) => {
  const {
    allowClear,
    autoFocus,
    dataTestId,
    disabledDate,
    id,
    inactive,
    label,
    name,
    optional,
    rules,
    showTime,
    timeZone,
  } = props;
  const closedFormat = showTime ? 'LL LT' : 'LL';
  const [format, setFormat] = useState(closedFormat);

  const changeFormat = useCallback(
    (open: boolean) => {
      const openFormat = showTime ? 'l LT' : 'l';

      setFormat(open ? openFormat : closedFormat);
    },
    [closedFormat, showTime],
  );

  const methods = useFormContext() as any;
  const { control, errors } = methods;
  const error = get(errors || methods.formState.errors, name);
  const value: Dayjs | null | undefined = useWatch({ control, name });

  /**
   * Ideally, we would just pass 'LT' to the datepicker for easy international formatting.
   * However, the timepicker actually checks the format we pass for the presence of
   - h for hour
   - m for minute
   *
   * so if we pass 'LT', the user won't be able to select hours and minutes.
   * By extracting this value, we get the best of both worlds:
   * - Easy international formatting,
   * - and the dang thing actually works.
   * @returns 'h:mma' for America.
   */
  const localeTimeFormat = useMemo(() => {
    return dayjs().localeData().longDateFormat('LT');
  }, []);

  const registerOptions: RegisterOptions = {
    required: !optional,
    setValueAs: rules?.setValueAs || setValueAs,
    ...rules,
  };

  const showTimeFinal = showTime
    ? {
        hourStep: 1,
        minuteStep: 15,
        use12Hours: true,
        format: localeTimeFormat,
      }
    : false;

  function setValueAs(value: Dayjs | null) {
    /** if we have a timezone, we should send the time up with that time zone. */
    if (timeZone && value) {
      /**
       * * Important: This must be formatted like so in order to maintain the selected local time.
       * Without the `true` parameter, we would convert the date to the user's local time zone
       * (which may be different than their member time zone)
       * */
      return dayjs(value).tz(timeZone, true);
    }
    return value;
  }

  return (
    <FieldContainer {...getFieldContainerProps(props, error)}>
      <Controller
        control={control}
        defaultValue={value || null}
        name={name}
        rules={registerOptions}
        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
          <BaseDatePickerContainer error={error} inactive={inactive}>
            <AntDatePicker
              allowClear={allowClear ?? optional}
              aria-disabled={inactive}
              aria-label={label || name}
              autoFocus={autoFocus}
              data-testid={dataTestId || id || name}
              disabled={inactive}
              disabledDate={disabledDate}
              format={format}
              id={id || name}
              inputReadOnly={inactive}
              name={name}
              onChange={(date) => {
                onChange(date);
              }}
              onOpenChange={changeFormat}
              ref={ref}
              showTime={showTimeFinal as any}
              value={value}
            />
          </BaseDatePickerContainer>
        )}
      />
    </FieldContainer>
  );
};
