import { DSButton } from '@demandstar/components/button';

import { agencySelectionText } from './texts';
import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from 'src/components/common/modals';
import { SpacedButtonWrapper } from './styles';

interface MultipleAgencyConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const MultipleAgencyConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: MultipleAgencyConfirmationModalProps) => {
  return (
    <ModalPopUp
      size='md'
      title='Confirmation'
      closeModal={onCancel}
      isOpen={isOpen}
      backdrop='static'
    >
      <p>{agencySelectionText.oneAgencyPerMember}</p>
      <SpacedButtonWrapper justifyContent='space-between'>
        <DSButton type='secondary' title='No' onClick={onCancel}>
          {commonLabels.no}
        </DSButton>
        <DSButton title='Yes' onClick={onConfirm}>
          {commonLabels.yes}
        </DSButton>
      </SpacedButtonWrapper>
    </ModalPopUp>
  );
};
