import { Suspense, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { DSButton } from '@demandstar/components/button';
import {  PopUpFlexContainerMobile } from '@demandstar/components/styles';

import { AgencySelection } from '../agency-selection';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { ProductApiResponse } from 'src/types/products';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';
import { StyledRegistrationPage } from '../registration/Registration.styles';
import { useAmplitude } from '../../amplitude';
import { useRegistration } from 'src/shared/hooks/useRegistration';

/**
 * Page to select a free agency during registration
 */
export const SelectFreeAgency = () => {
  const { logEvent } = useAmplitude();
  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const recoilRegistrationData = useRecoilValue<RegistrationData>(recoilRegistrationDataState);
  const { saveIncompleteRegistration } = useRegistration();

  const selectAgency = useCallback(
    async (
      agency: ProductApiResponse | undefined,
      agencyData?: { agencyName: string; agencyState: string; agencyCounty: string },
    ) => {
      // Agency is not selected -> display confirmation modal
      if (!agency) {
        toggleConfirmationPopup(true);
        return;
      }

      logEvent('registration (supplier) - select agency', {
        Description: 'Select a free agency',
        'Free Agency': agency.productName,
        'State (Agency)': agencyData?.agencyState,
        'County (Agency)': agencyData?.agencyCounty,
      });

      // Update registration state & call addIncompleteRegistration
      const regData: RegistrationData = {
        ...recoilRegistrationData,
        freeAgency: agency?.productId,
        freeAgencyInfo: agencyData,
      };
      await saveIncompleteRegistration(registrationComponent.CommodityCode, regData);
    },
    [logEvent, recoilRegistrationData, saveIncompleteRegistration],
  );

  const confirmSkip = useCallback(async () => {
    logEvent('registration (supplier) - skip agency', {
      Description:
        'Clicks the button in the modal to confirm they want to skip adding a free agency',
    });

    // Clear free agency & call addIncompleteRegistration w/ the next page
    const regData: RegistrationData = {
      ...recoilRegistrationData,
      freeAgency: 0,
      freeAgencyInfo: undefined,
    };

    await saveIncompleteRegistration(registrationComponent.CommodityCode, regData);

    toggleConfirmationPopup(false);
  }, [logEvent, recoilRegistrationData, saveIncompleteRegistration]);

  return (
    <>
      <StyledRegistrationPage>
        <div className='col-12'>
          <div className='colWrapper'>
            <h2 className='arrowWrapper mobileArrowWrapper'>1 of 4: Choose your free agency</h2>
            <div className='row d-flex'>
              <div className='col-12 col-md-10 col-lg-8'>
                <Suspense fallback={<div>Agency Selection Loading..</div>}>
                  <AgencySelection
                    products={
                      recoilRegistrationData.freeAgency ? [recoilRegistrationData.freeAgency] : []
                    }
                    pageFor='registration'
                    moveToNextPage={selectAgency}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </StyledRegistrationPage>
      <ModalPopUp
        size='lg'
        title='Are you sure?'
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        <p>
          To receive proactive notifications from DemandStar, you must select an agency. Selecting a
          single agency on this step is free.
        </p>
        <p>
          Filter by State and County to see what’s available near you, then select an agency. The
          moment this agency posts a new bid, we’ll send you an email with the details.
        </p>
        <p>Make sure you never miss out on a government contract again!</p>

        <PopUpFlexContainerMobile justifyContent='space-between'>
          <DSButton type='secondary' onClick={confirmSkip}>
            Skip getting free notifications
          </DSButton>
          <DSButton
            onClick={() => {
              toggleConfirmationPopup(false);
            }}
          >
            Choose a free agency now
          </DSButton>
        </PopUpFlexContainerMobile>
      </ModalPopUp>
    </>
  );
};
