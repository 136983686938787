import { ForwardedRef, forwardRef } from 'react';

import { ChoiceLabel, ChoiceLabelProps } from '../../assets/ChoiceStyles';
import { DataTestId, Inactive } from '../../types';
import { BaseRadio } from './DSRadioStyled';
import { InvisibleInput } from '../../assets/CommonStyles';
import { onEnter } from '../../utils';

export type RadioValue = string | number | boolean;

export type DSRadioProps<Value> = Inactive &
  ChoiceLabelProps &
  DataTestId & {
    /** Indicate if the radio is selected */
    checked?: boolean;
    /** HTML id (must be unique) */
    id?: string;
    /** HTML label for the radio */
    label: string;
    /** HTML name */
    name: string;
    /** Custom behavior for the Select action */
    onSelect: (value?: Value) => void;
    /** Value of the input */
    value?: Value;
  };

/**
 * @description A single radio input with a label
 * @example <DSRadio name='acceptTerms' label='Accept Terms and Conditions' />
 */
export const DSRadio = forwardRef(
  (props: DSRadioProps<RadioValue>, ref: ForwardedRef<HTMLInputElement>) => {
    const { checked, dataTestId, id, inactive, inlineWithInput, label, name, onSelect, value } =
      props;

    function onClick() {
      if (!inactive) {
        onSelect(value);
      }
    }

    return (
      <ChoiceLabel inlineWithInput={inlineWithInput} inactive={inactive} data-testid={dataTestId}>
        {label}
        <InvisibleInput
          aria-checked={checked}
          checked={checked}
          id={id}
          name={name}
          onClick={onClick}
          onKeyDown={onEnter(onClick)}
          readOnly={true}
          ref={ref}
          type='radio'
        />
        <BaseRadio checked={checked} inactive={inactive} />
      </ChoiceLabel>
    );
  },
);
