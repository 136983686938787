import { useRefreshUpsellProducts, useUpsellProducts } from './useUpsellProducts';

import { UpsellCard } from './UpsellCard';

/**
 * @description a self-contained wrapper for the list of upsell products/cards based on selected/subscribed products
 * or member address
 * @returns JSX.Element, list of `UpsellCard`
 * @example <UpsellList />
 */
export const UpsellList = () => {
  useRefreshUpsellProducts();
  const { upsellProducts } = useUpsellProducts();

  return (
    <>
      {upsellProducts.map(product => {
        return (
          <UpsellCard
            key={product.product.productId}
            baseProductName={product.baseProductName}
            product={product.product}
            totalAgencies={product.totalAgencies}
            upsellType={product.upsellType}
          />
        );
      })}
    </>
  );
};
