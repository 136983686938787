import { atomFamily, selectorFamily } from 'recoil';

import { Assert } from '@demandstar/components/utils';

import { MemberInfoKey, MemberType } from 'src/types';
import { EBidResponse } from 'src/types/suppliers';
import { getEBidResponseList } from 'src/store/services';
import { memberInfoSelector } from '../memberState';

export const eBidResponsesRequestIdState = atomFamily({
  key: 'eBidResponsesRequestIdState',
  default: 0,
});

export const eBidResponsesQuerySelector = selectorFamily<EBidResponse[], number | undefined>({
  key: 'eBidResponsesQuerySelector',
  get:
    bidId =>
    async ({ get }) => {
      get(eBidResponsesRequestIdState(bidId)); // Add request ID as a dependency

      const memberInfo = get(memberInfoSelector);
      Assert(memberInfo, 'memberInfo should not be null', 'eBidResponsesQuerySelector');
      if (!memberInfo) {
        return [];
      }

      const isAgency = memberInfo[MemberInfoKey.MemberType] === MemberType.AgencyBuyer;
      const numericBidId = bidId || 0;
      Assert(
        numericBidId >= 0,
        'We should not have a bidId less than 0',
        'eBidResponsesQuerySelector',
      );

      /** bidId: 0 is valid for suppliers, but returns a 400 error for agencies. */
      if (isAgency && !numericBidId) {
        return [];
      }

      const response = await getEBidResponseList({
        bidId: numericBidId,
        memberId: memberInfo.mi,
      });
      return response;
    },
});
