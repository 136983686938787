import { DSSVGIcon, DSSVGIconProps } from './';

export const ToggleExpandIcon = ({
  color,
  dataTestId,
  height,
  onClick,
  title,
  viewBox = '0 0 24 24',
  width,
}: DSSVGIconProps) => (
  <DSSVGIcon
    color={color}
    dataTestId={dataTestId}
    height={height}
    onClick={onClick}
    title={title}
    viewBox={viewBox}
    width={width}
  >
    <path d='M24 24H0V0h24v24z' fill='none' />
    <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z' />
  </DSSVGIcon>
);
