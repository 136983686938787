export const datePickerYear = 30;

export const invalidDateMessage = 'Please enter a valid date.';
export const invalidGreaterDateMessage = 'End date must be greater than or equal to start date.';
export const invalidYearDateMessage ='Selected Dates in the range must be after the year 2000.';

export enum TimeZone {
  // United States Time Zones
  Samoa = 'Pacific/Pago_Pago', // -11
  Hawaii = 'Pacific/Honolulu', // -10
  Aleutian = 'America/Adak', // -10,  -9
  Alaska = 'America/Anchorage', //  -9,  -8
  Pacific = 'America/Los_Angeles', //  -8,  -7
  Arizona = 'America/Phoenix', //  -7
  Mountain = 'America/Denver', //  -7,  -6
  Central = 'America/Chicago', //  -6,  -5
  Eastern = 'America/New_York', //  -5,  -4

  //UTC
  UTC = 'Etc/UTC', // 0, 0

  // America
  Saskatchewan = 'America/Regina', // -6
  CentralAmerica = 'America/Belize', // -6
  MexicoCity = 'America/Mexico_City', // -6, -5
  Bogota = 'America/Bogota', // -5
  Caracas = 'America/Caracas', // -4
  Atlantic = 'America/Halifax', // -4, -3
  Santiago = 'America/Santiago', // -4, -3
  Newfoundland = 'America/St_Johns', // -3.5, -2.5
  SaoPaulo = 'America/Sao_Paulo', // -3
  BuenosAires = 'America/Argentina/Buenos_Aires', // -3
  Greenland = 'America/Nuuk', // -3 -2
  AtlanticIslands = 'America/Noronha', // -2

  // Pacific
  MarshallIslands = 'Pacific/Majuro', // +12

  // Atlantic
  Azores = 'Atlantic/Azores', // -1, +0
  CapeVerde = 'Atlantic/Cape_Verde', // -1

  // Africa
  Morocco = 'Africa/Casablanca', // +0, +1
  WestAfrica = 'Africa/Lagos', // +1
  CentralAfrica = 'Africa/Cairo', // +2
  EastAfrica = 'Africa/Nairobi', // +3

  // Europe
  WesternEurope = 'Europe/London', // +0, +1
  CentralEurope = 'Europe/Paris', // +1, +2
  EasternEurope = 'Europe/Sofia', // +2, +3
  Moscow = 'Europe/Moscow', // +3

  // Asia
  Jerusalem = 'Asia/Jerusalem', // +2, +3
  Baghdad = 'Asia/Baghdad', // +3
  Riyadh = 'Asia/Riyadh', // +3
}

/** DeprecatedFullTimeZone
 * This type is labeled Deprecated because *hopefully soon* it will be deprecated.
 * We want our time zones to be from the official ICANN time zones.
 *
 * For example: `America/Los_Angeles` (see TimeZone enum above).
 *
 * Typing this soon-to-be-deprecated concept will make it easier to ensure that our bases are covered
 * when we eventually make this change.
 */
export type DeprecatedFullTimeZone =
  | 'Hawaii'
  | 'Alaska'
  | 'Pacific Time (US & Canada); Tijuana'
  | 'Arizona'
  | 'Mountain Time (US & Canada)'
  | 'Central Time (US & Canada)'
  | 'Eastern Time (US & Canada)'
  | 'Indiana (East)'
  | 'Atlantic Time (Canada)'
  | 'Eniwetok, Kwajalein'
  | '-- International Zones --------------------------'
  | 'Midway Island, Samoa'
  | 'Mexico City'
  | 'Saskatchewan'
  | 'Central America'
  | 'Bogota, Lima, Quito'
  | 'Newfoundland'
  | 'Caracas, La Paz'
  | 'Brasila' // [sic]
  | 'Santiago'
  | 'Buenos Aires, Georgetown'
  | 'Greenland'
  | 'Mid-Atlantic'
  | 'Azores'
  | 'Cape Verde Is.'
  | 'Casablanca, Monrovia'
  | 'GMT: Dublin, Edinburgh, Lisbon, London'
  | 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  | 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  | 'Brussels, Copenhagen, Madrid, Paris'
  | 'Sarajevo, Skopje, Sofjia, Vilnus, Warsaw, Zagreb' // [sic]
  | 'West Central Africa'
  | 'Athens, Istanbul, Minsk'
  | 'Bucharest'
  | 'Cairo'
  | 'Harare, Pretoria'
  | 'Helsinki, Riga, Tallinn'
  | 'Jerusalem'
  | 'Baghdad'
  | 'Kuwait, Riyadh'
  | 'Moscow, St. Petersburgh, Volograd' // [sic]
  | 'Nairobi';

export const convertToICANN: { [key in DeprecatedFullTimeZone]: TimeZone } = {
  Hawaii: TimeZone.Hawaii,
  Alaska: TimeZone.Alaska,
  'Pacific Time (US & Canada); Tijuana': TimeZone.Pacific,
  Arizona: TimeZone.Arizona,
  'Mountain Time (US & Canada)': TimeZone.Mountain,
  'Central Time (US & Canada)': TimeZone.Central,
  'Eastern Time (US & Canada)': TimeZone.Eastern,
  'Indiana (East)': TimeZone.Eastern,
  'Atlantic Time (Canada)': TimeZone.Atlantic,
  'Eniwetok, Kwajalein': TimeZone.MarshallIslands,
  '-- International Zones --------------------------': TimeZone.UTC,
  'Midway Island, Samoa': TimeZone.Samoa,
  'Mexico City': TimeZone.MexicoCity,
  Saskatchewan: TimeZone.Saskatchewan,
  'Central America': TimeZone.CentralAmerica,
  'Bogota, Lima, Quito': TimeZone.Bogota,
  Newfoundland: TimeZone.Newfoundland,
  'Caracas, La Paz': TimeZone.Caracas,
  Santiago: TimeZone.Santiago,
  Brasila: TimeZone.SaoPaulo,
  'Buenos Aires, Georgetown': TimeZone.BuenosAires,
  Greenland: TimeZone.Greenland,
  'Mid-Atlantic': TimeZone.AtlanticIslands,
  Azores: TimeZone.Azores,
  'Cape Verde Is.': TimeZone.CapeVerde,
  // Note: Monrovia does not actually observe daylight savings
  'Casablanca, Monrovia': TimeZone.Morocco,
  'GMT: Dublin, Edinburgh, Lisbon, London': TimeZone.WesternEurope,
  'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna': TimeZone.CentralEurope,
  'Belgrade, Bratislava, Budapest, Ljubljana, Prague': TimeZone.CentralEurope,
  'Brussels, Copenhagen, Madrid, Paris': TimeZone.CentralEurope,
  // Note: Only Sofia and Vilnius are Eastern. The rest are central.
  'Sarajevo, Skopje, Sofjia, Vilnus, Warsaw, Zagreb': TimeZone.EasternEurope,
  'West Central Africa': TimeZone.WestAfrica,
  // Note: Athens actually does observe Daylight Savings
  'Athens, Istanbul, Minsk': TimeZone.Moscow,
  Bucharest: TimeZone.EasternEurope,
  Cairo: TimeZone.CentralAfrica,
  'Harare, Pretoria': TimeZone.CentralAfrica,
  'Helsinki, Riga, Tallinn': TimeZone.EasternEurope,
  Jerusalem: TimeZone.Jerusalem,
  Baghdad: TimeZone.Baghdad,
  'Kuwait, Riyadh': TimeZone.Riyadh,
  'Moscow, St. Petersburgh, Volograd': TimeZone.Moscow,
  Nairobi: TimeZone.EastAfrica,
};

// TOREFACTOR: This is not a timeZone list.
// This is an offset object and inaccurate for half the year.
export const timeZoneList: { [key in DeprecatedFullTimeZone]: number } = {
  Hawaii: -10, // TimeZone.Hawaii
  Alaska: -9, // TimeZone.Alaska
  'Pacific Time (US & Canada); Tijuana': -8, // TimeZone.Pacific
  Arizona: -7, // TimeZone.Arizona
  'Mountain Time (US & Canada)': -7, // TimeZone.Mountain
  'Central Time (US & Canada)': -6, // TimeZone.Central
  'Eastern Time (US & Canada)': -5, // TimeZone.Eastern
  'Indiana (East)': -5, // TimeZone.Eastern
  'Atlantic Time (Canada)': -4, // 'America/Halifax' (TimeZone.Atlantic)
  'Eniwetok, Kwajalein': -12,
  '-- International Zones --------------------------': 0,
  'Midway Island, Samoa': -11, // TimeZone.Samoa
  'Mexico City': -6, // 'America/Mexico_City' (TimeZone.MexicoCity)
  Saskatchewan: -6,
  'Central America': -6,
  'Bogota, Lima, Quito': -5,
  Newfoundland: -4.5, // 'America/St_Johns' (TimeZone.Newfoundland)
  'Caracas, La Paz': -4,
  Santiago: -4,
  Brasila: -3,
  'Buenos Aires, Georgetown': -3,
  Greenland: -3,
  'Mid-Atlantic': -2,
  Azores: -1,
  'Cape Verde Is.': -1,
  'Casablanca, Monrovia': 0,
  'GMT: Dublin, Edinburgh, Lisbon, London': 0,
  'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna': 1,
  'Belgrade, Bratislava, Budapest, Ljubljana, Prague': 1,
  'Brussels, Copenhagen, Madrid, Paris': 1,
  'Sarajevo, Skopje, Sofjia, Vilnus, Warsaw, Zagreb': 1,
  'West Central Africa': 1,
  'Athens, Istanbul, Minsk': 1,
  Bucharest: 2,
  Cairo: 2,
  'Harare, Pretoria': 2,
  'Helsinki, Riga, Tallinn': 2,
  Jerusalem: 2,
  Baghdad: 3,
  'Kuwait, Riyadh': 3,
  'Moscow, St. Petersburgh, Volograd': 3,
  Nairobi: 3,
};

export const urlDateFormat = 'MM-DD-YYYY';

export const usaDateFormat = 'MM/DD/YYYY';

export const usaDateTimeFormat = 'MM/DD/YYYY hh:mm A';

export const validDateFormats = [
  'M/D/YY',
  'MM/D/YY',
  'MM/DD/YY',
  'MM/DD/YYYY',
  'M/DD/YY',
  'M/DD/YYYY',
  'MM-DD-YYYY',
];
