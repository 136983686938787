import { useCallback, useEffect, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { AgencyByProductModal } from '../agency-by-product';
import { CheckoutErrorType } from './useCheckout';
import { DemandStarSupportEmail } from '../../../utils/texts/common/emails';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { registrationComponent } from '../../../utils/constants';
import { RegistrationErrorModal } from './RegistrationErrorModal';
import { ReviewOrderForm } from './ReviewOrderForm';
import { scrollToTop } from 'src/utils/helpers';
import { TollFreeNumber } from 'src/utils/texts/common';
import { useCart } from '../cart';
import { useRegistration } from 'src/shared/hooks/useRegistration';

/**
 * @description page for registration checkout
 * @returns JSX.Element
 *
 * @example <ReviewRegistration
 * setRegistrationData={setRegistrationData}
 * />
 */
export const ReviewRegistration = () => {
  const { registration: recoilRegistrationData, setCurrentComponent } = useRegistration();

  const [paymentErrorModal, setPaymentErrorModal] = useState<boolean>(false);
  const [registrationErrorModal, setRegistrationErrorModal] = useState(false);
  const [invalidFormError, setInvalidFormError] = useState(false);
  const { cartItems, cartTotal } = useCart();

  const proceed = useCallback(() => {
    setCurrentComponent(registrationComponent.AccountConfirmation);
  }, [setCurrentComponent]);

  const goBack = useCallback(() => {
    setCurrentComponent(registrationComponent.CompleteProfile);
  }, [setCurrentComponent]);

  const handleError = (errorType: CheckoutErrorType) => {
    switch (errorType) {
      case CheckoutErrorType.Payment:
        setPaymentErrorModal(true);
        break;
      case CheckoutErrorType.InvalidForm:
        setInvalidFormError(true);
        break;
      default:
        setRegistrationErrorModal(true);
        if (!cartItems?.length || !(cartTotal > 0)) {
          goBack();
        }
        break;
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div className='colWrapper container'>
            <div className='row'>
              <div className='col col-xl-8  col-sm-12 col-md-10'>
                <h2 className='arrowWrapper mobileArrowWrapper'>Review and Complete Order</h2>
                <ReviewOrderForm
                  defaultAddress={recoilRegistrationData?.memberAddress}
                  handleError={handleError}
                  previous={goBack}
                  next={proceed}
                  invalidFormError={invalidFormError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AgencyByProductModal />
      <ModalPopUp
        title='Payment Error'
        size='lg'
        isOpen={paymentErrorModal}
        closeModal={() => {
          setPaymentErrorModal(!paymentErrorModal);
          setCurrentComponent(registrationComponent.AccountConfirmation);
        }}
      >
        <p>
          We created your DemandStar account, but there was a problem with your credit card, and we
          weren&apos;t able to add your subscriptions.
        </p>
        <p></p>
        <p>
          Log in to your new account, click your name in the top right and select
          &quot;Subscriptions&quot; to add your subscriptions.
        </p>
        <p></p>
        <p>
          If your payment still does not process, please contact DemandStar at <br />
          {DemandStarSupportEmail} or {TollFreeNumber}.
        </p>
        <FlexContainer justifyContent='center'>
          <DSButton
            type='primary'
            onClick={() => {
              setPaymentErrorModal(!paymentErrorModal);
              setCurrentComponent(registrationComponent.AccountConfirmation);
            }}
          >
            Continue
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
      <RegistrationErrorModal
        showModal={registrationErrorModal}
        setShowModal={setRegistrationErrorModal}
      />
    </div>
  );
};
