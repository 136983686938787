import { useParams } from 'react-router-dom';

import { MatchParams } from '../../types';

export const useIdFromRoute = () => {
  // Get the values of dynamic parameters from the current URL.
  const routerParams = useParams<MatchParams>();

  const bidId = (routerParams.bidId && Number(routerParams.bidId)) || undefined;
  const contractId = routerParams.contractId;

  return { bidId, contractId };
};
