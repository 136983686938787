import moment from 'moment-timezone';

import {
  canadaZipCodeRegex,
  CountryId,
  integerVal,
  invalidDateMessage,
  phoneVal,
  usaDateFormat,
  usaZipCodeRegex,
} from '../../../utils/constants';
import { emailValidation } from '../../../utils/helpers';

export const validate = (values = {} as any) => {
  const keys = Object.keys(values);

  const errors = {} as any;

  const optional = [
    'address2',
    'altTotalPrice',
    'totalPrice',
    'phoneExtension',
    'faxNumber',
    'faxExtension',
    'notes',
    'county',
    'contact',
    'website',
    'doNotNotify',
    'planholderDisplay',
    'status',
    'memberId',
    'memberType',
    'planholderId',
    'bidAmtOptional',
  ];

  if (keys.indexOf('planholderId') > -1) {
    optional.push('address1', 'city', 'country', 'state', 'postalCode', 'phoneNumber');
  }

  if (keys.indexOf('pagefor') > -1) {
    optional.push('phoneNumber');
  }

  if (values.country && values.country.value === CountryId.Canada) {
    optional.push('metro');
  }

  const numbers = [] as any;
  const checknumber =
    Object.keys(values)
      .filter(items => numbers.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];
  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => {
        const returnValue = values[items];
        return {
          name: items,
          value: typeof returnValue === 'string' ? values[items].trim() : values[items],
        };
      }) || [];
  const errorarr = required.filter(items => !items.value) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));
  const errornumarr =
    checknumber.filter(items => items.value && !integerVal.test(items.value)) || [];
  errornumarr.forEach(items => (errors[items.name] = 'Invalid'));
  if (values.phoneNumber && !phoneVal.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid';
  }
  if (
    !optional.includes('phoneNumber') &&
    (values.phoneNumber === '' || values.phoneNumber === undefined)
  ) {
    errors.phoneNumber = 'Required';
  }
  if (!optional.includes('city') && (values.city === '' || values.city === undefined)) {
    errors.city = 'Required';
  }
  if (
    !optional.includes('postalCode') &&
    (values.postalCode === '' || values.postalCode === undefined)
  ) {
    errors.postalCode = 'Required';
  }
  if (!optional.includes('address1') && (values.address1 === '' || values.address1 === undefined)) {
    errors.address1 = 'Required';
  }

  if (values.faxNumber && !phoneVal.test(values.faxNumber)) {
    errors.faxNumber = 'Invalid';
  }
  if ((values.postalCode + '').trim().length <= 3 && keys.indexOf('planholderId') === -1) {
    errors.postalCode = 'Required';
  }
  if (
    keys.includes('totalPrice') &&
    values.bidAmtOptional === false &&
    !values.totalPrice &&
    values.totalPrice !== 0
  ) {
    errors.totalPrice = 'Required';
  }
  if (values.totalPrice) {
    if (values.totalPrice.length <= 0) {
      errors.totalPrice = 'Required';
    }
  }
  if (
    values.totalPrice &&
    !/^(0|[0-9]\d*)$/.test(values.totalPrice) &&
    !/^[-+]?[0-9]+\.[0-9]+$/.test(values.totalPrice)
  ) {
    const ItemPrice = values.totalPrice
      .toString()
      .replace(/ /g, '')
      .replace('$', '')
      .replace(/,/g, '');

    if (!/^(0|[0-9]\d*)$/.test(ItemPrice) && !/^[-+]?[0-9]+\.[0-9]+$/.test(ItemPrice)) {
      errors.totalPrice = 'Invalid';
    }
  }
  if (
    values.altTotalPrice &&
    !/^(0|[0-9]\d*)$/.test(values.altTotalPrice) &&
    !/^[-+]?[0-9]+\.[0-9]+$/.test(values.altTotalPrice)
  ) {
    const AltItemPrice = values.altTotalPrice
      .toString()
      .replace(/ /g, '')
      .replace('$', '')
      .replace(/,/g, '');
    if (!/^(0|[0-9]\d*)$/.test(AltItemPrice) && !/^[-+]?[0-9]+\.[0-9]+$/.test(AltItemPrice)) {
      errors.altTotalPrice = 'Invalid';
    }
  }

  if (
    values.country &&
    values.country.value === CountryId.UnitedStates &&
    values.postalCode &&
    !usaZipCodeRegex.test(values.postalCode)
  ) {
    errors.postalCode = 'Invalid';
  }
  if (
    values.country &&
    values.country.value === CountryId.Canada &&
    values.postalCode &&
    !canadaZipCodeRegex.test(values.postalCode.toUpperCase())
  ) {
    errors.postalCode = 'Invalid';
  }
  if (values.faxNumber && !integerVal.test(values.faxNumber)) {
    errors.faxNumber = 'Invalid';
  }
  if (values.faxExtension && !integerVal.test(values.faxExtension)) {
    errors.faxExtension = 'Invalid';
  }
  if (
    values.website &&
    !/^((http|https|HTTP|HTTPS):\/\/)?(WWW.|www.)?(?!.*(http|https|HTTP|HTTPS|WWW.|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      values.website.trimStart().trimEnd().toLowerCase(),
    )
  ) {
    errors.website = 'Invalid';
  }
  if (emailValidation(values.email)) {
    errors.email = 'Invalid';
  }
  if (values.buyerresponsedate && !moment(values.buyerresponsedate, usaDateFormat).isValid()) {
    errors.buyerresponsedate = invalidDateMessage;
  }
  return errors;
};
