import React from 'react';
import { Link } from 'react-router-dom';

import { bidstatParamType } from '../../../types/commoditycodes';

import { MemberType } from '../../../utils/constants';

interface DashboardSummaryListProps {
  memberType?: string;
  pagefor?: string;
  dataList: bidstatParamType[];
}

const DashboardSummaryList = (props: DashboardSummaryListProps) => {
  const { dataList = [], pagefor, memberType } = props;

  const pageType = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';

  return (
    <>
      {dataList.map(item => (
        <div key={item.bidId || item.quoteId} className='listGroupWrapper clearfix '>
          {pagefor === 'bids' ? (
            <Link className='text-truncate' to={`/${pageType}/bids/${item.bidId}/details`}>
              {item.bidName}
            </Link>
          ) : (
            <Link
              className='text-truncate'
              to={`/${pageType}/quotes/${item.quoteId}${pageType === 'buyers' ? '/details' : ''}`}
            >
              {item.quoteName}
            </Link>
          )}
          <p className='text-truncate'>{item.agency}</p>
        </div>
      ))}
    </>
  );
};

export default DashboardSummaryList;
