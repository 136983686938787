import { useRecoilState } from 'recoil';

import { defaultNumericId } from '../constants';
import { selectedBidAwardeeState } from '../../store/recoil/award-bid';

/**
 * Helper fn to save a step when reading/writing to `selectedBidAwardeeState`,
 * @returns
 */
export function useSelectedBidAwardee() {
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const awardedToMemberId = selectedBidAwardee?.awardedToMemberId || defaultNumericId;

  function resetSelectedBidAwardee() {
    setSelectedBidAwardee(null);
  }

  return {
    awardedToMemberId,
    resetSelectedBidAwardee,
    selectedBidAwardee,
    setSelectedBidAwardee,
  };
}
