import { countrieslist, CountryId } from '../constants';
import { getCountiesList, getStatesList, populateMetroDetails } from '../../store/services/shared';

export async function postalCodeLookup(postalcode: string) {
  try {
    const response = await populateMetroDetails({ zip: postalcode });

    if (response?.data.result.metroId || response?.data.result.countryId === CountryId.Canada) {
      // Find Selected State Product
      let selectedState: any = {};
      const stateResponse = await getStatesList();
      selectedState = stateResponse?.data.result.find(
        (item: { id: number }) => item.id === response.data.result.stateId,
      );

      // Find Selected County Product
      let selectedCounty: any = {};
      const countyResponse = await getCountiesList(response.data.result.stateId);
      if (countyResponse?.data.result.length) {
        const formattedCountyResponse = countyResponse.data.result.map(
          (items: { name: string; title: string; id: number }) => ({
            ...items,
            label: items.name || items.title,
            value: items.id,
          }),
        );
        selectedCounty = formattedCountyResponse?.find(
          (item: { id: number }) => item.id === response.data.result.countyId,
        );
      }

      // Find Selected Country Product
      const selectedCountry: any = countrieslist.find(
        item => item.value === response.data.result.countryId,
      );

      // Find Selected City Name
      const selectedCity = response.data.result.city;

      return { selectedCity, selectedCounty, selectedCountry, selectedState };
    }
  } catch (error) {
    console.log(error);
  }
}
