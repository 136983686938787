import { useQuery } from '@tanstack/react-query'; 
import { getDashboardNetwork } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetDashboardNetworkData = (data: any) => {
  return getDashboardNetwork(data);
};

export const useGetDashboardNetworkData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardNetworkData'],
    queryFn: () => GetDashboardNetworkData(data),
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
