import { Cell, Column } from 'react-table';
import { useCallback, useMemo } from 'react';

import { DSDollarField } from '@demandstar/components/fields';
import { DSLink } from '@demandstar/components/link';
import { DSTable } from '@demandstar/components/table';

import { Awardee, AwardeeDraft } from 'src/store/recoil/bid-awardees';

type AwardeeTableProps = {
  /** function that deletes awardee by memberId */
  deleteAwardee: (memberId: number) => void;
  /** memoized awardees (not directly from query selector) */
  awardees: AwardeeDraft[];
};

export function AwardeeTable({ deleteAwardee, awardees }: AwardeeTableProps) {
  const getIndexFromId = useCallback(
    (id: string) => {
      return awardees.findIndex(awardee => {
        return awardee.awardedToMemberId === Number(id);
      });
    },
    [awardees],
  );

  const awardeeColumns: Column<AwardeeDraft>[] = useMemo(() => {
    return [
      {
        accessor: 'supplierName',
        Header: 'Award To',
        minWidth: 240,
      },
      {
        accessor: 'email',
        Header: 'Email',
      },
      {
        accessor: 'amount',
        Header: 'Award Amount',
        maxWidth: 360,
        disableSortBy: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<Awardee>) => {
          const rowIndex = getIndexFromId(cellData.row.id);
          return (
            <DSDollarField
              autoFocus={rowIndex === awardees.length - 1}
              name={`awardees.${rowIndex}.amount`}
              allowNA
              optional
            />
          );
        },
      },
      {
        accessor: 'bidAwardId',
        Header: '',
        maxWidth: 60,
        disableSortBy: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Cell: (cellData: Cell<AwardeeDraft>) => {
          // const rowIndex = getIndexFromId(cellData.row.id);
          const awardee = cellData.row.original;
          return (
            <DSLink type={'error'} onClick={() => deleteAwardee(awardee.awardedToMemberId)}>
              Delete
            </DSLink>
          );
        },
      },
    ] as Column<AwardeeDraft>[];
  }, [getIndexFromId, awardees.length, deleteAwardee]);

  return (
    <DSTable
      data={awardees}
      columns={awardeeColumns}
      rowKey='awardedToMemberId'
      emptyMessage='No Awardees Selected'
    />
  );
}
