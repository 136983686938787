import React, { memo, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAuthContext } from 'src/auth/AuthProvider';
import * as actionCreators from '../../store/actions';
import { Footer } from '../layout';
import HeaderNoAuth from '../layout/headernoauth';

interface propsTypes extends PropsFromRedux {
  match: any;
  validateEmail: any;
}

const MailValidator = (props: propsTypes) => {
  const { validateEmail } = props;
  const { auth } = useAuthContext();
  const { validatedResult } = auth || {};

  const accountId = props.match.params.accountId;

  useEffect(() => {
    validateEmail({ accountId });
  }, [accountId, validateEmail]);

  return (
    <>
      <div className='mainNoWrapper'>
        <HeaderNoAuth />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='contentNoWrapper'>
                <div className='d-flex align-content-center justify-content-center'>
                  <div className='col-auto  p-5'>
                    {validatedResult && validatedResult.status === true ? (
                      <>
                        <h3>
                          <i className='mdi mdi-check-circle text-green200 mr-2' />
                          Email Validation Completed
                        </h3>
                        <p>
                          <b>
                            {validatedResult.firstName} {validatedResult.lastName}
                          </b>
                          ,<br />
                          You have successfully validated your email address to our system
                        </p>
                      </>
                    ) : (
                      <>
                        <h3>
                          <i className='mdi mdi-close-circle-outline text-red200 mr-2' /> Record Not
                          Found
                        </h3>
                        <p>We were unable to locate the record you wished to edit.</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({}),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(MailValidator));
