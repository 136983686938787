import { pluralize } from 'src/utils/helpers';

// Multi-Use
export const awarded = 'Awarded';
export const recommendationOfAward = 'Recommendation of Award';

// Copy (in order on page)
export const changeStatusIntro = "Change this bid's status to";
export const recommendationCopy = [
  (numAwardees: number, shouldNotify?: boolean): string =>
    `to ${
      shouldNotify
        ? `notify the presumptive ${pluralize(numAwardees, 'awardee', 'awardees')}, `
        : ''
    }send documents, and request documents you want from them.`,
  'Once you are ready to award this bid, return to this screen to formally award the contract.',
  'does not notify those who were not selected.',
] as const;
export const awardBidCopy = [
  (numAwardees: number, shouldNotify?: boolean): string =>
    `to ${
      shouldNotify ? `notify the ${pluralize(numAwardees, 'awardee', 'awardees')}, ` : ''
    }send documents, and request documents you want from them.`,
  'This will also notify all unselected bidders that they were not chosen.',
] as const;

// Buttons
export const award = 'Award';
export const awardBid = 'Award Bid';
export const recommendAward = 'Recommend Award';
