/* eslint-disable no-console */
import 'core-js/stable';
import 'regenerator-runtime/runtime';


import AuthProvider from './auth/AuthProvider';
import ErrorBoundary from './components/common/splash/errorboundary';
import Root from './containers/Root';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProvider } from './global-context/AppContext';
import { queryClient } from './queryClient';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log =
      console.error =
      console.info =
      console.debug =
      console.warn =
      console.trace =
      console.dir =
      console.dirxml =
      console.group =
      console.groupEnd =
      console.time =
      console.timeEnd =
      console.assert =
      console.profile =
        function () {
          // do not log in production
        };
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AuthProvider>
              <Root />
          </AuthProvider>
        </AppProvider>
        <ReactQueryDevtools initialIsOpen={false}  />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
