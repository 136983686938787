import { atom, selector } from 'recoil';

import { defaultNumericId } from '@demandstar/components/constants';

import { getBidCommodityCodeList, getBidWatcherList } from '../services/bids';
import { AddBidDocTypeOption } from '../../types/addbid';
import { BidCommodityCode } from '../../types/commodity';
import { BidId } from '../../types/shared';
import { BidWatcher } from '../../types/bidssummary';
import { getBidDocumentTypeList } from '../services/document';
import { track } from '../../utils/telemetry';

/** @deprecated use `const {bidId} from useIdFromRoute() */
export const selectedBidIdState = atom<number>({
  key: 'selectedBidIdState',
  default: defaultNumericId,
});

/**
 * Bid Watchers
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidWatcherListSelector = selector<BidWatcher[]>({
  key: 'bidWatcherListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    if (selectedBidId !== defaultNumericId) {
      const requestParams: BidId = { bidId: selectedBidId };
      try {
        // API call
        const response = await getBidWatcherList(requestParams);

        return response;
      } catch (error: any) {
        track(`bidWatcherListSelector -> getWatcherList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidWatcherListSelector -> getWatcherList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidWatcherList state with the associated API call.
 * TODO: Rewrite using requestId pattern.
 * This is only used in one other location, so it won't require much in the way of refactoring.
 * @description This is the state the UI will consume.
 */
export const bidWatcherListState = atom<BidWatcher[]>({
  key: 'bidWatcherListState',
  default: bidWatcherListSelector,
});

/**
 * Bid Commodity Codes
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidCommodityCodeListSelector = selector<BidCommodityCode[]>({
  key: 'bidCommodityCodeListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    const requestParams: BidId = { bidId: selectedBidId };
    if (selectedBidId !== defaultNumericId) {
      try {
        // API call
        const response = await getBidCommodityCodeList(requestParams);

        return response;
      } catch (error: any) {
        track(`bidCommodityCodeListSelector -> getCommodityCodeList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidCommodityCodeListSelector -> getCommodityCodeList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidCommodityCodeList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidCommodityCodeListState = atom<BidCommodityCode[]>({
  key: 'bidCommodityCodeListState',
  default: bidCommodityCodeListSelector,
});

/**
 * Bid Document Types
 * @description Make a service API call to populate a Recoil Selector.
 */
const bidDocumentTypeListSelector = selector<AddBidDocTypeOption[]>({
  key: 'bidDocumentTypeListSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);
    // Re-run this selector whenever the selectedBidId changes.
    const requestParams: BidId = { bidId: selectedBidId };
    if (selectedBidId !== defaultNumericId) {
      try {
        // API call
        const response = await getBidDocumentTypeList(requestParams);
        return response;
      } catch (error: any) {
        track(`bidDocumentTypeListSelector -> getDocumentTypeList(${selectedBidId}) ERROR:`, {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        console.error(`bidDocumentTypeListSelector -> getDocumentTypeList() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    }
    return [];
  },
});

/**
 * Initialize the bidDocumentTypeList state with the associated API call.
 * @description This is the state the UI will consume.
 */
export const bidDocumentTypeListState = atom<AddBidDocTypeOption[]>({
  key: 'bidDocumentTypeListState',
  default: bidDocumentTypeListSelector,
});
