import { DSSVGIcon, DSSVGIconProps } from './';
import React from 'react';

export const DSLogoIcon = ({
  color,
  dataTestId,
  height,
  onClick,
  viewBox = '0 0 24 24',
  width,
}: DSSVGIconProps) => (
  <DSSVGIcon
    height={height}
    onClick={onClick}
    viewBox={viewBox}
    width={width}
    dataTestId={dataTestId}
    title='DemandStar Logo'
  >
    <path
      d='M34.0051 21.9366C33.8188 21.9366 33.7134 21.8312 33.7134 21.6228V7.32432C33.7134 7.11585 33.818 7.01123 34.0051 7.01123H39.1833C42.6487 7.01123 44.8175 8.86764 44.8175 12.5209V16.4918C44.8175 20.1023 42.6472 21.9397 39.1833 21.9397L34.0051 21.9366ZM36.238 19.5762H39.1818C41.1864 19.5762 42.2089 18.5957 42.2089 16.5064V12.5011C42.2089 10.3522 41.1856 9.32965 39.1818 9.32965H36.2395L36.238 19.5762Z'
      fill={`${color}`}
    />
    <path
      d='M50.2868 19.6385H56.9825C57.194 19.6385 57.2971 19.7446 57.2971 19.9317V21.6224C57.3032 21.6651 57.2993 21.7087 57.2857 21.7496C57.2721 21.7906 57.249 21.8278 57.2185 21.8583C57.1879 21.8887 57.1507 21.9117 57.1097 21.9252C57.0687 21.9388 57.0252 21.9425 56.9825 21.9363H48.0478C47.8592 21.9363 47.7554 21.8309 47.7554 21.6224V7.32323C47.7554 7.11475 47.8592 7.01013 48.0478 7.01013H56.6885C56.731 7.00403 56.7744 7.00791 56.8152 7.02147C56.8561 7.03503 56.8931 7.0579 56.9236 7.08828C56.954 7.11865 56.977 7.15569 56.9907 7.19647C57.0043 7.23725 57.0083 7.28064 57.0023 7.32323V9.01393C57.0023 9.20026 56.8969 9.30564 56.6885 9.30564H50.2815V13.2086H56.1463C56.1889 13.2028 56.2322 13.2069 56.2729 13.2206C56.3136 13.2344 56.3507 13.2573 56.381 13.2877C56.4114 13.3181 56.4344 13.3551 56.4481 13.3958C56.4618 13.4365 56.4659 13.4799 56.4601 13.5225V15.1926C56.4661 15.2351 56.4621 15.2785 56.4485 15.3193C56.4348 15.3601 56.4119 15.3971 56.3814 15.4275C56.351 15.4579 56.3139 15.4808 56.2731 15.4943C56.2323 15.5079 56.1888 15.5118 56.1463 15.5057H50.2815L50.2868 19.6385Z'
      fill={`${color}`}
    />
    <path
      d='M60.2842 21.9367C60.0979 21.9367 59.9917 21.8313 59.9917 21.6228V7.32363C59.9917 7.11515 60.0971 7.01054 60.2842 7.01054H62.5598C62.6331 7.00595 62.7057 7.02603 62.7662 7.06755C62.8267 7.10907 62.8715 7.16965 62.8935 7.23963L65.4403 11.9742C66.0039 13.0173 66.882 14.8974 67.2158 15.6473H67.2998C67.6335 14.8974 68.5109 13.0173 69.0737 11.9742L71.5991 7.23963C71.6284 7.17005 71.6781 7.11095 71.7416 7.07007C71.8051 7.0292 71.8794 7.00845 71.9549 7.01054H74.2099C74.2524 7.00453 74.2957 7.00849 74.3364 7.02211C74.3771 7.03574 74.4141 7.05864 74.4444 7.08902C74.4747 7.1194 74.4975 7.15641 74.511 7.19713C74.5245 7.23785 74.5284 7.28116 74.5223 7.32363V21.6228C74.5285 21.6654 74.5248 21.7087 74.5113 21.7496C74.4978 21.7904 74.475 21.8275 74.4447 21.858C74.4144 21.8884 74.3774 21.9114 74.3366 21.9251C74.2959 21.9387 74.2525 21.9427 74.2099 21.9367H72.31C72.1237 21.9367 72.0183 21.8313 72.0183 21.6228V17.4266C72.0183 15.4648 72.1015 12.9211 72.184 10.873L72.1222 10.8501C71.6204 11.9353 70.9324 13.3343 70.2436 14.7119L68.4063 18.2827C68.3782 18.353 68.3289 18.4128 68.2652 18.4538C68.2016 18.4949 68.1268 18.5151 68.0512 18.5117H66.4666C66.3908 18.5155 66.3157 18.4954 66.2519 18.4543C66.1881 18.4132 66.1387 18.3532 66.1108 18.2827L64.2719 14.7126C63.5846 13.3381 62.8974 11.9368 62.3957 10.8509L62.3338 10.8738C62.417 12.9188 62.5003 15.4663 62.5003 17.4274V21.6228C62.5064 21.6655 62.5025 21.709 62.4889 21.7498C62.4753 21.7907 62.4524 21.8279 62.4219 21.8583C62.3914 21.8888 62.3543 21.9117 62.3134 21.9253C62.2725 21.9389 62.2291 21.9428 62.1864 21.9367H60.2842Z'
      fill={`${color}`}
    />
    <path
      d='M76.6263 21.9364C76.3972 21.9364 76.3125 21.8104 76.3758 21.5806L81.3853 7.26154C81.4039 7.18808 81.4471 7.12318 81.5077 7.07762C81.5682 7.03206 81.6425 7.00858 81.7183 7.01107H84.0993C84.1753 7.00768 84.2502 7.0308 84.3111 7.07649C84.3719 7.12217 84.415 7.18759 84.433 7.26154L89.4425 21.5814C89.5067 21.8104 89.4021 21.9372 89.1943 21.9372H87.1493C87.0701 21.9432 86.9914 21.9213 86.9266 21.8755C86.8617 21.8296 86.8149 21.7626 86.7942 21.686L85.7709 18.5344H80.0497L79.0264 21.686C79.0032 21.761 78.9559 21.8262 78.8918 21.8716C78.8278 21.917 78.7506 21.9401 78.6721 21.9372L76.6263 21.9364ZM80.7607 16.2572H85.06L82.9516 9.76629H82.8683L80.7607 16.2572Z'
      fill={`${color}`}
    />
    <path
      d='M91.5951 21.9363C91.4088 21.9363 91.3042 21.8309 91.3042 21.6225V7.32328C91.3042 7.1148 91.408 7.01019 91.5951 7.01019H93.7036C93.7788 7.00944 93.8529 7.02827 93.9186 7.06482C93.9844 7.10137 94.0394 7.15438 94.0785 7.21866L98.1479 14.3144C98.8612 15.5683 99.6531 16.9665 100.238 18.0937L100.3 18.0738C100.174 16.2976 100.114 14.1701 100.114 12.4152V7.32328C100.107 7.28078 100.111 7.2374 100.124 7.1966C100.138 7.15581 100.161 7.11872 100.191 7.08831C100.221 7.05789 100.258 7.03499 100.299 7.02142C100.34 7.00786 100.383 7.00401 100.426 7.01019H102.305C102.348 7.00405 102.391 7.00791 102.432 7.02147C102.473 7.03504 102.51 7.05792 102.54 7.08832C102.571 7.11871 102.593 7.15577 102.607 7.19656C102.621 7.23735 102.624 7.28074 102.618 7.32328V21.6217C102.624 21.6643 102.621 21.7077 102.607 21.7485C102.593 21.7893 102.571 21.8264 102.54 21.8569C102.51 21.8873 102.473 21.9103 102.432 21.9239C102.391 21.9376 102.348 21.9416 102.305 21.9356H100.201C100.128 21.9377 100.055 21.9192 99.9925 21.8822C99.9296 21.8452 99.8784 21.7912 99.8448 21.7263L95.8998 15.1934C95.1277 13.9402 94.2503 12.3122 93.6669 11.0819L93.6066 11.101C93.7089 12.8963 93.7929 15.072 93.7929 16.8428V21.6225C93.7929 21.8309 93.6883 21.9363 93.5012 21.9363H91.5951Z'
      fill={`${color}`}
    />
    <path
      d='M106.335 21.9366C106.146 21.9366 106.042 21.8312 106.042 21.6228V7.32432C106.042 7.11585 106.145 7.01123 106.335 7.01123H111.51C114.975 7.01123 117.146 8.86764 117.146 12.5209V16.4918C117.146 20.1023 114.976 21.9397 111.51 21.9397L106.335 21.9366ZM108.568 19.5762H111.511C113.515 19.5762 114.539 18.5957 114.539 16.5064V12.5011C114.539 10.3522 113.516 9.32965 111.511 9.32965H108.568V19.5762Z'
      fill={`${color}`}
    />
    <path
      d='M124.744 22.1846C122.784 22.1886 120.891 21.4756 119.42 20.1801C119.387 20.1559 119.358 20.1252 119.337 20.0898C119.316 20.0544 119.302 20.0151 119.296 19.9742C119.291 19.9333 119.293 19.8917 119.304 19.8519C119.315 19.812 119.334 19.7748 119.36 19.7425L120.403 18.2809C120.424 18.2469 120.453 18.2179 120.486 18.1959C120.52 18.1739 120.557 18.1594 120.597 18.1534C120.637 18.1474 120.677 18.15 120.716 18.1611C120.754 18.1721 120.79 18.1914 120.821 18.2175C121.966 19.1516 123.374 19.7065 124.849 19.8051C126.372 19.8051 127.876 19.1576 127.876 17.7387C127.876 16.2351 126.311 15.8601 124.494 15.4837C122.303 15.0255 119.421 14.0228 119.421 10.9133C119.421 8.01144 121.99 6.7583 124.995 6.7583C126.766 6.76133 128.488 7.33997 129.902 8.407C129.938 8.42563 129.97 8.45226 129.995 8.48491C130.02 8.51756 130.037 8.55539 130.045 8.59556C130.053 8.63573 130.052 8.6772 130.041 8.71689C130.031 8.75657 130.012 8.79343 129.986 8.82472L128.98 10.2672C128.877 10.4337 128.711 10.4337 128.543 10.3291C127.433 9.58786 126.14 9.16912 124.807 9.11948C123.011 9.11948 121.967 9.82967 121.967 10.9576C121.967 12.2947 123.304 12.6483 125.14 13.066C127.583 13.6089 130.463 14.4024 130.463 17.6998C130.465 20.8292 127.792 22.1846 124.744 22.1846Z'
      fill={`${color}`}
    />
    <path
      d='M142.615 7.01083C142.657 7.00687 142.699 7.01228 142.739 7.02666C142.778 7.04104 142.814 7.06404 142.844 7.09399C142.874 7.12394 142.896 7.1601 142.91 7.19986C142.924 7.23963 142.929 7.282 142.925 7.32392V9.03524C142.927 9.07584 142.921 9.11646 142.906 9.15426C142.891 9.19206 142.868 9.22613 142.838 9.25407C142.809 9.28202 142.773 9.30315 142.735 9.31601C142.696 9.32886 142.655 9.33312 142.615 9.32848H138.415V21.6231C138.415 21.8316 138.312 21.937 138.123 21.937H136.203C136.015 21.937 135.912 21.8316 135.912 21.6231V9.32848H131.695C131.654 9.33318 131.613 9.32899 131.574 9.31621C131.535 9.30343 131.5 9.28236 131.47 9.25447C131.44 9.22657 131.417 9.19252 131.401 9.15466C131.386 9.11681 131.379 9.07606 131.381 9.03524V7.32392C131.377 7.28168 131.382 7.239 131.396 7.199C131.41 7.15899 131.433 7.12267 131.463 7.09268C131.493 7.06269 131.53 7.03978 131.57 7.02562C131.61 7.01146 131.652 7.00641 131.695 7.01083H142.615Z'
      fill={`${color}`}
    />
    <path
      d='M142.342 21.9366C142.113 21.9366 142.029 21.8106 142.093 21.5808L147.104 7.26098C147.123 7.1873 147.166 7.12216 147.226 7.07653C147.287 7.0309 147.362 7.00758 147.437 7.0105H149.818C149.894 7.00755 149.968 7.03084 150.029 7.07646C150.09 7.12208 150.133 7.18724 150.151 7.26098L155.16 21.5816C155.222 21.8106 155.118 21.9374 154.91 21.9374H152.865C152.785 21.9435 152.706 21.9218 152.641 21.8759C152.576 21.8301 152.53 21.7629 152.509 21.6862L151.486 18.5346H145.767L144.743 21.6862C144.72 21.7612 144.672 21.8265 144.608 21.8719C144.544 21.9173 144.467 21.9403 144.388 21.9374L142.342 21.9366ZM146.477 16.2574H150.775L148.668 9.76649H148.584L146.477 16.2574Z'
      fill={`${color}`}
    />
    <path
      d='M165.389 21.9366C165.316 21.9387 165.244 21.9202 165.181 21.8832C165.119 21.8462 165.067 21.7921 165.034 21.7274L161.38 16.4888H159.524V21.6228C159.524 21.8312 159.42 21.9366 159.231 21.9366H157.31C157.121 21.9366 157.02 21.8312 157.02 21.6228V7.32432C157.02 7.11585 157.121 7.01123 157.31 7.01123H162.153C165.575 7.01123 167.579 8.78364 167.579 11.771C167.627 12.8036 167.304 13.8194 166.669 14.6347C166.033 15.4499 165.127 16.0109 164.114 16.2162L167.932 21.5617C168.079 21.7908 167.975 21.9381 167.746 21.9381L165.389 21.9366ZM159.524 9.32888V14.233H162.153C164.095 14.233 165.013 13.4609 165.013 11.7893C165.013 10.0986 164.096 9.32736 162.153 9.32736L159.524 9.32888Z'
      fill={`${color}`}
    />
    <path
      d='M11.9092 11.0379C11.7941 11.3197 11.5997 11.5621 11.3497 11.7357C11.0996 11.9093 10.8046 12.0067 10.5003 12.0161C10.3142 12.0145 10.1303 11.9752 9.95966 11.9006C9.78906 11.8261 9.63529 11.7179 9.50758 11.5824C8.76609 10.8111 6.22392 7.70386 6.88218 6.99291C6.93054 6.94531 6.98798 6.90794 7.05108 6.88301C7.11419 6.85808 7.18166 6.84611 7.24949 6.84782C8.22466 6.84782 10.7951 8.71034 11.5855 9.503C11.7912 9.69416 11.9328 9.9443 11.9908 10.2191C12.0487 10.4939 12.0203 10.7799 11.9092 11.0379Z'
      fill={`${color}`}
    />
    <path
      d='M10.4375 14.4688C10.4466 14.6612 10.4161 14.8533 10.348 15.0334C10.2799 15.2135 10.1755 15.3777 10.0414 15.5159C9.90722 15.6541 9.74615 15.7632 9.56813 15.8366C9.39011 15.91 9.19892 15.9461 9.00639 15.9426C8.70093 15.9426 6.50241 15.8617 4.26341 15.6372C0.441385 15.247 0 14.7842 0 14.4688C0 14.1534 0.441385 13.6937 4.26341 13.3088C6.4963 13.0798 8.7017 12.9988 9.00639 12.9988C9.19868 12.995 9.38969 13.0308 9.56759 13.1039C9.7455 13.1769 9.90652 13.2857 10.0407 13.4235C10.1748 13.5613 10.2793 13.7252 10.3475 13.905C10.4158 14.0848 10.4464 14.2767 10.4375 14.4688Z'
      fill={`${color}`}
    />
    <path
      d='M22.0968 7.28071C22.0968 8.25588 20.2319 10.8248 19.4401 11.6121C19.3124 11.7478 19.1587 11.8564 18.9881 11.9314C18.8176 12.0065 18.6336 12.0464 18.4473 12.0489C18.2601 12.0469 18.0751 12.0083 17.9028 11.9351C17.6214 11.8193 17.3797 11.6243 17.2069 11.3738C17.0341 11.1233 16.9376 10.8281 16.9292 10.5239C16.9305 10.3382 16.9696 10.1548 17.0442 9.98476C17.1188 9.81473 17.2272 9.66169 17.3629 9.53498C17.7348 9.16003 20.5405 6.79885 21.6233 6.79503C21.744 6.78891 21.8623 6.82999 21.9532 6.90958C22.0011 6.95792 22.0386 7.01562 22.0633 7.07907C22.088 7.14252 22.0994 7.21038 22.0968 7.27842V7.28071Z'
      fill={`${color}`}
    />
    <path
      d='M12.0197 18.439C12.0181 18.6252 11.9788 18.8091 11.9043 18.9797C11.8298 19.1503 11.7215 19.3041 11.5859 19.4318C11.2133 19.809 8.40383 22.1686 7.32098 22.1686C7.26226 22.1723 7.2034 22.1643 7.14781 22.145C7.09223 22.1258 7.04103 22.0956 6.9972 22.0564C6.94922 22.0088 6.91162 21.9518 6.88678 21.889C6.86194 21.8262 6.85039 21.7589 6.85287 21.6914C6.85287 20.7109 8.71616 18.145 9.50805 17.3569C9.63531 17.2207 9.78895 17.1119 9.95961 17.0369C10.1303 16.9619 10.3144 16.9225 10.5008 16.9209C10.6869 16.9231 10.8709 16.9606 11.043 17.0316C11.3245 17.1466 11.5666 17.3407 11.7399 17.5905C11.9133 17.8404 12.0105 18.1351 12.0197 18.439Z'
      fill={`${color}`}
    />
    <path
      d='M15.9441 19.9385C15.9441 20.244 15.8677 22.4471 15.6386 24.683C15.2522 28.5012 14.7887 28.948 14.4764 28.948C14.164 28.948 13.7013 28.5035 13.3118 24.683C13.0827 22.4685 13.0063 20.2486 13.0063 19.9385C13.0187 19.5569 13.1789 19.1949 13.4533 18.9293C13.7276 18.6637 14.0945 18.5151 14.4764 18.5151C14.8582 18.5151 15.2251 18.6637 15.4995 18.9293C15.7738 19.1949 15.9341 19.5569 15.9464 19.9385H15.9441Z'
      fill={`${color}`}
    />
    <path
      d='M22.0403 21.9496C21.9917 21.9971 21.9339 22.0343 21.8705 22.0588C21.8071 22.0834 21.7394 22.0948 21.6714 22.0924C20.6924 22.0924 18.1258 20.2283 17.337 19.4364C17.1309 19.2461 16.9892 18.9964 16.9316 18.7218C16.874 18.4473 16.9033 18.1617 17.0155 17.9046C17.1301 17.6224 17.3241 17.3795 17.5741 17.2056C17.8241 17.0317 18.1193 16.9341 18.4237 16.9248C18.6095 16.9265 18.7932 16.9655 18.9637 17.0394C19.1343 17.1134 19.2882 17.2209 19.4164 17.3555C20.18 18.1611 22.6924 21.2455 22.0403 21.9496Z'
      fill={`${color}`}
    />
    <path
      d='M28.9456 14.4856C28.9456 14.7911 28.5004 15.2493 24.6799 15.6364C22.4523 15.8655 20.2454 15.9419 19.9407 15.9419C19.5562 15.9331 19.1903 15.7742 18.9214 15.4991C18.6525 15.224 18.502 14.8546 18.502 14.47C18.502 14.0853 18.6525 13.7159 18.9214 13.4408C19.1903 13.1657 19.5562 13.0068 19.9407 12.998C21.5879 12.998 28.0651 13.4341 28.8188 14.1947C28.8601 14.2306 28.8929 14.2753 28.9149 14.3255C28.9368 14.3757 28.9473 14.4301 28.9456 14.4849V14.4856Z'
      fill={`${color}`}
    />
    <path
      d='M15.9439 9.00639C15.9316 9.38806 15.7714 9.74998 15.497 10.0156C15.2227 10.2813 14.8558 10.4298 14.4739 10.4298C14.092 10.4298 13.7252 10.2813 13.4508 10.0156C13.1765 9.74998 13.0162 9.38806 13.0039 9.00639C13.0039 8.69635 13.0803 6.47263 13.3094 4.26189C13.6973 0.440621 14.1593 0 14.4732 0C14.787 0 15.2482 0.440621 15.6354 4.26189C15.8637 6.49096 15.9439 8.70093 15.9439 9.00639Z'
      fill={`${color}`}
    />
  </DSSVGIcon>
);
