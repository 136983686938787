import { Link, withRouter } from 'react-router-dom';
import { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

import * as actionCreators from '../../../store/actions';
import { Buttons } from '../../customcontrols';
import { EBidsType } from '../../../types/payments';
import { setEbidDetails } from '../../../store/actions';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useAuthContext } from 'src/auth/AuthProvider';

const consthStyle = {
  th: {
    width: '150px',
  },
};
interface propsTypes {
  history: any;
  match: any;
  downloadAllDocument: any;
  ebids: EBidsType;
}

const PaymentThanks = (props: propsTypes) => {
  const { history, ebids, downloadAllDocument } = props;
  const { auth } = useAuthContext();
  const { paymentresponse } = ebids;
  const {
    orderNumber = '',
    amount = '',
    data = {},
    cardinfo,
    card_token,
    results,
  } = paymentresponse;
  const { details = {} } = card_token;
  const { cardType = '', cardNumberMask = '' } = cardinfo;
  const { agencyName = '', bidExternalStatus = '', bidName = '' } = results;
  const bidID = props.match.params.bidId || '';

  useEffect(() => {
    if (!bidID) history.replace('/suppliers/bids');
  }, [bidID, bidName, history]);

  useEffect(() => {
    if (bidID && !orderNumber) history.replace(`/suppliers/bids/${bidID}`);
  }, [bidID, bidName, history, orderNumber]);

  useEffect(() => {
    return () => {
      setEbidDetails({ paymentresponse: {} });
    };
  }, []);

  useSetBreadcrumb({
    component: '',
    page: 'Supplier Payment Thanks',
    title: 'Payment Acknowledgement',
    altname: 'Payment Acknowledgement',
    name: bidName,
    id: bidID,
  });

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-7 colWrapper'>
          <h3 className='reciept-print'>Receipt</h3>
          <h3 className='reciept-screen'>Thank You</h3>
          <h4>Your Order is Complete</h4>
          <div className='innerColIndent'>
            <Buttons
              text='Download Bid Package'
              classNames='bttn-primary callAction mb-4'
              icon='mdi-download'
              onClick={() => downloadAllDocument({ id: bidID, type: 'Bid' })}
            />
            <Table borderless className='tableData'>
              <tr>
                <th style={consthStyle.th}>Account</th> <td>{auth?.fullName}</td>
              </tr>
              <tr>
                <th>Order Number</th> <td>{orderNumber}</td>{' '}
              </tr>
              {card_token && card_token.details ? (
                <tr>
                  <th>Card</th>{' '}
                  <td>
                    {details.cardType} ( ************{details.lastFour} )
                  </td>
                </tr>
              ) : (
                <tr>
                  <th>Card</th>{' '}
                  <td>
                    {cardType} ( {cardNumberMask} )
                  </td>
                </tr>
              )}
            </Table>
          </div>
          <h4>Order Details</h4>
          <div className='innerColIndent'>
            <Table borderless className='tableData'>
              <tr>
                <th style={consthStyle.th}>Bid Name</th>
                <td>{bidName}</td>
              </tr>
              <tr>
                <th>Price</th>
                <td>${amount}</td>
              </tr>
              <tr>
                <th>Agency Name</th>
                <td>{agencyName}</td>
              </tr>
              <tr>
                <th>Bid Status</th>
                <td>
                  <span className='tag purple ml-0'>{bidExternalStatus}</span>
                </td>
              </tr>
            </Table>
          </div>
          <Link
            className='bttn bttn-primary float-left mb-3 ml-2 reciept-screen'
            to={`/suppliers/bids/${data.bidId}/details`}
            replace
          >
            Go to Bid Package
          </Link>
          <span
            onClick={() => window.print()}
            //target='_blank'
            className='reciept-screen bttn bttn-secondary float-right staticLink'
          >
            Print Receipt
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state: any) => ({ ebids: state.ebids }),
    dispatch => bindActionCreators({ ...actionCreators }, dispatch),
  )(memo(PaymentThanks)),
);
