import { AddressCountry } from './address';
import { CountryId } from 'src/utils/constants';

export const allCountries: AddressCountry[] = [
  {
    key: 'usa',
    name: 'United States of America',
    id: CountryId.UnitedStates,
  },
  {
    key: 'canada',
    name: 'Canada',
    id: CountryId.Canada,
  },
];
