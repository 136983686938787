import { selector } from 'recoil';

import { AcceptedFormat } from 'src/types/publications';
import { getAccountDocumentInfo } from 'src/store/services/document';
import { tryCatchLog } from 'src/utils/errors';

export const acceptedFileFormatsQuerySelector = selector<AcceptedFormat[]>({
  key: 'acceptedFileFormatsQuerySelector',
  get: async () => {
    return await tryCatchLog(async () => {
      // API call
      const response = await getAccountDocumentInfo();
      return response.acceptedFormats.map(format => {
        return { ...format, docFormatType: format.docFormatType.trim().toLocaleUpperCase() };
      });
    }, 'acceptedFileFormatsQuerySelector');
  },
});

export const convertibleFileFormatsSelector = selector<AcceptedFormat[]>({
  key: 'convertibleFileFormatsSelector',
  get: ({ get }) => {
    const acceptedFileFormats = get(acceptedFileFormatsQuerySelector);
    return acceptedFileFormats.filter(format => {
      return format.isConversion;
    });
  },
});
