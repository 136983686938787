import { phoneVal } from '../../../../../../utils/constants';
import { emailValidation } from '../../../../../../utils/helpers';

export const validate = (values: any) => {
  // const keys = Object.keys(values)

  const errors = {} as any;

  const optional = [
    'phoneNumber',
    'phoneExtension',
    'jobTitle',
    'existingUserName',
    'departmentId',
    'mainContact',
    'memberId',
    'notify',
    'validatedEmail',
    'exceededBidVisit',
    'accountId',
    'password',
    'existingNotify',
    'hasCardInfo',
  ];
  // const optional = ['quoteIntro', 'quoteClose', 'quoteInsurance'];
  // const requiredFields = ['first', 'legalCostInfo', 'legalDueDateTime','legalAdditional','legalClosing'];

  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => {
        const returnValue = values[items];
        return {
          name: items,
          value: typeof returnValue === 'string' ? values[items].trim() : values[items],
        };
      }) || [];

  const errorarr = required.filter(items => !items.value) || [];
  errorarr.forEach(items => (errors[items.name] = 'Required'));

  if (values.phoneNumber && !phoneVal.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid';
  }

  if (values.username !== values.existingUserName && emailValidation(values.username)) {
    errors.username = 'Invalid (e.g: mail@example.com)';
  }

  if (emailValidation(values.email)) {
    errors.email = 'Invalid';
  }
  return errors;
};
