import React, { memo } from 'react';
import { Field, Formik } from 'formik';
import renderTextarea from '../../common/controls/inputs/textarea';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import { WizardPage } from '../../../types/wizard';
import { useDispatch } from 'react-redux';
import { setWizardStatus } from '../../../utils/helpers';
import { useDSSelector } from '../../../store/reducers';

interface PropsTypes {
  handleSubmit?: any;
  match?: any;
  currentwizard?: WizardPage;
  initialValues?: any;
  setAgencyRegistrationDetails?: any;
}

function LegalAds(props: PropsTypes) {
  const dispatch = useDispatch();

  const { initialValues, setAgencyRegistrationDetails } = props;

  const agencyRegistrationWizard = useDSSelector(
    state => state.agencyregistration.agencyRegistrationWizard,
  );

  const onSubmitFn = (data: any) => {
    if (data) {
      dispatch(
        setAgencyRegistrationDetails({
          agencyRegistrationWizard: setWizardStatus(agencyRegistrationWizard, 6),
          legalAds: data,
          wizardcurrentpage: 6,
        }),
      );
    }
  };

  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-lg-12'>
                  <h2 className='lineHR' data-testid='agencyregistration.legalads.head'>
                    Legal Ads
                  </h2>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <div className='innerColIndent'>
                        <Field
                          label='Introductory Text'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='legalAdIntro'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.intro'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Cost Information'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='legalAdCostInfo'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.cost'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Due Date/Time'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='legalAdDueDateTime'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.date'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Additional Text'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='legalAdAdditional'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.additional'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Closing Text'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='legalAdClosing'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.closing'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Quote Introductory Text'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='quoteIntro'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.qintro'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Quote Terms and Conditions'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='quoteClose'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.qclose'
                          onChange={setFieldValue}
                        />
                        <Field
                          label='Quote Insurance Requirements'
                          classNames='class'
                          placeholder='Notes'
                          parentClass='formItemSpace'
                          name='quoteInsurance'
                          component={renderTextarea}
                          remainShow={true}
                          maxLength={1024}
                          optional={true}
                          data-testid='agencyregistration.legalads.input.qinsurance'
                          onChange={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RegistrationWizardButtons {...props} />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default memo(LegalAds);
