import { useCallback, useMemo } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import { DSLoadingPanel } from '@demandstar/components/loading-panel';
import { DSRecordItem } from '@demandstar/components/record-item';
import { FlexContainer } from '@demandstar/components/styles';

import * as constants from './BrowseBids.constants';
import * as texts from './BrowseBids.texts';

import { ErrorPanel, InfoPanel } from 'src/components/common/loading';

import { BidDetailsLink } from 'src/components/common/controls/text/links/BidDetailsLink';
import { BidListTitle } from './BrowseBids.styles';
import { displayDate } from 'src/utils/helpers';
import { getLoadableContents } from 'src/utils';
import { HistoricalBidAPIResponse } from './BrowseBids.d';
import { historicBidsListState } from './BrowseBids.state';
import { SignUpButton } from 'src/components/common/layout/StaticHeader.styles';
import { useBrowseBids } from './useBrowseBids';
import { useRouter } from 'src/shared/hooks';

export function BrowseBidsList() {
  const { history } = useRouter();

  const { hasSEOSearchValues } = useBrowseBids();

  const renderBidList = useCallback((list: HistoricalBidAPIResponse[]) => {
    return list.map((bidInfo: HistoricalBidAPIResponse, index) => {
      const bidData = [
        { title: 'Broadcast Date', value: displayDate(bidInfo.broadCastDate) },
        { title: 'Award Date', value: displayDate(bidInfo.dueDate) },
        { title: 'Agency', value: bidInfo.agency },
        { title: 'City', value: bidInfo.city },
        { title: 'ID', value: bidInfo.bidIdentifier },
      ];
      const recordTitle = (
        <BidDetailsLink bidId={bidInfo.bidId} title={bidInfo.bidName}>
          {bidInfo.bidName}
        </BidDetailsLink>
      );
      return <DSRecordItem data={bidData} key={index} title={recordTitle} />;
    });
  }, []);

  const bidListLoadable = useRecoilValueLoadable(historicBidsListState);
  const loadableState = bidListLoadable.state;
  const bidList = getLoadableContents(bidListLoadable, []);

  const bidCount = useMemo(() => {
    return bidList?.length || 0;
  }, [bidList]);

  const bidListTitleDisplay = useMemo(() => {
    return `${texts.historicalBids} ${!bidCount ? '' : `(most recent ${bidCount})`}`;
  }, [bidCount]);

  const emptyMessage = useMemo(() => {
    return hasSEOSearchValues
      ? 'No matching Historical Bids for that search yet!'
      : texts.emptyMessage;
  }, [hasSEOSearchValues]);

  const getListDisplay = useCallback(() => {
    const handleRegistrationClick = () => {
      history.push('/registration');
    };
    return (
      <>
        {loadableState === 'hasError' && <ErrorPanel message={emptyMessage} />}
        {loadableState === 'loading' && <DSLoadingPanel />}
        {loadableState === 'hasValue' && bidList.length ? (
          <>
            {renderBidList(bidList)}
            <FlexContainer justifyContent='center'>
              <SignUpButton
                onClick={handleRegistrationClick}
                title='See All Active Bids'
                type='primary'
              >
                See All Active Bids
              </SignUpButton>
            </FlexContainer>
          </>
        ) : (
          <InfoPanel message={emptyMessage}>
            <SignUpButton
              onClick={handleRegistrationClick}
              title='See All Active Bids'
              type='primary'
            >
              See All Active Bids
            </SignUpButton>
          </InfoPanel>
        )}
      </>
    );
  }, [bidList, emptyMessage, history, loadableState, renderBidList]);

  return (
    <>
      <BidListTitle
        data-testid={constants.bidListTitleElementId}
        id={constants.bidListTitleElementId}
      >
        {bidListTitleDisplay}
      </BidListTitle>
      {getListDisplay()}
    </>
  );
}
