import { call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';

import * as actionTypes from '../../actionTypes';
import * as Api from '../../services/addbid';
import * as bidApi from '../../services/bids';
import {
  addPageToWizard,
  scrollToTop,
  setTimeToDate,
  setWizardStatus,
  toastFn,
} from '../../../utils/helpers';
import {
  BidExternalStatusType,
  BidInternalStatusType,
  usaDateFormat,
  userEvent,
} from '../../../utils/constants';
import { addBidToastId } from '../addbid';
import { SubmitAddBidInfoPayload } from '../../actions';
import { WizardStatus } from '../../../types/wizard';

import history from '../../../utils/history';

export function* watchsubmitAddBidInfo() {
  yield takeLatest(actionTypes.SUBMIT_ADD_BID_INFO.TRIGGER, submitAddBidInfo);
}

export function* submitAddBidInfo(action: { payload: SubmitAddBidInfoPayload }): unknown {
  yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.REQUEST, meta: action.payload });
  try {
    const addbid = yield select(state => state.addbid);
    const bidssummary = yield select(state => state.bidssummary);
    const { results = {} } = bidssummary;
    const { bidID = '' } = results;

    const {
      addbidwizard = [],
      bidId = '',
      addbidwizardpartial = false,
      sequence = 0,
      newbidNumber = 0,
      duplicatebidmodal = false,
      addbidinfo = {},
    } = addbid || {};

    const ID = bidID || bidId;

    const values = action.payload.data;

    const bidInfoDiff = Object.keys(addbidinfo).reduce((diff: any, key: string) => {
      if ((values as any)[key] === addbidinfo[key]) return diff;
      return {
        ...diff,
        [key]: addbidinfo[key],
      };
    }, {});

    const dueDateInitial = bidInfoDiff.dueDate
      ? setTimeToDate(
          bidInfoDiff.dueDate,
          `${bidInfoDiff.dueTime ? bidInfoDiff.dueTime.value : values.dueTime.value} ${
            bidInfoDiff.dueTimeZone ? bidInfoDiff.dueTimeZone.value : values.dueTimeZone.value
          }`,
        )
      : '';
    const broadcastDateInitial = bidInfoDiff.broadcastDate
      ? moment(bidInfoDiff.broadcastDate).format(usaDateFormat)
      : '';
    const dueDate = setTimeToDate(
      values.dueDate,
      `${values.dueTime.value} ${values.dueTimeZone.value}`,
    );
    const broadcastDate = setTimeToDate(
      values.broadcastDate,
      `${values.broadcastDateTime.value} ${values.broadcastDateTimeZone.value}`,
    );
    let finalvalue = {};
    if (ID) {
      finalvalue = {
        bidId: ID,
        bidSequenceNumber: results.bidSequenceNumber,
        memberId: values.memberId,
        bidName: values.bidName && values.bidName.trim(),
        bidBond: values.bidBond && values.bidBond.trim(),
        displayBudgetToSuppliers: values.displayBudgetToSuppliers,
        projectEstimatedBudget: values.projectEstimatedBudget
          ? parseFloat(
              values.projectEstimatedBudget
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )
          : '',
        bidWriterAccountId:
          values.bidWriterAccountId &&
          typeof values.bidWriterAccountId !== 'string' &&
          values.bidWriterAccountId.value,
        scopeOfWork: values.scopeOfWork && values.scopeOfWork.trim(),
        bidYear: values.bidYear && values.bidYear.value,
        bidNumber: duplicatebidmodal && newbidNumber ? newbidNumber : values.bidNumber,
        memberBidType:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.value,
        memberBidTypeDesc:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.label,
        openedText: values.openedText,
        dueDateTime: dueDate,
        broadcastDate: broadcastDate,
        onlineResponses: values.eBidding === 'yes' ? true : false,
        bidExternalStatusType: values.bidstatus.value,
        BidInternalStatusType: results.bidInternalStatusType,
      };
    } else {
      finalvalue = {
        bidId: 0,
        bidSequenceNumber: duplicatebidmodal && !newbidNumber ? sequence + 1 : 0,
        memberId: values.memberId,
        bidName: values.bidName && values.bidName.trim(),
        bidBond: values.bidBond && values.bidBond.trim(),
        displayBudgetToSuppliers: values.displayBudgetToSuppliers,
        projectEstimatedBudget: values.projectEstimatedBudget
          ? parseFloat(
              values.projectEstimatedBudget
                .toString()
                .replace(/ /g, '')
                .replace('$', '')
                .replace(/,/g, ''),
            )
          : '',
        bidWriterAccountId:
          values.bidWriterAccountId &&
          typeof values.bidWriterAccountId !== 'string' &&
          values.bidWriterAccountId.value,
        scopeOfWork: values.scopeOfWork && values.scopeOfWork.trim(),
        bidYear: values.bidYear && values.bidYear.value,
        bidNumber: duplicatebidmodal && newbidNumber ? newbidNumber : values.bidNumber,
        memberBidType:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.value,
        memberBidTypeDesc:
          values.memberBidType &&
          typeof values.memberBidType !== 'string' &&
          values.memberBidType.label,
        openedText: values.openedText,
        dueDateTime: dueDate,
        broadcastDate: broadcastDate,
        onlineResponses: values.eBidding === 'yes' ? true : false,
        eBidding: values.eBidding === 'yes' ? true : false,
        blueprintStatusType: 'NA',
        documentHandlerType: 'DM',
        documentVendorType: 'PE',
        bidExternalStatusType: BidExternalStatusType.Upcoming,
        bidInternalStatusType: BidInternalStatusType.Pending,
      };
    }

    const response = yield call(Api.submitAddBidInfo, finalvalue);
    if (response.data.result) {
      if (dueDateInitial !== '') {
        const payloadEvent = {
          eventId: userEvent.UpdateDueDate,
          parentId: ID,
          value: dueDateInitial,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
      if (broadcastDateInitial !== '') {
        const payloadEvent = {
          eventId: userEvent.UpdateBroadcastDate,
          parentId: ID,
          value: broadcastDateInitial,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      }
      if (bidInfoDiff.bidstatus) {
        const payloadEvent = {
          eventId: userEvent.UpdateStatus,
          parentId: ID,
          value: bidInfoDiff.bidstatus,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
        yield call(Api.clearDashboardCache, {
          bidIds: [ID],
        });
      }
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { halfWayCancel: false },
      });
      const responsedata = values;
      const dup = response.data.result.dup;
      const newbidId = response.data.result.bidId || ID;
      if (dup) {
        if (duplicatebidmodal === true)
          toastFn('error', 'Bid number already exists', addBidToastId);
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: {
            duplicatebidmodal: true,
            sequence: response.data.result.sequence,
            addbidinfo: responsedata,
            bidId: newbidId,
          },
        });
        yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE });
        return;
      }
      let newwizzard = addbidwizard;
      if (values) {
        const checkexists =
          addbidwizard.filter((item: { name: string }) => item.name === 'eBidding Info') || [];
        if (values.eBidding && values.eBidding === 'yes' && checkexists.length > 0) {
          newwizzard = addbidwizard;
        } else if (values.eBidding && values.eBidding === 'yes' && checkexists.length === 0) {
          newwizzard = addPageToWizard(
            addbidwizard,
            { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
            2,
            values.eBidding === 'yes' ? true : false,
          );
        } else if (values.eBidding && values.eBidding === 'no' && checkexists.length > 0) {
          newwizzard = addPageToWizard(
            addbidwizard,
            { id: 2, name: 'eBidding Info', status: WizardStatus.Unavailable },
            2,
            false,
          );
        }
      }
      let payload: any = {
        addbidwizard: setWizardStatus(newwizzard, 2),
        addbidinfo: responsedata,
        bidId: newbidId,
        duplicatebidmodal: false,
      };
      if (addbidwizardpartial) {
        payload = {
          addbidwizardpartial: false,
          addbidinfo: responsedata,
          bidId: newbidId,
          duplicatebidmodal: false,
          halfWayCancelAllow: true,
        };
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { halfWayCancelAllow: true },
        });
        history.goBack();
      }

      if (!addbidwizardpartial && newbidId) {
        yield put({
          type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
          payload: { halfWayCancelAllow: true, commodityAlert: true },
        });
        if (!bidID) history.replace(`/buyers/bids/${newbidId}/creation`);
      }
      yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.SUCCESS, payload });
      if (ID) {
        const response = yield call(bidApi.getBidsSummary, { bidId: ID });
        const results = response.data.result || {};
        yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
      }

      scrollToTop();
      toastFn('success', 'Saved', addBidToastId);
      // Tracking API call
      if (ID) {
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: { eventId: userEvent.UpdateBidInfo, parentId: ID, value: finalvalue },
        });
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE });
      toastFn('error', 'Failed', addBidToastId);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_ADD_BID_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', addBidToastId);
  }
}
