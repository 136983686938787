import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';

import { BorderColor, BorderThickness, Margin, Padding } from '@demandstar/components/styles';

import { agenciesByProductState } from 'src/store/recoil/agencyState';
import { ModalPopUp } from 'src/components/common/modals';
import { ProductApiResponse } from 'src/types/products';

const AgencyList = styled.div`
  max-height: calc(100vh - 15rem);
  overflow: auto;
  min-height: 12.5rem;

  ul {
    list-style: none;

    li {
      border-bottom: ${BorderThickness.Base} solid ${BorderColor.Highlight};
      margin-bottom: ${Margin.Less};
      padding: ${Padding.Less};
      padding-bottom: ${Padding.None};
    }
  }
`;
/**
 * @description displays a modal with a list of agencies for a specific product
 * The modal and its content are toggled by on agenciesByProductState in Recoil
 * @example <AgencyByProductModal />
 * @returns A modal that toggles based on a non-empty Recoil agenciesByProductState
 */
export const AgencyByProductModal = () => {
  const [showAgencyModal, setShowAgencyModal] = useState(false);
  const [agenciesByProduct, setAgenciesByProduct] =
    useRecoilState<ProductApiResponse[]>(agenciesByProductState);

  useEffect(() => {
    if (agenciesByProduct.length > 0) {
      setShowAgencyModal(true);
    }
  }, [agenciesByProduct]);

  function closeModal() {
    // Closing the Modal also clears the state
    setAgenciesByProduct([]);
    setShowAgencyModal(false);
  }

  return (
    <ModalPopUp size='lg' title='Agency List' closeModal={closeModal} isOpen={showAgencyModal}>
      <AgencyList>
        <ul data-testid='agency-by-product-modal-list'>
          {agenciesByProduct.map((agency: ProductApiResponse) => (
            <li key={agency.productId}>{agency.productName}</li>
          ))}
        </ul>
      </AgencyList>
    </ModalPopUp>
  );
};
