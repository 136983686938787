import { useState } from 'react';

import { DSButton } from '@demandstar/components/button';

import { AgencySelection } from '../agency-selection';
import { ModalPopUp } from 'src/components/common/modals';
import { OpsActionsCopy } from 'src/utils/texts/supplier/subscription';
import { Product } from 'src/types/subscriptions';

interface ModifyFreeAgencyProps {
  /** the member's currently subscribed products */
  products?: Product[];
}

/**
 * @description an OPS-styled button that opens a modal that allows for
 * the selection of a new or different free agency for a member
 * @returns JSX.Element
 *
 * @example <ModifyFreeAgency products={{
 *  productId: 1,
 *  productType: ProductType.FreeAgency,
 *  productName: 'Subscribed Agency',
 *  price: 0,
 * }} />
 */
export const ModifyFreeAgency = ({ products = [] }: ModifyFreeAgencyProps) => {
  const [agencySelection, setAgencySelection] = useState(false);

  const toggleAgencySelection = () => {
    setAgencySelection(!agencySelection);
  };

  return (
    <>
      <DSButton
        type={'ops'}
        title={OpsActionsCopy.ModifyFreeAgency}
        onClick={toggleAgencySelection}
      >
        {OpsActionsCopy.ModifyFreeAgency}
      </DSButton>
      <ModalPopUp
        size='lg'
        title={OpsActionsCopy.ModifyFreeAgency}
        closeModal={toggleAgencySelection}
        isOpen={agencySelection === true}
        backdrop='static'
      >
        <AgencySelection
          products={products}
          toggleAgencySelection={toggleAgencySelection}
          isOpsUser={true}
        />
      </ModalPopUp>
    </>
  );
};
