import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { getFileExtension, getFileNames } from '../../utils';

import {
  fileUploadMaxSize,
  fileUploadMaxSizeDisplay,
  supportedFileTypes,
} from '../../constants/document';

import { FileUploadText } from './constants';

export function useFileInput() {
  const [filesToUpload, setFilesToUpload] = useState<FileList>();

  const handleFileSelection = useCallback((changeEvent: ChangeEvent<HTMLInputElement>) => {
    const fileList = changeEvent?.target?.files;
    if (fileList) {
      setFilesToUpload(fileList);
    }
  }, []);

  const fileNames = useMemo(() => {
    return getFileNames(filesToUpload);
  }, [filesToUpload]);

  function validateFileSize(filesToUpload: FileList) {
    let uploadSize = 0;
    if (filesToUpload) {
      for (let i = 0; i < filesToUpload.length; i++) {
        uploadSize += filesToUpload[i].size;
      }
    }
    return (
      uploadSize <= fileUploadMaxSize ||
      `${FileUploadText.ErrorFileSize} ${fileUploadMaxSizeDisplay}MB`
    );
  }

  function validateFileType(filesToUpload: FileList) {
    let validType = true;
    if (filesToUpload) {
      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        if (file?.name) {
          const extension = getFileExtension(file?.name);
          if (!supportedFileTypes.some(ext => ext === extension)) {
            validType = false;
            break;
          }
        }
      }
      return validType || FileUploadText.ErrorDocumentType;
    }
  }

  return {
    filesToUpload,
    fileNames,
    handleFileSelection,
    setFilesToUpload,
    validateFileSize,
    validateFileType,
  };
}
