import { Controller, get, RegisterOptions, FieldError, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import { convertPriceToDollarsAndCents, formatDollar } from '../../utils';
import { FieldContainer, FieldProps, getFieldContainerProps } from '../../field-container';
import { BaseTextInput } from '../..';
import { Inactive } from 'src/types/shared';
import { NAWrapper } from './NAWrapper';
import { DevTool } from "@hookform/devtools";


export const NADollarDisplay: string = '--';
const dollarMaxLength = 20;

export type DSDollarFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus'> &
  Inactive & {
    /** sets whether NaN is an appropriate value. */
    allowNA?: boolean;
    /** optional function that calls on value change. */
    onUpdate?: () => void;
  };

/** Dollar field which converts text into valid dollars.
 * @example <DSDollarField
          name={fieldName}
          allowNA
        />
 * */
export const DSDollarField = (props: DSDollarFieldProps) => {
  const { allowNA, autoFocus, dataTestId, inactive, name, optional, onUpdate } = props;

  const methods = useFormContext() as any;
  const { control, formState:{errors}, setValue, watch,register } = methods;
  const [preventUpdate, setPreventUpdate] = useState(autoFocus);
  const [isFocused, setIsFocused] = useState(autoFocus);

  const setDisplayValue = useCallback(
    (value: string) => {
      setValue(name, value);
    },
    [name, setValue],
  );

  /** the number value of the input */
  const value: number = watch(name);

  /** The correct string value we should be displaying when the input is not focused. */
  const trueDisplay = useMemo(() => {
    if (value === 0 || value === undefined) {
      return '$0.00';
    }

    if (Number.isNaN(value)) {
      return NADollarDisplay;
    }

    return formatDollar(value);
  }, [value]);
 

  // /** true if defaultValue is a nonzero falsy value.*/
  const notApplicable = useMemo(() => Number.isNaN(value), [value]);

  const registerOptions: RegisterOptions = {
    required: !optional,
    setValueAs: getNumericValue,
    maxLength: dollarMaxLength,
  };

  const error = errors[name] as FieldError;

  useEffect(() => {
    if (!preventUpdate) {
      setDisplayValue(trueDisplay);
    } else if (value === 0) {
      setDisplayValue('$');
    }
  }, [preventUpdate, setDisplayValue, trueDisplay, value]);


  function getNumericValue(price?: string): number {
    if (price === NADollarDisplay) {
      return NaN;
    }
    if (!price) {
      return null;
    }
    const { dollars, cents } = convertPriceToDollarsAndCents(price);
    return parseInt(dollars, 10) + parseInt(cents, 10) / 100;
  }

  function toggleNA() {
    if (Number.isNaN(value)) {
      setDisplayValue('$0.00');
    } else {
      setDisplayValue('--');
    }
    if (onUpdate) onUpdate();
  }

  function onFocus() {
    setIsFocused(true);
    setPreventUpdate(true);
  }

  return (
    <Controller
      control={control}
      defaultValue={trueDisplay}
      name={name}
         rules={registerOptions}
      render={({ field }: any) => {
        function formatPrice() {
          setPreventUpdate(false);
          setDisplayValue(trueDisplay === '$' ? '$0.00' : trueDisplay);
          if (onUpdate) onUpdate();
          field.onBlur();
          setIsFocused(false);
        }

        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <NAWrapper
              name={name}
              notApplicable={notApplicable}
              onNAClick={allowNA ? toggleNA : undefined}
              inactive={!!inactive}
            >
              <BaseTextInput
                autoFocus={autoFocus}
                data-testid={dataTestId}
                error={error}
                hasNA={allowNA}
                id={name}
                isNumeric
                maxLength={dollarMaxLength}
                name={name}
                onBlur={formatPrice}
                onChange={field.onChange}
                onFocus={onFocus}
                readOnly={inactive}
                ref={field.ref}
                value={isFocused && value !== 0 ? value:trueDisplay}
              />
            </NAWrapper>
            <DevTool control={control} /> 
          </FieldContainer>
           
        );
      }}
    />
  );
};
