import * as Scroll from 'react-scroll';

import {
  limitedloginPreviousUrlName,
  loginPreviousUrlName,
  loginPreviousUrlSearch,
  MemberType,
  Pages,
} from '../constants';
import { Permission } from 'src/types/auth';

/**
 * @description Use this to scroll the main window up until it reaches the top
 */
export function scrollToTop() {
  const scroll = Scroll.animateScroll;
  const options = { delay: 0, duration: 1000, smooth: 'easeOutQuart', isDynamic: true };
  scroll.scrollToTop(options);
}

/**
 * @description Opens a link in a new tab
 * @param path - relative path - use constants whenever possible
 */
export function openInNewTab(path: string) {
  window.open(path, '_blank');
}

function getQueryStringValue(queryString: string, key: string) {
  key = key.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(queryString);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

type LoginRedirectParams = {
  us: number;
  prms?: string;
  mt: string;
};

export const getLoginRedirect = ({
  us: userId,
  prms: permissionString = '',
  mt: memberType,
}: LoginRedirectParams) => {
  const dashboard =
    memberType === MemberType.AgencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
  const userType = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
  let path: string = dashboard;

  const permissions = permissionString.replace(/ /g, '').split(',') as Permission[];

  const ltdPrevUrl = sessionStorage.getItem(limitedloginPreviousUrlName) ?? '';
  const prevUrl = sessionStorage.getItem(loginPreviousUrlName) ?? '';
  const returnUrl = getQueryStringValue(window.location.href, 'RU');

  if (userId && permissions.includes(Permission.Ops)) {
    path = '/ops/agency/registration';
  } else if (returnUrl && returnUrl.length > 0) {
    path = returnUrl;
  } else if (ltdPrevUrl) {
    path = `/${userType}${ltdPrevUrl}`;
    sessionStorage.removeItem(limitedloginPreviousUrlName);
  } else if (prevUrl) {
    path = prevUrl;

    if (sessionStorage.getItem(loginPreviousUrlSearch)) {
      path = path + sessionStorage.getItem(loginPreviousUrlSearch);
    }

    path = path.replace('/beta/', '/').replace('/app/', '/');
    if (userType === 'buyers' && path.includes('/suppliers')) {
      path = dashboard;
    } else if (userType === 'suppliers' && path.includes('/buyers')) {
      path = dashboard;
    }
    sessionStorage.removeItem(loginPreviousUrlName);
    sessionStorage.removeItem(loginPreviousUrlSearch);
  }

  path = ['', '/', '/login'].includes(path) ? dashboard : path;

  return path;
};
