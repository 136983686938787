import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import { Authorization, Permission } from 'src/types';

import { defaultNumericId } from 'src/shared/constants';
import { getTokenData } from 'src/utils/token';

export enum AuthUserType {
  Anonymous = 'A',
  DSUser = 'DS',
  Initial = '',
  Ops = 'O',
}

export enum AuthKey {
  AccountCreatedDate = 'acd',
  AccountId = 'us',
  AccountLocked = 'lkd',
  AccountLockedMinutes = 'lm',
  AccountPermissions = 'prms',
  DisabledNotifications = 'dn',
  Email = 'eml',
  FirstName = 'fn',
  FreeMemberId = 'fmi',
  FullName = 'fln',
  HasError = 'her',
  LastLogin = 'll',
  LastName = 'ln',
  MainContact = 'mc',
  MemberBlackListed = 'mbl',
  MemberCreatedDate = 'mcd',
  MemberId = 'mi',
  MembershipLevels = 'ml',
  MemberStatus = 'ms',
  MemberType = 'mt',
  Migrated = 'mgrtd',
  OpsId = 'opi',
  PrimaryMemberId = 'pmid',
  ProductType = 'pt',
  Theme = 'tm',
  Token = 'tk',
  Username = 'un',
  /** @warning this is different from our standard use of userType. Should be typed as AuthUserType */
  AuthUserType = 'ut',
}

export const defaultAuthState: Authorization = {
  accountId: defaultNumericId,
  accountLocked: false,
  accountLockedMinutes: defaultNumericId,
  accountPermissions: '',
  email: '',
  firstName: '',
  fullName: '',
  hasError: false,
  lastName: '',
  mainContact: false,
  memberBlackListed: false,
  memberCreatedDate: '',
  memberId: defaultNumericId,
  membershipLevels: '',
  memberStatus: '',
  memberType: '',
  migrated: false,
  opsId: defaultNumericId,
  primaryMemberId: defaultNumericId,
  themetokenName: '',
  token: '',
  userName: '',
  userType: AuthUserType.Initial,
  validatedResult: { status: true, firstName: '', lastName: '' },
};

export const authTokenState = atom<string>({
  key: 'authTokenState',
  default: '',
});

export const authSelectorState = selector<Authorization>({
  key: 'authSelectorState',
  get: ({ get }) => {
    const token = get(authTokenState); // Sets a dependency on authTokenState. TODO: restore setting token state to trigger auth re-parsing/refresh (6915?).
    const auth = token ? getTokenData(token) : defaultAuthState;
    return auth;
  },
});

// TODO: update default to null/undefined
export const authState = atom<Authorization>({
  key: 'authState',
  default: authSelectorState,
});

export const authErrorState = atom<string>({ key: 'authErrorState', default: '' });
