import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  AwardBidLabels,
  defaultNumericId,
  documentUploadProgressThrottlePercentage,
  uploadMessages,
} from 'src/shared/constants';
import { DocumentUploadFormValues, FileUploadRequest } from 'src/types/document';

import { AddRequiredDocumentTypePanel } from '../list-required-docs/AddRequiredDocumentTypePanel';
import { DocumentUploadForm } from '../../../common/document';
import { documentUploadFormChangedState } from '../../../../store/recoil/documentState';
import { selectedBidAwardeeState } from '../../../../store/recoil/award-bid';
import { showToastSuccess } from 'src/utils/message';
import { StatusMessage } from '../../../../types/shared';
import { tryCatchLog } from 'src/utils/errors';
import { uploadVendorSpecificDocument } from '../../../../store/services';
import { useRequiredDocumentTypes } from '../../../../shared/hooks/award-bid/useRequiredDocumentTypes';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';
import { useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';
import { useVendorDocuments } from '../../../../shared/hooks/award-bid/vendor-document';
import { VendorDocumentUploadAPIRequest } from '../../../../types/awardBid';

export function VendorDocumentUploadForm() {
  /// Hooks
  const { refreshVendorDocuments } = useVendorDocuments();

  /// Application state

  const selectedBidAwardee = useRecoilValue(selectedBidAwardeeState);

  const { selectedBidId } = useSelectedBidId();
  const { supplierMemberId } = useSupplierMemberId();
  const { requiredDocTypes, refreshRequiredDocTypes, setRequiredDocTypes } =
    useRequiredDocumentTypes();

  // Get/Set dirty upload status
  const setDocumentUploadFormStarted = useSetRecoilState(documentUploadFormChangedState);

  /// Local state
  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);

  // Refresh requiredDocType Recoil state on mount, reset when unmounted.
  // TODO: wrap this function into the custom hook.
  useEffect(() => {
    refreshRequiredDocTypes();
    return () => {
      setRequiredDocTypes([]);
    };
  }, [refreshRequiredDocTypes, setRequiredDocTypes]);

  function handleUploadProgress(progressEvent: ProgressEvent) {
    const progress = (progressEvent.loaded / progressEvent.total) * 100;

    // Only update per >=5% increment to reduce state setting / redraw cycles.
    if (progress - documentUploadProgress >= documentUploadProgressThrottlePercentage) {
      setDocumentUploadProgress(Math.round(progress));
    }
  }

  /**
   * Process form values to make the api upload request.
   * @param {DocumentUploadFormValues} formValues
   */
  async function handleUploadFormSubmit(formValues: DocumentUploadFormValues) {
    const uploadFile: File = formValues.files[0];

    const apiRequestParameters: VendorDocumentUploadAPIRequest = {
      agencyDocDescription: formValues.documentTypeValue || '',
      agencyDocType: formValues.documentTypeValue || '',
      bidId: selectedBidId,
      documentTitle: formValues.documentTitle || uploadFile.name,
      docFormatType: uploadFile.type,
      documentType: formValues.documentTypeValue,
      filePath: uploadFile.name,
      fileSize: uploadFile.size,
      isPDFConversion: formValues.convertToPDF,
      memberId: supplierMemberId || defaultNumericId,
      originalFileName: uploadFile.name,
      supplierMemberId: selectedBidAwardee?.awardedToMemberId || defaultNumericId,
    };

    const data: FileUploadRequest<VendorDocumentUploadAPIRequest> = {
      ...apiRequestParameters,
      file: uploadFile,
    };

    // Make the upload request.
    return tryCatchLog(async () => {
      const uploadResponse = await uploadVendorSpecificDocument({ data, handleUploadProgress });
      if (uploadResponse?.message === StatusMessage.Success) {
        setDocumentUploadProgress(0);
        // Show success Toast
        showToastSuccess({
          message: `"${apiRequestParameters.documentTitle}" ${uploadMessages.successSuffix}`,
          autoClose: 5000,
        });
        // Refresh the list if the upload succeeds.
        return tryCatchLog(async () => {
          await refreshVendorDocuments();
          setDocumentUploadFormStarted(false);
        }, 'VendorDocumentUploadForm -> handleUploadFormSubmit -> refreshVendorDocuments()');
      }
    }, 'VendorDocumentUploadForm -> handleUploadFormSubmit()');
  }

  return (
    <>
      <DocumentUploadForm
        dataTestId='vendor-document-upload'
        docTypeLabel={AwardBidLabels.awardDocumentType}
        docTypes={requiredDocTypes}
        handleFormSubmit={handleUploadFormSubmit}
        uploadProgress={documentUploadProgress}
      />
      <AddRequiredDocumentTypePanel />
    </>
  );
}
