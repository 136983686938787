import React, { memo } from 'react';
import { appKeys } from '../../../../../../utils/constants';
import { loadingMsg, noresultFound } from '../../../../../../utils/texts';
import {
  deprecatedGetDateTimeZoneConverted,
  formatPrice,
  formattedPhoneNumber,
} from '../../../../../../utils/helpers';
import { dateTo12HourFormat } from '../../../../../common/deprecatedDateConverter';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';
import { Buttons, NoResult } from '../../../../../customcontrols/index';
import { bidItemParamType, bidssummaryParamType } from '../../../../../../types/biddetail';
import { TimeZone } from '../../../../../../utils/constants';
const tdWidth = {
  width: '120px',
};

interface PropsTypes {
  bidssummary?: bidssummaryParamType;
  handleClick?: any;
  exportLegalAd?: any;
  pageFor?: string | undefined;
  data: bidItemParamType;
}

const legalAdContent = (props: PropsTypes) => {
  const { bidssummary, handleClick, exportLegalAd, pageFor } = props;
  const { results, legalAd, writerInfo: writerData, legalAddLoading = false } = bidssummary || {};
  const { writerInfo, memberInfo } = writerData || {};
  const {
    memberID,
    bidID: bidId,
    tzfn,
    contactInfoVisibility,
    contactInfo,
    contactName,
    isExternalBid,
  } = results || {};

  const handleExportClick = () => {
    exportLegalAd({ id: memberID, bidId });
  };

  const {
    agencyName = '',
    documentHandlerType = '',
    shownAmount = '',
    legalAdCostInfo = '',
    name = '',
    displayDocCosts = '',
    displayBudgetToSuppliers = '',
    projectEstimatedBudget = '',
    memberBidTypeDesc = '',
    bidName = '',
    bidBond = '',
    memberBidType = '',
    bidNumber = '',
    bidSequenceNumber = '',
    bidYear = '',
    initials = '',
    pbcMemo = '',
    dueDateTime = '',
    legalAdAdditional = '',
    legalAdClosing = '',
    legalAdIntro = '',
    legalAdDueDateTime = '',
    timeZoneShortName = '',
    firstName = '',
    lastName = '',
    // memberPhoneNumber = '',
    phoneNumber = '',
    scopeOfWork = '',
    phoneExtension = '',
    publications = [],
  } = legalAd || {};

  setTimeout(() => {
    if (pageFor === 'DistributionInfo') {
      const Element = document.getElementById('distributionInfoRef');
      if (Element) {
        Element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, 500);

  const distributionInfo = () => (
    <>
      <h4 className='lineHR' id='distributionInfoRef'>
        Ordering Instructions
      </h4>
      <div className='innerColIndent'>
        {
          <Table borderless className='tableData'>
            {shownAmount > 0 ? (
              <tr>
                <th style={tdWidth}>Package Cost</th>
                <td>{shownAmount > 0 ? `$${formatPrice(shownAmount)}` : null}</td>
              </tr>
            ) : null}
            {props.data.fulfillmentEmail ? (
              <tr>
                <th style={tdWidth}>Contact Info</th>
                <td>{props.data.fulfillmentEmail}</td>
              </tr>
            ) : null}
            {props.data.distributionNotes && props.data.distributionNotes !== 'None' ? (
              <tr>
                <th style={tdWidth}>Fulfillment Notes</th>
                <td>{props.data.distributionNotes}</td>
              </tr>
            ) : null}
          </Table>
        }
      </div>
    </>
  );

  if (agencyName) {
    const mailInfo = props.data.fulfillmentEmail ? props.data.fulfillmentEmail : null;
    const orderingInstructions =
      documentHandlerType === 'AG' || documentHandlerType === 'TP' ? (
        <div>
          {shownAmount > 0 ||
          props.data.fulfillmentEmail ||
          (props.data.distributionNotes && props.data.distributionNotes !== 'None')
            ? distributionInfo()
            : null}

          <h4 className='lineHR'>Document Cost</h4>
          <div className='innerColIndent'>
            {' '}
            <p>{legalAdCostInfo}</p>
          </div>
        </div>
      ) : (
        <>
          <h4 className='lineHR'>Ordering Instructions</h4>
          <div className='innerColIndent'>
            <p>
              This package can be downloaded from {name} on our website at
              http://www.demandstar.com.
            </p>
          </div>
          {displayDocCosts ? (
            <>
              {' '}
              <h4 className='lineHR'>Document Cost</h4>
              <div className='innerColIndent'>
                <p>{legalAdCostInfo}</p>
                <p>{mailInfo}</p>
              </div>
            </>
          ) : null}
        </>
      );

    const estimatedBudget =
      displayBudgetToSuppliers && projectEstimatedBudget > 0 ? (
        <>
          <h4 className='lineHR'>Project Estimated Budget</h4>
          <div className='innerColIndent'>
            <p>{projectEstimatedBudget ? `$${formatPrice(projectEstimatedBudget)}` : ''}</p>
          </div>
        </>
      ) : null;

    const preBidProposal = (
      <>
        {pbcMemo ? (
          <>
            <h4 className='lineHR'>Pre/Bid Proposal Conference</h4>
            <div className='innerColIndent'>
              <p>{pbcMemo}</p>
            </div>
          </>
        ) : null}
      </>
    );

    const dueDate = (
      <>
        <h4 className='lineHR'>Due Date/Time</h4>{' '}
        <div className='innerColIndent'>
          <p>
            {dueDateTime ? (
              <>
                {deprecatedGetDateTimeZoneConverted(dueDateTime, tzfn)} {timeZoneShortName}
              </>
            ) : (
              dueDateTime
            )}
          </p>
        </div>
      </>
    );
    const bidWriter = firstName ? (
      <>
        <tr>
          <th style={tdWidth}>Contact</th>
          <td>
            {firstName} {lastName}, {writerInfo && writerInfo.jt}
          </td>
        </tr>
        {phoneNumber ? (
          <tr>
            <th style={tdWidth}>Phone</th>
            <td>{formattedPhoneNumber(phoneNumber, phoneExtension)}</td>
          </tr>
        ) : null}
      </>
    ) : null;
    const bonfireContactInfo =
      contactInfoVisibility === 1 ? (
        <>
          <tr>
            <th style={tdWidth}>Contact</th>
            <td>{contactName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Email</th>
            <td>{contactInfo}</td>
          </tr>
        </>
      ) : null;
    const bidBondComponent = bidBond ? (
      <>
        <h4 className='lineHR'>Bid Bond</h4>
        <div className='innerColIndent'>
          <p> {bidBond} </p>
        </div>
      </>
    ) : null;

    const additionalNotes =
      legalAdAdditional || legalAdClosing || (isExternalBid && contactInfoVisibility === 1) ? (
        <>
          <h4 className='lineHR'>Additional Notes</h4>
          {legalAdAdditional || legalAdClosing ? (
            <div className='innerColIndent'>
              <p>{legalAdAdditional}</p>
              <p>{legalAdClosing}</p>
            </div>
          ) : null}
        </>
      ) : null;
    const title = `${appKeys.headers.legalAd} - ${bidName} - ${appKeys.name}`;

    return (
      <div className='legalAdsWrapper'>
        <title>{title}</title>
        <header>
          <h5>{agencyName}</h5>
          <p>
            {`${memberInfo && memberInfo.a1 ? memberInfo.a1 : ''} ${
              memberInfo && memberInfo.a2 ? ', ' : ''
            } ${memberInfo && memberInfo.a2 ? memberInfo.a2 : ''}`}
            <br />
            {`${memberInfo && memberInfo.ct ? memberInfo.ct : ''}
							${memberInfo && memberInfo.st ? ', ' : ''}
							${memberInfo && memberInfo.st ? memberInfo.st : ''}
							${memberInfo && memberInfo.pc ? ', ' : ''}
							${memberInfo && memberInfo.pc ? memberInfo.pc : ''}`}
          </p>
          <h6>{memberBidTypeDesc ? memberBidTypeDesc : null}</h6>
          <h6>{bidName}</h6>
          <h6>
            {memberBidType +
              '-' +
              bidNumber +
              '-' +
              bidSequenceNumber +
              '-' +
              bidYear +
              '/' +
              initials}
          </h6>
          <p className='mb-0'>{legalAdIntro ? legalAdIntro : null}</p>
        </header>
        <main>
          {scopeOfWork ? (
            <>
              <h4 className='lineHR'>Scope of Work</h4>
              <div className='innerColIndent'>
                <>
                  <div dangerouslySetInnerHTML={{ __html: scopeOfWork }} />
                </>
              </div>
            </>
          ) : null}
          {orderingInstructions}
          {bidBondComponent}
          {estimatedBudget}
          {preBidProposal}
          {dueDate}
          <div className='innerColIndent'>
            <p>{agencyName}</p>
            <p>{legalAdDueDateTime}</p>
          </div>
          {additionalNotes}
        </main>
        <footer>
          <Table borderless className='tableData'>
            {!isExternalBid ? bidWriter : bonfireContactInfo}
            {publications && publications.length > 0 ? (
              <tr>
                <th>Publications</th>
                <td>
                  {publications.map((publication, i) => (
                    <span key={i}>
                      {' '}
                      {publication.publication}
                      {', '}
                    </span>
                  ))}
                </td>
              </tr>
            ) : null}
          </Table>
        </footer>
        <div className='d-flex flex-column flex-md-row justify-content-between mt-4'>
          <Buttons classNames='bttn-secondary mb-2' text='Close' onClick={handleClick} />
          <Buttons classNames='bttn-primary mb-2' text='Export' onClick={handleExportClick} />
        </div>
      </div>
    );
  } else {
    return <NoResult message={legalAddLoading === true ? loadingMsg : noresultFound} />;
  }
};

export default memo(legalAdContent);
