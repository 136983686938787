import { WizardStatus } from '../../../types/wizard';

export function baseWizardBehavior(
  wizardId: number,
  currentWizardId: number,
  behavior?: () => WizardStatus,
) {
  if (currentWizardId === wizardId) {
    return WizardStatus.Current;
  }
  if (behavior) {
    return behavior();
  }
  if (currentWizardId > wizardId) {
    return WizardStatus.Completed;
  }
  return WizardStatus.Unavailable;
}
