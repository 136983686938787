import { PropsWithChildren, ReactNode } from 'react';

import styled from 'styled-components/macro';

import {
  BackgroundColor,
  BorderColor,
  BorderThickness,
  FlexContainer,
  FlexContainerColumn,
  FontWeight,
  H1,
  H3,
  H4,
  LineHeight,
  Margin,
  Padding,
  TextColor,
} from '@demandstar/components/styles';

import { Id } from '@demandstar/components/types';
import { ToggleExpandIcon } from '@demandstar/components/icon/ToggleExpandIcon';
import { ToggleIconWrapper } from '@demandstar/components/tree/DSTree.styles';

import { FontSize } from 'src/shared/styles';
import { scrollToTop } from 'src/utils/helpers';

export const BannerOverlay = styled.div<{ bgImageURL?: string }>`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: ${TextColor.Contrast};
  display: flex;
  column-gap: ${Padding.Base};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Title};
  padding: ${Padding.Extra};
  position: absolute;
  width: 100%;
`;

export const ContentWrapper = styled.div<{ bgImageURL?: string }>`
  background-color: ${BackgroundColor.BaseWhite};
  margin: ${Margin.None};
  padding: ${Padding.Extra};
`;

export const HeaderContainer = styled(FlexContainer)`
  align-items: flex-start;
  background-color: ${BackgroundColor.BaseWhite};
  border-bottom: solid ${BorderThickness.Base} ${BorderColor.Table};
  min-height: 22.5rem;
  padding: ${Padding.None} ${Padding.Extra};
  position: relative;
`;

export const HeaderTitleColumn = styled(FlexContainerColumn)`
  row-gap: ${Margin.Small};
`;

export const HeaderTitle = styled(H1)`
  display: flex;
  justify-content: space-between;
  padding: ${Padding.Less} ${Padding.Extra};
`;

export function HeaderWithScrollToTop(props: PropsWithChildren<ReactNode & Id>) {
  return (
    <HeaderTitle id={props.id}>
      {props.children}
      <ToggleIconWrapper selected title={'top'} onClick={scrollToTop}>
        <ToggleExpandIcon />
      </ToggleIconWrapper>
    </HeaderTitle>
  );
}

export const ScrollLinkContainer = styled(FlexContainer)`
  padding-bottom: ${Padding.Less};
  padding-left: ${Padding.Less};
`;

export const IntroTextWrapper = styled.div`
  font-size: ${FontSize.SubTitle};
  line-height: ${LineHeight.Subheader};
`;

export const IntroTextList = styled.ul`
  list-style: none;
  li {
    padding: ${Padding.Less} ${Padding.None};
  }
`;

export const BidListTitle = styled(H3)`
  margin-top: ${Margin.Base};
`;

export const BidListSubTitle = styled(H4)`
  margin-top: ${Margin.Less};
  a {
    font-size: inherit;
  }
`;

export const ClearFiltersLinkContainer = styled(FlexContainer)`
  margin: ${Margin.Base} ${Margin.None};
`;
