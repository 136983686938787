import { Field } from 'formik';
import { memo } from 'react';

import { getTimeIntervals, getTimeZone } from '../../../../utils/helpers';

interface PropsTypes {
  component?: any;
  timeFieldName?: string;
  zoneFieldName?: string;
  parentClass?: string;
  disabled?: any;
  optional?: any;
  reduxform?: any;
  label?: string;
  interval?: number;
  setFieldValue: any;
}

/** @deprecated use <DSTimeField showTime/> instead */
function TimePicker(props: PropsTypes) {
  const {
    component,
    timeFieldName,
    zoneFieldName,
    parentClass,
    disabled = false,
    optional = false,
    label = 'Time',
    interval = 15,
    setFieldValue,
  } = props;

  return (
    <div className={'timePickerWrapper ' + parentClass + (optional ? ' optional' : '')}>
      <label>{label}</label>
      <div>
        <Field
          label={''}
          type='select'
          classNames='class'
          placeholder='Due Time'
          name={timeFieldName}
          component={component}
          onChange={setFieldValue}
          options={getTimeIntervals(interval)}
          disabled={disabled}
        />
        <Field
          label=''
          type='select'
          classNames='class'
          placeholder='AM / PM'
          name={zoneFieldName}
          component={component}
          options={getTimeZone()}
          onChange={setFieldValue}
          disabled={disabled}
        />
      </div>
      {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
    </div>
  );
}

/** @deprecated use <DSTimeField showTime/> instead */
export default memo(TimePicker);
