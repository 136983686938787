export const activitiesQuoteStatusFields = [
  { value: '', label: 'All' },
  { value: 'AW', label: 'Awarded' },
  { value: 'CA', label: 'Cancelled' },
  { value: 'CL', label: 'Closed' },
  { value: 'DE', label: 'Deleted' },
  { value: 'OP', label: 'Open' },
];

export const AgencyQuoteStatusList = [
  { value: 'AW', label: 'Awarded' },
  { value: 'CA', label: 'Cancelled' },
  { value: 'CL', label: 'Closed' },
  { value: 'IN', label: 'Incomplete' },
  { value: 'OP', label: 'Open' },
];

export const editQuoteStatus = ['IN'];

export const quoteBreakdownStatus = ['RS', 'NQ', 'VW'];

export const quoteExternalStatus = {
  awarded: 'AW',
  cancelled: 'CA',
  closed: 'CL',
  incomplete: 'IN',
  open: 'OP',
  published: 'PB',
};

export const quoteExternalStatusNames = {
  Awarded: 'AW',
  Cancelled: 'CA',
  Closed: 'CL',
  Incomplete: 'IN',
  Open: 'OP',
  Published: 'PB',
};

export const quoteReportsActivityPaginationsOptions = [
  { label: '10', value: 10 },
  { label: '50', value: 50 },
];

export const quoteResponsesSortFields = {
  price: 'Price',
  respondedDate: 'Date',
  supplierName: 'Name',
};

export const quoteSupplierFilter = { label: 'Submitted Quote', value: 'RS' };

export const reportotherquoteStatus = 'IN,OP,CL,AW,CA';

export const reportquoteStatus = [
  { label: 'All', value: 'all' },
  { label: 'Awarded', value: 'AW' },
  { label: 'Cancelled', value: 'CA' },
  { label: 'Closed', value: 'CL' },
  { label: 'Incomplete', value: 'IN' },
  { label: 'Open', value: 'OP' },
];

export const SupplierQuoteStatusList = [
  { value: 'AW', label: 'Awarded' },
  { value: 'CA', label: 'Cancelled' },
  { value: 'CL', label: 'Closed' },
  { value: 'OP', label: 'Open' },
];

export const updateQuoteDuedatePermission = ['OP'];

export const updateQuoteStatusPermission = ['CL', 'IN', 'OP'];

export const quotesDataConstantsLeftMenu = [
  { title: 'Open', categoryType: quoteExternalStatus.open, count: 0 },
  { title: 'Incomplete', categoryType: quoteExternalStatus.incomplete, count: 0 },
  { title: 'Awarded', categoryType: quoteExternalStatus.awarded, count: 0 },
  { title: 'Closed', categoryType: quoteExternalStatus.closed, count: 0 },
  { title: 'Cancelled', categoryType: quoteExternalStatus.cancelled, count: 0 },
  { title: 'My Quotes', categoryType: 'MQ', count: 0 },
];
