import { AppliedFilter, SearchInputType } from './DSSearch.d';

export function hasSearchValue<T extends Record<string, unknown>>(filter: AppliedFilter<T>) {
  // If filter value is truthy, add it back to the list with new value
  if (filter.inputType === SearchInputType.DateRange) {
    const range = filter.value;
    if (Array.isArray(range) && (range[0] || range[1])) {
      return true;
    }
  } else if (filter.value) {
    return true;
  }
  return false;
}
