/** */
export enum WizardStatus {
  Current = 'current',
  Unavailable = 'notstarted',
  Available = 'available',
  Completed = 'completed',
  Error = 'error',
}

export type WizardPage<IdType = number, NameType = string> = {
  id: IdType;
  status: WizardStatus;
  name: NameType;
  navName?: string;
};
