import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { cartRenewalState } from 'src/store/recoil/productState';
import { useCart } from '.';
import { useSelectedProducts } from 'src/shared/hooks/useSelectedProducts';

/**
 * @description uses `useCart` functionality to keep cartTotal in sync and duplicate products out of the cart
 * @example useRefreshCartTotal();
 */
export function useRefreshCartTotal() {
  const { refreshCartTotal } = useCart();
  const { clearDuplicateProducts, selectedProducts } = useSelectedProducts();
  const setCartRenewal = useSetRecoilState(cartRenewalState);

  useEffect(() => {
    refreshCartTotal();
  }, [refreshCartTotal]);

  useEffect(() => {
    clearDuplicateProducts();
  }, [clearDuplicateProducts]);

  useEffect(() => {
    if (
      selectedProducts.county?.length ||
      selectedProducts.state?.length ||
      selectedProducts.national
    )
      setCartRenewal(false);
  }, [selectedProducts, setCartRenewal]);
}
