import React, { memo } from 'react';
import { DeprecatedInput } from './index';
import { toastFn } from '../../utils/helpers';

interface PropsTypes {
  content: string;
}

const CopyBox = (props: PropsTypes) => {
  const { content } = props;

  const copytoClipBoard = () => {
    navigator.clipboard.writeText(content);
    toastFn('success', 'Copied', 'CLIPBOARD_COPY');
  };

  const OpenNewTab = () => {
    window.open(content, '_blank');
  };

  return (
    <>
      <div className='d-block'>
        <DeprecatedInput
          label='Agency Page URL'
          value={content}
          disabled={true}
          parentClass='d-inline-block w-75 clear-both'
        />
        <i
          className='mdi mdi-content-copy mdi-24px d-inline-block ml-2 staticLink'
          title='Copy URL'
          style={{ position: 'relative', top: '5px' }}
          onClick={copytoClipBoard}
        />
        <i
          className='mdi mdi-open-in-new mdi-24px d-inline-block ml-2 staticLink'
          title='Open in new tab'
          style={{ position: 'relative', top: '5px' }}
          onClick={OpenNewTab}
        />
      </div>
    </>
  );
};

export default memo(CopyBox);
