import { PropsWithChildren, ReactNode } from 'react';

import { LoadingTextWrapper, NotFoundText } from '../../../shared/styles';
import { InfoIcon } from '../icons';

export type InfoPanelProps = PropsWithChildren<ReactNode> & {
  message: string;
};
export function InfoPanel(props: InfoPanelProps) {
  return (
    <LoadingTextWrapper>
      <InfoIcon width={18} height={18} />
      <NotFoundText>{props.message}</NotFoundText>
      {props.children}
    </LoadingTextWrapper>
  );
}
