import { useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSRadio } from '@demandstar/components/inputs';

import { AwardWizardId, newUnselectedPlanholders } from '../../../../../store/recoil/award-bid';
import { hasValue, isValidEmailInfo } from '../../../../../utils/helpers';
import { AddUnselectedBidders } from './AddUnselectedBidders';
import { BidEmailPreview } from './../BidEmailPreview';
import { commonLabels } from 'src/shared/constants';
import { CustomMessageType } from '../../../../../types/awardBid';
import { EditEmailInfo } from './../EditEmailInfo';
import { LoadableWrapper } from '../../../../common/loading/LoadableWrapper';
import { MessagePreview } from './../MessagePreview';
import { notifyUnselectedState } from '../../../../../store/recoil/award-bid';
import { useAwardBidWizard } from '../../../../../shared/hooks';
import { WizardButtons } from '../../../../customcontrols/wizard/WizardButtons';

export const NotifyUnselectedBidders = () => {
  // Hooks
  const { saveAwardBidWizard, returnToBidSummary, messagePreview, setMessagePreview } =
    useAwardBidWizard();

  // Recoil
  const emailInfo = useRecoilValueLoadable(notifyUnselectedState);
  const newPlanholders = useRecoilValue(newUnselectedPlanholders);

  // Local
  const [showRecipients, setShowRecipients] = useState(false);

  const allPotentialPlanholders = useMemo(() => {
    if (hasValue(emailInfo)) {
      return [
        ...emailInfo.contents.recipients,
        ...newPlanholders.map(planholder => {
          return {
            email: planholder.email,
            contactName: planholder.name,
            memberName: planholder.name,
          };
        }),
      ];
    }
    return [];
  }, [emailInfo, newPlanholders]);

  const hasPotentialUnselected = !!allPotentialPlanholders.length;

  const shouldPreview =
    // not currently in preview mode
    !messagePreview &&
    // should notify is selected
    hasValue(emailInfo) &&
    emailInfo.contents.shouldNotify &&
    // there are actual bidders to notify
    hasPotentialUnselected;

  function onPrevious() {
    if (messagePreview) {
      setMessagePreview(false);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyAwardees);
    }
  }

  /**
   * Shows a preview if on the first page AND there are unselected bidders.
   * If there are no unselected bidders, or we're already on the preview,
   * it will take you to NotifyColleagues.
   */
  function onNext() {
    if (shouldPreview) {
      setMessagePreview(true);
    } else {
      saveAwardBidWizard(AwardWizardId.NotifyColleagues);
    }
  }

  return (
    <>
      <LoadableWrapper loadable={emailInfo}>
        {hasValue(emailInfo) && (
          <>
            {messagePreview ? (
              <MessagePreview to={allPotentialPlanholders} subject={emailInfo.contents.subject}>
                <BidEmailPreview
                  emailInfo={emailInfo.contents}
                  addressee={
                    allPotentialPlanholders.length === 1
                      ? allPotentialPlanholders[0].contactName ||
                        allPotentialPlanholders[0].memberName ||
                        ''
                      : '{{COMPANY_NAME}}'
                  }
                />
              </MessagePreview>
            ) : (
              <EditEmailInfo
                emailInfoState={notifyUnselectedState}
                customMessageType={CustomMessageType.NonAwardees}
              >
                Did any suppliers submit responses outside of DemandStar?
                <FlexContainer>
                  <Flex>
                    <DSRadio
                      name='yes'
                      checked={showRecipients}
                      onSelect={() => setShowRecipients(true)}
                      label={commonLabels.yes}
                    />
                  </Flex>
                  <Flex>
                    <DSRadio
                      name='no'
                      checked={!showRecipients}
                      onSelect={() => setShowRecipients(false)}
                      label={commonLabels.no}
                    />
                  </Flex>
                </FlexContainer>
                {showRecipients && <AddUnselectedBidders />}
              </EditEmailInfo>
            )}
          </>
        )}
      </LoadableWrapper>

      <WizardButtons
        onPrevious={onPrevious}
        onNext={onNext}
        altNext={shouldPreview ? 'Preview' : undefined}
        save={returnToBidSummary}
        inactiveNext={
          !hasValue(emailInfo) ||
          !isValidEmailInfo(emailInfo.contents, CustomMessageType.NonAwardees)
        }
      />
    </>
  );
};
