import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

import Suspenseloader from '../../common/splash/suspenseloader';

// import { loginUsingCookie } from 'src/store/actions';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useHistory } from 'react-router-dom';
import {
  BROADCAST_CHANNEL_AUTH_ID,
  broadcastChannelReload,
} from 'src/utils/helpers/broadcast-channel';
import { deleteCookie, getCookie } from 'src/utils/cookie';
import { CookieredirectUrl } from 'src/utils/constants';
import { useRouter } from 'src/shared/hooks';

export function LoginFromCookie() {
  const dispatch = useDispatch();
  const { handleStoredLogin } = useAuthContext();
  const { push } = useHistory();
  const bcAuth = useMemo(() => new BroadcastChannel(BROADCAST_CHANNEL_AUTH_ID), []);
  const { history } = useRouter();

  useEffect(() => {
    // dispatch(loginUsingCookie({}));
  }, [dispatch]);

  useEffect(() => {
    broadcastChannelReload(bcAuth);
    handleStoredLogin();
    const redirectURL = getCookie(CookieredirectUrl);
    if (redirectURL) {
      history.push(redirectURL);
      deleteCookie(CookieredirectUrl);
    } else push('/');
  }, [handleStoredLogin, push, bcAuth, history]);

  return <Suspenseloader />;
}
