import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import {
  SearchFilter,
  SearchFilters,
  SearchInputType,
  SearchInputWidth,
} from '@demandstar/components/search';
import { SortOption } from '@demandstar/components/sort';

import * as texts from '../Reminders.texts';
import { ContractReminder } from './reminders';
import { ContractSearchResult } from '../../../contract-management';
import { useContractSearch } from '../../../search';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';
import { useReminders } from './useReminders';
import { useRouter } from 'src/shared/hooks';

type ReminderFilters = {
  contract: ContractSearchResult;
  includeUpcoming: boolean;
};

export function useReminderSearch() {
  const { allContractsLoadable } = useContractSearch();
  const { contractId } = useIdFromRoute();
  const { remindersLoadable } = useReminders();
  const { searchParams } = useRouter();
  const urlReminderId = searchParams.get('reminder');

  const filteredContracts = useMemo(() => {
    return remindersLoadable.map(allReminders => {
      const reminderContractIdList = contractId
        ? [contractId]
        : allReminders.map(reminder => {
            return reminder.contractId;
          });

      return allContractsLoadable.map(contracts => {
        return contracts?.filter(contract => {
          return (
            reminderContractIdList.findIndex(id => {
              return contract.id === id;
            }) !== -1
          );
        });
      });
    });
  }, [allContractsLoadable, contractId, remindersLoadable]);

  const filters = useMemo(() => {
    const contractFilter: SearchFilter<ContractSearchResult, ReminderFilters> = {
      defaultValue: contractId
        ? filteredContracts.map(contracts => contracts.find(c => c.id === contractId)).valueMaybe()
        : undefined,
      getDisplayValue: (value?: ContractSearchResult | undefined) => value?.name,
      inputType: SearchInputType.Select,
      label: texts.formLabels.contract,
      name: 'contract',
      optionLabel: 'name',
      options: filteredContracts,
      readOnly: !!contractId,
      width: SearchInputWidth.Full,
    };

    const upcomingFilter: SearchFilter<boolean, ReminderFilters> = {
      defaultValue: !!urlReminderId,
      displayName: 'Include Upcoming Reminders',
      inputType: SearchInputType.Checkbox,
      label: texts.formLabels.upcoming,
      name: 'includeUpcoming',
      width: SearchInputWidth.Full,
    };

    return [contractFilter, upcomingFilter] as SearchFilters<ReminderFilters>;
  }, [contractId, filteredContracts, urlReminderId]);

  const search = useCallback(
    async ({ contract, includeUpcoming }: Partial<ReminderFilters>) => {
      const reminders = await remindersLoadable.toPromise();
      return reminders.filter(
        r =>
          !r.dismissed &&
          (!contract || r.contractId === contract.id) &&
          (!!includeUpcoming || r.dueDate.isBefore(dayjs().endOf('day'))),
      );
    },
    [remindersLoadable],
  );

  const defaultSort: SortOption<ContractReminder> = useMemo(
    () => ({
      label: 'Default',
      sort: (r1, r2): number => {
        // defining these as consts for more legible code
        const equivalent = 0;
        const lessThan = -1;
        const greaterThan = 1;

        if (urlReminderId && [r1.id, r2.id].includes(urlReminderId)) {
          return r1.id === urlReminderId ? lessThan : greaterThan;
        }

        // If the reminder date is today, it comes first
        if (r1.dueDate.isSame(dayjs())) {
          // If both reminder dates are from today, they are equivalent, otherwise r1 comes first
          return r2.dueDate.isSame(dayjs(), 'day') ? equivalent : lessThan;
        }

        // If the reminder date is before today, it comes after items from today, and before items from the future
        if (r1.dueDate.isBefore(dayjs())) {
          // If r2 is from today, it comes first
          return r2.dueDate.isSame(dayjs(), 'day')
            ? greaterThan
            : // If r2 is ALSO from before today, sort by standard date ascending
            r2.dueDate.isBefore(dayjs(), 'day')
            ? r1.dueDate.isBefore(r2.dueDate)
              ? lessThan
              : greaterThan
            : // If r2 is from the future, it comes after
              lessThan;
        }

        // Default sort - sort by date ascending (r1 is in the future, so no need to check if r2 is before/on today)
        return r1.dueDate.isBefore(r2.dueDate) ? lessThan : greaterThan;
      },
    }),
    [urlReminderId],
  );

  return {
    /** The `SortOption<ContractReminder>` to use as the default sort */
    defaultSort,
    /** The filters available for searching contract reminders. Currently `contractId` and `upcoming` */
    filters,
    /** The search function used to filter reminders */
    search,
    /** The reminder ID passed via URL query params */
    urlReminderId,
  };
}
