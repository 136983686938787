import styled from 'styled-components';

import {
  BackgroundColor,
  FlexDirection,
  FontWeight,
  Padding,
  TextColor,
  TextTransform,
} from '@demandstar/components/styles';
import { BaseButton, ButtonStyleProps } from '@demandstar/components/button';

import { mediaQueries } from '@demandstar/components/styles';

export const HeaderContainer = styled.nav`
  align-items: flex-start;
  background-color: ${BackgroundColor.BaseWhite};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 4.375rem;
  padding: ${Padding.Extra} ${Padding.Base} ${Padding.Less} ${Padding.Base};
  position: relative;
`;

export const NavListContainer = styled.ul<{ direction?: FlexDirection; visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  list-style: none;
  /* flex-direction: ${({ direction }) => direction || 'row'}; */
  column-gap: ${Padding.Extra};
  row-gap: ${Padding.Extra};
  ${mediaQueries('Small')`
    flex-direction: column;
  `};
`;

export const SignUpButton = styled(BaseButton).attrs<ButtonStyleProps>(
  ({ type }: ButtonStyleProps) => ({
    // Pass appearance converted from type
    appearance: type === 'submit' ? 'primary' : type === 'reset' ? 'secondary' : type,
  }),
)`
  padding: 0.75rem 2rem;
`;

export const HeaderLink = styled.a`
  color: ${TextColor.Base};
  display: block;
  font-weight: ${FontWeight.Bold};
  padding-top: ${Padding.Base};
  text-decoration: none;
  text-transform: ${TextTransform.Uppercase};
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  padding-bottom: ${Padding.Extra};
  display: flex;
`;

export const NavigationMenuToggleButtonContainer = styled.div`
  display: none;
  ${mediaQueries('Small')`
    display: block;
  `};
`;
