import { Field, Formik } from 'formik';
import { memo, useEffect, useState } from 'react';

import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import { AddBidWizardButtons } from './AddBidWizardButtons';
import renderTextAreaField from '../controls/inputs/textarea';

import { addbidParamType } from '../../../types/addbid';
import { Buttons } from '../../customcontrols/index';
import { ModalPopUp } from '../modals/ModalPopUp';
import { toastFn } from '../../../utils/helpers';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardPage } from '../../../types/wizard';
import { DeprecatedFormikRadio } from '../controls/inputs/DeprecatedFormikRadio';

interface PropsTypes {
  handleSubmit?: any;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  addbid?: addbidParamType;
  setAddBidDetails?: any;
  currentwizard: WizardPage;
  bidId?: number;
  submitAddBidUpdateMemo?: any;
  currentBidName?: string;
  SkipNext?: any;
  initialValues: any;
}

function Prebidconference(props: PropsTypes) {
  const [showRecipients, setShowRecipients] = useState(false);
  const {
    valid,
    pristine,
    submitting,
    addbid,
    setAddBidDetails,
    currentwizard,
    bidId,
    submitAddBidUpdateMemo,
    currentBidName,
    SkipNext,
    initialValues,
  } = props;
  const {
    editable,
    prebidconference,
    updatedConferenceMessage = false,
    modalNotifyPreBid = false,
  } = addbid || {};
  const { pbcMemo, preBidMandatory } = prebidconference || {};
  const mandatoryRadioBtnYesText = [<strong key={1}>Yes</strong>];
  const mandatoryRadioBtnNoText = [<strong key={1}>No</strong>];
  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useSetBreadcrumb(
    {
      component: (
        <AddBidControl
          valid={valid}
          pristine={pristine}
          submitting={submitting}
          bidId={bidId}
          pristineOpt={true}
        />
      ),
      // TODO: Create consistency between 'Update Bid' and 'Bid Creation'.
      // How do we use these terms? How can we make all titles consistent?
      page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
      title: currentBidName
        ? `Bid Update - ${currentwizard?.name}`
        : `Bid Creation - ${currentwizard?.name}`,
      altname: currentBidName
        ? `Bid Update - ${currentwizard?.name}`
        : `Bid Creation - ${currentwizard?.name}`,
      name: currentBidName ? currentBidName : 'Bid Creation',
      id: bidId,
      menuactive: 'bids',
    },
    currentwizard !== undefined,
  );

  const placeholder =
    'September 10, 2022 10:00 AM County Courthouse 999 Anystreet Room 999 Anytown, XX. www.urlforvideoconference.com';
  const [formData, setFormData] = useState({});

  const onSubmitFn = (data: any) => {
    if (data) {
      if (editable && ['all', 'active'].includes(editable)) {
        if (data.preBidMandatory === true && data.pbcMemo.length <= 0) {
          toastFn('error', 'Enter Pre-Bid Conference', 'PreBidConference');
        } else {
          if (editable === 'active' && updatedConferenceMessage) {
            setAddBidDetails({ modalNotifyPreBid: true, updatedConferenceMessage: false });
            setFormData(data);
          } else {
            submitAddBidUpdateMemo({ bidId, data });
          }
        }
      } else {
        SkipNext();
      }
    }
  };

  const handleNotifyclick = (isNotify: boolean) => {
    submitAddBidUpdateMemo({ bidId, isNotify, data: formData });
    setAddBidDetails({ modalNotifyPreBid: !modalNotifyPreBid });
  };

  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-lg-12 '>
                  <div className='innerColIndent'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='d-inline-flex'>
                          <b className='MandatoryOptRadioBtn'>Pre-bid meeting is required</b>
                          <Field
                            name='preBidMandatory'
                            label='preBidMandatory'
                            title={mandatoryRadioBtnYesText}
                            component={DeprecatedFormikRadio}
                            type='radio'
                            disabled={
                              (editable && !['all', 'active'].includes(editable)) || !editable
                            }
                            value={formikProps.values.preBidMandatory}
                            defaultChecked={formikProps.values.preBidMandatory}
                            handleSelect={(name: string, value: boolean) => {
                              setAddBidDetails({
                                updatedConferenceMessage: true,
                              });
                              setFieldValue('preBidMandatory', true);
                              setAddBidDetails({ preBidMandatory: true });
                            }}
                          />
                          <Field
                            label='preBidMandatory'
                            name='preBidMandatory'
                            title={mandatoryRadioBtnNoText}
                            component={DeprecatedFormikRadio}
                            type='radio'
                            disabled={
                              (editable && !['all', 'active'].includes(editable)) || !editable
                            }
                            value={formikProps.values.preBidMandatory}
                            defaultChecked={!formikProps.values.preBidMandatory}
                            handleSelect={(name: string, value: boolean) => {
                              setAddBidDetails({
                                updatedConferenceMessage: false,
                              });
                              setFieldValue('preBidMandatory', false);
                              setAddBidDetails({ preBidMandatory: false });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-6'>
                        <Field
                          label='Your Entry'
                          classNames='class'
                          placeholder={placeholder}
                          name='pbcMemo'
                          component={renderTextAreaField}
                          disabled={
                            (editable && !['all', 'active'].includes(editable)) || !editable
                              ? true
                              : false
                          }
                          remainShow={true}
                          maxLength={1024}
                          onChange={(name: string, value: any) => {
                            setFieldValue('pbcMemo', value);
                            setAddBidDetails({
                              updatedConferenceMessage: value !== pbcMemo ? true : false,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <AddBidWizardButtons
                      currentWizard={props.currentwizard}
                      submit={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <ModalPopUp
        title={'Pre-Bid Conference'}
        size='md'
        isOpen={modalNotifyPreBid}
        closeModal={() => handleNotifyclick(false)}
      >
        <p>Do you want to notify planholders of the updated Pre-Bid Conference information?</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='No'
          title='No'
          type='button'
          onClick={() => handleNotifyclick(false)}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Yes'
          title='Yes'
          type='button'
          onClick={() => handleNotifyclick(true)}
        />
      </ModalPopUp>
    </>
  );
}
export default memo(Prebidconference);
