import { useCallback } from 'react';

import { atom, selector, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

import { getMemberAwards } from '../../ContractManagement.services';
import { MemberAward } from '../../contract-management.d';
import { tryCatchLog } from 'src/utils/errors';

export async function getMemberAwardsDetails(): Promise<MemberAward[]> {
  return await getMemberAwards();
}

export const memberAwardsRequestIdState = atom({
  key: 'memberAwardsRequestIdState',
  default: 0,
});

/**
 * @description API call to retrieve all awarded bids
 * @returns Array of MemberAward
 */
const memberAwardsDetailsSelector = selector<MemberAward[]>({
  key: 'memberAwardsDetailsSelector',
  get: async ({ get }) => {
    get(memberAwardsRequestIdState);
    return tryCatchLog(async () => {
      return await getMemberAwardsDetails();
    }, 'memberAwardsDetailsSelector');
  },
});

const memberAwardsDetailsState = atom({
  key: 'memberAwardsDetailsState',
  default: memberAwardsDetailsSelector,
});

/**
 * @description Array of date strings indicating the most recent 12 months
 */
const dateStrings: string[] = [];
for (let i = 0; i < 12; i++) {
  dateStrings.push(dayjs().subtract(i, 'month').format('YYYY-MM'));
}

/**
 * @description Aggregates and formats API data for each month of the past year
 * @returns Awarded dollar amount by month
 */
const memberAwardsQuerySelector = selector<{ month: string; amount: number }[]>({
  key: 'memberAwardsQuerySelector',
  get: ({ get }) => {
    const response = get(memberAwardsDetailsState);

    const aggregated = dateStrings.map(value => {
      const thisMonthsAwards = response.filter(award => award.awardDate.startsWith(value));
      const amounts = thisMonthsAwards.map(award => award.amount);
      const total = amounts.reduce((a, b) => a + b, 0) || 0;
      return { month: dayjs(value).format('MMM YYYY'), amount: total };
    });

    return aggregated.reverse();
  },
});

/**
 * @description Aggregates API awardee data for each month of the past year
 * @returns Total number of unique suppliers who were awarded a bid
 */
const suppliersAwardedQuerySelector = selector({
  key: 'suppliersAwardedQuerySelector',
  get: ({ get }) => {
    const response = get(memberAwardsDetailsState);

    const awardees = dateStrings.map(value => {
      const thisMonthsAwards = response.filter(award => award.awardDate.startsWith(value));
      const thisMonthsAwardees = thisMonthsAwards.map(award => award.supplierName);
      return thisMonthsAwardees;
    });

    const awardeesArr = awardees.reduce((a, b) => {
      return a.concat(b);
    });

    const uniqueAwardees = awardeesArr.filter((c, index) => {
      return awardeesArr.indexOf(c) === index;
    });

    return uniqueAwardees.length;
  },
});

/**
 * @description Aggregates API data of awarded bids from the past year
 * @returns Total number of bids awarded in the last year
 */
const bidsAwardedQuerySelector = selector({
  key: 'bidsAwardedQuerySelector',
  get: ({ get }) => {
    const response = get(memberAwardsDetailsState);

    const bidsByMonth = dateStrings.map(value => {
      const thisMonthsAwards = response.filter(award => award.awardDate.startsWith(value));
      const thisMonthsBids = thisMonthsAwards.map(award => award.bidId);
      return thisMonthsBids;
    });

    const bidsArr = bidsByMonth.reduce((a, b) => {
      return a.concat(b);
    });

    const uniqueBids = bidsArr.filter((c, index) => {
      return bidsArr.indexOf(c) === index;
    });

    return uniqueBids.length;
  },
});

/** hook for accessing memberAwards from a contractId. */
export function useMemberAwards() {
  const setMemberAwardsRequestId = useSetRecoilState(memberAwardsRequestIdState);
  const memberAwards = useRecoilValueLoadable(memberAwardsQuerySelector);
  const suppliersAwarded = useRecoilValueLoadable(suppliersAwardedQuerySelector);
  const bidsAwarded = useRecoilValueLoadable(bidsAwardedQuerySelector);

  /**
   * manually update memberAwards of the requestId passed to useMemberAwards
   */
  const refreshMemberAwards = useCallback(() => {
    setMemberAwardsRequestId(requestId => requestId + 1);
  }, [setMemberAwardsRequestId]);

  return {
    bidsAwarded,
    memberAwards,
    refreshMemberAwards,
    suppliersAwarded,
  };
}
