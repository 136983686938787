import React, { memo, useEffect, useState } from 'react';
import { initialquotesFilters } from '../../../store/reducers/quotes';
import {
  AgencyQuoteStatusList,
  MemberType,
  SupplierQuoteStatusList,
  usaDateFormat,
} from '../../../utils/constants';
import { Buttons, DeprecatedInput, SelectBox } from '../../customcontrols';
import { sharedParamType } from '../../../types/commoditycodes';
import { quotesParamType } from '../../../types/quotesdetails';
import moment from 'moment-timezone';
import DateRangePickerComponent from '../../customcontrols/daterangepicker';
import { toastFn } from '../../../utils/helpers';
import { useAuthContext } from 'src/auth/AuthProvider';
const quotestoastID = '52b602fe-b840-45c3-9b57-650a843b5d6b';

interface propsTypes {
  shared: sharedParamType;
  quotes: quotesParamType;
  setQuotesDetails: any;
  getQuotesList: any;
  getFiscalYear: any;
  setQuotesFilter: any;
  getContactList: any;
  /* trackAmplitudeUserActions: any; */
}

const Filters = (props: propsTypes) => {
  const [dateshow, setDateshow] = useState(true);
  const {
    shared,
    quotes,
    setQuotesDetails,
    getQuotesList,
    getFiscalYear,
    getContactList,
    setQuotesFilter,
    /* trackAmplitudeUserActions, */
  } = props;
  const { auth } = useAuthContext();
  const { fiscalYear = [], invaliddateerror } = shared || {};
  const { quotesFilters, contactList = [] } = quotes || {};
  const {
    quoteName,
    quoteNumber,
    quoteStatuses,
    quoteYear,
    startDueDate,
    endDueDate,
    quoteContact,
  } = quotesFilters || {};

  const quoteStatus =
    auth?.memberType === MemberType.AgencyBuyer ? AgencyQuoteStatusList : SupplierQuoteStatusList;

  useEffect(() => {
    let getContactListAPICALL = true;
    if (
      contactList.length === 0 &&
      auth?.memberType === MemberType.AgencyBuyer &&
      getContactListAPICALL
    )
      getContactList();
    return () => {
      getContactListAPICALL = false;
    };
  }, [auth, getContactList, contactList.length]);

  const onInputChange = (name: string, value: string | number) => {
    setQuotesFilter({ [name]: value });
  };
  const searchFns = () => {
    setDateshow(true);
    setQuotesDetails({ quotecurrentPage: 1 });
    getQuotesList({ types: 'search' });
    setTimeout(() => {
      setDateshow(true);
    }, 10);
    //trackAmplitudeUserActions({title:'Quote search', desc:'Quote - search button clicked', data:quotesFilters});
  };

  const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (invaliddateerror === '') {
      if (startDueDate && endDueDate) {
        if (
          moment(moment(startDueDate).format(usaDateFormat)).isSameOrBefore(
            moment(endDueDate).format(usaDateFormat),
          )
        ) {
          searchFns();
        } else {
          toastFn(
            'error',
            'End due date must be greater than or equal to start due date',
            quotestoastID,
          );
        }
      } else {
        searchFns();
      }
    }
  };

  const onClearFilterClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setDateshow(false);
    setQuotesDetails({ quotecurrentPage: 1 });
    setQuotesDetails({ quotesFilters: initialquotesFilters });
    getQuotesList({ types: 'initial' });
    setTimeout(() => {
      setDateshow(true);
    }, 10);
  };

  return (
    <div className='col-12 col-lg-3 col-xl-3 mb-3 mb-lg-0 mb-xl-0'>
      <div className='sideBarSpacing'>
        <form>
          <DeprecatedInput
            label='Quote Name'
            placeholder='Quote Name'
            name='quoteName'
            type='search'
            classNames='class mb-3'
            value={quoteName}
            handleChange={onInputChange}
            maxLength={255}
          />
          <DeprecatedInput
            label='Quote Number'
            name='quoteNumber'
            type='search'
            classNames='class mb-3'
            value={quoteNumber}
            placeholder='Quote Number'
            handleChange={onInputChange}
            maxLength={10}
          />

          {auth?.memberType === MemberType.AgencyBuyer ? (
            <SelectBox
              reactselect={true}
              label='Contact'
              name='quoteContact'
              options={contactList}
              handleSelect={onInputChange}
              value={quoteContact}
              clear={true}
            />
          ) : null}
          <SelectBox
            reactselect={true}
            label='Quote Status'
            name='quoteStatuses'
            options={quoteStatus}
            handleSelect={onInputChange}
            value={quoteStatuses}
            clear={true}
          />
          <SelectBox
            reactselect={true}
            label='Fiscal Year'
            parentClass='w-75'
            name='quoteYear'
            options={fiscalYear}
            handleSelect={onInputChange}
            value={quoteYear}
            clear={true}
          />
          {dateshow ? (
            <DateRangePickerComponent
              label='Due Date Range'
              // name='quotedueDate'
              startDatename='startDueDate'
              endDatename='endDueDate'
              handleChange={onInputChange}
              isOutsideRange={true}
              startDatevalue={startDueDate}
              endDatevalue={endDueDate}
              showClearDate={true}
              optional={`(e.g. ${moment().format(usaDateFormat)} to ${moment(
                moment().format(usaDateFormat),
              )
                .add(5, 'days')
                .format(usaDateFormat)})`}
              parentClass='clearfix'
            />
          ) : null}
          <Buttons
            classNames='bttn-primary mt-1 w-100'
            text='Search'
            title='Search'
            onClick={onSearchClick}
            disable={invaliddateerror}
          />
          <Buttons
            classNames='bttn-secondary mt-4 w-100'
            text='Clear Filter'
            title='Clear Filter'
            onClick={onClearFilterClick}
            type='button'
          />
        </form>
      </div>
    </div>
  );
};

export default memo(Filters);
