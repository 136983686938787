import { AgencyDocs } from '../../types/publications';

// Default objects
export const defaultAgencyDocs: AgencyDocs = { acceptedFormats: [], requiredDocuments: [] };

// Text

export const documentLabels = {
  addMoreDocuments: 'Add More Documents',
  cancelUpload: 'Cancel Upload',
  confirmNavigationUnfinishedUpload:
    'This page has an incomplete file upload. Are you sure you want to leave this page?',
  convertedToPdf: 'Converted to PDF',
  convertToPdf: 'Convert to PDF',
  dateModified: 'Date Modified',
  docTitle: 'Document Title',
  docType: 'Document Type',
  download: 'Download',
  downloadAllDocuments: 'Download all documents',
  downloadUnavailable: '(Download Unavailable)',
  fileName: 'Filename',
  sendDocumentsTo: 'Send Documents To',
  status: 'Status',
  upload: 'Upload',
  uploadTitle: 'Upload a Document',
} as const;

export const uploadMessages = {
  successSuffix: 'was uploaded successfully.',
  errorSuffix: 'was not uploaded.',
};

export const downloadMessages = {
  documentLinkPrefix: 'Click to download',
  fileNameNotFound: 'The file name could not be found.',
};

export const documentUploadProgressThrottlePercentage = 5;
