import React, { memo, useContext, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import history from '../../../../utils/history';

import { BidExternalStatusType, userPermissionKeys } from '../../../../utils/constants';

import { agencyStateCountyState } from '../../../../store/recoil/agencyState';
import { bidssummaryParamType } from '../../../../types/biddetail';
import { BidSummaryLabels } from '../../../../shared/constants';
import { Buttons } from '../../../customcontrols';
import { constants } from '../../../../utils/settings';
import { getKey } from '../../../supplier/BidPlans/BidPlans';
import { hasPermission } from '../../../../utils/helpers';
import { MemberTypeName } from '../../../../types/dashboard';
import { bonfireVendorRegistration } from '../../../../utils/constants';
import { useDSSelector } from 'src/store/reducers';
import { useAuthContext } from 'src/auth/AuthProvider';
import { AppContext } from 'src/global-context/AppContext';

interface PropsTypes {
  bidssummary: bidssummaryParamType;
  bidsBidWatch: any;
  downloadAllDocument: any;
  setBidSummaryDetails: any;
  bidsSummary: any;
  memberTypeName?: MemberTypeName;
  memberId?: number;
  trackAmplitudeUserActions?: any;
}

export function shouldAllowDirectDownload(
  planholderIds: number[],
  memberTypeName: MemberTypeName,
  isPurchased: boolean,
  memberId?: number,
  memberIsBidAwardee?: boolean,
) {
  const supplierName = getKey((memberTypeName as MemberTypeName) || '' as any, !!isPurchased);
  return (
    ['paid', 'basic'].includes(supplierName) ||
    (supplierName === 'freeAgency' && memberId && planholderIds.includes(memberId)) ||
    !!memberIsBidAwardee
  );
}

const BidSummaryControlsInner = (props: PropsTypes) => {
  const setAgencyStateCounty = useSetRecoilState(agencyStateCountyState);

  const {
    bidsBidWatch,
    bidssummary,
    bidsSummary,
    downloadAllDocument,
    setBidSummaryDetails,
    trackAmplitudeUserActions,
  } = props || {};
  const memberinfo = useDSSelector(state => state.memberinfo);
  const { mi: memberId, mtn: memberTypeName } = memberinfo;
  const { auth } = useAuthContext();

  const { purchaseInfo, results, watchOn, planHolders } = bidssummary || {};
  const { isPurchased = '' } = purchaseInfo || {};
  const { eBidding, bidID, bidExternalStatusType = '', memberID = 0, bidCounty = '' } = results;
  const hasBidRespondPermission =
    hasPermission(auth?.accountPermissions as any, userPermissionKeys.respondBids) ||
    auth?.mainContact;
  const { RefetchDashboardData, RefetchDashboardTrackingData } = useContext(AppContext);

  const clickHandle = () => {
    if (bidID) {
      setBidSummaryDetails({ watchOn: !watchOn });
      bidsBidWatch({ bidId: bidID, watchOn: !watchOn });
      RefetchDashboardTrackingData(true);
    }
  };

  const submitEbidProposal = () => {
    bidsSummary({ auth, bidId: bidID, otherapis: false, checkfor: 'ebid' });
    RefetchDashboardData(true);
  };

  const planholderIds: number[] = planHolders?.ids || [];
  const allowDirectDownload = shouldAllowDirectDownload(
    planholderIds,
    memberTypeName as MemberTypeName,
    !!isPurchased,
    memberId,
  );

  function setAgencyPrepopulateData(memberID: number) {
    if (bidssummary.writerInfo) {
      setAgencyStateCounty({
        county: bidssummary.writerInfo.memberInfo.cu,
        memberId: memberID,
        state: bidssummary.writerInfo.memberInfo.st,
      });
    }
  }

  return (
    <>
      <div className='d-block mt-3'>
        {!results.isExternalBid && (
          <Buttons
            text={[
              'Download Bid Package ',
              isPurchased === '' ? (
                <i className='mdi mdi-spin mdi-loading' /> 
              ) : (
                ''
              ),
            ]}
            classNames='bttn-primary callAction'
            icon='mdi-download-outline'
            onClick={() => {
              if (results.isExternalBid === true) {
                window.open(bonfireVendorRegistration, '_blank');
              } else if (allowDirectDownload) {
                downloadAllDocument({ id: bidID, type: 'Bid' });
              } else {
                trackAmplitudeUserActions({
                  title: 'upgrade - click download bid package',
                  desc: 'Click button on bid detail page',
                  data: {
                    Source: `${constants.networkUrl}/suppliers/bids/${bidID}/details`,
                    'Source (Agency)': memberID,
                    'Product Recommendation': bidCounty,
                  },
                });
                setAgencyPrepopulateData(memberID);
                history.push(`/suppliers/plans?bidId=${bidID}`);
              }
            }}
          />
        )}
        {eBidding === 'Submit' && results.isExternalBid === false ? (
          hasBidRespondPermission ? (
            <Buttons
              text='Submit eBid Proposal'
              classNames='bttn-primary callAction'
              icon='mdi-send'
              onClick={submitEbidProposal}
            />
          ) : null
        ) : null}
        {eBidding === 'ViewEdit' && bidExternalStatusType === BidExternalStatusType.Active ? (
          <Buttons
            text='Edit eBid Response'
            classNames='bttn-primary callAction'
            icon='mdi-square-edit-outline'
            onClick={submitEbidProposal}
          />
        ) : null}
        {watchOn ? (
          <Buttons
            text={BidSummaryLabels.unwatch}
            classNames='bttn-primary callAction '
            icon='mdi-eye-off-outline'
            onClick={clickHandle}
          />
        ) : (
          <Buttons
            text='Watch'
            classNames='bttn-primary callAction '
            icon='mdi-eye-plus-outline'
            onClick={clickHandle}
          />
        )}
      </div>
    </>
  );
};

export default memo(BidSummaryControlsInner);
