import { FieldError } from 'react-hook-form';

export const requiredErrorMsg = 'This field is required';
// Email
export const emailVal = /^([A-Za-z0-9_\-.+])+@[A-Za-z0-9_\-.]+\.([A-Za-z]{2,25})$/;
export const emailValErrorMsg = 'Invalid email address';
export const emailMaxLength = 100;

export function isValidEmail(value?: string) {
  return !!(value && emailVal.test(value));
}

// General Helper Functions
/**
 * Returns a default error message for basic error types like 'required'.
 * Can be modified to return additional defaults for 'maxLength', etc.
 * @param error
 * @returns string
 */
export function getErrorMessage(error?: FieldError, label?: string) {
  if (!error) {
    return '';
  }
  if (error.message) {
    return error.message;
  }
  switch (error.type) {
    case 'required':
      return label ? `${label} is required` : requiredErrorMsg;
    default:
      return 'This field is invalid';
  }
}

// Phone
/** accepts 7-digit, 10-digit, and 11-digit phone numbers of ONLY digits. */
export const numericPhoneVal = /^[0-9]{7}$|^[0-9]{10}$|^[0-9]{11}$/;
export const phoneValErrorMsg = 'Invalid phone number';

export function isValidPhone(value?: string) {
  return !!(value && numericPhoneVal.test(value));
}

/**
 * Format a 10-digit string of numbers
 * according to Sophia's preferred phone number format.
 * @example formatPhone('14258675309') // +1 (425) 867-5309
 * @example formatPhone('4258675309') // (425) 867-5309
 * @example formatPhone('8675309') // 867-5309
 */
export function formatPhone(phoneNumber?: string) {
  /** Since this is often used with react-hook-form which can occasionally slip through type safety,
   * we cast it as a string first, just in case an undefined or number sneaks in.
   * */
  const phoneString = phoneNumber ? String(phoneNumber) : '';
  const strippedNumber = phoneString.replace(/\D+/g, '');
  if (strippedNumber.length === 7) {
    return `${strippedNumber.substring(0, 3)}-${strippedNumber.substring(3, 7)}`;
  }
  if (strippedNumber.length === 10) {
    return `(${strippedNumber.substring(0, 3)}) ${strippedNumber.substring(
      3,
      6,
    )}-${strippedNumber.substring(6, 10)}`;
  }
  if (strippedNumber.length === 11) {
    return `+${strippedNumber.substring(0, 1)} (${strippedNumber.substring(
      1,
      4,
    )}) ${strippedNumber.substring(4, 7)}-${strippedNumber.substring(7, 11)}`;
  }
  return phoneString;
}
