//TODO Deprecate linkText and handleClick props, in favor of combined link
import { ReactElement } from 'react';

import {
  AlertBody,
  AlertContainer,
  AlertContentColumn,
  AlertDismissIconContainer,
  AlertIcon,
  AlertMessage,
} from './DSAlert.styles';
import { CheckmarkIcon, CrossIcon, ExclaimIcon } from '../assets/icons';
import { commonText, Status } from '../constants';
import { DataTestId, NoOpFunction } from '../types/shared';
import { DSButton } from '../button';
import { DSLink } from '../link';
import { TextColor } from '../styles';

export type DSAlertProps = DataTestId & {
  /** Optional button, of type DSButton */
  button?: ReactElement<typeof DSButton>;
  /** Optional click handler to close the modal, perform ad hoc actions */
  onDismiss?: NoOpFunction;
  /** optional {string} header text */
  header?: string;
  /** Optional link, of type DSLink */
  link?: ReactElement<typeof DSLink>;
  /** The body text of the alert */
  children?: React.ReactNode;
  /** Status: can be success, error, or warning */
  type: Status;
};

/**
 * ##Alert boxes have a variety of uses, depending on context, but are meant to capture the user's attention, either
 * to give new information or for a call to action.
 *
 * We rely on three parts of the design together to convey the urgency of the message:
 *
 * - Color: Red, green, and yellow have the following connotations: Error, success, and warning.
 * - Icons: Red alerts have a `x` icon to indicate an error; green has a checkmark to indicate success, and yellow
 *          has an exclamation to indicate warning. <b>These icons should never be associated with other colors; if
 *          this need arises there's a conversation to be had!</b>
 * - Text:  The written copy in a given alert should be written in a tone appropriate to the urgency, with care paid
 *          to not actually scaring anybody.
 *
 * ###Used in:
 * - Contract Management
 * - Dashboard (commodity codes)
 * - Products page cart
 * - where else? Toast replacement?
 *
 * @description DSAlert component
 * @example <DSAlert
 *    button={<DSButton onClick={buttonClick}>{buttonText}</DSButton>}
 *    header={header}
 *    link={<DSLink onClick={handleClick}>{linkText}</DSLink>}
 *    message={message}
 *    type={Status.Warning}
    />
 *
 */

export const DSAlert = (props: DSAlertProps) => {
  const { button, children, dataTestId, header, link, onDismiss, type } = props;

  const showDismissButton = !!onDismiss;

  const bodyDataTestId = `${dataTestId}-body`;
  const dismissIconDataTestId = `${dataTestId}-dismiss-icon`;
  const iconDataTestId = `${dataTestId}-icon`;

  return (
    <AlertContainer type={type} data-testid={dataTestId}>
      <AlertIcon type={type} data-testid={iconDataTestId}>
        {type === Status.Success && (
          <CheckmarkIcon color={TextColor.Contrast} width={50} height={50}></CheckmarkIcon>
        )}
        {type === Status.Error && (
          <CrossIcon color={TextColor.Contrast} width={50} height={50}></CrossIcon>
        )}
        {type === Status.Warning && (
          <ExclaimIcon color={TextColor.Contrast} width={50} height={50}></ExclaimIcon>
        )}
      </AlertIcon>
      <AlertBody type={type} data-testid={bodyDataTestId}>
        <AlertContentColumn>
          {header && <h5>{header}</h5>}
          {children && <AlertMessage>{children}</AlertMessage>}
          {link}
          {button}
        </AlertContentColumn>
        {showDismissButton && (
          <AlertDismissIconContainer
            onClick={onDismiss}
            title={commonText.dismiss}
            data-testid={dismissIconDataTestId}
          >
            <CrossIcon />
          </AlertDismissIconContainer>
        )}
      </AlertBody>
    </AlertContainer>
  );
};
