import { History } from 'history';
import { setBidDetailsType } from '../../../types/bids';
import { bidsStateTypes, getBidsTypes } from '../../../types/bids';
import { activefilterText, filtersText } from '../../../utils/texts';
import { Authorization, MemberType } from 'src/types';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes {
  bids: bidsStateTypes;
  setBidDetails: setBidDetailsType;
  getBids: getBidsTypes;
  location: Location;
  history: History;
}

export function Searchlabel(props: PropsTypes) {
  const { bids, setBidDetails, getBids, location: historyLocation } = props;

  const { auth } = useAuthContext();
  const { memberType } = auth || ({} as Authorization);

  const { filters, labelFilters } = bids || {};
  const {
    myBids = false,
    bidsNotified = false,
    orderedBids = false,
    watchedBids = false,
    commodityMatches = false,
    ebiddingAvailable = false,
    IncludeExternalBids = true,
    agencyMemberId = '',
    bidName = '',
    location = '',
    radius = '',
    bidStatus = { label: '', value: '' },
    bidIdentifier = '',
    startDueDate = '',
    endDueDate = '',
    industry = [],
    states = [],
    fiscalYear: fiscalYearvalue = { label: '', value: '' },
  } = labelFilters || {};

  const ClearBidsFilterFn = async (elementType: string, value: string) => {
    let finalfilter;
    switch (elementType) {
      case 'checkbox':
        finalfilter = { ...filters, [value]: false };
        break;
      case 'selectbox':
      case 'text':
        finalfilter = { ...filters, [value]: '' };
        break;
      case 'datepicker':
        finalfilter = { ...filters, startDueDate: '', endDueDate: '' };
        break;
      default:
        finalfilter = filters;
        break;
    }
    await setBidDetails({
      filters: finalfilter,
      bidscurrentPage: 1,
      selectedBidIds: '',
      loadedBids: [],
    });
    //
    const params = new URLSearchParams(historyLocation.search);
    if (params) {
      params.delete(value);
      if (value === 'startDueDate') {
        params.delete('endDueDate');
      }
      const newLocation = {
        ...historyLocation,
        search: params.toString(),
      };
      if (!params.toString()) {
        await getBids({ preserveFilters: false });
      }
      props.history.replace(newLocation);
    }
  };

  let displayFilterText = false;
  if (
    myBids ||
    bidsNotified ||
    orderedBids ||
    watchedBids ||
    commodityMatches ||
    ebiddingAvailable
  ) {
    displayFilterText = true;
  }

  if (
    agencyMemberId ||
    bidName ||
    location ||
    radius ||
    bidIdentifier ||
    startDueDate ||
    endDueDate ||
    bidStatus?.value ||
    fiscalYearvalue?.value
  ) {
    displayFilterText = true;
  }

  if (industry.length > 0) {
    displayFilterText = true;
  }

  return (
    <div className='d-block filterTags' data-testid='bids.search.tags.label'>
      {displayFilterText ? (
        <h6 className='d-inline-block' data-testid='bids.search.tags.heading'>
          {activefilterText}
        </h6>
      ) : null}
      {myBids ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.myBids}>
            {filtersText.myBids}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'myBids')}
          />
        </div>
      ) : null}
      {bidsNotified ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.bidsNotified}>
            {filtersText.bidsNotified}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'bidsNotified')}
          />
        </div>
      ) : null}
      {orderedBids ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.orderedBids}>
            {filtersText.orderedBids}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'orderedBids')}
          />
        </div>
      ) : null}
      {IncludeExternalBids && memberType !== MemberType.AgencyBuyer ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.IncludeExternalBids}>
            {filtersText.IncludeExternalBids}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'IncludeExternalBids')}
          />
        </div>
      ) : null}
      {watchedBids ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.watchedBids}>
            {filtersText.watchedBids}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'watchedBids')}
          />
        </div>
      ) : null}
      {commodityMatches ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.commodityMatches}>
            {filtersText.commodityMatches}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'commodityMatches')}
          />
        </div>
      ) : null}
      {ebiddingAvailable ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.ebiddingAvailable}>
            {filtersText.ebiddingAvailable}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('checkbox', 'ebiddingAvailable')}
          />
        </div>
      ) : null}
      {agencyMemberId ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.agencyMemberId}>
            {filtersText.agencyMemberId}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'agencyMemberId')}
          />
        </div>
      ) : null}
      {bidName ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.bidName}>
            {filtersText.bidName}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('text', 'bidName')}
          />
        </div>
      ) : null}
      {states && states.length > 0 && (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.state}>
            {filtersText.state}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'states')}
          />
        </div>
      )}
      {location ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.location}>
            {filtersText.location}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'location')}
          />
        </div>
      ) : null}
      {radius ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.radius}>
            {filtersText.radius}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'radius')}
          />
        </div>
      ) : null}
      {bidStatus && bidStatus.value ? (
        <div className='tag draft'>
          <span className='text-truncate' title={bidStatus.label}>
            {bidStatus.label}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'bidStatus')}
          />
        </div>
      ) : null}
      {bidIdentifier ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.bidIdentifier}>
            {filtersText.bidIdentifier}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('text', 'bidIdentifier')}
          />
        </div>
      ) : null}
      {industry && industry.length > 0 ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.industry}>
            {filtersText.industry}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'industry')}
          />
        </div>
      ) : null}
      {fiscalYearvalue && fiscalYearvalue.value ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.fiscalYear}>
            {filtersText.fiscalYear}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('selectbox', 'fiscalYear')}
          />
        </div>
      ) : null}
      {startDueDate || endDueDate ? (
        <div className='tag draft'>
          <span className='text-truncate' title={filtersText.dueDate}>
            {filtersText.dueDate}
          </span>
          <i
            className='mdi mdi-close-circle ml-1'
            title='Clear'
            onClick={() => ClearBidsFilterFn('datepicker', 'startDueDate')}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Searchlabel;
