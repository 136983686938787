// Headers
export const createAContract = 'Create a Contract';
export const verifyAContract = 'Verify a Contract';

// Nav Header
export const creationProcess = 'Creation Process';

// Descriptions
export const wizardCreateDescription =
  "If you'd like to manage a contract on DemandStar that was not awarded on DemandStar, please use this tool to add it to our system.";
export const wizardManageDocuments =
  'Please upload any files relevant to this awarded solicitation. You may rename any files, and indicate what they are, which will make them easier to find later.';
export const wizardVerifyDescription =
  "You've recently awarded a contract on DemandStar. We've brought it into our contract management system for you, but we'd like you to verify that all the information is correct.";
