import Configurations from '../../settings';

export const accountUpdateKeys = {
  PRIMARYFAIL:
    'Cannot update primary contact on the last date of subscription or the new contact does not have card.',
  SUCCESS: 'Saved',
  FAILED: 'Failed',
  USERNAMEEXISTS: 'Username already exists',
  PASSWORDMAILSENT: 'Sent password email',
};

export const appKeys = {
  name: 'DemandStar',
  BTkey: Configurations.REACT_APP_BTKey,
  headers: {
    auditTrail: 'Audit Trail',
    bidders: 'Bidders',
    bidDetails: 'Bid Details',
    bidPlans: 'Bid Plans',
    bidPurchase: 'Bid Purchase',
    bids: 'Bids',
    bidWriter: 'Bid Writer',
    billingAddress: 'Billing Address',
    dashboard: 'Dashboard',
    docNotAvailable: 'Download Document Unavailable',
    docRequest: 'Document Request',
    ebidResponse: 'eBid Response',
    legalAd: 'Legal Ad',
    myEbidResponse: 'eBid Response',
    paymentMethod: 'Payment Method',
    planholders: 'Planholders',
    plans: 'Plans',
    purchase: 'Purchase',
    quotes: 'Quotes',
    watchers: 'Watchers',
  },
};

export const userPermissionKeys = {
  //	Operations:1,
  editMember: 2,
  manageAccounts: 3,
  manageBids: 4,
  // createQuotes:5,
  managePrograms: 6,
  // awardQuotes:7,
  // fulfillment:8,
  // salesPerson:9,
  // salesManager:10,
  // manageReports:11,
  // dedupMembers:12,
  // giveCredit:13,
  respondBids: 14,
  respondQuotes: 15,
};
