import { ComponentType } from 'react';

import { DataTestId, Selected } from '../types';
import { BaseTabNavigationLabel } from './DSTabNavigation.styles';

export type DSTabNavigationLabelProps = {
  /** ID for the tab, used as a key in the list */
  id: string;
  /** The text to display on the tab */
  label: string;
  /** Optional override for this tab's renderer */
  labelComponent?: ComponentType<DSTabNavigationLabelProps>;
  /** Click handler for selecting this tab */
  onClick?: () => void;
} & DataTestId &
  Selected;

export const DSTabNavigationLabel = ({
  dataTestId,
  id,
  label,
  labelComponent,
  onClick,
  selected = false,
}: DSTabNavigationLabelProps) => {
  const LabelComponent = labelComponent ?? BaseTabNavigationLabel;

  return (
    <LabelComponent
      data-testid={dataTestId}
      onClick={onClick}
      selected={selected}
      id={id}
      label={label}
      title={label}
    >
      {label}
    </LabelComponent>
  );
};
