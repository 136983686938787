import React, { memo, useState } from 'react';
import { NoResult } from '../customcontrols';
import { loadingMsg } from '../../utils/texts';
import { sharedTypes } from '../../types/shared';

interface PropsTypes {
  innerhtml: boolean;
  data?: string;
  shared: sharedTypes;
}

const initailslice = 240;

const ExpandCollapse = (props: PropsTypes) => {
  const { innerhtml = false, data = '', shared } = props;
  const { internalLoader } = shared || {};
  const [textlength, setTextlength] = useState(initailslice);

  const length = data.length;

  let DisplayData = data.substring(0, textlength) || '';

  if (textlength === initailslice && length > initailslice) {
    DisplayData = `${DisplayData}   ......`;
  }

  return (
    <>
      {data ? (
        <>
          {innerhtml ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: DisplayData }} />
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: DisplayData }} />
          )}
          {textlength === initailslice && length > initailslice ? (
            <span
              className='float-right staticLink clearfix'
              title='Show More'
              onClick={() => setTextlength(length)}
            >
              Show More <i className='mdi mdi-chevron-double-down' />
            </span>
          ) : null}
          {textlength === length && length > initailslice ? (
            <span
              className='float-right staticLink clearfix'
              title='Show Less'
              onClick={() => setTextlength(initailslice)}
            >
              Show Less <i className='mdi mdi-chevron-double-up' />
            </span>
          ) : null}
        </>
      ) : (
        <NoResult message={internalLoader ? loadingMsg : 'No Data Available'} />
      )}
    </>
  );
};

export default memo(ExpandCollapse);
