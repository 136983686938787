import { ChangeEvent } from 'react';

import {
  BaseFileInput,
  FileInputButton,
  FileInputContainer,
  FileNameWrapper,
} from './DSFileInput.styles';
import { FieldContainer, getFieldContainerProps } from '../../field-container/FieldContainer';

import { DSFileInputProps } from './DSFileInput.d';
import { FileUploadText } from './constants';
import { useFileInput } from './useFileInput';

export const DSFileInput = (props: DSFileInputProps) => {
  const {
    allowMultiple,
    buttonLabel,
    dataTestId,
    inactive,
    label,
    name,
    onChange,
    title,
    uploading = false,
  } = props;

  const { fileNames, handleFileSelection } = useFileInput();
  const fileNameDisplay = uploading ? '' : fileNames;

  /**
   * Handle fileInput change
   * @param {ChangeEvent<HTMLInputElement>} event
   * @description Update the file name component via the useFileInput hook state,
   *              then pass the event to any change handler set in the props.
   */
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    handleFileSelection(event);
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <FieldContainer {...getFieldContainerProps(props)}>
      <FileInputContainer title={label} data-testid={dataTestId}>
        <BaseFileInput
          data-testid={`${dataTestId}-input`}
          id={name}
          inactive={inactive}
          multiple={!!allowMultiple}
          name={name}
          onChange={handleInputChange}
          readOnly={!!inactive}
          title={title}
          type={'file'}
        />
        <FileNameWrapper
          data-testid={`${dataTestId}-file-name`}
          inactive={!!inactive}
          title={fileNameDisplay || label}
        >
          {fileNameDisplay}
        </FileNameWrapper>
        <FileInputButton inactive={inactive}>
          {buttonLabel || FileUploadText.ChooseFile}
        </FileInputButton>
      </FileInputContainer>
    </FieldContainer>
  );
};
