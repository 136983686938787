import { useEffect, useMemo } from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilStateLoadable,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';

import {
  allProductsState,
  selectedProductsState,
  upsellProductsState,
} from '../../../store/recoil/productState';
import {
  NationalProductId,
  ProductApiResponse,
  ProductType,
  sortByProductType,
} from '../../../types/products';
import { UpsellCardData, UpsellType } from '../../../types/registration';

import { getAgencyCounts, isSubscribed } from '../helpers';
import { compareLists } from 'src/utils/helpers';
import { Product } from '../../../types/subscriptions';
import { useAccountInfo } from '../../../shared/hooks/useAccountInfo';

/**
 * @description wraps functionality related to upsell product state
 * @returns {
 *  addUpsellProduct,
 *  clearExtraUpsellProducts,
 *  refreshUpsellProducts,
 *  resetUpsellProducts,
 *  setUpsellProducts,
 *  upsellProducts,
 * }
 * @example const { refreshUpsellProducts, upsellProducts } = useUpsellProducts();
 */
export function useUpsellProducts() {
  const {
    accountInfo: { products, memberInfo },
  } = useAccountInfo();

  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const resetUpsellProducts = useResetRecoilState(upsellProductsState);
  const selectedProducts = useRecoilValue(selectedProductsState);
  const [upsellProducts, setUpsellProducts] = useRecoilState(upsellProductsState);

  /** The member's main address */
  const mainAddress = useMemo(() => {
    return memberInfo?.find(x => x.addressType === 'MA');
  }, [memberInfo]);

  /** A combined list of selected & subscribed products for ease-of-use */
  const combinedProducts = useMemo(() => {
    let prods = [] as Product[];

    prods = prods.concat(products ? [...products] : []);
    prods = prods.concat(selectedProducts?.agency?.productId ? [selectedProducts.agency] : []);
    prods = prods.concat(
      selectedProducts?.county ? [...selectedProducts.county.filter(c => c.productId > 0)] : [],
    );
    prods = prods.concat(
      selectedProducts?.state ? [...selectedProducts.state.filter(s => s.productId > 0)] : [],
    );
    prods = prods.concat(
      selectedProducts?.national
        ? [
            {
              productId: NationalProductId.UnitedStates,
              productName: 'National',
              productType: ProductType.National,
              price: 0,
              productGroupId: 52,
            },
          ]
        : [],
    );

    return prods;
  }, [products, selectedProducts]);

  /**
   * The product on which standard upsell options are based. For Basic Suppliers, this is based on their address,
   * for all other suppliers, this is based on their lowest-tier subscription (free agency => county => state)
   */
  const baseProduct = useMemo(() => {
    let newProduct: Product | undefined = undefined;

    newProduct = combinedProducts?.sort((p1, p2) =>
      sortByProductType(p1.productType, p2.productType),
    )[0];

    // Try to guess upsell
    if (!newProduct && mainAddress && allProducts.state === 'hasValue') {
      const stateProdByLoc = allProducts.contents.find(
        product =>
          product.productType === ProductType.State &&
          product.productName === mainAddress?.stateName,
      );

      const countyProdByLoc = allProducts.contents.find(
        product =>
          product.productType === ProductType.County &&
          product.parentId &&
          product.parentId === stateProdByLoc?.productId &&
          product.productName === mainAddress?.countyName,
      );

      newProduct = countyProdByLoc ?? stateProdByLoc;
    }

    return newProduct;
  }, [allProducts.contents, allProducts.state, combinedProducts, mainAddress]);

  /**
   * @description refreshes the list of upsell products
   * @returns void
   * @example refreshUpsellProducts();
   */
  const refreshUpsellProducts = useRecoilCallback(
    ({ set }) =>
      () => {
        if (baseProduct && allProducts.state === 'hasValue') {
          const extras =
            upsellProducts?.filter(
              x => x.upsellType !== UpsellType.Parent && x.upsellType !== UpsellType.National,
            ) ?? [];
          const options = getUpsellOptions(
            baseProduct,
            allProducts.contents,
            combinedProducts ?? [],
          );

          const newUpsellProducts = extras?.length ? extras.concat(options) : options;

          const { firstOnly, secondOnly } = compareLists(
            upsellProducts,
            newUpsellProducts,
            (u1, u2) => u1.product.productId === u2.product.productId,
          );

          // Only update if the list has changed
          if (firstOnly.length || secondOnly.length) {
            set(
              upsellProductsState,
              newUpsellProducts.filter(p => !isSubscribed(p.product, combinedProducts)),
            );
          }
        } else if (allProducts.state === 'hasValue') {
          const national = allProducts.contents.find(x => x.productType === ProductType.National);
          const nationalUpsell: UpsellCardData | undefined = national
            ? {
                baseProductName: '',
                product: national,
                totalAgencies: allProducts.contents.filter(
                  product => product.productType === ProductType.FreeAgency,
                ).length,
                upsellType: UpsellType.National,
              }
            : undefined;

          if (nationalUpsell) {
            if (
              !upsellProducts.length ||
              upsellProducts.find(upsell => upsell.upsellType !== UpsellType.National)
            ) {
              set(upsellProductsState, [nationalUpsell]);
            }
          } else if (upsellProducts.length) {
            set(upsellProductsState, []);
          }
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allProducts.state, allProducts.contents, baseProduct, combinedProducts],
  );

  /**
   * @description adds an "extra" upsell card outside of the default upsell options
   * @param activeProduct - number - the productId of the product to add
   * @param upsellType - UpsellCardData['upsellType'] - the type of upsell card
   * @param baseProductName - string - the child product of the upsell, if applicable
   * @returns void
   * @example addUpsellProduct({
   *  activeProduct: 10,
   *  upsellType: UpsellType.Popular,
   * });
   */
  const addUpsellProduct = useRecoilCallback(
    ({ set }) =>
      (activeProduct: number, upsellType: UpsellType, baseProductName?: string) => {
        if (allProducts.state === 'hasValue') {
          const upsellProduct = allProducts.contents.filter(
            product => product.productId === activeProduct,
          );
          let totalAgencies = 0;

          switch (upsellProduct[0].productType) {
            case ProductType.FreeAgency:
              totalAgencies = 1;
              break;
            default:
              totalAgencies = getAgencyCounts(
                allProducts.contents,
                upsellProduct[0].productId,
                upsellProduct[0].productType,
              );
              break;
          }

          const upsellCardData: UpsellCardData = {
            baseProductName: baseProductName || upsellProduct[0].productName,
            product: upsellProduct[0],
            totalAgencies: totalAgencies,
            upsellType: upsellType,
          };

          refreshUpsellProducts();

          if (upsellProducts.filter(u => u.product.productId === activeProduct).length) {
            clearExtraUpsellProducts();
          } else if (!isSubscribed(upsellProduct[0], combinedProducts)) {
            const newUpsellProducts = [
              upsellCardData,
              ...(upsellType === UpsellType.Popular
                ? upsellProducts.filter(p => p.upsellType !== UpsellType.Popular)
                : upsellProducts),
            ];
            set(upsellProductsState, newUpsellProducts);
          }
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshUpsellProducts],
  );

  /**
   * @description clears any products not in the default options for upsell
   * @returns void
   * @example clearExtraUpsellProducts();
   */
  const clearExtraUpsellProducts = useRecoilCallback(
    ({ set }) =>
      () => {
        const newUpsellProducts = upsellProducts.filter(
          p => p.upsellType !== UpsellType.Popular && p.upsellType !== UpsellType.Nearby,
        );
        set(upsellProductsState, newUpsellProducts);
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    addUpsellProduct,
    clearExtraUpsellProducts,
    refreshUpsellProducts,
    resetUpsellProducts,
    setUpsellProducts,
    upsellProducts,
  };
}

/**
 * @description keeps upsell products in sync based on subscribed & selected products
 * @example useRefreshUpsellProducts();
 */
export function useRefreshUpsellProducts() {
  const { refreshUpsellProducts } = useUpsellProducts();

  useEffect(() => {
    refreshUpsellProducts();
  }, [refreshUpsellProducts]);
}

// TOREFACTOR: This should be moved to the service layer to eliminate site reliance on "allProducts" for this purpose
/**
 * @description Provides a list of upsell products based on a provided set
 * @param activeProduct - The lowest-tier subscribed product, hopefully Free Agency
 * @param allProducts - The list of all available products
 * @param currentProducts - The list of products to which the Member is currently subscribed
 * @returns UpsellCardData[] containing valid upsell options
 */
const getUpsellOptions = (
  activeProduct: Product,
  allProducts: ProductApiResponse[],
  currentProducts: Product[],
): UpsellCardData[] => {
  let freeAgency: ProductApiResponse | undefined;
  let county: ProductApiResponse | undefined;
  let state: ProductApiResponse | undefined;
  let national: ProductApiResponse | undefined;

  if (currentProducts?.filter(p => p.productType === ProductType.National)?.length) return [];

  switch (activeProduct.productType) {
    case ProductType.FreeAgency:
      freeAgency = allProducts.filter(x => x.productId === activeProduct.productId)[0];
      county = allProducts.filter(x => x.productId === freeAgency?.parentId)[0];
      break;
    case ProductType.County:
      county = allProducts.filter(x => x.productId === activeProduct.productId)[0];
      break;
  }

  if (county || activeProduct.productType === ProductType.State) {
    state = allProducts.filter(
      x =>
        x.productGroupId === activeProduct?.productGroupId &&
        x.productType === ProductType.State &&
        x.parentId === 0,
    )[0];
  }

  if (state) {
    national = allProducts.filter(
      x => x.productId === (state?.parentId || NationalProductId.UnitedStates),
    )[0];
  }

  const upsellOptions = [] as ProductApiResponse[];

  if (county) upsellOptions.push(county);
  if (state) upsellOptions.push(state);
  if (national) upsellOptions.push(national);

  return upsellOptions
    .map(product => {
      const totalAgencies = getAgencyCounts(allProducts, product.productId, product.productType);

      return {
        baseProductName:
          activeProduct.productId === product.productId &&
          (activeProduct.productType === ProductType.County ||
            activeProduct.productType === ProductType.State)
            ? 'Your contact address '
            : activeProduct.productName,
        product: product,
        totalAgencies: totalAgencies,
        upsellType:
          product.productType === ProductType.National ? UpsellType.National : UpsellType.Parent,
      } as UpsellCardData;
    })
    .sort((a, b) => sortByProductType(a.product.productType, b.product.productType));
};
