import { RecoilLoadable, useRecoilValueLoadable } from 'recoil';
import { useMemo } from 'react';

import {
  allStatesQuerySelector,
  countiesByStateQuerySelector,
  metroDetailsQuerySelector,
} from './address.recoil';

/** hook to look up address information based on a postal code. */
export function useAddress(postalCode?: string, stateId?: number) {
  const allStatesLoadable = useRecoilValueLoadable(allStatesQuerySelector);
  const allAddressStates = useMemo(() => {
    return allStatesLoadable.valueMaybe() || [];
  }, [allStatesLoadable]);

  const metroDetailsLoadable = useRecoilValueLoadable(metroDetailsQuerySelector(postalCode));
  const metroDetails = metroDetailsLoadable.valueMaybe() || null;

  const stateCountiesLoadable = useRecoilValueLoadable(
    countiesByStateQuerySelector(stateId || metroDetails?.stateId),
  );

  const stateCounties = useMemo(() => {
    return stateCountiesLoadable.valueMaybe() || [];
  }, [stateCountiesLoadable]);

  const loadedAllAddressInfo =
    RecoilLoadable.all([allStatesLoadable, metroDetailsLoadable, stateCountiesLoadable]).state ===
    'hasValue';

  const postalCountryId = metroDetails?.countryId;
  const postalStateId = metroDetails?.stateId;
  const postalCountyId = metroDetails?.countyId;
  const postalCity = metroDetails?.city;

  return {
    /** all states and provinces, loaded once */
    allAddressStates,
    /** list of county objects based off stateId passed to useAddress hook */
    stateCounties,
    /** boolean that checks for allStates, metroDetails, and stateCounties loadables */
    loadedAllAddressInfo,
    /** countryId based off postal code past to useAddress hook */
    postalCountryId,
    /** stateId based off postal code past to useAddress hook */
    postalStateId,
    /** countyId based off postal code past to useAddress hook */
    postalCountyId,
    /** city name based off postal code past to useAddress hook */
    postalCity,
  };
}
