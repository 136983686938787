import { atomFamily, selectorFamily, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { BidApiResponse, Commodity } from '../../types/bidssummary';
import { BidExternalStatusType, DeprecatedFullTimeZone, Paths } from 'src/utils/constants';
import { Assert } from 'src/utils/helpers';
import { axiosPostAuthenticated } from 'src/store/services/api';
import { constants } from 'src/utils/settings';
import { defaultNumericId } from '../../shared/constants';
import { tryCatchLog } from 'src/utils/errors';
import { useIdFromRoute } from './useIdFromRoute';

/** Necessary information for a single bid. To replace BidSummary.
 * This is to be expanded as needed. But we avoid using BidApiResponse type,
 *  which has
 * - redundant properties
 * - mis-capitalized properties
 * - unclearly labeled properties
 * */
export type BidState = {
  accountId: number;
  agencyName: string;
  bidExternalStatusType: BidExternalStatusType;
  bidId: number;
  bidName: string;
  bidNumber: string;
  bidWriter: string;
  commodities: Commodity[];
  memberId: number;
  scopeOfWork: string;
  timeZoneFullName: DeprecatedFullTimeZone;
};

export const bidRequestIdState = atomFamily({
  key: 'bidRequestIdState',
  default: 0,
});

export const bidQuerySelector = selectorFamily<BidState | undefined, number | string | undefined>({
  key: 'bidQuerySelector',
  get:
    bidId =>
    async ({ get }) => {
      if (!bidId || bidId < 0) {
        return undefined;
      }
      get(bidRequestIdState(bidId)); // Add request ID as a dependency
      return tryCatchLog(async () => {
        const response = await getBid(bidId);
        return convertToBidState(response);
      }, 'bidQuerySelector');
    },
});

/**
 * Typed Bid Summary request
 * @param bidId
 * @returns a Promise with a BidApiResponse type
 */
export function getBid(bidId: number | string): Promise<BidApiResponse> {
  // Pass an empty config if the bidId is the default value (defaultNumericId).
  const requestConfig: AxiosRequestConfig =
    bidId === defaultNumericId
      ? {}
      : {
          baseURL: constants.api.url,
          data: {
            bidId,
            checkfor: '',
            otherapis: true,
          },
          url: Paths.bidsSummary,
        };
  return axiosPostAuthenticated<BidApiResponse>(requestConfig);
}

function convertToBidState(apiResponse: BidApiResponse): BidState {
  const {
    accountID,
    agencyName,
    bidExternalStatusType,
    bidID,
    bidName,
    bidNumber,
    bidWriter,
    commodities,
    memberID,
    scopeOfWork,
    tzfn,
  } = apiResponse;
  Assert(!!accountID, 'All bids should have an accountId');
  Assert(!!agencyName, 'All bids should have an agencyName');
  Assert(!!bidExternalStatusType, 'All bids should have a bidExternalStatusType');
  Assert(!!bidID, 'All bids should have a bidId');
  Assert(!!bidName, 'All bids should have a bidName');
  Assert(!!bidNumber, 'All bids should have a bidNumber');
  Assert(!!bidWriter, 'All bids should have a bidWriter');
  Assert(!!memberID, 'All bids should have a memberId');

  return {
    accountId: accountID,
    agencyName,
    bidExternalStatusType,
    bidId: bidID,
    bidName,
    bidNumber,
    bidWriter,
    commodities,
    memberId: memberID,
    scopeOfWork,
    timeZoneFullName: tzfn,
  };
}

/**
 * Manages the current bid information (replaces Redux's bidSummary)
 * Include `useRefreshBid()` at a high level component, and use in sync with selectedBidIdState.
 */
export function useBid(bidId?: string | number | null) {
  const { bidId: bidIdFromRoute } = useIdFromRoute();
  bidId = bidId || bidIdFromRoute;

  const bidLoadable = useRecoilValueLoadable(bidQuerySelector(bidId));
  const bid = bidLoadable.valueMaybe();
  const setBidRequestId = useSetRecoilState(bidRequestIdState(bidId));

  const refreshBid = useCallback(() => {
    setBidRequestId(requestId => requestId + 1);
  }, [setBidRequestId]);

  return {
    bid,
    bidLoadable,
    refreshBid,
  };
}

export const mockBidState: BidState = {
  accountId: 1234,
  agencyName: 'Agency Name',
  bidExternalStatusType: BidExternalStatusType.Active,
  bidId: 12345,
  bidName: 'Bid Name',
  bidNumber: 'Bid Number',
  bidWriter: 'Bid Writer',
  commodities: [],
  memberId: 123456,
  scopeOfWork: 'Scope of Work',
  timeZoneFullName: 'Eastern Time (US & Canada)',
};
