import {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FieldError, RegisterOptions,useFormContext, UseFormSetValue } from 'react-hook-form';

import { FieldContainer, FieldProps, getFieldContainerProps } from '../../field-container';
import { BaseTextInput } from '../..';
import { Inactive } from '../../types/shared';
import { useAssert } from '../../utils/testing';

export type DSNumberFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus' | 'id'> &
  Inactive & {
    /** are negative numbers valid? */
    allowNegative?: boolean;
    /** are non-integers valid? */
    allowDecimal?: boolean;
    /** lowest number allowed */
    min?: number;
    /** highest number allowed */
    max?: number;
  };

/** Number field which prevents non-number values.
 * @example
 * <FormProvider {...methods}>
 * <DSNumberField
 *    name={fieldName}
 *    max={500}
 *    allowDecimals
 *  />
 * */
export const DSNumberField = (props: DSNumberFieldProps) => {
  const {
    allowDecimal,
    allowNegative,
    autoFocus,
    dataTestId,
    id,
    inactive,
    max,
    min,
    name,
    optional,
  } = props;

  const methods = useFormContext() as any;
  const { control, setValue, formState: { errors }, watch } = methods;
  const [isFocused, setIsFocused] = useState(autoFocus);

  /** simple callback for developer ease. */
  const setDisplayValue = useCallback(
    (value: string) => {
      // console.log(name,value)
      setValue(name, value);
    },
    [name, setValue],
  );

  /** the number value of the input */
  const value: number | undefined = watch(name);

  const isEmpty = (value?: unknown) => {
    return Number.isNaN(value) || value === undefined;
  };

  /**
   * Converts what the user is typing to a valid safe string, preventing non numeric characters
   * and ensuring that - and . are used properly, while still allowing some flexibility
   * (leading/trailing zeros, leading hyphens, etc).
   * @returns {number}
   * @example getSafeStringValue("500.0.") // 500.0
   */
  const getSafeStringValue = useCallback(
    (value?: string | number) => {
      if (isEmpty(value)) return '';

      let strippedValue = String(value).replace(/[^\d.-]/g, ''); // strips out all but numbers, decimals, and hyphens.

      if (!allowDecimal) {
        strippedValue = strippedValue.replace(/\./g, '');
      }
      if (!allowNegative) {
        strippedValue = strippedValue.replace(/-/g, '');
      }

      if (allowDecimal || allowNegative) {
        const decimalIndex = strippedValue.indexOf('.');

        // remove excess hyphens and decimals
        for (let i = strippedValue.length - 1; i > 0; i--) {
          const char = strippedValue[i];
          if (char === '-' || (char === '.' && i > decimalIndex)) {
            strippedValue = strippedValue.substring(0, i) + strippedValue.substring(i + 1);
          }
        }
      }
      return strippedValue;
    },
    [allowDecimal, allowNegative],
  );

  /** The correct string value we should be displaying when the input is not focused. */
  const trueDisplay = useMemo(() => {
    if (isEmpty(value)) {
      return '';
    }
    return String(parseFloat(getSafeStringValue(value)));
  }, [getSafeStringValue, value]);

  /** A more flexible string value we should be allowing when the input is focused. */
  const inputDisplay = useMemo(() => {
    if (isEmpty(value)) {
      return '';
    }
    return getSafeStringValue(value);
  }, [getSafeStringValue, value]);

  const registerOptions: RegisterOptions = {
    required: !optional,
    min: min || min === 0 ? { value: min, message: `Minimum value is ${min}` } : undefined,
    max: max || max === 0 ? { value: max, message: `Maximum value is ${max}` } : undefined,
    // setValueAs: (value: string) => {
    //   const safeString = getSafeStringValue(value);
    //   return safeString ? Number(safeString) : NaN;
    // },
  };

  const error = errors[name ] as FieldError;

  useEffect(() => {
    if (!isFocused) {
      setDisplayValue(trueDisplay);
    }
  }, [inputDisplay, isFocused, setDisplayValue, trueDisplay, value]);

  useAssert(
    (!max && max !== 0) || (!min && min !== 0) || max > min,
    'Maximum value should be greater than minimum value',
  );

  useAssert(
    allowNegative || (!min && min !== 0) || min >= 0,
    'Minimum value should not be less than 0 if the field does not allow negative values',
  );

  /** sets preventUpdate to true, which prevents the value from strictly
   * formatting while the user is typing. */
  function onFocus() {
    setIsFocused(true);
  }

  const disallowInvalidCharacters = (e: ChangeEvent<HTMLInputElement>) => {
    const typedValue = e.target.value;
    const displayValue = getInputDisplay(typedValue);
    setDisplayValue(displayValue);
  };

  const getInputDisplay = (value?: string) => {
    if (isEmpty(value)) {
      return '';
    }
    /** Allow single hyphen for negative number */
    if (value === '-' && allowNegative) {
      return value;
    }
    return getSafeStringValue(value);
  };

  return (
    <Controller
      control={control}
      defaultValue={trueDisplay}
      name={name}
       rules={registerOptions}
      render={({ field: { onBlur, onChange, value, ref } }) => {
        function formatNumber() {
          setIsFocused(false);
          setDisplayValue(trueDisplay);
          onBlur();
        }
        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <BaseTextInput
              autoFocus={autoFocus}
              data-testid={dataTestId || id || name}
              error={error}
              id={id || name}
              name={name}
              onBlur={() => {
                formatNumber();
                setIsFocused(false);
                onBlur();
              }}
              onChange={disallowInvalidCharacters}
              onFocus={onFocus}
              readOnly={inactive}
              ref={ref}
              value={value}
              isNumeric
            />
          </FieldContainer>
        );
      }}
    />
  );
};
