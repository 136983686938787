import React, { memo, useState } from 'react';
import QuoteAwardEmail from '../buyer/quotes/quotedetails/tabs/details/modal/quoteawardemail';
import { ModalPopUp } from './modals/ModalPopUp';
import { Buttons } from '../customcontrols/index';
import NoResult from '../customcontrols/noresult';
import BreakdownList from './quotebreakdownlist';
import { loadingMsg } from '../../utils/texts';
import { MembershipLevel, Permission } from '../../types/auth';
import { LineItemType, QuoteSummaryType } from '../../types/quotedetailstypedef';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes {
  pagefor?: string;
  quoteSummary?: QuoteSummaryType;
  setQuoteSummaryDetails?: any;
  QuoueSummaryGetCurrentSupplierinfo?: any;
  breakDownlist?: LineItemType[];
  userpagefor?: string;
}

function QuoteBreakDown(props: PropsTypes) {
  const {
    pagefor = 'lineitem',
    quoteSummary,
    setQuoteSummaryDetails,
    QuoueSummaryGetCurrentSupplierinfo,
    breakDownlist = [],
    userpagefor,
  } = props;
  const { auth } = useAuthContext();
  const {
    awardModal = false,
    selectedResponseItem = '',
    selectedbreakdownlist = [],
    lineitems_supplier_internalLoader = false,
  } = quoteSummary || {};
  const mlcheck = auth?.membershipLevels.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];

  const [selectedLineItem, setSelectedLineItem] = useState([]) as any;

  const awardModalUpdate = () => {
    if (pagefor === 'lineitems' && awardModal === false) {
      QuoueSummaryGetCurrentSupplierinfo(selectedResponseItem);
      setQuoteSummaryDetails({ currentSupplier: selectedResponseItem });
    }
    setQuoteSummaryDetails({ awardModal: !awardModal });
  };

  return (
    <>
      <h4 className='lineHR'>
        {pagefor === 'suppliers' ? 'Quote Breakdown' : 'Suppliers'} (
        {breakDownlist && breakDownlist.length})
      </h4>
      {breakDownlist && breakDownlist.length > 0 ? (
        <>
          <div className='innerColIndent'>
            <BreakdownList
              {...props}
              breakDownlist={breakDownlist}
              setSelectedLineItem={setSelectedLineItem}
              userpagefor={userpagefor}
              pagefor={pagefor}
              type={1}
            />
            {userpagefor === 'suppliers' ? null : (
              <div className='clearfix'>
                {
                  (pagefor === 'suppliers' &&
                    breakDownlist &&
                    breakDownlist.filter(item => item.selected === true).length > 0) ||
                  (pagefor === 'lineitems' && selectedResponseItem) ? (
                    <Buttons
                      text='Award'
                      classNames='bttn bttn-primary float-right'
                      onClick={awardModalUpdate}
                      title='Send Mail'
                    />
                  ) : // <span className='float-right'  title='Send Mail'>
                  // 	<i className='mdi mdi-email mr-1' />
                  // 	Award
                  // </span>

                  mlcheck.includes(MembershipLevel.BidPoster) ||
                    prmscheck.includes(Permission.AwardQuotes) ? (
                    <Buttons
                      text='Award'
                      classNames='bttn bttn-primary float-right'
                      disable={true}
                      title='Send Mail'
                    />
                  ) : null

                  // <span className='disabled-link float-right' title='Send Mail'>
                  // 	<i className='mdi mdi-email mr-1' />
                  // 	Award
                  // </span>
                }
              </div>
            )}
          </div>
        </>
      ) : (
        <NoResult
          message={
            lineitems_supplier_internalLoader
              ? loadingMsg
              : `No ${pagefor === 'suppliers' ? 'Quote Breakdown' : 'Suppliers'} Available`
          }
        />
      )}
      <ModalPopUp
        size='xl'
        title='Quote Award Notification Email'
        closeModal={awardModalUpdate}
        isOpen={awardModal}
        backdrop='static'
      >
        <QuoteAwardEmail
          {...props}
          awardModalUpdate={awardModalUpdate}
          breakDownlist={
            selectedbreakdownlist.length > 0 ? selectedbreakdownlist : selectedLineItem
          }
        />
      </ModalPopUp>
    </>
  );
}

export default memo(QuoteBreakDown);
