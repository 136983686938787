import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import * as texts from './AddDocument.texts';
import { AddDocumentFormValues, submitAddDocument } from './AddDocument.helpers';
import { AddContractDocumentForm } from './AddContractDocumentForm';
import { Alert } from 'src/components/common/alert';
import { documentAlertId } from '../ContractDocuments.alerts';
import { scrollToTop } from 'src/utils/helpers';
import { useContractDetails } from 'src/features/contract-management/useContractDetails';
import { useContractDocuments } from '../useContractDocuments';

export const AddDocument = () => {
  const { contractDetails } = useContractDetails();
  const contractId = contractDetails?.id;
  const { uploadContractDocument } = useContractDocuments();
  const [triggerReset, setTriggerReset] = useState(false);

  const [uploading, setUploading] = useState(false);

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  // TODO pt 2: Ticket #5224 mentions "Convert to PDF" which is not in Figma. Cliff suggested it gets done automatically
  const onSubmit = async (values: AddDocumentFormValues) => {
    // Enter "uploading" state
    setUploading(true);

    try {
      // Upload document
      await submitAddDocument(uploadContractDocument, values, contractId);
      // Reset form
      setTriggerReset(true);
    } finally {
      // Exit "uploading" state
      setUploading(false);
      scrollToTop();
    }
  };

  useEffect(() => {
    // Reset toggled true, show upload success alert -- TODO: standardize this around upload form(?)
    if (triggerReset) {
      setTriggerReset(false);
      reset();
    }
  }, [reset, triggerReset]);

  const buttonText = uploading ? texts.uploading : contractId ? texts.saveAndAdd : texts.saveDoc;

  return (
    <>
      <Alert id={documentAlertId} allowDismiss />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddContractDocumentForm />
          <FlexContainer justifyContent='flex-end'>
            <DSButton inactive={uploading} type='submit'>
              {buttonText}
            </DSButton>
          </FlexContainer>
        </form>
      </FormProvider>
    </>
  );
};
