import { BidDetailsLink } from '../../../../common/controls/text/links/BidDetailsLink';
import { notifyAwardeeTexts } from '../../../../../utils/texts/agency/awardBid';
import { useBid } from 'src/shared/hooks/useBid';

/**
 * @returns '[Agency Name] has identified documents you are required to review.
 * There may also be additional documentation you must provide.
 * Please go to the Bid Details Page [link] for more information.'
 */
export const ReviewDocsText = () => {
  const { bid } = useBid();

  if (bid) {
    return (
      <span data-testid='review.docs.text'>
        {bid.agencyName} {notifyAwardeeTexts.copy.requiredDocs[0]}
        <BidDetailsLink bidId={bid.bidId}>{notifyAwardeeTexts.copy.requiredDocs[1]}</BidDetailsLink>
        {notifyAwardeeTexts.copy.requiredDocs[2]}
      </span>
    );
  }
  return null;
};
