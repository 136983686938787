/**
 * Get the file type, uppercase
 * @param fileName: string // example: 'image/jpg'
 * @returns string
 * @example getFileExtension(newfile.pdf) // 'PDF'
 */
export function getFileExtension(fileName: string) {
  if (!fileName) {
    return '';
  }
  return fileName.substring(fileName.lastIndexOf('.') + 1).toLocaleUpperCase();
}

/**
 * @returns {string} file name(s) for display
 * @example getFileNames(files) // "foo.pdf, bar.jpg"
 */
export function getFileNames(files?: FileList): string {
  const fileNames = [];
  if (files) {
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
  }
  return fileNames.join(', ');
}
