// CRUD
export const create = '/reminders';
export const get = (reminderId: string) => `/reminders/${reminderId}`;
export const update = (reminderId: string) => `/reminders/${reminderId}`;
export const remove = (reminderId: string) => `/reminders/${reminderId}`;

// Misc.
export const send = (reminderId: string) => `/reminders/${reminderId}/send`;
export const search = '/reminders/search';

export const getPreviewPreferences = '/reminders/preview';
export const setPreviewPreferences = '/reminders/preview';
