import { Table } from 'reactstrap';

import { Buttons } from '../../../../customcontrols';
import { displayDate } from '../../../../../utils/helpers';
import { DSDocument } from 'src/types';

const tdWidth = { type: { width: '25%' }, date: { width: '18%' } };

interface PropsTypes {
  data: DSDocument[];
  documentTitle: string;
  bidID: string | number | null;
  responseId: string | number | null;
  documentType: string;
  bidsResponseDocumentDownload: any;
  downloadAllDocument: any;
  checkduedate?: boolean;
  pagefor?: string;
}

const documents = (props: PropsTypes) => {
  const {
    data,
    documentTitle,
    bidID,
    responseId,
    documentType,
    bidsResponseDocumentDownload,
    downloadAllDocument,
    checkduedate = true,
  } = props;
  const sortedData = data
    ? data.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0))
    : null;
  let isElectronicDocAvailable = false;
  if (data && data.length > 0) {
    const elDocs = data.filter(doc => doc.icon === 'EL' && doc.bidDocId);
    if (elDocs && elDocs.length > 0) {
      isElectronicDocAvailable = true;
    }
  }
  const downloadClick = (e: any, docId: number, bidId: number | string | null) => {
    e.preventDefault();
    bidsResponseDocumentDownload({ docId, id: bidId, type: 'Bid' });
  };

  const downloadAllClick = () => {
    downloadAllDocument({ id: responseId, type: 'Response' });
  };

  if (sortedData && sortedData.length > 0) {
    return (
      <>
        <h4>{documentTitle}</h4>
        {documentTitle === 'Required Documents' &&
        checkduedate === true &&
        isElectronicDocAvailable === true ? (
          <Buttons
            classNames='bttn-primary small float-right'
            title='One-click Bid Response Download'
            text={'Download Entire Bid Package'}
            onClick={downloadAllClick}
          />
        ) : null}
        {isElectronicDocAvailable === true ? null : (
          <h6 className='float-right text-red200 clear-left'>(No document uploaded)</h6>
        )}
        <div className='innerColIndent'>
          {Number(documentType) === 1 ? (
            <ul className='list-unstyled'>
              {sortedData.map((doc, id) => (
                <li key={id}>
                  <span
                    className={`
											${doc.icon === 'EL' && doc.bidDocId ? 'successTick' : ''}
											${doc.icon === 'MN' ? 'manual' : ''}
											${doc.icon === 'NS' || (doc.icon === 'EL' && !doc.bidDocId) ? 'notSubmit' : ''}
											${doc.icon === 'None' || !doc.icon ? 'noneDot' : ''}
									`}
                  />
                  {doc.bidDocId ? (
                    <>
                      {checkduedate === true ? (
                        <span
                          className='staticLink underLine'
                          onClick={e => downloadClick(e, Number(doc.bidDocId), bidID)}
                          title={doc.description}
                        >
                          {doc.description}
                        </span>
                      ) : (
                        <span title={doc.description}>{doc.description}</span>
                      )}
                    </>
                  ) : (
                    <>{doc.description}</>
                  )}
                  {doc.statusName && <>({doc.statusName})</>}
                </li>
              ))}
            </ul>
          ) : (
            <Table className='tableFixed'>
              <tbody>
                <tr>
                  <th>Filename</th>
                  <th style={tdWidth.type}>Type</th>
                  <th style={tdWidth.date}>Date Modified</th>
                </tr>
                {sortedData.map(d => (
                  <tr key={d.bidDocId}>
                    <td className='text-truncate'>
                      <span
                        className='staticLink'
                        onClick={e => downloadClick(e, Number(d.bidDocId), bidID)}
                        title={d.title}
                      >
                        {d.title}
                      </span>
                    </td>
                    <td>{d.bidDocStatusType}</td>
                    <td>{displayDate(d.datePosted)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default documents;
