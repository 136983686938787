import React from 'react';

import { DSSVGIcon, DSSVGIconProps } from '../../icon';

export const ExclaimIcon = ({
  color,
  dataTestId,
  height,
  onClick,
  title,
  viewBox = '0 0 24 24',
  width,
}: DSSVGIconProps) => (
  <DSSVGIcon
    height={height}
    onClick={onClick}
    viewBox={viewBox}
    width={width}
    dataTestId={dataTestId}
    title={title}
  >
    <path fill={`${color}`} d='M11 4H13V15H11V4ZM13 18V20H11V18H13Z' />
  </DSSVGIcon>
);
