import { Loadable, RecoilLoadable } from 'recoil';
import { PropsWithChildren, useMemo } from 'react';

import { Assert } from '@demandstar/components/utils';
import { DSLoadingPanel } from '@demandstar/components/loading-panel';

import { ErrorPanel } from './ErrorPanel';

export type LoadableProps = PropsWithChildren<{
  errorMessage?: string;
  loadingMessage?: string;
}> &
  (
    | {
        loadable: Loadable<unknown>;
        loadables?: never;
      }
    | {
        loadable?: never;
        loadables: Loadable<unknown>[];
      }
  );

export function LoadableWrapper(props: LoadableProps) {
  const { loadable, loadables, children, errorMessage, loadingMessage } = props;

  const state = useMemo(() => {
    if (loadable) {
      return loadable.state;
    } else {
      Assert(loadables, 'If we did not pass loadable, we must pass an array');
      return RecoilLoadable.all(loadables).state;
    }
  }, [loadable, loadables]);

  switch (state) {
    case 'loading':
      return <DSLoadingPanel>{loadingMessage}</DSLoadingPanel>;
    case 'hasValue':
      return <>{children}</>;
    case 'hasError':
      return <ErrorPanel message={errorMessage} />;
  }
}
