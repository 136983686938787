import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { AmplitudeAwardInfo, AmplitudeBidInfo } from '../../../types/amplitude';
import { AmplitudeEventProps, useAmplitudeRelay } from '../../../components/amplitude';
import {
  mapAmplitudeAwardAmounts,
  mapAmplitudeAwardeeNames,
  mapAmplitudeCommodities,
} from '../../../utils/helpers';
import { bidWatcherListState } from '../../../store/recoil/bidState';
import { getLoadableContents } from 'src/utils/recoil';
import { memberInfoSelector } from '../../../store/recoil/memberState';
import { useBid } from '../useBid';
import { useBidAwardees } from 'src/store/recoil/bid-awardees';
import { useEBidResponses } from 'src/store/recoil/ebid-responses/useEBidResponses';
import { usePlanholders } from 'src/store/recoil/planholders/usePlanholders';

export enum AwardBidEventName {
  SaveAwardee = 'award process - save awardee',
  AwardBid = 'award process - award bid',
  NonDemandStarSupplier = 'award process - non-demandstar supplier',
  UploadPublicDocs = 'award process - upload public documents',
}

type AwardBidEvent =
  | {
      eventName: AwardBidEventName.SaveAwardee | AwardBidEventName.AwardBid;
    }
  | {
      eventName: AwardBidEventName.NonDemandStarSupplier;
      eventProps: {
        'Awardee Name': string;
        'Email Address': string;
      };
    }
  | {
      eventName: AwardBidEventName.UploadPublicDocs;
      eventProps: {
        'Award Documents Uploaded': number;
      };
    };

type AwardBidStateEvents =
  | {
      eventName: AwardBidEventName.SaveAwardee | AwardBidEventName.AwardBid;
      eventProps: AmplitudeBidInfo & AmplitudeAwardInfo;
    }
  | {
      eventName: AwardBidEventName.NonDemandStarSupplier;
      eventProps: AmplitudeBidInfo;
    }
  | {
      eventName: AwardBidEventName.UploadPublicDocs;
      eventProps: Omit<AmplitudeBidInfo, 'Bid Name'> & Pick<AmplitudeAwardInfo, 'Award Amount'>;
    };

export function useAwardBidEvents() {
  const { relayEvent } = useAmplitudeRelay();

  const { bid } = useBid();
  const memberInfo = getLoadableContents(useRecoilValueLoadable(memberInfoSelector)) || {
    cu: '',
    st: '',
  };
  const { planholders } = usePlanholders(bid?.bidId);
  const { eBidResponses } = useEBidResponses(bid?.bidId);
  const watchers = getLoadableContents(useRecoilValueLoadable(bidWatcherListState)) || [];
  const { bidAwardees } = useBidAwardees(bid?.bidId);

  const amplitudeBidInfo: AmplitudeBidInfo = useMemo(
    () => ({
      Agency: bid?.agencyName || '',
      'Bid Name': bid?.bidName || '',
      'Commodity Codes': mapAmplitudeCommodities(bid?.commodities),
      County: memberInfo.cu,
      State: memberInfo.st,
      'Number of Planholders': planholders.length,
      'Number of Responses': eBidResponses.length,
      'Number of Watchers': watchers.length,
    }),
    [
      bid?.agencyName,
      bid?.bidName,
      bid?.commodities,
      eBidResponses.length,
      memberInfo.cu,
      memberInfo.st,
      planholders.length,
      watchers.length,
    ],
  );

  const amplitudeAwardInfo: AmplitudeAwardInfo = useMemo(
    () => ({
      'Award Amount': mapAmplitudeAwardAmounts(bidAwardees),
      'Awardee Name': mapAmplitudeAwardeeNames(bidAwardees),
    }),
    [bidAwardees],
  );

  const events: AwardBidStateEvents[] = useMemo(
    () => [
      {
        eventName: AwardBidEventName.SaveAwardee,
        eventProps: { ...amplitudeAwardInfo, ...amplitudeBidInfo },
      },
      {
        eventName: AwardBidEventName.AwardBid,
        eventProps: { ...amplitudeAwardInfo, ...amplitudeBidInfo },
      },
      {
        eventName: AwardBidEventName.NonDemandStarSupplier,
        eventProps: { ...amplitudeBidInfo },
      },
      {
        eventName: AwardBidEventName.UploadPublicDocs,
        eventProps: {
          Agency: amplitudeBidInfo['Agency'],
          'Commodity Codes': amplitudeBidInfo['Commodity Codes'],
          County: amplitudeBidInfo['County'],
          State: amplitudeBidInfo['State'],
          'Number of Planholders': amplitudeBidInfo['Number of Planholders'],
          'Number of Responses': amplitudeBidInfo['Number of Responses'],
          'Number of Watchers': amplitudeBidInfo['Number of Watchers'],
          'Award Amount': amplitudeAwardInfo['Award Amount'],
        },
      },
    ],
    [amplitudeAwardInfo, amplitudeBidInfo],
  );

  return useMemo(() => {
    return {
      logEvent: (event: AwardBidEvent) => relayEvent(events as AmplitudeEventProps[], event),
    };
  }, [events, relayEvent]);
}
