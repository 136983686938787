export enum Margin {
  Base = '1rem',
  Extra = '1.5rem',
  Less = '0.5rem',
  None = '0',
  Single = '0.0625rem',
  Small = '0.25rem',
}

export enum Padding {
  Base = '1rem',
  Button = '0.5rem 1rem',
  Extra = '1.5rem',
  Less = '0.5rem',
  None = '0',
  Small = '0.25rem',
}

export enum Width {
  /* 400px, used as optional shorter input width */
  Input = '25rem',
  Full = '100%',
}
