import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

type dataTypes = Record<string, unknown> | undefined;

export function getGeoStates() {
  return Api.getRequestWithAuthentication(Paths.geoStates, {
    baseURL: constants.api.userUrl,
  });
}

export function getGeoCounties(state: { value: any; stateId: string | number }) {
  return Api.getRequestWithAuthentication(
    `${Paths.geoCounties}?stateId=${state.value || state.stateId || state || 0}`,
    {
      baseURL: constants.api.userUrl,
    },
  );
}

export function updateMemberAddress(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.updateMemberAddress, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getCommodityCodes(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.getCommodityCodes, data);
}

export function updateCommodityCodes(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.updateCommodityCodes, data);
}

export function UpdateMemberTagsandCommodities(data: dataTypes) {
  return Api.postRequestWithAuthentication(Paths.UpdateMemberTags, data);
}

export function searchCommodityCodes(data: { searchText: string }) {
  return Api.getRequestWithAuthentication(
    `${Paths.searchCommodityCodes}?searchText=${data.searchText}`,
  );
}

export function checkAccountExist(data = {}) {
  return Api.postRequest(Paths.IsCompanyOrAccountExists, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getIncompleteRegistration(data = {}) {
  return Api.postRequest(Paths.getIncompleteRegistration, data, {
    baseURL: constants.api.userUrl,
  });
}

export function addInCompleteRegistrationData(data = {}) {
  return Api.postRequest(Paths.addInCompleteRegistrationData, data, {
    baseURL: constants.api.userUrl,
  });
}

export function addMemberAccount(data = {}) {
  return Api.postRequest(Paths.addMemberAccount, data, {
    baseURL: constants.api.userUrl,
  });
}

export function claimPlanholderAccount(data = {}) {
  return Api.postRequest(Paths.claimPlanholderAccount, data, {
    baseURL: constants.api.userUrl,
  });
}
