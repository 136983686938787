import Configurations from 'src/settings';
import { FeatureFlagKey } from '../common/feature-flag';
import { MemberType } from 'src/utils/constants';

export type HeaderItem = {
  routeMatch: string;
  href: string;
  name: string;
  memberTypeFilter?: (memberType: string) => boolean;
  featureFlag?: FeatureFlagKey;
};

export const navItems: HeaderItem[] = [
  {
    routeMatch: '/dashboard',
    href: '/dashboard',
    name: 'Dashboard',
  },
  {
    routeMatch: '/bid**',
    href: '/bids',
    name: 'Bids',
  },
  {
    routeMatch: '/quote**',
    href: '/quotes',
    name: 'Quotes',
    memberTypeFilter: (memberType: string) => memberType !== MemberType.BasicSupplier,
  },
  {
    routeMatch: '/contract**',
    href: '/contract',
    name: 'Contracts',
    memberTypeFilter: (memberType: string) => memberType === MemberType.AgencyBuyer,
    featureFlag: FeatureFlagKey.ContractManagement,
  },
  {
    routeMatch: '/activities**',
    href: '/activities/notifications',
    name: 'Activities',
  },
  {
    routeMatch: '/responses**',
    href: '/responses/required-actions',
    name: 'Responses',
    memberTypeFilter: (memberType: string) => memberType !== MemberType.AgencyBuyer,
  },
  {
    routeMatch: '/research**',
    href: '/research',
    name: 'Research',
    memberTypeFilter: (memberType: string) => memberType === MemberType.AgencyBuyer,
  },
];

export const opsNavItems: HeaderItem[] = [
  {
    routeMatch: '/bid**',
    href: `${Configurations.REACT_APP_OPS_URL}/bids`,
    name: 'Bids',
  },
  {
    routeMatch: '/quote**',
    href: `${Configurations.REACT_APP_OPS_URL}/quotes`,
    name: 'Quotes',
  },
  {
    routeMatch: '/ops/agency/registration',
    href: '/ops/agency/registration',
    name: 'Create Member',
  },
  {
    routeMatch: '/member**',
    href: `${Configurations.REACT_APP_OPS_URL}/ops/members`,
    name: 'Member',
  },
  {
    routeMatch: '/reports**',
    href: `${Configurations.REACT_APP_OPS_URL}/ops/reports`,
    name: 'Reports',
  },
];
