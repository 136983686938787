import styled from 'styled-components/macro';

import { DSTree } from '@demandstar/components/tree';
import { ToggleExpandIcon } from '@demandstar/components/icon/ToggleExpandIcon';

import { limitedPaths } from 'src/shared/constants';

import * as constants from './BrowseBids.constants';
import * as texts from './BrowseBids.texts';

import { HeaderWithScrollToTop } from './BrowseBids.styles';
import { useBrowseBids } from './useBrowseBids';

const EqualColumnGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`;
export function StateAndMSATree() {
  const { buildGeoLinkURI, geoCodes = [], urlSelectedSEOIDs } = useBrowseBids();

  const treeData = geoCodes;

  const dataLength = treeData.length;
  const middleIndex = Math.floor(dataLength / 2);
  const columnData = [treeData.slice(0, middleIndex + 1), treeData.slice(middleIndex + 1)];

  return (
    <>
      <HeaderWithScrollToTop
        data-testid={constants.geoCodeElementId}
        id={constants.geoCodeElementId}
      >
        {texts.stateAndMSATreeTitle}
      </HeaderWithScrollToTop>
      <EqualColumnGrid>
        {columnData.map((columnDataPortion, index) => (
          <DSTree
            baseUrl={limitedPaths.browseBids}
            buildLink={buildGeoLinkURI}
            dataTestId='state-msa-tree'
            icon={ToggleExpandIcon}
            key={index}
            openNodes={urlSelectedSEOIDs}
            treeData={columnDataPortion}
          />
        ))}
      </EqualColumnGrid>
    </>
  );
}
