import { useCallback, useMemo } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { eBidResponsesQuerySelector, eBidResponsesRequestIdState } from './ebid-responses.recoil';

/** hook for accessing eBidResponses from a single bidId. */
export function useEBidResponses(bidId?: number) {
  const setEBidResponsesRequestId = useSetRecoilState(eBidResponsesRequestIdState(bidId));
  const eBidResponsesLoadable = useRecoilValueLoadable(eBidResponsesQuerySelector(bidId));

  const eBidResponses = useMemo(
    () => eBidResponsesLoadable.valueMaybe() || [],
    [eBidResponsesLoadable],
  );
  /**
   * manually update eBidResponses of the bidId passed to useeBidResponses
   */
  const refreshEBidResponses = useCallback(() => {
    setEBidResponsesRequestId(requestId => requestId + 1);
  }, [setEBidResponsesRequestId]);

  return {
    refreshEBidResponses,
    eBidResponses,
    eBidResponsesLoadable,
  };
}
