// TODO: Replace with library Page Layout components when available
import styled from 'styled-components';

import { Padding } from '@demandstar/components/styles';

export const SubscriptionTableWrapper = styled.div`
  flex-grow: 3;
  flex-shrink: 2;
  padding: ${Padding.Base};
  min-width: 18rem;
  width: 66.66%;
`;

export const SubscriptionStatusWrapper = styled.div`
  flex-flow: row;
  flex-grow: 3;
  flex-shrink: 1;
  justify-content: center;
  min-height: 10rem;
  min-width: 9rem;
  padding: ${Padding.Base};
  width: 33.33%;
`;
