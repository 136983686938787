import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import { defaultTreeNode, DSTreeNode } from '@demandstar/components/tree';

import { appText } from '@demandstar/components/constants';

import {
  browseBidsAPIRequestState,
  geoCodesMapState,
  selectedBrowseBidMetroState,
  selectedBrowseBidSEOCategoryState,
  selectedBrowseBidSEOGroupState,
  selectedBrowseBidStateState,
  seoBaseCodesState,
  seoPageTitleFullState,
  urlSelectedSEOIDsState,
} from './BrowseBids.state';

import * as constants from './BrowseBids.constants';
import * as texts from './BrowseBids.texts';

import { HistoricalBidAPIRequest, SEOBaseCodeAPIResponse } from './BrowseBids.d';

import { getLoadableContents } from 'src/utils';
import { scrollToTop } from 'src/utils/helpers';
import { SubPaths } from 'src/shared/constants';
import { useRouter } from 'src/shared/hooks';

/**
 * Get seo ids by route parameters.
 * set default value to 'florida' as the seoState value.
 * @returns {strings} seoCategory, seoGroup, seoMetroArea, seoState
 */
export function useBrowseBidsRouterParameters() {
  const {
    routerParams: { seoCategory = '', seoGroup = '', seoMetroArea = '', seoState = '' },
  } = useRouter();

  return { seoCategory, seoGroup, seoMetroArea, seoState };
}

export function useBrowseBids() {
  const seoBaseCodesStateLoadable = useRecoilValueLoadable(seoBaseCodesState);
  const seoBaseCodes = getLoadableContents(seoBaseCodesStateLoadable, {} as SEOBaseCodeAPIResponse) as any;

  const commodityCodes = useMemo(() => {
    return seoBaseCodes.commodities || [];
  }, [seoBaseCodes.commodities]);

  const geoCodes = useMemo(() => {
    return seoBaseCodes.locations || [];
  }, [seoBaseCodes.locations]);

  const [geoCodesMap, setGeoCodesMap] = useRecoilState(geoCodesMapState);
  const setSeoPageTitleFull = useSetRecoilState(seoPageTitleFullState);
  const [urlSelectedSEOIDs, setURLSelectedSEOIDs] = useRecoilState(urlSelectedSEOIDsState);
  const setBrowseBidsAPIRequest = useSetRecoilState(browseBidsAPIRequestState);
  const [headerTitleIntro, setHeaderTitleIntro] = useState<string>('');
  const [headerTitleSubject, setHeaderTitleSubject] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');

  const { seoCategory, seoGroup, seoMetroArea, seoState } = useBrowseBidsRouterParameters();

  const { location } = useRouter();

  const setSEOSelectedIDsByRoute = useCallback(() => {
    // Persist the current route for 'Back' link from individual legacy bid pages.
    // localStorage used here because Recoil state doesn't appear to
    // persist from the SEO static scaffolding to the legacy code : (
    localStorage.setItem(constants.browseBidBaseURLStorageKey, location.pathname);

    // Persist IDs
    const urlSelectedIds = {
      commodityCategory: seoCategory,
      commodityGroup: seoGroup,
      msa: seoMetroArea,
      state: seoState,
    };

    setURLSelectedSEOIDs(urlSelectedIds);
  }, [location.pathname, seoCategory, seoGroup, seoMetroArea, seoState, setURLSelectedSEOIDs]);

  const [selectedBrowseBidSEOGroup, setSelectedBrowseBidSEOGroup] = useRecoilState(
    selectedBrowseBidSEOGroupState,
  );
  const [selectedBrowseBidSEOCategory, setSelectedBrowseBidSEOCategory] = useRecoilState(
    selectedBrowseBidSEOCategoryState,
  );
  const [selectedBrowseBidState, setSelectedBrowseBidState] = useRecoilState(
    selectedBrowseBidStateState,
  );
  const [selectedBrowseBidMetro, setSelectedBrowseBidMetro] = useRecoilState(
    selectedBrowseBidMetroState,
  );

  const parseGeoCodesMap = useCallback(() => {
    // Set a hash for lookup by `seoId`
    const geoCodesMapTemp: Record<string, DSTreeNode> = {};
    geoCodes.forEach(geoCode => {
      geoCodesMapTemp[geoCode.id] = geoCode;
      if (geoCode.items) {
        geoCode.items.forEach(region => {
          geoCodesMapTemp[region.id] = region;
        });
      }
    });

    setGeoCodesMap(geoCodesMapTemp);
  }, [geoCodes, setGeoCodesMap]);

  const hasSEOSearchValues = useMemo(() => {
    return (
      selectedBrowseBidSEOCategory.link ||
      selectedBrowseBidSEOGroup.link ||
      selectedBrowseBidMetro.link ||
      selectedBrowseBidState.link
    );
  }, [
    selectedBrowseBidMetro.link,
    selectedBrowseBidSEOCategory.link,
    selectedBrowseBidSEOGroup.link,
    selectedBrowseBidState.link,
  ]);

  const { commodityCategory, commodityGroup, msa, state } = urlSelectedSEOIDs;

  /**
   * Build router link URI for Commodity Codes
   * @param baseUrl
   * @param node
   * @param parentId
   * @param selected
   * @returns
   */
  const buildCommodityCodeLinkURI = useCallback(
    (baseUrl: string, seoId: string, parentId?: string, selected?: boolean) => {
      let uri = baseUrl;

      // state value
      if (state) {
        uri += `/${SubPaths.States}/${state}`;

        // metro - only conditionally added with a state value
        if (msa) {
          uri += `/${SubPaths.MetroArea}/${msa}`;
        }
      }

      // group - has no parentId
      if (!parentId) {
        // append if not selected
        if (!selected) {
          uri += `/${SubPaths.Groups}/${seoId}`;
        }
      }

      // category, has a parentId
      else {
        // add parent id
        uri += `/${SubPaths.Groups}/${parentId}`;
        // add cat id if not already selected
        if (!selected) {
          uri += `/${SubPaths.Categories}/${seoId}`;
        }
      }
      return uri;
    },
    [msa, state],
  );

  /**
   * Build router link URI for Commodity Codes
   * @param baseUrl
   * @param node
   * @param parentId
   * @param selected
   */
  const buildGeoLinkURI = useCallback(
    (baseUrl: string, seoId: string, parentId?: string, selected?: boolean) => {
      let uri = baseUrl;

      // state - no parent id
      if (!parentId) {
        // append if not selected
        if (!selected) {
          uri += `/${SubPaths.States}/${seoId}`;
        }
      }

      // metro, has a parentId
      else {
        // add metro id
        uri += `/${SubPaths.States}/${parentId}`;
        // add msa id if not selected
        if (!selected) {
          uri += `/${SubPaths.MetroArea}/${seoId}`;
        }
      }

      // group
      if (commodityGroup) {
        uri += `/${SubPaths.Groups}/${commodityGroup}`;
      }

      // category
      if (commodityCategory) {
        uri += `/${SubPaths.Categories}/${commodityCategory}`;
      }

      return uri;
    },
    [commodityCategory, commodityGroup],
  );

  // Scroll to the top of the page if there are no selected values
  useEffect(() => {
    if (!hasSEOSearchValues) {
      scrollToTop();
    }
  }, [hasSEOSearchValues]);

  // Set selected DSTreeNode objects and API payload by selected seo ids
  useEffect(() => {
    // Set API request payload
    const apiRequestPayload: HistoricalBidAPIRequest = {};

    // Iterate commodity codes.
    if (seoGroup) {
      let selectedGroup = defaultTreeNode;
      for (let i = 0; i < commodityCodes.length; i++) {
        const group = commodityCodes[i];

        // Find and store selected group by route parameter
        if (group.link === seoGroup) {
          selectedGroup = group;

          // Update API request payload
          apiRequestPayload.commodityGroup = group.link;

          // iterate over any items
          if (seoCategory && group.items) {
            let selectedCategory = defaultTreeNode;
            for (let j = 0; j < group.items.length; j++) {
              const category = group.items[j];
              // find and store selected category by route parameter
              if (category.link === seoCategory) {
                selectedCategory = category;

                // Update API request payload
                apiRequestPayload.commodityCategory = category.link;

                break;
              }
            }
            setSelectedBrowseBidSEOCategory(selectedCategory);
          }
          break;
        }
      }
      setSelectedBrowseBidSEOGroup(selectedGroup);
    }

    // Iterate geographic codes.
    if (seoState) {
      let selectedState = defaultTreeNode;
      for (let i = 0; i < geoCodes.length; i++) {
        const geoNode = geoCodes[i];

        // find and store selected state by route parameter
        if (geoNode.link === seoState) {
          selectedState = geoNode;

          // Update API request payload
          apiRequestPayload.state = geoNode.id;

          let selectedMetro = defaultTreeNode;

          // iterate over any Regions
          if (seoMetroArea && geoNode.items) {
            for (let j = 0; j < geoNode.items.length; j++) {
              const metro = geoNode.items[j];
              // find and store selected metro by route parameter
              if (metro.link === seoMetroArea) {
                selectedMetro = metro;

                // Update API request payload
                apiRequestPayload.msa = metro.link;

                break;
              }
            }
          }

          // Reset metro with a blank value if none was selected.
          setSelectedBrowseBidMetro(selectedMetro);
          break;
        }
      }
      setSelectedBrowseBidState(selectedState);
    }
    // Persist API request payload.
    setBrowseBidsAPIRequest(apiRequestPayload);
  }, [
    commodityCodes,
    geoCodesMap,
    geoCodes,
    parseGeoCodesMap,
    seoCategory,
    seoGroup,
    seoMetroArea,
    seoState,
    setBrowseBidsAPIRequest,
    setSelectedBrowseBidMetro,
    setSelectedBrowseBidSEOCategory,
    setSelectedBrowseBidSEOGroup,
    setSelectedBrowseBidState,
  ]);

  // Set display text by selected values.
  useEffect(() => {
    const geographicName = `${selectedBrowseBidState?.label ? selectedBrowseBidState.label : ''}${
      selectedBrowseBidMetro?.label ? ` - ${selectedBrowseBidMetro?.label}` : ''
    }`;
    const ccGroupTitle = selectedBrowseBidSEOGroup?.label || '';
    const ccCategoryTitle = selectedBrowseBidSEOCategory?.label || '';
    const ccDisplay = `${ccGroupTitle ?? ''}${ccCategoryTitle ? ` / ${ccCategoryTitle}` : ''}`;
    const pageDescriptionTemp = `${texts.pageTitleSuffix} ${
      geographicName ? ` in ${geographicName} ` : ''
    }`;
    setPageDescription(pageDescriptionTemp);

    const metaDescriptionTemp = `Browse awarded government ${pageDescription}${
      geographicName ? ` for ${geographicName}` : ''
    }. Discover all bid opportunities, RFPs, and government contracts with ${geographicName} for Commodity Codes related to ${ccDisplay} `;
    setMetaDescription(metaDescriptionTemp);

    const pageTitleTemp = geographicName || `${appText.companyName} | ${texts.pageTitleSuffix}`;
    setPageTitle(pageTitleTemp);
    const pageTitleFullTemp = `${pageDescription} ${
      ccDisplay ? `for Commodity Codes related to ${ccDisplay}` : ''
    } | ${appText.companyName}`;

    // Persist the current route for 'Back' link from individual legacy bid pages.
    localStorage.setItem(constants.browseBidFullTitleStorageKey, pageTitleFullTemp);

    setSeoPageTitleFull(pageTitleFullTemp);

    const headerTitleIntro = `Browse ${ccDisplay ? ccDisplay : 'awarded '}`;
    const headerTitleSubject = `${ccDisplay ? '' : 'government bids'}${
      geographicName
        ? ` in ${geographicName}.`
        : ` by Geographic Regions and ${texts.nigpCommodityGroups}.`
    }`;
    setHeaderTitleIntro(headerTitleIntro);
    setHeaderTitleSubject(headerTitleSubject);
  }, [
    pageDescription,
    selectedBrowseBidMetro?.label,
    selectedBrowseBidSEOCategory?.label,
    selectedBrowseBidSEOGroup?.label,
    selectedBrowseBidState.label,
    setSeoPageTitleFull,
  ]);

  return {
    buildCommodityCodeLinkURI,
    buildGeoLinkURI,
    commodityCodes,
    geoCodesMap,
    geoCodes,
    hasSEOSearchValues,
    headerTitleIntro,
    headerTitleSubject,
    metaDescription,
    pageDescription,
    pageTitle,
    parseGeoCodesMap,
    setSEOSelectedIDsByRoute,
    setURLSelectedSEOIDs,
    urlSelectedSEOIDs,
  };
}
