import styled from 'styled-components';

import { FontFamily, Margin, ParagraphFontSize } from './../styles';
import { Inactive } from '../types';
import { pxToRem } from '../utils/conversions';

const choicePx = 20;

export const choiceRem = pxToRem(choicePx);
export const choicePaddingRem = pxToRem(choicePx + 10);
export const halfChoiceRem = pxToRem(choicePx / 2);

export type ChoiceLabelProps = Inactive & {
  /** adjusts margins to keep the checkbox aligned with an input on the same line */
  inlineWithInput?: boolean;
};

/**
 * ChoiceLabel is used for radio and checkboxes labels.
 */
export const ChoiceLabel = styled.label<ChoiceLabelProps>`
  display: block;
  position: relative;
  padding: 0 0 0 ${choicePaddingRem};
  margin: ${({ inlineWithInput }) =>
    inlineWithInput
      ? `${halfChoiceRem} ${halfChoiceRem} 0 0`
      : `0 ${halfChoiceRem} ${Margin.Base} 0`};
  cursor: ${({ inactive }) => (inactive ? 'not-allowed' : 'pointer')};
  line-height: ${choiceRem};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Base};
`;
