import { atom } from 'recoil';

export interface BreadcrumbsState {
  /** component to display on right side of the breadcrumbs bar, generally a set of <li>-wrapped buttons */
  component?: React.ReactChild;
  /** the id of the current bid/contract/quote, used to  */
  recordId?: string | number;
  /** label to display for a page labeled "RecordType Details" (RecordType = bids,contract,quote), if in the list */
  recordLabel?: string;
  /** Lookup key for the Route.page attribute of component routes, defaults to lookup by route/path match */
  page?: string;
  /** Label to display for the current page */
  activePageLabel: string;
}

export const breadcrumbsState = atom<BreadcrumbsState>({
  key: 'breadcrumbsState',
  default: {
    activePageLabel: '',
  },
});
