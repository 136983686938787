import { ReactNode } from 'react';

import {
  DataList,
  DSRecordItemSubTitle,
  DSRecordItemTitle,
  FlexStatus,
} from './DSRecordItem.styles';
import { FlexContainer } from '..';

import { DSPill, DSPillType } from '../pill';
import { H5 } from '../styles';

export enum DSRecordItemStatus {
  Active = 'Active',
  Awarded = 'Awarded',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Completed = 'Completed',
  Draft = 'Draft',
  Overdue = 'Overdue',
  RecommendationOfAward = 'Recommendation of Award',
  UnderEvaluation = 'Under Evaluation',
}

export type DSRecordItemProps = {
  data: { title: string; value: string | number | boolean | undefined }[];
  status?: DSRecordItemStatus;
  subtitle?: string;
  title: ReactNode;
  watch?: ReactNode;
};

/**
 * @description Styles a single record data, like a bid or contract.
 * @example <DSRecordItem data={[]} title='' status=''/>
 * @param {array}     data - Array of data in title/value pairs.
 * @param {enum}      status - Active | Awarded | Cancelled | Closed | Completed | Draft |
 *                             Overdue | RecommendationOfAward | UnderEvaluation
 * @param {string}    subtitle - Optional value for more information
 * @param {ReactNode} title - Name of the Record, can be a Link
 * @param {ReactNode} watch - optional JSX.Element to show icon
 */

export const DSRecordItem = (props: DSRecordItemProps) => {
  const { data = [], status = '', subtitle = '', title = '', watch = false } = props || {};

  let pillType: DSPillType | undefined;

  if (status) {
    switch (status.toLowerCase()) {
      case 'active':
      case 'awarded':
        pillType = DSPillType?.Success;
        break;
      case 'cancelled':
        pillType = DSPillType?.Error;
        break;
      case 'draft':
        pillType = DSPillType?.Warning;
        break;
    }
  }

  return (
    <div>
      <div className='row d-flex justify-content-center'>
        <DSRecordItemTitle>
          <H5>{title}</H5>
        </DSRecordItemTitle>
        {status && (
          <FlexStatus>
            <DSPill showIcon={false} type={pillType}>
              {status}
            </DSPill>
            {watch && <span data-testid='rc-watch'>{watch}</span>}
          </FlexStatus>
        )}
      </div>
      {subtitle && (
        <DSRecordItemSubTitle data-testid='rc-subtitle'>{subtitle}</DSRecordItemSubTitle>
      )}
      <DataList>
        {data &&
          data.map((item, index) => (
            <li key={index}>
              <strong>{item.title}:</strong>
              <br />
              {item.value}
            </li>
          ))}
      </DataList>
    </div>
  );
};
