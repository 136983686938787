import styled from 'styled-components/macro';

import {
  BackgroundColor,
  FlexContainer,
  FontWeight,
  Padding,
  TextColor,
} from '@demandstar/components/styles';

import { appText } from '@demandstar/components/constants';

import { currentYear } from 'src/utils/helpers';
import { Link } from 'react-router-dom';
import { MemberType } from 'src/utils/constants';
import { useAuthContext } from 'src/auth/AuthProvider';

const FooterContainer = styled(FlexContainer)`
  background: ${BackgroundColor.Footer};
  color: ${TextColor.Contrast};
  column-gap: ${Padding.Less};
  justify-content: flex-end;
  padding-right: ${Padding.Base};
`;

const FooterInfo = styled(FlexContainer)`
  padding: ${Padding.Less} ${Padding.Extra};
  column-gap: ${Padding.Less};
  font-weight: ${FontWeight.Footer};
`;

const FooterLinkList = styled(FlexContainer)`
  justify-content: flex-end;
  padding: ${Padding.Less} ${Padding.Base};
  column-gap: ${Padding.Less};
  div {
    a {
      color: ${TextColor.Contrast};
      font-weight: ${FontWeight.Footer};
    }
  }
`;

export function StaticFooter() {
  const { auth } = useAuthContext();

  const userpath = auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';

  return (
    <FooterContainer>
      <FooterInfo>
        <span>&copy; {currentYear()}</span>
        <span>{appText.companyFullName}</span>
      </FooterInfo>
      <FooterLinkList>
        <div>
          <Link data-testid='help.footer' to={`/${userpath}/help`}>
            Help
          </Link>
        </div>
        |
        <div>
          <Link data-testid='contact.footer' to={`/${userpath}/help/gettinghelp`}>
            Contact
          </Link>
        </div>
        |
        <div>
          <a
            data-testid='termsofservice.footer'
            rel='noopener noreferrer'
            target='_blank'
            href='https://network.demandstar.com/terms-of-use/'
          >
            Terms of Use
          </a>
        </div>
        |
        <div>
          <a
            data-testid='privacypolicy.footer'
            rel='noopener noreferrer'
            target='_blank'
            href='https://network.demandstar.com/privacy-policy/'
          >
            Privacy Policy
          </a>
        </div>
      </FooterLinkList>
    </FooterContainer>
  );
}
