import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import {
  DSSelectClassPrefix,
  getLabel,
  getReactSelectProps,
  getSelectedOptions,
  getValue,
  isMultiValue,
} from '../../inputs/select/DSSelect.utils';
import { DSSelectFieldProps, SelectOption } from './DSSelectField.d';
import { DSSelectStyled, IconOption, styleOverrides } from '../../inputs/select';
import { FieldContainer, getFieldContainerProps } from '../../field-container/FieldContainer';
import { Assert } from '../../utils';

/** Select box to be used in an RHF form.
 * @warning if you're getting errors from your option array, try converting the option interface into a type
 * @example
 * <FormProviders {...methods}>
    <DSSelectField
      isClearable
      label={texts.formLabels.supplier}
      message={texts.formMessages.supplier}
      name='supplier'
      optional
      labelField='name'
      valueField={false} // takes whole object as value
      options={supplierOptions}
    />
  </FormProvider>
 */
export function DSSelectField<I extends SelectOption, O>(props: DSSelectFieldProps<I, O>) {
  const {
    dataTestId,
    isMulti,
    label,
    labelField,
    keyField,
    name,
    onCreateOption,
    onMultiSelect,
    onSelect,
    optional,
    options,
    rules,
    valueField,
  } = props;

  const methods = useFormContext() as any;
  const { control, errors, setValue, watch } = methods;
  const error = get(errors || methods.formState.errors, name);
  const emptyFallback = isMulti ? [] : null;

  const testId = dataTestId || name;

  /** the selected value of the input */
  const value: unknown = watch(name) ?? emptyFallback;

  const setSelectionValue = useCallback(
    (value: O | O[] | undefined) => {
      setValue(name, value, { shouldValidate: true });
    },
    [name, setValue],
  );

  const formattedOptions = useMemo(() => {
    return options.map(option => {
      return {
        label: getLabel(option, labelField),
        value: getValue(option, valueField),
      };
    }) as any;
  }, [labelField, options, valueField]);

  const SelectComponent = props.onCreateOption ? CreatableSelect : Select;

  const handleCreateOption = useCallback(
    (value: string) => {
      setValue(name, undefined, { shouldValidate: true });
      if (onCreateOption) onCreateOption(value);
    },
    [name, onCreateOption, setValue],
  );

  const selectedOption = useMemo(() => {
    return getSelectedOptions(formattedOptions, isMulti, keyField, value);
  }, [formattedOptions, isMulti, keyField, value]);

  const registerOptions: any = useMemo(() => {
    return {
      ...rules,
      required: !optional,
      validate: {
        ...rules?.validate,
        isRequired: (v: unknown) => {
          if (optional) {
            return true;
          }
          if (isMulti) {
            Assert(Array.isArray(v), 'isMulti should always have an array value');
            return v.length > 0 || `${label} is required`;
          }
        },
      },
    };
  }, [isMulti, label, optional, rules]);

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      render={({ name, onBlur, ref }: any) => {
        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <DSSelectStyled error={error} data-testid={testId}>
              <SelectComponent
                aria-errormessage={`${name}-error`}
                aria-label={name}
                // This is needed to keep us safe from SCSS styles.
                classNamePrefix={DSSelectClassPrefix}
                components={{ Option: IconOption }}
                inputId={name}
                onBlur={onBlur}
                onChange={selectable => {
                  if (!isMultiValue(selectable)) {
                    Assert(
                      !isMulti,
                      'There should only be multiple values if we have passed isMulti',
                    );
                    const value = selectable?.value as O;
                    setSelectionValue(value);
                    onSelect && onSelect(value);
                  } else {
                    Assert(
                      isMulti && Array.isArray(selectable),
                      'There should only be multiple values if we have passed isMulti',
                    );
                    const value = selectable.map(val => val.value as O);
                    setSelectionValue(value);
                    onMultiSelect && onMultiSelect(value);
                  }
                }}
                onCreateOption={handleCreateOption}
                openMenuOnFocus
                options={formattedOptions}
                ref={ref}
                styles={styleOverrides}
                value={selectedOption}
                {...getReactSelectProps<I, O>(props)}
              />
            </DSSelectStyled>
          </FieldContainer>
        );
      }}
      rules={registerOptions}
    />
  );
}
