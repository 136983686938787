import { commonLabels } from 'src/shared/constants';

export const addReminderHeader = 'Set a Reminder';
export const addReminderDescription =
  "Make sure you don't forget something important by setting a reminder on this contract. You may set one for yourself on DemandStar, or send one via email to a supplier.";
export const form = {
  labels: {
    dueDate: 'Due on this date:',
    remindDate: 'Remind on this date:',
    sendEmail: 'Send the email reminder to:',
    sendCopy:
      'Send me a copy (You will receive an email reminder, and a notification on DemandStar)',
    subject: 'What do you need to remember?',
    contract: 'What contract would you like to associate this with?',
    body: "Add any details you'd like",
  },
  messages: {
    sendEmail: 'You can add multiple email addresses separated by a comma.',
    subject: 'This will be the subject line of your reminder.',
  },
  errors: {
    sendEmail: 'One or more emails are invalid',
  },
  submit: 'Set Reminder',
  cancel: commonLabels.cancel,
  update: 'Update Reminder',
} as const;
