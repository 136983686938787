import { useCallback, useState } from 'react';

import { AutoRenewalModal } from './AutoRenewalModal';
import { DSCheckbox } from '@demandstar/components/index';
import { useProfileInfo } from 'src/shared/hooks/useProfileInfo';

interface AutorenewProps {
  /** whether autorenewal is currently set as "on" */
  isAutoRenewalOn: boolean;
  isDisabled?: boolean;
}

/**
 * @description displays a checkbox & confirmation modal for updating auto-renewal
 * @returns JSX.Element
 *
 * @example <AutoRenewal
 *  isAutoRenewalOn={true}
 * />
 */
export const AutoRenewal = ({ isAutoRenewalOn, isDisabled }: AutorenewProps) => {
  const { updateAutoRenewalSubscription } = useProfileInfo();
  const [modalOpen, setModelOpen] = useState(false);

  const handleClick = () => {
    setModelOpen(!modalOpen);
  };

  const handleSubmit = useCallback(async () => {
    setModelOpen(false);
    await updateAutoRenewalSubscription(!isAutoRenewalOn);
  }, [isAutoRenewalOn, updateAutoRenewalSubscription]);

  return (
    <>
      <div className='auto-renew'>
        <DSCheckbox
          name='autorenew'
          dataTestId={'autorenewal.checkbox'}
          label={''}
          checked={isAutoRenewalOn}
          onClick={handleClick}
          inactive={isDisabled}
        ></DSCheckbox>
      </div>
      <AutoRenewalModal
        isAutoRenewalOn={isAutoRenewalOn}
        isOpen={modalOpen}
        onChangeAutoRenewal={handleSubmit}
        onCancel={handleClick}
      />
    </>
  );
};
