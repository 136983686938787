import { useEffect } from 'react';

import { DSLoadingPanel } from '@demandstar/components/loading-panel';

import { ContentRowWrapper, PanelLabel } from '../../../../shared/styles';
import { ArrowTitle } from '../../../../components/layout/titles';
import { getDefaultLoadingMessage } from '../../../../utils/message';
import { navigationPaths } from '../../../../shared/constants';
import { requiredActionLabels } from '../../../../shared/constants';
import { requiredActionListState } from '../../../../store/recoil/award-bid';
import { RequiredActionsTable } from './RequiredActionsTable';
import { RESPONSE_LABEL_PLURAL } from '../../../../shared/constants/bids';
import { selectedBidIdState } from '../../../../store/recoil/bidState';
import { useRequiredActions } from '../../../../shared/hooks/award-bid/useRequiredActions';
import { useResetOnUnmount } from '../../../../shared/hooks/useResetOnUnmount';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';
import { useSetBreadcrumb } from '../../../../shared/hooks/useSetBreadcrumb';
import { useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';

export function RequiredActionList() {
  const { selectedBidId } = useSelectedBidId();
  const { supplierMemberId } = useSupplierMemberId();

  useResetOnUnmount(selectedBidIdState);
  useResetOnUnmount(requiredActionListState);

  const { refreshRequiredActions, requiredActionList, requiredActionsLoading } =
    useRequiredActions();

  useSetBreadcrumb({
    page: RESPONSE_LABEL_PLURAL,
    altname: requiredActionLabels.titlePlural,
    title: requiredActionLabels.titlePlural,
    name: requiredActionLabels.titlePlural,
    menuactive: navigationPaths.subPaths.Responses,
  });

  /**
   * Show all RequiredActions: Refresh only for the default `selectedBidId` (-1)
   */
  useEffect(() => {
    if (selectedBidId <= 0) {
      refreshRequiredActions();
    }
  }, [refreshRequiredActions, selectedBidId, supplierMemberId]);

  const requiredActionCountDisplay = requiredActionList.length
    ? `(${requiredActionList.length})`
    : '';

  return (
    <>
      <ArrowTitle label={requiredActionLabels.titlePlural} />
      <ContentRowWrapper>
        <PanelLabel>{`${requiredActionLabels.titleAll} ${requiredActionCountDisplay}`}</PanelLabel>
        {requiredActionsLoading ? (
          <DSLoadingPanel>
            {getDefaultLoadingMessage(requiredActionLabels.titlePlural)}
          </DSLoadingPanel>
        ) : (
          <RequiredActionsTable requiredActions={requiredActionList} />
        )}
      </ContentRowWrapper>
    </>
  );
}
