import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { RegistrationData } from 'src/types/supplierregistration';
import { scrollToTop } from 'src/utils/helpers';
import { RegistrationCommodityCodeNew } from './AICommodityCode';
import { LegacyCommodityCode } from './LegacyCommodityCodes';

export const CommoditiesAndTagsRegistration = () => {
    const registrationData = useRecoilValue<RegistrationData>(recoilRegistrationDataState);

    const [useLegacy, setUseLegacy] = useState(false);
    const hasFreeAgency = () => {
        return !!registrationData.freeAgency;
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        setUseLegacy(registrationData.commodityAddedFromLegacy ||false);   
    }, [registrationData]);

    const handleToggle = () => {
        setUseLegacy(prevState => {
            const newUseLegacy = !prevState;
            return newUseLegacy;
        });
    };

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='colWrapper'>
                            <div className='row'>
                                <div className='col col-8'>
                                    <h2 className='arrowWrapper mobileArrowWrapper'>2 of 4: Refine Bid Notifications</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-12 col-xl-7 col-md-8'>
                                    <h2>What are commodity codes?</h2>
                                    <p className='reg-intro no-top-padding'>
                                        DemandStar commodity codes are how we categorize goods and services for
                                        government procurement. When you choose commodity codes, DemandStar uses them to
                                        match what your company provides to what governments in your subscription need,
                                        helping you find the best bids and quotes to bid on.
                                    </p>
                                </div>
                                {hasFreeAgency() && (
                                    <div className='col-sm-12 col-xl-5 col-md-4 '>
                                        <p className='text-right justify-content-end'>
                                            {useLegacy ? `To use smart search for adding commodity codes and tags ` : 'To use legacy commodity search '}
                                            <a className='bannerCurser' onClick={handleToggle}>
                                                <u> Click here</u>
                                            </a>
                                        </p>
                                    </div>)}
                            </div>

                            {useLegacy ? <LegacyCommodityCode  /> : <RegistrationCommodityCodeNew />}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
