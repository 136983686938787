import React, { memo, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions';
import { MembershipLevel, Permission } from '../../../../types/auth';
import history from '../../../../utils/history';
import { useDSSelector } from 'src/store/reducers';
import { useAuthContext } from 'src/auth/AuthProvider';
import { AppContext } from 'src/global-context/AppContext';

interface propsTypes {
  setAddBidDetails: any;
  setBidSummaryDetails: any;
  resetQuoteSummaryDetails: any;
  resetCommodityCodes: any;
  resetAddQuotesDetails: any;
  /* trackAmplitudeUserActions: any; */
  accountinfo?: any; // TODO: TS4 - this does not seem to be in use
}

const DashboardControl = (props: propsTypes) => {
  const {
    setAddBidDetails,
    setBidSummaryDetails,
    resetQuoteSummaryDetails,
    resetCommodityCodes,
    resetAddQuotesDetails,
    /* trackAmplitudeUserActions, */
  } = props;

  const memberinfo = useDSSelector(state => state.memberinfo);
  const { auth } = useAuthContext();
  const { RefetchDashboardData } = useContext(AppContext);
  const mlcheck = auth?.membershipLevels.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];

  const { isExternalAgency = false } = memberinfo;

  const AddQuote = () => {
    resetQuoteSummaryDetails();
    setAddBidDetails({ ResetState: true, broadcastListId: '' });
    resetCommodityCodes();
    resetAddQuotesDetails();
    RefetchDashboardData(true);
    //trackAmplitudeUserActions({title:'Add quote - button', desc:'add quote button clicked from dashboard'});
    history.push('/buyers/quotes/creation');
  };

  return (
    <>
      <>
        {(auth?.mainContact ||
          (mlcheck.includes(MembershipLevel.BidPoster) &&
            prmscheck.includes(Permission.ManageBids))) &&
          !isExternalAgency && (
            <li title='Add Bid'>
              <span
                className='staticLink addBid'
                onClick={event => {
                  event.preventDefault();
                  setAddBidDetails({ ResetState: true, broadcastListId: '' });
                  setBidSummaryDetails({ results: {} });
                  RefetchDashboardData(true);
                  //trackAmplitudeUserActions({title:'Add bid - button', desc:'add bid button clicked from dashboard'});
                  history.push('/buyers/bid/creation');
                }}
              >
                <i className='mdi mdi-plus mr-1' /> Add Bid
              </span>
            </li>
          )}
        {(auth?.mainContact ||
          (mlcheck.includes(MembershipLevel.QuotePoster) &&
            prmscheck.includes(Permission.CreateQuotes))) &&
          !isExternalAgency && (
            <li title='Add Quote'>
              <span className='staticLink addBid' onClick={AddQuote}>
                <i className='mdi mdi-plus mr-1' /> Add Quote
              </span>
            </li>
          )}
      </>
    </>
  );
};

export default connect(
  (state: any) => ({}),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(DashboardControl));
