import { atom, selector } from 'recoil';

import dayjs from 'dayjs';

import { AwardeeContact, ContractSearchResult } from '../../contract-management.d';
import { compareObjectsByKey } from 'src/utils';
import { getAllContracts } from '../../ContractManagement.services';
import { removeDuplicates } from 'src/utils/helpers';
import { tryCatchLog } from 'src/utils/errors';

const allContractsSelector = selector<ContractSearchResult[]>({
  key: 'allContractsSelector',
  get: async () => {
    return await tryCatchLog(async () => {
      const contracts = await getAllContracts();
      return contracts.map(c => ({
        ...c,
        awardeeContacts: c.awardeeContacts ?? [],
        startDate: dayjs(c.startDate),
        endDate: dayjs(c.endDate),
      }));
    }, 'allContractsSelector => getAllContracts');
  },
});

export const allContractsState = atom<ContractSearchResult[]>({
  key: 'allContractsState',
  default: allContractsSelector,
});

export const allSuppliersQuery = selector<AwardeeContact[]>({
  key: 'allSuppliersQuery',
  get: ({ get }) => {
    const allContracts = get(allContractsState);
    const aggSuppliers = allContracts?.flatMap(({ awardeeContacts }) => awardeeContacts) ?? [];
    return removeDuplicates(aggSuppliers, 'name').sort(compareObjectsByKey('name'));
  },
});

export const allSuppliersState = atom<AwardeeContact[]>({
  key: 'allSuppliersState',
  default: allSuppliersQuery,
});
