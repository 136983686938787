// Helper function to convert hours to milliseconds
const hoursToMilliseconds = (hours: number): number => hours * 60 * 60 * 1000;
const minutesToMilliseconds = (minutes: number): number => minutes * 60 * 1000;

// Define all your stale times here using the helper function
export const STALE_TIMES = {
  ONE_MINUTE: minutesToMilliseconds(1), // 1 minute
  FIVE_MINUTES: minutesToMilliseconds(5), // 5 minutes
  FIFTEEN_MINUTES: minutesToMilliseconds(15), // 15 minutes
  THIRTY_MINUTES: minutesToMilliseconds(30), // 30 minutes
  ONE_HOUR: hoursToMilliseconds(1), // 1 hour
  ONE_DAY: hoursToMilliseconds(24),//1 day
};

//add other constants related to React Query here if needed
