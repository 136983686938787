import { FlexContainer, H4, Large } from '@demandstar/components/styles';
import { DSLinkButton } from '@demandstar/components/button';

import {
  cmButtonTexts,
  cmExplanatoryTexts,
  contractManagementLabels,
} from '../../ContractManagement.texts';

import { AddContractsSection } from './AddContracts.styles';
import { paths } from '../../ContractManagement.paths';

export const AddContracts = () => {
  return (
    <AddContractsSection>
      <H4>{contractManagementLabels.addContracts}</H4>
      <p>
        <Large>{cmExplanatoryTexts.AddContracts}</Large>
      </p>
      <FlexContainer justifyContent='center'>
        <DSLinkButton to={paths.navigation.createContract}>
          {cmButtonTexts.addExistingContract}
        </DSLinkButton>
        <DSLinkButton to={paths.navigation.addContractInterstitial(true)}>
          {cmButtonTexts.awardNewContract}
        </DSLinkButton>
      </FlexContainer>
    </AddContractsSection>
  );
};
