import { MembershipLevel, Permission } from '../../../../types/auth';
import { anyParamType } from '../../../../types/paymentdetails';
import { useDSSelector } from '../../../../store/reducers';
import history from '../../../../utils/history';
import { useAuthContext } from 'src/auth/AuthProvider';
import { useContext } from 'react';
import { AppContext } from 'src/global-context/AppContext';

interface PropsTypes {
  setAddBidDetails: anyParamType;
  setBidSummaryDetails: anyParamType;
  /* trackAmplitudeUserActions?: anyParamType; */
}

function Homecontrol(props: PropsTypes) {
  const { setAddBidDetails, setBidSummaryDetails } = props;
  const { auth } = useAuthContext();
  const mlcheck = auth?.membershipLevels.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];
  const memberInfoState = useDSSelector(state => state.memberinfo);
  const { isExternalAgency = false } = memberInfoState;
  const { RefetchDashboardData } = useContext(AppContext);

  return (
    <>
      {auth?.mainContact ||
      (mlcheck.includes(MembershipLevel.BidPoster) &&
        prmscheck.includes(Permission.ManageBids) &&
        !isExternalAgency) ? (
        <li title='Add Bid' data-tut='reactour__agency_1'>
          <span
            className='staticLink addBid'
            onClick={event => {
              event.preventDefault();
              setAddBidDetails({ ResetState: true, broadcastListId: '' });
              setBidSummaryDetails({ results: {} });
              RefetchDashboardData(true);
              //trackAmplitudeUserActions({title:'Add bid - button', desc:'add bid button clicked from bid search'});
              history.push('/buyers/bid/creation');
            }}
          >
            <i className='mdi mdi-plus mr-1' /> Add Bid
          </span>
        </li>
      ) : null}
    </>
  );
}

export default Homecontrol;
