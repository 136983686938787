import { useMemo } from 'react';

import * as texts from './AddContract.texts';

import { DSLink } from '@demandstar/components/link';
import { DSRecordItem } from '@demandstar/components/record-item';

import { BidSearchResult } from './useAwardedBidSearch';
import { displayDate } from 'src/utils/helpers';
import { paths } from '../../ContractManagement.paths';

export const BidResult = ({
  bidId,
  bidIdentifier,
  bidName,
  broadCastDate,
  dueDate,
}: BidSearchResult) => {
  const title = useMemo(
    () => <DSLink to={paths.navigation.addContractFromBid(bidId)}>{bidName}</DSLink>,
    [bidId, bidName],
  );

  const bidData = [
    { title: texts.recordLabels.id, value: bidIdentifier },
    { title: texts.recordLabels.brodcast, value: displayDate(broadCastDate) },
    { title: texts.recordLabels.due, value: displayDate(dueDate) },
  ];

  return <DSRecordItem data={bidData} title={title} />;
};
