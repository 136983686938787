import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

import { useCallback, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { axiosRequest } from 'src/store/services/api';
import { Paths } from 'src/utils/constants/paths';
import { track } from 'src/utils/telemetry';
import { updateCommodityCodes ,UpdateMemberTagsandCommodities} from 'src/store/services/registration';

export interface CommodityState {
  bold: string;
  commodityCategory: string;
  commodityCode: string;
  commodityDescription: string;
  commodityGroup: string;
  commodityId: number;
  contains: number;
  fullCode: string;
  selected: string;
}

export const commodityState = atom<CommodityState[]>({
  key: 'commodityCodeState',
  default: [],
});

export function getCommodities(): Promise<CommodityState[]> {
  const requestConfig: AxiosRequestConfig = {
    url: Paths.getAllCommodityCodes,
    data: {},
  };

  return axiosRequest<CommodityState[]>(requestConfig);
}

export function useCommodities() {
  const commodities = useRecoilValue(commodityState);

  const refreshCommodities = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const commodities = await getCommodities();
          set(commodityState, commodities);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          track('refreshcommodities -> getCommodities() ERROR:', {
            error,
            method: 'GET',
            errorMessage: error.message,
          });
          // eslint-disable-next-line no-console
          console.log(`getCommodities() ERROR: \n${error}`);
          throw new Error(error.message);
        }
      },
    [],
  );

  const updateCommodities = useCallback(
    async (args: { memberId?: number; commodityCodeIds?: string[] }) => {
      const { memberId, commodityCodeIds } = args;
      try {
        await updateCommodityCodes({
          MemberId: memberId,
          CommodityCodeIds: commodityCodeIds?.join(',')||'',
        });
        // eslint-disable-next-line no-console
      } catch (error: any) {
        track('updateCommodities -> updateCommodityCodes() ERROR:', {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        // eslint-disable-next-line no-console
        console.log(`getCommodities() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    },
    [],
  );

  const addAITagsAndCommoditycodes = useCallback(
    async (args: { memberId?: number;  tags?: string[], commodityCodeIds?: string[] }) => {
      const { memberId, tags,commodityCodeIds } = args;
      try {
        await UpdateMemberTagsandCommodities({
          MemberId: memberId,
          tags:tags?.join(',')||'',
          CommodityCodeIds: commodityCodeIds?.join(',')||'',
        });
        // eslint-disable-next-line no-console
      } catch (error: any) {
        track('UpdateMemberTagsandCommodities -> UpdateMemberTagsandCommodities() ERROR:', {
          error,
          method: 'POST',
          errorMessage: error.message,
        });
        // eslint-disable-next-line no-console
        console.log(`UpdateMemberTagsandCommodities() ERROR: \n${error}`);
        throw new Error(error.message);
      }
    },
    [],
  );

  return {
    commodities,
    refreshCommodities,
    updateCommodities,
    addAITagsAndCommoditycodes,
  };
}

export function useRefreshCommodities(): void {
  const { refreshCommodities } = useCommodities();

  useEffect(() => {
    refreshCommodities();
  }, [refreshCommodities]);
}
