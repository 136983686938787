import React from 'react';
import { useQuery } from '@tanstack/react-query'; // Updated import
import { getOtherAccountInfo } from 'src/store/services';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';
import { toastFn } from 'src/utils/helpers';

const GetAccountInfoCommodity = () => {
  return getOtherAccountInfo();
};

export const useGetAccountInfoCommodity = () => {
  return useQuery({
    queryKey: ['AccountInfoCommodity'],
    queryFn: () => GetAccountInfoCommodity(),
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
