import * as addDocTexts from '../../documents/add-document/AddDocument.texts';
import { commonLabels } from 'src/shared/constants';
import { wizardManageDocuments } from '../ContractWizard.texts';

export const addAnotherDocument = 'Add Another Document';
export const cancel = commonLabels.cancel;
export const manageDocuments = wizardManageDocuments;
export const saveAndAdd = addDocTexts.saveAndAdd;
export const saveDoc = addDocTexts.saveDoc;
export const uploading = addDocTexts.uploading;
