import { DSSelectProps, SelectOption } from './DSSelect.d';
import { MultiValue, SingleValue } from 'react-select';
import { Assert } from '../../utils';
import { DSSelectFieldProps } from '../../fields/select-field/DSSelectField.d';

export function getReactSelectProps<I extends SelectOption, O>(
  props: DSSelectProps<I, O> | DSSelectFieldProps<I, O>,
) {
  return (({
    closeMenuOnSelect,
    isClearable,
    isDisabled,
    isMulti,
    isSearchable,
    placeholder,
    menuIsOpen,
  }) => ({
    closeMenuOnSelect,
    isClearable,
    isDisabled,
    menuIsOpen,
    isMulti,
    isSearchable,
    placeholder,
  }))(props);
}

export function getLabel(option: SelectOption, labelField?: string) {
  if (labelField) {
    return option[labelField as keyof typeof option] as unknown as string;
  }
  return String(option);
}

export function getValue(option: SelectOption, valueField?: string | false) {
  if (valueField) {
    return option[valueField as keyof typeof option];
  }
  return option;
}

export function getKey(option: SelectOption, keyField?: string) {
  if (keyField) {
    return option[keyField as keyof typeof option];
  }
}

export function isMultiValue<T>(
  selectable: MultiValue<T> | SingleValue<T>,
): selectable is MultiValue<T> {
  return Array.isArray(selectable);
}

export function getSelectedOptions<O>(
  formattedOptions: {
    label: string;
    value: O;
  }[],
  isMulti?: boolean,
  keyField?: string,
  value?: O | O[],
) {
  if (isMulti) {
    Assert(Array.isArray(value), 'If isMulti, then we must have an array as value');
    return value.map(val => {
      return formattedOptions.find(option => {
        if (keyField) {
          return (
            option.value[keyField as keyof typeof option.value] ===
            val[keyField as keyof typeof val]
          );
        }
        return option.value === val;
      });
    });
  } else {
    return (
      formattedOptions.find(option => {
        if (value === undefined) return null;
        if (keyField) {
          return (
            value &&
            option.value[keyField as keyof typeof option.value] ===
              value[keyField as keyof typeof value]
          );
        }
        return option.value === value;
      }) || null
    );
  }
}

export const DSSelectClassPrefix = 'ds-select';
