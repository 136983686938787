/* eslint-disable max-lines */
import dayjs from 'dayjs';
import { call, fork, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { Authorization } from 'src/types';
import {
  changedueTypes,
  changestatusTypes,
  getlineitemsTypes,
  linewireTypes,
  quotereplyTypes,
  quotespayloadTypes,
} from '../../types/quotes';
import { quoteBreakdownStatus, userEvent } from '../../utils/constants';
import {
  deprecatedConvertotherTimezonetoUTC,
  deprecatedGetDateTimeZoneConverted,
  formatAsUTC,
  getTimeFromDate,
  getZoneFromDate,
  toastFn,
} from '../../utils/helpers';
import history from '../../utils/history';
import * as actionTypes from '../actionTypes';
import * as addquoteApi from '../services/addquotes';
import * as bidApi from '../services/bids';
import * as quoteApi from '../services/quotes';

const quotesummarytoastID = '5e67ed59-3566-46a5-b74b-f267cb402e16';

function* watchGetQuoteSummary() {
  yield takeLeading(actionTypes.GET_QUOTE_SUMMARY.TRIGGER, getQuoteSummary);
}

function* getQuoteSummary(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_QUOTE_SUMMARY.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuoteSummary, action.payload);
    const convertedDueDateTime = deprecatedGetDateTimeZoneConverted(
      response.data.result.dueDateTime,
      response.data.result.tzfn,
    );
    const summary = {
      ...response.data.result,
      dueDate: new Date(convertedDueDateTime),
      dueTime: getTimeFromDate(convertedDueDateTime),
      dueTimeZone: getZoneFromDate(convertedDueDateTime),
      commodities: [],
    };

    const { quoteId } = action.payload as { quoteId: number };

    const payload = { summary };
    yield put({ type: actionTypes.GET_QUOTE_SUMMARY.SUCCESS, payload });
    yield put({
      type: actionTypes.GET_QUOTE_COMMODITYCODES.TRIGGER,
      payload: { quoteId, type: 'quote' },
    });
  } catch (error) {
    const payload = { summary: '' };
    yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload });
    yield put({
      type: actionTypes.GET_QUOTE_SUMMARY.FAILURE,
      payload: { payload: action.payload, error },
    });
    history.replace('/');
  }
}

function* watchQuoteSummaryGetLineItems() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.TRIGGER,
    QuoteSummaryGetLineItems,
  );
}

function* QuoteSummaryGetLineItems(action: getlineitemsTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.REQUEST, meta: action.payload });
  try {
    const { pagefor = 'quotesummary', quoteId, pageType = '' } = action.payload;
    const response = yield call(quoteApi.getQuoteLineItems, { quoteId });
    const payload = response.data.result;

    if (pagefor === 'quotesummary') {
      const item = payload.find((item: any, index: number) => index === 0);
      const { quoteItemId, quoteId } = item;
      if (pageType !== 'supplier') {
        yield put({
          type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
          payload: { quoteItemId: quoteItemId, quoteId: quoteId },
        });
      }
    }

    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
      payload: { lineItems: [] },
    });
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryGetQuoteResponses() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES.TRIGGER,
    QuoteSummaryGetQuoteResponses,
  );
}

function* QuoteSummaryGetQuoteResponses(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuoteResponses, action.payload);
    let data = response.data.result || [];
    data = data.map((item: any) => {
      return {
        ...item,
        supplierName: item.supplierName ? item.supplierName : '',
      };
    });
    data =
      data &&
      data.length > 0 &&
      data.sort((a: { supplierName: string }, b: { supplierName: string }) =>
        a.supplierName.localeCompare(b.supplierName),
      );
    yield put({
      type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
      payload: { suppliers: data, currentSupplier: '', response_internalloader: false },
    });
    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryGetSupplierInfo() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO.TRIGGER,
    QuoteSummaryGetSupplierInfo,
  );
}

function* QuoteSummaryGetSupplierInfo(action: { payload: { responseId: number } }): any {
  yield put({
    type: actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO.REQUEST,
    meta: action.payload,
  });
  try {
    const response = yield call(quoteApi.getQuoteSupplierInfo, {
      responseId: action.payload.responseId,
    });
    const payload = { currentSupplier: { ...action.payload, ...response.data.result } };
    yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO.SUCCESS });
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN.TRIGGER,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoueSummaryGetSupplierBreakdown() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN.TRIGGER,
    QuoueSummaryGetSupplierBreakdown,
  );
}

function* QuoueSummaryGetSupplierBreakdown(action: {
  payload: { responseType: string; responseId: number };
}): any {
  yield put({
    type: actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN.REQUEST,
    meta: action.payload,
  });
  try {
    let breakdowns = [];
    const quoteSummary = yield select(state => state.quoteSummary);
    const { currentSupplier } = quoteSummary;
    if (quoteBreakdownStatus.includes(action.payload.responseType)) {
      const response = yield call(quoteApi.getSupplierBreakdown, {
        responseId: action.payload.responseId,
      });
      breakdowns = (response && response.data.result) || [];
    }
    const payload = {
      currentSupplier: { ...currentSupplier, breakDownlist: breakdowns },
      lineitems_supplier_internalLoader: false,
    };
    yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload });
    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryGetStatusOptions() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS.TRIGGER,
    QuoteSummaryGetStatusOptions,
  );
}

function* QuoteSummaryGetStatusOptions(action: quotespayloadTypes): any {
  yield put({
    type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS.REQUEST,
    meta: action.payload,
  });
  try {
    const response = yield call(quoteApi.getStatusOptions, action.payload);
    const options =
      response.data.result.map((item: { statusType: string; statusName: string }) => ({
        value: item.statusType,
        label: item.statusName,
      })) || [];
    const payload = { quoteStatus: options };
    yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload });

    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryChangeStatus() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_STATUS.TRIGGER, QuoteSummaryChangeStatus);
}

function* QuoteSummaryChangeStatus(action: changestatusTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_STATUS.REQUEST, meta: action.payload });
  try {
    const fields = { ...action.payload, quoteStatus: action.payload.changeStatus.value };
    const response = yield call(quoteApi.changeQuoteStatus, fields);

    if (response && response.data.result.status) {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { summary } = quoteSummary;
      const newSummary = {
        ...summary,
        status: action.payload.changeStatus.label,
        statusType: action.payload.changeStatus.value,
        statusUpdateNotes: action.payload.notes,
      };
      const payload = { statusModal: false, summary: newSummary };
      yield put({ type: actionTypes.SET_QUOTES_DETAILS.TRIGGER, payload: { quotesreload: true } });
      yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload: payload });
      toastFn('success', 'Updated', quotesummarytoastID);
    } else {
      toastFn('error', 'Error', quotesummarytoastID);
    }

    yield put({ type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_STATUS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteSummaryChangeDuedate() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE.TRIGGER,
    QuoteSummaryChangeDuedate,
  );
}

function* QuoteSummaryChangeDuedate(action: changedueTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.changeQuoteDueDate, {
      dueDateTime: action.payload.dueDateTime,
      quoteId: action.payload.quoteId,
    });
    if (response && response.data.result.status) {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { summary } = quoteSummary;
      const dueDateTime = deprecatedConvertotherTimezonetoUTC(
        action.payload.dueDateTime,
        summary.tzfn,
      );

      const convertedDueDateTime = dayjs(dueDateTime).format('YYYY-MM-DDTHH:mm:ss');
      const newSummary = {
        ...summary,
        dueDateTime: convertedDueDateTime,
        dueDate: action.payload.duedates,
        dueTime: action.payload.duetimes,
        dueTimeZone: action.payload.duetimezones,
      };
      const payload = { statusModal: false, summary: newSummary };

      yield put({ type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER, payload: payload });
      yield put({ type: actionTypes.SET_QUOTES_DETAILS.TRIGGER, payload: { quotesreload: true } });
      toastFn('success', 'Updated', quotesummarytoastID);
    } else {
      toastFn('error', 'Error', quotesummarytoastID);
    }

    yield put({ type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteDocuments() {
  yield takeLatest(actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS.TRIGGER, quoteDocumentsAsync);
}

function* quoteDocumentsAsync(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS.REQUEST, meta: action.payload });
  try {
    const response = yield call(addquoteApi.getAddQuotesDocuments, action.payload);
    yield put({
      type: actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS.SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteResponseStatus() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_GET_RESPONSE_STATUS.TRIGGER, QuoteResponseStatus);
}

function* QuoteResponseStatus(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_GET_RESPONSE_STATUS.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuoteResponsesStatus, action.payload);
    const responseList = response.data.result.map(
      (item: { statusName: string; statusType: string }) => ({
        label: item.statusName,
        value: item.statusType,
      }),
    ) || [{ label: 'All', value: '' }];
    const payload = { responsesStatus: responseList };
    yield put({
      type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
      payload,
    });
    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_RESPONSE_STATUS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_RESPONSE_STATUS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchQuoteStatistics() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_GET_STATISTICS.TRIGGER, QuoteStatistics);
}

function* QuoteStatistics(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_GET_STATISTICS.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuoteStatistics, action.payload);
    const payload = { statistics: response.data.result };
    yield put({
      type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
      payload,
    });
    yield put({ type: actionTypes.QUOTE_SUMMARY_GET_STATISTICS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_GET_STATISTICS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetQuotelineItemSuppliers() {
  yield takeLatest(
    actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
    getQuotelineItemSuppliers,
  );
}

function* getQuotelineItemSuppliers(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuotelineItemSuppliers, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchSendRewardMail() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL.TRIGGER, SendRewardMail);
}

function* SendRewardMail(action: { payload: { currentPage: string } }): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.awardQuote, action.payload);
    if (response.data.result.status) {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { currentSupplier, currentLineItem } = quoteSummary;
      yield put({
        type: actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO.TRIGGER,
        payload: { ...currentSupplier },
      });
      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: { awardModal: false },
      });

      if (currentLineItem && currentLineItem.quoteId) {
        yield put({
          type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
          payload: {
            quoteItemId: currentLineItem.quoteItemId,
            quoteId: currentLineItem.quoteId,
          },
        });
      }

      if (action.payload.currentPage === 'lineitems') {
        /* yield put({
					type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
					payload: {
						quoteItemId: currentLineItem.quoteItemId,
						quoteId: currentLineItem.quoteId
					}
				}) */

        yield put({
          type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
          payload: { selectedResponseItem: '' },
        });
      }

      yield put({ type: actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL.SUCCESS });
      toastFn('success', 'Awarded', quotesummarytoastID);
    } else {
      yield put({ type: actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL.FAILURE });
      toastFn('error', 'Failed', quotesummarytoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', quotesummarytoastID);
  }
}

function* watchnotSubmitQuote() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.TRIGGER, notSubmitQuote);
}

function* notSubmitQuote(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.notSubmitQuote, action.payload);
    const payload = response.data.result.status || '';
    if (payload) {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { summary } = quoteSummary;
      const NewSummary = { ...summary, responseStatus: 'NQ' };
      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: { summary: NewSummary },
      });
      yield put({ type: actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.SUCCESS });
      toastFn('success', 'Your quote has been Declined', quotesummarytoastID);
    } else {
      toastFn('error', 'Quote has been expired', quotesummarytoastID);
      yield put({ type: actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', quotesummarytoastID);
    yield put({
      type: actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchSubmitQuoteReply() {
  yield takeLatest(actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.TRIGGER, submitQuoteReply);
}

function* submitQuoteReply(action: quotereplyTypes): any {
  yield put({ type: actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.REQUEST, meta: action.payload });
  try {
    const { finishlater = false, quoteId, responseId, data: values = {} } = action.payload;
    const memberinfo = yield select(state => state.memberinfo);
    const { linewires, delivery, additionalSupplierInfo, preparedBy } = values;
    const { sti, ci, a1, a2, ct, pc, p, jt } = memberinfo;

    const finallineItems = linewires.map((item: linewireTypes) => ({
      quantity: item.quantity,
      uom: item.uom,
      unitPrice: item.unitPrice
        ? parseFloat(
            item.unitPrice.toString().replace(/ /g, '').replace('$', '').replace(/,/g, ''),
          ).toFixed(4)
        : item.unitPrice,
      quoteItemId: item.quoteItemId,
      alternateNotes: item.alternateNotes || '',
    }));

    const finalvalue = {
      quoteId,
      responseId,
      delivery,
      additionalSupplierInfo,
      preparedBy,
      lineItems: finallineItems,
      stateId: sti,
      countryId: ci,
      address1: a1,
      address2: a2,
      city: ct,
      postalCode: pc,
      jobTitle: jt,
      phone: p,
    };

    let response: any = '';
    let message = 'Your quote has been submitted';
    if (finishlater) {
      response = yield call(quoteApi.submitQuoteLater, finalvalue);
      message = 'Your quote has been saved';
    } else {
      response = yield call(quoteApi.submitQuoteReply, finalvalue);
    }
    const payload = response.data.result.status;
    if (payload) {
      yield put({ type: actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.SUCCESS });
      toastFn('success', message, quotesummarytoastID);
      history.goBack();
      const addquotes = yield select(state => state.addquotes);
      const { summary } = addquotes || '';
      if (quoteId && summary && summary.responseStatus === 'RS') {
        yield put({
          type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER,
          payload: { eventId: userEvent.UpdateQuoteResponse, parentId: quoteId, value: finalvalue },
        });
      }
    } else {
      toastFn('error', 'Quote has been expired', quotesummarytoastID);
      yield put({ type: actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.FAILURE });
    }
  } catch (error) {
    toastFn('error', 'Failed', quotesummarytoastID);
    yield put({
      type: actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetQuoteView() {
  yield takeLatest(actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW.TRIGGER, getQuoteView);
}

function* getQuoteView(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.getQuoteView, action.payload);
    const payload = response.data.result || [];
    yield put({ type: actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetQuotesReply() {
  yield takeLatest(actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY.TRIGGER, getQuotesReply);
}

function* getQuotesReply(action: { payload: { auth: Authorization; quoteId: number } }): any {
  yield put({ type: actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY.REQUEST, meta: action.payload });
  try {
    const { quoteId } = action.payload;
    const quoteSummary = yield select(state => state.quoteSummary);
    let lineitemsvalue = quoteSummary.lineItems;
    const response = yield call(quoteApi.getQuoteSummary, { quoteId });
    const convertedDueDateTime = deprecatedGetDateTimeZoneConverted(
      response.data.result.dueDateTime,
      response.data.result.tzfn,
    );
    const summary = {
      ...response.data.result,
      dueDate: new Date(convertedDueDateTime),
      dueTime: getTimeFromDate(convertedDueDateTime),
      dueTimeZone: getZoneFromDate(convertedDueDateTime),
    };
    const lineresponse = yield call(quoteApi.getQuoteLineItems, { quoteId });
    const lineitemslist = lineresponse.data.result || [];
    lineitemsvalue = lineitemslist;
    const lineItems = lineitemsvalue;
    const lineItemone = lineItems.find((item: any, index: number) => index === 0);
    let preparedBy = lineItemone.preparer || '';
    if (preparedBy === '') {
      const auth = action.payload.auth;
      preparedBy = auth.firstName + ' ' + auth.lastName;
    }

    const payload = {
      quotereplyinfo: {
        delivery: lineItemone.deliveryARO || '',
        additionalSupplierInfo: lineItemone.paymentTerms || '',
        preparedBy,
        linewires:
          lineItems.map((item: { unitPrice: string | number; altDescription: string }) => ({
            ...item,
            unitPrice: item.unitPrice || '',
            alternateNotes: item.altDescription,
          })) || [],
      },
      summary,
      lineItems,
    };
    yield put({ type: actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetQuoteCommodityCodes() {
  yield takeLatest(actionTypes.GET_QUOTE_COMMODITYCODES.TRIGGER, getQuoteCommodityCodes);
}

function* getQuoteCommodityCodes(action: quotespayloadTypes): any {
  yield put({ type: actionTypes.GET_QUOTE_COMMODITYCODES.REQUEST, meta: action.payload });

  try {
    const quoteSummary = yield select(state => state.quoteSummary || '');
    const { summary = {} } = quoteSummary;
    const response = yield call(bidApi.getBidCommodityByType, action.payload);
    const summaryData = { ...summary, commodities: response.data.result };

    yield put({
      type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
      payload: { summary: summaryData },
    });
    yield put({ type: actionTypes.GET_QUOTE_COMMODITYCODES.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.GET_QUOTE_COMMODITYCODES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchDeleteLineItem() {
  yield takeLatest(actionTypes.DELETE_LINE_ITEM.TRIGGER, deleteLineitem);
}

function* deleteLineitem(action: { payload: { quoteItemId: number } }): any {
  yield put({ type: actionTypes.DELETE_LINE_ITEM.REQUEST, meta: action.payload });
  try {
    const response = yield call(quoteApi.deleteLineItem, action.payload);

    if (response.data.result.status === true) {
      const quoteSummary = yield select(state => state.quoteSummary);
      const { lineItems = [] } = quoteSummary || {};
      const filteredLineItems = lineItems.filter(
        (item: { quoteItemId: number }) => item.quoteItemId !== action.payload.quoteItemId,
      );

      yield put({
        type: actionTypes.SET_QUOTE_SUMMARY_DETAILS.TRIGGER,
        payload: {
          lineItems: filteredLineItems,
          currentSupplier: '',
          currentLineItem: filteredLineItems[0],
          currentPage: 1,
        },
      });
      yield put({
        type: actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER.TRIGGER,
        payload: {
          quoteItemId: filteredLineItems[0].quoteItemId,
          quoteId: filteredLineItems[0].quoteId,
        },
      });
      toastFn('success', 'Deleted', action.payload.quoteItemId);
      yield put({ type: actionTypes.DELETE_LINE_ITEM.SUCCESS });
    } else {
      toastFn('error', 'Failed', action.payload.quoteItemId);
      yield put({ type: actionTypes.DELETE_LINE_ITEM.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_LINE_ITEM.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchExportQuoteLineItems() {
  yield takeLatest(actionTypes.EXPORT_LINEITEM_DETAILS.TRIGGER, exportQuoteLineItems);
}

function* exportQuoteLineItems(action: { payload: { quoteId: number } }) {
  yield put({ type: actionTypes.EXPORT_LINEITEM_DETAILS.REQUEST, meta: action.payload });
  try {
    yield call(quoteApi.exportQuoteLineItems, action.payload);
    yield put({ type: actionTypes.EXPORT_LINEITEM_DETAILS.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_LINEITEM_DETAILS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* quoteSummarySaga() {
  yield fork(watchGetQuoteSummary);
  yield fork(watchQuoteSummaryGetLineItems);
  yield fork(watchQuoteSummaryGetStatusOptions);
  yield fork(watchQuoteSummaryChangeStatus);
  yield fork(watchQuoteSummaryChangeDuedate);
  yield fork(watchQuoteDocuments);
  yield fork(watchQuoteSummaryGetQuoteResponses);
  yield fork(watchQuoteSummaryGetSupplierInfo);
  yield fork(watchQuoteResponseStatus);
  yield fork(watchQuoteStatistics);
  yield fork(watchgetQuotelineItemSuppliers);
  yield fork(watchSendRewardMail);
  yield fork(watchnotSubmitQuote);
  yield fork(watchSubmitQuoteReply);
  yield fork(watchgetQuoteView);
  yield fork(watchgetQuotesReply);
  yield fork(watchGetQuoteCommodityCodes);
  yield fork(watchQuoueSummaryGetSupplierBreakdown);
  yield fork(watchDeleteLineItem);
  yield fork(watchExportQuoteLineItems);
}
