import styled from 'styled-components';

import {
  BackgroundColor,
  BorderColor,
  BorderThickness,
  FontFamily,
  FontWeight,
  HeaderFontSize,
  Padding,
  TextColor,
} from '../styles';

import { DSTabNavigationLabelProps } from './DSTabNavigationLabel';
import { Selected } from '../types';

export const TabNavigationContainer = styled.div<Selected>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TabRow = styled.div<Selected>`
  background-color: ${BackgroundColor.BaseWhite};
  border-bottom: ${BorderThickness.Base} solid ${BorderColor.SubNavigation};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

/**
 * Base styled implementation of a tab label.
 * this can be replaced with any component which uses DSTabNavigationLabelProps
 */
export const BaseTabNavigationLabel = styled.div<DSTabNavigationLabelProps>`
  background-color: ${({ selected }: Selected) =>
    selected ? BackgroundColor.SubNavigationTabSelected : BackgroundColor.SubNavigationTab};
  color: ${({ selected }: Selected) => (selected ? TextColor.Contrast : TextColor.Action)};
  cursor: pointer;
  font-family: ${FontFamily.Header};
  font-size: ${HeaderFontSize.H5};
  font-weight: ${FontWeight.Bold};
  padding: 0.75rem;
`;

export const TabContentContainer = styled.div<Selected>`
  background-color: ${BackgroundColor.BaseWhite};
  padding: ${Padding.Base};
`;
