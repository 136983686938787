// import * as crypto from 'crypto';
const crypto = require('crypto-browserify');

function encryptPassword(username: string, password: string) {
  try {
    return crypto
      .createHash('md5')
      .update(username?.toUpperCase() + password)
      .digest('hex');
  } catch (error) {
    return error;
  }
}
export default encryptPassword;
