import { navigationPaths } from '../../shared/constants/navigation';
import { Route } from '../../types/routes';

// Routes of View Components
import { CurrentSubscriptions } from '../../components/products/current-subscriptions';
import { ReviewUpgrade } from '../../components/products/review-order/ReviewUpgrade';
import { SupplierDashboard } from '../../components/supplier/dashboard';
import { SupplierResponses } from '../../features/suppliers/responses';
import { UpgradeSubscription } from '../../components/products/upgrade-subscription';

import AccountInfoTabContent from '../../components/shared/accountinfo/tabcontent';
import Activities from '../../components/shared/activities';
import AddEditUser from '../../components/shared/accountinfo/tabcontent/useraccount/userform';
import Bids from '../../components/shared/bids';
import BidsDetail from '../../components/supplier/biddetails';
import BidsDownload from '../../components/shared/bidsdownload';
import CommodityCodes from '../../components/shared/accountinfo/commoditycodes/commoditycodeedit';
import Commodityedit from '../../components/shared/accountinfo/commoditycodes';
import EBids from '../../components/supplier/ebids';
import Message from '../../components/shared/message';
import OrderView from '../../components/supplier/order/index';
import Payments from '../../components/shared/accountinfo/payments';
import PaymentThanks from '../../components/supplier/revieworder/paymentthanks';
import Plans from '../../components/supplier/Plans/Plans';
import Quotedetail from '../../components/supplier/quotedetail';
import Quotes from '../../components/shared/quotes';
import Quotesreply from '../../components/supplier/quotesreply';
import ReviewOrder from '../../components/supplier/revieworder';
import SupplierRestrict from '../../components/common/supplierRestrict';
import UserManual from '../../components/shared/usermanual';
import UserManualPages from '../../components/shared/usermanual/pages';
import WelcomeMail from '../../components/common/welcomesplash/index';

export const supplierRoutes: Route[] = [
  {
    path: '/suppliers/dashboard',
    component: SupplierDashboard,
    page: 'Dashboard',
    breadcrumb: { Home: '/' },
  },
  {
    path: `${navigationPaths.suppliers.bids}`,
    component: Bids,
    page: 'Bids Search',
    breadcrumb: { Home: '/' },
  },
  {
    path: `${navigationPaths.suppliers.quotes}`,
    component: Quotes,
    page: 'Quotes Search',
    breadcrumb: { Home: '/' },
  },
  {
    path: `${navigationPaths.suppliers.quotes}/unauthorized/:quoteId`,
    component: SupplierRestrict,
    page: 'QuoteRestrict',
    breadcrumb: { Home: '/' },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId`,
    component: BidsDetail,
    page: 'Supplier Bids Details',
    breadcrumb: { Home: '/', Bids: `${navigationPaths.suppliers.bids}` },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/response`,
    component: EBids,
    page: 'Ebids Response',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.suppliers.bids}`,
      'Bids Details': `${navigationPaths.suppliers.bids}/:bidId/details`,
    },
  },
  {
    path: `${navigationPaths.suppliers.quotes}/:quoteId/response`,
    component: Quotesreply,
    page: 'Supplier Quote Response',
    breadcrumb: {
      Home: '/',
      Quotes: `${navigationPaths.suppliers.quotes}`,
      'Quote Details': `${navigationPaths.suppliers.quotes}/:bidId`,
    },
  },
  {
    path: `${navigationPaths.suppliers.quotes}/:quoteId`,
    component: Quotedetail,
    page: 'Supplier Quote Details',
    breadcrumb: { Home: '/', Quotes: `${navigationPaths.suppliers.quotes}` },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/download`,
    component: BidsDownload,
    page: 'Supplier Bids Download',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.suppliers.bids}`,
      'Bids Details': `${navigationPaths.suppliers.bids}/:bidId`,
    },
  },
  {
    path: '/suppliers/plans',
    component: Plans,
    page: 'Supplier Plans',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.suppliers.bids}`,
      'Bids Details': `${navigationPaths.suppliers.bids}/:bidId`,
    },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/order`,
    component: ReviewOrder,
    page: 'Supplier Bids Payment',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.suppliers.bids}`,
      'Bids Details': `${navigationPaths.suppliers.bids}/:bidId`,
    },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/paymentthanks`,
    component: PaymentThanks,
    page: 'Supplier Payment Thanks',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.suppliers.bids}`,
      'Bids Details': `${navigationPaths.suppliers.bids}/:bidId`,
    },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/:Tab`,
    component: BidsDetail,
    page: 'Buyer Bids Details',
    breadcrumb: { Home: '/', Bids: `${navigationPaths.suppliers.bids}` },
  },
  {
    path: `${navigationPaths.suppliers.bids}/:bidId/:Tab/:itemId`,
    component: BidsDetail,
    page: 'Buyer Bids Details',
    breadcrumb: { Home: '/', Bids: `${navigationPaths.suppliers.bids}` },
  },
  {
    path: '/suppliers/account/commoditycodes',
    component: Commodityedit,
    page: 'Supplier Account Commodity Codes',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/account/commoditycodes/edit',
    component: CommodityCodes,
    page: 'Supplier Account Commodity Codes Edit',
    breadcrumb: { Home: '/', 'Commodity Codes': '/suppliers/account/commoditycodes' },
  },
  {
    path: '/suppliers/account/subscriptions',
    component: CurrentSubscriptions,
    page: 'Subscriptions',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/account/payments',
    component: Payments,
    page: 'Payments',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/accountinfo/useraccounts/userform',
    component: AddEditUser,
    page: 'Account Add edit user',
    breadcrumb: { Home: '/', 'Account Info': '/suppliers/accountinfo/useraccount' },
  },
  {
    path: '/suppliers/accountinfo/:Tab',
    component: AccountInfoTabContent,
    page: 'Account Info',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/help',
    component: UserManual,
    page: 'usermanual',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/help/:pagesid',
    component: UserManualPages,
    page: 'usermanualpages',
    breadcrumb: { Home: '/', Help: '/suppliers/help' },
  },
  {
    path: '/suppliers/activities/:Tab',
    component: Activities,
    page: 'Activities',
    breadcrumb: { Home: '/', Activities: '/suppliers/activities/notifications' },
  },
  { path: '/suppliers/messages', component: Message, page: 'Message', breadcrumb: { Home: '/' } },
  {
    path: `${navigationPaths.suppliers.responses}/:Tab/`,
    component: SupplierResponses,
    page: 'Responses',
    breadcrumb: {
      Home: '/',
      Responses: `${navigationPaths.suppliers.responses}/${navigationPaths.subPaths.RequiredActions}`,
    },
  },
  {
    path: '/suppliers/order',
    component: OrderView,
    page: 'Order View',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/suppliers/welcome',
    component: WelcomeMail,
    page: 'users',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/subscription/renewal',
    component: UpgradeSubscription,
    page: 'Upgrade and Renew',
    breadcrumb: { Home: '', Subscriptions: '/suppliers/account/subscriptions' },
  },
  {
    path: '/subscription/renewal/checkout',
    component: ReviewUpgrade,
    page: 'Checkout',
    breadcrumb: {
      Home: '',
      Subscriptions: '/suppliers/account/subscriptions',
      'Upgrade and Renew': '/subscription/renewal',
    },
  },
];
