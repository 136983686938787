import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { useAuthContext } from 'src/auth/AuthProvider';
import { bidItemParamType } from '../../../../../types/biddetail';
import { MemberType, userPermissionKeys } from '../../../../../utils/constants';
import { formatPrice, hasPermission } from '../../../../../utils/helpers';

const tdWidth = {
  width: '240px',
};

interface PropsTypes {
  eBidClick?: any;
  data: bidItemParamType;
}

const DistributionInfo = (props: PropsTypes) => {
  const { eBidClick = () => {} } = props;
  const { auth } = useAuthContext();

  const viewEditClick: any = (e: Event) => {
    e && e.preventDefault();
    eBidClick();
    window.scrollTo(0, 0);
  };

  return (
    <Table borderless className='tableData'>
      <tbody>
        <tr>
          <th style={tdWidth}>Bid Bond</th>
          <td>{props.data.bidBond ? props.data.bidBond : 'None'}</td>
        </tr>
        <tr>
          <th>Plan (blueprint)</th>
          <td>{props.data.planBluePrint}</td>
        </tr>
        <tr>
          <th>eBidding</th>
          <td>
            {props.data.eBidding === 'No' ? (
              props.data.eBidding
            ) : props.data.eBidding === 'ViewEdit' ? (
              <>
                <a href={props.data.eBidding} onClick={viewEditClick}>
                  View
                </a>
                /<Link to={{ pathname: `/suppliers/bids/${props.data.bidID}/response` }}>Edit</Link>
              </>
            ) : props.data.eBidding === 'Submit' ? (
              hasPermission(auth?.accountPermissions as any, userPermissionKeys.respondBids) ||
              auth?.mainContact ? (
                <Link to={`/suppliers/bids/${props.data.bidID}/response`}>
                  {props.data.eBidding}
                </Link>
              ) : (
                <span className='staticText'>
                  {"You don't have permission to submit response."}
                </span>
              )
            ) : (
              props.data.eBidding
            )}
          </td>
        </tr>
        {props.data.distributedBy ? (
          <tr>
            <th>Distributed By</th>
            <td>{props.data.distributedBy}</td>
          </tr>
        ) : null}

        <tr>
          <th>Distribution Method</th>
          <td>{props.data.isExternalBid === true ? 'External' : props.data.distributionMethod}</td>
        </tr>
        <tr>
          <th>Distribution Options</th>
          <td>{props.data.distributionOptions}</td>
        </tr>
        {props.data.projectEstimatedBudget &&
        ((MemberType.AgencyBuyer === auth?.memberType && props.data.memberID === auth?.memberId) ||
          props.data.displayBudgetToSuppliers) ? (
          <tr>
            <th>Project Estimated Budget</th>
            <td>
              {props.data.projectEstimatedBudget
                ? `$${formatPrice(props.data.projectEstimatedBudget)}`
                : ''}
            </td>
          </tr>
        ) : null}
        {/* {props.data.cost ? (
					<tr>
						<th>Distribution Package Cost</th>
						<td>{props.data.cost ? `$${formatPrice(props.data.cost)}` : ''}</td>
					</tr>
				) : null}
				{props.data.fulfillmentEmail ? (
					<tr>
						<th>Distribution Contact Info</th>
						<td>{props.data.fulfillmentEmail}</td>
					</tr>
				) : null}
				{props.data.distributionNotes ? (
					<tr>
						<th>Distribution Notes</th>
						<td>{props.data.distributionNotes}</td>
					</tr>
				) : null} */}
      </tbody>
    </Table>
  );
};

export default memo(DistributionInfo);
