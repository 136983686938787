import styled from 'styled-components';

import {
  BorderColor,
  BorderThickness,
  FlexContainer,
  Margin,
  Padding,
} from '@demandstar/components/styles';
import { DSLink } from '@demandstar/components/link';

import * as texts from './Reminders.texts';
import { paths } from '../../ContractManagement.paths';
import { useContractDetails } from '../../useContractDetails';
import { useReminders } from './hook/useReminders';

const ReminderToggleContainer = styled(FlexContainer)<{ canHide?: boolean; collapsed?: boolean }>`
  border-bottom: ${({ canHide, collapsed }) => (!canHide || collapsed ? '0' : BorderThickness.Base)}
    solid ${BorderColor.Table};
  column-gap: ${Margin.Less};
  padding-bottom: ${Padding.Base};
`;

interface ReminderPreviewToggleProps {
  canHide?: boolean;
}

export const ReminderPreviewToggle = ({ canHide = true }: ReminderPreviewToggleProps) => {
  const { previewVisible, reminders, toggleReminderPreview } = useReminders();
  const { contractDetails } = useContractDetails();
  const contractId = contractDetails?.id;
  const reminderCount = reminders.length;

  return (
    <>
      {!!reminderCount && (
        <ReminderToggleContainer
          canHide={canHide}
          collapsed={!previewVisible}
          justifyContent='flex-end'
        >
          {canHide && (
            <>
              <DSLink dataTestId='reminders-preview-toggle' onClick={toggleReminderPreview}>
                {previewVisible ? texts.hideReminders : texts.showReminders}
              </DSLink>
              <div>{'|'}</div>
            </>
          )}
          <div>{texts.youHave(reminderCount)}</div>
          <DSLink
            dataTestId='reminders-preview-view-all'
            to={paths.navigation.reminders(contractId)}
          >
            {texts.viewAllReminders}
          </DSLink>
        </ReminderToggleContainer>
      )}
    </>
  );
};
