import { Dayjs } from 'dayjs';

import { Id } from '@demandstar/components/types';

import { DocumentAPIResponse } from '../../service/documents';

export enum TermUnit {
  Days = 'D',
  Months = 'M',
  Weeks = 'W',
  Years = 'Y',
}

export enum ContractStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Draft = 'DRAFT',
}

export enum ContractDocumentStatus {
  Overdue = 'Overdue',
  Requested = 'Requested',
  Uploaded = 'Uploaded',
}

export type AgencyContact = {
  dtCreated?: string;
  email: string;
  id: string;
  name: string;
  phone: string;
};

export type Term = {
  dtCreated?: string;
  duration: string;
  id: string;
};

export type ContractDetailsAPI = {
  accountId: number;
  agencyContact: AgencyContact;
  agencyContactGroupId: string;
  allowBuyOff: boolean;
  amount: number;
  awardeeContactGroupId?: string;
  awardeeContacts: AwardeeContact[];
  bidId: number | null;
  documentGroupId?: string;
  documents?: ContractDocumentAPIResponse[];
  dtCreated: string;
  dtUpdated: string;
  endDate: string;
  id: string;
  isEmailPublic: boolean;
  isPhonePublic: boolean;
  memberId: number;
  name: string;
  scopeOfWork: string;
  solicitationId?: string;
  solicitationType: string | null;
  startDate: string;
  status: ContractStatus;
  termGroupId?: string;
  terms: Term[];
  updatedByAccountId: number;
};

/** Contract Details type */
export type ContractDetails = Omit<ContractDetailsAPI, 'startDate' | 'endDate' | 'bidId'> & {
  startDate: Dayjs;
  endDate: Dayjs;
  bidId?: number;
};

export type ContractDetailsDraft = Omit<ContractDetails, 'awardeeContacts'> & {
  awardeeContacts: AwardeeContactDraft[];
};

export type ContractSearchResultAPI = Omit<ContractDetailsAPI, 'agencyContact' | 'terms'>;

export type ContractSearchResult =
  | (Omit<ContractSearchResultAPI, 'startDate' | 'endDate'> & {
      startDate: Dayjs;
      endDate: Dayjs;
    })
  | ContractDetails;

export type AwardeeContact = {
  // ID
  id: string;
  // Required
  amount: number;
  email: string;
  name: string;
  // Optional
  contactEmail?: string;
  contactName: string;
  contactPhone?: string;
  memberId?: number;
  phone?: string;
};

export type AwardeeContactDraft = Omit<AwardeeContact, 'id'> & Id;

/** Used for graphing section on Dashboard */
export type MemberAward = {
  amount: number;
  awardDate: string;
  awardedToMemberId: number;
  bidAwardId: number;
  bidId: number;
  supplierName: string;
};

/** This type reflects the document metadata that gets returned from the Contract Management API */
export type ContractDocumentAPIResponse = {
  awardee?: AwardeeContact;
  awardeeContactId?: string;
  contract?: ContractDetails;
  contractId?: string;
  documentTypeId?: string;
  documentType?: ContractDocumentType;
  documentId: string;
  isPublic: boolean;
};

/** Stand-in for Document Type returned from CM API, TBD */
export type ContractDocumentType = Id & {
  description: string;
  isDeleted: boolean;
  title: string;
};

/** Pre-existing view model for contract documents, data is flattened into this shape */
export type ContractDocument = {
  id: string;
  contractId: string;
  contract: string;
  document?: DocumentAPIResponse;
  dtCreated: Dayjs;
  dtUpdated: Dayjs;
  public: boolean;
  source: string;
  status: ContractDocumentStatus;
  title?: string;
  type: string;
};
