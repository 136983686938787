//TODO: Rename this file. BidInfo is already in use.

import { Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { bidssummaryParamType, EBidResponseFull } from '../../../../../types/biddetail';
import {
  deprecatedCheckTimezoneCountDown,
  deprecatedGetDateTimeZoneConverted,
} from '../../../../../utils/helpers';
import { bidsSummary } from '../../../../../store/actions';

import CountDownTimer from '../../../../common/countdowntimer';
import { useAuthContext } from 'src/auth/AuthProvider';

const tdWidth = { width: '170px' };

interface BidInfoProps {
  bidssummary?: bidssummaryParamType;
  bidsSummary?: any;
  data: EBidResponseFull;
}

const BidInfo = (props: BidInfoProps) => {
  const dispatch = useDispatch();
  const { bidssummary, data } = props;
  const { auth } = useAuthContext();
  const { results } = bidssummary || {};
  const { bidId = '' } = data;
  const { bidID = '', tzfn = '' } = results || {};

  useEffect(() => {
    if (bidId !== bidID) {
      dispatch(bidsSummary({ auth, bidId: bidId as string, otherapis: false }));
    }
  }, [auth, bidID, bidId, dispatch]);

  return (
    <Table borderless className='tableData'>
      <tbody data-testid='bids.info.detail.table'>
        <tr>
          <th style={tdWidth}>Agency Name</th>
          <td>{props.data.agencyName}</td>
        </tr>
        <tr>
          <th>Bid Number</th>
          <td>{props.data.bidIdentifier}</td>
        </tr>
        <tr>
          <th>Bid Name</th>
          <td>{props.data.bidName}</td>
        </tr>
        <tr>
          <th>Bid Due Date</th>
          <td className='text-red200'>
            {props.data.dueDate && deprecatedGetDateTimeZoneConverted(props.data.dueDate, tzfn)}{' '}
            {props.data.tzn}
          </td>
        </tr>
        {props.data.responseDateTime && results && results.tzfn && (
          <tr>
            <th>Response Submitted On</th>
            <td className='text-red200'>
              {props.data.responseDateTime &&
                deprecatedGetDateTimeZoneConverted(props.data.responseDateTime, results.tzfn)}{' '}
              {props.data.tzn}
            </td>
          </tr>
        )}
        <tr>
          <th>Countdown to Bid Due Date</th>
          <td className='text-red200'>
            {props.data.dueDate &&
            deprecatedCheckTimezoneCountDown(props.data.dueDate, tzfn) === 'closed' ? (
              'Closed'
            ) : (
              <CountDownTimer tzfn={tzfn} dueDate={props.data.dueDate} />
            )}
          </td>
        </tr>
        <tr>
          <th>State</th>
          <td>{props.data.agencyState}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>
            <i className='tag status ml-0'>{props.data.responseStatus}</i>{' '}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default BidInfo;
