import { Flex, FlexContainer } from '@demandstar/components/index';
import { memo, useEffect } from 'react';
import { useAuthContext } from 'src/auth/AuthProvider';
import { Buttons } from 'src/components/customcontrols';
import ToogleBtn from 'src/components/customcontrols/togglebtn';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { useDSSelector } from 'src/store/reducers';
import { Authorization, MemberType, memberinfoParamType } from 'src/types';
import { userPermissionKeys } from 'src/utils/constants';
import { hasPermission } from 'src/utils/helpers';

interface PropsTypes {
  getUserPreference?: any;
  updateUserPreference?: any;
  setMemberInfoDetails?: any;
  memberinfo?: memberinfoParamType;
  activeTab?: string;
  history: any;
}

function Preferences(props: PropsTypes) {
  const { getUserPreference, updateUserPreference, setMemberInfoDetails } = props;

  const memberinfo = useDSSelector(state => state.memberinfo);
  const { prms } = memberinfo;

  const { auth } = useAuthContext();

  const { memberType } = auth || {};
  const { groupedBroadcast } = memberinfo || {};

  const { accountPermissions: authPerms, mainContact: isPrimaryContact = false } =
    auth || ({} as Authorization);
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact;

  useSetBreadcrumb({
    page: 'Account Info',
    title: 'Preferences',
    altname: 'Preferences',
    name: 'Preferences',
    menuactive: '',
    component: '',
  });

  useEffect(() => {
    if (memberType === MemberType.SubscribingSupplier) getUserPreference();
  }, [memberType, getUserPreference]);

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <FlexContainer>
            <Flex>
              <ToogleBtn
                title={'Broadcast daily digest'}
                name={'userBroadcast'}
                label={'Broadcast daily digest'}
                value={groupedBroadcast}
                checked={groupedBroadcast}
                onChange={(event: any) => {
                  setMemberInfoDetails({ groupedBroadcast: event.target.checked });
                }}
              />
            </Flex>
          </FlexContainer>
        </div>
        <div className='col-md-6'>
          <p className='Notes'>
            <b>Note:</b> By choosing to receive the Daily Digest emails, all bid notifications will
            be bundled into one single email, released the next day. To receive each bid
            notification in real time as they are published by the government, unselect this box.
          </p>
        </div>
      </div>
      {hasEditMemberPermission && (
        <div className='row'>
          <div className='col-md-6'>
            <Buttons
              classNames='bttn-primary mt-4 mb-3 float-right'
              text='Save Changes'
              title='Save Changes'
              type='button'
              onClick={() => {
                updateUserPreference({ groupedBroadcast });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default memo(Preferences);
