import { Fragment, useMemo } from 'react';

import { DSSort } from '../sort';
import { FlexContainer } from '../styles';
import { PaginationContainer } from './DSSearch.styles';
import { SearchProps } from './DSSearch';
import { ValidFilterValue } from './DSSearch.d';

function ManagedResultSet<ResultType extends Record<string, unknown>>({
  dataTestId,
  emptyMessage,
  error,
  pagination,
  recordKey,
  renderResult,
  results,
  sort,
}: Pick<
  SearchProps<Record<string, ValidFilterValue>, ResultType>,
  'dataTestId' | 'emptyMessage' | 'pagination' | 'recordKey' | 'sort'
> & {
  error?: boolean;
  renderResult: React.ComponentType<ResultType>;
  results: ResultType[];
}) {
  const RenderResult = renderResult;

  const getRecordKey = useMemo(() => {
    let getKeyFn = (record: ResultType): React.Key => `${record}`;

    if (typeof recordKey === 'function') {
      getKeyFn = recordKey;
    } else {
      getKeyFn = (record: ResultType): React.Key => {
        const value = record[recordKey];
        if (typeof value === 'number' || typeof value === 'string') return value;
        else return `${value}`;
      };
    }

    return getKeyFn;
  }, [recordKey]);

  return (
    <>
      {results?.length ? (
        <DSSort
          dataTestId={sort?.dataTestId || `${dataTestId}-sort`}
          data={results}
          pagination={pagination}
          shouldPaginate={true}
          {...sort}
        >
          {data => (
            <PaginationContainer>
              {data.map((result: ResultType) => (
                <Fragment key={getRecordKey(result)}>
                  <RenderResult {...result} />
                </Fragment>
              ))}
            </PaginationContainer>
          )}
        </DSSort>
      ) : (
        emptyMessage &&
        !error && (
          <FlexContainer data-testid={`${dataTestId}-no-results`} justifyContent={'center'}>
            {emptyMessage}
          </FlexContainer>
        )
      )}
    </>
  );
}

export function DSSearchResults<
  FilterType extends Record<string, ValidFilterValue>,
  ResultType extends Record<string, unknown>,
>({
  dataTestId,
  emptyMessage,
  error,
  pagination,
  recordKey,
  renderResult,
  renderResultSet,
  results,
  sort,
}: Pick<
  SearchProps<FilterType, ResultType>,
  | 'dataTestId'
  | 'emptyMessage'
  | 'pagination'
  | 'recordKey'
  | 'renderResult'
  | 'renderResultSet'
  | 'sort'
> & { error?: boolean; results: ResultType[] }) {
  const RenderResultSet = renderResult ? undefined : renderResultSet;

  return (
    <div data-testid={dataTestId}>
      {renderResult && (
        <ManagedResultSet
          dataTestId={dataTestId}
          emptyMessage={emptyMessage}
          error={error}
          pagination={pagination}
          recordKey={recordKey}
          renderResult={renderResult}
          results={results}
          sort={sort}
        />
      )}

      {RenderResultSet && <RenderResultSet data={results} />}
    </div>
  );
}
