import React from 'react';

const AdvertiseCard: React.FC<{
  title?: string;
  content: string;
  link?: string;
  icon?: any;
  pageFor: string;
}> = ({ title, content, link, icon, pageFor }) => {
  function handlecardClick() {
    window.open(link, '_blank');
  }
  return pageFor === 'eunaDashboard' ? (
    <div className='cardmain ' onClick={handlecardClick}>
      <div className='card-content'>
        {icon ? (
          <div>
            <img className='iconsDashboard' src={icon} />
            <br />
          </div>
        ) : null}
        {title}
        <br />
        <small>{content}</small>
      </div>
    </div>
  ) : null;
};

export default AdvertiseCard;
