import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BorderColor, BorderThickness, H3, Margin, Padding } from '../styles';

import { DataTestId, Selected } from '../types';

export const TreeContainer = styled.div`
  border-bottom: solid ${BorderThickness.Base} ${BorderColor.Table};
  margin-bottom: ${Margin.Less};
`;

export const TreeWrapper = styled.div<Selected>`
    height: ${({ selected }) => (selected ? '100%' : Margin.Single)}};
    overflow: hidden;
    padding-left: ${Padding.Extra};
`;

export const TreeItemWrapper = styled.div<{ isChild?: boolean }>`
  padding: ${Padding.Less};
  ${({ isChild }) => (isChild ? `padding-bottom: ${Padding.None};` : '')}
`;

export const TreeTitle = styled(H3)<DataTestId>`
  margin-top: ${Margin.Base};
`;

export const TreeItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  column-gap: ${Padding.Less};
`;

export const TreeItemSelected = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${Padding.Less};
`;

// Rotate an item icon depending on whether it's toggled open
export const ToggleIconWrapper = styled.div<Selected>`
  transform: ${({ selected }) => (selected ? 'rotate(180deg)' : 'rotate(270deg)')};
`;
