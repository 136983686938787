import styled from 'styled-components';

import {
  BackgroundColor,
  FlexContainer,
  FontFamily,
  FontWeight,
  HeaderFontSize,
  Margin,
  Padding,
} from '@demandstar/components/styles';

import { CartWrapper } from './cart/styles';

export const Page = styled.div`
  background: ${BackgroundColor.BaseWhite};
  display: flex;
  flex-flow: row wrap;
  margin: ${Margin.None} auto;
  padding: 10rem ${Padding.Base};
  width: calc(100% - 2rem);

  @media (max-width: 33rem) {
    padding-top: 10.3125rem;
  }

  @media (max-width: 47.9rem) {
    padding-top: 9.375rem;
  }

  @media (min-width: 75rem) {
    max-width: 100rem;
  }

  ${CartWrapper} {
    left: -3rem;
  }
`;

export const AddStateButtonWrapper = styled(FlexContainer)`
  padding: ${Padding.Base} 0;
  width: 100%;

  > button {
    font-family: ${FontFamily.Header};
    font-size: ${HeaderFontSize.H5};
    font-weight: ${FontWeight.Header};
    
  }
      @media screen and (max-width: 700px) {
      > button {
         font-family: ${FontFamily.Header};
         font-size: ${HeaderFontSize.H6};
         font-weight: ${FontWeight.Header};
         width:max-content; 
        }
     }
  }
`;
