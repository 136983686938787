import { DSEmail } from '@demandstar/components/email';

import { commonEmail } from 'src/utils/texts/common';
interface resultType {
  results: {
    fee?: string;
  };
}
const DownloadEDoc = ({ results = {} }: resultType) => {
  const { fee = '5.00' } = results;
  return (
    <>
      {/* <h4>Download Electronic Documents</h4> */}
      <div className='innerColIndent'>
        <p>
          {`You will be charged a nominal fee of $${fee}. Once you purchase this Bid Package by using
          Place Order option there will be no cost for subsequent document downloads associated with
          this Bid.`}
        </p>
        <p className='noteImportant text-justify'>
          <b>Important Notice Regarding Access and Use of the DemandStar Platform</b>: Under the
          terms of service that you have agreed to in order to access the platform, you may make use
          of the content included in the DemandStar platform solely for your internal business
          analysis and evaluation of governmental procurement opportunities. Without an express
          license from DemandStar, you are strictly prohibited from (1) compiling any form of
          database that includes the content or (2) copying, reproducing, distributing,
          republishing, downloading, posting or otherwise transmitting the content to any third
          party. Please contact <DSEmail>{commonEmail.demandStar}</DSEmail> for further details.
        </p>
      </div>
    </>
  );
};

export default DownloadEDoc;
