import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function getCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_COMMODITY_CODES, payload);
}

export function updateCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_COMMODITY_CODES, payload);
}

export function postUpdateCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.POST_UPDATE_COMMODITY_CODES, payload);
}

export function searchCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.SEARCH_COMMODITY_CODES, payload);
}

export function setCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.SET_COMMODITY_CODES, payload);
}

export function resetCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_COMMODITY_CODES, payload);
}

export function setSelectedCommodityCodes(payload: actionPayloadTypes) {
  return action(actionTypes.SET_SELECTED_COMMODITY_CODES, payload);
}
