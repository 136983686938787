import { ReactNode } from 'react';
import { RecoilState } from 'recoil';
import styled from 'styled-components';

import { isValidEmail, isValidPhone } from '@demandstar/components/utils';
import { DSCheckbox } from '@demandstar/components/inputs';
import { DSEmail } from '@demandstar/components/email/DSEmail';
import { DSPhone } from '@demandstar/components/phone/DSPhone';
import { H4 } from '@demandstar/components/styles';

import { CustomMessageType, EmailInfo } from '../../../../types/awardBid';
import { AwardNotificationBidDetails } from './AwardNotificationBidDetails';
import { EmailInput } from '../../../forms/EmailInput';
import { NotificationRow } from './NotificationRow';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { PhoneInput } from '../../../forms/PhoneInput';
import { Section } from './../styled/AwardBidStyled';
import { TextArea } from '../../../forms/TextArea';
import { TextInput } from '../../../forms/TextInput';
import { ToggleEditDisplay } from '../../../forms/ToggleEditDisplay';
import { useLocalRecoilState } from '../../../../shared/hooks/useLocalRecoilState';

const MinHeightDiv = styled.div`
  min-height: 20rem;
`;
interface EditEmailInfoProps {
  emailInfoState: RecoilState<EmailInfo>;
  includeContactInfo?: boolean;
  customMessageType: CustomMessageType;
  children?: ReactNode;
}

export const EditEmailInfo = (props: EditEmailInfoProps) => {
  const { emailInfoState, includeContactInfo, customMessageType, children } = props;

  const [emailInfo, setEmailInfo] = useLocalRecoilState(emailInfoState);

  const shouldNotifyMessageMap = {
    [CustomMessageType.Awardees]: 'Send notifications to all awardees.',
    [CustomMessageType.NonAwardees]: 'Send notifications to all unselected bidders.',
  };

  function addressee() {
    switch (customMessageType) {
      case CustomMessageType.Awardees:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.awardee;
      case CustomMessageType.NonAwardees:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.nonAwardee;
      case CustomMessageType.Colleagues:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.colleague;
      default:
        return notifyAwardeeTexts.copy.whatShouldRecipientDo.default;
    }
  }

  function setEmailInfoField(field: keyof EmailInfo, value: string) {
    setEmailInfo({ ...emailInfo, [field]: value });
  }
  function toggleShouldNotify() {
    setEmailInfo({ ...emailInfo, shouldNotify: !emailInfo.shouldNotify });
  }

  function whatShouldRecipientDo() {
    if (customMessageType === CustomMessageType.NonAwardees) {
      return; // no message for Unselected Bidders
    }
    return `${notifyAwardeeTexts.copy.whatShouldRecipientDo[0]} ${addressee()} ${
      notifyAwardeeTexts.copy.whatShouldRecipientDo[1]
    }`;
    // What should the awardee do?
    // What should your colleague do?
  }

  return (
    <MinHeightDiv>
      {(customMessageType === CustomMessageType.Awardees ||
        customMessageType === CustomMessageType.NonAwardees) && (
        <DSCheckbox
          name='shouldNotify'
          label={shouldNotifyMessageMap[customMessageType]}
          checked={emailInfo.shouldNotify}
          onClick={toggleShouldNotify}
        ></DSCheckbox>
      )}
      {emailInfo.shouldNotify && (
        <>
          <Section>{children}</Section>
          <Section>
            <H4>{notifyAwardeeTexts.headers.message}</H4>
            <div>
              <NotificationRow required label={notifyAwardeeTexts.labels.subjectLine}>
                <ToggleEditDisplay value={emailInfo.subject}>
                  <TextInput
                    required
                    name='subjectLine'
                    value={emailInfo.subject}
                    onChange={value => setEmailInfoField('subject', value)}
                  />
                </ToggleEditDisplay>
              </NotificationRow>
              <NotificationRow
                required
                label={notifyAwardeeTexts.labels.customMessage}
                subLabel={whatShouldRecipientDo()}
              >
                <ToggleEditDisplay value={emailInfo.customMessage}>
                  <TextArea
                    required
                    name='customMessage'
                    value={emailInfo.customMessage}
                    maxLength={2048}
                    onChange={value => setEmailInfoField('customMessage', value)}
                  />
                </ToggleEditDisplay>
              </NotificationRow>
            </div>
          </Section>
          {includeContactInfo && (
            <Section>
              <H4>{notifyAwardeeTexts.headers.contactInfo}</H4>
              <div>
                <NotificationRow required label={notifyAwardeeTexts.labels.contactName}>
                  <ToggleEditDisplay value={emailInfo.contactName}>
                    <TextInput
                      required
                      name='contactName'
                      value={emailInfo.contactName}
                      onChange={value => setEmailInfoField('contactName', value)}
                    />
                  </ToggleEditDisplay>
                </NotificationRow>
                <NotificationRow required label={notifyAwardeeTexts.labels.contactEmail}>
                  <ToggleEditDisplay
                    value={emailInfo.contactEmail}
                    isValid={isValidEmail}
                    display={<DSEmail>{emailInfo.contactEmail}</DSEmail>}
                  >
                    <EmailInput
                      name='contactEmail'
                      value={emailInfo.contactEmail}
                      onChange={value => setEmailInfoField('contactEmail', value)}
                    />
                  </ToggleEditDisplay>
                </NotificationRow>
                <NotificationRow required label={notifyAwardeeTexts.labels.phoneNumber}>
                  <ToggleEditDisplay
                    value={emailInfo.contactPhone}
                    display={<DSPhone>{emailInfo.contactPhone}</DSPhone>}
                    isValid={isValidPhone}
                  >
                    <PhoneInput
                      required
                      name='phoneNumber'
                      value={emailInfo.contactPhone}
                      onChange={value => setEmailInfoField('contactPhone', value)}
                    />
                  </ToggleEditDisplay>
                </NotificationRow>
                <NotificationRow required label={notifyAwardeeTexts.labels.jobTitle}>
                  <ToggleEditDisplay value={emailInfo.contactTitle}>
                    <TextInput
                      required
                      name='jobTitle'
                      value={emailInfo.contactTitle}
                      maxLength={60} // Maximum length in database
                      onChange={value => setEmailInfoField('contactTitle', value)}
                    />
                  </ToggleEditDisplay>
                </NotificationRow>
              </div>
            </Section>
          )}
          <AwardNotificationBidDetails
            requiresDocs={customMessageType === CustomMessageType.Awardees}
          />
        </>
      )}
    </MinHeightDiv>
  );
};
