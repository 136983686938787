import { DataTestId } from '@demandstar/components/types';

import { Direction } from '../../../../types/shared';
import { sortDirections } from '../../../../utils/constants';

type SortButtonProps = Required<Direction> &
  DataTestId & {
    onClick: () => void;
  };

/**
 * Sort Button with Icon
 */
export function SortButton(props: SortButtonProps) {
  const { direction, onClick, dataTestId } = props;

  return (
    <span
      data-testid={dataTestId}
      className={
        'mdi ml-3' +
        (direction === sortDirections.ASC ? ' mdi-sort-descending' : ' mdi-sort-ascending')
      }
      onClick={onClick}
      title={direction === sortDirections.ASC ? 'Ascending' : 'Descending'}
    />
  );
}
