import { useMemo } from 'react';

import { DSRecordItem, DSRecordItemStatus } from '@demandstar/components/record-item';
import { DSLink } from '@demandstar/components/link';

import * as texts from './ContractSearch.texts';

import { capitalize, displayDate } from 'src/utils/helpers';
import { ContractSearchResult } from '../../contract-management.d';
import { paths } from '../../ContractManagement.paths';

export const ContractResult = ({
  awardeeContacts,
  endDate,
  id,
  name,
  solicitationId,
  startDate,
  status,
}: ContractSearchResult) => {
  const title = useMemo(
    () => <DSLink to={paths.navigation.contractDetails(id)}>{name}</DSLink>,
    [id, name],
  );

  const recordStatus = capitalize(status.toString()) as DSRecordItemStatus;

  const contractData = [
    { title: texts.recordLabels.id, value: solicitationId },
    { title: texts.recordLabels.start, value: displayDate(startDate) },
    { title: texts.recordLabels.end, value: displayDate(endDate) },
    { title: texts.recordLabels.suppliers, value: awardeeContacts.length },
  ];

  return <DSRecordItem data={contractData} status={recordStatus} title={title} />;
};
