import { Selectable } from '../../types/common';

export { convertRGBColorToHex } from '@demandstar/components/utils/conversions';

/**
 * @param array - array of strings
 * @returns comma delimited string
 */
export function arrayToString(array: string[]) {
  let string = '';
  for (let i = 0; i < array.length; i++) {
    if (i) {
      string += ', ';
    }
    string += array[i];
  }
  return string;
}

/**
 * Converts an array of objects to one that we can pass into a SelectBox
 * @param array - array of objects
 * @param label - the property that we want to work as the label in SelectBox
 * @param value - the property that we want to work as the value in SelectBox
 * @returns an array of objects that we can pass into a SelectBox
 */
export function objectToSelectBox<Type>(
  array: Type[],
  label: keyof Type,
  value: keyof Type,
): (Type & Selectable<unknown>)[] {
  return array.map(item => {
    return {
      ...item,
      label: item[label] as any, // string
      value: item[value],
    };
  });
}

export function generateSelectOptions<T>(array: T[], labelKey: keyof T, valueKey: keyof T) {
  return array.map(item => {
    return { label: item[labelKey], value: item[valueKey] || '' };
  });
}

export function convertExternalLinks(URL: string, type: string = 'list', Id: number = 0) {
  let returnURL = URL;
  const isBonfire = URL?.includes('bonfire');
  if (isBonfire) {
    returnURL =
      type === 'list' ? `https://${URL}/portal?tab=login` : `https://${URL}/opportunities/${Id}`;
  } else {
    returnURL =
      type === 'list'
        ? `${URL?.split('PublicDetail.aspx')[0]}VendorRegistration/PreliminaryInfo.aspx`
        : URL;
  }
  return returnURL;
}
