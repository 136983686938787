import styled from 'styled-components';

import { FontFamily, FontWeight, LineHeight, Margin, Padding, ParagraphFontSize } from '../styles';

export const BaseJumpToList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const BaseJumpToListItem = styled.li<{ lastInSection?: boolean }>`
  margin-bottom: ${({ lastInSection }) => (lastInSection ? Margin.Extra : Margin.Less)};
`;

export const BaseJumpToSection = styled.div<{ top?: string }>`
  display: inline-flex;
  flex-direction: column;
  font-family: ${FontFamily.Base};
  font-size: ${ParagraphFontSize.Large};
  font-weight: ${FontWeight.Base};
  height: 100%;
  line-height: ${LineHeight.Base};
  padding: ${Padding.Less} ${Padding.Extra} ${Padding.Less} ${Padding.None};
  position: sticky;
  top: ${({ top }) => top};
`;
