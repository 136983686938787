import { AgencyRegistrationState } from 'src/store/reducers/agencyregistration';
import { ProductApiResponse } from './products';
import { RegistrationData } from './supplierregistration';

export type RegistrationPayload = { payload: any | undefined };

export interface UpsellCardData {
  /** name of the child product, used for building a message */
  baseProductName: string;
  /** the object for the product being upsold */
  product: ProductApiResponse;
  /** number of agencies included in this product */
  totalAgencies: number;
  /** indicates type of upsell & determines message format */
  upsellType: UpsellType;
}

export enum UpsellType {
  National = 'national',
  Nearby = 'nearby',
  Parent = 'parent',
  Popular = 'popular',
}

export enum CartAlertType {
  None = 'none',
  County = 'county',
  National = 'national',
  NationalUpgrade = 'nationalUpgrade',
  StateUpgrade = 'stateUpgrade',
}

export type IncompleteRegistration = {
  email: string;
  JsonData: {
    regData: {
      currentComponent: string;
      registrationData: Partial<RegistrationData>;
    };
  };
};

export type AgencyExistsRequestType = {
  agencyName: string;
  email: string;
  userName: string;
};

export type AgencyFormValidationType = {
  payload: {
    agencyExistsPayload: AgencyExistsRequestType;
    agencyRegistrationDetails: Partial<AgencyRegistrationState>;
  };
};
