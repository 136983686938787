/** TOREFACTOR:
 *
 * bidResponseDetailsState is not typed (it's `any`). It is sometimes like a Bid Details object.
 * And sometimes it is an empty object with undefined values. We need this to be a reliable type.
 * Consider using `useBid` instead.
 *
 * We should also rewrite `deprecatedCheckTimezoneCountDown`.
 * I don't know why it's called a "countdown" when it returns a status.
 *
 * Should we just grab `responseStatus` from bidResponseDetailsState instead of using
 * `deprecatedCheckTimezoneCountDown`
 *
 * * */

import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { bidResponseDetailsState } from '../../store/recoil/bidResponseState';
import { deprecatedCheckTimezoneCountDown } from '../../utils/helpers';

/**
 * Helper fn to check the status on the currently selected `bidResponseDetailsState`,
 * @returns
 */
export function useBidResponseStatus() {
  const bidResponseDetailsLoadable = useRecoilValueLoadable(bidResponseDetailsState);
  const bidResponseDetails = useMemo(() => {
    return bidResponseDetailsLoadable.valueMaybe();
  }, [bidResponseDetailsLoadable]);

  const bidResponseStatus = useMemo(() => {
    if (bidResponseDetails?.dueDate && bidResponseDetails?.tzfn) {
      return deprecatedCheckTimezoneCountDown(bidResponseDetails.dueDate, bidResponseDetails.tzfn);
    }
    return '';
  }, [bidResponseDetails]);
  return { bidResponseStatus };
}
