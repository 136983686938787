import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';
import { DeprecatedFullTimeZone } from '../../utils/constants';

export interface MemberInfoState {
  a1: string; // address1
  a2: string; // address2
  addresses?: unknown[];
  addressType?: string;
  ahc?: boolean;
  c: string; // country
  ca?: string; // country abbreviation (???)
  ci?: number; // countryId
  ct: string; // city
  cu: string; // county
  cui?: number; // countyId (???)
  dnd?: boolean; // doNotContact
  eml?: string; // email
  f?: string; // fax (???)
  fe?: string; // fax extension (???)
  ic?: number | false; // 0 (???)
  ifd: boolean; // isFreeDocDownload
  jt?: string; // job title (???) "Sr. Department Specialist"
  metroId?: number;
  mc?: boolean; // mainContact
  mi: number; // memberId
  mn: string; // memberName
  ms: string; // memberStatus // TODO: Convert to enum
  mt: string; // memberType // TODO: Convert to enum
  mtn?: string; // memberTypeName
  p: string; // phone
  pc: string; // postalCode
  pe: string; // phoneExtension
  phdt?: string; // planholderDisplayType // TODO: convert to enum
  prms?: string; // permissions
  qclose?: unknown;
  qins?: unknown;
  qintro?: unknown;
  sdbd?: boolean; // downloadSuppDocBeforeDue
  st: string; // state
  sta?: string; // state abbreviation
  sti?: number; // state id
  tzfn: DeprecatedFullTimeZone | ''; // timeZoneFullName
  tzn?: string; // timeZoneName
  tzi?: string; // timeZoneId;
  isExternalAgency: boolean;
  groupedBroadcast: boolean;
}

export const initialStateMemberInfo = {
  a1: '',
  a2: '',
  ct: '',
  cu: '',
  cui: 0,
  c: '',
  ca: '',
  ci: 0,
  f: '',
  fe: '',
  ic: false,
  ifd: false,
  mi: 0,
  mn: '',
  ms: '',
  mt: '',
  mtn: '',
  p: '',
  pe: '',
  pc: '',
  st: '',
  sta: '',
  sti: 0,
  tzfn: '',
  tzn: '',
  isExternalAgency: false,
  groupedBroadcast: false,
} as MemberInfoState;

export const reducer = (state = initialStateMemberInfo, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.LOAD_MEMBER_INFO.SUCCESS:
      state = { ...state, ...action.payload };
      break;
    case actionTypes.SET_MEMBER_INFO_DETAILS.TRIGGER:
      state = { ...state, ...action.payload };
      break;
    case actionTypes.RESET_MEMBER_INFO.TRIGGER:
      state = initialStateMemberInfo;
      break;
    default:
      break;
  }
  return state;
};
