import styled from 'styled-components';

import { BaseSVG, DSSVGIconProps } from './DSSVGIconStyles';

import { height, width } from '../constants';
import { pxToRem } from '../utils/conversions';

/**
 * FInal exported component, wraps the styled svg and applies default props.
 */
export const DSSVGIcon = styled(BaseSVG).attrs<DSSVGIconProps>(
  ({ dataTestId, color, onClick, title }: DSSVGIconProps) => ({
    /**
     * FIXME: This is a styled component and it should not take
     * dataTestId as a property. We're getting console errors saying
     * that React doesn't know what to do with dataTestId.
     */
    'data-testid': `${dataTestId}`,
    fill: `${color}`,
    onClick,
    title: title || '',
  }),
)`
  color: ${(props: DSSVGIconProps) => (props?.color ? props.color : 'currentColor')};
  height: ${(props: DSSVGIconProps) =>
    props?.height ? pxToRem(props.height) : height.iconBaseRem};
  width: ${(props: DSSVGIconProps) => (props?.width ? pxToRem(props.width) : width.iconBaseRem)};
`;
