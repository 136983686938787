import { BidDetailsLink } from '../../../common/controls/text/links/BidDetailsLink';
import { EmailInfo } from '../../../../types/awardBid';
import { Label } from '../../../common/controls/text/Label';
import { MessagePreviewRow } from '../styled/AwardBidStyled';
import { notifyAwardeeTexts } from '../../../../utils/texts/agency/awardBid';
import { useBid } from 'src/shared/hooks/useBid';

interface BidEmailPreviewProps {
  emailInfo: EmailInfo;
  addressee: string;
}

export const BidEmailPreview = (props: BidEmailPreviewProps) => {
  const { addressee, emailInfo } = props;

  const { bid } = useBid();

  return bid ? (
    <>
      <p>
        {emailInfo.introduction} {addressee},
      </p>
      <p>{emailInfo.customMessage}</p>
      <BidDetailsLink bidId={bid.bidId} newTab>
        {bid.bidName}
      </BidDetailsLink>
      <MessagePreviewRow>
        <Label>{notifyAwardeeTexts.labels.agency}:</Label>
        <span>{bid.agencyName}</span>
      </MessagePreviewRow>
      <MessagePreviewRow>
        <Label>{notifyAwardeeTexts.labels.bidWriter}:</Label>
        <span>{bid.bidWriter}</span>
      </MessagePreviewRow>
      <MessagePreviewRow>
        <Label>{notifyAwardeeTexts.labels.bidNumber}:</Label>
        <span>{bid.bidNumber}</span>
      </MessagePreviewRow>
    </>
  ) : (
    <></>
  );
};
