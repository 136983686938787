import { ComponentType, ReactNode, useCallback, useEffect, useState } from 'react';

import { TabContentContainer, TabNavigationContainer } from './DSTabNavigation.styles';

import { DataTestId } from '../types';
import { DSTabNavigationHeader } from './DSTabNavigationHeader';
import { DSTabNavigationLabelProps } from './DSTabNavigationLabel';

export const defaultDataTestId = 'tab-navigation';

export type DSTabNavigationProps = DataTestId & {
  /** Optional override for the tab navigation label renderer */
  labelComponent?: ComponentType<DSTabNavigationLabelProps>;
  /** The tabs for navigation */
  tabs?: (Omit<DSTabNavigationLabelProps, 'labelComponent' | 'onClick' | 'selected'> & {
    content: ReactNode;
  })[];
};

/**
 * A high-level tab navigation component that displays tabs & toggles between their associated content
 * @example <DSTabNavigation tabs={[
 * {
 *   id: '2edx2wsw',
 *   label: `Tab 1`,
 *   content: <div>content 1</div>,
 * },
 * {
 *   id: '2ecddxsw',
 *   label: `Tab 2`,
 *   content: <div>content 2</div>,
 * },
 * ]} />
 */
export function DSTabNavigation({
  dataTestId = defaultDataTestId,
  labelComponent,
  tabs,
}: DSTabNavigationProps) {
  const [selectedTabId, setSelectedTabId] = useState<string>('');

  /**
   * Get the tab content to be rendered by selectedTabId
   * @returns {ReactNode} tab.content
   * @example getTabContent({id: 'mytab', content; <div>my content</div>, label: 'my label'})
   *            if (selectedtabId === 'mytab')
   *                returns <div>my content</div>
   */
  const getTabContent = useCallback(() => {
    return tabs?.find(({ id }) => id === selectedTabId)?.content;
  }, [selectedTabId, tabs]);

  /** Auto-select the first tab unless an `itemId` has been passed via
   * a deep-link route. // TODO - implement deep linking */
  useEffect(() => {
    setSelectedTabId(tabs?.length ? tabs[0].id : '');
  }, [tabs]);

  return (
    <TabNavigationContainer data-testid={dataTestId}>
      <DSTabNavigationHeader
        dataTestId={`${dataTestId}.header`}
        tabs={tabs?.map(t => ({
          ...t,
          labelComponent: labelComponent,
          onClick: () => {
            setSelectedTabId(t.id);
          },
          selected: selectedTabId === t.id,
        }))}
      />
      <TabContentContainer>{getTabContent()}</TabContentContainer>
    </TabNavigationContainer>
  );
}
