import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AccountConfirmation, CreateAccount, EmailAccountDetect } from '../../limited/registration';
import { clearStorageLoginFn, clearTokenCookie } from 'src/utils/helpers';
import {
  useInitializeSelectedProductsFromRegistration,
  useRefreshIncompleteRegistration,
  useRefreshRegistrationSubscriptions,
  useRegistration,
} from 'src/shared/hooks/useRegistration';

import { CompleteProfile } from '../../products/complete-profile/CompleteProfile';
import { LimitedFooter } from '../../limited/footer';
import { LimitedHeader } from '../../limited/header';
import { registrationComponent } from '../../../utils/constants';
import { resetAuth } from '../../../store/actions';
import { ReviewRegistration } from '../../products/review-order';
import { SelectFreeAgency } from '../../products/select-free-agency/SelectFreeAgency';
import { SelectProducts } from '../../products/select-products';
import { StyledRegistrationPage } from './Registration.styles';
import { useRouter } from 'src/shared/hooks';
import {CommoditiesAndTagsRegistration} from '../commodity-code-AI/index';

export const Registration = () => {
  const dispatch = useDispatch();
  const {
    routerParams: { memberId },
  } = useRouter();
  const { currentComponent, populateRegistrationEmail, setRegistrationDataFromMemberId } =
    useRegistration();

  useRefreshIncompleteRegistration();
  useInitializeSelectedProductsFromRegistration();
  useRefreshRegistrationSubscriptions();

  // TODO: Evaluate what this is doing & if all 3 are necessary
  useEffect(() => {
    dispatch(resetAuth());
    clearStorageLoginFn();
    clearTokenCookie();
  }, [dispatch]);

  useEffect(() => {
    if (memberId) {
      setRegistrationDataFromMemberId(memberId);
    }
  }, [memberId, setRegistrationDataFromMemberId]);

  useEffect(() => {
    populateRegistrationEmail();
  }, [populateRegistrationEmail]);

  // TOREFACTOR: Limited Header, Limited Footer, & registration page layout should become a DSPageLayout option
  return (
    <>
      <LimitedHeader pagefor='accountrecovery' />
      {currentComponent === registrationComponent.CreateAccount ? (
        <CreateAccount />
      ) : currentComponent === registrationComponent.EmailAccount ? (
        <EmailAccountDetect />
      ) : currentComponent === registrationComponent.ChooseAgency ? (
        <SelectFreeAgency />
      ) : currentComponent === registrationComponent.CommodityCode ? (
        <StyledRegistrationPage>
         <CommoditiesAndTagsRegistration/>
        </StyledRegistrationPage>
      ) : currentComponent === registrationComponent.ChooseSubscription ? (
        <StyledRegistrationPage>
          <SelectProducts />
        </StyledRegistrationPage>
      ) : currentComponent === registrationComponent.CompleteProfile ? (
        <StyledRegistrationPage>
          <CompleteProfile />
        </StyledRegistrationPage>
      ) : currentComponent === registrationComponent.ReviewOrder ? (
        <StyledRegistrationPage>
          <Suspense fallback={<div>Review Order Loading...</div>}>
            <ReviewRegistration />
          </Suspense>
        </StyledRegistrationPage>
      ) : currentComponent === registrationComponent.AccountConfirmation ? (
        <AccountConfirmation />
      ) : null}
      <LimitedFooter pagefor='registration' currentComponent={currentComponent} />
    </>
  );
};
