import * as paths from './reminders.paths';
import { axiosBarePostAuthenticated, axiosBareRequestAuthenticated } from 'src/store/services/api';
import { ContractReminder, ContractReminderAPI, ReminderPreviewPreferences } from './reminders';
import { constants } from 'src/utils/settings';

export async function createReminder(reminder: ContractReminder) {
  return axiosBarePostAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    data: reminder,
    url: paths.create,
  });
}

export async function getReminder(id: string) {
  return axiosBareRequestAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    url: paths.get(id),
  });
}

export async function sendReminder(id: string) {
  return axiosBarePostAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    url: paths.send(id),
  });
}

export async function searchReminders(params: { contractId?: string }) {
  return axiosBarePostAuthenticated<ContractReminderAPI[]>({
    baseURL: constants.api.reminderUrl,
    data: params,
    url: paths.search,
  });
}

export async function updateReminder(reminder: ContractReminder) {
  return axiosBarePostAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    data: reminder,
    url: paths.update(reminder.id),
  });
}

export async function getPreviewPreferences() {
  return axiosBareRequestAuthenticated<ReminderPreviewPreferences>({
    baseURL: constants.api.reminderUrl,
    url: paths.getPreviewPreferences,
  });
}

export async function setPreviewPreferences(hidePreview = false) {
  return axiosBarePostAuthenticated<ContractReminderAPI>({
    baseURL: constants.api.reminderUrl,
    data: {
      hidePreview,
    },
    url: paths.setPreviewPreferences,
  });
}
