import { ChangeEvent, ChangeEventHandler, FormEventHandler, useLayoutEffect, useRef } from 'react';

import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import getSizingData, { SizingData } from './getSizingData';
import { TextAreaStyled } from './DSTextArea.styles';
import { useWindowResizeListener } from './hooks';

import calculateNodeHeight from './calculateNodeHeight';

type TextAreaHeightChangeMeta = {
  rowHeight: number;
};

export type DSTextAreaProps = FieldProps & {
  /** Cache measurements to improve performance */
  cacheMeasurements?: boolean;
  /** Test ID for testing-library */
  dataTestId?: string;
  /** Disable the field */
  disabled?: boolean;
  /** The maximum length of characters allowed */
  maxLength?: number;
  /** The maximum number of rows the area can expand to */
  maxRows?: number;
  /** The minimum number of rows the area starts with */
  minRows?: number;
  /** onBlur event callback */
  onBlur?: (value: string) => void;
  /** onChange event callback */
  onChange?: (value: string) => void;
  /** Callback that fires when the height changes */
  onHeightChange?: (height: number, meta: TextAreaHeightChangeMeta) => void;
  /** Placeholder text that shows when area is empty */
  placeholder?: string;
  /** Default value */
  value?: string;
};

/**
 * Textarea input
 * @example <DSTextArea name="textarea-example" />
 */
export const DSTextArea = (props: DSTextAreaProps) => {
  const {
    cacheMeasurements,
    dataTestId,
    disabled,
    maxLength = 524288, // Per Google, this is the actual default
    maxRows = 5,
    minRows = 2,
    name,
    onChange,
    onHeightChange,
    onBlur,
    placeholder,
    value,
  } = props;
  const isControlled = value !== undefined;
  const libRef = useRef<HTMLTextAreaElement | null>(null);
  const heightRef = useRef(0);
  const measurementsCacheRef = useRef<SizingData>();

  const resizeTextarea = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const node = libRef.current!;
    const nodeSizingData =
      cacheMeasurements && measurementsCacheRef.current
        ? measurementsCacheRef.current
        : getSizingData(node);

    if (!nodeSizingData) {
      return;
    }

    measurementsCacheRef.current = nodeSizingData;

    const [height, rowHeight] = calculateNodeHeight(
      nodeSizingData,
      node.value || node.placeholder || 'x',
      minRows,
      maxRows,
    );

    if (heightRef.current !== height) {
      heightRef.current = height;
      node.style.setProperty('height', `${height}px`, 'important');
      if (onHeightChange) {
        onHeightChange(height, { rowHeight });
      }
    }
  };

  const change: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!isControlled) {
      resizeTextarea();
    }

    if (onChange) {
      onChange(event.target.value.trimStart());
    }
  };

  const blur: ChangeEventHandler<HTMLTextAreaElement> & FormEventHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!isControlled) {
      resizeTextarea();
    }

    if (onBlur) {
      onBlur(event.target.value.trim());
    }
  };

  useLayoutEffect(resizeTextarea);
  useWindowResizeListener(resizeTextarea);

  return (
    <FieldContainer {...getFieldContainerProps(props)}>
      <TextAreaStyled
        data-testid={dataTestId}
        disabled={disabled}
        maxLength={maxLength}
        onChange={change}
        onBlur={blur}
        placeholder={placeholder}
        ref={libRef}
        name={name}
        value={value}
      />
    </FieldContainer>
  );
};
