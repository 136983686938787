import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';
import { TollFreeNumber } from 'src/utils/texts/common';

const HelpText = () => {
  return (
    <small className='clear-both mt-3'>
      Need help? Email {DemandStarSupportEmail} or call {TollFreeNumber}
    </small>
  );
};

export default HelpText;
