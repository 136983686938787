import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { BaseCheckbox } from '../../inputs/checkbox/DSCheckbox.styles';
import { ChoiceLabel } from '../../assets/ChoiceStyles';
import { FieldProps } from '../../field-container';
import { Inactive } from '../../types';
import { InvisibleInput } from '../../assets/CommonStyles';
import { onEnter } from '../../utils';

export type DSCheckFieldProps = FieldProps &
  Inactive & {
    /** react-hook-form rules */
    rules?: RegisterOptions;
    /** optional HTML id */
    id?: string;
  };

/** single checkbox field to be used in react-hook-form
 * @example
 * <FormProvider {...methods}>
 *   <DSCheckField name='yes' label='Yes'/>
 * </FormProvider>
 */
export const DSCheckField = (props: DSCheckFieldProps): JSX.Element => {
  const { dataTestId, inactive, id, name, label, rules } = props;

  const methods = useFormContext();
  const { control, watch } = methods;

  const value = !!watch(name);

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      rules={rules}
      render={({ field }: any) => {
        function toggle() {
          if (!inactive) field.onChange(!value);
        }
        return (
          <ChoiceLabel inactive={inactive} data-testid={name || dataTestId}>
            {label}
            <InvisibleInput
              ref={field.ref}
              readOnly={true}
              type='checkbox'
              onChange={toggle}
              data-testid={`${dataTestId || id || name}-input`}
              id={id || name}
              name={name}
              checked={value}
              onKeyDown={onEnter(toggle)}
            />
            <BaseCheckbox checked={!!value} inactive={inactive} />
          </ChoiceLabel>
        );
      }}
    />
  );
};
