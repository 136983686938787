import { useState } from 'react';

import { DSEmail } from '@demandstar/components/email';

import { commonEmail, TollFreeNumber } from 'src/utils/texts/common';
import { footerMenuType, registrationComponent } from '../../utils/constants';
import { Buttons } from '../customcontrols';
import { currentYear } from '../../utils/helpers';
import { footerMenus } from '../../utils/constants';
import { ModalPopUp } from '../common/modals/ModalPopUp';

interface propsTypes {
  pagefor?: string;
  currentComponent?: string;
}

export function LimitedFooter(props: propsTypes) {
  const { pagefor = '', currentComponent = '' } = props;

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [lastPageUrl, setLastPageUrl] = useState('');

  const goToPage = (pageurl: string) => {
    if (pagefor === 'registration' && currentComponent !== registrationComponent.CreateAccount) {
      setConfirmModalVisible(true);
      setLastPageUrl(pageurl);
    } else {
      window.location.href = pageurl;
    }
  };

  return (
    <>
      <footer className='p-6 pt-md-5 border-top business-footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <section>
                <h5 data-toggle='modal' data-target='#navAlert'>
                  <span
                    className='cursorPointer'
                    onClick={() => goToPage('https://network.demandstar.com/contact/')}
                  >
                    Contact
                  </span>
                </h5>
                <ul className='list-unstyled text-small'>
                  <li data-toggle='modal' data-target='#navAlert'>
                    <span className='footer-MenuItems'>
                      Email us at <DSEmail>{commonEmail.hello}</DSEmail>
                    </span>
                  </li>
                </ul>
              </section>
            </div>
            {footerMenus.map((menuGroup: footerMenuType, index: number) => (
              <div className='col-12 col-md-4' key={index}>
                <h5 data-toggle='modal' data-target='#navAlert'>
                  <span>{menuGroup.headerTitle}</span>
                </h5>
                <ul className='list-unstyled text-small'>
                  {menuGroup.items.map(
                    (meuItem: { title: string; link: string }, Mindex: number) => (
                      <li data-toggle='modal' data-target='#navAlert' key={Mindex}>
                        <span
                          className='cursorPointer footer-MenuItems limted-footer'
                          onClick={() => goToPage(meuItem.link)}
                        >
                          {meuItem.title}
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className='row copyrights-band'>
          <div className='col-12'>
            <p>
              <span
                className='cursorPointer limted-footer'
                onClick={() => goToPage('https://network.demandstar.com/')}
              >
                Demandstar {currentYear()}.
              </span>
              <span
                className='cursorPointer limted-footer'
                onClick={() => goToPage('https://network.demandstar.com/privacy-policy/')}
              >
                &nbsp;Privacy Policy.
              </span>
              <span
                className='cursorPointer limted-footer'
                onClick={() => goToPage('https://network.demandstar.com/terms-of-use/')}
              >
                &nbsp;Terms of Use.
              </span>
            </p>
          </div>
        </div>
      </footer>
      <ModalPopUp
        title='Confirm Navigation?'
        size='md'
        isOpen={confirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>You are one step away from receiving hundreds of opportunities!</p>
        <p className='text-red200'>Are you sure you want to move away from sign up?</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={() => {
            setConfirmModalVisible(false);
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Confirm'
          title='Confirm'
          type='button'
          onClick={() => {
            setConfirmModalVisible(false);
            window.location.href = lastPageUrl;
          }}
        />
      </ModalPopUp>
    </>
  );
}
