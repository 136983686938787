import { ClickMapData } from '.';

export const mapDataUS: ClickMapData = {
  mapKey: 'us',
  mapItems: [
    {
      pathData:
        'm98.47,56.11-4.07-.16h-1.82l.16,1.33-.81.06v-.66l-.43-.23-.17.96-.67-.04-.24-1.83 .07-9.99 5.41-.5 1.99,6.82-.33,1.34 .5.83-.17,1.07 .5.76 .08.24',
      seoId: 'alabama',
    },
    {
      seoId: 'alabama',
      pathData:
        'm98.47,56.11-4.07-.16h-1.82l.16,1.33-.81.06v-.66l-.43-.23-.17.96-.67-.04-.24-1.83 .07-9.99 5.41-.5 1.99,6.82-.33,1.34 .5.83-.17,1.07 .5.76 .08.24',
    },
    {
      seoId: 'alaska',
      pathData:
        'm27.33,54.91 .33,3.41 .17,4.25 .09,2 .83.07 .91.59 .33.5h.17l.33-.59 .5.26v.24l-.41.09-.26.17 .09.24 .17.09 .33-.17 .24-.25 .26-.17 .5.26 .16.41 .34.25 .49.34 .34.49 .07.34 .92.16 .17,1.25-.33-.17-.5-.67h-.26l-.16-.5-.33-.07-.08-.09-.17-.58-.25-.25-.34-.17-.24.5 .08.33 .33.09 .26.17-.34.57-.25-.07-.24-.43-.43-.41-.07-.58-.42-.41-.67-.26-.33-.24-.5-.34-1.07-.42-.76-.08h-.91l-.33-.08-.59-.84-.4-.16-.5.09v.33l.07.5-.16.08h-.5l-.66.5-.34.25-.41-.09 .08-.41 .42-.25v-.5l.08-.58-.17-.09-.57.67-.5.5-.33.5-.09.16 .33.17-.08.25-.66.5-.5.5-.83.5-.83.33-.67.34-.5.24-.25-.41 .25-.33 .5-.26 .75-.5 .33-.41 .16-.5 .09-.33-.42-.09 .09-.33-.42.09-.24-.26-.34.09-.25.08-.25.5-.42-.67 .26-.57-.26-.84-.49.25-.41-.83v-.75l-.34-.75 .75-.17-.08-.5 .41-.33 1.58.17 .25-.17v-1.08l-.08-.16-.76.07-.07-.41-.59.34v-.42l-.83-.17 .09-.41 .24-.33-.33-.43 .5-.07 .33-.17h.42l.24-.25 .26.25-.17.33-.09.34 .59-.09 .58-.17 .33-.5-.41-.08-.59-.83-.41-.66v-.59l.41-.17 .5-.24 .42.16 .67-1.16 .74.08 .66-.17 .59-.24 .58.41 .58.25 .91.25 2.25.5 .5.16 .17.5 .07-.58',
    },
    {
      seoId: 'alaska',
      pathData: 'm13.21,69.56 .33-.42-.16-.5-.34.16-.24.59h-.42',
    },
    {
      seoId: 'alaska',
      pathData: 'm15.46,68.56-.33.24',
    },
    {
      seoId: 'alaska',
      pathData: 'm22.44,66.56v.67l-.42.33-.75.17-.16-.34 .33-.25 .5-.17 .16-.5h.25',
    },
    {
      seoId: 'alaska',
      pathData: 'm2.58,64.81-.58-.67',
    },
    {
      seoId: 'alaska',
      pathData: 'm7.32,68.8-.17.09',
    },
    {
      seoId: 'alaska',
      pathData: 'm8.65,69.3-.42-.24',
    },
    {
      seoId: 'alaska',
      pathData: 'm5.99,68.23-.59-.5',
    },
    {
      seoId: 'alaska',
      pathData: 'm4.49,67.14-.58-.33h.08',
    },
    {
      seoId: 'alaska',
      pathData: 'm4.66,67.89-.26-.25',
    },
    {
      seoId: 'arizona',
      pathData:
        'm32.79,41.9-.12.49 .42.5 .17.74 .16.42-.25.67-.91.58 .08.25-.41.58-.09.42v1.16 1.08l2.99,2.13 1.5,1 1.82,1.24 5.48.61 2.3-14.83-10.87-1.81-.47,2.43-.33.33-.59-.49-.5.41-.17,1.99-.21.1',
    },
    {
      seoId: 'arkansas',
      pathData:
        'm86.67,43.3-1,.17-.41-.34 .25-.41 .25-.92-9.56.42 .34,7.74 .58.02 .25,1.56 6.57-.25 .07-.25-.07-.92 .07-.91 .59-1 .74-.92-.33-.83 .66-.5 .16-.51 .6-.56 .33-1.26-.09-.33',
    },
    {
      seoId: 'california',
      pathData:
        'm31.84,47.71v-1.16l.09-.42 .41-.58-.08-.25 .91-.58 .25-.67-.16-.42-.17-.74-.42-.5 .32-.63-5.3-7.78-3.16-5.16 2.02-7.54-8.01-2.05-.36.24 .17.5v.33l-1.09,1.83-.41.67v1l.41.5 .09,1.57-.16,1.33 .24,1.09 .5.83 .09,1.33h.83l.08-.33 .42.17v.33l-.34.17-.08.41v.33l-.34-.08-.41.58 .5.59 .5,1.16-.25.5-.34.33 .59,1.5 .33.67 .41,1.07 .42.92v.84l-.16.66-.09.17 .75.24 .41.5 .5-.08 .42.08 .41.33 .17.43 .16.49 .67.08 .58.34 .25.33 .42.59 .33.49 .33.5 .5.08 .08.17 .17.5 .08.83 .08.66 .5.43 .5.07 .5.09 1.49.34 1.09.16 .74-.09 .62-.29',
    },
    {
      seoId: 'colorado',
      pathData: 'm47.15,28.16 9.82,1.08 4.04.34-.6,10.67-2.23-.06-12.22-1.22 1.19-10.81',
    },
    {
      seoId: 'connecticut',
      pathData:
        'm120.82,22.5v-1.41l-3.44,1.18-.02,2.2-.11,1.59 .41-1.19 .34-.57 1.24-1 .75-.17 .74-.16 .09-.47',
    },
    {
      seoId: 'delaware',
      pathData: 'm117.31,31.22-.69.07-.88-.61-.2-.83-.45-1.37-.94.23 .77,3.2h1.75l.64-.69',
    },
    {
      seoId: 'florida',
      pathData:
        'm97.3,58.24-.99-.42-.91-.58-.92-.08-1,.58h-.69l-.05-.46-.16-1.33h1.82l4.05.16 .07.56 6.25-.03 .19.22h.35l-.2-1.91 1.57.2 .35,1.17 .83,1.5 .5,1.17 1.33,2.16 .5,1.25 .99,1.5 .58.83 .09,2.08-.26,1.08-.16.5-.08.41-.83.59h-.59l-.41-.33-.25-.5-.5-.5-.74-.67-.83-.49v-.59l-.42-.5v-.75l-.58.17-.67-.59-.42-.5 .09-.5 .17-.49-.09-.75-.33.17-.25.41-.08-1.33 .08-1-.16-.83-.92-.58-.91-.59-.75-.41-.91-.42-1-.41-.33.5-.5.5-.83-.09-1.09-.08',
    },
    {
      seoId: 'georgia',
      pathData:
        'm106.44,50.79-.41-.58-.66-1-1.16-1.5-.92-.08-1.17-1.5-.41-.41-.58-.09-.41-.41 .33-.76-2.66.34-2.49.25 1.99,6.82-.33,1.34 .5.83-.16,1.06 .45.59 .21,1.04 .09-.06 6.13-.03 .23.24h.28l-.18-1.93 1.59.21-.26-.67 .17-1.66 .33-.17 .17-.33-.5-.08 1.59-1.16-.95-.46-.81.16',
    },
    {
      seoId: 'hawaii',
      pathData: 'm47.22,66.32 .33-.33-.33-.43h-.84l-.08.43 .83.24 .09.09',
    },
    {
      seoId: 'hawaii',
      pathData: 'm52.37,67.82-.92-.34-.75-.33 .25.74 .5.59 .33.5 .59-.33 .07-.5-.16-.17 .09-.16',
    },
    {
      seoId: 'hawaii',
      pathData:
        'm53.86,69.98 .83.17 .25.41 .49.49 .59.26-.09.58-.83.42-.5.41-.57-.08-.26.5-.5.08-.41-.67-.08-1.16-.25-.41 .33-.83 .41-.34 .59.17',
    },
    {
      seoId: 'hawaii',
      pathData: 'm39.98,65.23-.08.09',
    },
    {
      seoId: 'hawaii',
      pathData: 'm43.14,64.82 .42-.42-.17-.33-.42-.17-.83.17-.33.5 .59.08 .07.25 .59-.08h.41-.33',
    },
    {
      seoId: 'hawaii',
      pathData: 'm41.31,64.73-.5.33',
    },
    {
      seoId: 'hawaii',
      pathData: 'm50.28,67.32-.9-.5',
    },
    {
      seoId: 'idaho',
      pathData:
        'm44.53,18.16-.64-.32h-.58l-.58.17-.75.16-1.17-.16 .17-.67-.5-.5-.17-.66-.49-.58 .08-.5-.16-.92-.26.17-.41.09-.33.24-.09-.5 .17-1.24 .59-1.26-.26-.24-.24-.09-.42-.41-.17-.66-.41-.67-.5-.5-.16-.83 .07-1.5 .11-1.95-1.44-.3-.75,3.59-.49,2.13-.44,1.66-2.47,10.59 5.77,1.45 5.8,1.05 1.12-6.84',
    },
    {
      seoId: 'illinois',
      pathData:
        'm90.66,37.56-.58.42-.09.58-.49.25-.08.5-.62.56-.84.7-.46-.34-.66-.59 .16-.17 .17-.5-.74-.57-.67-.5-.66-.83v-.67l.24-.5-.08-.59-.99-.07-.92-1-.58-.76-.25-.83-.32-1.14 .9-.52-.16-1.08 .33-.33-.5-.59 .41-.33 .76-.34 .49-.57 .34-.67-.34-.5-.58-.83-.34-.59 5.82-.16 .17,1.13 .66,1.16 .17.37 .16,2.49 .09,2.26 .08,1.33-.41.83 .17.5 .16.58-.42.83 .66.09 .34.83-.5.16',
    },
    {
      seoId: 'indiana',
      pathData:
        'm90.16,27.29 .5.17 .75-.26 .25-.74 3.74.12 1.16,6.49v.75l-.5.33-.49.75-.34.5-.58.08-.17,1.25-.58.34-.42-.43-.74.34-.33.33-1.25.09-.34-.83-.66-.09 .42-.83-.16-.58-.17-.5 .41-.83-.08-1.33-.09-2.26-.16-2.49-.17-.37',
    },
    {
      seoId: 'iowa',
      pathData:
        'm73.38,30.49-.58-3.74-.58-1.09-.25-2.41 .36-.25 10.04.17 .98,1.66 .5.92 .58.83 .34.5-.34.67-.49.57-.76.34-.41.33 .5.59-.33.33 .16,1.08-.93.53-.15-.28-.41-.59-1.09.34-6.89.25-.25-.75',
    },
    {
      seoId: 'kansas',
      pathData:
        'm76.16,35.04-.2-.14-.66-1.25 .38-.33-.05-.41-14.79-.59-.42,7.91 15.74.57 .04-5.35-.04-.41',
    },
    {
      seoId: 'kentucky',
      pathData:
        'm99.89,39.73 .66-.59 .74-.24 .09-.59 .91-.83-.41-1.08-.52-1.44-.4-.39-.83-.17-1.07.25-1.5-.34-.59-.49h-.41l-.5.33-.49.75-.34.5-.58.08-.17,1.25-.58.34-.42-.43-.74.34-.33.33h-.26l-1.49.25-.58.42-.09.58-.49.25-.08.5-1,.89-.44.36-.43.83 1.2.41 1.08-.57 .92-.09 3.23-.41 5.74-.67 .17-.33',
    },
    {
      seoId: 'louisiana',
      pathData:
        'm87.76,57.94-.26-1.24h-.41-3.74l-.17-1.16 .59-.84 .17-.58 .83-.58-.42-.83-.41-1.42-6.57.25 .09.67v1.99l.83,1 .41,1 .16,1-.24.91-.5.83v.59l.08.58 .92-.37 .91.5 1.32.17 1.17-.17 1.16-.09v.5l.83.33 1,.67 .25-.58 .74.08 .34-.25 .42-.08 1.16.33 .5.59 .33-.26 .17-.5-.33-.08-.5-.49-.59-.34 .33-.5 .42-.25v-.58l-.49.17-.43.74-.16-.33 .16-.58-.33-.16-.41.24-.92-.24 1.09-.34 1,.17-.5-.47',
    },
    {
      seoId: 'maine',
      pathData:
        'm121.82,17.51-1.92-4.99 .17-.29 .33-1.08 .17-1.33-.17-1.75 .66-.58-.07-1.83 .66-.33 .74.66 .83-.58 .59-.17 .67.92 .41,1.58 .08,1.16 .08.83 .33.42 .67-.33 .25.58 .67.83 .5.17 .24.33-.41.33h-.75l-1.08,1-.16-.41-.17.5-.66-.09 .07.92-.57.58-1,1.08-.66,1-.26,1.41-.24-.54',
    },
    {
      seoId: 'maryland',
      pathData:
        'm112.1,30.86 .5.93-.16,1.49 1.98,1.33 .16-.16-.16-.42-1-.84 .92.34-.59-.58v-.75l-.16-1-.08-.91h.24l.17,1 .25.66-.08.83 .49-.16 .34.33 .58.67-.08.66-.08,1.33 .33-.33 .41-.92 .09-1 .5-1v-.45h-1.75l-.82-3.21-7,1.42 .01,1.29 2.65-1.29 .22.41 .93.66 .61.22 .58-.55',
    },
    {
      seoId: 'massachusets',
      pathData:
        'm122.06,19.01-3.16.25-1.57.15 .08,2.85 3.41-1.17 .71.22 .37.32 .83.34 .33-.34h.66l.76-.33v-.42l-.59-.67 .33.43-.16.5-.67-.09-.24-.34-.59-.66-.33-1-.17-.04',
    },
    {
      seoId: 'michigan',
      pathData:
        'm84.27,14.68 2.23.5 1.83.83 .59,1.5-.09,1.54 .42-1 .33-1.07 .34-.92 .41-.08 .49.57 .26-.57 .91-.26 .59-.5 .83-.07 1.17.51 .7-.57 1.03-.03 .33-.25-.5-.09h-.66l-.5-.41-.33-.25-.83-.08-.08-.5-.5-.25h-.26l-.9.16-.42.17-.58.25-.59.34h-.57-.83l-.59-.67h-.33l-.75.08-.25-.33 .17-.58 .49-.33v-.5l-.57.24-1,.83-.83.84-.83.49-.83.17 .5.29',
    },
    {
      seoId: 'michigan',
      pathData:
        'm95.39,26.57-3.73-.11 .49-1.16v-1.09l-.33-1.58-.5-1.25 .34-1.33 .58-1.41 .58-.83 .25.24 .58-.83 .42-.91 .5-.42 .83.09 .66.5 .84.33 .4.17 .09,1.16 .08,1-.33.74-.33.76v.66l.42.08 .16-.41 .08-.5 .59-.33 .74-.26 .26.17 .16.75 .33.58 .25.5 .25.59-.75.74-.17.75-.41.34-.16.91-.5.75 .08.5-.54-.15-2.21.26',
    },
    {
      seoId: 'minnesota',
      pathData:
        'm72.3,22.67-.08-4-.58-.66 .08-.75 .42-.66-.17-.83-.25-1.59-.17-1.41 .09-.92-.66-1.24v-1.92l1.53-.03 2.16-.09 .25-.08v-.75h.25l.25.83 .33.66 .83.42 .66.17 .59.17-.09-.61h1.88l-.5.52 .74.25 .75.25 .25.33 2.41.09 1.08-.09 .25.09-1.58,1-1.5,1.07-.66.5-.58.88 .08,1.33 .09.24-.76.67-.41.33v.59l.08.67 .17.41-.17,1.33 .33.5 1.42.42 .5.33 .33.67 .41.24v.92l.09.25-10.14-.17v-.33',
    },
    {
      seoId: 'mississippi',
      pathData:
        'm85.67,45.96-.66.5 .33.83-.74.92-.59,1-.07.91 .03,1.19 .38,1.4 .42.83-.83.58-.17.58-.59.84 .17,1.16h3.74 .41l.26,1.24 .78-.36 1.13-.34 .99.13-.24-1.83v-9.91h-1.33l-3.33.09-.09.24',
    },
    {
      seoId: 'missouri',
      pathData:
        'm87.26,41.89-.59.67v.74l-1,.17-.41-.34 .25-.41 .25-.92-9.56.42-.04-7.23-.2-.09-.66-1.23 .38-.37-.07-.38-1.98-1.68 6.89-.25 1.09-.34 .56.87 .35,1.13 .25.83 .58.76 .92,1 .99.07 .08.59-.24.5v.67l.66.83 .67.5 .74.57-.17.5-.16.17 .72.62 .41.32-.71,1.31',
    },
    {
      seoId: 'montana',
      pathData:
        'm37.32,6.78-.07,1.5 .16.83 .5.5 .41.67 .17.66 .42.41 .24.09 .26.24-.59,1.26-.17,1.24 .09.5 .33-.24 .41-.09 .26-.17 .16.92-.08.5 .49.58 .17.66 .5.5-.17.67 1.17.16 .75-.16 .58-.17h.58l.67.33 .16-1.16 13.14,1.54 .85-10.5-4.89-.48-3.9-.41-3.49-.5-3.49-.76-2.91-.49-2.57-.58-.14,1.95',
    },
    {
      seoId: 'nebraska',
      pathData:
        'm72.8,26.75 .58,3.77 .28.73 1.92,1.66-.93-.05-13.81-.54 .17-2.74-3.99-.32 .42-5.33 10.71.49 1,.74h.5l.24-.33 .66.17 1.09.75 .66.41 .5.59',
    },
    {
      seoId: 'nevada',
      pathData:
        'm32.31,40.76-4.62-6.78-3.16-5.16 2.01-7.53 11.04,2.63-2.99,15.64-.33.33-.59-.49-.5.41-.17,1.99-.69-1.04',
    },
    {
      seoId: 'new hampshire',
      pathData:
        'm122.06,19.01-.01-.91-2.15-5.58-1,.3 .34.66-.17,1.37-.41.92v1.33l.24,1.33v.82l3.16-.24',
    },
    {
      seoId: 'new jersey',
      pathData:
        'm115.5,29.66-.42-.91 .42-.5 .34-.92-.92-.34v-.66l.33-1.91-.24-.33 1.99.83 .33.29-.09,1 .59.33v1.25l-.33,1-.83,1.24-1.17-.16v-.21',
    },
    {
      seoId: 'new mexico',
      pathData:
        'm49.04,53.48-3.61-.27-.11.74-1.68-.17 2.29-14.84 12.24,1.25 .03,1.43-.85,12-8.23-.58-.08.44',
    },
    {
      seoId: 'new york',
      pathData:
        'm105.37,23.42-.09.91h.66l7.74-1.57 1.33,1.33 2.32,1.01 .07-2.88-.07-2.79-1.25-2.42-.24-2.57-1.42.12-1.24.25-.83.41-1.33,1.83 .08,1.26 .16.83-.66.24-.5.5-.91.26-1.42.07-1.74.43v.91l.5.58-.5.5-.66.75v.04',
    },
    {
      seoId: 'north carolina',
      pathData:
        'm115.91,37.48h-.49l-2.91.66-3.57.83-3.16.17-.85.13-.89.56-1.42.9-.66.57h-.58l-.33.76-.92.57-.67.34-.49.33-.34,1-.16.46 2.91-.37 .74-.67h.92l2.66-.25 .5.42 2.82-.09 2.66,1.83 .25.13 .25-.25 .67-.17v-.58l.33-.92 .74-.99 1.33-.25 .75-.59-.25-.41-.91.25 .41-.5 .09-.5-.92-.17 .5-.16 .92.25 .41-.59 .42-.66 .57.25-.16,1.17 .33-.5 .09-.67-.92-1.25-.58.09-.5.5-.75.16-.08-.42 .58.09 .75-.59 .33-.33-.08-.67-.34.13',
    },
    {
      seoId: 'north dakota',
      pathData:
        'm71.97,15.77-.25-1.59-.17-1.41 .09-.92-.66-1.24v-1.92l-2.87-.12-3.14-.12-3.19-.17-3.08-.21-.61,8.03 13.93.64-.05-.97',
    },
    {
      seoId: 'ohio',
      pathData:
        'm101.62,33.9 .92-1.49 1-.5 .58-1.09 .09-2.33-.39-4.03-.53.41-1.67,1.09-.66.41-.74.42-.26-.33h-.5l-.74.08-.66-.08-.42-.13-2.24.25 1.16,6.49v.75h.41l.59.49 1.5.34 1.07-.25 .77.15 .46.42 .26-1.07',
    },
    {
      seoId: 'oklahoma',
      pathData:
        'm58.17,40.17 17.99.62 .3,9-.59-.08-.66-.59-.91-.07-.75.24-1.25.09-.83-.09-.75.09-.5-.33h-.57l-.83.07-.34-.33-.33-.24-.83-.09-.66-.25-.59-.33-.58-.25-.92-.17 .09-5.74-6.48-.08-.01-1.47',
    },
    {
      seoId: 'oregon',
      pathData:
        'm21.92,8.82-.41.41v.92l-.83,1.25-.09.58 .16.41-.41.43-.25.5-.08.9-.42.67-.41.26 .24.41-.74.33-.16.75-.17.58 .24.17-.33.5v.75l.31.59 8,2.05 5.25,1.24 2.27-9.48 .24-1.12-1.07-.07-.83-.17-.83-.16-1.42-.08-.41.17-.75-.26-.5.09h-.74l-.76-.25-.41.08-.25-.16-.5-.17-.67.17h-.99l-.5-.83 .25-1.17-.16-.33-1.33-.42-.54.46',
    },
    {
      seoId: 'pennsylvania',
      pathData:
        'm114.84,28.58-10.14,2-.92-5.99 1.59-1.17-.09.91h.66l7.74-1.57 1.27,1.26 .3.4-.33,1.91v.66l.92.34-.34.92-.66.33',
    },
    {
      seoId: 'rhode island',
      pathData: 'm120.82,22.5v-1.41l.24.08 .5.17 .34.29-.08,1-.26.34h-.83l.09-.47',
    },
    {
      seoId: 'south carolina',
      pathData:
        'm111.68,45.63-2.66-1.83-2.82.09-.5-.42-2.66.25h-.92l-.74.67-.33.07-.33.76 .41.41 .58.09 .41.41 1.17,1.5 .92.08 1.16,1.5 .66,1 .41.58 .85-.16 .93.47-.3-.67-.31-.43h.25l.74-.17v-.5l.42-.07 .58-.26 .33-.83 .67-.24v-.5l.42-1.09 .91-.6-.25-.11',
    },
    {
      seoId: 'south dakota',
      pathData:
        'm72.3,26.16-.66-.41-1.09-.75-.66-.17-.24.33h-.5l-1-.74-10.68-.53 .62-7.79 13.79.67-.16.49-.08.75 .58.66 .08,4v.42l-.33.16 .25,2.41 .58,1.09-.5-.59',
    },
    {
      seoId: 'tennessee',
      pathData:
        'm89.09,45.63 1.39-.08 7.95-.79 .2-.46 .34-1 .49-.33 .67-.34 .92-.57 .33-.76h.58l.66-.57 2.29-1.45-1.7.28-3.49.5-5.74.67-3.23.41-.92.09-1.03.58-1.21-.42-.92,1.17 .09,1.07-.34,1.25-.55.52-.12.28 1.09.03 2.25-.08',
    },
    {
      seoId: 'texas',
      pathData:
        'm77.53,60.24-.74.5-.5-.42v-.41l-.58.33 .25.5-.17.41-.66.83-1,.59-.99.5-.84-.09-.58-.08 .16.41 .34.33-.08.34-1,.16-.5.26 .08.33-.24.33-.5.08v.42l-.26.58-.49.26-.08.41h.66l.17.5-.33.66 .24.5 .17,1-.08.66 .16.38-1.32-.38-1.42-.24-.74-.33-1.09-1-.5-1.25-.66-1.25-.25-.92-.58-.57-.41-.43-.42-.5v-.74l-.5-.59v-.66l-.67-.58-.74-.59-.59-.57-1.16-.17-1.16-.33-.83.33-.17,1.16-.41.34-.5.24-.83-.5-1.16-.91-.59-.66-.24-1.17-.76-1.08-.07-.67-1.26-.91-.16-.5-1-1.09-.92-.52 .06-.41 8.26.56 .83-11.98 6.48.08-.09,5.74 .92.17 .58.25 .59.33 .66.25 .83.09 .33.24 .34.33 .83-.07h.57l.5.33 .75-.09 .83.09 1.25-.09 .75-.24 .91.07 .66.59 .59.08 .66.25 .34,2.17v1.99l.83,1 .41,1 .16,1-.24.91-.5.83v.59l.08.58-.67.13',
    },
    {
      seoId: 'vermont',
      pathData:
        'm119.19,13.83-.12,1.02-.41.92v1.33l.24,1.33-.01.84-1.59.15-1.22-2.41-.24-2.57 3.35-.61',
    },
    {
      seoId: 'virginia',
      pathData:
        'm111.36,31.45 .74-.59 .5.93-.16,1.49 .74.67 .99,1 .5.5-.42.24h-.91l.83.5 1,.25 .67.25 .41.66-.83.13-2.91.66-3.57.83-3.16.17-2.57.42-3.49.5 .17-.33 .66-.59 .74-.24 .09-.59 .91-.83 .33.08 1.16.25 .92-.33 .91-.67 .59-.74 .33-1.26 .41-.74 .5-.59 .66.09 .17-.59 .33-.74 .76-1.09 .49-.74 1.25.91 .42.09-.16.04',
    },
    {
      seoId: 'washington',
      pathData:
        'm25.66,4.07 .08.34-.5.59-.41.57-.09.42 .09.34v.33l.58-.17 .25-.42 .41-.41 .09-.66 .08-.67h-.41l-.09-.42 .16-.33 .42-.5v-.91l.58-.17 2.83.74 1.99.67 2.91.75 1.35.37-1.23,5.75-.17.74-.26.9-1.06-.07-.83-.17-.83-.16-1.42-.08-.41.17-.75-.26-.5.09h-.74l-.76-.25-.41.08-.25-.16-.5-.17-.67.17h-.99l-.5-.83 .25-1.17-.16-.33-1.04-.35-.5-.44v-.75l.33-.84-.33-.33 .16-1.16-.41-1.5 .75-1.24 1.33,1.33 .66-.17v.41l.83.17 .09.16',
    },
    {
      seoId: 'wisconsin',
      pathData:
        'm89.92,16.06-.34.92-.33,1.07-.42,1v.42l.5-.83 .66-.75 .34-.42-.34.75-.33.75-.33,1.24 .09.84-.17,1.25v1.5l.17.83-.09.37-5.82.16-.16-.33-.69-1.14-.28-.6-.03-1.09-.41-.24-.33-.67-.5-.33-1.42-.42-.33-.5 .17-1.33-.17-.41-.08-.67v-.59l.41-.33 .76-.67-.09-.24-.08-1.33 .33-.05 .83-.07 .67-.17 .5-.09 .16-.16 .25.49 .75.17 .83-.17-.33.46 2.23.5 1.83.83 .59,1.5 1-1.45',
    },
    {
      seoId: 'wyoming',
      pathData: 'm57.02,29.24 .88-10.7-13.18-1.53-1.75,10.65 8.41,1.01 5.64.57',
    },
    {
      seoId: 'utah',
      pathData: 'm45.95,38.96-10.86-1.86 2.52-13.16 5.8,1.06-.46,2.65 4.19.51-1.19,10.8',
    },
    {
      seoId: 'west virginia',
      pathData:
        'm101.88,36.4 .42,1.09 1.48.32 .92-.33 .91-.67 .59-.74 .33-1.26 .41-.74 .5-.59 .66.09 .17-.59 .33-.74 .76-1.09 .49-.74 1.25.91 .42.09-.67-.59-1.08-.67-2.66,1.26v-1.3l-2.39.44-.46-2.4-.14,2.67-.58,1.09-1,.5-.92,1.49-.24,1 .5,1.5',
    },
  ],
};
