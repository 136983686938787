import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { DSBreadcrumbs } from '@demandstar/components/breadcrumbs';

import { scrollToTop } from 'src/utils/helpers';
import { useBreadcrumbs } from './useBreadcrumbs';

export const Breadcrumbs = () => {
  const { breadcrumbs, component, currentRoute, resetBreadcrumbInfo } = useBreadcrumbs();
  const { pathname } = useLocation();

  // Resets the breadcrumb state when the path name changes (copied from legacy implementation)
  useEffect(() => {
    resetBreadcrumbInfo();
  }, [resetBreadcrumbInfo]);

  /** Copied from legacy code, consider removing */
  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <>
      {breadcrumbs.length > 0 && (
        <DSBreadcrumbs
          breadcrumbs={breadcrumbs}
          component={component}
          layoutType={currentRoute?.layout}
        />
      )}
    </>
  );
};
