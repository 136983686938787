import styled from 'styled-components';

import { BorderColor, BorderThickness, Margin, Padding } from '@demandstar/components/styles';

export const AddContractsSection = styled.div`
  border-top: ${BorderThickness.Base} solid ${BorderColor.Highlight};
  padding-top: ${Padding.Extra};

  button {
    flex-grow: 1;
    max-width: 30rem;
  }

  p {
    margin-bottom: ${Margin.Extra};
  }
`;
