import { useState } from 'react';

import {
  AccordionCard,
  AccordionCardContent,
  AccordionCardHeader,
  AccordionCardStatus,
} from './DSAccordionCard.styles';
import { capitalize } from '../utils';
import { DataTestId } from '../types';
import { Flex } from '../styles';
import { Status } from '../constants';

export type DSAccordionCardProps = DataTestId & {
  /** The content of the accordion, only visible when `isOpen` is set to `true` */
  children: React.ReactNode;
  /** Whether the compact version of the accordion card should be used, which moves the
   * `status` component to the top of the card header. */
  compact?: boolean;
  /** The title to display in the accordion header, always visible */
  header: string;
  /** Whether the card is open & content should be visible
   * if `true` card content will always be visible */
  isOpen?: boolean;
  /** Handling function for clicking the header, with `isOpen` allows for custom control
   * of the accordion state */
  onClick?: () => unknown;
  /** Optional status to control the color of a small banner displayed on the
   * top or right side of the header bar. Invisible if `status` and `statusMessage` are
   * not provided. Defaults to a gray display if `statusMessage` is provided  */
  status?: Status;
  /** Optional status message. If undefined & `status` is defined, the `status` name is used
   * as the message. e.g. Status.Success => "Success" */
  statusMessage?: string;
  /** When true, toggles card content visibility when header is clicked,
   * otherwise visibility is controlled by `isOpen`
   * @default true */
  useDefaultBehavior?: boolean;
};

export const DSAccordionCard = ({
  children,
  compact = false,
  dataTestId = 'ds-accordion-card',
  header,
  isOpen,
  onClick,
  status,
  statusMessage,
  useDefaultBehavior = true,
}: DSAccordionCardProps) => {
  const [cardOpen, setCardOpen] = useState(false);

  const isCardOpen = cardOpen || isOpen;

  const handleClick = () => {
    if (useDefaultBehavior) setCardOpen(!cardOpen);

    if (onClick) onClick();
  };

  return (
    <AccordionCard data-testid={dataTestId}>
      <AccordionCardHeader
        data-testid={`${dataTestId}-header`}
        justifyContent='space-between'
        onClick={handleClick}
        compact={compact}
      >
        <Flex grow={1}>
          <i
            data-testid={`${dataTestId}-header-icon`}
            className={`mdi mdi-chevron-${isCardOpen ? 'down' : 'right'}`}
          ></i>
          {header}
        </Flex>
        {(status || statusMessage) && (
          <AccordionCardStatus
            data-testid={`${dataTestId}-header-status`}
            status={status}
            grow={0}
            compact={compact}
          >
            {statusMessage || capitalize(status?.toString() || '')}
          </AccordionCardStatus>
        )}
      </AccordionCardHeader>
      {isCardOpen && (
        <AccordionCardContent data-testid={`${dataTestId}-content`}>
          {children}
        </AccordionCardContent>
      )}
    </AccordionCard>
  );
};
