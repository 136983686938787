import { smoothScrollTo } from '@demandstar/components/utils';

import * as constants from './BrowseBids.constants';

/** Utility functions - state-independent, used only once */
export function scrollToGeo() {
  smoothScrollTo(`#${constants.geoCodeElementId}`, constants.staticHeaderClassName);
}

export function scrollToCommodityCodes() {
  smoothScrollTo(`#${constants.geoCodeElementId}`, constants.staticHeaderClassName);
}

export function scrollToBidList() {
  smoothScrollTo(`#${constants.bidListTitleElementId}`, constants.staticHeaderClassName);
}

export function scrollToHeaderTitle() {
  smoothScrollTo(`#${constants.headerTitleElementId}`, constants.staticHeaderClassName);
}
