import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Flex, FlexContainer, FontWeight, H5, HeaderFontSize } from '@demandstar/components/styles';
import { AlertContainer } from '@demandstar/components/alert/DSAlert.styles';
import { DSAlert } from '@demandstar/components/alert';
import { DSLink } from '@demandstar/components/link';
import { Status } from '@demandstar/components/constants';

import * as texts from './CMDashboard.texts';

import { newContractIdState, useContractDetails } from '../../useContractDetails';

import { ContractLimitAlert } from '../components/ContractLimitAlert';
import { paths } from '../../ContractManagement.paths';

const StyledDismissAlertIcon = styled.i`
  font-size: ${HeaderFontSize.H4};
  font-weight: ${FontWeight.Header};
  cursor: pointer;
`;

const ContractAddedAlertContainer = styled(FlexContainer)`
  > ${AlertContainer} {
    margin-top: 0;
  }
`;

export const ContractAddedAlert = () => {
  const [newContractId, setNewContractId] = useRecoilState(newContractIdState);
  const history = useHistory();
  const { contractDetails } = useContractDetails(newContractId);

  const dismissAlert = () => {
    setNewContractId(undefined);
  };

  const proceedToNewContract = () => {
    setNewContractId(undefined);
    history.push(paths.navigation.contractDetails(newContractId));
  };

  // const addReminders = () => {
  //   // TODO: Add link to add reminders for this contract
  // };

  return (
    <ContractAddedAlertContainer>
      {newContractId ? (
        <>
          <DSAlert
            dataTestId='contractAddedAlert'
            // onClick={addReminders}
            header={''}
            type={Status.Success}
            link={
              <DSLink to={paths.navigation.reminders(contractDetails?.id, { addReminder: true })}>
                {texts.scheduleReminders}
              </DSLink>
            }
          >
            <FlexContainer justifyContent='space-between'>
              <Flex grow={5}>
                <H5>
                  <DSLink large onClick={proceedToNewContract}>
                    {contractDetails?.name ?? ''}
                  </DSLink>
                  {texts.contractAdded}
                </H5>
              </Flex>
              <Flex grow={0}>
                <StyledDismissAlertIcon
                  className='mdi mdi-close'
                  data-testid='dismiss.contractAddedAlert'
                  onClick={dismissAlert}
                />
              </Flex>
            </FlexContainer>
          </DSAlert>
          <ContractLimitAlert />
        </>
      ) : null}
    </ContractAddedAlertContainer>
  );
};
