import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DSCheckbox, DSDatePicker, DSTextArea } from '@demandstar/components/inputs';
import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { cardNonceType, cardParamType } from '../../../types/subscriptions';
import { displayDate, toastFn } from '../../../utils/helpers';

import Braintree from '../../common/braintree';

import { commonLabels } from 'src/shared/constants';

import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useSubscription } from 'src/shared/hooks/useSubscription';

interface ModifySubscriptionProps {
  expiryDate: dayjs.Dayjs;
  toggleModifySubscription: () => void;
  card: cardParamType;
}

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  margin-bottom: 2rem;
  width: 100%;

  .ant-picker {
    width: 100%;
  }
`;

export const ModifySubscriptionContent = ({
  expiryDate,
  toggleModifySubscription,
  card,
}: ModifySubscriptionProps) => {
  const { extendSubscription, previewSubscriptionExtension } = useSubscription();
  const { refreshAccountInfo } = useAccountInfo();
  const [newSubscriptionAmount, setNewSubscriptionAmount] = useState(0);

  const [cardModal, setChangeCardModalStatus] = useState(false);
  const [cardNonce, setCardNonce] = useState({} as cardNonceType);
  const [notes, setNotes] = useState('');
  const [isFreeCharge, setFreeCharge] = useState(false);
  const [newExpiryDate, setNewExpiryDate] = useState(expiryDate);

  const handeChangeCardClick = useCallback(() => {
    setChangeCardModalStatus(!cardModal);
  }, [cardModal]);

  const onCardSubmit = useCallback(
    (card: cardNonceType) => {
      setCardNonce(card);
      setChangeCardModalStatus(!cardModal);
    },
    [cardModal],
  );

  const onExpirationDateChange = useCallback(
    (value: dayjs.Dayjs | null) => {
      setNewExpiryDate(dayjs(value));
      if (newSubscriptionAmount && newSubscriptionAmount > 0) {
        setNewSubscriptionAmount(0);
      }
    },
    [newSubscriptionAmount],
  );

  const handleModifySubscription = useCallback(async () => {
    const payload = {
      expirationDate: newExpiryDate,
      notes: notes,
      nonce: cardNonce && cardNonce.nonce && cardNonce.nonce.length > 0 ? cardNonce.nonce : '',
    };
    if (notes && notes.length > 0) {
      await extendSubscription(payload, isFreeCharge);
      await refreshAccountInfo();
      toggleModifySubscription();
      setNotes('');
      setFreeCharge(false);
      setNewExpiryDate(expiryDate);
    } else {
      toastFn('error', 'Please enter sales notes', 'approvetoastid');
    }
  }, [
    cardNonce,
    expiryDate,
    extendSubscription,
    isFreeCharge,
    newExpiryDate,
    notes,
    refreshAccountInfo,
    toggleModifySubscription,
  ]);

  const handleGetNewSubscriptionAmount = useCallback(async () => {
    const payload = {
      subscriptionRule: 'Modify',
      accountingRule: 'Charge',
      expirationDate: newExpiryDate,
      isreadonly: true,
      existingExpiryDate: expiryDate,
    };
    await previewSubscriptionExtension(payload, (amount: number) =>
      setNewSubscriptionAmount(amount),
    );
  }, [expiryDate, previewSubscriptionExtension, newExpiryDate]);

  const getCardMessage = useCallback(() => {
    const { details, nonce } = cardNonce || {};
    const { lastFour, cardType } = details || {};
    return `The balance amount ${newSubscriptionAmount} will be paid through ${
      nonce && nonce.length > 0 ? cardType : card.cardType
    } card ending
	${nonce && nonce.length > 0 ? lastFour : card.cardLastFour}`;
  }, [card.cardLastFour, card.cardType, cardNonce, newSubscriptionAmount]);

  const validDateCheck = useCallback(
    (date: dayjs.Dayjs) => {
      return (
        date && (date <= expiryDate.endOf('day') || date > expiryDate.add(1, 'year').endOf('day'))
      );
    },
    [expiryDate],
  );

  return (
    <>
      <div className='mb-2'>
        <label>Current Expiration Date : {displayDate(expiryDate)}</label>
      </div>

      <DatePickerWrapper>
        <DSDatePicker
          allowClear={false}
          disabledDate={validDateCheck}
          label='New Expiration Date'
          name='newExpiryDate'
          onChange={onExpirationDateChange}
          value={newExpiryDate}
        />
      </DatePickerWrapper>

      {newSubscriptionAmount >= 1 && (
        <>
          <div className='mb-1'>
            <label className='text-danger'>{getCardMessage()}</label>
            <DSButton type={'secondary'} onClick={handeChangeCardClick}>
              Change
            </DSButton>
          </div>
          <div className='mb-4'>
            <DSCheckbox
              label='Make it complimentary'
              onClick={() => setFreeCharge(!isFreeCharge)}
              checked={isFreeCharge}
              name={'isFreeCharge'}
            />
          </div>
          <div className='mb-3'>
            <DSTextArea label='Sales Note' value={notes} onChange={setNotes} name={'salesNote'} />
          </div>
        </>
      )}

      <FlexContainer justifyContent={'space-between'}>
        <DSButton type='secondary' onClick={toggleModifySubscription}>
          {commonLabels.cancel}
        </DSButton>

        {newSubscriptionAmount && newSubscriptionAmount >= 1 ? (
          <DSButton onClick={handleModifySubscription} inactive={newExpiryDate === expiryDate}>
            Place Order
          </DSButton>
        ) : (
          <DSButton onClick={handleGetNewSubscriptionAmount}>Modify</DSButton>
        )}
      </FlexContainer>

      <ModalPopUp
        title={'Payment Method'}
        size='md'
        closeModal={handeChangeCardClick}
        isOpen={cardModal}
      >
        <Braintree
          onSubmitPayment={onCardSubmit}
          cancelClick={handeChangeCardClick}
          buttonname='Accept'
          title='New Card Info'
        />
      </ModalPopUp>
    </>
  );
};
