import { useEffect } from 'react';

import { DSJumpTo, JumpToSection } from '@demandstar/components/jump-to';
import { Flex, FlexContainer, FlexContainerColumn, H2, H3 } from '@demandstar/components/styles';
import { DSLinkButton } from '@demandstar/components/button';

import * as texts from './ContractDetails.texts';
import { DetailsSection, UnderlinedSection } from '../../ContractManagement.styles';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';
import { cmButtonTexts } from '../../ContractManagement.texts';
import { CMTabNavigation } from '../../navigation/CMTabNavigation';
import { ContractAwardeeTable } from '../wizard/contract-awardee-details/ContractAwardeeTable';
import { ContractDetailsSummary } from '../wizard/review-your-contract/ContractDetailsSummary';
import { ContractDocumentsTable } from '../documents/ContractDocumentsTable';
import { ContractStatusPill } from '../../contract-status-pill/ContractStatusPill';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { paths } from '../../ContractManagement.paths';
import { Reminders } from '../reminders/Reminders';
import { useBreadcrumbs } from 'src/components/common/breadcrumb';
import { useContractDetails } from '../../useContractDetails';
import { useContractDocuments } from '../documents/useContractDocuments';

// const ContractNameInAlert = styled('span')`
//   display: inline-block;
//   margin-right: ${Margin.Base};
//   width: 15rem;
//   vertical-align: top;
// `;

export const ContractDetails = () => {
  const { setBreadcrumbInfo } = useBreadcrumbs();
  const { contractDetails, contractDetailsLoadable } = useContractDetails();
  const { contractDocuments, contractDocumentsLoadable } = useContractDocuments();

  const jumpToSections: JumpToSection[] = [
    {
      items: [
        {
          display: texts.jumpToLabels.contractDetails,
          scrollTo: '#details',
        },
        {
          display: texts.jumpToLabels.supplierDetails,
          scrollTo: '#suppliers',
        },
        {
          display: texts.jumpToLabels.documents,
          scrollTo: '#documents',
        },
      ],
    },
    {
      items: [
        {
          display: texts.jumpToLabels.uploadDocument,
          href: paths.navigation.documents(contractDetails?.id, 'upload'),
        },
        {
          display: texts.jumpToLabels.setReminder,
          href: paths.navigation.reminders(contractDetails?.id, { addReminder: true }),
        },
      ],
    },
  ];

  // TODO: Implement with Document Requests and/or Reminders post-MVP (probably as a separate component)
  // const requestDocuments = () =>
  //   history.push(paths.navigation.contractDocuments(contractDetails?.id || '', 'request'));
  // const uploadDocument = () =>
  //   history.push(paths.navigation.contractDocuments(contractDetails?.id || '', 'upload'));

  // const contractsWithRequestedDocuments = contractDocuments.filter((doc: ContractDocument) => {
  //   return !doc.title;
  // });

  // let alertsMessage;
  // if (contractsWithRequestedDocuments.length > 0) {
  //   alertsMessage = contractsWithRequestedDocuments.map((contract, index) => {
  //     if (index < 3) {
  //       return (
  //         <li key={index}>
  //           <ContractNameInAlert>{contract.type}</ContractNameInAlert>
  //           {/* TODO: Uncomment & updated when Reminders is ready
  //           <DSLink onClick={() => console.log('go to reminders page')}>
  //             Set a reminder
  //           </DSLink> |  */}
  //           <DSLink onClick={requestDocuments}>Request document</DSLink> |{' '}
  //           <DSLink onClick={uploadDocument}>Upload</DSLink>
  //         </li>
  //       );
  //     }
  //   });
  // }

  // const documentsAlert = {
  //   // TODO: this needs the filter applied
  //   handleClick: () => history.push(paths.navigation.contractDocuments(contractDetails?.id || '')),
  //   header:
  //     'This contract is missing ' + contractsWithRequestedDocuments.length + ' requested documents',
  //   linkText: 'See all missing requested documents',
  //   message: <ul>{alertsMessage}</ul>,
  // };

  useEffect(() => {
    setBreadcrumbInfo({
      activePageLabel: (contractDetails?.solicitationId || contractDetails?.name) ?? '',
    });
  }, [contractDetails?.name, contractDetails?.solicitationId, setBreadcrumbInfo]);

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      <CMTabNavigation />
      <Reminders preview />
      <LoadableWrapper loadable={contractDetailsLoadable}>
        {contractDetails && (
          <>
            <H2>{contractDetails?.name}</H2>
            <FlexContainer>
              <DSJumpTo sections={jumpToSections} />
              <Flex grow={2}>
                <FlexContainerColumn>
                  <UnderlinedSection>
                    {/** Contract Details */}
                    <H3 id='details' style={{ alignItems: 'center', display: 'flex' }}>
                      <label className='flex1'>{texts.contractDetails}</label>
                      <DSLinkButton to={paths.navigation.editDraft(contractDetails?.id)}>
                        {cmButtonTexts.editContractDetails}
                      </DSLinkButton>
                    </H3>
                    <ContractStatusPill contractId={contractDetails.id} />
                    <ContractDetailsSummary />
                  </UnderlinedSection>
                  <DetailsSection>
                    <H3 id='suppliers'>{texts.supplierDetails}</H3>
                    <ContractAwardeeTable />
                  </DetailsSection>
                  <Flex style={{ width: '100%' }}>
                    <LoadableWrapper loadable={contractDocumentsLoadable}>
                      <H3 id='documents'>{texts.contractDocuments}</H3>
                      {/* {contractsWithRequestedDocuments.length > 0 && (
                        <DSAlert
                          dataTestId='documents-alert'
                          handleClick={documentsAlert.handleClick}
                          header={documentsAlert.header}
                          linkText={documentsAlert.linkText}
                          message={documentsAlert.message}
                          type={Status.Warning}
                        />
                      )} */}

                      <ContractDocumentsTable maxItems={5} data={contractDocuments} />
                    </LoadableWrapper>
                  </Flex>
                  <FlexContainer justifyContent='end'>
                    <DSLinkButton to={paths.navigation.documents(contractDetails?.id)}>
                      {cmButtonTexts.viewContractDocuments}
                    </DSLinkButton>
                  </FlexContainer>
                </FlexContainerColumn>
              </Flex>
            </FlexContainer>
          </>
        )}
      </LoadableWrapper>
    </Feature>
  );
};
