import { useDispatch } from 'react-redux';

import { Assert, scrollToTop, setWizardStatus } from '../../../utils/helpers';
import {
  useSelectedBidId,
  useSetSelectedBidIdFromRoute,
} from '../../../shared/hooks/useSelectedBidId';
import { setAddBidDetails } from '../../../store/actions/addbid';
import { useDSSelector } from '../../../store/reducers';
import { useHistory } from 'react-router-dom';
import { WizardButtons } from 'src/components/customcontrols/wizard/WizardButtons';
import { WizardPage } from '../../../types/wizard';

interface AddBidWizardButtonsProps {
  currentWizard: WizardPage;
  submit: (() => void) | false;
}

export const AddBidWizardButtons = (props: AddBidWizardButtonsProps) => {
  useSetSelectedBidIdFromRoute();

  const dispatch = useDispatch();

  const history = useHistory();
  const { selectedBidId } = useSelectedBidId();

  const { currentWizard, submit } = props;

  const addBidWizard = useDSSelector(state => state.addbid.addbidwizard);

  /** Ideally, we can remove this with proper type-safety.
   * However, for now, we have some tests with some possible bad data.
   */
  Assert(currentWizard, 'We must be passed a currentWizard', 'AddBidWizardButtons.tsx');
  if (!currentWizard) return <></>;

  const cancel = () => {
    if (history) {
      if (selectedBidId) {
        history.push(`/buyers/bids/${selectedBidId}`);
      } else {
        history.goBack();
      }
    }
  };

  const isFinal = currentWizard.id >= addBidWizard.length;

  function onPrevious() {
    if (currentWizard && currentWizard.id > 1) {
      scrollToTop();
      dispatch(
        setAddBidDetails({
          addbidwizard: setWizardStatus(addBidWizard, currentWizard.id - 1 || 1),
        }),
      );
    } else {
      cancel();
    }
  }
  function saveAndFinishLater() {
    dispatch(setAddBidDetails({ addbidwizardpartial: true }));
    if (submit) submit();
  }

  function onNext() {
    if (isFinal) {
      cancel();
    } else {
      Assert(submit, 'Unless this is the final step, we must have a submit passed');
      submit();
    }
  }

  return (
    <WizardButtons
      altPrev={currentWizard.id > 1 ? 'Previous' : 'Cancel'}
      onPrevious={onPrevious}
      altNext={isFinal ? 'Review Details' : 'Next'}
      save={saveAndFinishLater}
      onNext={onNext}
    />
  );
};
