import { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import NoResult from '../../../customcontrols/noresult';
import SortingIcon from '../../../common/sortingIcon';

import { Buttons, DeprecatedRadio } from '../../../customcontrols';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { sortDirections } from '../../../../utils/constants';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface PropsTypes {
  memberinfo?: any;
  accountinfo?: any;
  setAccountInfoDetails?: any;
  shared?: any;
}

const TemplateList = (props: PropsTypes) => {
  const { accountinfo, setAccountInfoDetails, memberinfo, shared } = props;

  const { qintro = '', qins = '', qclose = '' } = memberinfo;
  const { internalLoader = false } = shared;

  let initialData = {
    legalIntro: '',
    legalCostInfo: '',
    legalDueDateTime: '',
    legalAdditional: '',
    legalClosing: '',
    quoteIntro: '',
    quoteClose: '',
    quoteInsurance: '',
  };

  const { templateList = [], templateTypeModal = false, selectedTemplate = 0 } = accountinfo;

  useSetBreadcrumb({
    component: (
      <li title='Add Template'>
        <span
          className='staticLink addBid'
          onClick={(event: any) => {
            event.preventDefault();
            setAccountInfoDetails({ templateTypeModal: true });
          }}
        >
          <i className='mdi mdi-plus mr-1' /> Add Template
        </span>
      </li>
    ),
    page: 'Agency Templates',
    title: 'Templates',
    name: 'Templates',
    menuactive: '',
  });

  const [sortvalue, setSortvalue] = useState('templateName');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  useEffect(() => {
    return () => {
      setSortvalue('templateName');
      setSortorder(sortDirections.ASC);
    };
  }, []);

  const editExistingTemplate = (data: any) => {
    const fields: any = { ...data, quoteIntro: qintro, quoteClose: qclose, quoteInsurance: qins };
    setAccountInfoDetails({ selectedTemplateItem: fields, addTemplateModal: true, updateType: 1 });
  };

  const useSelectedTemplate = () => {
    templateList.map((item: any) => {
      if (item.templateId === parseInt(selectedTemplate)) {
        initialData = { ...item, quoteIntro: qintro, quoteClose: qclose, quoteInsurance: qins };
      }
      return item;
    });
    setAccountInfoDetails({
      selectedTemplateItem: initialData,
      templateTypeModal: false,
      addTemplateModal: true,
      updateType: 1,
    });
  };

  const useNewTemplate = () => {
    setAccountInfoDetails({
      selectedTemplateItem: initialData,
      selectedTemplate: 0,
      templateTypeModal: false,
      addTemplateModal: true,
      updateType: 1,
    });
  };

  const LoadtemplateComponent = (props: PropsTypes) => {
    return (
      <>
        {templateList.map((item: any, index: number) => (
          <DeprecatedRadio
            name='selectedTemplate'
            key={index}
            title={item.templateName}
            value={item.templateId}
            handleSelect={(name: any, value: any) =>
              setAccountInfoDetails({ selectedTemplate: value })
            }
            defaultChecked={parseInt(selectedTemplate) === item.templateId}
          />
        ))}
        <div className='d-block mt-3'>
          <Buttons
            text='Skip'
            title='Skip'
            type='button'
            classNames='bttn bttn-secondary float-left'
            onClick={useNewTemplate}
          />
          <Buttons
            text='Use Selected'
            title='Use Selected'
            onClick={useSelectedTemplate}
            classNames='bttn bttn-primary float-right'
            disable={!selectedTemplate}
            type='button'
          />
        </div>
      </>
    );
  };

  return (
    <>
      <h1 className='arrowWrapper'>Templates</h1>
      <div className='col-md-7'>
        {templateList && templateList.length > 0 ? (
          <Table className='tableHData'>
            <tbody>
              <tr>
                <th>
                  Template Name
                  <SortingIcon
                    sortlist={templateList}
                    initialsortorder={sortorder}
                    currentsortname={sortvalue}
                    changesortname={(value: any) => setSortvalue(value)}
                    sortname='supplierName'
                    changeSortingFn={(list: any) => setAccountInfoDetails({ templateList: list })}
                  />
                </th>
                <th></th>
              </tr>
              {templateList.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.templateName}</td>
                  <td>
                    <i
                      className='mdi mdi-square-edit-outline staticLink mdi-24px'
                      title='Edit'
                      onClick={() => editExistingTemplate(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoResult message={internalLoader ? loadingMsg : 'No Templates Available'} />
        )}

        <Buttons
          text='Add Template'
          title='Add Template'
          onClick={() => setAccountInfoDetails({ templateTypeModal: true })}
          classNames='bttn bttn-primary'
          type='button'
        />
      </div>
      <ModalPopUp
        title='Choose Template Type'
        size='md'
        isOpen={templateTypeModal}
        closeModal={() => setAccountInfoDetails({ templateTypeModal: !templateTypeModal })}
      >
        <LoadtemplateComponent />
      </ModalPopUp>
    </>
  );
};

export default memo(TemplateList);
