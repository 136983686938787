import { AxiosRequestConfig } from 'axios';

import { AccountInfoState, ProfileInfoState } from '../recoil/accountInfoState';
import Api, { axiosPostAuthenticated, axiosRequestAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';
import { Product } from 'src/types/subscriptions';
import { CardInfoRequest } from 'src/types/accountinfo';

export function getAccountInfo() {
  return Api.getRequestWithAuthentication(Paths.accountinfo, { baseURL: constants.api.userUrl });
}

export function getAcctInfo(): Promise<AccountInfoState | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.userUrl,
    data: {},
    method: 'GET',
    url: Paths.accountinfo,
  };
  return axiosRequestAuthenticated<AccountInfoState | null>(requestConfig);
}

export function getOtherAccountInfo() {
  return Api.getRequestWithAuthentication(Paths.getaccountInfo);
}

export function getProfileInfo(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(
    Paths.getProfileInfo,
    data,
    { baseURL: constants.api.subscriptionUrl },
  );
}

export function getProfile(): Promise<ProfileInfoState | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.subscriptionUrl,
    data: {},
    url: Paths.getProfileInfo,
  };
  return axiosPostAuthenticated<ProfileInfoState | null>(requestConfig);
}

export function addUpdateUserInfo(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.addUpdateUserAccount, data, {
    baseURL: constants.api.userUrl,
  });
}

export function forgotPassword(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.forgotPassword, data, {
    baseURL: constants.api.userUrl,
  });
}

export function saveSelfDeclarations(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.saveSelfDeclarations, data, {
    baseURL: constants.api.userUrl,
  });
}

export function searchPrograms(data: {
  memberId: number;
  buyerName: string;
  certificationStatus: string;
  statusChangedSince: string;
  expiresAfter: string;
  attribMatch: string;
}) {
  return Api.getRequestWithAuthentication(
    `${Paths.searchPrograms}
	?memberId=${data.memberId}&
	buyerName=${data.buyerName}&
	certificationStatus=${data.certificationStatus}&
	statusChangeDate=${data.statusChangedSince}&
	expirationDate=${data.expiresAfter}&
	attribMatch=${data.attribMatch}`,
    {
      baseURL: constants.api.userUrl,
    },
  );
}

export function addMemberProgramCertification(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.addMemberProgramCertification, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getProgramAttributes(programId: number) {
  return Api.getRequestWithAuthentication(`${Paths.getProgramAttributes}?programId=${programId}`, {
    baseURL: constants.api.userUrl,
  });
}

export function updateAutoRenewalSubscription(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.autoRenewSwitch, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function updateSubscriptionAutoRenewal(data: { state: boolean }): Promise<string | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.subscriptionUrl,
    data: data,
    url: Paths.autoRenewSwitch,
  };
  return axiosPostAuthenticated<string | null>(requestConfig);
}

export function getUserAccounts(memberId: number) {
  return Api.getRequestWithAuthentication(`${Paths.getUserAccounts}?memberId=${memberId}`, {
    baseURL: constants.api.userUrl,
  });
}

export function getPermissions() {
  return Api.getRequestWithAuthentication(`${Paths.getPermissions}`, {
    baseURL: constants.api.userUrl,
  });
}

export function reactivateUserAccount(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.reactivateUserAccount, data, {
    baseURL: constants.api.userUrl,
  });
}
export function unblockUserAccount(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.unblockUserAccount, data, {
    baseURL: constants.api.userUrl,
  });
}

export function changePrimaryContact(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.changePrimaryContact, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function getaccountinfopublications(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.accountpublications, data, {
    baseURL: constants.api.url,
  });
}

export function submitaccountinfopublicationsadd(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.accountpublicationsadd, data, {
    baseURL: constants.api.url,
  });
}

export function submitaccountinfopublicationsremove(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.accountpublicationsremove, data, {
    baseURL: constants.api.url,
  });
}

export function getaccountinfobidtypes(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.BidTypes, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitaccountinfobidtypesadd(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.BidTypesAdd, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitaccountinfobidtypesremove(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.BidTypesRemove, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getLegalTemplatesList() {
  return Api.getRequestWithAuthentication(Paths.GetLegalTemplates, {
    baseURL: constants.api.userUrl,
  });
}

export function getaccountinforequiredDocs(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.requiredDocs, data, {
    baseURL: constants.api.url,
  });
}

export function submitaccountinforequiredDocsUpdate(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.requiredDocsUpdate, data, {
    baseURL: constants.api.url,
  });
}

export function updateNewLegalTemplate(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.legalUpdate, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getSavedLegal(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.savedLegal, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getOrderInfo(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.orderinfo, data, { baseURL: constants.api.url });
}

export function getOrders(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.orders, data, { baseURL: constants.api.url });
}

export function submitRemoveCard(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.removeCard, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function getPlanholderTypes(data = {}) {
  return Api.getRequestWithAuthentication(Paths.planholderTypes, {
    baseURL: constants.api.userUrl,
  });
}

export function operationsAccountUpdate(data = {}) {
  return Api.postRequestWithAuthentication(Paths.operationUpdate, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getDocAutoApprovalStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getDocAutoApprovalStatus, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getToggleDocAutoApproval(data = {}) {
  return Api.postRequestWithAuthentication(Paths.toggleDocAutoApproval, data, {
    baseURL: constants.api.userUrl,
  });
}
export function getAgencyStates(data = {}) {
  return Api.getRequestWithAuthentication(Paths.agencyStates, { baseURL: constants.api.userUrl });
}
export function getAgencyCounties(data = {} as { stateId: number }) {
  return Api.getRequestWithAuthentication(`${Paths.agencyCounties}?stateId=${data.stateId}`, {
    baseURL: constants.api.userUrl,
  });
}
export function getFreeAgencies(
  data = {} as {
    autosuggestText: string;
    state: { value: number } | undefined;
    county: { value: number } | undefined;
  },
) {
  const queryParam = [];
  if (data.autosuggestText) {
    queryParam.push(`autosuggestText=${data.autosuggestText}`);
  }
  if (data.state) {
    queryParam.push(`stateId=${data.state.value}`);
  }
  if (data.county) {
    queryParam.push(`countyId=${data.county.value}`);
  }
  const queryParamString = queryParam.join('&');

  return Api.getRequestWithAuthentication(`${Paths.freeAgencies}?${queryParamString}`, {
    baseURL: constants.api.userUrl,
  });
}

export function updateSupplierFreeAgencies(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateSupplierFreeAgencies, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function updateSubscriptionFreeAgencies(data: {
  products: string;
  pageFor: string;
}): Promise<string | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.subscriptionUrl,
    data: data,
    url: Paths.updateSupplierFreeAgencies,
  };
  return axiosPostAuthenticated<string | null>(requestConfig);
}

export function modifySubscription(data = {}) {
  return Api.postRequestWithAuthentication(Paths.modifysubscription, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function modifySupplierSubscription(data: {
  subscriptionRule: string;
  accountingRule: string;
  existingExpiryDate?: Date;
  expirationDate: Date;
  isreadonly: boolean;
  notes?: string;
  nonce?: string;
}): Promise<{
  SubscriptionAmount: number;
} | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.subscriptionUrl,
    data: data,
    url: Paths.modifysubscription,
  };
  return axiosPostAuthenticated<{
    SubscriptionAmount: number;
  } | null>(requestConfig);
}

export function cancelSubscription(data = {}) {
  return Api.postRequestWithAuthentication(Paths.cancelSubscription, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

export function cancelSupplierSubscription(): Promise<{
  status: string;
  message: string;
} | null> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.subscriptionUrl,
    data: {},
    url: Paths.cancelSubscription,
  };
  return axiosPostAuthenticated<{
    status: string;
    message: string;
  } | null>(requestConfig);
}

export function toggleSuppDocDownload(data = {}) {
  return Api.postRequestWithAuthentication(Paths.toggleSuppDocDownload, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getSuppDocDownloadStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getSuppDocDownloadStatus, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getExpiredSubscriptionProducts() {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.userUrl,
    method: 'GET',
    url: Paths.getExpiredSubscriptionProducts,
  };
  return axiosRequestAuthenticated<Product[] | null>(requestConfig);
}

export function getCardInfoAndTransactions(data: CardInfoRequest) {
  return Api.postRequestWithAuthentication(Paths.getCardInfoAndTransactions, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}
