import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { AwardeeDraft, useBidAwardees } from 'src/store/recoil/bid-awardees';
import { defaultNumericId } from '../../../shared/constants';
import { generateSelectOptions } from '../../../utils/helpers';
import { PanelText } from '../../../shared/styles';
import { Selectable } from '../../../types/common';
import { SelectBox } from '../../customcontrols';
import { selectedBidAwardeeState } from '../../../store/recoil/award-bid';
import { useResetOnUnmount } from '../../../shared/hooks/useResetOnUnmount';
import { useSupplierMemberId } from '../../../shared/hooks/useMemberInfo';
import { vendorDocLabels } from '../../buyer/awardbid/add-vendor-docs/constants';

export function BidAwardeeSelect() {
  /// Application state

  const { bidAwardees } = useBidAwardees();
  const [selectedBidAwardee, setSelectedBidAwardee] = useRecoilState(selectedBidAwardeeState);
  const { setSupplierMemberId, supplierMemberId } = useSupplierMemberId();

  // Awardee select vars
  const awardeeOptions = generateSelectOptions<AwardeeDraft>(
    bidAwardees,
    'supplierName',
    'awardedToMemberId',
  );

  const selectedAwardeeOption = {
    label: selectedBidAwardee?.supplierName || '',
    value: selectedBidAwardee?.awardedToMemberId,
  };

  /**
   * Select a default `selectedBidAwardee` and `selectedMemberId`.
   */
  useEffect(() => {
    if (!selectedBidAwardee && bidAwardees[0]) {
      setSelectedBidAwardee(bidAwardees[0]);
      setSupplierMemberId(bidAwardees[0].awardedToMemberId);
    }
  }, [
    bidAwardees,
    selectedBidAwardee,
    setSelectedBidAwardee,
    setSupplierMemberId,
    supplierMemberId,
  ]);

  // Reset `selectedBidAwardee` on unmount.
  useResetOnUnmount(selectedBidAwardeeState);

  function handleAwardeeSelection(name: string, value: Selectable<number>) {
    const selectedAwardee =
      bidAwardees.find(awardee => awardee.awardedToMemberId === value.value) || selectedBidAwardee;
    setSelectedBidAwardee(selectedAwardee);
    setSupplierMemberId(selectedAwardee?.awardedToMemberId || defaultNumericId);
  }

  if (bidAwardees.length > 1) {
    return (
      <>
        <PanelText>{vendorDocLabels.documentListDescription}</PanelText>
        <SelectBox
          handleSelect={handleAwardeeSelection}
          label='Awardee'
          name='awardedToMemberId'
          options={awardeeOptions}
          reactselect
          value={selectedAwardeeOption}
        />
      </>
    );
  }
  return <></>;
}
