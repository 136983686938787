import * as actionTypes from '../actionTypes';
import { AddBidInfo, BidDocument, updateBidDocumentTitleType } from '../../types/addbid';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';
import { AddBidState } from '../reducers/addbid';
import { Authorization } from 'src/types';

interface BidIdPayload {
  bidId: number;
  auth?: Authorization;
}

export type SetAddBidDetailsPayload<T = BidDocument> = Partial<AddBidState<T>>;

export function setAddBidDetails<T>(payload: SetAddBidDetailsPayload<T>) {
  return action(actionTypes.SET_ADD_BID_DETAILS, payload);
}

export function getAddBidBidTypes(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_BID_TYPES, payload);
}

export function getAddBidBidWriters(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_BID_WRITERS, payload);
}

export interface SubmitAddBidInfoPayload {
  data: AddBidInfo;
}

export function submitAddBidInfo(payload: SubmitAddBidInfoPayload) {
  return action(actionTypes.SUBMIT_ADD_BID_INFO, payload);
}

export function setAddBidInitialData(payload: BidIdPayload) {
  return action(actionTypes.SET_ADD_BID_INITIAL_DATA, payload);
}

export function getAddBidResponseItems(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_RESPONSE_ITEMS, payload);
}

export function getAddBidPublications(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_PUBLICATIONS, payload);
}

export function submitAddBidUpdateBidPublications(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_UPDATE_BID_PUBLICATIONS, payload);
}

export function getAddBidLegal(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_LEGAL, payload);
}

export function getAddBidActivePrograms(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_ACTIVE_PROGRAMS, payload);
}

export function getAddBidBroadcastListId(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_BROADCAST_LIST_ID, payload);
}

export function submitAddBidUpdateLegal(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_UPDATE_LEGAL, payload);
}

export function submitAddBidUpdateMemo(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_UPDATE_MEMO, payload);
}

export function updateEbidInfo(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_ADD_BID_EBID_INFO, payload);
}

export function submitAddBidAddList(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_ADD_LIST, payload);
}

export function submitAddBidSearchVendors(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_SEARCH_VENDORS, payload);
}

export function submitAddBidIsFilterStatus(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_IS_FILTER_STATUS, payload);
}

export function submitAddBidSupplierCount(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_SUPPLIER_COUNT, payload);
}

export function submitAddBidGetPreBuiltList(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_GET_PREBUILT_LIST, payload);
}

export function setFilteredPreBuiltList(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_SET_PREBUILT_LIST, payload);
}

export function submitAddBidDeleteList(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_DELETE_LIST, payload);
}

export function SubmitAddBidusePreBuiltList(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_USE_PREBUILT_LIST, payload);
}

export interface BidDocTypePayload {
  bidId: number;
  type: 'Award' | 'Bid';
}

export function getAddBidDocTypes(payload: BidDocTypePayload) {
  return action(actionTypes.GET_ADD_BID_DOC_TYPES, payload);
}

export function getAddBidIsConstruction(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_IS_CONSTRUCTION, payload);
}

export function getAddBidIsPermissions(payload: BidIdPayload) {
  return action(actionTypes.GET_ADD_BID_IS_PERMISSIONS, payload);
}

export function getAddBidConversionTypes(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_CONVERSION_TYPES, payload);
}

export function submitAddBidUpdatePlan(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_UPDATE_PLAN, payload);
}

export function submitAddBidDocumentUpload(payload: { bidId: number; uploadPath?: string }) {
  return action(actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPLOAD, payload);
}

export function submitAddBidDocumentDelete(payload: { bidId: number | ''; bidDocId: number | '' }) {
  return action(actionTypes.SUBMIT_ADD_BID_DOCUMENT_DELETE, payload);
}
interface SubmitAddBidDocumentApprovePayload {
  bidId: number;
  bidDocId: number | '';
  pagefor?: string;
}

export function submitAddBidDocumentApprove(payload: SubmitAddBidDocumentApprovePayload) {
  return action(actionTypes.SUBMIT_ADD_BID_DOCUMENT_APPROVE, payload);
}

export function submitAddBidComplete(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_COMPLETE, payload);
}

export function submitAddBidAddSupplemental(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_ADD_SUPPLEMENTAL, payload);
}

export function submitAddBidMoveNext(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_ADD_BID_MOVE_NEXT, payload);
}

export function getAddBidsupplementalsuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_SUPPLEMENTAL_SUPPLIERS, payload);
}

export function AddBidRemoveSupplimentalSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_BID_REMOVE_SUPPLIMENTAL_SUPPLIERS, payload);
}

export function getAddBidBroadcastMembers(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_BROADCAST_MEMBERS, payload);
}

export function getAddBidBidsDocuments(payload: BidIdPayload) {
  return action(actionTypes.GET_ADD_BID_BIDS_DOCUMENTS, payload);
}

export function getAddBidCanComplete(payload: BidIdPayload) {
  return action(actionTypes.GET_ADD_BID_CAN_COMPLETE, payload);
}

export function getAddBidBidStatus(payload: actionPayloadTypes) {
  return action(actionTypes.GET_ADD_BID_BID_STATUS, payload);
}

export function getAddBidChangeDue(payload: { bidId: number; dueDate: string }) {
  return action(actionTypes.GET_ADD_BID_CHANGE_DUE, payload);
}

export function submitBidAwardees(payload: BidIdPayload) {
  return action(actionTypes.ADD_BID_ADD_AWARDEE, payload);
}

export function getaddBidGetAwardees(payload: { bidId: number | string }) {
  return action(actionTypes.GET_ADD_BID_GET_AWARDEES, payload);
}

export function setDeleteBroadcastSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_BID_DELETE_BROADCAST_SUPPLIERS, payload);
}

export function setCommodityFilters(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_BID_SET_COMMODITY_FILTERS, payload);
}

export function resetAddbid() {
  return action(actionTypes.RESET_ADD_BID, {});
}

export function getListCountDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_LIST_COUNT_DETAILS, payload);
}

export function bulkUploadDocument(payload: actionPayloadTypes) {
  return action(actionTypes.BULK_UPLOAD_DOCUMENT, payload);
}

export function updateSuplimentalSheetUpdate(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_SUPLIMENTAL_SHEET_UPDATE, payload);
}

export function saveUploadedSupplimentalSupplier(payload: actionPayloadTypes) {
  return action(actionTypes.SAVE_UPLOADED_SUPPLIMENTAL_SUPPLIER, payload);
}

export function reBroadcastBidToSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.REBROADCAST_BID_TO_SUPPLIERS, payload);
}

export function updateBidDocumentTitle(payload: updateBidDocumentTitleType) {
  return action(actionTypes.SUBMIT_ADD_BID_DOCUMENT_UPDATE, payload);
}

export function setDocumentName(payload: actionPayloadTypes | undefined = undefined) {
  return action(actionTypes.SET_DOCUMENT_NAME, payload);
}
