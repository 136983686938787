/**
 * Set a Typescript `enum` because it (or a `const`) can be used as a key/value lookup and also as a `type`.
 */
export enum ExpandedProductType {
  FreeAgency = 'Free Agency',
  County = 'County',
  State = 'State',
  National = 'Nation Wide',
}

/**
 * Generates a string union of ProductTypeEnum keys.
 */
export enum ProductType {
  FreeAgency = 'AG',
  County = 'CT',
  State = 'ST',
  National = 'NA',
}

/**
 * Type-safe expanded ProductType value.
 * @param key
 */
export function getProductTypeExpanded(key: ProductType): ExpandedProductType {
  switch (key) {
    case ProductType.FreeAgency:
      return ExpandedProductType.FreeAgency;
    case ProductType.County:
      return ExpandedProductType.County;
    case ProductType.State:
      return ExpandedProductType.State;
    case ProductType.National:
      return ExpandedProductType.National;
  }
}

export function sortByProductType(key1: ProductType, key2: ProductType): number {
  const sortOrder = [
    ProductType.FreeAgency,
    ProductType.County,
    ProductType.State,
    ProductType.National,
  ];
  return sortOrder.indexOf(key1) - sortOrder.indexOf(key2);
}

export enum NationalProductPrice {
  None = 0,
  UnitedStates = 2399,
}

export enum NationalProductId {
  None = 0,
  UnitedStates = 10156,
}

export type ProductApiResponse = {
  parentId?: number;
  parentName?: string;
  parentPrice?: number;
  parentType?: ProductType;
  price: number;
  productGroupId: number;
  productId: number;
  productName: string;
  productType: ProductType;
  sortOrder: number;
};

export type CartProduct = ProductApiResponse & {
  calculatedPrice: number;
};

/**
 * This can be used with selected or subscribed
 */
export interface SubscriptionProducts {
  agency?: ProductApiResponse;
  county?: ProductApiResponse[];
  state?: ProductApiResponse[];
  national?: NationalProductId;
}
