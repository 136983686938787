import styled from 'styled-components';

import * as texts from './CMDashboard.texts';

const ComingSoonArea = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    url('https://network.demandstar.com/wp-content/uploads/2021/01/banner13.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding: 5rem;
  text-align: center;
  background-blend-mode: lighten;

  div {
    margin: 0px auto;
    width: 60%;
  }
`;

export const ComingSoon = () => {
  return (
    <ComingSoonArea>
      <h3>{texts.comingSoonHeadline}</h3>
      <div>{texts.comingSoonInfo}</div>
    </ComingSoonArea>
  );
};
