import Api from './api';

import { constants } from 'src/utils/settings';
import { Paths } from 'src/utils/constants';

export function getAuthToken(data = {}) {
  return Api.postRequest(Paths.auth.authorization, data, {
    baseURL: constants.api.authUrl,
  });
}

export function getRefreshToken(data = {}) {
  return Api.postRequestWithAuthentication(Paths.auth.refreshToken, data, {
    baseURL: constants.api.authUrl,
  });
}

export function validateEmail(data: any = {}) {
  return Api.getRequest(`${Paths.member.validateEmail}?id=${data.accountId}`, {
    baseURL: constants.api.userUrl,
  });
}
