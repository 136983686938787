import { Field, Formik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import AddQuotesControl from '../breadcrumb/buyer/addquotescontrol';
import Formbuttons from './formbuttons';
import renderTextarea from '../../common/controls/inputs/textarea';

import { addquoteParamType } from '../../../types/addquote';
import { QuoteSummaryType } from '../../../types/quotedetailstypedef';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { validate } from './form/validations';
import { WizardPage } from '../../../types/wizard';

const divStyle = {
  td1: {
    width: '63%',
  },
  td2: {
    width: '15%',
  },
  td3: {
    width: '18%',
  },
  td4: {
    width: '25px',
  },
};

interface PropsTypes {
  handleSubmit?: any;
  setAddQuotesDetails?: any;
  addquotes?: addquoteParamType;
  currentwizard?: WizardPage;
  addquoteswirereduxForm?: any;
  quotesId?: number | string;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  currentQuotesName?: string;
  submitAddQuotesWire?: any;
  getAddQuotesItems?: any;
  getAddQuotesWire?: any;
  initialValues: any;
  quoteSummary: QuoteSummaryType;
}

function QuoteWire(props: PropsTypes) {
  const {
    handleSubmit,
    setAddQuotesDetails,
    currentwizard,
    quotesId,
    valid,
    pristine,
    submitting,
    currentQuotesName,
    submitAddQuotesWire,
    getAddQuotesItems,
    getAddQuotesWire,
    initialValues,
    quoteSummary,
  } = props;
  const { linewires = [] } = initialValues;
  const { summary } = quoteSummary;
  const { quoteId: summaryQuoteId = '' } = summary;

  const lineWirecounts = [] as any;
  for (let i = 0; i < linewires.length; i++) {
    lineWirecounts.push(i);
  }

  const [linewireListCount, setLineWireListCount] = useState(lineWirecounts);

  useEffect(() => {
    if (quotesId && summaryQuoteId) getAddQuotesItems({ quoteId: quotesId });
  }, [getAddQuotesItems, quotesId, summaryQuoteId]);

  useEffect(() => {
    if (quotesId && summaryQuoteId) getAddQuotesWire({ quoteId: quotesId });
  }, [quotesId, getAddQuotesWire, summaryQuoteId]);

  useSetBreadcrumb({
    component: (
      <AddQuotesControl
        valid={valid}
        pristine={pristine}
        submitting={submitting}
        quotesId={quotesId}
      />
    ),
    page: currentQuotesName ? 'Agency Quote Update' : 'Agency Quote Creation',
    title: currentQuotesName
      ? `Edit Quote - ${currentwizard && currentwizard.name}`
      : `Quote Creation - ${currentwizard && currentwizard.name}`,
    altname: currentQuotesName
      ? `Edit Quote - ${currentwizard && currentwizard.name}`
      : `Quote Creation - ${currentwizard && currentwizard.name}`,
    name: currentQuotesName ? currentQuotesName : 'Quote Creation',
    id: quotesId,
    menuactive: 'quotes',
  });

  const onSubmitFn = (data: any) => {
    if (data) {
      submitAddQuotesWire({ quotesId, data });
    }
  };

  const updateNewLineItem = (type: string, index: number | string = '') => {
    if (type === 'remove') {
      const newIndex = linewireListCount.indexOf(index);
      linewireListCount.splice(newIndex, 1);
    } else {
      const lastIndexValue = linewireListCount[linewireListCount.length - 1];
      const lineWireCount = [...linewireListCount];
      lineWireCount.push(lastIndexValue + 1);
      setLineWireListCount(lineWireCount);
    }
  };

  const LineWireForm = (props: any) => {
    const { values, setFieldValue } = props;
    return (
      <>
        <div className='row innerColIndent'>
          <div className='col-12'>
            <h3>
              Line {values.linewires.length > 0 ? 'Items' : 'Item'} ({values.linewires.length})
              <span
                title='Add'
                onClick={() => updateNewLineItem('add')}
                className='bttn-secondary small float-right'
              >
                <i className='mdi mdi-plus mr-1' />
                Add Line Item
              </span>
            </h3>
            <Table className='tableHData'>
              <tr>
                <th style={divStyle.td1}>Description</th>
                <th style={divStyle.td2}>Quantity</th>
                <th style={divStyle.td3}>Unit of Measure</th>
                <th style={divStyle.td4} />
              </tr>
              {linewireListCount.map((linewires: any, index: any) => (
                <tr key={index}>
                  <td>
                    <Field
                      label='Description'
                      type='text'
                      classNames='class'
                      placeholder='Description'
                      name={`linewires[${linewires}].description`}
                      component={renderTextarea}
                      parentClass='tableTextarea'
                      onChange={setFieldValue}
                      maxLength={700}
                    />
                  </td>
                  <td>
                    <Field
                      label='Quantity'
                      type='text'
                      classNames='class'
                      placeholder='Quantity'
                      name={`linewires[${linewires}].quantity`}
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      maxLength={8}
                    />
                  </td>
                  <td>
                    <Field
                      label='Unit of Measure'
                      type='text'
                      classNames='class'
                      placeholder='Unit of Measure'
                      name={`linewires[${linewires}].uom`}
                      component={renderDeprecatedTextField}
                      onChange={setFieldValue}
                      maxLength={50}
                    />
                  </td>
                  <td>
                    {linewires > 0 ? (
                      <>
                        {values.linewires.find(
                          (itemx: any, indexx: any) => indexx === linewires && itemx.quoteItemId,
                        ) ? (
                          <i
                            title='Remove'
                            className='mdi mdi-24px mdi-delete-forever-outline d-inline ml-1 staticLink'
                            onClick={() => {
                              setAddQuotesDetails({ halfWayCancel: true });
                              updateNewLineItem('remove', linewires);
                            }}
                          />
                        ) : (
                          <i
                            title='Remove'
                            className='mdi mdi-24px mdi-delete-forever-outline d-inline ml-1 staticLink'
                            onClick={() => {
                              setAddQuotesDetails({ halfWayCancel: true });
                              updateNewLineItem('remove', linewires);
                            }}
                          />
                        )}
                      </>
                    ) : null}
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={(values: any) => validate(values, props)}
        onSubmit={onSubmitFn}
      >
        {formikProps => {
          const { handleSubmit, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <LineWireForm values={formikProps.values} setFieldValue={setFieldValue} />
              <div className='row'>
                <div className='col-md-9 col-lg-10 col-xl-8 px-5'>
                  <Field
                    label='Additional Specifications'
                    type='text'
                    classNames='class'
                    placeholder='Additional Specifications'
                    name='additionalspecifications'
                    component={renderTextarea}
                    optional='max. 700 chars. optional'
                    onChange={setFieldValue}
                    maxLength={700}
                  />
                  <Field
                    label='Insurance and Additional Requirements'
                    type='text'
                    classNames='class'
                    placeholder='Insurance and Additional Requirements'
                    name='insuranceandadditional'
                    component={renderTextarea}
                    optional='max. 700 chars. optional'
                    onChange={setFieldValue}
                    maxLength={700}
                  />
                  <Field
                    label='Terms and Conditions'
                    type='text'
                    classNames='class'
                    placeholder='Terms and Conditions'
                    name='termsandconditions'
                    component={renderTextarea}
                    optional='max. 4096 chars. optional'
                    onChange={setFieldValue}
                    maxLength={4096}
                  />
                </div>
              </div>
              <Formbuttons {...props} />
            </form>
          );
        }}
      </Formik>
    </>
  );
}
export default memo(QuoteWire);
