import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function setQuoteSummaryDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_QUOTE_SUMMARY_DETAILS, payload);
}

export function resetQuoteSummaryDetails(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_QUOTE_SUMMARY_DETAILS, payload);
}

export function getQuoteSummary(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTE_SUMMARY, payload);
}

export function getQuoteLineItems(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_QUOTE_LINE_ITEMS, payload);
}

export function getQuoteStatusOptions(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_QUOTE_STATUS_OPTIONS, payload);
}

export function quoteSummaryChangeStatus(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_STATUS, payload);
}

export function quoteSummaryUpdateDueDate(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_CHANGE_QUOTE_DUEDATE, payload);
}

export function quoteSummaryGetDocuments(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_QUOTE_SUMMARY_DOCUMENTS, payload);
}

export function getQuoteResponses(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_QUOTE_RESPONSES, payload);
}

export function QuoueSummaryGetCurrentSupplierinfo(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_CURRENT_SUPPLIER_INFO, payload);
}

export function QuoueSummaryGetSupplierBreakdown(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_SUPPLIER_BREAKDOWN, payload);
}

export function getQuoteResponseStatus(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_RESPONSE_STATUS, payload);
}

export function getQuoteStatistics(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_GET_STATISTICS, payload);
}

export function getQuotelineItemSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_LINE_ITEMS_SUPPLIER, payload);
}

export function sendRewardMail(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_SEND_REWARD_MAIL, payload);
}

export function notSubmitQuote(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_NOT_SUBMIT_QUOTE, payload);
}

export function submitQuoteReply(payload: actionPayloadTypes) {
  return action(actionTypes.QUOTE_SUMMARY_SUBMIT_QUOTE_REPLY, payload);
}

export function getQuoteView(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTE_SUMMARY_QUOTE_VIEW, payload);
}

export function getQuotesReply(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTE_SUMMARY_QUOTES_REPLY, payload);
}

export function getQuoteCommoditycodes(payload: actionPayloadTypes) {
  return action(actionTypes.GET_QUOTE_COMMODITYCODES, payload);
}

export function resetQuotesummary(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_QUOTE_SUMMARY, payload);
}

export function deleteLineItem(payload: actionPayloadTypes) {
  return action(actionTypes.DELETE_LINE_ITEM, payload);
}
export function exportQuoteLineItems(data: { quoteId: number }) {
  return action(actionTypes.EXPORT_LINEITEM_DETAILS, data);
}
