import { Padding } from '../styles';
import { remToPx } from './conversions';
import { UrlHandling } from '../types';

/**
 * @description attempts to align the top of the user-visible window with the provided element
 * @param elementSelector an HTML selector for the target element
 * @param headerSelector an HTML selector for the header element
 * Note: ideally this will eventually be ref-based
 */
export const smoothScrollTo = (
  elementSelector: string,
  headerSelector = '.headerBreadcrumbWrapper',
) => {
  const basePadding = remToPx(Number(Padding.Base.replace('rem', '')));
  /** TOREFACTOR: Should look for the DSBreadcrumbs Element when replaced */
  const headerOffset = document.querySelector(headerSelector)?.getBoundingClientRect()?.height ?? 0;
  const element = document.querySelector(elementSelector);

  if (element) {
    const top = element.getBoundingClientRect()?.top + window.scrollY;
    window.scrollTo({
      top: top - (headerOffset + basePadding),
      behavior: 'smooth',
    });
  }
};

/**
 * @parameter search (URLSearchParams) - the current URL search parameters
 * @parameter handling (UrlHandling<T>) - the desired method of value retrieval
 * @returns a value of type T, extracted from the URL based on UrlHandling object */
export function getUrlValue<T>(search: URLSearchParams, handling: UrlHandling<T>) {
  if ('key' in handling) return handling.getValue(search.get(handling.key));
  else return handling.getValue(search);
}

/**
 * Sets (or removes key from) the URL search parameters based on the provided handling and value
 * @parameter search (URLSearchParams) - the current URL search parameters
 * @parameter handling (UrlHandling<T>) - the desired method of setting URL value
 * @parameter value? (T | null) - the desired method of setting URL value
 * @returns void */
export function setUrlValue<T>(
  search: URLSearchParams,
  handling: UrlHandling<T>,
  value?: T | null,
) {
  if ('key' in handling) {
    const newValue = handling.setValue(value);
    if (newValue) search.set(handling.key, newValue);
    else search.delete(handling.key);
  } else handling.setValue(search, value);

  refreshUrl(search);
}

/** Replaces window history state w/ provided URL search terms */
function refreshUrl(urlParameters: URLSearchParams) {
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}${urlParameters.toString() && `?${urlParameters.toString()}`}`,
  );
}

export const getBasicStringUrlHandler = (urlKey: string): UrlHandling<string> => {
  return {
    key: urlKey,
    getValue: (val: string | null) => val,
    setValue: (val?: string | null) => val,
  };
};
