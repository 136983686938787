import styled from 'styled-components';

import { BorderColor, Margin } from '../styles';

export const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${BorderColor.Table};
  margin: ${Margin.Base} 0;
`;
