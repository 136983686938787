import { memo, useEffect, useState } from 'react';

import {
  bidssummaryParamType,
  EBidResponseFull,
  ebidsParamType,
} from '../../../../../types/biddetail';
import { Buttons, SelectBox } from '../../../../customcontrols';

interface PropsTypes {
  bidsPlanHolders?: any;
  bidssummary?: bidssummaryParamType;
  handleOnClick: any;
  ebids?: ebidsParamType;
  match?: any;
}

const AddNewResponse = (props: PropsTypes) => {
  const { bidsPlanHolders, bidssummary, handleOnClick, ebids } = props;
  const { planHolders } = bidssummary || {};
  const { ebidResponse = [] } = ebids || {};
  const { result = [] } = planHolders || {};

  const [selectedPlanholder, setSelectedPlanholder] = useState({} as { value: any });

  const bidId = parseInt(props.match.params.bidId);

  useEffect(() => {
    if (bidId && result.length === 0) {
      bidsPlanHolders({ bidId });
    }
  }, [bidId, bidsPlanHolders, result.length]);

  const respondedIds =
    (ebidResponse as EBidResponseFull[]).map(item => item.supplierMemberId) || [];

  const SelectedEbidResponse = result
    .filter(el => !respondedIds.includes(el.mi))
    .map(item => ({
      label: item.supplierName,
      value: item.mi,
    }));

  const onSelectChange = (key: string, value: any) => {
    setSelectedPlanholder(value);
  };

  return (
    <>
      <SelectBox
        reactselect={true}
        label='PlanHolders'
        name='bidStatus'
        options={SelectedEbidResponse}
        handleSelect={onSelectChange}
        clear={true}
        value={selectedPlanholder && selectedPlanholder.value ? selectedPlanholder : ''}
      />

      <Buttons
        text='Add Response'
        title='Add Response'
        disable={!selectedPlanholder || SelectedEbidResponse.length === 0}
        classNames='bttn-primary float-right'
        onClick={() => handleOnClick(selectedPlanholder.value)}
      />
    </>
  );
};

export default memo(AddNewResponse);
