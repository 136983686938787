import { AxiosRequestConfig } from 'axios';

import { Assert } from './helpers';
import { track } from './telemetry';

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

/**
 * @description logs straightforward errors
 * @param error - error from the catch block
 * @param output - string (function name) or AxiosRequestConfig to pass in for easier debugging
 * @example
 *  logError(error, `planholdersQuerySelector (${bidId})`);
 *  throw new Error(getErrorMessage(error));
 */
function logError(error: unknown, output: string | AxiosRequestConfig<unknown>) {
  let functionString: string;
  const tracking: Record<string, unknown> = {};

  if (typeof output === 'string') {
    functionString = output;
  } else {
    functionString = output.url || 'Unknown axios request';
    tracking.method = output.method || 'GET';
    tracking.config = output;
  }

  track(`${functionString} ERROR:`, {
    error,
    errorMessage: getErrorMessage(error),
    ...tracking,
  });
  // eslint-disable-next-line no-console
  console.error(`${functionString} ERROR: \n${error}`, tracking.config);
  Assert(false, functionString);
}

/**
 *
 * @param tryFunc - async function that may fail
 * @param output - string (function name) or AxiosRequestConfig to pass in for easier debugging
 * @param catchFunc - optional function should additional steps be needed
 * @returns {T} - return value of the function (or throws an Error)
 * @example
 * return tryCatchLog(async () => {
      const response = await getBidPlanholderList(selectedBidId);
      return response;
    }, `bidPlanholderListSelector -> getPlanholderList(${selectedBidId})`);
 */
export async function tryCatchLog<T>(
  tryFunc: () => T,
  output: string | AxiosRequestConfig<unknown>,
  catchFunc?: (error: unknown) => void,
): Promise<T> {
  try {
    return await tryFunc();
  } catch (error: unknown) {
    if (catchFunc) catchFunc(error);
    logError(error, output);
    throw new Error(getErrorMessage(error));
  }
}
