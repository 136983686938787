import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';

export function login(payload: actionPayloadTypes) {
  return action(actionTypes.LOGIN, payload);
}

export function loginUsingCookie(payload: actionPayloadTypes) {
  return action(actionTypes.LOGIN_USING_COOKIE, payload);
}

export function setLogin(payload: actionPayloadTypes) {
  return action(actionTypes.SET_LOGIN, payload);
}

export function logout(payload: actionPayloadTypes) {
  return action(actionTypes.LOGOUT, payload);
}

export function getRefreshToken(payload: actionPayloadTypes = undefined) {
  return action(actionTypes.GET_REFRESH_TOKEN, payload);
}

export function resetAuth() {
  return action(actionTypes.RESET_AUTH);
}

export function validateEmail(payload: actionPayloadTypes) {
  return action(actionTypes.VALIDATE_EMAIL, payload);
}

export function setLoginDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_LOGIN_DETAILS, payload);
}

export function trackAmplitudeUserActions(payload: actionPayloadTypes) {
  return action(actionTypes.TRACK_AMPLITUDE_USER_ACTION, payload);
}
