import { DSTree } from '@demandstar/components/tree';
import { ToggleExpandIcon } from '@demandstar/components/icon/ToggleExpandIcon';

import { limitedPaths } from 'src/shared/constants';

import * as constants from './BrowseBids.constants';
import * as texts from './BrowseBids.texts';

import { HeaderWithScrollToTop } from './BrowseBids.styles';
import { useBrowseBids } from './useBrowseBids';

export function CommodityCodeTree() {
  const { commodityCodes = [], buildCommodityCodeLinkURI, urlSelectedSEOIDs } = useBrowseBids();

  return (
    <>
      <HeaderWithScrollToTop
        data-testid={constants.commodityCodeElementId}
        id={constants.commodityCodeElementId}
      >
        {texts.commodityCodeTreeTitle}
      </HeaderWithScrollToTop>
      <DSTree
        baseUrl={limitedPaths.browseBids}
        buildLink={buildCommodityCodeLinkURI}
        treeData={commodityCodes}
        dataTestId='commodity-code-tree'
        icon={ToggleExpandIcon}
        openNodes={urlSelectedSEOIDs}
      />

      {
        // Add a right-justified scroll to top link beneath if there are commodity codes.
        commodityCodes.length && (
          <HeaderWithScrollToTop>
            <span />
          </HeaderWithScrollToTop>
        )
      }
    </>
  );
}
