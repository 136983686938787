import { displayDateTime } from '../../../utils/helpers';
import NoResult from '../../customcontrols/noresult';
import { loadingMsg } from '../../../utils/texts';
import Paging from '../../customcontrols/paging';
import { Table } from 'reactstrap';
import { UserActivity } from '../../../types';
import { DSLoadingPanel } from '@demandstar/components/loading-panel';

interface propsTypes {
  loadermsg: string;
  InternalLoader: boolean;
  userActivitiesList: UserActivity[];
  finalUserActivitiesList: UserActivity[];
  total: number;
  currentPage: number;
  onPagingClick: any;
  listPerPages: number;
  tzfn: string;
}

function UserActivitiesList(props: propsTypes) {
  const {
    loadermsg,
    InternalLoader: loader,
    userActivitiesList,
    finalUserActivitiesList,
    total,
    currentPage,
    onPagingClick,
    listPerPages,
    tzfn,
  } = props;

  return (
    <>
      <Table className='tableHData tableFixed'>
        <thead>
          <tr>
            <th>Account Name</th>
            <th>Action</th>
            <th>Updated By</th>
            <th>Date / Time</th>
          </tr>
        </thead>
        <tbody>
          {userActivitiesList && userActivitiesList.length > 0 ? (
            <>
              {finalUserActivitiesList.length > 0 ? (
                <>
                  {finalUserActivitiesList.map((userActivity, index) => (
                    <tr key={index}>
                      <td className='text-truncate'>
                        {
                          userActivity.accountName
                        }
                      </td>
                      <td>{userActivity.userAction}</td>
                      <td>{userActivity.modifiedBy}</td>
                      <td>{displayDateTime(userActivity.dateOfAction, tzfn)}</td>

                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <DSLoadingPanel>{loadingMsg}</DSLoadingPanel>
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={10}>
                <NoResult message={loader ? loadingMsg : loadermsg} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <footer className='row'>
        <div className='col-12 '>
          <Paging
            directpage={false}
            totalRecords={total}
            currentPage={currentPage}
            onPagingClick={onPagingClick}
            limitmsg={false}
            pageLimit={listPerPages}
          />
        </div>
      </footer>
    </>
  );
}

export default UserActivitiesList;
