import { AwardeeAPI, AwardeeDraft } from 'src/store/recoil/bid-awardees';
import {
  CustomAwardNotification,
  CustomMessageType,
  CustomNotificationRecipient,
} from '../../types/awardBid';
import { axiosPostAuthenticated } from './api';
import { BidId } from '../../types/shared';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';

/**
 * Bid Awardee list request
 * @param {number} bidId
 * @returns api response: Awardee[]
 */
export function getBidAwardeeList(bidId: number) {
  return axiosPostAuthenticated<AwardeeAPI[]>({
    baseURL: constants.api.url,
    data: {
      bidId,
    },
    url: Paths.awards,
  });
}

export interface GetCustomAwardNotificationPayload {
  bidId: number;
  customMessageType: CustomMessageType;
}

/**
 * Custom Award Notification request
 * @param {bidId} BidId
 * @param {CustomMessageType} CustomMessageType
 * @returns {CustomAwardNotification}
 */
export function getCustomAwardNotification({
  bidId,
  customMessageType,
}: GetCustomAwardNotificationPayload): Promise<CustomAwardNotification> {
  return axiosPostAuthenticated<CustomAwardNotification>({
    baseURL: constants.api.url,
    data: {
      bidId,
      customMessageType,
    },
    url: Paths.Award.CustomAwardNotifications.get,
  });
}
export interface AddCustomAwardNotificationPayload {
  bidId: number;
  customMessageType: CustomMessageType;
  subject: string;
  introduction: string;
  customMessage: string;
  contactName: string;
  contactTitle: string;
  contactPhone: string;
  contactEmail: string;
  recipients: CustomNotificationRecipient[];
  shouldNotify: boolean;
}

/**
 * Custom Award Notification request
 * @param {bidId} bidId
 * @param {CustomMessageType} CustomMessageType
 * @returns {CustomAwardNotification}
 */
export function addCustomAwardNotification({
  bidId,
  customMessageType,
  subject,
  customMessage,
  introduction,
  contactName,
  contactTitle,
  contactPhone,
  contactEmail,
  recipients,
  shouldNotify,
}: AddCustomAwardNotificationPayload) {
  return axiosPostAuthenticated<CustomAwardNotification>({
    baseURL: constants.api.url,
    data: {
      bidId,
      customMessage,
      customMessageType,
      subject,
      introduction,
      contactName,
      contactTitle,
      contactPhone,
      contactEmail,
      recipients,
      shouldNotify,
    } as AddCustomAwardNotificationPayload,
    url: Paths.Award.CustomAwardNotifications.add,
  });
}

/**
 * Mark Bid As Awarded request
 * @param {bidId} bidId
 */
export function markBidAsAwarded(bidId: number) {
  return axiosPostAuthenticated({
    baseURL: constants.api.url,
    data: {
      bidId,
    } as BidId,
    url: Paths.Award.markBidAsAwarded,
  });
}

/**
 * Mark Bid As Recommended For Award request
 * @param {bidId} bidId
 */
export function markBidAsRecommended(bidId: number) {
  return axiosPostAuthenticated({
    baseURL: constants.api.url,
    data: {
      bidId,
    } as BidId,
    url: Paths.Award.markBidAsRecommendationOfAward,
  });
}

export interface AddBidPlanholderPayload {
  accountId?: number;
  address1?: string;
  address2?: string;
  bidId: number;
  city?: string;
  contact?: string;
  countryId?: number;
  declaredAttributes?: string;
  email: string;
  faxNumber?: string;
  memberId?: number;
  name?: string;
  phoneNumber?: string;
  planholderId?: number;
  postalCode?: string;
  stateId?: number;
}

interface AddAwardeesPayload {
  bidId: number;
  bidAwardees: AwardeeDraft[];
}

/**
 * Add Awardees request
 * @param {AddAwardeesPayload} payload
 * @returns {unknown}
 */
export function addAwardees(payload: AddAwardeesPayload): unknown {
  return axiosPostAuthenticated<unknown>({
    baseURL: constants.api.url,
    data: payload,
    url: Paths.addAwardees,
  });
}
