import styled from 'styled-components';

import {
  BackgroundColor,
  BorderColor,
  BorderThickness,
  Flex,
  FlexContainer,
  FlexContainerColumn,
  FontWeight,
  HeaderFontSize,
  LineHeight,
  Margin,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '../styles';
import { Status } from '../constants';

export const AccordionCard = styled.div`
  width: 100%;
`;

export const AccordionCardContent = styled(FlexContainerColumn)`
  border: ${BorderThickness.Base} solid ${BorderColor.Table};
  padding: ${Padding.Base};
`;

export const AccordionCardHeader = styled(FlexContainer)<Pick<StatusProps, 'compact'>>`
  align-items: center;
  background-color: ${BackgroundColor.SubNavigationTabSelected};
  color: ${TextColor.Contrast};
  cursor: pointer;
  flex-wrap: ${({ compact }) => (compact ? 'wrap' : 'nowrap')};
  font-size: ${({ compact }) => (compact ? HeaderFontSize.H6 : HeaderFontSize.H5)};
  font-weight: ${FontWeight.Header};
  line-height: ${LineHeight.Base};
  row-gap: 0;

  & i {
    font-size: ${({ compact }) => (compact ? HeaderFontSize.H3 : HeaderFontSize.H1)};
    margin-right: ${Margin.Less};
  }

  & > div:first-child {
    align-items: center;
    display: inline-flex;
    padding: ${Padding.Less} ${Padding.Base};
  }
`;

interface StatusProps {
  compact?: boolean;
  status?: Status;
}

const getStatusBackgroundColor = ({ status }: StatusProps) => {
  switch (status) {
    case Status.Success:
      return BackgroundColor.Success;
    case Status.Warning:
      return BackgroundColor.Warning;
    case Status.Error:
      return BackgroundColor.Error;
    default:
      return BackgroundColor.BaseGray;
  }
};

const getStatusTextColor = ({ status }: StatusProps) => {
  switch (status) {
    case Status.Success:
    case Status.Error:
      return TextColor.Contrast;
    default:
      return TextColor.Base;
  }
};

export const AccordionCardStatus = styled(Flex)<StatusProps>`
  background-color: ${getStatusBackgroundColor};
  color: ${getStatusTextColor};
  font-size: ${ParagraphFontSize.Base};
  padding: ${({ compact }) => (compact ? Padding.None : Padding.Base)};
  white-space: nowrap;
  ${({ compact }) =>
    compact &&
    `
    order: -1;
    width: 100%;
    flex: 3 0 auto;
    text-align: center;
    font-size: ${ParagraphFontSize.Small};
    line-height: ${LineHeight.Small};
  `}
`;
