import styled from 'styled-components';

import { BackgroundColor, BorderColor, BorderRadius } from '../../styles';

import { choiceRem } from '../../assets/ChoiceStyles';
import { Inactive } from '../../types';
import { pxToRem } from '../../utils/conversions';

interface BaseCheckboxProps extends Inactive {
  checked: boolean;
  indeterminate?: boolean;
}

function checkboxBackground(checked: boolean, indeterminate?: boolean, inactive?: boolean) {
  if (inactive) {
    return BackgroundColor.Inactive;
  }
  if (checked || indeterminate) {
    return BorderColor.Checked;
  }

  return BackgroundColor.BaseWhite;
}

function checkboxBorder(checked: boolean, indeterminate?: boolean, inactive?: boolean) {
  if (inactive) {
    return BorderColor.Base;
  }
  if (checked || indeterminate) {
    return BorderColor.Checked;
  }

  return BorderColor.Base;
}

function checkboxPosition(indeterminate?: boolean) {
  if (indeterminate) {
    return `
    left: ${pxToRem(3)};
    top: ${pxToRem(7)};
    width: ${pxToRem(11)};
    height: ${pxToRem(2)};
    transform: rotate(0deg);
    `;
  }

  return `
    left: ${pxToRem(5)};
    top: 0;
    width: ${pxToRem(7)};
    height: ${pxToRem(13)};
    transform: rotate(45deg);
  `;
}

function hoverBorderColor({ inactive }: BaseCheckboxProps) {
  return inactive ? BorderColor.Base : BorderColor.CheckedFocus;
}
function hoverBackgroundColor({ inactive, checked, indeterminate }: BaseCheckboxProps) {
  return (checked || indeterminate) && !inactive
    ? BorderColor.CheckedFocus
    : checkboxBackground(checked, indeterminate, inactive);
}

export const BaseCheckbox = styled.span<BaseCheckboxProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${choiceRem};
  width: ${choiceRem};
  border: 2px solid
    ${({ inactive, checked, indeterminate }) => checkboxBorder(checked, indeterminate, inactive)};
  background-color: ${({ inactive, checked, indeterminate }) =>
    checkboxBackground(checked, indeterminate, inactive)};
  border-radius: ${BorderRadius.Eased};

  &:after {
    display: ${({ checked, indeterminate }) => (checked || indeterminate ? 'block' : 'none')};
    content: '';
    position: absolute;
    ${({ indeterminate }) => checkboxPosition(indeterminate)}

    border-color: ${({ inactive }) => (inactive ? BorderColor.Base : BackgroundColor.BaseWhite)};
    border-style: solid;
    border-width: 0 ${pxToRem(3)} ${pxToRem(3)} 0;

    background: ${BackgroundColor.Transparent};
  }
  &:hover,
  input:focus + &,
  input:active + &,
  label:hover & {
    border-color: ${hoverBorderColor};
    background-color: ${hoverBackgroundColor};
  }
`;
