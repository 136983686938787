import React from 'react';
import 'src/assets/scss/PasswordStrengthMeter.scss';

interface PasswordStrengthMeterProps {
    password: string;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({ password }) => {
    const calculatePasswordStrength = (password: string): number => {
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (password.match(/[A-Z]/)) strength += 1;
        if (password.match(/[a-z]/)) strength += 1;
        if (password.match(/[0-9]/)) strength += 1;
        if (password.match(/[^A-Za-z0-9]/)) strength += 1;
        return strength;
    };

    const score = calculatePasswordStrength(password);

    const getPasswordStrength = (): string => {
        switch (score) {
            case 0:
                return 'Very Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            case 5:
                return 'Very Strong';
            default:
                return '';
        }
    };

    return (<>

        <div className="password-strength-meter d-flex">
            <div className="strength-meter ">
                <div className={`strength-meter-fill strength-${score}`} ></div>
            </div>
            {password.length > 7 ? <small>{getPasswordStrength()} </small> : password.length > 0 ? <small> Too Short</small> : null}
        </div>
    </>
    );
};

export default PasswordStrengthMeter;
