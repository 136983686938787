import { MemberType } from 'src/utils/constants';
import { MemberTypeName } from './dashboard';

export { MemberType } from '../utils/constants';

export enum MemberInfoKey {
  AcceptHardCopy = 'ahc',
  Address1 = 'a1',
  Address2 = 'a2',
  Addresses = 'addresses',
  AddressType = 'addressType',
  City = 'ct',
  Country = 'c',
  CountryAbbr = 'ca',
  CountryId = 'ci',
  County = 'cu',
  CountyId = 'cui',
  DoNotContact = 'dnd',
  DownloadSuppDocBeforeDue = 'sdbd',
  Email = 'eml',
  Fax = 'f',
  FaxExtension = 'fe',
  FederalTaxId = 'fti',
  IsCorporate = 'ic',
  IsFreeDocDownload = 'ifd',
  JobTitle = 'jt',
  MainContact = 'mc',
  MemberId = 'mi',
  MemberName = 'mn',
  MemberStatus = 'ms',
  MemberType = 'mt',
  MetroId = 'metroId',
  MemberTypeName = 'mtn',
  Notify = 'nfy',
  Phone = 'p',
  PhoneExtension = 'pe',
  PlanholderDisplayType = 'phdt',
  Permissions = 'prms',
  PostalCode = 'pc',
  State = 'st',
  StateAbbr = 'sta',
  StateId = 'sti',
  TimeZoneFullName = 'tzfn',
  TimeZoneId = 'tzi',
  TimeZoneName = 'tzn',
}

export type MemberAddress = Pick<
  MemberInfoApiResponse,
  | MemberInfoKey.Address1
  | MemberInfoKey.Address2
  | MemberInfoKey.City
  | MemberInfoKey.County
  | MemberInfoKey.Country
  | MemberInfoKey.CountryAbbr
  | MemberInfoKey.CountryId
  | MemberInfoKey.CountyId
  | MemberInfoKey.MetroId
  | MemberInfoKey.PostalCode
  | MemberInfoKey.State
  | MemberInfoKey.StateAbbr
  | MemberInfoKey.StateId
> & {
  addrType: string;
};

export type MemberInfoApiResponse = {
  [MemberInfoKey.Address1]: string;
  [MemberInfoKey.Address2]: string;
  [MemberInfoKey.Addresses]: MemberAddress[];
  [MemberInfoKey.City]: string;
  [MemberInfoKey.Country]: string;
  [MemberInfoKey.CountryAbbr]: string;
  [MemberInfoKey.CountryId]: number;
  [MemberInfoKey.County]: string;
  [MemberInfoKey.CountyId]: number;
  [MemberInfoKey.DoNotContact]: boolean;
  [MemberInfoKey.DownloadSuppDocBeforeDue]: boolean;
  [MemberInfoKey.Email]: string;
  [MemberInfoKey.IsCorporate]: number;
  [MemberInfoKey.IsFreeDocDownload]: boolean;
  [MemberInfoKey.JobTitle]: string;
  [MemberInfoKey.MemberId]: number;
  [MemberInfoKey.MemberName]: string;
  [MemberInfoKey.MemberStatus]: string; // TODO: convert to enum
  [MemberInfoKey.MemberType]: MemberType;
  [MemberInfoKey.MetroId]: number;
  [MemberInfoKey.MemberTypeName]: MemberTypeName;
  [MemberInfoKey.Notify]: boolean;
  [MemberInfoKey.Phone]: string;
  [MemberInfoKey.PostalCode]: string;
  [MemberInfoKey.State]: string;
  [MemberInfoKey.StateAbbr]: string;
  [MemberInfoKey.StateId]: number;
  [MemberInfoKey.TimeZoneFullName]: string;
  [MemberInfoKey.TimeZoneId]: number;
  [MemberInfoKey.TimeZoneName]: string;
};

export type memberinfoType = Pick<
  MemberInfoApiResponse,
  | MemberInfoKey.Address1
  | MemberInfoKey.Address2
  | MemberInfoKey.Country
  | MemberInfoKey.City
  | MemberInfoKey.County
  | MemberInfoKey.DoNotContact
  | MemberInfoKey.IsFreeDocDownload
  | MemberInfoKey.MemberId
  | MemberInfoKey.MemberName
  | MemberInfoKey.MemberStatus
  | MemberInfoKey.MemberType
  | MemberInfoKey.Phone
  | MemberInfoKey.PostalCode
  | MemberInfoKey.State
  | MemberInfoKey.TimeZoneId
> & {
  [MemberInfoKey.PlanholderDisplayType]: string; // TODO: Convert to enum
  [MemberInfoKey.Permissions]: string;
};
