import styled from 'styled-components';

import { FontFamily } from '@demandstar/components/styles';

import { Colors } from './';

export interface PillWrapperProps {
  bgColor?: Colors;
  borderColor?: Colors;
  textColor?: Colors;
}

export const PillWrapper = styled.div`
  background: ${(props: PillWrapperProps) => props?.bgColor || Colors.orangeMedium};
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  border: ${(props: PillWrapperProps) =>
    props?.borderColor
      ? `0.05rem solid ${props.borderColor}`
      : props?.bgColor === Colors.transparent
      ? `0.05rem solid ${Colors.grayLight}`
      : 'none'};
  color: ${(props: PillWrapperProps) => props?.textColor || Colors.grayDark};
  cursor: inherit;
  font-family: ${FontFamily.Header};
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  max-width: 12.5rem;
  padding: 0.125rem 0.5rem 0 0.5rem;
  width: fit-content;
`;
