import {
  BreadcrumbBar,
  BreadcrumbComponent,
  BreadcrumbContainer,
  BreadcrumbList,
} from './DSBreadcrumbs.styles';
import { Layout } from '../types';

export type BreadcrumbItem = {
  display: string;
  handleClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  path?: string;
};

type BreadcrumbsProps = {
  breadcrumbs?: BreadcrumbItem[];
  component?: React.ReactNode;
} & Layout;

// TOREFACTOR: the `component` Prop should take an object instead of JSX & build the buttons itself
/**
 * @description Displays full-width list of "breadcrumbs" with option to include an additional component to display
 * @default {}
 * @example <Breadcrumbs breadcrumbs={[{ // BreadcrumbItem[]
 *                          {
 *                              display: 'Home'
 *                              path: '/home', // path will render as link w/ href
 *                          },
 *                          {
 *                              display: 'Bids',
 *                              handleClick: (e) => { console.log('clicked bids'); window.location.href = '/bids'; }
 *                          }, // handleClick allows for more complex action, if provided, overrides "Path" attr
 *                          {
 *                              display: 'DS100',
 *                          },
 *                       ]}
 *                    component={(
 *                      <>
 *                        <li title='Save'>
 *                          <span className='staticLink' onClick={submit}>
 *                            <i className='mdi mdi-content-save-outline mr-1' /> Save
 *                          </span>
 *                        </li>
 *                        <li title='Cancel'>
 *                          <span className='staticLink' onClick={cancel}>
 *                            <i className='mdi mdi-close mr-1' /> Cancel
 *                          </span>
 *                        </li>
 *                      </>)} // Example taken from [sic] /components/common/breadcrumb/supplier/billibgeditControl
 *            />
 * @param {BreadcrumbItem[]=} breadcrumbs - the list of items to display
 * @param {React.ReactNode=} component - A React element expected to include a series of <li></li> elements (legacy)
 */
export const DSBreadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbs,
  component,
  layoutType,
}: BreadcrumbsProps) => {
  return (
    <BreadcrumbBar data-testid='breadcrumbs-bar'>
      <BreadcrumbContainer layoutType={layoutType}>
        <BreadcrumbList>
          {breadcrumbs?.map((b, index) => {
            const last = b === breadcrumbs[breadcrumbs.length - 1];

            return (
              <li key={`${index}-${b.display}`} title={b.display}>
                {b.display === 'Home' && <i className='mdi mdi-home-variant-outline' />}
                <span onClick={last ? undefined : b.handleClick}>
                  {b.handleClick || last ? b.display : <a href={b.path}>{b.display}</a>}
                </span>

                {!last && <i className='mdi mdi-chevron-right' />}
              </li>
            );
          })}
        </BreadcrumbList>
        <BreadcrumbComponent>{component}</BreadcrumbComponent>
      </BreadcrumbContainer>
    </BreadcrumbBar>
  );
};
