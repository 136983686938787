import { atomFamily } from 'recoil';

import { AlertProps } from './Alert';

export type AlertState =
  | Partial<
      Omit<AlertProps, 'id' | 'showByDefault' | 'children'> & {
        message: React.ReactNode;
      }
    >
  | undefined;

export const alertVisibleState = atomFamily<boolean, string>({
  key: 'alertVisibleState',
  default: false,
});

export const alertState = atomFamily<AlertState | undefined, string>({
  key: 'alertState',
  default: undefined,
});
