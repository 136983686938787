import { Table } from 'reactstrap';

import { formatPrice, formattedPhoneNumber } from '../../../../../utils/helpers';
import { EBidResponseFull } from '../../../../../types/biddetail';

const tdWidth = { width: '170px' };

interface PropsTypes {
  data: EBidResponseFull;
}

const Tabel2 = (props: PropsTypes) => {
  return (
    <Table borderless className='tableData'>
      <tbody data-testid='bids.contact.info.detail.table'>
        <tr>
          <th style={tdWidth}>Company Name</th>
          <td>{props.data.contactName}</td>
        </tr>
        <tr>
          <th>Address 1</th>
          <td>{props.data.address1}</td>
        </tr>
        <tr>
          <th>Address 2</th>
          <td>{props.data.address2}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>{props.data.city}</td>
        </tr>
        <tr>
          <th>State</th>
          <td>{props.data.state}</td>
        </tr>
        <tr>
          <th>Postal Code</th>
          <td>{props.data.postalCode}</td>
        </tr>
        <tr>
          <th>Response Number</th>
          <td>{props.data.responseId}</td>
        </tr>
        <tr>
          <th>Phone Number</th>
          <td>
            {formattedPhoneNumber(
              props.data.phone || props.data.phoneNumber,
              props.data.phoneExtension,
            )}
          </td>
        </tr>
        {/* <tr>
					{props.data.phoneExtension ? (
						<>
							<th>Extension</th>
							<td>{props.data.phoneExtension}</td>
						</>
					) : null}
				</tr> */}
        <tr>
          <th>Country</th>
          <td>{props.data.countryName}</td>
        </tr>
        <tr>
          <th>Bid Amount</th>
          <td>{props.data.totalPrice && `$${formatPrice(props.data.totalPrice, 2)}`}</td>
        </tr>
        <tr>
          <th>Alternate Bid Amount</th>
          <td>{props.data.altTotalPrice && `$${formatPrice(props.data.altTotalPrice, 2)}`}</td>
        </tr>
        <tr>
          <th>Notes</th>
          <td>{props.data.notes}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Tabel2;
