import { ProductApiResponse, ProductType } from './products';

export type Product = {
  productId: number;
  productName: string;
  productType: ProductType;
  price: number;
  productGroupId?: number;
};
/**
 * TOREFACTOR
 * There's no need for a ProductType to be automatically in an array.
 * This remains to insure no problems in legacy code,
 * but it should probably be replaced when it's used with Product[]
 */
export type productsType = Array<Product>;
export type dataParamType = {
  serviceText: Array<string>;
  services: Array<{ membershipLevelId: number; subscriptionStatusType: string }>;
  daysToRenew: number;
  expiryDate: Date;
  userType: string;
  nonFreeProducts: Product[];
  isMainContact: boolean;
  getProfileInfo: any;
  updateAutoRenewalSubscription: any;
};

export type cardNonceType = { nonce: string; details: { lastFour: number; cardType: string } };
export type cardParamType = { cardType: string; cardLastFour: number };

export interface PrepopulatedCounties {
  state: number;
  counties: number[];
}
export const defaultPrepopulatedCounties = {
  state: 0,
  counties: [],
};

export interface PrepopulatedProducts {
  state: number;
  stateName: string;
  counties: number[];
}
export const defaultPrepopulatedProducts = {
  state: 0,
  stateName: '',
  counties: [],
};

export interface ProrationMismatch {
  expectedCost: number;
  calculatedCost: number;
}

/**
 * TODO: currently all parameters are optional because they are set in a disjointed manner
 * 	this can be resolved by either separating interests, or having default values
 */
export interface SubscriptionDetails {
  addressInitialForm?: any; // TODO AddressFormProps exists in ../components/common/addressreduxform/addressform but it doesn't have all  fields needed here
  allProducts?: productsType; // TODO need a ProductType to be defined in a central and shared place. Different definitions currently exist elsewhere (src/types/products.ts, src/utls)
  calculatedPrice?: number | string;
  currentExpiryDate?: string;
  currentStep?: number;
  currentSubscribedCounties?: number[];
  currentSubscribedNational?: number[];
  currentSubscribedStates?: number[];
  expiryDate?: string;
  filteredProducts?: number[];
  memberType?: string;
  memberSubscriptionInfo?: any; // TODO need a MemberSubscriptionInfoType,
  orderNumber?: string;
  selectedState?: number;
  selectedCounties?: number[];
  subscribedCounties?: number[];
  subscribedCountiesGroup?: (string | number)[];
  subscribedNational?: number[];
  subscribedStates?: number[];
  token?: string;
  transaction?: Record<string, unknown>;
  existingSubscriptionDetails?: [];
  products?: [];
}
export const defaultSubscriptionDetails: SubscriptionDetails = {
  calculatedPrice: 0,
  currentSubscribedCounties: [],
  currentSubscribedNational: [],
  currentSubscribedStates: [],
  filteredProducts: [],
  memberSubscriptionInfo: {
    memberDetails: {
      daysToRenew: 0,
    },
  },
  selectedState: 0,
  selectedCounties: [],
  subscribedCounties: [],
  subscribedCountiesGroup: [],
  subscribedNational: [],
  subscribedStates: [],
};
