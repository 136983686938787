import jwt from 'jsonwebtoken';

import * as actionTypes from '../../store/actionTypes';

import Configurations from 'src/settings';

import {
  appstatetokenName,
  limitedloginPreviousUrlName,
  loginPreviousUrlName,
  memberCookieName,
  OpsTokenName,
  relogintokenName,
  storedCommoditiestokenName,
  themetokenName,
  tokenCookieName,
} from '../constants';
import {
  BROADCAST_CHANNEL_AUTH_ID,
  broadcastChannelLogout,
  broadcastChannelReload,
} from './broadcast-channel';

import { AuthToken } from '../token';
import { deleteCookie } from '../cookie';
import { track } from '../telemetry';

// Broadcast Channel logout handling
const bcAuth = new BroadcastChannel(BROADCAST_CHANNEL_AUTH_ID);

// Logout method
export const quietLogOutFn = () => {
  sessionStorage.setItem('isloggingout', '2');
  deleteCookie(tokenCookieName);
  deleteCookie(memberCookieName);
  deleteAspCookies();
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  sessionStorage.removeItem(loginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
  broadcastChannelLogout(bcAuth);
};

// Logout method
export const logOutFn = (isOps = false) => {
  sessionStorage.setItem('isloggingout', '2');
  deleteCookie(tokenCookieName);
  deleteCookie(memberCookieName);
  if (isOps) deleteCookie(OpsTokenName);
  deleteAspCookies();
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  sessionStorage.removeItem(loginPreviousUrlName);
  sessionStorage.removeItem(limitedloginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
  window.location.href = isOps
    ? `${Configurations.REACT_APP_OPS_URL}/Account/SignOut`
    : Configurations.REACT_APP_REDIRECT_HOME_URL;
  const { REQUEST } = actionTypes.LOGOUT as any;
  track(REQUEST as any, {});

  broadcastChannelLogout(bcAuth);
};

// Clear Storage method
export const clearStorageFn = (redirect = false, previousurl = false) => {
  console.log('CLEARSTORAGEFN');
  sessionStorage.setItem('isloggingout', '1');
  if (redirect) {
    sessionStorage.setItem('sessionExpired', '1');
    window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
  }
  localStorage.removeItem(appstatetokenName);
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
  if (previousurl) {
    sessionStorage.removeItem(loginPreviousUrlName);
  }
  sessionStorage.removeItem(limitedloginPreviousUrlName);
  sessionStorage.removeItem(relogintokenName);
};

// Clear Storage method during login
export const clearStorageLoginFn = () => {
  //localStorage.removeItem(appstatetokenName)
  localStorage.setItem(appstatetokenName, JSON.stringify({}));
  localStorage.removeItem(storedCommoditiestokenName);
  localStorage.removeItem(themetokenName);
};

export const clearTokenCookie = (code = '1') => {
  deleteCookie(tokenCookieName);
  sessionStorage.setItem('isloggingout', code);
  sessionStorage.setItem('sessionExpired', code);
};

export function deleteAspCookies() {
  deleteCookie('session');
  deleteCookie('LTV');
  deleteCookie('IDSERVER');
  deleteCookie('SID');
  deleteCookie('UID');

  const cookiesArr = document.cookie.split('; ');
  for (let i = 0; i < cookiesArr.length; i++) {
    const cItem = cookiesArr[i].split('=');
    if (cItem.length > 0 && cItem[0].indexOf('ASPSESSIONID') === 0) {
      deleteCookie(cItem[0]);
    }
  }
}

export const isExpiredToken = (token: string) => {
  const tokenData = jwt.decode(token) as AuthToken;
  // Assert(tokenData, 'We should not get a null value when decoding tokenData', 'helpers/auth.ts');
  if (tokenData && tokenData.exp && Date.now() > tokenData.exp * 1000) {
    return true;
  }
  return false;
};
