import { useEffect, useState } from 'react';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { allProductsState } from '../../../store/recoil/productState';
import { Link } from 'react-router-dom';
import { PrepopulatedCounties } from '../../../types/subscriptions';
import { prepopulatedCountiesState } from '../../../store/recoil/subscriptionState';
import { ProductType } from 'src/types/products';
import { supplierTexts } from '../../../utils/texts';
import { useAmplitude } from '../../amplitude';
import { AccountInfoState } from 'src/store/reducers/accountinfo';

type UpsellBasicProps = {
  accountInfo: AccountInfoState;
  currentRoute?: { page: string } | undefined;
};

const NotificationWrapper = styled.div`
  background: #f5f5f5;
  display: block;
  padding: 1rem;
  text-align: center;

  h1 {
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
  }
`;

export function UpsellBasic({ accountInfo, currentRoute }: UpsellBasicProps) {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const setPrepopulatedCounties =
    useSetRecoilState<PrepopulatedCounties>(prepopulatedCountiesState);
  const { logEvent } = useAmplitude();
  const [upsellCount, setUpsellCount] = useState<number>(0);
  const countyName =
    typeof accountInfo.membersinfo.county === 'object'
      ? accountInfo.membersinfo.county.name
      : accountInfo.membersinfo.county;
  const stateName =
    typeof accountInfo.membersinfo.state === 'object'
      ? accountInfo.membersinfo.state.name
      : accountInfo.membersinfo.state;

  useEffect(() => {
    if (allProducts.state === 'hasValue') {
      const county = allProducts?.contents?.find(
        product => product.productName === countyName && product.parentName === stateName,
      );
      const stateId = county?.parentId || 0;
      if (county) {
        const products = allProducts?.contents?.filter(product => {
          return (
            product.productType === ProductType.FreeAgency && product.parentId === county?.productId
          );
        });

        setUpsellCount(products.length);
        setPrepopulatedCounties({
          state: stateId,
          counties: [county.productId],
        });
      }
    }
  }, [allProducts, accountInfo, countyName, setPrepopulatedCounties, stateName]);

  return countyName && upsellCount > 0 ? (
    <div className='basic-upsell-card' data-testid='basic-upsell-card'>
      <div className='row'>
        <div className={currentRoute?.page === 'Supplier Bids Details' ? 'col-lg-12' : 'col-lg-6'}>
          <NotificationWrapper>
            <p>{supplierTexts.dashboard.upsellBasic.notifications}</p>
            <h1 data-testid='basic-upsell-count'>{upsellCount}</h1>
            <p>{supplierTexts.dashboard.upsellBasic.agenciesInCounty}</p>
            <Link
              data-testid='basic-upsell-btn'
              className='bttn-accent w-100 mb-2 btn-block text-center'
              title={supplierTexts.dashboard.upsellBasic.getMoreNotifications}
              rel='noopener noreferrer'
              to='/suppliers/plans'
              onClick={() =>
                logEvent('upgrade - click fomobox', { 'Member Type': accountInfo.mtn })
              }
            >
              {currentRoute?.page === 'Supplier Bids Details'
                ? supplierTexts.dashboard.upsellBasic.subscribeNow
                : supplierTexts.dashboard.upsellBasic.getMoreNotifications}
            </Link>
          </NotificationWrapper>
        </div>
      </div>
    </div>
  ) : null;
}
