import styled from 'styled-components';

import { BackgroundColor, Margin, MaximumPageWidth } from '../styles';

export const DSPageLayoutStyled = styled.div`
  background-color: ${BackgroundColor.BaseWhite};
  margin: ${Margin.None} auto ${Margin.Base} auto;
  max-width: ${MaximumPageWidth.SingleContentBlock};
  padding: 8.75rem 4rem 4rem 4rem;

  @media (max-width: 33rem) {
    padding-top: 10.3125rem;
  }

  @media (max-width: 47.9rem) {
    padding-top: 9.375rem;
  }

  @media (max-width: 82rem) {
    margin: ${Margin.None} ${Margin.Base} ${Margin.Base} ${Margin.Base};
  }

  h2 {
    margin-top: ${Margin.Base};
  }
`;
