import { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

import { DataTestId, Inactive } from '../../types';
import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import { AntDatePicker } from './AntDatePickerConfig';
import { BaseDatePickerContainer } from './DSDatePicker.styles';

export type DSDatePickerProps = FieldProps &
  DataTestId &
  Pick<PickerProps<Dayjs>, 'allowClear' | 'autoFocus' | 'disabledDate' | 'value'> &
  Inactive & {
    /** onChange which takes the DayJs object directly */
    onChange?: (date: Dayjs | null) => void;
  };

/** simple DatePicker - not for use with React-Hook-Form
 * @example
  <DSDatePicker
    label={requiredDocLabels.updateAllDueDates}
    disabledDate={isTodayOrEarlier}
    onChange={changeAllDueDates}
    name={'update-all-dates'}
    value={defaultDate}
  />
 */
export const DSDatePicker = (props: DSDatePickerProps) => {
  const {
    allowClear,
    autoFocus,
    dataTestId,
    disabledDate,
    inactive,
    label,
    name,
    onChange,
    value,
  } = props;
  const [format, setFormat] = useState('LL');

  const changeFormat = useCallback((open: boolean) => {
    setFormat(open ? 'l' : 'LL');
  }, []);

  function change(date: Dayjs | null, dateString: string) {
    if (onChange) {
      onChange(date);
    }
  }

  return (
    <FieldContainer {...getFieldContainerProps(props)}>
      <BaseDatePickerContainer inactive={inactive}>
        <AntDatePicker
          allowClear={allowClear}
          aria-disabled={inactive}
          aria-label={label || name}
          autoFocus={autoFocus}
          data-testid={dataTestId || name}
          disabled={inactive}
          disabledDate={disabledDate}
          format={format}
          id={name}
          inputReadOnly={inactive}
          name={name}
          onChange={change}
          onOpenChange={changeFormat}
          value={value}
        />
      </BaseDatePickerContainer>
    </FieldContainer>
  );
};
