import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Contactinfo from './contactinfo';
import EbidResponseControl from '../../common/breadcrumb/supplier/ebidresponsecontrol';
import { RequiredDoc } from './requiredoc';
import ReviewBid from './reviewbid';

import * as actionCreators from '../../../store/actions';
import { appKeys, MemberType, userPermissionKeys } from '../../../utils/constants';
import { hasPermission, toastFn } from '../../../utils/helpers';

import { EBidResponse } from './response';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardContainer } from '../../../components/customcontrols/wizard/WizardContainer';
import { WizardPage } from 'src/types/wizard';
import { useAuthContext } from 'src/auth/AuthProvider';

// TODO: TS4 - These props not provided from Redux are now all any and need to be
// typed appropriately again.
type PropsTypes = PropsFromRedux & {
  bidssummary?: any;
  docs?: any;
  getAccountInfo?: any;
  files_response?: any;
  files_response_error?: any;
  history?: any;
  match?: any;
  memberInfo?: any;
  progressvalue?: any;
  setEbidDetails?: any;
  setSharedDetails?: any;
  wizard?: any;
  isExternalBid?: any;
};

const EBids = (props: PropsTypes) => {
  const {
    progressvalue,
    history,
    files_response,
    setEbidDetails,
    files_response_error,
    docs,
    bidssummary,
    setSharedDetails,
    match,
    getAccountInfo,
  } = props;
  const { auth } = useAuthContext();
  const wizard: WizardPage[] = props.wizard || [];

  const { results = {} } = bidssummary;
  const { bidName = '', eBidding = '', dueDate = '' } = results;
  const bidID = match?.params?.bidId || '';
  const currentDate = new Date().toISOString();
  const currentDateTimeStamp = new Date(currentDate).getTime() as number;
  const dueDateTimeStamp = new Date(dueDate).getTime() as number;
  const hasBidRespondPermission =
    hasPermission(auth?.accountPermissions || '', userPermissionKeys.respondBids) ||
    auth?.mainContact;

  // Check page loaded based on click
  useEffect(() => {
    if (props?.isExternalBid === true) {
      history.push('/suppliers/bids/${bidID}/details');
    } else if (auth?.memberType !== MemberType.AgencyBuyer) {
      if (
        !bidID ||
        (eBidding && eBidding !== 'Submit' && eBidding !== 'ViewEdit') ||
        !hasBidRespondPermission
      )
        history.goBack();
    }
  }, [
    auth,
    bidID,
    currentDateTimeStamp,
    dueDate,
    dueDateTimeStamp,
    eBidding,
    hasBidRespondPermission,
    history,
    props?.isExternalBid,
  ]);

  useSetBreadcrumb({
    component: <EbidResponseControl />,
    page: 'Ebids Response',
    title: `${appKeys.headers.ebidResponse} - ${bidName}`,
    altname: 'My Ebid Response',
    name: bidName,
    id: bidID,
    menuactive: 'bids',
  });

  const { reqdocs, supplementaldocs } = docs;
  const alldocs = [...reqdocs, ...supplementaldocs];
  const doc = alldocs.filter(item => item.value && item.value.length && !item.bidDocId);

  // file upload error msg
  useEffect(() => {
    if (files_response && files_response.length === doc.length) {
      setEbidDetails({ files_response: '' });
    } else if (files_response_error) {
      setEbidDetails({ files_response_error: '' });
      const error =
        typeof files_response_error === 'object'
          ? files_response_error.response && files_response_error.response.data
            ? files_response_error.response.data.error
            : 'Upload Failed'
          : files_response_error;
      toastFn('error', error, 'document file upload api failed');
    }
  }, [doc, files_response, files_response_error, setEbidDetails]);

  useEffect(() => {
    return () => {
      setSharedDetails({ countieslist: [], othermemberinfo: '' });
      const resetFields = {
        responsedetails: { requiredDocuments: [], supplementalDocuments: [] },
        contactinfo: {},
      };
      setEbidDetails(resetFields);
    };
  }, [setSharedDetails, setEbidDetails]);

  useEffect(() => {
    return () => {
      setEbidDetails({ getEbitsResponseDetailsAPICall: true });
    };
  }, [setEbidDetails]);

  useEffect(() => {
    getAccountInfo({ auth });
  }, [auth, bidID, getAccountInfo]);

  // components used in the page (contact info, required docs, bids review submit)
  const component = {
    'Contact Information': <Contactinfo {...props} />,
    'Documents Upload': <RequiredDoc {...props} />,
    'Review Bid': <ReviewBid {...props} />,
  } as any;

  // fn to find which page to display currently based on redux value (status in wizard array)
  const [current] = wizard.filter(page => page.status === 'current');

  const [currentcomponent] = Object.keys(component)
    .filter(key => key === current.name)
    .map(item1 => component[item1]);

  return (
    <>
      <WizardContainer
        currentWizard={current}
        header={`${current.name === 'Review Bid' ? 'Review Your' : ''} eBid Response`}
        navTitle={'eBid Progress'}
        navComponent={<EBidResponse results={results} progressvalue={progressvalue} />}
        navComponentTitle={'Bid Details'}
        wizard={wizard}
      >
        {currentcomponent}
      </WizardContainer>
      {/* <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='sideBarSpacing clearfix '>
              <EBidResponse results={results} wizard={wizard} progressvalue={progressvalue} />
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='colWrapper clearfix'>
              <h3 className='arrowWrapper'>
                {current === 'Review Bid' ? 'Review Your' : null} eBid Response
              </h3>

            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const connector = connect(
  (state: any) => ({
    ...state.ebids,
    ...state.payment,
    bidssummary: state.bidssummary,
    shared: state.shared,
    memberinfo: state.memberinfo,
    ebids: state.ebids,
    accountinfo: state.accountinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(withRouter(EBids));
