import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useMemo } from 'react';

import {
  DSSelectClassPrefix,
  getKey,
  getLabel,
  getReactSelectProps,
  getSelectedOptions,
  getValue,
  isMultiValue,
} from './DSSelect.utils';
import { DSSelectProps, SelectOption } from './DSSelect.d';
import { DSSelectStyled, IconOption, styleOverrides } from '../../inputs/select';
import { FieldContainer, getFieldContainerProps } from '../../field-container/FieldContainer';
import { Assert } from '../../utils';

/** Select box to be used outside an RHF form.
 * @param props
 * @example
    <DSSelect
      isClearable
      label={texts.formLabels.supplier}
      message={texts.formMessages.supplier}
      name='supplier'
      optional
      labelField='name'
      valueField={id}
      options={suppliers}
      value={523434}
    />
 */
export function DSSelect<I extends SelectOption, O>(props: DSSelectProps<I, O>) {
  const {
    dataTestId,
    isMulti,
    keyField,
    labelField,
    name,
    onMultiSelect,
    onSelect,
    options,
    value,
    valueField,
  } = props;
  const testId = dataTestId || name;

  const formattedOptions: { label: string; value: O }[] = useMemo(() => {
    return options.map(option => {
      return {
        label: getLabel(option, labelField),
        value: getValue(option, valueField) as O,
        key: getKey(option, keyField),
      };
    });
  }, [keyField, labelField, options, valueField]);

  const SelectComponent = props.onCreateOption ? CreatableSelect : Select;

  const selectedOption = useMemo(() => {
    return getSelectedOptions(formattedOptions, isMulti, keyField, value);
  }, [formattedOptions, isMulti, keyField, value]);

  return (
    <FieldContainer {...getFieldContainerProps(props)}>
      <DSSelectStyled data-testid={testId}>
        <SelectComponent
          aria-errormessage={`${name}-error`}
          aria-label={name}
          // This is needed to keep us safe from SCSS styles.
          classNamePrefix={DSSelectClassPrefix}
          components={{ Option: IconOption }}
          inputId={name}
          onChange={selectable => {
            if (!isMultiValue(selectable)) {
              Assert(!isMulti, 'There should only be multiple values if we have passed isMulti');
              const value = selectable?.value as O;
              onSelect && onSelect(value);
            } else {
              Assert(
                isMulti && Array.isArray(selectable),
                'There should only be multiple values if we have passed isMulti',
              );
              const value = selectable.map(val => val.value as O);
              onMultiSelect && onMultiSelect(value);
            }
          }}
          options={formattedOptions}
          openMenuOnFocus
          value={selectedOption}
          styles={styleOverrides}
          {...getReactSelectProps<I, O>(props)}
        />
      </DSSelectStyled>
    </FieldContainer>
  );
}
