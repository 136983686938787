import { BackgroundColor, LineHeight } from '@demandstar/components/styles';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${BackgroundColor.BaseWhite};
    background: transparent linear-gradient(#1c525a,#3395a4);
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-feature-settings: 'tnum';
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: ${LineHeight.Body};
    margin: 0;
    width: 100%;
  }
`;
