import React from 'react';

import {
  ActionMessage,
  Colors,
  PanelItemWrapper,
  PanelLabel,
  PanelListItem,
  SolicitationTitle,
} from '../../../shared/styles';
import { RequiredAction, RequiredActionType } from '../../../types/required-action';
import {
  requiredDocLabels,
  vendorDocLabels,
} from '../../../components/buyer/awardbid/add-vendor-docs/constants';

import { DueDatePill } from '../../../components/common/controls/static/DueDatePill';
import { StatusPill } from '../../../components/common/controls/static/StatusPill';

export type RequiredActionsListPanelViewProps = {
  requiredActions: RequiredAction[];
  handleActionClick(requiredAction: RequiredAction): void;
  label: string;
  selectedRequiredActionId?: string;
};

/**
 * Presentation component for the Required Actions list
 * @param props
 * @returns
 */
export function RequiredActionsListPanelView(props: RequiredActionsListPanelViewProps) {
  const { requiredActions, handleActionClick, label, selectedRequiredActionId = '' } = props;

  const renderRequiredAction = (requiredAction: RequiredAction, index: number) => {
    const { actionId, dueDate, label, status, type } = requiredAction;

    const message =
      type === RequiredActionType.RequiredDocument
        ? label
        : vendorDocLabels.downloadProvidedDocuments;
    const displayLabel =
      type === RequiredActionType.RequiredDocument
        ? status !== 'Complete'
          ? requiredDocLabels.requiredActionMessageUpload
          : requiredDocLabels.requiredActionMessageUploadConplete
        : vendorDocLabels.documentsForYou;

    // For RequiredDocuments, show status pill with date-based theming
    // or 'Complete'(green) if a response has been uploaded.
    const pillComponent =
      type === RequiredActionType.RequiredDocument ? (
        status === 'Complete' ? (
          <StatusPill
            backgroundColor={Colors.white}
            borderColor={Colors.complete}
            textColor={Colors.complete}
            status={status}
          />
        ) : (
          <DueDatePill dueDate={dueDate} />
        )
      ) : (
        []
      );

    return (
      <PanelListItem
        key={`${actionId}_${index}`}
        onClick={() => handleActionClick(requiredAction)}
        title={`${message}`}
        selected={actionId === selectedRequiredActionId}
      >
        {pillComponent}
        <SolicitationTitle title={message}>{displayLabel}</SolicitationTitle>
        <ActionMessage>{message}.</ActionMessage>
      </PanelListItem>
    );
  };

  const returnContent = requiredActions?.map(renderRequiredAction);

  return (
    <>
      <PanelLabel>{label}</PanelLabel>
      <PanelItemWrapper>{returnContent}</PanelItemWrapper>
    </>
  );
}
