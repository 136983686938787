import styled from 'styled-components';

/** For when we want an input for HTML reasons, but want to hide it.
 * Uses `opacity: 0` instead of `display: none;` so that users can still tab to focus on the input.
 */
export const InvisibleInput = styled.input`
  position: absolute;
  opacity: 0;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;
