//TOREFACTOR:

import { memo, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Table } from 'reactstrap';

import { addbidParamType, broadcastListItemType } from '../../../types/addbid';
import {
  broadcastcancelText,
  broadcastconfirmText,
  confirmbroadcastsubText,
} from '../../../utils/texts';
import { Buttons, DeprecatedInput, Paging } from '../../customcontrols';
import { Searchingfn, toastFn } from '../../../utils/helpers';
import { AddBidWizardButtons } from './AddBidWizardButtons';
import { addquoteParamType } from '../../../types/addquote';
import { ConfirmModal } from '../modals/ConfirmModal';
import { loadingMsg } from '../../../utils/texts';
import { ModalPopUp } from '../modals/ModalPopUp';
import { pageSize } from '../../../utils/constants';
import { sharedTypes } from '../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardPage } from '../../../types/wizard';

import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import AddQuotesControl from '../breadcrumb/buyer/addquotescontrol';
import CommodityOptionalText from './commodityoptionaltext';
import GenerateNewList from './modals/generatenewlist';
import NoResult from '../../customcontrols/noresult';
import Prebuiltlist from './modals/prebuiltlist';
import QFormbuttons from '../createquotes/formbuttons';
import SaveList from './modals/savelist';
import UploadList from './modals/uploadlist';

interface PropsTypes {
  handleSubmit?: any;
  setAddBidDetails?: any;
  addbid?: addbidParamType;
  currentwizard: WizardPage;
  bidId?: number;
  valid?: Record<string, unknown> | undefined;
  pristine?: Record<string, unknown> | undefined;
  submitting?: Record<string, unknown> | undefined;
  submitAddBidAddList?: any;
  getAddBidIsConstruction?: any;
  setDeleteBroadcastSuppliers?: any;
  getAddBidBroadcastListId?: any;
  getAddBidBroadcastMembers?: any;
  currentBidName?: string;
  pagefor?: string;
  addquotes?: addquoteParamType;
  setAddQuotesDetails?: any;
  quotesId?: number | string;
  quotecurrentwizard?: WizardPage;
  submitAddQuotesMoveNext?: any;
  currentQuotesName?: string;
  checkedit?: boolean;
  shared?: sharedTypes;
  cancel?: any;
  SkipNext?: any;
  submitAddBidMoveNext?: any;
}

function Bidbroadcastlist(props: PropsTypes) {
  const {
    setAddBidDetails,
    addbid,
    currentwizard,
    bidId = 0,
    valid,
    pristine,
    submitting,
    submitAddBidAddList,
    getAddBidIsConstruction,
    setDeleteBroadcastSuppliers,
    getAddBidBroadcastListId,
    getAddBidBroadcastMembers,
    currentBidName,
    pagefor = '',
    quotesId = 0,
    quotecurrentwizard,
    submitAddQuotesMoveNext,
    currentQuotesName,
    checkedit,
    shared,
    submitAddBidMoveNext,
  } = props;
  const {
    prebuiltmodal = false,
    newlistmodal = false,
    uploadlistmodal = false,
    broadcastinglist = [],
    broadcastLists = [],
    savelistmodal = false,
    newlistname = '',
    savelistExistmodal,
    editable,
    broadcastListId,
  } = addbid || {};
  const { internalLoader = false } = shared || {};
  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  useEffect(() => {
    let getAddBidBroadcastMembersAPICALL = true;
    if (id && type && getAddBidBroadcastMembersAPICALL) {
      getAddBidBroadcastMembers({ id, type });
    }
    return () => {
      getAddBidBroadcastMembersAPICALL = false;
    };
  }, [getAddBidBroadcastMembers, id, type]);

  useEffect(() => {
    let getAddBidBroadcastListIdAPICALL = true;
    if (!broadcastListId && getAddBidBroadcastListIdAPICALL) {
      getAddBidBroadcastListId({ type, id });
    }
    return () => {
      getAddBidBroadcastListIdAPICALL = false;
    };
  }, [id, getAddBidBroadcastListId, broadcastListId, type]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  //TODO: Fix casing below.
  let breadcrumbtitle = currentBidName
    ? `Update Bid - ${currentwizard && currentwizard.name}`
    : `Bid Creation - ${currentwizard && currentwizard.name}`;
  if (pagefor === 'addquotes') {
    breadcrumbtitle = currentQuotesName
      ? `Edit Quote - ${quotecurrentwizard && quotecurrentwizard.name}`
      : `Quote Creation - ${quotecurrentwizard && quotecurrentwizard.name}`;
  }
  let breadcrumbName = currentBidName ? currentBidName : 'Bid Creation';
  if (pagefor === 'addquotes') {
    breadcrumbName = currentQuotesName ? currentQuotesName : 'Quote Creation';
  }
  let breadcrumbpage = currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation';
  if (pagefor === 'addquotes') {
    breadcrumbpage = currentQuotesName ? 'Agency Quote Update' : 'Agency Quote Creation';
  }
  const breadcrumbmenuactive = pagefor === 'addquotes' ? 'quotes' : 'bids';
  const breadcrumbid = pagefor === 'addquotes' ? quotesId : bidId;
  const breadcrumbComponent =
    pagefor !== 'addquotes' ? (
      <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
    ) : (
      <AddQuotesControl
        valid={valid}
        pristine={pristine}
        submitting={submitting}
        quotesId={quotesId}
      />
    );

  useSetBreadcrumb({
    component: breadcrumbComponent,
    page: breadcrumbpage,
    title: breadcrumbtitle,
    altname: breadcrumbtitle,
    name: breadcrumbName,
    id: breadcrumbid,
    menuactive: breadcrumbmenuactive,
  });

  useEffect(() => {
    setCurrentPages(1);
  }, [broadcastLists, broadcastinglist]);

  const Setprebuiltmodal = () => {
    setAddBidDetails({ prebuiltmodal: !prebuiltmodal });
  };

  const setNewListModal = () => {
    setCurrentPages(1);
    setAddBidDetails({
      newlistmodal: !newlistmodal,
      broadcastListssearchShow: false,
      broadcastListssearch: [],
    });
  };

  const setUploadListModal = () => {
    setAddBidDetails({ uploadlistmodal: !uploadlistmodal });
  };

  const Setsavelistmodal = () => {
    setAddBidDetails({ newlistname: '', savelistmodal: !savelistmodal });
  };

  // Logic for displaying pagination
  const [currentPages, setCurrentPages] = useState(1);
  const listPerPages = pageSize.default;
  const indexOfLastLists = currentPages * listPerPages;
  const indexOfFirstLists = indexOfLastLists - listPerPages;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSkip, setConfirmSkip] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState('');

  const onConfirm = () => {
    setConfirmDelete(false);
    setDeleteBroadcastSuppliers({ blockedMemberID: selectedDeleteItem, bidId, quoteId: quotesId });
  };

  const deleteBroadcastSupplier = (memberId: any) => {
    setConfirmDelete(true);
    setSelectedDeleteItem(memberId);
  };

  const ListComponent = (internallisting: broadcastListItemType[] = []) => {
    return (
      <>
        <h4>Suppliers ({internallisting.length})</h4>
        <div className='innerColIndent clearfix'>
          <Table className='tableHData'>
            <tr>
              <th>Supplier</th>
              <th>City, State</th>
              {editable === 'all' && <th></th>}
            </tr>
            {internallisting && internallisting.length > 0 ? (
              <>
                {internallisting
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>
                        {item.city}
                        {item.city && item.state && ', '}
                        {item.state}
                      </td>
                      {editable === 'all' ? (
                        <td>
                          <span
                            className='mdi mdi-delete-forever-outline mdi-24px staticLink ml-3 float-right'
                            title='Delete'
                            onClick={() => deleteBroadcastSupplier(item.mi)}
                          />
                        </td>
                      ) : null}
                    </tr>
                  ))
                  .slice(indexOfFirstLists, indexOfLastLists)}
              </>
            ) : (
              <tr>
                <td colSpan={2}>
                  <NoResult message={internalLoader ? loadingMsg : 'No Suppliers Available'} />
                </td>
              </tr>
            )}
          </Table>
          <Paging
            totalRecords={internallisting.length}
            currentPage={currentPages}
            onPagingClick={(value: number) => {
              if (value !== currentPages) setCurrentPages(Number(value));
            }}
            limitmsg={false}
            pageLimit={listPerPages}
          />
        </div>
      </>
    );
  };

  const [searchText, setSearchText] = useState('');
  const [supplierSearchList, setSupplierSearchList] = useState([] as any);

  const searchSupplierLists = (name: string, value: any) => {
    if (value) {
      setSearchText(value);
      const filterResult = Searchingfn(broadcastinglist, ['name', 'city', 'state'], value) as any;
      setSupplierSearchList(filterResult);
      if (currentPages !== 1) setCurrentPages(1);
    } else {
      setSearchText('');
      setSupplierSearchList([]);
      if (currentPages !== 1) setCurrentPages(1);
    }
  };

  const onSubmitFn = (data: any) => {
    if (data) {
      if (broadcastinglist.length > 0) {
        if (pagefor === 'addquotes') {
          submitAddQuotesMoveNext({ id: quotesId, type: 'Quote', quoteStep: 4 });
        } else {
          getAddBidIsConstruction({ bidId, broadcastlistnextbtn: true });
        }
      } else {
        if (pagefor === 'addquotes') {
          toastFn('error', 'Supplier is Required', 'add bid supplier required');
        } else {
          setConfirmSkip(true);
        }
      }
    }
  };

  function toggleConfirmDelete() {
    setConfirmDelete(!confirmDelete);
  }

  function toggleConfirmSkip() {
    setConfirmSkip(!confirmSkip);
  }

  return (
    <>
      <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
        {formikProps => {
          const { handleSubmit } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              {editable === 'all' ? (
                <>
                  {/* <h4>Build Broadcast List</h4> */}
                  <CommodityOptionalText pagefor={pagefor} />

                  <div className='innerColIndent'>
                    <div className='d-flex justify-content-around'>
                      <Buttons
                        classNames='bttn-secondary mt-4 mb-5'
                        text='Load Pre-Built List'
                        title='Load Pre-Built List'
                        type='button'
                        onClick={Setprebuiltmodal}
                      />
                      <Buttons
                        classNames='bttn-secondary mt-4 mb-5'
                        text={broadcastinglist.length > 0 ? 'Refine List' : 'Generate New List'}
                        type='button'
                        onClick={setNewListModal}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {broadcastinglist.length > 0 && (
                <DeprecatedInput
                  type='search'
                  placeholder='Search Suppliers'
                  label='Search'
                  name='searchText'
                  value={searchText}
                  handleChange={searchSupplierLists}
                  parentClass='mb-3'
                />
              )}
              {ListComponent(searchText ? (supplierSearchList as any) : (broadcastinglist as any))}
              {pagefor === 'addquotes' ? (
                <QFormbuttons {...props} />
              ) : (
                <AddBidWizardButtons currentWizard={props.currentwizard} submit={handleSubmit} />
              )}
            </form>
          );
        }}
      </Formik>

      <ModalPopUp
        title='Saved Broadcast Lists'
        closeModal={Setprebuiltmodal}
        isOpen={prebuiltmodal}
        backdrop='static'
      >
        <Prebuiltlist
          {...props}
          Setprebuiltmodal={Setprebuiltmodal}
          pagefor={pagefor}
          quotesId={quotesId}
        />
      </ModalPopUp>
      <ModalPopUp
        size='xxl'
        title={
          broadcastinglist.length > 0
            ? 'Refine List - Select Commodity Codes'
            : 'Generate New List - Select Commodity Codes'
        }
        closeModal={setNewListModal}
        isOpen={newlistmodal}
        backdrop='static'
        className='selectBroadcast'
        // ? what does this class name do? consider using a style component
        // ? and removing from here and from ModalPopUp.
      >
        <GenerateNewList
          {...props}
          Setnewlistmodal={setNewListModal}
          ListComponent={ListComponent}
          bidId={bidId}
          pagefor={pagefor ? pagefor : 'addbid'}
          quotesId={quotesId}
          checkedit={checkedit}
          setCurrentPages={setCurrentPages}
        />
      </ModalPopUp>
      <ModalPopUp
        title='Upload A Broadcast List'
        closeModal={setUploadListModal}
        isOpen={uploadlistmodal}
        backdrop='static'
      >
        <UploadList />
      </ModalPopUp>
      <ModalPopUp
        title='Save Supplier List'
        closeModal={Setsavelistmodal}
        isOpen={savelistmodal}
        backdrop='static'
        size='md'
      >
        <SaveList
          setAddBidDetails={setAddBidDetails}
          newlistname={newlistname}
          Setsavelistmodal={Setsavelistmodal}
          submitAddBidAddList={submitAddBidAddList}
          bidId={bidId}
          savelistExistmodal={savelistExistmodal}
          pagefor={pagefor}
          quotesId={quotesId}
        />
      </ModalPopUp>
      <ConfirmModal
        onConfirm={onConfirm}
        closeModal={toggleConfirmDelete}
        title='Confirm Delete'
        isOpen={confirmDelete}
        danger='delete'
        size='md'
      >{`This supplier will be removed from broadcasting supplier list for this ${
        bidId ? 'bid' : 'quote'
      }. Are you sure want to delete this supplier?`}</ConfirmModal>
      <ConfirmModal
        onConfirm={() => submitAddBidMoveNext({ type: 'Bid', Id: bidId, stepId: 6 })}
        closeModal={toggleConfirmSkip}
        confirmText={broadcastconfirmText}
        cancelText={broadcastcancelText}
        title='Confirmation'
        isOpen={confirmSkip}
        size='xl'
      >
        {confirmbroadcastsubText}
      </ConfirmModal>
    </>
  );
}

export default memo(Bidbroadcastlist);
