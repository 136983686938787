import { useRecoilCallback, useRecoilState } from 'recoil';
import { useMemo } from 'react';

import { allProductsState, selectedProductsState } from 'src/store/recoil/productState';
import {
  NationalProductId,
  ProductApiResponse,
  ProductType,
  SubscriptionProducts,
} from 'src/types/products';
import { removeDuplicates } from 'src/utils/helpers';
import { useAccountInfo } from './useAccountInfo';
import { useRecoilLoadableContents } from './useRecoilLoadableContents';

export function useSelectedProducts() {
  const allProducts = useRecoilLoadableContents(allProductsState);
  const {
    accountInfo: { products },
  } = useAccountInfo();

  const [selectedProducts, setSelectedProducts] = useRecoilState(selectedProductsState);

  /** Memoized conversion of account's `products` to `ProductApiResponse` types */
  const subscribedProducts: ProductApiResponse[] = useMemo(() => {
    const expanded = [] as ProductApiResponse[];

    if (allProducts && products?.length) {
      products.forEach(sp => {
        const prd = allProducts.find(ap => ap.productId === sp.productId);
        if (prd) expanded.push(prd);
      });
    }

    return expanded;
  }, [allProducts, products]);

  /** Categorization & flattening of subscribedProducts by product type for easier computation below */
  const { subscribedCountyIds, subscribedStateIds } = useMemo(() => {
    /** existing subscription isn't needed in full currently, but leaving unused attributes commented out
     * for easy enabling in the future, could be worth returning from the hook for use elsewhere */
    const existingSub: SubscriptionProducts = {
      // agency: subscribedProducts.find(pr => pr.productType === ProductType.FreeAgency),
      county: subscribedProducts.filter(pr => pr.productType === ProductType.County),
      state: subscribedProducts.filter(pr => pr.productType === ProductType.State),
      // national: subscribedProducts.find(pr => pr.productType === ProductType.National)?.productId,
    };
    const countyIds = existingSub.county?.map(p => p.productId) ?? [];
    const stateIds = existingSub.state?.map(p => p.productId) ?? [];
    return {
      existingSubscription: existingSub,
      subscribedCountyIds: countyIds,
      subscribedStateIds: stateIds,
    };
  }, [subscribedProducts]);

  /**
   * @description removes the provided product from the cart
   * @param product - ProductApiResponse - the product to remove from the cart
   * @returns void
   * @example removeProduct({
   *  productId: 1,
   *  productType: ProductType.County,
   * })
   */
  const removeProduct = useRecoilCallback(
    ({ set }) =>
      ({ productId, productType, parentId }: ProductApiResponse) => {
        // Remove a sub-product from a national subscription
        if (selectedProducts.national && productType !== ProductType.National) {
          // Get all state products (other than the one being removed) to which the member is not already subscribed
          const selectedStates = (
            allProducts?.filter(st => st.productType === ProductType.State) ?? []
          ).filter(st => !subscribedStateIds.includes(st.productId)); // Removes already subscribed states

          // If the product being removed is a State
          if (productType === ProductType.State) {
            // Set states to be states from above & de-select any counties from the state that is being removed
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
              state: selectedStates?.filter(x => x.productId !== productId),
              county: selectedProducts.county?.filter(c => c.parentId !== productId),
            });
          } else if (productType === ProductType.County) {
            /** For removing county, get all counties in the same state (other than the one being removed)
             * to which the member is not already subscribed */
            const selectedCounties = allProducts
              ?.filter(
                ct =>
                  ct.productType === ProductType.County &&
                  ct.parentId &&
                  parentId &&
                  ct.parentId === parentId &&
                  ct.productId !== productId,
              )
              ?.filter(ct => !subscribedCountyIds.includes(ct.productId)); // Removes already subscribed counties

            // Set states & counties to result sets determined above
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
              state: selectedStates.filter(x => x.productId !== parentId),
              county: selectedCounties ?? [],
            });
          }
          return;
        }

        // Removing a product w/o a selected national subscription
        switch (productType) {
          case ProductType.County:
            const selectedParent = selectedProducts.state?.find(
              st => parentId && st.productId === parentId,
            );
            let counties = selectedProducts.county;

            // If the parent state of the county to be removed is selected
            if (selectedParent) {
              // Get the other counties in the same state as the one being removed & add them to selection
              counties = allProducts
                ?.filter(
                  ct =>
                    ct.productType === ProductType.County &&
                    ct.parentId &&
                    parentId &&
                    ct.parentId === parentId &&
                    ct.productId !== productId,
                )
                ?.filter(ct => !subscribedCountyIds.includes(ct.productId)) // Removes already subscribed counties
                .concat(selectedProducts.county?.filter(c => c.parentId !== parentId) ?? []);
            } else {
              // Otherwise, we just remove this county w/o extra work
              counties = selectedProducts.county?.filter(c => c.productId !== productId) ?? [];
            }

            // Remove the parent state, if selected, and set counties to the value determined above
            set(selectedProductsState, {
              ...selectedProducts,
              state: selectedProducts.state?.filter(
                st => st.productId !== selectedParent?.productId,
              ),
              county: counties,
            });

            break;
          // State & National removals, at this point, behave normally (i.e. just remove product from selection)
          case ProductType.State:
            const states = selectedProducts.state?.filter(s => s.productId !== productId) ?? [];
            set(selectedProductsState, {
              ...selectedProducts,
              state: states,
            });
            break;
          case ProductType.National:
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.None,
            });
            break;
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allProducts, selectedProducts],
  );

  /**
   * @description adds the provided product to the cart
   * @param product - ProductApiResponse - the product to add to the cart
   * @returns void
   * @example addProduct(product)
   */
  /**
   * @description adds the provided product to the cart
   * @param product - ProductApiResponse - the product to add to the cart
   * @returns void
   * @example addProduct(product)
   */
  const addProduct = useRecoilCallback(
    ({ set }) =>
      (product: ProductApiResponse) => {
        let productType = product.productType;

        if (productType === ProductType.County) {
          // The products that will be selected after adding this one
          const newCounties = (selectedProducts.county ?? []).concat(product);
          // The unsubscribed sibling products of this county
          const siblingCounties =
            allProducts?.filter(
              pr =>
                pr.parentId &&
                pr.parentId === product.parentId &&
                pr.productType === ProductType.County &&
                !subscribedCountyIds.includes(pr.productId),
            ) ?? [];

          // Save an iteration over the newCounties array if it is not possible all of the siblings have been selected
          if (siblingCounties.length <= newCounties.length) {
            const selectedSiblings = newCounties.filter(
              pr => pr.parentId && pr.parentId === product.parentId,
            );

            // If all selectable siblings are selected, add the state instead
            if (selectedSiblings.length === siblingCounties.length) {
              const parentState = allProducts?.filter(pr => pr.productId === product.parentId)[0];

              // Call `addProduct` with the state, because we then need to check if it is the last state available
              if (parentState) addProduct(parentState);

              return;
            }
          }
        } else if (productType === ProductType.State) {
          // All states to which the user is not already subscribed
          const allStates =
            allProducts?.filter(
              pr =>
                pr.productType === ProductType.State && !subscribedStateIds.includes(pr.productId),
            ) ?? [];
          // States in cart
          const newStates = (selectedProducts.state ?? []).concat(product);

          // If selecting the last available state, select national instead
          if (newStates.length === allStates.length) {
            productType = ProductType.National;
          }
        }

        switch (productType) {
          case ProductType.County:
            set(selectedProductsState, {
              ...selectedProducts,
              county: (selectedProducts.county ?? []).concat(product),
            });
            break;
          case ProductType.State:
            set(selectedProductsState, {
              ...selectedProducts,
              state: (selectedProducts.state ?? []).concat(product),
            });
            break;
          case ProductType.National:
            set(selectedProductsState, {
              ...selectedProducts,
              national: NationalProductId.UnitedStates,
            });
            break;
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allProducts, selectedProducts],
  );

  /**
   * @description clears any accidentally added duplicates from the cart
   * @returns void
   * @example clearDuplicateProducts()
   */
  const clearDuplicateProducts = useRecoilCallback(
    ({ set }) =>
      () => {
        const uniqueCounties = removeDuplicates(selectedProducts.county ?? [], 'productId').filter(
          ct => !subscribedCountyIds.includes(ct.productId),
        );
        const uniqueStates = removeDuplicates(selectedProducts.state ?? [], 'productId').filter(
          st => !subscribedStateIds.includes(st.productId),
        );

        if (
          uniqueStates.length < (selectedProducts.state?.length ?? 0) ||
          uniqueCounties.length < (selectedProducts.county?.length ?? 0)
        ) {
          set(selectedProductsState, {
            ...selectedProducts,
            county: uniqueCounties,
            state: uniqueStates,
          });
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProducts.county, selectedProducts.state],
  );

  return {
    addProduct,
    clearDuplicateProducts,
    removeProduct,
    selectedProducts,
    setSelectedProducts,
    subscribedProducts,
  };
}
