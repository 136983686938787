import { Assert } from '@demandstar/components/utils';

import {
  AwardeeContact,
  ContractDetailsAPI,
  ContractDetailsDraft,
  ContractSearchResultAPI,
  MemberAward,
} from 'src/features/contract-management/contract-management.d';
import {
  axiosBarePostAuthenticated,
  axiosBareRequestAuthenticated,
  axiosPostAuthenticated,
} from '../../store/services/api';

import { constants } from '../../utils/settings';
import { paths } from './ContractManagement.paths';
import { Paths } from '../../utils/constants';

/**
 * Get Contract Request
 * @param {string} contractId
 */
export function getContract(contractId: string) {
  return axiosBareRequestAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    url: paths.api.contracts.get(contractId),
  });
}

export function getAllContracts() {
  return axiosBarePostAuthenticated<ContractSearchResultAPI[]>({
    baseURL: constants.api.contractUrl,
    url: paths.api.contracts.search,
  });
}

/**
 * Update Contract Request
 * @param {ContractDetails} contract
 */
export function updateContract(contract: ContractDetailsDraft) {
  return axiosBarePostAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    data: {
      ...contract,
    },
    url: contract.id ? paths.api.contracts.update(contract.id) : paths.api.contracts.create,
  });
}

/**
 * Publish Contract
 * @param {ContractDetails} contract
 */
export function publishContract(contractId: string) {
  return axiosBarePostAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    url: paths.api.contracts.publish(contractId),
  });
}

/**
 * Add AwardeeContact
 * @param {AwardeeContact} contact awardee contact
 */
export function addAwardeeContact(contractId: string, contact: AwardeeContact) {
  Assert(!contact.id, 'Contact must not have an id if we are adding');
  return axiosBarePostAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    data: [contact],
    url: paths.api.awardees.add(contractId),
  });
}

/**
 * Edit AwardeeContact
 * @param {AwardeeContact} contact awardee contact
 */
export function updateAwardeeContact(contractId: string, contact: AwardeeContact) {
  Assert(contact.id, 'Contact must have an id if we are updating');
  return axiosBarePostAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    data: contact,
    url: paths.api.awardees.update(contractId, contact.id),
  });
}

/**
 * Delete AwardeeContact */
export function deleteAwardeeContactAPI(contractId: string, awardeeId: string) {
  return axiosBareRequestAuthenticated<ContractDetailsAPI>({
    baseURL: constants.api.contractUrl,
    url: paths.api.awardees.delete(contractId, awardeeId),
    method: 'DELETE',
  });
}

/**
 * Get Member Awards
 */
export function getMemberAwards(): Promise<MemberAward[]> {
  return axiosPostAuthenticated({
    baseURL: constants.api.url,
    url: Paths.Award.memberAwards,
  });
}
