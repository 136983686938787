import React, { useEffect, useState } from 'react';

import { DSPaginationControl, PaginationControlProps } from './DSPaginationControl';

export type PaginateProps<T> = {
  /** The render function for the paginated data
   * @warning memoize when applicable */
  children?: (data: T[]) => React.ReactNode;
  /** The data to paginate  */
  data: T[];
  /** Optional function called when a new page is selected, page data will update automatically */
  onPageChange?: (page: { selected: number }) => void;
} & Omit<PaginationControlProps, 'activePage' | 'totalCount' | 'onPageChange'>;

/**
 * @description Manages pagination for the array of provided data
 * @default {marginPagesDisplayed: 2, pageRangeDisplayed: 3, pageSize: 10, showLabel: true}
 * @example <DSPaginate data={[]}><Child /></DSPaginate>
 */
export function DSPaginate<T>({
  children,
  deepLinkParameter,
  disableDeepLink,
  data,
  marginPagesDisplayed = 2,
  onPageChange = undefined,
  pageRangeDisplayed = 3,
  pageSize = 10,
  showLabel = true,
}: PaginateProps<T>) {
  const [pagedData, setPagedData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length !== totalCount) {
      resetPagination();
    }
    setTotalCount(data.length);
    // Slice from page * pageSize to page * pageSize + pageSize
    // I forget sometimes slice is exclusive of its end item
    // Page 0 = 0*10 and 0*10+10 (0,10)
    // Page 1 = 1*10 and 1*10+10 (10,20)
    const start = activePage * pageSize;
    // if the page has less than pageSize number of items
    const end = Math.min(activePage * pageSize + pageSize, data.length);
    setPagedData(data.slice(start, end));
  }, [data, pageSize, activePage, totalCount]);

  const resetPagination = () => {
    setActivePage(0);
    setPagedData([]);
  };

  const handlePageChange = (page: { selected: number }) => {
    setActivePage(page.selected);

    /* istanbul ignore else */
    if (onPageChange !== undefined) {
      onPageChange(page);
    }
  };

  return (
    <>
      {children && children(pagedData)}

      <DSPaginationControl
        activePage={activePage}
        deepLinkParameter={deepLinkParameter}
        disableDeepLink={disableDeepLink}
        marginPagesDisplayed={marginPagesDisplayed}
        onPageChange={handlePageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        pageSize={pageSize}
        showLabel={showLabel}
        totalCount={totalCount}
      />
    </>
  );
}
