import { emailVal } from '@demandstar/components/utils';

import { canadaZipCodeRegex, CountryId, phoneVal, usaZipCodeRegex } from '../../../utils/constants';

const Validations = (values: any) => {
  const errors: any = {};

  const optional = ['address2', 'county', 'website'];
  if (values.country && values.country.value === CountryId.Canada) {
    optional.push('metro');
  }

  const required =
    Object.keys(values)
      .filter(items => !optional.includes(items))
      .map(items => ({ name: items, value: values[items] })) || [];

  required.forEach(items => {
    if (!items.value) {
      errors[items.name] = 'Required';
    }
  });

  if (values.email && !emailVal.test(values.email.trimStart().trimEnd())) {
    errors.email = 'Invalid';
  }
  if (values.phone && !phoneVal.test(values.phone.trimStart().trimEnd())) {
    errors.phone = 'Invalid';
  }
  if (values.phonenumber && !phoneVal.test(values.phonenumber.trimStart().trimEnd())) {
    errors.phonenumber = 'Invalid';
  }
  if (
    values.country &&
    values.country.value === CountryId.UnitedStates &&
    values.postalcode &&
    !usaZipCodeRegex.test(values.postalcode.trimStart().trimEnd())
  ) {
    errors.postalcode = 'Invalid';
  }
  if (
    values.country &&
    values.country.value === CountryId.Canada &&
    values.postalcode &&
    !canadaZipCodeRegex.test(values.postalcode.trimStart().trimEnd().toUpperCase())
  ) {
    errors.postalcode = 'Invalid';
  }
  if (
    values.website &&
    !/^((http|https|HTTP|HTTPS):\/\/)?(WWW.|www.)?(?!.*(http|https|HTTP|HTTPS|WWW.|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/.test(
      values.website.trimStart().trimEnd().toLowerCase(),
    )
  ) {
    errors.website = 'Invalid';
  }
  return errors;
};

export default Validations;
