import { WizardPage, WizardStatus } from 'src/types/wizard';
import { Assert } from './testing';

/**
 * @deprecated
 * add a wizard status page to a wizard
 * Ideally, we would have an intelligent memoized constant in a hook
 * which updates automatically, based on how much the user has filled out.
 * */
export function addPageToWizard(
  wizardPages: WizardPage<number, string>[],
  newPage: WizardPage,
  position: number,
  action: boolean,
) {
  Assert(
    newPage && (position || position === 0),
    'We must have an object and position when calling `addPageToWizard',
  );
  let wizard: WizardPage[];
  if (action) {
    wizard = wizardPages.map(page => {
      if (page.id >= position) {
        page.id = page.id + 1;
      }
      return page;
    });
    wizard = [...wizard, newPage];
  } else {
    wizard = wizardPages.filter(page => page.id !== position);
    wizard = wizard.map(page => {
      if (page.id > position) {
        page.id = page.id - 1;
      }
      return page;
    });
  }
  wizard = wizard.sort((a, b) => a.id - b.id);
  const newWizard: WizardPage[] = wizard.map((page, index) => {
    return {
      ...page,
      id: index + 1,
    };
  });
  return [...newWizard];
}

/** To set a wizard manually
 * @param  {WizardPage[]} wizard - the full array of WizardPages
 * @param  {number} pageId - the pageId you want to set to current
 */
export const setWizardStatus = (wizard: WizardPage[], pageId: number) => {
  const wiz = wizard.map(page => {
    if (page.id > pageId) {
      page.status = WizardStatus.Unavailable;
    }
    if (page.id < pageId) {
      page.status = WizardStatus.Completed;
    }
    if (page.id === pageId) {
      page.status = WizardStatus.Current;
    }
    return page;
  });
  return [...wiz];
};
