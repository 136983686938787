import { connect, ConnectedProps } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';

import * as actionCreators from '../../../../store/actions';
import { MembershipLevel, Permission } from '../../../../types/auth';
import { Buttons } from '../../../customcontrols';
import { leavingAlertMessage } from '../../../../utils/texts';
import { MemberType } from '../../../../utils/constants';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { setWizardStatus } from '../../../../utils/helpers';
import { WizardContainer } from '../../../../components/customcontrols/wizard/WizardContainer';
import { WizardPage } from '../../../../types/wizard';

import AddSupplementalSuppliers from '../../../common/createbid/modals/addsupplementalsuppliers';
import BidBoardcastList from '../../../common/createbid/bidbroadcastlist';
import QDocumentUpload from '../../../common/createquotes/qdocumentupload';
import QuotesInformation from '../../../common/createquotes/quoteinfo';
import QuoteWire from '../../../common/createquotes/quotewire';
import SupplementalSuppliers from '../../../common/createbid/supplementalsuppliers';
import { useAuthContext } from 'src/auth/AuthProvider';

interface PropsTypes extends PropsFromRedux {
  addquoteswizard: WizardPage[];
  history: any;
  resetAddQuotesDetails: any;
  resetCommodityCodes: any;
  match: any;
}

function AddQuotes(props: PropsTypes) {
  const {
    addquotes,
    history,
    setAddQuotesDetails,
    quoteSummary,
    addbid,
    setAddBidDetails,
    getAddQuotesCompletedPage,
    resetAddQuotesDetails,
    resetCommodityCodes,
  } = props;
  const { ...otherprops } = props;
  const { auth } = useAuthContext();
  const {
    addquoteswizard = [],
    halfWayCancel,
    halfWayCancelAllow,
    addquotesinfo,
    addquoteswire,
  } = addquotes || {};
  const { addsupplementalmodal, addsupplementalvalues, isExistsSupplier } = addbid || {};
  const currentwizard = addquoteswizard.find((item: any) => item.status === 'current') || {};
  const EditquotesId = parseInt(props?.match?.params?.bidId) || '';
  const { summary = {} } = quoteSummary || {};
  const { quoteName = '', statusType = '', memberId = '' } = summary || {};
  const mlcheck = auth?.membershipLevels.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];
  let checkedit = props?.match?.path?.split('/') || [];
  checkedit = checkedit.find((item: any) => item === 'edit') || '';
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState('' as any);

  useEffect(() => {
    if (auth?.memberType === MemberType.BasicSupplier) history.replace('/');
  }, [auth, history]);

  useEffect(() => {
    if (EditquotesId && statusType && statusType !== 'IN') history.replace('/');
  }, [EditquotesId, history, statusType]);

  useEffect(() => {
    if (auth?.memberId && memberId && EditquotesId) {
      if (auth?.memberId !== memberId) {
        history.replace('/');
      }
    }
  }, [auth, EditquotesId, history, memberId]);

  useEffect(() => {
    if (
      !auth?.mainContact &&
      (!mlcheck.includes(MembershipLevel.QuotePoster) ||
        !prmscheck.includes(Permission.CreateQuotes))
    ) {
      history.replace('/');
    }
  }, [auth, history, mlcheck, prmscheck]);

  useEffect(() => {
    if (EditquotesId) getAddQuotesCompletedPage({ quoteId: EditquotesId });
  }, [EditquotesId, getAddQuotesCompletedPage]);

  useEffect(() => {
    return () => {
      resetAddQuotesDetails();
    };
  }, [resetAddQuotesDetails]);

  const SetAddSupplemental = () => {
    setAddBidDetails({
      addsupplementalmodal: !addsupplementalmodal,
      isExistsSupplier: '',
      existssupplierlist: [],
    });
  };

  useEffect(() => {
    return () => {
      setAddBidDetails({ loadNewCommodities: true });
    };
  }, [setAddBidDetails]);

  useEffect(() => {
    return () => {
      resetCommodityCodes();
    };
  }, [resetCommodityCodes]);

  const currentQuotesName = checkedit === 'edit' ? quoteName : '';

  // components used in the page
  const component = {
    'Quote Information': (
      <QuotesInformation
        {...otherprops}
        initialValues={addquotesinfo}
        currentwizard={currentwizard}
        //addquotesinforeduxForm={addquotesinforeduxForm}
        quotesId={EditquotesId}
        currentQuotesName={currentQuotesName}
      />
    ),
    'Quote Wire': (
      <QuoteWire
        {...otherprops}
        currentwizard={currentwizard}
        initialValues={addquoteswire}
        quotesId={EditquotesId}
        //addquoteswirereduxForm={addquoteswirereduxForm}
        currentQuotesName={currentQuotesName}
      />
    ),
    'Manage Documents': (
      <QDocumentUpload
        {...otherprops}
        currentwizard={currentwizard}
        quotesId={EditquotesId}
        currentQuotesName={currentQuotesName}
      />
    ),
    'Build Broadcast List': (
      <BidBoardcastList
        {...otherprops}
        currentwizard={currentwizard}
        quotecurrentwizard={currentwizard}
        quotesId={EditquotesId}
        currentQuotesName={currentQuotesName}
        pagefor='addquotes'
        checkedit={checkedit}
      />
    ),
    'Supplemental Suppliers': (
      <SupplementalSuppliers
        {...otherprops}
        currentwizard={currentwizard}
        quotecurrentwizard={currentwizard}
        SetAddSupplemental={SetAddSupplemental}
        quotesId={EditquotesId}
        currentQuotesName={currentQuotesName}
        pagefor='addquotes'
      />
    ),
  } as any;

  // fn to find which page to display currently based on redux value (status in wizard array)
  const [current] = addquoteswizard
    .filter((item: WizardPage) => item.status === 'current')
    .map((item: WizardPage) => item.name);
  const [currentcomponent] = Object.keys(component)
    .filter(item => item === current)
    .map(item => component[item]);

  const wizardclickFn = (id: number) => {
    setAddQuotesDetails({ addquoteswizard: setWizardStatus(addquoteswizard, id) });
  };
  const handleBlockedNavigation = (nextLocation: any) => {
    if (halfWayCancelAllow || ConfirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };
  return (
    <>
      <Prompt when={halfWayCancel} message={handleBlockedNavigation} />
      <WizardContainer
        navTitle={'Quotes Creation Progress'}
        header={`${EditquotesId && checkedit === 'edit' ? 'Edit' : 'Add'} Quote`}
        testId={'addquote.main.page.header'}
        currentWizard={currentwizard}
        wizard={addquoteswizard}
        navigateTo={wizardclickFn}
      >
        {currentcomponent}
      </WizardContainer>
      <ModalPopUp
        size={isExistsSupplier ? 'xl' : 'md'}
        title='Add Supplemental Suppliers'
        closeModal={SetAddSupplemental}
        isOpen={addsupplementalmodal}
        backdrop='static'
      >
        <AddSupplementalSuppliers
          {...otherprops}
          initialValues={addsupplementalvalues}
          quotesId={EditquotesId}
          pagefor='addquotes'
        />
      </ModalPopUp>
      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>{leavingAlertMessage}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Stay on this page'
          title='Cancel'
          type='button'
          onClick={() => {
            setConfirmModalVisible(false);
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Leave without saving'
          title='OK'
          type='button'
          onClick={discardConfirmationPopup}
        />
      </ModalPopUp>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    addquotes: state.addquotes,
    quoteSummary: state.quoteSummary,
    addbid: state.addbid,
    shared: state.shared,
    commoditycodes: state.commoditycodes,
    tours: state.tours,
    accountinfo: state.accountinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(AddQuotes));
