import history from '../../../utils/history';
import React, { useContext } from 'react';

import { MembershipLevel, Permission } from '../../../types/auth';
import { anyParamType } from '../../../types/paymentdetails';
import { useAuthContext } from 'src/auth/AuthProvider';
import { AppContext } from 'src/global-context/AppContext';

interface PropsTypes {
  resetQuoteSummaryDetails: anyParamType;
  resetCommodityCodes: anyParamType;
  setAddBidDetails: anyParamType;
  resetAddQuotesDetails: anyParamType;
  //trackAmplitudeUserActions?: anyParamType;
}

function Quotescontrol(props: PropsTypes) {
  const {
    /* trackAmplitudeUserActions, */ resetQuoteSummaryDetails,
    resetCommodityCodes,
    setAddBidDetails,
    resetAddQuotesDetails,
  } = props;
  const { auth } = useAuthContext();
  const mlcheck = auth?.membershipLevels.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = auth?.accountPermissions.replace(/ /g, '').split(',') as Permission[];
  const { RefetchDashboardData } = useContext(AppContext);

  const AddQuote = () => {
    resetQuoteSummaryDetails();
    setAddBidDetails({ ResetState: true, broadcastListId: '' });
    resetCommodityCodes();
    resetAddQuotesDetails();
    RefetchDashboardData(true);
    //trackAmplitudeUserActions({title:'Add quote - button', desc:'add quote button clicked from quote search'});
    history.push('/buyers/quotes/creation');
  };

  return (
    <>
      {auth?.mainContact ||
      (mlcheck.includes(MembershipLevel.QuotePoster) &&
        prmscheck.includes(Permission.CreateQuotes)) ? (
        <li title='Add Quote'>
          <span className='staticLink addBid' onClick={AddQuote}>
            <i className='mdi mdi-plus mr-1' /> Add Quote
          </span>
        </li>
      ) : null}
    </>
  );
}

export default Quotescontrol;
