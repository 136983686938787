import React, { memo } from 'react';
// import helpImgs from '../../../../assets/images/help'

import imgsCdn from '../../../../assets/images/imagescdn';

const helpImgs = imgsCdn.helpImgs;

function SearchBid() {
  return (
    <>
      <p>
        We recommend using the following steps to create a broad search on DemandStar. This will
        allow you to “see” how our government partners classify and name the types of projects you
        are most interested in.
      </p>
      <p>
        After doing a few broad searches, you may want to try using the additional filters beyond
        these mentioned below:
      </p>
      <ol>
        <li>Log In</li>
        <li>
          {' '}
          Once your logged in and on your Dashboard Homepage - Click on “Bids” at the top of the
          screen
          <img src={helpImgs.picture15} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          On the left side of the screen use the filter “Show bids” and select “All bids in the
          system” from the drop-down options
          <img src={helpImgs.picture16} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          On the left side of the screen use the filter “Bid Name” to enter in a keyword and click
          “Search”
          <img src={helpImgs.picture17} alt='images' className='d-block mt-2 img-fluid' />
        </li>
        <li>
          Review your results
          <img src={helpImgs.picture18} alt='images' className='d-block mt-2 img-fluid' />
        </li>
      </ol>
    </>
  );
}

export default memo(SearchBid);
