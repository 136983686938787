import React, { useState } from 'react';
import { ModalPopUp } from './modals';
import { useDSSelector } from '../../store/reducers';
import TagDetailsPopup from './generatedTagEditPopUp';
import { useDispatch } from 'react-redux';
import { updateGeneratedTags } from 'src/store/actions';
import { Buttons } from '../customcontrols';

interface PopUpProps {
  isOpen: boolean;
  closeGenerateTagsPopup: () => void;
  bidId: number | '';
}

const GenerateTagsPopUp: React.FC<PopUpProps> = ({ isOpen, closeGenerateTagsPopup, bidId }) => {
  const dispatch = useDispatch();
  const commodityTags = useDSSelector(state => state.shared.commodityTags || []);
  const generateTags = useDSSelector(state => state.bidssummary.generateTags);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [isAddTagPopupOpen, setAddTagPopupOpen] = useState(false);

  const generateTagBubbles = (tagsString: string | undefined, colorCode: string) => {
    return tagsString?.split(',').map((tag: string, index: number) => (
      <span
        key={index}
        style={{
          backgroundColor: colorCode,
          fontSize: '12px',
          fontWeight: '500',
          cursor: 'pointer',
        }}
        className='badge  mr-2 mb-2 mt-3 ml-2'
        onClick={() => handleTagClick(tag)}
        title={`Click to edit: ${tag}`}
      >
        <span className='ml-2'>{tag.trim()}</span>{' '}
        <i className='mdi mdi-square-edit-outline ml-1 tagsPencil float-right'></i>
      </span>
    ));
  };

  const getTags = (tagsStrings: string | undefined) => {
    return tagsStrings
      ?.split(',')
      .map(tag => tag.trim().replace(/"/g, ''))
      .filter(tag => tag !== '');
  };

  const handleTagClick = (tag: string) => {
    setSelectedTag(tag);
  };

  const handleUpdateTag = (updatedTag: string) => {
    const currentTags = getTags(generateTags?.Tags);
    const updatedTagsArray = currentTags?.map(tag => {
      return tag.toLowerCase() === selectedTag?.toLowerCase() ? updatedTag : tag;
    });
    const updatedTagsList = updatedTagsArray?.join(',');
    dispatch(updateGeneratedTags({ BidId: bidId, Tags: updatedTagsList }));
  };

  const handleDeleteTag = (selectedTag: string) => {
    const currentTags = getTags(generateTags?.Tags);
    const updatedTagsList = currentTags
      ?.filter(tag => tag.toLowerCase() !== selectedTag.toLowerCase())
      .join(',');

    dispatch(updateGeneratedTags({ BidId: bidId, Tags: updatedTagsList }));
  };

  const handleAddTagClick = () => {
    setAddTagPopupOpen(true);
  };

  return (
    <>
      <ModalPopUp
        title={'Tags'}
        size='xl'
        isOpen={isOpen}
        closeModal={() => {
          closeGenerateTagsPopup();
          setSelectedTag(null);
        }}
        backdrop='static'
      >
        <span>
          <Buttons
            classNames='bttn-primary Tag  mb-1 float-right '
            text='Add Tag'
            title='Add Tag'
            type='button'
            onClick={handleAddTagClick}
          />
        </span>
        <div className='tag-container mt-5'>
          {generateTagBubbles(generateTags?.Tags, '#d2dfe1')}
        </div>
      </ModalPopUp>

      {selectedTag && (
        <TagDetailsPopup
          tag={selectedTag}
          isOpen={!!selectedTag}
          onClose={() => setSelectedTag(null)}
          onUpdate={handleUpdateTag}
          popUpFor={'editTag'}
          onDelete={handleDeleteTag}
        />
      )}

      <ModalPopUp
        title={'Add Tag'}
        size='md'
        isOpen={isAddTagPopupOpen}
        closeModal={() => setAddTagPopupOpen(false)}
        backdrop='static'
      >
        <TagDetailsPopup
          tag=''
          isOpen={isAddTagPopupOpen}
          onClose={() => setAddTagPopupOpen(false)}
          onUpdate={newTag => {
            const updatedTagsList = `${generateTags?.Tags},${newTag}`;
            dispatch(updateGeneratedTags({ BidId: bidId, Tags: updatedTagsList }));
            setAddTagPopupOpen(false);
          }}
        />
      </ModalPopUp>
    </>
  );
};

export default GenerateTagsPopUp;
