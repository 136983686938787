import { Collapse, NavbarToggler } from 'reactstrap';
import { useEffect, useState } from 'react';

import dsLogo from '../../assets/images/logo.svg';
import NavHistory from '../../utils/history';

import { constants } from '../../utils/settings';
import { limitedloginPreviousUrlName } from '../../utils/constants';
import { useRouter } from 'src/shared/hooks';

interface LimitedHeaderProps {
  pagefor?: string;
}

export function LimitedHeader({ pagefor }: LimitedHeaderProps) {
  const {
    routerParams: { bidId },
  } = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('light_DS');
    const maintag = document.getElementById('root');
    const urlPath = window.location.pathname.split('/');

    if (maintag) {
      maintag.className = 'bidThem';
      if (urlPath.find(urlVal => urlVal) !== 'limited') {
        maintag.className = 'defaultThem';
      }
    }
  }, []);

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light business-header businessSite'>
        <div className='container-fluid'>
          <i
            className='navbar-brand cursorPointer'
            onClick={event => (window.location.href = 'https://network.demandstar.com/')}
          >
            <img src={dsLogo} alt='DemandStar' />
          </i>
          {pagefor !== 'maintanance' ? (
            <>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar id='navBarLogin'>
                <ul className='navbar-nav mt-2 mt-lg-0 float-right ml-auto'>
                  <li className='nav-item'>
                    {' '}
                    <a className='nav-link' href={`${constants.networkUrl}/for-business/`}>
                      For Business
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href={`${constants.networkUrl}/for-government/`}>
                      For Government
                    </a>
                  </li>
                  {pagefor === 'accountrecovery' ? null : (
                    <>
                      <li>
                        <button
                          className='login-toolbar ml-5'
                          onClick={event => {
                            if (bidId) {
                              sessionStorage.setItem(
                                limitedloginPreviousUrlName,
                                `/bids/${bidId}/details`,
                              );
                            }
                            NavHistory.push('/login');
                          }}
                        >
                          Sign In
                        </button>
                      </li>
                      <li>
                        <button
                          className='login-toolbar'
                          onClick={event => NavHistory.push('/registration')}
                        >
                          Sign Up
                        </button>
                      </li>
                    </>
                  )}
                </ul>
                {/* </div> */}
              </Collapse>
            </>
          ) : null}
        </div>
      </nav>
    </>
  );
}
