import { atom, selector } from 'recoil';

import { defaultNumericId } from '@demandstar/components/constants';

import { MemberInfoApiResponse, MemberType } from '../../types';

import { getCurrentMemberInfo } from '../services';
import { track } from '../../utils/telemetry';

/**
 * Track the currently logged-in auth memberId
 */
export const authMemberIdState = atom<number>({
  key: 'authMemberIdState',
  default: defaultNumericId,
});

/**
 * Track the currently selected supplier memberId
 */
export const supplierMemberIdState = atom<number>({
  key: 'supplierMemberIdState',
  default: defaultNumericId,
});

/**
 * Track the currently selected member Type
 * TODO: clean up and merge with `memberInfoState`.
 */
export const selectedMemberTypeState = atom<MemberType | ''>({
  key: 'selectedMemberTypeState',
  default: '',
});

/**
 * Make a service API call to populate a Recoil Selector.
 * TODO: Implement dynamic updates via WebHooks or explicit component re-instantiation.
 */
export const memberInfoSelector = selector<MemberInfoApiResponse | null>({
  key: 'memberInfoSelector',
  get: async ({ get }) => {
    const memberId = get(authMemberIdState);
    // Skip the service call for the default value (-1)
    if (memberId === defaultNumericId) {
      return null;
    }
    try {
      // API call
      const response = await getCurrentMemberInfo({
        isCurrentMember: true,
        memberId: `${memberId}`,
      });
      return response;
      // ! FIXME: any
    } catch (error: any) {
      track(`memberInfoSelector -> getCurrentMemberInfo(${memberId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      // eslint-disable-next-line no-console
      console.error(`memberInfoSelector -> getMemberInfo() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      // return {};
      throw new Error(error.message);
    }
  },
});

/** To be used for easy testing. */
export const mockMemberInfoState: MemberInfoApiResponse = {
  mi: 2460105,
  mn: 'Z Z agencydstest_2.0 1',
  ms: 'AC',
  mt: MemberType.AgencyBuyer,
  mtn: 'Agency',
  ic: 0,
  ifd: true,
  a1: 'add1 m12a aaaa asdfer asd',
  a2: 'add2 tes mani',
  ct: 'Alachua',
  pc: '32616',
  sti: 1,
  st: 'Florida',
  sta: 'FL',
  ci: 1,
  c: 'United States of America',
  ca: 'USA',
  p: '1111122221',
  cu: 'Alachua County',
  cui: 87,
  metroId: 0,
  eml: 'user_2360332@example.com',
  jt: 'Sales Manager',
  nfy: true,
  dnd: false,
  tzi: 7,
  sdbd: false,
  tzn: 'Mountain',
  tzfn: 'Mountain Time (US & Canada)',
  addresses: [
    {
      a1: 'add1 m12a aaaa asdfer asd',
      a2: 'add2 tes mani',
      ct: 'Alachua',
      pc: '32616',
      sti: 1,
      st: 'Florida',
      sta: 'FL',
      ci: 1,
      c: 'United States of America',
      ca: 'USA',
      cu: 'Alachua County',
      cui: 87,
      metroId: 29609,
      addrType: 'BA',
    },
    {
      a1: 'add1 m12a aaaa asdfer asd',
      a2: 'add2 tes mani',
      ct: 'Alachua',
      pc: '32616',
      sti: 1,
      st: 'Florida',
      sta: 'FL',
      ci: 1,
      c: 'United States of America',
      ca: 'USA',
      cu: 'Alachua County',
      cui: 87,
      metroId: 0,
      addrType: 'MA',
    },
  ],
};
