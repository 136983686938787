import { useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { DSButton } from '@demandstar/components/button';

import { BID_DETAILS_LABEL, BidResponseLabels } from '../../../../shared/constants/bids';
import { getDefaultLoadingErrorMessage } from '../../../../utils/message';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { ModalPopUp } from '../../../../components/common/modals/ModalPopUp';
import { PanelRowRight } from '../../../../shared/styles';
import { responseEventListState } from '../../../../store/recoil/bidResponseState';
import { responseEventsParamType } from 'src/types/biddetail';
import { ResponseHistoryView } from './ResponseHistoryView';
import { useBid } from 'src/shared/hooks/useBid';

interface ResponseHistoryProps {
  responseId: string;
  bidId: string | number | null | undefined;
}

export function ResponseHistory(props: ResponseHistoryProps) {
  const { responseId, bidId } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const { bid, bidLoadable } = useBid(bidId);

  const responseEventListLoadable = useRecoilValueLoadable(responseEventListState);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // ! FIXME: This is not reliably typed.
  const responseEvents: responseEventsParamType[] = useMemo(() => {
    return responseEventListLoadable.valueMaybe() || [];
  }, [responseEventListLoadable]);

  return (
    <LoadableWrapper
      loadables={[bidLoadable, responseEventListLoadable]}
      errorMessage={getDefaultLoadingErrorMessage(BID_DETAILS_LABEL)}
    >
      <PanelRowRight>
        <DSButton type='secondary' onClick={openModal} title={BidResponseLabels.viewHistory}>
          {BidResponseLabels.viewHistory}
        </DSButton>
      </PanelRowRight>
      <ModalPopUp
        backdrop='static'
        title='History'
        size='xl'
        closeModal={closeModal}
        isOpen={modalOpen}
      >
        {bid && (
          <ResponseHistoryView
            responseEvents={responseEvents}
            timeZoneFullName={bid.timeZoneFullName}
            responseId={responseId}
          />
        )}
      </ModalPopUp>
    </LoadableWrapper>
  );
}
