import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import {
  allProductsState,
  initialAgencyProduct,
  initialCountyProduct,
  initialStateProduct,
  selectedProductsState,
} from 'src/store/recoil/productState';
import { checkAccountExist, getIncompleteRegistration } from 'src/store/services';
import {
  currentRegistrationComponentState,
  incompleteRegistrationState,
  recoilRegistrationDataState,
  RegistrationComponent,
  registrationErrorState,
  updateIncompleteRegistration,
} from 'src/store/recoil/registrationState';
import { NationalProductId, ProductApiResponse, ProductType } from 'src/types/products';

import { compareLists } from 'src/utils/helpers';
import { getSubscriptionMemberDetails } from 'src/store/services/subscriptions';
import { initialRegistrationData } from 'src/store/reducers/registration';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';
import { tryCatchLog } from 'src/utils/errors';

/**
 * @description wrapper for recoil registration state
 * @example const { refreshRegistrationSubscriptions, saveIncompleteRegistration } = useRegistration();
 */
export function useRegistration() {
  const allProducts = useRecoilValueLoadable<ProductApiResponse[]>(allProductsState);
  const [registration, setRegistration] = useRecoilState<RegistrationData>(
    recoilRegistrationDataState,
  );
  const incompleteRegistration = useRecoilValue(incompleteRegistrationState);
  const selectedProducts = useRecoilValue(selectedProductsState);
  const [currentComponent, setCurrentComponent] = useRecoilState(currentRegistrationComponentState);
  const registrationError = useRecoilValue(registrationErrorState);

  /**
   * @description sets selectedProductsState based on incompleteRegistrationState subscriptionData
   * @returns void
   * @example initializeSelectedProductsFromRegistration();
   */
  const initializeSelectedProductsFromRegistration = useRecoilCallback(
    ({ set }) =>
      () => {
        let selectUpdated = false;

        if (allProducts.state === 'hasValue' && registration.emailAddress?.length) {
          const newSelectedProducts = { ...selectedProducts };

          if (registration.freeAgency && selectedProducts.agency === initialAgencyProduct) {
            const agency = allProducts.contents.find(p => p.productId === registration.freeAgency);
            newSelectedProducts.agency = agency;
            selectUpdated = true;
          }

          if (registration.subscriptionData && registration.subscriptionData.length > 0) {
            const previouslySelected = registration.subscriptionData as ProductApiResponse[];

            const counties = previouslySelected.filter(
              s => s.productType === ProductType.County && s.productId > 0,
            );
            const states = previouslySelected.filter(
              s => s.productType === ProductType.State && s.productId > 0,
            );
            const national = previouslySelected.filter(
              s => s.productType === ProductType.National && s.productId > 0,
            );

            if (
              !selectedProducts.county?.filter(x => x !== initialCountyProduct)?.length &&
              counties.length
            ) {
              newSelectedProducts.county = [...counties];
              selectUpdated = true;
            }

            if (
              !selectedProducts.state?.filter(x => x !== initialStateProduct)?.length &&
              states.length
            ) {
              newSelectedProducts.state = [...states];
              selectUpdated = true;
            }

            if (!selectedProducts.national && national.length) {
              newSelectedProducts.national = NationalProductId.UnitedStates;
              selectUpdated = true;
            }
          }

          if (selectUpdated) {
            set(selectedProductsState, { ...newSelectedProducts });
          }
        }
        return selectUpdated || false;
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allProducts.state, registration],
  );

  /**
   * @description checks for an email URL param & updates incompleteRegistrationDataState if needed
   * @returns Promise<void>
   * @example await populateRegistrationEmail();
   */
  const populateRegistrationEmail = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');

        const registrationData = await snapshot.getPromise(recoilRegistrationDataState);

        if (email && registrationData.email !== email) {
          set(incompleteRegistrationState, {
            ...registrationData,
            emailAddress: email,
          });
        }
      },
    [],
  );

  /**
   * @description loads incomplete registration data from service layer
   * @returns Promise<void>
   * @example await refreshIncompleteRegistration();
   */
  const refreshIncompleteRegistration = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const incompleteRegData = await snapshot.getPromise(incompleteRegistrationState);

        if (incompleteRegData?.emailAddress?.length) {
          await tryCatchLog(async () => {
            const response = await getIncompleteRegistration({
              email: incompleteRegData.emailAddress,
            });
            const data = JSON.parse(response?.data.result);
            if (
              data?.regData.currentComponent === registrationComponent.CreateAccount ||
              data?.regData.currentComponent === registrationComponent.EmailAccount
            ) {
              data.regData.currentComponent = registrationComponent.ChooseAgency;
            }

            if (data?.regData?.registrationData) {
              const regData = {
                ...data.regData.registrationData,
                companyName:
                  incompleteRegData.companyName || data.regData.registrationData.companyName,
              };
              set(recoilRegistrationDataState, regData);

              if (regData.companyName) {
                set(
                  currentRegistrationComponentState,
                  data?.regData.currentComponent || registrationComponent.ChooseAgency,
                );
              } else {
                set(currentRegistrationComponentState, registrationComponent.CreateAccount);
              }
            } else if (data?.regData) {
              const registrationData = {
                ...data.regData,
                emailAddress: data.regData.emailId,
                companyName: incompleteRegData.companyName || data.regData.companyName,
                acceptTerms: data.regData.isAcceptTerms,
                freeAgency: parseInt(data.regData.selectedAgencyId),
                selectedAgencyName: data.regData.selectedAgencyName
                  ? data.regData.selectedAgencyName
                  : '',
              };

              set(recoilRegistrationDataState, { ...registrationData });

              if (registrationData.companyName) {
                set(
                  currentRegistrationComponentState,
                  data?.regData.currentComponent || registrationComponent.ChooseAgency,
                );
              } else {
                set(currentRegistrationComponentState, registrationComponent.CreateAccount);
              }
            } else if (incompleteRegData.acceptTerms) {
              saveIncompleteRegistration(registrationComponent.ChooseAgency, {
                ...incompleteRegData,
              });
            }
          }, 'useRegistration => getIncompleteRegistration');
        } else {
          set(recoilRegistrationDataState, initialRegistrationData);
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [incompleteRegistration.emailAddress],
  );

  /**
   * @description saves selectedProductsState to incompleteRegistrationData & updates information
   * saved on the back-end
   * @returns Promise<void>
   * @example await refreshRegistrationSubscriptions();
   */
  const refreshRegistrationSubscriptions = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (
          selectedProducts.county?.length ||
          selectedProducts.state?.length ||
          selectedProducts.national ||
          registration.subscriptionData?.length
        ) {
          const subscriptionData: ProductApiResponse[] = [];
          let update = false;

          if (selectedProducts.county) {
            selectedProducts.county.map(county => subscriptionData.push(county));

            const { firstOnly, secondOnly } = compareLists(
              selectedProducts.county,
              registration.subscriptionData?.filter(s => s.productType === ProductType.County) ??
                [],
              (a, b) => {
                return a.productId === b.productId;
              },
            );

            update = update || firstOnly.length + secondOnly.length > 0;
          }

          if (selectedProducts.state) {
            selectedProducts.state.map(state => subscriptionData.push(state));

            const { firstOnly, secondOnly } = compareLists(
              selectedProducts.state,
              registration.subscriptionData?.filter(s => s.productType === ProductType.State) ?? [],
              (a, b) => {
                return a.productId === b.productId;
              },
            );

            update = update || firstOnly.length + secondOnly.length > 0;
          }

          update =
            update ||
            selectedProducts.national !==
              (registration.subscriptionData?.find(x => x.productType === ProductType.National)
                ?.productId ?? NationalProductId.None);

          const subscriptions = {
            subscribedCounties: selectedProducts.county
              ? selectedProducts.county.map((county: { productId: number }) => county.productId)
              : [],
            // Since National subscriptions are handled differently, simply taking what's there
            subscribedNational:
              registration.subscriptions && registration.subscriptions.subscribedNational
                ? registration.subscriptions.subscribedNational
                : [],
            subscribedStates: selectedProducts.state
              ? selectedProducts.state.map((state: { productId: number }) => state.productId)
              : [],
            subscriptionSelected: true,
          };

          if (update) {
            await tryCatchLog(async () => {
              const regData = {
                ...registration,
                subscriptionData: subscriptionData,
                subscriptions: subscriptions,
              };

              set(recoilRegistrationDataState, regData);

              await saveIncompleteRegistration(registrationComponent.ChooseSubscription, regData);
            }, 'updateSubscriptions -> updateIncompleteRegistration()');
          }
        }
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProducts.county, selectedProducts.national, selectedProducts.state],
  );

  /**
   * @description saves incompleteRegistrationDataState back to the server in case user does not
   * complete registration
   * @returns Promise<void>
   * @example await saveIncompleteRegistration();
   */
  const saveIncompleteRegistration = useRecoilCallback(
    ({ set }) =>
      async (nextComponent: RegistrationComponent, regData: RegistrationData) => {
        await tryCatchLog(async () => {
          const response = await updateIncompleteRegistration({
            email: regData.emailAddress,
            JsonData: {
              regData: {
                currentComponent: nextComponent,
                registrationData: {
                  ...regData,
                },
              },
            },
          });

          if (response) {
            set(incompleteRegistrationState, response);
            set(recoilRegistrationDataState, {
              ...registration,
              ...regData,
            });
          }

          if (currentComponent !== nextComponent) {
            set(currentRegistrationComponentState, nextComponent);
          }
        }, 'saveIncompleteRegistration -> updateIncompleteRegistration()');
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * @description Resets registration state
   */
  const resetRegistrationState = useRecoilCallback(
    ({ set }) =>
      () => {
        set(incompleteRegistrationState, initialRegistrationData);
        set(recoilRegistrationDataState, initialRegistrationData);
        set(currentRegistrationComponentState, registrationComponent.CreateAccount);
      },
    [],
  );

  const setRegistrationDataFromMemberId = useRecoilCallback(
    ({ set }) =>
      async (memberId: string) => {
        const response = await getSubscriptionMemberDetails({ memberId });
        const payload = response?.data.result || [];

        const memberDetails = payload.memberDetails;
        const registrationData = { ...registration };
        registrationData.companyName = memberDetails.shortName;
        registrationData.emailAddress = memberDetails.email;
        registrationData.freeAgency = memberDetails.freeAgencyId;
        registrationData.companyAddress = {
          address1: memberDetails.billingAddress1,
          address2: memberDetails.billingAddress2,
          city: memberDetails.billingCity,
          stateId: memberDetails.stateId,
          stateName: memberDetails.stateId ? memberDetails.state : '',
          postalCode: memberDetails.postalCode,
          countyId: memberDetails.countyId,
          countyName: '',
          countryId: memberDetails.countryId,
          country: '',
        };

        registrationData.companyInfo = {
          contactName: memberDetails.shortName,
          contactPhone: memberDetails.memberPhone,
          fax: 0,
          website: '',
        };

        registrationData.mainContact = {
          firstName: memberDetails.firstName ? memberDetails.firstName : memberDetails.firstname,
          lastName: memberDetails.lastName,
          phone: memberDetails.memberPhone,
          email: memberDetails.email,
        };

        set(recoilRegistrationDataState, registrationData);
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const checkAccountExists = useRecoilCallback(
    ({ set }) =>
      async ({
        emailAddress,
        companyName,
        memberId = '',
      }: {
        emailAddress: string;
        companyName: string;
        memberId?: string;
      }) => {
        await tryCatchLog(async () => {
          const payload = { email: emailAddress, company: companyName };
          const response = await checkAccountExist(payload);
          if (
            response &&
            response.data.result.isBlackList === false &&
            (response.data.result.planholderExist === false || memberId) &&
            response.data.result.companyExists === false &&
            response.data.result.accountExists === false
          ) {
            refreshIncompleteRegistration();
          } else {
            set(registrationErrorState, {
              ...response?.data.result,
              planholderEmail: emailAddress,
            });
            set(currentRegistrationComponentState, registrationComponent.EmailAccount);
          }
        }, 'useRegistration => checkAccountExist');
      },
    /**FIXME: */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    currentComponent,
    incompleteRegistration,
    registration,
    registrationError,
    checkAccountExists,
    initializeSelectedProductsFromRegistration,
    populateRegistrationEmail,
    refreshIncompleteRegistration,
    refreshRegistrationSubscriptions,
    resetRegistrationState,
    saveIncompleteRegistration,
    setCurrentComponent,
    setRegistration,
    setRegistrationDataFromMemberId,
  };
}

/**
 * @description loads incomplete registration data from the back end on registration start
 * @returns void
 * @example useRefreshIncompleteRegistration();
 */
export function useRefreshIncompleteRegistration() {
  const { refreshIncompleteRegistration, incompleteRegistration, registration } = useRegistration();

  useEffect(() => {
    if (registration === initialRegistrationData) {
      refreshIncompleteRegistration();
    }
  }, [refreshIncompleteRegistration, incompleteRegistration.emailAddress, registration]);
}

/**
 * @description keeps selectedProductsState up-to-date with registration data state & server-stored
 * incomplete registration data
 * @returns void
 * @example useRefreshRegistrationSubscriptions();
 */
export function useRefreshRegistrationSubscriptions() {
  const { refreshRegistrationSubscriptions } = useRegistration();

  useEffect(() => {
    refreshRegistrationSubscriptions();
  }, [refreshRegistrationSubscriptions]);
}

/**
 * @description sets selected products one time (as data becomes available) when member loads into registration
 * w/ an incomplete registration state
 * @returns void
 * @example useInitializeSelectedProductsFromRegistration();
 */
export function useInitializeSelectedProductsFromRegistration() {
  const { initializeSelectedProductsFromRegistration } = useRegistration();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const update = initializeSelectedProductsFromRegistration();
      setIsInitialized(update);
    }
  }, [initializeSelectedProductsFromRegistration, isInitialized]);
}
