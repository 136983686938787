import { DSTabNavigationHeader } from '@demandstar/components/tab-navigation';

import { CMTab, useCMNavigation } from './useCMNavigation';
import { StyledCMTabContainer } from './CMTabNavigation.styles';

type CMNavigationProps = {
  selectedTab?: CMTab;
};

export const CMTabNavigation = ({ selectedTab = CMTab.Contracts }: CMNavigationProps) => {
  const { tabs } = useCMNavigation();

  return (
    <div className='mt-4'>
      <StyledCMTabContainer>
        <DSTabNavigationHeader tabs={tabs} />
      </StyledCMTabContainer>
    </div>
  );
};
