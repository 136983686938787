import {
  defaultMaximumContractCount,
  defaultMaximumContractWarningCount,
} from './contract-management.helpers';

export const cmButtonTexts = {
  addContracts: 'Add Contracts',
  addExistingContract: 'Add an existing contract to DemandStar',
  addNewDocumentType: 'Add New Document Type',
  addToContractManagement: 'Add To Contract Management',
  awardNewContract: 'Award a contract on DemandStar',
  editContractDetails: 'Edit Contract Details',
  managedContracts: 'Managed Contracts',
  manageDocumentTypes: 'Manage Document Types',
  saveAndAdd: 'Save & Add Another',
  saveDoc: 'Save Document',
  sendDocRequest: 'Send Document Request',
  recentDocuments: 'Recent Documents',
  requestDocuments: 'Request a Document',
  uploadDocuments: 'Upload a Document',
  viewContractDetails: 'View Contract Details',
  viewContractDocuments: 'View all Contract Documents',
  yourReminders: 'Your Reminders',
} as const;

export const cmExplanatoryTexts = {
  AddContracts: `Do you have contracts that you'd like to manage on DemandStar? Upload or import up to ${defaultMaximumContractCount} contracts. We have just the tool for you!`,
  Dashboard: `Welcome to your contract management hub on DemandStar. Here you'll find shortcuts to manage up to ${defaultMaximumContractCount} of your awarded contracts and your Document Library.`,
  Documents:
    "Here you'll find all your contract documents. You may also upload new files and manage document types. Please note that bid and quote documents can still be found in the Bids and Quotes sections of DemandStar, but are not included here.",
} as const;

export const cmFormLabels = {
  ChooseFile: 'Choose a file from your system',
  Contract: 'Contract',
  ConvertPdf: 'Convert to PDF',
  DocumentType: 'Document Type',
  DueDate: 'Due Date',
  Filename: 'Filename',
  FilterContract: 'Filter by Contract',
  FilterDocType: 'Filter by Document Type',
  FilterSource: 'Filter by Source',
  FilterStatus: 'Filter by Status',
  NewDocumentType: 'New Document Type Title',
  NewDocumentDescription: 'New Document Type Description',
  Public: 'Make this file public?',
  Supplier: 'Supplier',
  SupplierEmail: 'Supplier Email',
} as const;

export const cmFormMessages = {
  Contract: 'Which contract should this be associated with?',
  Filename:
    'For example: "original_contract_document." If you leave this blank, we\'ll use the filename as uploaded.',
  Supplier: 'Which supplier submitted this document?',
} as const;

export const contractManagementLabels = {
  addContracts: 'Add Contracts',
  atGlance: 'Contracts at a Glance',
  contracts: 'Contracts',
  manage: 'Manage your Contracts',
  reminders: 'Reminders',
  title: 'Contract Management',
  welcome: 'Welcome to Contract Management',
} as const;

export const contractManagementLimitText = {
  headerError: `You have reached the limit of ${defaultMaximumContractCount} contracts in DemandStar!`,
  headerWarning: (contractCount = defaultMaximumContractWarningCount) =>
    `You have used ${contractCount} of your maximum ${defaultMaximumContractCount} contracts in DemandStar!`,
  linkPrefix: 'For unlimited contracts and other advanced eProcurement features, ',
  linkText: 'upgrade to Bonfire today!',
  linkURL: 'https://www.gobonfire.com',
} as const;

export enum ContractManagementTestIds {
  Error = 'contractLimitExceededAlertError',
  Warning = 'contractLimitExceededAlertWarning',
}
