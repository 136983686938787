import styled from 'styled-components';
import { useHistory } from 'react-router';

import {
  FontFamily,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  TableFontSize,
} from '@demandstar/components/styles';
import { DSLink } from '@demandstar/components/link';

import { ProductsCopy, ProductsPaths } from '../../../../utils/texts/supplier/subscription';

const Message = styled.div`
  font-family: ${FontWeight.Base};
  font-size: ${TableFontSize.BaseData};
  font-weight: ${FontFamily.Base};
  line-height: ${LineHeight.Base};
  padding: ${Padding.Base} ${Padding.Less};
  margin: ${Margin.None};
`;

/**
 * @description renders a message for users without any subscriptions
 * @returns JSX.Element
 *
 * <NoProductsMessage />
 */
export const NoProductsMessage = () => {
  const history = useHistory();

  const exploreClick = () => {
    history.push(ProductsPaths.ExploreProducts);
  };

  const selectAgencyClick = () => {
    history.push(ProductsPaths.SelectFreeAgency);
  };

  return (
    <Message>
      {`${ProductsCopy.NoProducts} `}
      <DSLink onClick={exploreClick}>{ProductsCopy.ExploreProductsText}</DSLink>
      {' or '}
      <DSLink onClick={selectAgencyClick}>{ProductsCopy.SelectFreeAgencyText}</DSLink>
    </Message>
  );
};
