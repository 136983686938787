import { useCallback } from 'react';
import { useRecoilValueLoadable } from 'recoil';

import { FileUploadText } from '@demandstar/components/inputs/file/constants';
import { getFileExtension } from '@demandstar/components/utils';

import {
  acceptedFileFormatsQuerySelector,
  convertibleFileFormatsSelector,
} from './acceptedFileFormats.recoil';
import { getLoadableContents } from 'src/utils';

/** hook to access accepted file formats.
 * Using this will call an endpoint to get the accepted file formats if it hasn't already happened.
 */
export function useAcceptedFileFormats() {
  const acceptedFileFormatsLoadable = useRecoilValueLoadable(acceptedFileFormatsQuerySelector);
  const convertibleFileFormatsLoadable = useRecoilValueLoadable(convertibleFileFormatsSelector);

  /** array of file formats that DemandStar accepts.
   * @warning may be an empty array for a short time the first time this is used
   */
  const acceptedFileFormats = getLoadableContents(acceptedFileFormatsLoadable, []);

  /** array of file formats that can be converted into a PDF.
   * @warning may be an empty array for a short time the first time this is used
   */
  const convertibleFileFormats = getLoadableContents(convertibleFileFormatsLoadable, []);

  /** will DemandStar allow you to upload this file format?
   * @param {string} extension - file extension
   * @returns {boolean}
   * @example isAcceptedFormat('PDF') // true
   * @example isAcceptedFormat('pdf') // true
   * @example isAcceptedFormat('ZZT') // false
   */
  const isAcceptedFormat = useCallback(
    (extension: string) => {
      return acceptedFileFormats.some(format => {
        return format.docFormatType === extension.toLocaleUpperCase();
      });
    },
    [acceptedFileFormats],
  );

  /** are all these files in acceptable formats for DemandStar?
   * @param {FileList} filesToUpload - a list of all files pending upload
   * @returns {true | Message}
   * @example validateFileFormats([fooPDF]) // true
   * @example validateFileFormats([fooZZT]) // "The selected file type is not supported"
   * @example validateFileFormats([fooZZT, barPDF]) // "One or more selected file types are not supported"
   */
  const validateFileFormats = useCallback(
    (filesToUpload: FileList) => {
      let isValid = true;
      if (filesToUpload) {
        for (let i = 0; i < filesToUpload.length; i++) {
          const file = filesToUpload[i];
          if (file?.name) {
            const extension = getFileExtension(file?.name);
            isValid = isAcceptedFormat(extension);
            if (!isValid) {
              break;
            }
          }
        }
      }
      return (
        isValid ||
        (filesToUpload.length > 2
          ? FileUploadText.ErrorDocumentTypePlural
          : FileUploadText.ErrorDocumentType)
      );
    },
    [isAcceptedFormat],
  );

  /** can this file format convert to PDF?
   * @param {string} extension - file extension
   * @returns {boolean}
   * @example isAcceptedFormat('PDF') // true
   * @example isAcceptedFormat('pdf') // true
   * @example isAcceptedFormat('ZZT') // false
   */
  function isConvertibleFormat(extension: string): boolean {
    return convertibleFileFormats.some(format => {
      return format.docFormatType === extension.toLocaleUpperCase();
    });
  }

  return {
    acceptedFileFormats,
    convertibleFileFormats,
    isConvertibleFormat,
    validateFileFormats,
  };
}
