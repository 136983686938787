import styled from 'styled-components';

import { FontFamily, FontWeight, Margin, ParagraphFontSize } from '@demandstar/components/styles';
import { BaseLinkSpan } from '@demandstar/components/link/DSLinkStyles';

export const ProductMapContainerFlorida = styled.div`
  height: 19rem;
  width: 24rem;
  margin: ${Margin.Base};
`;

export const ProductList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  width: 100%;

  li {
    label {
      font-weight: ${FontWeight.Base};
      font-size: ${ParagraphFontSize.Large};
    }

    > div {
      > div {
        align-content: center;
        font-family: ${FontFamily.Base};
        text-align: center;
        width: 30%;

        :first-of-type {
          align-content: left;
          text-align: left;
          width: 50%;
          max-width: 20rem;

          + div:last-of-type {
            width: 50%;
            text-align: left;
          }
        }

        :last-of-type {
          align-content: right;
          font-weight: ${FontWeight.Bold};
          font-size: ${ParagraphFontSize.Large};
          margin-right: ${Margin.Extra};
          text-align: right;
          width: 20%;
        }

        & ${BaseLinkSpan} {
          font-size: ${ParagraphFontSize.Large};
          font-weight: ${FontWeight.Base};
          text-decoration: underline;
        }
      }
    }

    &:last-of-type {
      margin-bottom: ${Margin.Extra};
    }
  }
`;
