import styled from 'styled-components';

import { FlexContainer, Margin } from '../styles';

export const AppliedFiltersStyled = styled(FlexContainer)`
  column-gap: ${Margin.Less};
  margin-bottom: ${Margin.Base};
  row-gap: ${Margin.Less};
  width: 100%;

  h6 {
    width: 100%;
  }
`;
