import { useEffect, useMemo } from 'react';

import { FeatureFlagKey, useEnabledFeatures } from './useFeatureFlag';
import { hasValue } from 'src/utils/helpers';
import { LoadableWrapper } from '../loading/LoadableWrapper';
import { useRouter } from 'src/shared/hooks';

interface FeatureFlagProps {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  flag?: FeatureFlagKey;
  redirect?: string;
}

export const Feature = ({ children, fallback, flag, redirect }: FeatureFlagProps) => {
  const { history } = useRouter();
  const { enabledFeatures, enabledFeaturesLoadable } = useEnabledFeatures();

  const isFeatureEnabled = useMemo(() => {
    return !flag || (enabledFeatures.length && enabledFeatures.includes(flag));
  }, [enabledFeatures, flag]);

  useEffect(() => {
    const featureNotEnabled = hasValue(enabledFeaturesLoadable) && !isFeatureEnabled;
    const hasError = enabledFeaturesLoadable.state === 'hasError';
    if (redirect && (featureNotEnabled || hasError)) {
      history.push(redirect);
    }
  }, [enabledFeaturesLoadable, history, isFeatureEnabled, redirect]);

  return enabledFeaturesLoadable.state !== 'hasError' ? (
    <LoadableWrapper loadable={enabledFeaturesLoadable}>
      {isFeatureEnabled ? children : fallback}
    </LoadableWrapper>
  ) : (
    <>{fallback}</>
  );
};
