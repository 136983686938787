export type programsParamType = {
  programId: number;
  certificationStatus: string;
  statusName: string;
  supplierMemberId: string;
  statusChangedDate: string;
  name: string;
  acceptApplications: boolean;
  certificationRequired: boolean;
  certifiedMemberCount: number;
  attributeName: string;
  id: number;
  attributeType?: string;
  description?: unknown;
};

export enum UserType {
  BasicSupplier = 'SB',
  FreeAgencySupplier = 'FA',
  PaidSupplier = 'SS',
  Agency = 'AB',
  SupplementalSupplier = 'SV',
  AgencyAddedPlanholder = 'PL',
}

export type userAccountCardInfo = {
  cardType: string;
  cardholderName: string;
  cardLastFour: number;
  expirationMonth: number;
  expirationYear: number;
}

export type paymentsType = Array<{
  trackingNumber: string;
  date: Date;
  paidAmount: number;
  printbtn: boolean;
  chargeType: string;
  paymentType: string;
}>

export type CardInfoRequest = {
  userAccountId: number;
}
