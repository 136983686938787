import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getEunaLinks } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetEunaLinks = (data: any) => {
  const response = getEunaLinks(data);
  return response;
};

export const useGetEunaLinks = (data: any) => {
  return useQuery({
    queryKey: ['EunaLinks'],
    queryFn: () => GetEunaLinks(data),
    staleTime: STALE_TIMES.ONE_DAY,
  });
};
