import { memo, useEffect } from 'react';

import { listParamType, paymentFormFieldType } from '../../../types/payments';
import PaymentForm from './paymentform';
import { toastFn } from '../../../utils/helpers';
import { TollFreeNumber } from 'src/utils/texts/common';

interface PropsTypes {
  match?: any;
  history?: any;
  paymentvalidation?: boolean;
  getStateList?: any;
  getCountyList?: any;
  paymentForm?: paymentFormFieldType;
  paymentinfo?: paymentFormFieldType;
  card_token?: { nonce: string; type: string };
  getPaymentCardDetails?: any;
  submitEbitPaymentCard?: any;
  setPaymentFormDetails?: any;
  paymentmemberInfo?: any;
  countrieslist?: listParamType[];
  stateslist?: listParamType[];
  countylist?: listParamType[];
  cardinfo?: { cardNumberMask: string };
}

const Payment = (props: PropsTypes) => {
  const {
    history,
    paymentvalidation,
    getCountyList,
    paymentForm,
    paymentinfo,
    card_token,
    getPaymentCardDetails,
    submitEbitPaymentCard,
    setPaymentFormDetails,
    paymentmemberInfo = {},
    countrieslist = [],
    stateslist = [],
    countylist = [],
    cardinfo,
  } = props;
  const bidID = props.match.params.bidId || '';

  // getting card tabs from api
  useEffect(() => {
    getPaymentCardDetails();
  }, [getPaymentCardDetails]);

  // Check validation and go to next page
  useEffect(() => {
    const { submitFailed, submitSucceeded, values } = paymentForm || {};
    if (submitFailed && paymentvalidation) {
      setPaymentFormDetails({ paymentvalidation: false });
      toastFn('error', 'Please enter required fields', 'payment info input');
    } else if (submitSucceeded && values) {
      // submit function
      setPaymentFormDetails({ paymentinfo: values });
      const finalvalue = {
        ...values,
        currentMemberId: paymentmemberInfo.memberId,
        currentAccountId: 0,
        bidId: bidID,
        token: card_token && card_token.nonce,
        paymentType: card_token && card_token.type,
        totalFee: 0,
        stateId: values.state.value,
        countryId: values.country.value,
        countyId: values.county.value,
        phoneNumber: paymentmemberInfo.phone,
        faxNumber: paymentmemberInfo.fax,
        isNew: Boolean(values.card === 'newcard'),
      };
      submitEbitPaymentCard(finalvalue);
    }
  }, [
    bidID,
    card_token,
    paymentForm,
    paymentmemberInfo,
    paymentvalidation,
    setPaymentFormDetails,
    submitEbitPaymentCard,
  ]);

  // on cancel button presed go to previous page
  const GoToPrevious = () => {
    history.push(`suppliers/bids/${bidID}`);
  };

  const initialValues = {
    ...paymentinfo,
    cardholdername: paymentmemberInfo.memberName,
    address1: paymentmemberInfo.address1,
    address2: paymentmemberInfo.address2,
    city: paymentmemberInfo.city,
    state: paymentmemberInfo.state,
    country: countrieslist.find(item => item.value === paymentmemberInfo.countryId),
    postalCode: paymentmemberInfo.postalCode,
  } as any;

  const filterstatelist = stateslist
    .filter(item => item.countryId === initialValues.country.value)
    .map(items => ({ ...items, label: items.name, value: items.id }));

  const filtercountylist = countylist.map(items => ({
    ...items,
    label: items.Name,
    value: items.id,
  }));

  return (
    <>
      <h3>Credit Card Payment</h3>
      <hr />
      <p>Document Download Cost: $5.0</p>
      <p>
        You can also purchase an annual subscription and receive unlimited FREE documents download
      </p>
      <p>Click here to upgrade to an annual subscription or call {TollFreeNumber}</p>
      <PaymentForm
        initialValues={initialValues}
        paymentvalues={paymentForm && paymentForm.values}
        onSubmit={() => {}}
        setPaymentFormDetails={setPaymentFormDetails}
        cardinfo={cardinfo}
        countrieslist={countrieslist}
        stateslist={filterstatelist}
        countylist={filtercountylist}
        getCountyList={getCountyList}
        GoToPrevious={GoToPrevious}
      />
    </>
  );
};

export default memo(Payment);
