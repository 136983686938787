import styled from 'styled-components';

// TODO: Add suitable background colors to library
import {
  BackgroundColor,
  BorderThickness,
  FontWeight,
  LineHeight,
  Margin,
  Padding,
  ParagraphFontSize,
  TextColor,
} from '@demandstar/components/styles';

interface CardStyleProps {
  highlightColor?: CardHighlightColor;
}

// TODO: This should be moved to the library & utilize constants
export enum CardHighlightColor {
  Light = '#A5D574',
  Base = '#8AC675',
  Dark = '#609B58',
}

export const CardHeader = styled.div<CardStyleProps>`
  background: ${({ highlightColor }) => highlightColor};
  color: ${TextColor.Contrast};
  padding: ${Padding.Less} ${Padding.Base};

  h5 {
    margin-bottom: ${Margin.None};
    color: ${TextColor.Contrast};
  }
`;

export const CardWrapper = styled.div<CardStyleProps>`
  cursor: pointer;
  border: ${BorderThickness.Base} solid ${({ highlightColor }) => highlightColor};
  margin-bottom: ${Margin.Extra};
  text-align: center;
  margin-left:1rem;
  margin-right:1rem;

  & ${CardHeader} {
    background: ${({ highlightColor }) => highlightColor};
  }
`;

export const CardBody = styled.div`
  background: ${BackgroundColor.BaseWhite};
  padding: ${Padding.Less} ${Padding.Base};

  p {
    margin-bottom: ${Margin.Base};
  }

  button {
    background: none;
    border: none;
    color: ${TextColor.Action};
    font-size: ${ParagraphFontSize.Large};
    font-weight: ${FontWeight.Bold};
    text-decoration: underline;
    line-height: ${LineHeight.Small};
    padding: ${Padding.None};
    padding-bottom: ${Padding.Less};
  }
`;
