export const blankFile: File = new File([], '');

export enum FileUploadText {
  AddAnother = 'Add Another Document',
  ChooseFile = 'Choose File',
  ChooseFiles = 'Choose Files',
  ChooseFileDescription = 'Choose a file from your system',
  DocumentName = 'Document Name',
  DocumentTitle = 'Document Title',
  DocumentType = 'Document Type',
  ErrorDocumentType = 'The selected file type is not supported',
  ErrorDocumentTypePlural = 'One or more selected file types are not supported',
  ErrorFileSize = 'The total size of the selected file(s) cannot exceed ',
  ErrorRequiredDocumentTitle = 'Document Title is required',
  ErrorRequiredDocumentType = 'Document Type is required',
  ErrorRequiredFile = 'A File to upload is required',
  SaveAndAddAnother = 'Save and Add Another',
}
