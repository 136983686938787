import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { memo } from 'react';

import BroadcastSupplierList from './broadcastlist';

import * as actionCreators from '../../../store/actions';
import { AddBid, Broadcastlist, CommodityCodes, Shared } from '../../../types/publications';

import { generatenewTypes } from '../../../types/broadcastlist';

interface propsTypes extends PropsFromRedux {
  shared: Shared;
  broadcastList: Broadcastlist;
  addbid: AddBid;
  commoditycodes: CommodityCodes;
  setBroadcastListDetails: any;
  GoBackToPage: any;
  SetSelectedCommodity: any;
  getBroadcastListSearchVendors: any;
}

type combinedTypes = propsTypes & generatenewTypes;

function Research(props: combinedTypes) {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='colWrapper'>
              <BroadcastSupplierList {...props} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    shared: state.shared,
    broadcastList: state.broadcastList,
    addbid: state.addbid,
    commoditycodes: state.commoditycodes,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Research));
