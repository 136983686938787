import { useEffect } from 'react';

import { DSAlert, DSAlertProps } from '@demandstar/components/alert/DSAlert';
import { Status } from '@demandstar/components/constants';

import { DemandStarSupportEmail, TollFreeNumber } from 'src/utils/texts/common';
import { capitalize } from 'src/utils/helpers';
import { useAlert } from './useAlert';

export type AlertProps = Omit<DSAlertProps, 'onClick' | 'linkText' | 'message' | 'type'> & {
  /** When true, shows the dismiss for the alert & hides the alert when clicked.
   * Defaults to `true` if `onDismiss` is provided and `false` otherwise */
  allowDismiss?: boolean;
  /** The body of the alert, maps to `DSAlert.message` */
  children?: React.ReactNode;
  /** Hides the default contact message appended to the bottom of Error Alerts */
  hideErrorContactMessage?: boolean;
  /** A unique id for this alert.
   * All `Alert`s with the same `id` will show/hide together */
  id: string;
  /** When true, shows the alert on initial render */
  showByDefault?: boolean;
  /** Alert type, defaults to Success */
  type?: Status;
};

export const ContactSupportErrorMessage = (
  <>
    If the error persists, please contact DemandStar support at {DemandStarSupportEmail} or{' '}
    {TollFreeNumber}.
  </>
);

export const Alert = (props: AlertProps) => {
  const { id, showByDefault } = props;
  const { alert, alertVisible, hide, show } = useAlert(id);

  const type = alert?.type || props.type || Status.Success;
  const button = alert?.button || props.button;
  const children = alert?.message || props.children;
  const dataTestId = alert?.dataTestId || props.dataTestId || `${id}-alert`;
  const header = (alert?.header || props.header) ?? capitalize(type.toString());
  const hideErrorContactMessage = alert?.hideErrorContactMessage || props.hideErrorContactMessage;
  const onDismiss = alert?.onDismiss || props.onDismiss;
  const allowDismiss = alert?.allowDismiss || props.allowDismiss || !!onDismiss;
  const link = alert?.link || props.link;

  const handleDismiss = () => {
    hide();
    if (onDismiss) {
      onDismiss();
    }
  };

  useEffect(() => {
    if (showByDefault) {
      show();
    } else {
      hide();
    }
  }, [hide, show, showByDefault]);
  return (
    <>
      {alertVisible && (
        <DSAlert
          button={button}
          dataTestId={dataTestId}
          header={header}
          link={link}
          type={type}
          onDismiss={allowDismiss ? handleDismiss : undefined}
        >
          {children}
          {!children && type === Status.Error && (
            <p>An error occurred while processing your request. Please try again.</p>
          )}
          {(alert?.type || type) === Status.Error && !hideErrorContactMessage && (
            <p>{ContactSupportErrorMessage}</p>
          )}
        </DSAlert>
      )}
    </>
  );
};
