import { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import ProgramApprove from './programapprove';
import SortingIcon from '../../../common/sortingIcon';

import {
  Buttons,
  DeprecatedInput,
  DeprecatedRadio,
  NoResult,
  Paging,
  SelectBox,
} from '../../../customcontrols';
import { pageSize, sortDirections, usaDateFormat } from '../../../../utils/constants';

import { deprecatedGetDate } from '../../../../utils/helpers';
import { DeprecatedReduxDatepicker } from '../../../customcontrols/DeprecatedReduxDatepicker';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { programsType } from '../../../../types/programtypedef';
import { sharedTypes } from '../../../../types/shared';
import { supplierItemFields } from '../../../../types/biddetail';
import { useSetBreadcrumb } from 'src/shared/hooks';

const certStatus = [
  {
    title: 'Unknown',
    label: 'Unknown',
    value: 'UN',
    key: 'Unknown',
  },
  {
    title: 'Certified',
    label: 'Certified',
    value: 'CT',
    key: 'Certified',
  },
  {
    title: 'Rejected',
    label: 'Rejected',
    value: 'RJ',
    key: 'Rejected',
  },
];
const searchCertStatus = [
  {
    title: 'Unknown',
    label: 'Unknown',
    value: 'UN',
    key: 'Unknown',
  },
  {
    title: 'Certified',
    label: 'Certified',
    value: 'CT',
    key: 'Certified',
  },
  {
    title: 'Applying',
    label: 'Applying',
    value: 'AP',
    key: 'Applying',
  },
  {
    title: 'Expired',
    label: 'Expired',
    value: 'EX',
    key: 'Expired',
  },
  {
    title: 'Rejected',
    label: 'Rejected',
    value: 'RJ',
    key: 'Rejected',
  },
  {
    title: 'Renewing',
    label: 'Renewing',
    value: 'RN',
    key: 'Renewing',
  },
];

interface PropsTypes {
  programs?: programsType;
  respondCertRequest?: any;
  shared: sharedTypes;
  getSuppliers?: any;
  activeTab?: string;
  setProgramFilters?: any;
  clearProgramFilters?: any;
  hasEditProgramPermission?: any;
  setProgramDetails?: any;
}

function ProgramSuppliers(props: PropsTypes) {
  const [dateshow, setDateshow] = useState(true);
  const {
    programs,
    respondCertRequest,
    shared,
    getSuppliers,
    activeTab,
    setProgramFilters,
    clearProgramFilters,
    hasEditProgramPermission,
    setProgramDetails,
  } = props;
  const { selfdeclaredattributes, internalLoader, invaliddateerror } = shared || {};
  const { suppliers, programs: buyerPrograms = [], programFilters } = programs || {};

  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  // Logic for displaying pagination
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  useSetBreadcrumb(
    {
      page: 'Agency Programs',
      altname: 'Supplier Certifications',
      title: 'Supplier Certifications',
      name: 'Supplier Certifications',
      menuactive: '',
      component: undefined,
    },
    activeTab === 'suppliers',
  );

  const [modal, setModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [selectedSupplier, setselectedSupplier] = useState({} as supplierItemFields);
  const [updateType, setUpdateType] = useState(1);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const toggle = useCallback(
    (supplier?: any, type = 1) => {
      if (!modal && supplier) {
        setInitialValues({
          certCode: '',
          notes: '',
          ...supplier,
          expires: supplier.expires ? moment(supplier.expires) : '',
          status: certStatus.find(f => f.label === supplier.status),
        });
        setselectedSupplier({ ...supplier });
      }
      setUpdateType(type);
      toggleModal();
    },
    [modal, toggleModal],
  );

  const onInputChange = (name: string, value: any) => {
    setProgramFilters({ [name]: value });
  };

  const onSearchClick = (event: any) => {
    setCurrentPage(1);
    event.preventDefault();
    getSuppliers();
  };

  const onClearFilterClick = (event: any) => {
    setDateshow(false);
    setCurrentPage(1);
    event.preventDefault();
    clearProgramFilters();
    setTimeout(() => {
      setDateshow(true);
    }, 10);
  };

  const programOptions = buyerPrograms.map(m => {
    return {
      title: m.name,
      label: m.name,
      value: m.id,
      key: m.name,
    };
  });

  const changeProgramFilter = (name: string, value: any) => {
    setProgramFilters({ [name]: value });
  };

  const changeStatusFilter = (name: string, value: any) => {
    setProgramFilters({ [name]: value });
  };

  //Logic for sort
  const [sortvalue, setSortvalue] = useState('supplierName');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);
  useEffect(() => {
    return () => {
      setSortvalue('supplierName');
      setSortorder(sortDirections.ASC);
    };
  }, []);

  return (
    <>
      <h3 className='arrowWrapper'>Manage Supplier Certifications</h3>
      <div className='row'>
        <div className='col-sm-4 col-md-3'>
          <SelectBox
            label='Certifications'
            name='programId'
            reactselect={true}
            options={programOptions}
            value={programFilters && programFilters.programId}
            handleSelect={changeProgramFilter}
          />
          <SelectBox
            label='Certification Status'
            name='status'
            reactselect={true}
            options={searchCertStatus}
            value={programFilters && programFilters.status}
            handleSelect={changeStatusFilter}
          />
          {dateshow ? (
            <DeprecatedReduxDatepicker
              label='Certification Expires After'
              name='expires'
              classNames='class'
              isOutsideRange={true}
              handleChange={onInputChange}
              value={programFilters && programFilters.expires}
              showClearDate={true}
              optional={`(e.g. "${moment().format(usaDateFormat)}")`}
            />
          ) : null}
          <DeprecatedInput
            label='Supplier Name'
            placeholder='Supplier Name'
            name='supplierName'
            type='search'
            classNames='class'
            value={programFilters && programFilters.supplierName}
            handleChange={onInputChange}
            maxLength={30}
          />
          <span>Subscription</span>

          <DeprecatedRadio
            key={0}
            title='Only show supplier whose Subscription includes my agency'
            value={true}
            ID='supplierShow'
            name='isSubscriber'
            defaultChecked={!!(programFilters && programFilters.isSubscriber)}
            handleSelect={() => {
              setProgramFilters({ isSubscriber: true });
            }}
          />
          <DeprecatedRadio
            key={1}
            title='Show all suppliers'
            value={false}
            ID='supplierShow'
            name='isSubscriber'
            defaultChecked={!!(programFilters && !programFilters.isSubscriber)}
            handleSelect={() => {
              setProgramFilters({ isSubscriber: false });
            }}
          />
          <div className='d-block my-4 text-center'>
            <Buttons
              classNames='bttn-primary w-100 mb-3'
              text='Search'
              title='Search'
              type='button'
              onClick={onSearchClick}
              disable={invaliddateerror}
            />
            <Buttons
              classNames='bttn-secondary w-100'
              text='Clear'
              title='Clear'
              type='button'
              onClick={onClearFilterClick}
            />
          </div>
        </div>
        <div className='col-sm-8 col-md-9'>
          {/* Search Result */}
          {suppliers && suppliers.length > 0 ? (
            <>
              <Table className='tableHData'>
                <tr>
                  <th>
                    Supplier
                    <SortingIcon
                      sortlist={suppliers}
                      initialsortorder={sortorder}
                      currentsortname={sortvalue}
                      changesortname={(value: string) => setSortvalue(value)}
                      sortname='supplierName'
                      changeSortingFn={(list: any) => setProgramDetails({ suppliers: list })}
                    />
                  </th>
                  <th>Self-Declaration</th>
                  <th>Certifier</th>
                  <th>Certification</th>
                  <th>Status</th>
                  <th>Expires</th>
                  {hasEditProgramPermission && <th>Actions</th>}
                </tr>
                {suppliers &&
                  suppliers.length > 0 &&
                  suppliers
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.supplierName}</td>
                          <td>
                            {selfdeclaredattributes &&
                              selfdeclaredattributes
                                .filter(
                                  f =>
                                    item.attributes &&
                                    item.attributes.split(', ').includes(f.attributeType),
                                )
                                .map(m => m.attributeName)
                                .join(', ')}
                          </td>
                          <td>{item.certifier}</td>
                          <td>{item.programName}</td>
                          <td>{item.status === 'Unknown' ? '' : item.status}</td>

                          <td>{deprecatedGetDate(item.expires)}</td>

                          {hasEditProgramPermission && (
                            <td>
                              {item.status === 'Certified' || item.status === 'Rejected' ? (
                                <span
                                  className='staticLink'
                                  onClick={() => {
                                    toggle(item, 2);
                                  }}
                                >
                                  Manage
                                </span>
                              ) : (
                                <span
                                  className='staticLink'
                                  onClick={() => {
                                    toggle(item, 1);
                                  }}
                                >
                                  Approve
                                </span>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })
                    .slice(indexOfFirstList, indexOfLastList)}
              </Table>
              <Paging
                totalRecords={suppliers.length}
                currentPage={currentPage}
                onPagingClick={(value: number) => {
                  if (value !== currentPage) setCurrentPage(Number(value));
                }}
                pageLimit={listPerPage}
              />
            </>
          ) : (
            <NoResult message={internalLoader ? loadingMsg : 'No Certifications Available'} />
          )}
        </div>
      </div>
      <ModalPopUp
        size='md'
        title='Supplier Certification'
        closeModal={() => toggle()}
        isOpen={modal}
        backdrop='static'
      >
        <ProgramApprove
          initialValues={initialValues}
          handleCancelClick={toggle}
          selectedSupplier={selectedSupplier}
          certStatus={certStatus}
          respondCertRequest={respondCertRequest}
          selfdeclaredattributes={selfdeclaredattributes}
          updateType={updateType}
        />
      </ModalPopUp>
    </>
  );
}

/* function areEqual(prevProps: any, nextProps: any) {
  return isEqual(prevProps.programs, nextProps.programs) && isEqual(prevProps.shared, nextProps.shared);
} */

export default memo(ProgramSuppliers);
