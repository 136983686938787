import { useEffect, useMemo, useState } from 'react';

import { FlexContainer, Margin } from '@demandstar/components/styles';

import { DSButton } from '@demandstar/components/button';

import * as texts from './ManageDocumentTypes.texts';

import { DocumentTypeList, NewDocumentTypeContainer } from './ManageDocumentTypes.styles';

import { Alert } from 'src/components/common/alert';
import { documentTypeAlertId } from '../ContractDocuments.alerts';
import { hasValue } from 'src/utils/helpers';
import { LoadableWrapper } from 'src/components/common/loading/LoadableWrapper';
import { ManageDocumentTypeRow } from './ManageDocumentTypeRow';
import { ManageDocumentTypesForm } from './ManageDocumentTypesForm';
import { useContractDocuments } from '../useContractDocuments';

export const ManageDocumentTypes = () => {
  const { contractDocumentTypes, contractDocumentTypesLoadable } = useContractDocuments();
  const [formOpen, setFormOpen] = useState(false);

  const manageableDocTypes = useMemo(
    () => contractDocumentTypes.filter(dt => !dt.isDeleted),
    [contractDocumentTypes],
  );

  useEffect(() => {
    if (hasValue(contractDocumentTypesLoadable) && !manageableDocTypes.length) setFormOpen(true);
  }, [contractDocumentTypesLoadable, manageableDocTypes.length]);

  return (
    <>
      <Alert id={documentTypeAlertId} allowDismiss />
      <LoadableWrapper loadable={contractDocumentTypesLoadable}>
        <DocumentTypeList>
          {manageableDocTypes.map(docType => (
            <li key={docType.id}>
              <ManageDocumentTypeRow documentType={docType} />
            </li>
          ))}
        </DocumentTypeList>

        {!formOpen && (
          <FlexContainer
            justifyContent='center'
            style={{
              marginBottom: Margin.Extra,
              marginTop: Margin.Extra,
            }}
          >
            <DSButton type='tertiary' onClick={() => setFormOpen(true)}>
              {texts.addDocumentType}
            </DSButton>
          </FlexContainer>
        )}

        {formOpen && (
          <NewDocumentTypeContainer>
            <ManageDocumentTypesForm
              onComplete={() => setFormOpen(false)}
              allowCancel={!!manageableDocTypes.length}
            />
          </NewDocumentTypeContainer>
        )}
      </LoadableWrapper>
    </>
  );
};
