export const nigpCommodityGroup = 'NIGP Commodity Group';
export const nigpCommodityGroups = `${nigpCommodityGroup}s`;
export const chooseCommodityCode = `Choose ${nigpCommodityGroup}...`;
export const chooseStateMetro = 'Choose state/metro...';
export const commodityCodeTreeTitle = `Browse by ${nigpCommodityGroups}`;
export const emptyMessage =
  'Please select a city/metropolitan area or commodity code to see historical bids.';
export const historicalBids = 'Historical Bids';
export const pageTitleSuffix = 'Bids & Government Contracts (RFPs)';
export const stateAndMSATreeTitle = 'Browse by State';
