import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { breadcrumbsState } from 'src/store/recoil/navigationState';
import { SetBreadcrumbPayload } from '../../store/actions';

export function useSetBreadcrumb(breadcrumbConfig: SetBreadcrumbPayload, condition = true) {
  const setRecoilBreadcrumb = useSetRecoilState(breadcrumbsState);

  useEffect(() => {
    if (condition) {
      setRecoilBreadcrumb({
        component: breadcrumbConfig.component,
        page: breadcrumbConfig.page,
        activePageLabel: breadcrumbConfig.altname || breadcrumbConfig.name,
        recordLabel: breadcrumbConfig.name,
        recordId: breadcrumbConfig.id,
      });
    }
  }, [breadcrumbConfig, condition, setRecoilBreadcrumb]);
}
