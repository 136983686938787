import { FlexContainer, H2, H3 } from '@demandstar/components/styles';
import { DSLinkButton } from '@demandstar/components/button';
import { DSSearch } from '@demandstar/components/search';

import * as texts from './AddContract.texts';

import { Feature, FeatureFlagKey } from '../../../../components/common/feature-flag';

import { BidResult } from './BidResult';
import { ContractLimitAlert } from '../components/ContractLimitAlert';
import { paths } from '../../ContractManagement.paths';
import { useAwardedBidSearch } from './useAwardedBidSearch';
import { useContractSearch } from '../../search';
import { useRouter } from '../../../../shared/hooks';

export const AddContract = () => {
  const { searchParams } = useRouter();
  const {
    awardedBidsWithoutContracts,
    awardedBidsWithoutContractsLoadable,
    search,
    searchFilters,
    sort,
  } = useAwardedBidSearch();
  const { contractLimitExceeded } = useContractSearch();

  const awardOnly = searchParams.get('award') === 'true';

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      {!awardOnly && (
        <>
          <H2>{texts.addContract}</H2>
          <FlexContainer>
            <ContractLimitAlert />
          </FlexContainer>
          <p>{texts.addContractExplanatoryText}</p>
          <FlexContainer justifyContent='center' style={{ margin: '2rem 0' }}>
            <DSLinkButton
              dataTestId='add-external-contract'
              icon={'mdi-plus'}
              inactive={contractLimitExceeded}
              to={paths.navigation.createContract}
            >
              {texts.addExternalContract}
            </DSLinkButton>
          </FlexContainer>
        </>
      )}
      <H3>{texts.addAwardedContract}</H3>
      <DSSearch
        emptyMessage={texts.emptyMessage}
        filters={searchFilters}
        recordKey={'bidId'}
        search={search}
        renderResult={BidResult}
        sort={sort}
      />
      {awardedBidsWithoutContractsLoadable.state === 'hasValue' &&
        !awardedBidsWithoutContracts?.length && (
          <FlexContainer justifyContent='center'>
            <DSLinkButton icon={'mdi-plus'} to={'/buyers/bids?myBids=true'}>
              {texts.awardBid}
            </DSLinkButton>
          </FlexContainer>
        )}
    </Feature>
  );
};
