import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { DSNumberField, DSRadioGroupField } from '@demandstar/components/fields';
import { Flex, FlexContainer, LineHeight } from '@demandstar/components/styles';
import { DSRadioGroupOptions } from '@demandstar/components/fields/radio-group-field/DSRadioGroupField';
import { useAssert } from '@demandstar/components/utils';

import {
  getTermArray,
  isValidContractDuration,
} from 'src/features/contract-management/contract-management.helpers';
import { contractDetailsText } from './VerifyContractDetails.texts';
import { TermUnit } from '../../../contract-management.d';

interface TermLengthProps {
  name: string;
  label: string;
}

/**Radio Container which keeps the fields aligned when there is an error. */
const RadioContainer = styled(Flex)`
  margin-top: ${LineHeight.Base};
  align-self: flex-start;
`;

export const TermLength = (props: TermLengthProps) => {
  const { label, name } = props;

  /** Contract Management Form */
  const { watch, setValue, register } = useFormContext();

  /** Local Form */
  const methods = useForm({
    mode: 'onTouched',
  });
  const { watch: localWatch, setValue: setLocalValue, trigger } = methods;

  const lengthName = `${name}-term-length`;
  const unitName = `${name}-term-unit`;

  const localLength: number = localWatch(lengthName);
  const localUnit: TermUnit = localWatch(unitName);

  const value: string | undefined = watch(name);

  const [length, unit] = useMemo(() => {
    return getTermArray(value);
  }, [value]);

  const maxMap = {
    /** 365 days max */
    D: 365,
    /** 52 weeks max */
    W: 52,
    /** 12 months max */
    M: 12,
    /** 10 years max */
    Y: 10,
  };

  const options: DSRadioGroupOptions[] = [
    { label: contractDetailsText.termUnits(length, TermUnit.Days), value: TermUnit.Days },
    { label: contractDetailsText.termUnits(length, TermUnit.Weeks), value: TermUnit.Weeks },
    { label: contractDetailsText.termUnits(length, TermUnit.Months), value: TermUnit.Months },
    { label: contractDetailsText.termUnits(length, TermUnit.Years), value: TermUnit.Years },
  ];

  useAssert(isValidContractDuration(value, true), 'There should always be a valid term');

  useEffect(() => {
    /** keeps length in sync */
    setLocalValue(lengthName, length);
  }, [length, lengthName, setLocalValue]);

  useEffect(() => {
    /** keeps unit in sync */
    setLocalValue(unitName, unit);
  }, [setLocalValue, unit, unitName]);

  useEffect(() => {
    /** Here we ensure that the term is registered,
     * because there's no single HTML input that is keeping track of the value.
     */
    register(name);
  }, [name, register]);

  useEffect(() => {
    /** this is where the magic happens.
     * the actual value of the term is an ISO string based off of the length and unit.
     * For example: "P3W" // 3 weeks.
     */
    if (localLength) {
      setValue(name, `P${localLength}${localUnit}`);
    }
  }, [name, localLength, localUnit, setValue]);

  useEffect(() => {
    /** when unit changes, we trigger a validation check for length. */
    if (unit && value) {
      trigger(lengthName);
    }
  }, [trigger, lengthName, unit, value]);

  return (
    <FormProvider {...methods}>
      <FlexContainer>
        <Flex grow={1}>
          <DSNumberField name={lengthName} label={label} min={1} max={maxMap[unit]} />
        </Flex>
        <RadioContainer grow={3}>
          <DSRadioGroupField name={unitName} options={options} horizontal />
        </RadioContainer>
      </FlexContainer>
    </FormProvider>
  );
};
