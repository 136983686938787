import React, { memo, useContext } from 'react';
import { Buttons } from '../../../../customcontrols';
import CommodityListItem from './commoditylistitem';
//import { commodityParamType } from '../typedef'
import { commoditycodesTypes } from '../../../../../types/broadcastlist';
import { AppContext } from 'src/global-context/AppContext';
interface PropsTypes {
  updateCommodityCodes?: any;
  GoBackToPage: () => void;
  SetSelectedCommodity: any;
  commoditycodes?: commoditycodesTypes;
  pagefor?: string;
  bidId?: number;
  quotesId?: number;
  submitAddBidSearchVendors?: any;
  getAddBLGetSearchVendors?: any;
}

function Commodityselected(props: PropsTypes) {
  const {
    updateCommodityCodes,
    GoBackToPage,
    SetSelectedCommodity,
    commoditycodes,
    pagefor = '',
    bidId,
    quotesId,
    submitAddBidSearchVendors,
    getAddBLGetSearchVendors,
  } = props;
  const { commoditieslist = [] } = commoditycodes || {};

  const selectedcomodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => !item.isExisting && item.isSelected && item.isDisplay)) ||
    [];
  const existingcomodity =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.isExisting && item.isDisplay && item.isSelected)) ||
    [];

  const commodityCodes =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(item => item.commodityCode !== '00' && item.isSelected)) ||
    [];

  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'QuoteWire' : 'BidWire';
  const { RefetchAccountCommodityData } = useContext(AppContext);

  return (
    <>
      <div className='sideBarSpacing'>
        <div className='clearfix text-center'>
          {pagefor === 'addbid' || pagefor === 'addquotes' || pagefor === 'addbroadcastlist' ? (
            pagefor === 'addbroadcastlist' ? (
              <Buttons
                text='Continue'
                title='Continue'
                type='submit'
                classNames='bttn bttn-primary w-75'
                onClick={() => getAddBLGetSearchVendors({})}
                disable={commodityCodes.length === 0}
              />
            ) : (
              <Buttons
                text='Continue'
                title='Continue'
                type='submit'
                classNames='bttn bttn-primary w-75'
                onClick={() => submitAddBidSearchVendors({ id, type })}
                disable={commodityCodes.length === 0}
              />
            )
          ) : (
            <Buttons
              text='Submit Commodity Codes'
              title='Submit Commodity Codes'
              type='submit'
              classNames='bttn bttn-primary w-75'
              onClick={() => {
                updateCommodityCodes();
                RefetchAccountCommodityData(true);
              }}
              disable={selectedcomodity.length === 0 && existingcomodity.length === 0}
            />
          )}
          <Buttons
            text='Cancel'
            title='Cancel'
            classNames='bttn bttn-secondary w-75 my-3'
            onClick={GoBackToPage}
          />
        </div>
        <h4 className='mt-0'>
          {pagefor === 'supplierCommCode' ? 'Your Commodity Codes' : 'All Commodity Codes'}{' '}
          {pagefor === 'addbid' || pagefor === 'addquotes' || pagefor === 'supplierCommCode' ? (
            <>({selectedcomodity.length + (existingcomodity ? existingcomodity.length : 0)})</>
          ) : null}
        </h4>

        {selectedcomodity && selectedcomodity.length > 0 && pagefor !== 'supplierCommCode' ? (
          <h5>
            Just Added{' '}
            {selectedcomodity && selectedcomodity.length > 0 ? `(${selectedcomodity.length})` : ''}
          </h5>
        ) : null}

        {pagefor !== 'supplierCommCode' && (
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={selectedcomodity}
          />
        )}

        <>
          {pagefor !== 'supplierCommCode' ? (
            <h5>
              Previously Selected Codes{' '}
              {existingcomodity && existingcomodity.length > 0
                ? `(${existingcomodity.length})`
                : ''}
            </h5>
          ) : null}
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={existingcomodity}
          />
        </>

        {pagefor === 'supplierCommCode' ? <hr /> : null}
        {pagefor === 'supplierCommCode' && (
          <CommodityListItem
            SetSelectedCommodity={SetSelectedCommodity}
            listings={selectedcomodity}
            assignedClass='mp-4'
          />
        )}
      </div>
    </>
  );
}

export default memo(Commodityselected);
