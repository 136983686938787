import styled from 'styled-components';

import { Flex, FlexContainer, Margin } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';

import { navigation } from '../../../utils/texts/common';
import { scrollToTop } from '../../../utils/helpers';

const WizardButtonsWrapper = styled(FlexContainer)`
  margin-top: ${Margin.Extra};
`;

interface WizardSaveSpanProps {
  /** sets the flex grow factor of a flex item's main size */
  align?: 'left' | 'right';
}

const WizardSaveSpan = styled(Flex)<WizardSaveSpanProps>`
  text-align: ${({ align }) => align || 'right'};
`;

export interface WizardButtonsProps {
  onPrevious?: () => void;
  onNext: () => boolean | void | Promise<boolean> | Promise<void>;
  save?: () => void;
  inactiveNext?: boolean;
  altNext?: string;
  altPrev?: string;
  inactivePrev?: boolean;
  inactiveAll?: boolean;
  onInactiveClick?: () => void;
}

export const WizardButtons = (props: WizardButtonsProps) => {
  const {
    onPrevious,
    onNext,
    save,
    inactiveNext,
    inactivePrev,
    inactiveAll,
    onInactiveClick,
    altNext,
    altPrev,
  } = props;
  function prev() {
    scrollToTop();
    if (onPrevious) {
      onPrevious();
    }
  }
  function next() {
    scrollToTop();
    onNext();
  }
  return (
    <WizardButtonsWrapper>
      {onPrevious ? (
        <Flex grow={0}>
          <DSButton
            type='secondary'
            inactive={inactivePrev || inactiveAll}
            title={altPrev || navigation.previousPage}
            onClick={prev}
            onInactiveClick={onInactiveClick}
            dataTestId={'wizard.previous'}
          >
            {altPrev || navigation.previousPage}
          </DSButton>
        </Flex>
      ) : (
        <></>
      )}
      <Flex>
        <FlexContainer>
          {save && !inactiveAll && !inactiveNext && (
            <WizardSaveSpan align={onPrevious ? 'right' : 'left'}>
              <DSLink id='saveAndFinishLater' onClick={save}>
                {navigation.saveAndFinishLater}
              </DSLink>
            </WizardSaveSpan>
          )}
        </FlexContainer>
      </Flex>
      <Flex grow={0}>
        <DSButton
          inactive={inactiveNext || inactiveAll}
          title={altNext || navigation.nextPage}
          onClick={next}
          onInactiveClick={onInactiveClick}
          dataTestId={'wizard.next'}
        >
          {altNext || navigation.nextPage}
        </DSButton>
      </Flex>
    </WizardButtonsWrapper>
  );
};
