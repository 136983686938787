import { formatDollar } from '../utils';

export type DSDollarProps = {
  /** dollar value */
  children?: string | number;
};

/**
 * Wrap this around a numeric dollar it will format
 * the number and turn it into a dollar.
 * @example <DSDollar>1234.567</DSDollar> // $1,234.56
 */
export const DSDollar = (props: DSDollarProps) => {
  const { children } = props;

  if (children || children === 0) {
    return <>{formatDollar(children)}</>;
  }
  return <></>;
};
