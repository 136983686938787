import { commonLabels } from 'src/shared/constants';
import history from '../../../utils/history';
interface AwardBidControlProps {
  bidId: number;
}

export const AwardBidControl = (props: AwardBidControlProps) => {
  const { bidId } = props;

  function cancel() {
    history.push(`/buyers/bids/${bidId}`);
  }

  return (
    <li title='Cancel'>
      <span className='staticLink' onClick={cancel}>
        <i className='mdi mdi-close mr-1' />
        {commonLabels.cancel}
      </span>
    </li>
  );
};
