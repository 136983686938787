import dayjs from 'dayjs';

import { ProductApiResponse, ProductType } from 'src/types/products';

import { CartProduct } from 'src/types/products';
import { compareLists } from '.';
/**
 * @description prorates the provided `pricePerYear` based on the provided dates
 * and the days in the year preceeding `endDate`
 * @param pricePerYear The amount to prorate
 * @param startDate The date to begin prorating from, should be > (`endDate` - 1 year)
 * @param endDate The date the subscription period ends
 * @returns The prorated amount rounded to 2 decimal places
 */
export const proratePrice = (
  pricePerYear: number,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
): number => {
  const price = pricePerYear;

  const start = startDate.startOf('day');
  const end = endDate.startOf('day');
  const subscriptionStart = end.subtract(1, 'year');

  const ratio = start.diff(end, 'day') / subscriptionStart.diff(end, 'day');

  // Round to 2 decimal places & don't return any negatives
  return Math.round(price * ratio * 100.0) / 100.0;
};

/**
 * USE WITH CAUTION: THIS FUNCTION DOES NOT ALIGN WITH BACK-END FUNCTIONALITY
 * @deprecated
 * @description prorates a specific product based on existing subscriptions
 * @param selectedProduct The product being prorated
 * @param subscribedProducts The existing products to which the member is subscribed
 * @param proratedDate The date from which to begin prorating
 * @param expirationDate The date which the subscription expires
 * @returns The prorated amount rounded to 2 decimal places, always >= 0
 */
export const prorateSubscription = (
  selectedProduct: ProductApiResponse,
  subscribedProducts: ProductApiResponse[],
  proratedDate: dayjs.Dayjs,
  expirationDate: dayjs.Dayjs,
) => {
  const childProductsToCredit =
    selectedProduct.productType === ProductType.National
      ? [...subscribedProducts]
      : selectedProduct.productType === ProductType.State
      ? subscribedProducts.filter(p => p.parentId && p.parentId === selectedProduct.productId)
      : [];
  const totalCredits = childProductsToCredit
    .map(p => proratePrice(p.price, proratedDate, expirationDate))
    .reduce((sum, curr) => sum + curr, 0);

  // Ensure product doesn't end up with a negative price
  return Math.max(
    Math.round(
      (proratePrice(selectedProduct.price, proratedDate, expirationDate) - totalCredits) * 100,
    ) / 100.0,
    0,
  );
};

/**
 * @description calculates prorated price all products in a list & returns a corresponding list
 * with a populated `calculatedPrice` attribute
 * @param selectedProducts The products being added to a subscription
 * @param subscribedProducts The existing products to which the member is subscribed
 * @param expirationDate The date which the subscription expires
 * @param proratedDate An optional override for the date to begin proration, defaults to "Today"
 * @returns A new list corresponding to `selectedProducts` with prices calculated
 */
export const calculateProratedProductPrices = (
  selectedProducts: ProductApiResponse[],
  subscribedProducts: ProductApiResponse[],
  expirationDate: dayjs.Dayjs,
  prorateDate?: dayjs.Dayjs,
): CartProduct[] => {
  const now = dayjs().startOf('day');
  const expiration = dayjs(expirationDate).startOf('day');
  const prorate = prorateDate ? prorateDate.startOf('day') : now;

  // No proration if not an active, paying member
  if (
    !subscribedProducts?.filter(p => p.productType !== ProductType.FreeAgency)?.length ||
    expiration.diff(now, 'day') <= 0
  ) {
    return selectedProducts.map(p => {
      return {
        ...p,
        calculatedPrice: p.price,
      };
    });
  }

  const productsWithPrices = selectedProducts.map(p => {
    return {
      ...p,
      calculatedPrice: proratePrice(p.price, prorate, expirationDate),
    };
  });

  return productsWithPrices;
};

/**
 * @description calculates prorated price for "removed" products
 * @param newProducts The products that the user will be subscribed to after upgrading
 * @param subscribedProducts The existing products to which the member is subscribed
 * @param expirationDate The date which the subscription expires
 * @param proratedDate An optional override for the date to begin proration, defaults to "Today"
 * @returns A summed total of prorated prices for all removed products
 */
export const calculateMaxCartDiscount = (
  newProducts: ProductApiResponse[],
  subscribedProducts: ProductApiResponse[],
  expirationDate: dayjs.Dayjs,
  prorateDate?: dayjs.Dayjs,
): number => {
  const now = dayjs().startOf('day');
  const expiration = expirationDate.startOf('day');
  const prorate = prorateDate ? prorateDate.startOf('day') : now;

  // No discount if not an active, paying member
  if (
    !subscribedProducts?.filter(p => p.productType !== ProductType.FreeAgency)?.length ||
    expiration.diff(now, 'day') <= 0
  ) {
    return 0;
  }

  // Get subscribed products no longer in selected products
  const { secondOnly: removedProducts } = compareLists(
    newProducts,
    subscribedProducts,
    (s1, s2) => s1.productId === s2.productId,
  );

  // get prorated prices for removed products & sum
  const totalDiscount = removedProducts
    .map(p => {
      return proratePrice(p.price, prorate, expirationDate);
    })
    .reduce((a, b) => a + b, 0);

  return Math.round(totalDiscount * 100) / 100.0;
};
