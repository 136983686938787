import { LoremIpsum } from 'lorem-ipsum';

import { nanoid } from 'nanoid';

// Instantiate LoremIpsum class for mock data
export const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

export function getMockWords(count = 2) {
  return lorem.generateWords(count);
}

export function getMockSentences(count = 2) {
  return lorem.generateSentences(count);
}

export function getMockParagraphs(count = 2) {
  return lorem.generateParagraphs(count);
}

export function getMockNumber(max = 10) {
  return Math.round(Math.random() * max);
}

export function getMockPhoneNumber(increment = 3): string {
  return `+${getMockNumber(increment)} ${getMockNumber(increment)} ${getMockNumber(
    increment,
  )} ${getMockNumber(increment)}`;
}

export function getMockDate(offset = -100000000): Date {
  return new Date(new Date().valueOf() + Math.round(Math.random() * offset));
}

export function getMockDateFuture(offset = 100000000): Date {
  return getMockDate(offset);
}

export function getMockDateString(offset = -100000000): string {
  return getMockDate(offset).toDateString();
}

export function getMockDateStringFuture(offset = 100000000): string {
  return getMockDateString(offset);
}

export function getMockUuid(size = 7): string {
  return nanoid(size);
}
