import { useMemo } from 'react';

import { BaseLinkA, BaseLinkSpan, BaseLinkTo, DSLinkType } from './DSLinkStyles';
import { DataTestId, SVGIcon, Title } from '../types';
import { iconSuffix } from '../button/constants';

export type BaseDSLinkProps = DataTestId &
  SVGIcon &
  Title & {
    children: string;
    id?: string;
    large?: boolean;
    type?: DSLinkType;
  };

export type HrefLinkProps = BaseDSLinkProps & {
  href: string;
  newTab?: boolean;
  onClick?: never;
  to?: never;
};

export type OnClickLinkProps = BaseDSLinkProps & {
  href?: never;
  newTab?: never;
  onClick: () => void;
  to?: never;
};

export type ToLinkProps = BaseDSLinkProps & {
  href?: never;
  newTab?: never;
  onClick?: never;
  to: string;
};

export type DSLinkProps = HrefLinkProps | OnClickLinkProps | ToLinkProps;

/** Wrap this around a clickable string and it will turn it into a link.
 * @example <Link href='www.google.com'>Google</Link>
 */
export const DSLink = (props: DSLinkProps) => {
  const { children, type, dataTestId, href, icon, id, large, newTab, onClick, title, to } = props;

  // Optional svg icon or style string for legacy impl.
  // TODO: assumed left abstract icon layout position.
  const iconClassName = typeof icon === 'string' ? `mr-2 mdi ${icon}` : '';
  const IconComponent = typeof icon === 'string' ? 'i' : icon;
  // Build a testId value for the icon by appending the `'-icon'` suffix to the button's dataTestId value.
  const iconDataTestId = `${dataTestId}-${iconSuffix}`;
  const iconTitle = `${title} (${iconSuffix})`;

  /**
   * Copied from DSButtonLink
   * Temporary fix, per previous comments:
   * For React components, we want dataTestId, but for HTML elements, we want data-testid.
        This is happening because IconComponent can be
        ComponentType<DSSVGIconProps> (which expects dataTestId)
        or
        <i> (which expects data-testid and logs an error when it gets dataTestId)
        In SVGIcon, we are passing in `dataTestId` to a styled svg component. Then we're translating that property
        to turn dataTestId into data-testid.
        Instead, we should maintain separate props for DSSVGIconProps
        and BaseSVGIconProps. The former should take dataTestId. The latter should take data-testid.
   *
   */
  const iconDisplay = useMemo(() => {
    if (IconComponent) {
      if (iconClassName) {
        return (
          <IconComponent className={iconClassName} title={iconTitle} data-testid={iconDataTestId} />
        );
      } else {
        return <IconComponent title={iconTitle} dataTestId={iconDataTestId} />;
      }
    }
    return [];
  }, [IconComponent, iconClassName, iconDataTestId, iconTitle]);

  if (href) {
    return (
      <BaseLinkA
        data-testid={dataTestId || id}
        href={href}
        id={id}
        large={large}
        target={newTab ? '_blank' : '_self'}
        title={title}
        type={type}
      >
        {children}
        {iconDisplay}
      </BaseLinkA>
    );
  } else if (to) {
    return (
      <BaseLinkTo
        data-testid={dataTestId || id}
        id={id}
        large={large}
        title={title}
        to={to}
        type={type}
      >
        {children}
        {iconDisplay}
      </BaseLinkTo>
    );
  } else {
    return (
      <BaseLinkSpan
        data-testid={dataTestId || id}
        id={id}
        large={large}
        onClick={onClick}
        title={title}
        type={type}
      >
        {children}
        {iconDisplay}
      </BaseLinkSpan>
    );
  }
};
