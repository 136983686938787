/* eslint-disable max-lines */
/* TOREFACTOR
  Exceeds 500 lines.
*/
import { useCallback, useEffect, useState } from 'react';
import isEqualWith from 'lodash/isEqualWith';
import { Prompt } from 'react-router-dom';
import { Table } from 'reactstrap';

import {
  fileUploadMaxSize,
  fileUploadMaxSizeDisplay,
} from '@demandstar/components/constants/document';

import {
  bidResponseLeavingAlertMessage,
  responseDocumentDeleteAlertMessage,
} from '../../../utils/texts';
import { Buttons, DeprecatedInput, SelectBox } from '../../customcontrols';
import { docItemParamType, docParamType } from '../../../types/biddetail';
import { getFileExtension, toastFn } from '../../../utils/helpers';
import { WizardPage, WizardStatus } from '../../../types/wizard';
import { AcceptedFileFormatsModal } from '../../common/AcceptedFileFormatsModal';
import { accountinfoType } from '../../../types/paymentdetails';
import { ActionColor } from '../../../shared/styles';
import { CircularProgressbar } from 'react-circular-progressbar';
import { initialEbidsTypes } from '../../../types/ebids';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { useForceUpdate } from '../../../utils/helperHooks';
import { resultsTypes } from 'src/types';
import { useDSSelector } from 'src/store/reducers';
import { BidSummaryResult } from 'src/types/bidssummary';
import { useAuthContext } from 'src/auth/AuthProvider';

const divStyle = {
  td1: { width: '35%' },
  td2: { width: '30%' },
  td3: { width: '31%' },
  td4: { width: '4%' },
};

const currentUploadcheck = (upload_progress: any = [], description = '') => {
  const value =
    (upload_progress.length > 0 &&
      upload_progress.filter(
        (item: any) => item.description === description && item.progress <= 100,
      )) ||
    [];
  return value && value.length > 0 ? value[0] : '';
};

const checkUploadRequest = (upload_request: any = [], description = '') => {
  const value =
    (upload_request.length > 0 &&
      upload_request.filter((item: any) => item.title === description)) ||
    [];
  return value && value.length > 0 ? 'initial' : '';
};

interface propsTypes {
  submitEbitsFiles?: any;
  setRequiredDocs?: any;
  docs?: docParamType;
  selectoptions?: Array<{ title: string; value: string; id: number }>;
  filetypes?: Array<{ docFormat: string; description: string; icon: string }>;
  setEbitPage?: any;
  submitEbidResponseDetails?: any;
  responsedetails?: {
    requiredDocuments: docItemParamType[];
    supplementalDocuments: docItemParamType[];
  };
  upload_progress?: Array<Record<string, unknown>>;
  upload_request?: Array<Record<string, unknown>>;
  action_buttons?: boolean;
  setEbidDetails?: any;
  getEbitsResponseDetails?: any;
  getaccountinforequiredDocs?: any;
  match?: any;
  history?: any;
  ebids?: initialEbidsTypes;
  accountinfo?: accountinfoType;
}

type newArrayValueType =
  | {
      value: any;
      fileName: any;
      format: any;
      statusName: string;
      option?: undefined;
    }
  | {
      option: any;
      value?: undefined;
      fileName?: undefined;
      format?: undefined;
      statusName?: undefined;
    };

export function RequiredDoc(props: propsTypes) {
  const forceUpdate = useForceUpdate();
  const [checkvalidate, setCheckvalidate] = useState({
    selectbox: false,
    fileupload: false,
    documenttite: false,
  });
  const [documenttitle, setDocumenttitle] = useState('');
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [LeaveConfirmModalVisible, setLeaveConfirmModalVisible] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [nextLocation, setNextLocation] = useState('' as any);
  const [FileTypeModalVisible, setFileTypeModalVisible] = useState(false);

  // Getting redux values & fns from parent
  const {
    submitEbitsFiles,
    setRequiredDocs,
    docs,
    selectoptions = [],
    filetypes = [],
    setEbitPage,
    submitEbidResponseDetails,
    responsedetails,
    // files_response = [],
    // files_response_error = {},
    upload_progress,
    upload_request = [],
    action_buttons,
    setEbidDetails,
    getEbitsResponseDetails,
    getaccountinforequiredDocs,
    history,
    ebids,
    match,
    accountinfo,
  } = props;
  const { auth } = useAuthContext();
  const { agencydocs } = accountinfo || {};
  const { DocAllowedExt = [] } = agencydocs || {};
  const { reqdocs = [], supplementaldocs = [] } = docs || {};
  const bidID = match?.params?.bidId || '';
  const responseId = match?.params?.responseId || '';
  const memberId = match?.params?.memberId || '';
  const alldocs = [...reqdocs, ...supplementaldocs];
  const { pagehasValues = false, getEbitsResponseDetailsAPICall } = ebids || {};

  const results = useDSSelector(state => state.bidssummary.results as BidSummaryResult);
  const { supplementalDocOptional = false, eBidding = 'No', dueDate } = results || {};

  const currentDate = new Date().toISOString();

  // to get response tabs
  useEffect(() => {
    if (bidID && getEbitsResponseDetailsAPICall) {
      getEbitsResponseDetails({ auth, bidId: bidID, responseId, memberId });
    }
  }, [auth, getEbitsResponseDetails, bidID, responseId, memberId, getEbitsResponseDetailsAPICall]);

  useEffect(() => {
    getaccountinforequiredDocs();
  }, [getaccountinforequiredDocs]);

  // to get file format list
  /* useEffect(() => {
    let getEbitsFileFormatsAPICALL = true;
    if (bidID && filetypes.length === 0 && getEbitsFileFormatsAPICALL) {
      getEbitsFileFormats({ bidId: bidID });
    }
    return () => (getEbitsFileFormatsAPICALL = false) as any;
  }, [bidID, filetypes.length, getEbitsFileFormats]); */

  // reset upload_progress & upload_request redux values to reset
  useEffect(() => {
    return () => {
      setEbidDetails({ upload_progress: [], upload_request: [] });
    };
  }, [setEbidDetails]);

  // Set file upload of docs array based on index & Delete docs array based on index
  const ChangeDeleteFn = useCallback(
    (purpose, type, input, name, value, index, reqdocs, supplementaldocs, items) => {
      setCheckvalidate({
        ...checkvalidate,
        selectbox: false,
        fileupload: false,
        documenttite: false,
      });
      const changearray =
        type === 'reqdocs' ? reqdocs : type === 'suppdocs' ? supplementaldocs : '';
      const arrayname =
        type === 'reqdocs' ? 'reqdocs' : type === 'suppdocs' ? 'supplementaldocs' : '';
      if (changearray) {
        let docsArray = [...changearray];
        let newarrayvalue: newArrayValueType =
          input === 'file'
            ? {
                value: value,
                fileName: items.description,
                format: getFileExtension(value),
                statusName: 'Electronic/Online',
              }
            : { option: value };
        if (input === 'select' && value !== 'EL') {
          newarrayvalue = { option: value, value: '' } as any;
        }
        if (purpose === 'delete') {
          newarrayvalue = { value: '', option: 'EL', fileName: '', format: '' } as any;
        }
        if (purpose === 'delete' && type === 'suppdocs') {
          docsArray = changearray.filter((item: any, indexx: number) => indexx !== index);
        } else {
          docsArray[index] = { ...docsArray[index], ...newarrayvalue };
        }
        return { [arrayname]: [...docsArray] };
      } else {
        return { [name]: value };
      }
    },
    [checkvalidate],
  );

  const [docError, setDocError] = useState([] as any);
  const [suppError, setSuppError] = useState([] as any);
  // Executing the file upload/select input & delete fn on change and delete click
  const onChange = async (
    purpose: any,
    type: string,
    input: any,
    name: string | null,
    value: any,
    index: number,
    items: any = null,
  ) => {
    setEbidDetails({ pagehasValues: true });
    const final = await ChangeDeleteFn(
      purpose,
      type,
      input,
      name,
      value,
      index,
      reqdocs,
      supplementaldocs,
      items,
    );
    if (input === 'file') {
      const file: any = (value && value[0]) || {};
      const docErrArr = [] as any;
      if (file && file.size > fileUploadMaxSize) {
        toastFn(
          'error',
          `This file is too big. Please upload a file smaller than ${fileUploadMaxSizeDisplay}MB instead.`,
          'document file size c',
        );
      } else if (!DocAllowedExt.includes(getFileExtension(value).toLowerCase())) {
        if (type === 'reqdocs') {
          const errorExist = docError.filter((docEr: any) => docEr.row === index);
          if (errorExist.length) {
            errorExist[0].error = `${getFileExtension(value)} is not a supported file type.`;
            setDocError(errorExist.concat(docError));
          } else {
            docErrArr.push({
              row: index,
              error: `${getFileExtension(value)} is not a supported file type.`,
              type: 'format',
              uploadon: type,
            });
            setDocError(docErrArr.concat(docError));
          }
        } else {
          const errorExist = suppError.filter((docEr: any) => docEr.row === index);
          if (errorExist.length) {
            errorExist[0].error = `${getFileExtension(value)} is not a supported file type.`;
            setSuppError(errorExist.concat(suppError));
          } else {
            docErrArr.push({
              row: index,
              error: `${getFileExtension(value)} is not a supported file type.`,
              type: 'format',
              uploadon: type,
            });
            setSuppError(docErrArr.concat(suppError));
          }
        }
        //  toastFn('error', `Document Type not allowed`, 'document file size'); //${filetypes.map(item => item.description)}
      } else {
        setRequiredDocs(final);
        if (type === 'reqdocs') {
          const filterError = docError.filter((docEr: any) => docEr.row !== index);
          setDocError(filterError);
        } else {
          const filterError = suppError.filter((docEr: any) => docEr.row !== index);
          setSuppError(filterError);
        }
      }
    } else {
      setRequiredDocs(final);
    }
  };

  // Onclicking add button adds supplimentary docs array with file upload & select box
  const addSuppDoc = () => {
    if (documenttitle) {
      const check = alldocs.filter(items => items.description === documenttitle);
      if (check && check.length) {
        setCheckvalidate({
          ...checkvalidate,
          selectbox: false,
          fileupload: false,
          documenttite: true,
        });
        toastFn('error', 'Document name already exists', 'document title input already exists');
      } else {
        const emptyvalue = { description: documenttitle, value: '', option: 'EL' };
        const finalvalue = {
          supplementaldocs: [...supplementaldocs, emptyvalue],
        };
        setRequiredDocs(finalvalue);
        setDocumenttitle('');
      }
    } else {
      setCheckvalidate({
        ...checkvalidate,
        selectbox: false,
        fileupload: false,
        documenttite: true,
      });
      toastFn('error', 'Please enter document title', 'document title input');
    }
  };

  // Check Validation
  const checkreqdocs = reqdocs.filter(
    items => items.option === 'EL' && !items.value && !items.bidDocId,
  );
  const checkreqdocsopts = reqdocs.filter(items => !items.option);
  const checksuppdocs = supplementaldocs.filter(
    items => items.option === 'EL' && !items.value && !items.bidDocId,
  );
  const checksuppdocsopts = supplementaldocs.filter(items => !items.option);

  // Going to previous page/next page using action fn from parent component
  const setPage = (action: string) => {
    if (currentDate > dueDate) {
      toastFn('error', 'Bid has been closed');
      history.goBack();
    } else {
      if (action === 'next') {
        if (
          checkreqdocs.length === 0 &&
          checksuppdocs.length === 0 &&
          checkreqdocsopts.length === 0 &&
          checksuppdocsopts.length === 0
        ) {
          setEbidDetails({ action_buttons: false });
          submitEbitsFiles({ auth, reqdocs, supplementaldocs, bidID, partialsubmit: false });
        } else {
          setCheckvalidate({
            ...checkvalidate,
            selectbox: true,
            fileupload: true,
            documenttite: false,
          });
          let message = 'Please submit required documents';
          if (checkreqdocsopts.length > 0 || checksuppdocsopts.length > 0) {
            message = 'Please select (Submission Options) for all required documents';
          }
          toastFn('error', message, 'document file upload');
        }
      } else {
        const wizard: WizardPage[] = [
          { name: 'Contact Information', status: WizardStatus.Current, id: 1 },
          { name: 'Documents Upload', status: WizardStatus.Unavailable, id: 2 },
          { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
        ];
        setEbitPage({ wizard });
      }
    }
  };

  //Required Documents/Supplemental Documents Table column one (Name Column)
  const DocsNameContent = (purpose: string, items: any) => {
    return (
      <>
        <span
          className={
            'mb-0' +
            `
						${items.option === 'EL' ? 'successTick' : ''}
						${items.option === 'MN' ? 'manual' : ''}
						${items.option === 'NS' ? 'notSubmit' : ''}
						${items.option === 'None' ? 'noneDot' : ''}
				`
          }
        />
        {items.description}
      </>
    );
  };

  let meta = { touched: false, error: '' };
  //Required Documents/Supplemental Documents Table column two (File Upload Box Column)
  const FileUploadBoxContent = (
    purpose: any,
    items: any,
    index: number,
    error: any,
    accept: Array<string>,
  ) => {
    if (error) {
      meta = { touched: true, error: 'Required' };
    } else {
      meta = { touched: false, error: '' };
    }
    return (
      <DeprecatedInput
        type='file'
        name={items.name}
        labelname={items.value && `file1_${index + 1}`}
        handleChange={(name: string, value: any) =>
          onChange('change', purpose, 'file', name, value, index, items)
        }
        disabled={items.option !== 'EL'}
        accept={accept}
        files={items.value}
        meta={meta}
        label='Choose a file'
      />
    );
  };

  //Required Documents/Supplemental Documents Table column three (Select Box Column)
  const SelectBoxContent = (purpose: any, items: any, index: number, error: any) => {
    if (error) {
      meta = { touched: true, error: 'Required' };
    } else {
      meta = { touched: false, error: '' };
    }
    if (
      currentUploadcheck(upload_progress, items.description) ||
      checkUploadRequest(upload_request, items.description)
    ) {
      return (
        <>
          <span>Electronic/Online</span>
        </>
      );
    }

    let supplyoption = selectoptions;
    if (purpose === 'suppdocs') {
      supplyoption = [{ title: 'Online/Electronic', value: 'EL', id: 1 }];
    }
    const selectchange = (key: string, value: any) => {
      const filterError = docError.filter((docEr: any) => docEr.row !== index);
      if (value !== 'EL') setDocError(filterError);
      if (purpose !== 'suppdocs') {
        return onChange('change', purpose, 'select', null, value, index);
      } else {
        return () => {};
      }
    };
    return (
      <SelectBox
        onChange={(key: string, value: any) => selectchange(key, value)}
        label={''}
        options={supplyoption.map(itemss => ({
          ...itemss,
          selected: Boolean(items.option === itemss.value),
          key: index,
        }))}
        meta={meta}
        parentClass='mt-0'
      />
    );
  };

  //Required Documents/Supplemental Documents Table column four (Delete Icon Area)
  const deleteFiles = (items: any) => {
    const value = {
      memberResponseItemId: items.memberResponseItemId || 0,
      bidResponseItemStatus: 'DE',
      bidDocId: items.bidDocId || 0,
    };
    submitEbidResponseDetails({
      auth,
      bidId: bidID,
      partialsubmit: false,
      bidResponseItems: [value],
      internal: true,
      deletefile: true,
    });
  };
  const DeleteIconContent = (purpose: any, items: any, index: number) => {
    if (items.bidDocId) {
      return (
        <i
          className='mdi mdi-24px mdi-delete-forever-outline staticLink'
          onClick={() => {
            setConfirmModalVisible(true);
            setDeletingItem(items);
          }}
        />
      );
    }
    if (
      currentUploadcheck(upload_progress, items.description) ||
      checkUploadRequest(upload_request, items.description)
    ) {
      return <></>;
    }
    return (
      <i
        className='mdi mdi-24px mdi-delete-forever-outline staticLink'
        onClick={() => {
          onChange('delete', purpose, null, null, null, index);
        }}
      />
    );
  };
  const doc = alldocs.filter(item => item.value && item.value.length && !item.bidDocId);

  // Upload Progress content based on api/ redux state values
  const FileUploadProgressContent = (purpose: any, items: any, index: number) => {
    const progressvalue = currentUploadcheck(upload_progress as any, items.description) as {
      progress: number;
      description: string;
    };
    if (items.bidDocId && !checkUploadRequest(upload_request as any, items.description)) {
      return <></>;
    }
    if (progressvalue && progressvalue.progress === 100) {
      return <></>;
    }
    if (progressvalue && progressvalue.description) {
      if (progressvalue.progress < 100) {
        setTimeout(() => {
          forceUpdate();
        }, 1000);
      }
      return (
        <div style={{ width: '40px' }}>
          <CircularProgressbar
            styles={{
              text: {
                fill: ActionColor.primary,
                fontSize: '28px',
              },
            }}
            value={progressvalue.progress}
            text={`${progressvalue.progress}%`}
          />
        </div>
      );
    } else if (checkUploadRequest(upload_request as any, items.description)) {
      return (
        <div style={{ width: '40px' }}>
          <CircularProgressbar
            styles={{
              text: {
                fill: ActionColor.primary,
                fontSize: '28px',
              },
            }}
            value={0}
            text={`${0}%`}
          />
        </div>
      );
    }
  };

  // Continue button based on upload completion/validation
  const ContinueFn = () => {
    if (currentDate > dueDate) {
      toastFn('error', 'Bid has been closed');
      history.goBack();
    } else {
      const { requiredDocuments = [], supplementalDocuments = [] } = responsedetails || {};
      const olddoc = [...(requiredDocuments || []), ...supplementalDocuments] || [];
      const docarr = [...reqdocs, ...supplementaldocs];
      const newdoc = docarr.map(({ option, ...all }) => all);
      const noneDocs = reqdocs.filter(item => item.option === 'None');

      if (noneDocs.length > 0) {
        toastFn(
          'error',
          `You are missing ${noneDocs.length} ${
            noneDocs.length > 1 ? 'items' : 'item'
          } required to bid by this agency`,
        );
      } else {
        if (isEqualWith(olddoc, newdoc)) {
          const wizard: WizardPage[] = [
            { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
            { name: 'Documents Upload', status: WizardStatus.Completed, id: 2 },
            { name: 'Review Bid', status: WizardStatus.Current, id: 3 },
          ];
          setEbitPage({ wizard });
        } else {
          submitEbitsFiles({ auth, reqdocs, supplementaldocs, bidID, reviewebid: true });
        }
      }
    }
  };

  const ContinueButton = () => {
    if (
      checkreqdocs.length === 0 &&
      checksuppdocs.length === 0 &&
      checkreqdocsopts.length === 0 &&
      checksuppdocsopts.length === 0
    ) {
      if (!doc.length) {
        return (
          <Buttons
            classNames='bttn-primary mt-4 mb-3 float-right'
            text='Next'
            onClick={ContinueFn}
          />
        );
      }
    }
    return (
      <Buttons
        classNames='bttn-primary mt-4 mb-3 float-right'
        text={doc.length > 1 ? 'Upload All' : 'Upload'}
        onClick={() => {
          setPage('next');
          setEbidDetails({ pagehasValues: false });
        }}
        disable={!action_buttons}
      />
    );
  };

  const discardConfirmationPopup = () => {
    setConfirmModalVisible(false);
    setDeletingItem(null);
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!pagehasValues || LeaveConfirmModalVisible) {
      return true;
    } else {
      setLeaveConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const leaveDiscardConfirmationPopup = () => {
    if (nextLocation) {
      setEbidDetails({ pagehasValues: false });
      history.push(nextLocation.pathname);
    }
  };

  const errorMsg = (index: any, type: string) => {
    const errData =
      type === 'reqdocs'
        ? docError.filter((docEr: any) => docEr.row === index)
        : suppError.filter((docEr: any) => docEr.row === index);
    return docError.length && errData.length ? (
      <tr>
        <td colSpan={5} className='text-red200 sourceSemiBold'>
          {errData[0].error}
          {errData[0].type === 'format' && (
            <span className='cursorPointer underLine' onClick={() => setFileTypeModalVisible(true)}>
              {' '}
              Please use a supported file type
            </span>
          )}
        </td>
      </tr>
    ) : null;
  };

  return (
    <>
      <Prompt when={pagehasValues} message={handleBlockedNavigation} />
      <h4>Required Documents</h4>
      <div className='innerColIndent'>
        <p>
          The following documents are required by the agency for this project. Please select which
          documents you will be submitting electronically (online), and which ones you will submit
          directly to the agency (offline).
        </p>
        <a className='cursorPointer' onClick={()=>setFileTypeModalVisible(true)}> Click here to see  accepted file formats</a>
        {/* <div className='noteImportant'>
          <h5>Agency Accepted File Formats</h5>
          <ul className='pl-2 mb-0 reqDocList'>
            {filetypes.map((item: any, index: number) => {
              const icon = docformats[item.icon.substring(0, item.icon.indexOf('.'))];
              return (
                <li key={index} className='d-inline-block mb-2 col-6'>
                  <img alt={item.description} src={icon} className='mr-2' />
                  {item.description}
                </li>
              );
            })}
          </ul>
        </div> */}

        {reqdocs.length > 0 ? (
          <Table className='tableHData'>
            <tr>
              <th style={divStyle.td1}>Required Document</th>
              <th style={divStyle.td2}>Submission Option</th>
              <th style={divStyle.td3}>Uploaded Document</th>
              <th style={divStyle.td4} />
            </tr>
            {reqdocs.map((items, index) => (
              <>
                <tr key={index}>
                  <td>{DocsNameContent('reqdocs', items)}</td>
                  <td>
                    {items.bidDocId ? (
                      <>{items.statusName}</>
                    ) : (
                      <>
                        {SelectBoxContent(
                          'reqdocs',
                          items,
                          index,
                          checkreqdocsopts.length > 0 && checkvalidate.selectbox && !items.option,
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {items.bidDocId ? (
                      <>
                        {items.fileName}.{items.format}
                      </>
                    ) : (
                      <>
                        {FileUploadBoxContent(
                          'reqdocs',
                          items,
                          index,
                          checkreqdocs.length > 0 &&
                            checkvalidate.fileupload &&
                            !items.values &&
                            items.option === 'EL',
                          filetypes.map(item => `.${item.docFormat}`),
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {items.value || items.bidDocId ? (
                      <>{DeleteIconContent('reqdocs', items, index)}</>
                    ) : null}
                  </td>
                  <td>{FileUploadProgressContent('reqdocs', items, index)}</td>
                </tr>
                {errorMsg(index, 'reqdocs')}
              </>
            ))}
          </Table>
        ) : null}
      </div>
      {!supplementalDocOptional && (
        <>
          <h4>Supplemental Documents</h4>
          <div className='innerColIndent'>
            <p>You can upload additional documents here.</p>
            <div className='w-100 clearfix'>
              <DeprecatedInput
                label='Document Title'
                type='text'
                classNames='class'
                placeholder='Document Title'
                parentClass='w-50 mb-3 float-left clear-fix'
                name='documenttitle'
                value={documenttitle}
                handleChange={(name: string, value: any) => {
                  setDocumenttitle(value);
                  setCheckvalidate({ ...checkvalidate, documenttite: false });
                }}
                meta={{ touched: checkvalidate.documenttite && !documenttitle, error: 'Required' }}
              />
              <Buttons
                classNames='bttn-secondary mb-3 float-right clear-fix'
                text='Add Document'
                onClick={addSuppDoc}
                disable={!action_buttons}
              />
            </div>
            {supplementaldocs.length ? (
              <Table className='tableHData'>
                <tr>
                  <th style={divStyle.td1}>Supplemental Document</th>
                  <th style={divStyle.td2}>Submission Option</th>
                  <th style={divStyle.td3}>Uploaded Document</th>
                  <th style={divStyle.td4} />
                </tr>
                {supplementaldocs.map((items, index) => (
                  <>
                    <tr key={index}>
                      <td>{DocsNameContent('suppdocs', items)}</td>
                      <td>
                        {items.bidDocId && items.bidDocId > 0 ? (
                          <>{items.statusName}</>
                        ) : (
                          <>
                            {SelectBoxContent(
                              'suppdocs',
                              items,
                              index,
                              checksuppdocsopts.length > 0 &&
                                checkvalidate.selectbox &&
                                !items.option,
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {items.bidDocId && items.bidDocId > 0 ? (
                          <>
                            {items.fileName}.{items.format}
                          </>
                        ) : (
                          <>
                            {FileUploadBoxContent(
                              'suppdocs',
                              items,
                              index,
                              checksuppdocs.length > 0 &&
                                checkvalidate.fileupload &&
                                !items.values &&
                                items.option === 'EL',
                              filetypes.map(item => `.${item.docFormat}`),
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {items.value || items.bidDocId ? (
                          <>{DeleteIconContent('suppdocs', items, index)}</>
                        ) : null}
                      </td>
                      <td>{FileUploadProgressContent('suppdocs', items, index)}</td>
                    </tr>
                    {errorMsg(index, 'suppdocs')}
                  </>
                ))}
              </Table>
            ) : null}
          </div>
        </>
      )}

      <div className='w-100 clearfix'>
        <Buttons
          classNames='bttn-secondary mt-4 mb-3'
          text='Previous'
          onClick={() => setPage('previous')}
          disable={!action_buttons}
        />
        {ContinueButton()}
      </div>

      <ModalPopUp
        title='Delete Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>{responseDocumentDeleteAlertMessage}</p>
        <div className='d-flex justify-content-between'>
          <Buttons
            classNames='bttn-secondary mt-2 mr-5'
            text='No, keep my response complete'
            title='No, keep my response complete'
            type='button'
            onClick={discardConfirmationPopup}
          />
          <Buttons
            classNames='bttn-primary  mt-2'
            text='Yes, continue and mark my response as Incomplete'
            title='Yes, continue and mark my response as Incomplete'
            type='button'
            onClick={() => {
              if (deletingItem !== null) {
                deleteFiles(deletingItem);
                setDeletingItem(null);
              }
              setConfirmModalVisible(false);
            }}
          />
        </div>
      </ModalPopUp>

      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={LeaveConfirmModalVisible}
        closeModal={() => setLeaveConfirmModalVisible(false)}
      >
        <p>{bidResponseLeavingAlertMessage}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Stay on this page'
          title='Cancel'
          type='button'
          onClick={() => {
            setLeaveConfirmModalVisible(false);
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Leave without saving'
          title='OK'
          type='button'
          onClick={leaveDiscardConfirmationPopup}
        />
      </ModalPopUp>

      <AcceptedFileFormatsModal
        isOpen={FileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      />
    </>
  );
}
