import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilStateLoadable,
} from 'recoil';
import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';

import { Assert } from '@demandstar/components/utils';

import {
  addAwardeeContact,
  deleteAwardeeContactAPI,
  getContract,
  publishContract,
  updateAwardeeContact,
  updateContract,
} from './ContractManagement.services';
import {
  AwardeeContact,
  AwardeeContactDraft,
  ContractDetails,
  ContractDetailsAPI,
  ContractDetailsDraft,
} from './contract-management.d';
import { getLoadableContents } from '../../utils';
import { useContractSearch } from './search';
import { useRouter } from '../../shared/hooks/useRouter';

/**
 * @param {string} contractId
 */
export const defaultContractDetailsQuerySelector = selectorFamily<
  ContractDetails | undefined,
  string | undefined
>({
  key: 'defaultContractDetailsQuerySelector',
  get: contractId => async () => {
    if (!contractId) {
      return undefined;
    }
    return getContractDetails(contractId);
  },
});

/**
 * @param {string} contractId
 */
export const contractDetailsState = atomFamily<ContractDetails | undefined, string | undefined>({
  key: 'contractDetailsState',
  default: defaultContractDetailsQuerySelector,
});

export const selectedContractIdState = atom<string | null>({
  key: 'selectedContractIdState',
  default: '',
});

export const newContractIdState = atom<string | undefined>({
  key: 'newContractIdState',
  default: undefined,
});

export function sanitizeContractDetails(response: ContractDetailsAPI): ContractDetails {
  return {
    ...response,
    startDate: dayjs(response.startDate),
    endDate: dayjs(response.endDate),
    awardeeContacts: response.awardeeContacts ?? [],
    bidId: response.bidId || undefined,
  };
}

/**
 * Typed Contract Summary request
 * @param contractId
 * @returns a Promise with a ContractDetails type
 */
export async function getContractDetails(contractId: string): Promise<ContractDetails> {
  const contract = await getContract(contractId);
  return sanitizeContractDetails(contract);
}

/**
 * Manages the current contract information
 */
export function useContractDetails(id?: string) {
  const { routerParams } = useRouter();
  const contractId = id || routerParams.contractId;
  const [contractDetailsLoadable, setContractDetails] = useRecoilStateLoadable(
    contractDetailsState(contractId),
  );
  const contractDetails = getLoadableContents(contractDetailsLoadable) || undefined;
  const { updateSearchResults } = useContractSearch();

  /** Save Contract Details, either editing or creating a contract.
   * This is a useRecoilCallback so that upon creation, we can set a specific
   * family member of the contractDetails atomFamily. This saves us an unnecessary API call
   * to then *get* that same contract we just created.
   */
  const saveContractDetails = useRecoilCallback(
    ({ set }) =>
      async (contract: ContractDetailsDraft) => {
        const response = await updateContract(contract);
        set(contractDetailsState(response.id), sanitizeContractDetails(response));
        updateSearchResults(sanitizeContractDetails(response));
        return response;
      },
    [updateSearchResults],
  );

  const submitContract = useCallback(async () => {
    Assert(contractId, 'There must be a contract id when we submit an awardee');
    const contractDetails = await publishContract(contractId);
    setContractDetails(sanitizeContractDetails(contractDetails));
    updateSearchResults(sanitizeContractDetails(contractDetails));
    return true;
  }, [contractId, setContractDetails, updateSearchResults]);

  const addUpdateAwardeeContact = useCallback(
    async (awardee: AwardeeContactDraft) => {
      Assert(contractId, 'There must be a contract id when we add an awardee');
      const contractDetails = awardee.id
        ? await updateAwardeeContact(contractId, awardee as AwardeeContact)
        : await addAwardeeContact(contractId, awardee as AwardeeContact);
      setContractDetails(sanitizeContractDetails(contractDetails));
      updateSearchResults(sanitizeContractDetails(contractDetails));
      return true;
    },
    [contractId, setContractDetails, updateSearchResults],
  );

  const deleteAwardeeContact = useCallback(
    async (awardeeId: string) => {
      Assert(contractId, 'There must be a contract id when we add an awardee');
      const contractDetails = await deleteAwardeeContactAPI(contractId, awardeeId);
      setContractDetails(sanitizeContractDetails(contractDetails));
      updateSearchResults(sanitizeContractDetails(contractDetails));
    },
    [contractId, setContractDetails, updateSearchResults],
  );

  return {
    addUpdateAwardeeContact,
    contractDetails,
    contractDetailsLoadable,
    deleteAwardeeContact,
    saveContractDetails,
    submitContract,
  };
}

/**
 * to get the `contractId` value via the `useRouter`
 * and pass it to `setSelectedContractId`.
 * @returns
 */
export function useSetSelectedContractIdFromRoute() {
  const { contractId } = useRouter().routerParams;
  const { setSelectedContractId } = useSelectedContractId();

  useEffect(() => {
    setSelectedContractId(contractId ?? '');
  }, [contractId, setSelectedContractId]);
}

/**
 * Helper fn to save a step when reading/writing to `selectedContractIdState`,
 * @returns
 */
export function useSelectedContractId() {
  const [selectedContractId, setSelectedContractId] = useRecoilState(selectedContractIdState);

  function resetSelectedContractId() {
    setSelectedContractId('');
  }
  return { resetSelectedContractId, selectedContractId, setSelectedContractId };
}
