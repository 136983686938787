import { ComponentType } from 'react';

import { OnClick } from 'src/types/shared';

import {
  FloridaCentral,
  FloridaNorth,
  FloridaPanhandle,
  FloridaSouth,
} from '../../../assets/images/maps/us/florida';

import { ProductMapContainerFlorida } from './styles';

interface Bundle {
  title: string;
  idList: number[];
  mapComponent: ComponentType<OnClick>;
}

export interface BundleCategory {
  title: string;
  items: Bundle[];
  mapContainer: ComponentType<OnClick>;
}

export const productBundles: BundleCategory[] = [
  {
    title: 'Florida',
    mapContainer: ProductMapContainerFlorida,
    items: [
      {
        title: 'Florida Panhandle',
        mapComponent: FloridaPanhandle,
        idList: [
          10089, 11962, 10103, 10105, 10123, 12049, 10126, 10132, 10147, 10148, 10151, 11808,
        ],
      }, //12 (20)
      {
        title: 'North Florida',
        mapComponent: FloridaNorth,
        idList: [
          10087, 10088, 10090, 10096, 10098, 10102, 10104, 10720, 10124, 10128, 10131, 10140, 10141,
          10150,
        ],
      }, //14 (17)
      {
        title: 'Central Florida',
        mapComponent: FloridaCentral,
        idList: [
          10091, 10095, 10111, 10113, 10114, 10115, 10117, 10121, 10127, 10134, 10135, 10137, 10138,
          10139, 10145, 10142, 10146,
        ],
      }, //17 (18)
      {
        title: 'South Florida',
        mapComponent: FloridaSouth,
        idList: [
          10092, 10094, 10097, 10100, 10108, 10112, 10122, 10129, 10099, 10130, 10136, 10144,
        ],
      }, //12 (12)
    ],
  },
];
