// Form labels and messages in order

export const addFromPlanholder = 'Add From Existing Planholder';

export const supplierName = 'Supplier Name';
export const supplierNameMessage =
  'This is the name of the business that won the contract. It will be the visible on the public view for this contract.';

export const contractAmount = 'Contract Amount';
export const contractAmountMessage =
  'Of the total contract amount; how much was awarded to this business?';

export const email = 'Business Email';
export const emailMessage =
  "This should be the supplier's business email address. It will be visible on the public view for this contract.";

export const businessPhone = 'Business Phone';
export const businessPhoneMessage =
  "This should be the supplier's business phone line. It will be visible on the public view for this contract.";

export const contactName = 'Primary Contact Name';
export const contactEmail = 'Contact Email';
export const contactPhone = 'Contact Phone';

// Buttons
export const addAnotherAwardee = 'Add Another Awardee';
export const deleteAwardee = 'Delete Awardee';
export const saveAndAdd = 'Save & Add Another';
export const saveAwardee = 'Save Awardee';
