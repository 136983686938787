import { ReactNode } from 'react';

import { BidId, DueDate, Id, Label, Status } from './shared';

export enum RequiredActionType {
  RequiredDocument = 'requiredDocument',
  VendorDocument = 'vendorDocument',
}

export type RequiredActionStatus = 'Not Started' | 'Pending' | 'Complete';

/**
 * Required Action type for bid management
 * Format for UI display, post translation
 */
export type RequiredAction = BidId &
  DueDate &
  Label &
  Status<RequiredActionStatus> & {
    actionId: string;
    agencyName?: string;
    bidTitle?: string;
    component: ReactNode;
    type: RequiredActionType;
  };
