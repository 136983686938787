/**
 * Navigation path constants, by feature
 */
export const supplierPaths = {
  base: '/suppliers',
  bids: '/suppliers/bids',
  quotes: '/suppliers/quotes',
  responses: '/suppliers/responses',
};

export const buyerPaths = {
  base: '/buyers',
  bids: '/buyers/bids',
  quotes: '/buyers/quotes',
};

export const limitedPaths = {
  browseBids: '/browse-bids',
} as const;

export enum SubPaths {
  Activities = 'activities',
  BidResponses = 'bid-responses',
  Categories = 'categories',
  Details = 'details',
  Groups = 'groups',
  Management = 'management',
  MetroArea = 'metro-areas',
  MyResponses = 'my-responses',
  Notifications = 'notifications',
  PlanHolders = 'planholders',
  RequiredActions = 'required-actions',
  Responses = 'responses',
  Similarbids = 'similarbids',
  States = 'states',
}

export const navigationPaths = {
  suppliers: supplierPaths,
  buyers: buyerPaths,
  subPaths: SubPaths,
};
