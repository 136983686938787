import { cloneElement, useCallback, useMemo, useState } from 'react';

import { FlexContainerColumn, H3 } from '../styles';
import { DataTestId } from '../types';
import { DSAccordionCardProps } from './DSAccordionCard';

type AccordionCardProps = Pick<
  DSAccordionCardProps,
  'isOpen' | 'dataTestId' | 'onClick' | 'useDefaultBehavior'
> & {
  key: React.Key;
};

export type DSAccordionProps = DataTestId & {
  allowMultiple?: boolean;
  children?: React.ReactElement<AccordionCardProps> | React.ReactElement<AccordionCardProps>[];
  defaultValue?: React.Key;
  title?: string;
};

/**
 * Base DSAccordion component. Wraps accordion items to allow for higher level control.
 * Direct children should expose `isOpen`, `onClick`, `key`, and `dataTestId` props.
 * @example <DSAccordion title="Accordion Title">
              <DSAccordionCard header="Card Title" key='card1'>
                Card Content
              </DSAccordionCard>
              <DSAccordionCard header="Card Title 2" key='card2'>
                Card Content 2
              </DSAccordionCard>
            </DSAccordion>
 */
export const DSAccordion = ({
  allowMultiple,
  children,
  dataTestId = 'ds-accordion',
  defaultValue,
  title,
}: DSAccordionProps) => {
  const [selectedItem, setSelectedItem] = useState<React.Key | undefined>(defaultValue);
  const handleClick = useCallback(
    (key: React.Key | null) => {
      const isOpen = !!selectedItem && selectedItem === key;
      setSelectedItem(!isOpen && key ? key : undefined);
    },
    [selectedItem],
  );

  const newChildren = useMemo(() => {
    if (children && 'length' in children && children.length > 0) {
      return children.map(c => {
        const testId = c.props.dataTestId || `${dataTestId}-card-${c.key}`;
        if (allowMultiple) {
          return cloneElement(c, {
            ...c.props,
            dataTestId: testId,
            isOpen: undefined,
            useDefaultBehavior: true,
          });
        } else {
          return cloneElement(c, {
            ...c.props,
            dataTestId: testId,
            isOpen: selectedItem === c.key,
            onClick: () => handleClick(c.key),
            useDefaultBehavior: false,
          });
        }
      });
    }
    return children;
  }, [allowMultiple, children, dataTestId, handleClick, selectedItem]);

  return (
    <FlexContainerColumn alignItems={'stretch'} rowGap>
      {title && <H3>{title}</H3>}
      {newChildren}
    </FlexContainerColumn>
  );
};
