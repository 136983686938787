import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';

export function createBuyer(data = {}) {
  return Api.postRequestWithAuthentication(Paths.createBuyer, data, {
    baseURL: constants.api.userUrl,
  });
}

export function addAgencyBidType(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidTypesUpdate, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getAgencyLegalAd(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyLegalad, data, {
    baseURL: constants.api.userUrl,
  });
}

export function agencyAddLegalAd(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyAddLegalAd, data, {
    baseURL: constants.api.userUrl,
  });
}
export function agencyAddPublications(data = {}) {
  return Api.postRequest(Paths.agencyAddPublications, data, {
    baseURL: constants.api.url,
  });
}

export function agencyAddEbidDocuments(data = {}) {
  return Api.postRequest(Paths.agencyAddEbidDocuments, data, {
    baseURL: constants.api.url,
  });
}
export function agencyGetPublications(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyGetPublications, data, {
    baseURL: constants.api.url,
  });
}

export function agencyGetRequiredDocs(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyGetRequiredDocs, data, {
    baseURL: constants.api.url,
  });
}

export function agencyGetBidTypes(data: any = {}) {
  return Api.getRequest(`${Paths.agencyBidTypes}?memberId=${data.buyerId}`, {
    baseURL: constants.api.userUrl,
  });
}
export function agencyBuyerInformation(data: any = {}) {
  return Api.getRequest(`${Paths.agencyBuyerInformation}?buyerId=${data.buyerId}`, {
    baseURL: constants.api.userUrl,
  });
}
export function agencyFinishRegistration(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyFinishRegistration, data, {
    baseURL: constants.api.userUrl,
  });
}
export function agencyRemoveBidtype(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyRemoveBidtype, data, {
    baseURL: constants.api.userUrl,
  });
}
export function agencyRemovePublication(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencyRemovePublication, data, {
    baseURL: constants.api.url,
  });
}

export function agencySettings(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencySettings, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function removeAgencyImages(data: { type: string }) {
  return Api.postRequestWithAuthentication(Paths.removeAgencyImages, data, {
    baseURL: constants.api.agencyUrl,
  });
}

export function updateAgencySettings(data: any = {}) {
  const formData = new FormData();
  formData.append('Theme', data && data.theme);
  formData.append('Position', data && data.imgPos.value);
  if (!data.logo.imageFrom) formData.append('Logo', data && data.logo);
  if (!data.banner.imageFrom) formData.append('Banner', data && data.banner);
  if (data.memberId) {
    formData.append('agencyId', data && data.memberId);
  }

  return Api.postFormDataRequestWithAuthentication(Paths.updateAgencySettings, formData, {
    baseURL: constants.api.agencyUrl,
  });
}

export function getMatchedAgencies(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getMatchedAgencies,data, {
    baseURL: constants.api.userUrl,
  });
}

export function reactivateSuspendedAgency(data: Record<string, unknown>) {
  return Api.postRequestWithAuthentication(Paths.reactivateMember, data, {
    baseURL: constants.api.userUrl,
  });
}
