import styled from 'styled-components';

import { BorderColor, BorderThickness, Flex, Margin, Padding, ParagraphFontSize } from '../styles';

export const DSRecordItemTitle = styled(Flex)`
  padding: ${Padding.None} ${Padding.Base};
`;

// Note: the negative in the margin-top lets the subtitle come right below the title,
//       while still keeping space between the title and the data if no subtitle is present.
export const DSRecordItemSubTitle = styled(Flex)`
  font-size: ${ParagraphFontSize.Small};
  margin-top: -${Margin.Base};
  padding: ${Padding.None} ${Padding.Base} ${Padding.Base} ${Padding.Base};
`;

export const DataList = styled.ul`
  border-bottom: ${BorderThickness.Base} solid ${BorderColor.Table};
  list-style-type: none;
  padding: ${Padding.None} ${Padding.None} ${Padding.Extra} ${Padding.None};

  /* grid layout for equal column widths */
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  li {
    padding: ${Padding.None} ${Padding.Base};
  }
`;

export const FlexStatus = styled(Flex)`
  text-align: right;
`;
