import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { NoResult, Paging } from '../../../../customcontrols/index';
import { MemberType, pageSize, sortDirections } from '../../../../../utils/constants';
import { loadingMsg } from '../../../../../utils/texts';
import { deprecatedGetDateTimeZoneConverted } from '../../../../../utils/helpers';
import SortingIcon from '../../../../common/sortingIcon';
import { sharedParamType } from '../../../../../types/commoditycodes';
import { bidItemParamType, bidssummaryParamType } from '../../../../../types/biddetail';
import { useAuthContext } from 'src/auth/AuthProvider';

const tdWidth = {
  date: {
    width: '220px',
  },
  author: {
    width: '21%',
  },
  event: {
    width: '24%',
  },
  detail: {
    width: '33%',
  },
};

interface PropsTypes {
  getResponseEvents?: any;
  bidssummary?: bidssummaryParamType;
  shared?: sharedParamType;
  responseId: string;
  setBidSummaryDetails?: any;
}

function ViewHistory(props: PropsTypes) {
  const { getResponseEvents, bidssummary, shared, responseId, setBidSummaryDetails } = props;
  const { auth } = useAuthContext();
  const { ebidresponseEvents = [], results } = bidssummary || {};
  const { tzfn, tzn } = (results as bidItemParamType) || {};
  const { internalLoader } = shared || {};
  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  useEffect(() => {
    setCurrentPage(1);
    setSortorder(sortDirections.ASC);
  }, []);

  useEffect(() => {
    if (responseId) getResponseEvents({ responseId });
  }, [getResponseEvents, responseId]);

  //Logic for sort
  const [sortvalue, setSortvalue] = useState('dtCreated');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);

  return (
    <>
      <Table className='tableFixed'>
        <thead>
          <tr>
            <th style={tdWidth.date}>
              Date
              <SortingIcon
                sortlist={ebidresponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='dtCreated'
                changeSortingFn={(list: any) => setBidSummaryDetails({ ebidresponseEvents: list })}
              />
            </th>
            <th style={tdWidth.author}>
              Author
              <SortingIcon
                sortlist={ebidresponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='author'
                changeSortingFn={(list: any) => setBidSummaryDetails({ ebidresponseEvents: list })}
              />
            </th>
            <th style={tdWidth.event}>
              Event
              <SortingIcon
                sortlist={ebidresponseEvents}
                initialsortorder={sortorder}
                currentsortname={sortvalue}
                changesortname={(value: string) => setSortvalue(value)}
                sortname='responseEventName'
                changeSortingFn={(list: any) => setBidSummaryDetails({ ebidresponseEvents: list })}
              />
            </th>
            <th style={tdWidth.detail}>Detail</th>
          </tr>
        </thead>
        <tbody>
          {ebidresponseEvents.length > 0 ? (
            <>
              {ebidresponseEvents
                .map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.dtCreated && deprecatedGetDateTimeZoneConverted(item.dtCreated, tzfn)}{' '}
                      {auth?.memberType !== MemberType.AgencyBuyer ? `( ${tzn} )` : null}
                    </td>
                    <td>{item.author}</td>
                    <td>{item.responseEventName}</td>
                    <td>{item.description}</td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </>
          ) : (
            <tr>
              <td colSpan={8}>
                <NoResult message={internalLoader ? loadingMsg : 'No History Available'} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Paging
        totalRecords={ebidresponseEvents.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
}

export default ViewHistory;
