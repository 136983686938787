import { Button, Col, Container, Row } from 'reactstrap';

import Configurations from '../../../settings';
import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';

const BidLimitExceeded = () => {
  const RedirectPage = () => {
    window.location = Configurations.REACT_APP_REDIRECT_HOME_URL as any;
  };

  return (
    <div className='splashWrapper d-flex align-items-center'>
      <Container className='errorTitle text-center'>
        <Row className='d-flex justify-content-center whiteLink'>
          <Col xs={12} md={8}>
            <h1>Bid Visit Limit Exceeded</h1>
            <p>
              Your account has shown a pattern of excessive activity that exceeds the usage behavior
              of typical users. Our terms of service prohibit the use of automated scrapers on
              DemandStar, and as such your access has been temporarily paused.
            </p>
            <p>
              If this was in error, please contact {DemandStarSupportEmail} to get your account
              reinstated.
            </p>
            <Button
              title='Go Home'
              variant='outline-light'
              className='btn btn-outline-light'
              onClick={RedirectPage}
            >
              Go Home
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BidLimitExceeded;
