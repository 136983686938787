import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';

import {
  DSDollarField,
  DSEmailField,
  DSPhoneField,
  DSTextField,
} from '@demandstar/components/fields';
import { DSButton } from '@demandstar/components/button';

import {
  AwardBidEventName,
  useAwardBidEvents,
} from '../../../../shared/hooks/amplitude/awardBid.events';
import { AwardeeDraft } from 'src/store/recoil/bid-awardees';
import { usePlanholders } from 'src/store/recoil/planholders/usePlanholders';
import { useRouter } from '../../../../shared/hooks';

interface AddNewAwardeeProps {
  /** function to add awardee and close modal */
  addAwardee: (awardee: AwardeeDraft) => void;
}

interface AddAwardeeForm {
  amount: number;
  email: string;
  name: string;
  phone: string;
}

/**
 * A pop-up which allows the user to add a planholder and automatically
 * assign that planholder as an awardee.
 */
export const AddNewAwardee = (props: AddNewAwardeeProps) => {
  const { addAwardee } = props;

  // Hooks
  const { logEvent } = useAwardBidEvents();
  const { planholders, addPlanholder } = usePlanholders();
  const methods = useForm<AddAwardeeForm>({ mode: 'onTouched' });
  const {
    formState: { isValid },
    getValues,
  } = methods;

  const { routerParams } = useRouter();
  const bidId = Number(routerParams.bidId);

  /** Grabs values from the form, creates a planholder. */
  async function saveNewSupplier() {
    logAddSupplierAmplitude();
    const { name, email, phone } = getValues();
    const newPlanholder = {
      name,
      email,
      bidId,
      phone,
    };
    await addPlanholder(newPlanholder);
  }

  /** saves new supplier, closeModal occurs in useEffect */
  async function saveAwardeeAndClose() {
    await saveNewSupplier();
  }

  /** when we get planholder info back from the endpoint,
   * we add the awardee to Select Awardee page*/
  useEffect(() => {
    const { email, amount } = getValues();
    const newPlanholder = planholders.find(planholder => {
      return planholder.email === email;
    });

    if (newPlanholder) {
      // add new awardee
      addAwardee({
        awardedToMemberId: newPlanholder.mi,
        supplierName: newPlanholder.supplierName,
        email: newPlanholder.email,
        amount,
      });
    }
  }, [addAwardee, getValues, planholders]);

  function logAddSupplierAmplitude() {
    logEvent({
      eventName: AwardBidEventName.NonDemandStarSupplier,
      eventProps: {
        'Awardee Name': getValues().name,
        'Email Address': getValues().email,
      },
    });
  }

  return (
    <div className='row justify-content-center'>
      {/** TODO: Remove className styling */}
      <div className='col-12 col-md-12 col-lg-10'>
        <div>
          <FormProvider {...methods}>
            <DSTextField name='name' label='Supplier Name' />
            <DSEmailField name='email' label='Email' />
            <DSDollarField name='amount' label='Award Amount' optional />
            <DSPhoneField name='phoneNumber' label='Phone' optional />
          </FormProvider>
        </div>
        <DSButton type='submit' inactive={!isValid} onClick={saveAwardeeAndClose}>
          Save Supplier
        </DSButton>
      </div>
    </div>
  );
};
