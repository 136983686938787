import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import DatePicker from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../../store/actionTypes';
import {
  datePickerYear,
  invalidDateMessage,
  invalidGreaterDateMessage,
  invalidYearDateMessage,
  usaDateFormat,
} from '../../utils/constants';
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import { deprecatedConvertUSAFormat, validateTypedDate } from '../../utils/helpers';
//import 'react-dates/initialize';

interface PropsTypes {
  handleChange?: any;
  type?: string;
  disabled?: any;
  label?: string;
  // name?: string
  startDatename?: string;
  endDatename?: string;
  startDatevalue?: any;
  endDatevalue?: any;
  meta?: any;
  optional?: any;
  input?: any;
  placeholder?: string;
  minDate?: any;
  maxDate?: any;
  parentClass?: string;
  reduxform?: boolean;
  showClearDate?: boolean;
  showDefaultInputIcon?: any;
  isOutsideRange?: boolean;
  numberOfMonths?: number;
  classNames?: string;
}

function DateRangePickerComponent(props: PropsTypes) {
  const {
    handleChange = () => {},
    disabled,
    label,
    // name = '',
    startDatevalue = '',
    endDatevalue = '',
    meta = {},
    optional,
    input,
    placeholder = 'mm/dd/yyyy',
    minDate = moment(),
    maxDate,
    parentClass,
    reduxform = false,
    showClearDate = false,
    isOutsideRange = false,
    startDatename = '',
    endDatename = '',
  } = props;
  const { showDefaultInputIcon = true } = props;
  const { touched, error } = meta || {};
  const [focusedInput, setFocusedInput] = useState(null);
  const [internalError, setInternalError] = useState('');
  const [state, setState] = useState({ format: 'MM/DD/YYYY' });
  const datePickerRef = useRef<any>();

  const initialValue = [];
  if (startDatevalue) {
    const startDate = startDatevalue.format(usaDateFormat);
    initialValue.push(startDate);
  }
  if (endDatevalue) {
    const endDate = endDatevalue.format(usaDateFormat);
    initialValue.push(endDate);
  }

  const startDateId = reduxform
    ? `multi_date_redux_${input.startDatename}`
    : `multi_date_${startDatename}`;
  const endDateId = reduxform
    ? `multi_date_redux_${input.endDatename}`
    : `multi_date_${endDatename}`;
  const dispatch = useDispatch();

  const settingErrorFn = useCallback(
    (value: string) => {
      setInternalError(value);
      dispatch({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { invaliddateerror: value },
      });
    },
    [dispatch],
  );

  const onMultiDateChange = useCallback(
    (dateArray: any, format = state.format) => {
      if (dateArray && dateArray.length !== 0) {
          let datesIsValid = true;
          const startDate = new DateObject(dateArray[0]).format();
          const endDate =
            dateArray[1] && dateArray[1].isValid && dateArray[1].year > 2000
              ? new DateObject(dateArray[1]).format()
              : '';

        if (dateArray[0].year <= 2000 || (dateArray[1] && dateArray[1].year <= 2000)) {
          settingErrorFn(invalidYearDateMessage);
          datesIsValid = false;
        } else if (startDate || endDate) {
            handleChange(startDatename, moment(startDate));
            handleChange(endDatename, endDate ? moment(endDate) : '');

            if (startDate && !moment(startDate, usaDateFormat).isValid()) {
              settingErrorFn(invalidDateMessage);
              datesIsValid = false;
            } else if (endDate && !moment(endDate, usaDateFormat).isValid()) {
              settingErrorFn(invalidDateMessage);
              datesIsValid = false;
            } else {
              settingErrorFn('');
            }
            if (
              endDate &&
              startDate &&
              moment(moment(endDate).format(usaDateFormat)).isBefore(
                moment(startDate).format(usaDateFormat),
              )
            ) {
              settingErrorFn(invalidGreaterDateMessage);
              datesIsValid = false;
            }
          } else {
            handleChange(startDatename, '');
            handleChange(endDatename, '');
          }
          if (startDate && endDate && datesIsValid) datePickerRef.current.closeCalendar();
        } else {
          settingErrorFn(invalidDateMessage);
      }
    },
    [endDatename, handleChange, state.format, settingErrorFn, startDatename],
  );
  

  return (
    <div
      className={
        'datePickerWrapper ' +
        parentClass +
        ((touched && error) || internalError ? ' errorWrapper' : '')
      }
    >
      <div title={usaDateFormat} className='inputWrapper'>
        <label title={usaDateFormat}>{label}</label>
        <DatePicker
          range
          ref={datePickerRef}
          id='datepickerId'
          placeholder='MM/DD/YYYY to MM/DD/YYYY'
          onChange={onMultiDateChange}
          value={initialValue}
          format='MM/DD/YYYY'
          rangeHover={true}
          containerClassName='DateRangePicker'
          minDate={minDate}
          maxDate={maxDate}
          numberOfMonths={2}
          dateSeparator=' to '
          animations={[
            opacity(),
            transition({
              from: 40,
              transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
            }),
          ]}
          monthYearSeparator="|"  
        />
        <i className='mdi mdi-calendar'></i>
      </div>
      {optional === true ? <span>(optional)</span> : optional ? <span>{optional}</span> : ''}
      <div className='errorMsg'>
        {(touched && error) || internalError ? (
          <>{<span>{internalError ? internalError : error}</span>}</>
        ) : null}
      </div>
    </div>
  );
}

export default memo(DateRangePickerComponent);
