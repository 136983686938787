import { AxiosRequestConfig } from 'axios';

import {  axiosFormDataPostAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';

/**
 * Generic upload document post call.
 * @param formData: FormData
 * @param config: AxiosRequestConfig
 * @description Default `AxiosRequestConfig.onUploadProgress` handler will returns an integer showing progress (%)
 */
export function uploadDocument<ResponseType>(formData: FormData, config: AxiosRequestConfig = {}) {
  const response = axiosFormDataPostAuthenticated<ResponseType>({
    baseURL: constants.api.documentUrl,
    data: formData,
    url: Paths.documentUpload,
    ...config,
  });
  return response;
}
