export { noReminders } from './Reminders.texts';

export const reminders = 'Reminders';
export const remindersDescription =
  'Set and send reminders to yourself, your team, and your suppliers.';

export const createReminder = 'Create a new Reminder';

export const inputLabels = {
  date: 'Sort by Date',
  alphabetically: 'Sort alphabetically',
  status: 'Filter by Status',
  contract: 'Filter by Contract',
  who: 'Who is this reminder for?',
} as const;
