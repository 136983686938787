import { BasePanel } from './DSLoadingPanel.styles';
import { DSProgressIndicator } from '../progress-indicator';
import { LogoStarSize } from '../progress-indicator/DSProgressIndicator.d';

export const DSLoadingPanel = (props: { children?: string }) => {
  return (
    <BasePanel>
      <DSProgressIndicator width={LogoStarSize.Inline} />
      {props.children ?? 'Loading...'}
    </BasePanel>
  );
};
