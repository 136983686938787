import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { appText, commonText } from '@demandstar/components/constants';
import { MediaQueryMinWidths, TextColor } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';
import { DSLogoIcon } from '@demandstar/components/icon/DSLogoIcon';

import {
  HeaderContainer,
  HeaderLink,
  LogoContainer,
  NavigationMenuToggleButtonContainer,
  NavListContainer,
  SignUpButton,
} from './StaticHeader.styles';
import { useSelectedBidId, useSetSelectedBidIdFromRoute } from 'src/shared/hooks/useSelectedBidId';
import { constants } from '../../../utils/settings';
import { limitedloginPreviousUrlName } from '../../../utils/constants';
import { NavigationMenuToggleIcon } from '../icons/NavigationMenuToggleIcon';
import { useRouter } from 'src/shared/hooks';

interface StaticHeaderProps {
  initialLoadRef: MutableRefObject<boolean>;
}

export function StaticHeader({ initialLoadRef }: StaticHeaderProps) {
  const { history } = useRouter();

  // Update the selected bidId from the current url params.
  useSetSelectedBidIdFromRoute();

  const bidId = useSelectedBidId().selectedBidId;

  const [navMenuOpen, setNavMenuOpen] = useState(initialLoadRef.current);
  const toggle = useCallback(() => setNavMenuOpen(!navMenuOpen), [navMenuOpen]);

  function handleSignUpClick() {
    if (bidId) {
      sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${bidId}/details`);
    }
    history.push('/registration');
  }

  function handleLoginClick() {
    if (bidId) {
      sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${bidId}/details`);
    }
    history.push('/login');
  }

  function goToCorporateLandingPage() {
    window.location.href = constants.corporateUrl;
  }

  function testSizeForNavMenuToggle() {
    // hide nav for narrow views on resize
    if (document.documentElement.clientWidth <= MediaQueryMinWidths.Small * 16) {
      setNavMenuOpen(false);
    } else {
      setNavMenuOpen(true);
    }
  }

  useEffect(() => {
    // test size on initial load
    if (initialLoadRef.current === true) {
      testSizeForNavMenuToggle();
      initialLoadRef.current = false;
    }

    const resizeListener = () => {
      testSizeForNavMenuToggle();
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [initialLoadRef]);

  return (
    <>
      <HeaderContainer className='staticHeader'>
        <LogoContainer title={appText.companyFullName}>
          <DSLogoIcon
            onClick={goToCorporateLandingPage}
            height={32}
            width={288}
            color={TextColor.Action}
            viewBox={'96 0 72 30'}
          />
          <NavigationMenuToggleButtonContainer>
            <DSButton
              icon={NavigationMenuToggleIcon}
              onClick={toggle}
              title={navMenuOpen ? commonText.hideNavMenu : commonText.showNavMenu}
              type='secondary'
            />
          </NavigationMenuToggleButtonContainer>
        </LogoContainer>

        <NavListContainer visible={navMenuOpen}>
          <li>
            <HeaderLink href={`${constants.networkUrl}/for-business/`}>For Business</HeaderLink>
          </li>
          <li>
            <HeaderLink href={`${constants.networkUrl}/for-government/`}>For Government</HeaderLink>
          </li>
          <li>
            <HeaderLink href={`${constants.networkUrl}/about/`}>About</HeaderLink>
          </li>
          <>
            <li>
              <SignUpButton onClick={handleSignUpClick} type='primary'>
                {commonText.signUp}
              </SignUpButton>
            </li>
            <li>
              <SignUpButton onClick={handleLoginClick} type='secondary'>
                {commonText.signIn}
              </SignUpButton>
            </li>
          </>
        </NavListContainer>
      </HeaderContainer>
    </>
  );
}
