import { useRecoilStateLoadable, useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import { DSButton } from '@demandstar/components/button';

import { allProductsState, selectedProductsState } from 'src/store/recoil/productState';
import { NationalProductId, ProductType } from 'src/types/products';

import { AddStateButtonWrapper } from '../styles';
import { CartAlertType } from 'src/types/registration';
import { getAgencyCounts } from '../helpers';
import { RegistrationInfoAlert } from '../registration-info-alert/RegistrationInfoAlert';
import { scrollToTop } from 'src/utils/helpers';
import { useCart } from './useCart';

/**
 * @description optionally displays an upsell alert based on items in cart
 * @returns JSX.Element
 *
 * @example <CartAlert />
 */
export const CartAlert = () => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const selectedProducts = useRecoilValue(selectedProductsState);
  const { alertType, stateUpgrades } = useCart();

  const isNationalUpgrade = alertType === CartAlertType.NationalUpgrade;

  const nationalAgencyCount = useMemo(() => {
    if (allProducts.state === 'hasValue')
      return getAgencyCounts(
        allProducts.contents,
        NationalProductId.UnitedStates,
        ProductType.National,
      );
  }, [allProducts.contents, allProducts.state]);

  const agenciesWithUpgrade = useMemo(() => {
    if (isNationalUpgrade) {
      return nationalAgencyCount;
    } else {
      return undefined;
    }
  }, [isNationalUpgrade, nationalAgencyCount]);

  const agenciesInCart = useMemo(() => {
    if (isNationalUpgrade && allProducts.state === 'hasValue') {
      let agencyCt = 0;

      if (selectedProducts.county?.length) {
        agencyCt += selectedProducts.county
          .map(c => getAgencyCounts(allProducts.contents, c.productId, ProductType.County))
          .reduce((prev, ct) => prev + ct, 0);
      }

      if (selectedProducts.state?.length) {
        agencyCt += selectedProducts.state
          .map(s => getAgencyCounts(allProducts.contents, s.productId, ProductType.State))
          .reduce((prev, ct) => prev + ct, 0);
      }

      return agenciesWithUpgrade && agenciesWithUpgrade < agencyCt ? agenciesWithUpgrade : agencyCt;
    } else {
      return undefined;
    }
  }, [
    agenciesWithUpgrade,
    allProducts.contents,
    allProducts.state,
    isNationalUpgrade,
    selectedProducts.county,
    selectedProducts.state,
  ]);

  const nationalProduct = useMemo(() => {
    if (allProducts.state === 'hasValue') {
      return allProducts.contents.find(x => x.productId === NationalProductId.UnitedStates);
    }
    return undefined;
  }, [allProducts.contents, allProducts.state]);

  const isAnyStateUpgradeSelected = useMemo(() => {
    return stateUpgrades?.filter(s => selectedProducts.state?.includes(s))?.length ? true : false;
  }, [selectedProducts.state, stateUpgrades]);

  return (
    <>
      {alertType === CartAlertType.StateUpgrade && isAnyStateUpgradeSelected && (
        <>
          <AddStateButtonWrapper justifyContent='center'>
            <DSButton
              onClick={() => {
                scrollToTop();
              }}
            >
              {'Add another state?'}
            </DSButton>
          </AddStateButtonWrapper>
          <RegistrationInfoAlert type={CartAlertType.County} />
        </>
      )}
      {alertType !== CartAlertType.None &&
        (alertType === CartAlertType.StateUpgrade && stateUpgrades?.length ? (
          stateUpgrades.map(state => {
            const isSelected = selectedProducts.state?.includes(state);
            return (
              !isSelected && (
                <RegistrationInfoAlert key={state.productId} type={alertType} product={state} />
              )
            );
          })
        ) : (
          <RegistrationInfoAlert
            agenciesInCart={agenciesInCart}
            agenciesWithUpgrade={agenciesWithUpgrade}
            product={nationalProduct}
            type={alertType}
          />
        ))}
    </>
  );
};
