import { BidId, Description, MemberId, Message } from './shared';

import { FileDeleteStatusMessage } from './document';
import { Selectable } from './common';

/**
 * @prop {string} email - recipient's email (required)
 *
 * @prop {string} contactName - contact name for Colleague recipient
 * @prop {string} role - role for Colleague recipient
 *
 * @prop {number} accountId - account id for DemandStar recipient
 * @prop {number} memberId - member id for DemandStar recipient
 * @prop {string} memberName - member name for DemandStar recipient
 */
export interface CustomNotificationRecipient {
  accountId: number | null;
  contactName: string | null;
  email: string;
  memberId: number | null;
  memberName: string | null;
  role: string | null;
}

export interface InternalRecipient extends CustomNotificationRecipient {
  contactName: string;
  role: string;
}

export interface CustomAwardNotification {
  accountId: number;
  bidId: number;
  contactEmail: string | null;
  contactName: string | null;
  contactPhone: string | null;
  contactTitle: string | null;
  customMessage: string | null;
  customMessageType: CustomMessageType;
  introduction: string | null;
  memberId: number;
  recipients: CustomNotificationRecipient[];
  subject: string | null;
  shouldNotify: boolean;
}

export type EmailInfo = {
  subject: string;
  introduction: string;
  customMessage: string;
  contactName: string;
  contactTitle: string;
  contactPhone: string;
  contactEmail: string;
  recipients: CustomNotificationRecipient[];
  shouldNotify: boolean;
};

export enum CustomMessageType {
  Awardees = 1,
  NonAwardees = 2,
  Colleagues = 3,
}

export type UnselectedBidder = Selectable<number>;

/// Vendor Documents

// Based on
// `/DemandStar.Services.Content/DemandStar.Services.Content/Entities/Data/Query/VendorDocumentQuery.cs`
export type VendorDocumentGetAPIRequest = BidId & {
  supplierMemberId: number;
};

// Based on
// `/DemandStar.Services.Content/DemandStar.Services.Content/Entities/Data/Results/VendorDocumentResult.cs`
export type GetVendorSpecificDocumentsAPIResponse = BidId & {
  agencyDocDescription?: string;
  agencyDocType?: string;
  bidAwardDocId?: number;
  docFormat?: string;
  docType?: string;
  fileName: string;
  icon?: string;
  mimeType?: string;
  modifiedDate: string;
  originalFileName?: string;
  path: string;
  requireConversion: boolean;
  status: string;
  statusType: string;
  type: string;
};

// Based on
// `/DemandStar.Services.Document/DemandStar.Services.Document/Entities/Data/Query/VendorSpecificBidDocUpdateQuery.cs`
// TODO - enforce required args
export type VendorDocumentUploadAPIRequest = BidId &
  MemberId & {
    agencyDocDescription?: string;
    agencyDocType?: string;
    bidAwardDocId?: number;
    docFormatType?: string;
    documentTitle?: string;
    documentType?: string | number;
    filePath?: string;
    fileSize?: number;
    isPDFConversion?: boolean;
    opsAccountId?: number;
    originalFileName?: string;
    supplierMemberId?: number;
  };

// Based on
// `/DemandStar.Services.Document/DemandStar.Services.Document/Entities/Data/Results/VendorSpecificBidDocResult.cs`
export type VendorDocumentUploadAPIResponse = BidId &
  Description &
  Message & {
    actionBy: string;
    actionById?: number;
    agencyDocType?: string;
    agencyDocDescription?: string;
    bidAwardDocId: number;
    bidAwardRequiredDocId?: number;
    bidSequenceNumber?: number;
    docStatus: string;
    docStatusType: string;
    docTypeName: string;
    eventTime?: string;
    eventType: string;
    isDuplicate?: boolean;
    status: boolean;
    supplierMemberId: number;
  };

// Based on
// `DemandStar.Services.Content/DemandStar.Services.Content/Contracts/Requests/VendorDocDeleteRequest.cs`
export type VendorDocumentDeleteAPIRequest = MemberId & {
  bidAwardDocId: number;
  opsAccountId?: number;
};

// Standard(?) delete message confirmation
// TODO: try to standardize attribute name casing on future service responses.
export type VendorDocumentDeleteAPIResponse = { Message: FileDeleteStatusMessage; Status: string };

export interface NewSupplier {
  address?: any; //Address;
  contact?: string;
  declaredAttributes?: string;
  email: string;
  faxNumber?: string;
  name: string;
  phoneNumber?: string;
  planholderId?: number;
}
