import styled from 'styled-components';

import { BackgroundColor, FlexContainer, FontWeight, Padding, TextColor } from '../styles';

export const BasePanel = styled(FlexContainer)`
  align-items: center;
  background-color: ${BackgroundColor.Transparent};
  color: ${TextColor.Action};
  font-weight: ${FontWeight.Bold};
  justify-content: center;
  padding: ${Padding.Base};
`;
