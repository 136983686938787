import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { dashboardQuotesList } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetDashboardQuotesListData = (data: any) => {
  return dashboardQuotesList(data);
};

export const useGetDashboardQuotesListData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardQuotes'],
    queryFn: () => GetDashboardQuotesListData(data),
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};

