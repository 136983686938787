import { emailValErrorMsg, isValidEmail } from '@demandstar/components/utils';

import { TextInput, TextInputProps } from './TextInput';

type EmailInputProps = Omit<TextInputProps, 'errorMsg'>;

/** @deprecated use DSEmailField instead */
export const EmailInput = (props: EmailInputProps) => {
  const errorMsg = props.value && !isValidEmail(props.value) ? emailValErrorMsg : undefined;

  const required = props.required === false ? false : true;

  const emailProps = {
    ...props,
    errorMsg,
    required,
  };

  return <TextInput {...emailProps}></TextInput>;
};
