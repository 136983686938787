import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { DSPaginate, DSPaginationControl } from '../paginate';
import { Flex, FlexContainer, ParagraphFontSize, TextColor } from '../styles';
import { compareObjectsByKey } from '../utils';
import { DataTestId } from '../types/shared';
import { DSSelect } from '../inputs';
import { PaginationControlProps } from '../paginate/DSPaginationControl';

export enum SortDirection {
  Ascending,
  Descending,
}

type SortOptionBase = {
  /** The label used to identify this option in the sort-by select */
  label: string;
  /** Optional sort direction, defaults to Ascending
   * If a sortByKey is provided, this controls sort direction & toggle appearance
   * If a custom sort function is provided, this only impacts the default appearance of the direction toggle
   */
  sortDirection?: SortDirection;
};

type SortOptionCustom<T> = SortOptionBase & {
  /** Custom sort function */
  sort: (item1: T, item2: T) => number;
  sortByKey?: never;
};

type SortOptionByKey<T> = SortOptionBase & {
  sort?: never;
  /** key for use with compareObjectsByKey */
  sortByKey: keyof T & string;
};

export type SortOption<T> = SortOptionCustom<T> | SortOptionByKey<T>;

export type DSSortProps<T> = DataTestId & {
  /** The render function for the sorted list of items */
  children?: (data: T[]) => React.ReactNode | React.ComponentType<{ data: T[] }>;
  /** The list of data to sort */
  data?: T[];
  /** The default sort option (technically does not need to be part of the selectable options)
   * if no default is provided, the first option from `sortOptions` will be used */
  defaultSort?: SortOption<T>;
  /** Optional function to handle onOrder behavior, use with backend search */
  onOrder?: (x: SortDirection) => void;
  /** Optional function to handle onSelect behavior, use with backend search */
  onSelect?: (y: keyof T & string) => void;
  /** Optional override for pagination props */
  pagination?: Omit<PaginationControlProps, 'data' | 'children'>;
  /** if sorted data should be paginated */
  shouldPaginate?: boolean;
  /** Optionally shows a direction toggle to the right of the sort option */
  showDirectionToggle?: boolean;
  /** The selectable options for sorting the data */
  sortOptions?: SortOption<T>[];
};

const ActionIcon = styled.i`
  color: ${TextColor.Action};
  font-size: ${ParagraphFontSize.Large};
  &:hover {
    color: ${TextColor.ActionHover};
  }
`;

export function DSSort<T>({
  children,
  data = [],
  dataTestId,
  defaultSort,
  onOrder,
  onSelect,
  pagination,
  shouldPaginate = false,
  showDirectionToggle = false,
  sortOptions = [],
}: DSSortProps<T>) {
  const firstOption = sortOptions?.length ? sortOptions[0] : undefined;
  const selected = defaultSort || firstOption;
  const [selectedSort, setSelectedSort] = useState(selected);
  const [sortDirection, setSortDirection] = useState(
    selected?.sortDirection || SortDirection.Ascending,
  );

  const toggleSortDirection = () => {
    onOrder && onOrder(sortDirection);
    setSortDirection(
      sortDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending,
    );
  };

  const selectSortOption = (sortOption: SortOption<T>) => {
    onSelect && sortOption.sortByKey && onSelect(sortOption.sortByKey);
    setSelectedSort(sortOption);
    setSortDirection(sortOption?.sortDirection || SortDirection.Ascending);
  };

  const sortedData = useMemo(() => {
    if (!selectedSort || onSelect || onOrder) return data;

    const { sort, sortByKey, sortDirection: selectedSortDirection } = selectedSort;

    let sortFn = sort;

    if (sort && sortDirection !== (selectedSortDirection || SortDirection.Ascending)) {
      sortFn = (item1, item2) => sort(item2, item1);
    } else if (!sort && sortByKey) {
      sortFn = compareObjectsByKey(sortByKey, sortDirection === SortDirection.Ascending);
    }

    return [...data].sort(sortFn);
  }, [data, onOrder, onSelect, selectedSort, sortDirection]);

  return (
    <>
      {/** Only showing the sortBy select if there is more than one option in the list */}
      {sortOptions.length > 1 && (
        <FlexContainer justifyContent='end'>
          <Flex grow={0} style={{ minWidth: '15rem' }}>
            <DSSelect
              label='Sort by'
              dataTestId={`${dataTestId || 'sortBy'}-select`}
              onSelect={selectSortOption}
              options={sortOptions}
              labelField='label'
              valueField={false}
              keyField='label'
              value={selectedSort}
              name='sortBy'
            />
          </Flex>
          <Flex grow={0}>
            {showDirectionToggle &&
              (sortDirection === SortDirection.Descending ? (
                <ActionIcon
                  className='mdi mdi-sort-descending'
                  onClick={toggleSortDirection}
                ></ActionIcon>
              ) : (
                <ActionIcon
                  className='mdi mdi-sort-ascending'
                  onClick={toggleSortDirection}
                ></ActionIcon>
              ))}
          </Flex>
        </FlexContainer>
      )}

      {shouldPaginate ? (
        pagination ? (
          <>
            {children && children(sortedData)}
            <DSPaginationControl
              activePage={pagination.activePage}
              onPageChange={pagination?.onPageChange}
              totalCount={pagination.totalCount | 0}
            />
          </>
        ) : (
          <DSPaginate data={sortedData}>{data => children && children(data)}</DSPaginate>
        )
      ) : (
        children && children(sortedData)
      )}
    </>
  );
}
