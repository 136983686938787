import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import { DeprecatedFullTimeZone, pageSize, sortDirections } from '../../../../utils/constants';
import { displayDateTime, useAssert } from '../../../../utils/helpers';
import { BidResponseLabels } from '../../../../shared/constants/bids';
import { getDefaultNotFoundMessage } from '../../../../utils/message';
import { InfoPanel } from '../../../../components/common/loading';
import { Paging } from '../../../../components/customcontrols';
import { responseEventsParamType } from '../../../../types/biddetail';
import SortingIcon from '../../../../components/common/sortingIcon';

const tdWidth = {
  date: {
    width: '220px',
  },
  author: {
    width: '21%',
  },
  event: {
    width: '24%',
  },
  detail: {
    width: '33%',
  },
};

export interface ResponseHistoryViewProps {
  responseEvents: responseEventsParamType[];
  timeZoneFullName: DeprecatedFullTimeZone;
  responseId: string;
}

// TODO: implement as a react-table with pagination and sorting.

export function ResponseHistoryView(props: ResponseHistoryViewProps) {
  const { responseEvents: bidResponseEvents, timeZoneFullName } = props;

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;
  useAssert(!!timeZoneFullName, 'Timezone should be defined', 'ResponseHistoryView.tsx');
  useEffect(() => {
    setCurrentPage(1);
    setSortOrder(sortDirections.ASC);
  }, []);

  //Logic for sort
  const [sortValue, setSortValue] = useState('dtCreated');
  const [sortOrder, setSortOrder] = useState(sortDirections.ASC);

  return (
    <>
      <Table className='tableFixed'>
        <thead>
          <tr>
            <th style={tdWidth.date}>
              Date
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortOrder}
                currentsortname={sortValue}
                changesortname={(value: string) => setSortValue(value)}
                sortname='dtCreated'
                changeSortingFn={() => null}
              />
            </th>
            <th style={tdWidth.author}>
              Author
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortOrder}
                currentsortname={sortValue}
                changesortname={(value: string) => setSortValue(value)}
                sortname='author'
                changeSortingFn={() => null}
              />
            </th>
            <th style={tdWidth.event}>
              Event
              <SortingIcon
                sortlist={bidResponseEvents}
                initialsortorder={sortOrder}
                currentsortname={sortValue}
                changesortname={(value: string) => setSortValue(value)}
                sortname='responseEventName'
                changeSortingFn={() => null}
              />
            </th>
            <th style={tdWidth.detail}>Detail</th>
          </tr>
        </thead>
        <tbody>
          {bidResponseEvents.length > 0 ? (
            <>
              {bidResponseEvents
                .map((response, index) => (
                  <tr key={index}>
                    <td>
                      {response.dtCreated && displayDateTime(response.dtCreated, timeZoneFullName)}
                    </td>
                    <td>{response.author}</td>
                    <td>{response.responseEventName}</td>
                    <td>{response.description}</td>
                  </tr>
                ))
                .slice(indexOfFirstList, indexOfLastList)}
            </>
          ) : (
            <tr>
              <td colSpan={8}>
                <InfoPanel
                  message={getDefaultNotFoundMessage(`${BidResponseLabels.viewHistory} records`)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Paging
        totalRecords={bidResponseEvents.length}
        currentPage={currentPage}
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
    </>
  );
}
