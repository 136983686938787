import classnames from 'classnames';

import { connect, ConnectedProps } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';

import {
  addUpdateMetaTag,
  clearStorageFn,
  convertExternalLinks,
  deprecatedGetDate,
} from '../../../../utils/helpers';
import { appstatetokenName, BidExternalStatusType, MemberType } from '../../../../utils/constants';
import { Buttons, NoResult } from '../../../customcontrols';
import { documentLabels, limitedPaths } from '../../../../shared/constants';

import * as actionCreators from '../../../../store/actions';
import * as constants from 'src/features/bids/browse/BrowseBids.constants';

import Awardees from '../../../shared/bidsdetail/lefttab/bidinfo/awardees';
import BroadcastFilter from '../../../shared/bidsdetail/lefttab/bidinfo/broadcastfilter';
import CommodityExpand from '../../../common/commodityexpand';
import ExpandCollapse from '../../../common/expandcollapse';
import LimitedBidsDocuments from './document';
import LimitedBidsInfo from './info';
import LimitedBidsPlanholders from './planholders';
import LimitedDistributionInfo from './distribution';
import LimitedlegalAdContent from './legal';
import SigninPopup from '../../signin';

import { limitedbiddetailsTypes } from '../../../../types/limited';
import { LimitedFooter } from '../../footer';
import { LimitedHeader } from '../../header';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { sharedTypes } from '../../../../types/shared';
import { DSLink } from '@demandstar/components/link';
import { useAuthContext } from 'src/auth/AuthProvider';

interface BidsDetailsProps extends PropsFromRedux {
  bidssummary: any;
  getLimitedAgencyAwards: any;
  getLimitedAgencyCommodities: any;
  getLimitedAgencyDocuments: any;
  getLimitedAgencyLegal: any;
  getLimitedAgencyPlanholders: any;
  getLimitedAgencySelfDeclarations: any;
  getLimitedAgencySummary: any;
  history: any;
  limited: limitedbiddetailsTypes;
  match: any;
  setLimitedDetails: any;
  shared: sharedTypes;
}

function BidsDetails(props: BidsDetailsProps) {
  const {
    getLimitedAgencyAwards,
    getLimitedAgencyCommodities,
    getLimitedAgencyDocuments,
    getLimitedAgencyLegal,
    getLimitedAgencySelfDeclarations,
    getLimitedAgencySummary,
    history,
    limited,
    setLimitedDetails,
    shared,
  } = props;
  const { auth } = useAuthContext();
  const bidId = parseInt(props.match.params.bidId) || '';
  const Tab = props.match.params.Tab;
  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'details');
  const {
    bidsawards,
    bidscommodities,
    bidsdetails,
    bidslegal,
    commodities_internalloader,
    selfdeclaredattributes,
    signinmodal,
  } = limited;
  const {
    agencyName = '',
    bidExternalStatus,
    bidExternalStatusType,
    bidName = '',
    filtered = '',
    filteredCities = '',
    filteredDeclarations = '',
    filteredPrograms = '',
    filteredProgramsname = '',
    filteredStates = '',
    pbcMemo = '',
    publications = [],
    scopeOfWork = '',
    showPlanholders = false,
    isExternalBid = false,
    externalId = 0,
    externalAgencyURL = '',
  } = bidsdetails || {};
  const { limitedBidId = '' } = bidslegal || {};
  const [clicked, setClicked] = useState(false);

  // Persisted values from static site scaffolding (Separate Recoil provider is not persisting)
  const seoSearchUrl = localStorage.getItem(constants.browseBidBaseURLStorageKey);
  const seoPageTitleFull = localStorage.getItem(constants.browseBidFullTitleStorageKey);

  useEffect(() => {
    const lStorage = localStorage.getItem(appstatetokenName) || '';
    if (!lStorage) {
      clearStorageFn(false, false);
    } else {
      if (auth && auth?.token && auth?.userName && auth?.memberType && bidId) {
        history.push(
          `/${
            auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
          }/bids/${bidId}/details`,
        );
      }
    }
  }, [auth, bidId, history]);

  useEffect(() => {
    if (bidExternalStatusType && bidExternalStatusType === BidExternalStatusType.Upcoming) {
      history.push('/login');
    }
  }, [bidExternalStatusType, history]);

  useEffect(() => {
    if (bidId) getLimitedAgencySummary({ bidId });
  }, [bidId, getLimitedAgencySummary]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!clicked && limitedBidId !== bidId) {
      getLimitedAgencyLegal({ bidId });
    }
    setClicked(!clicked);
  };

  useEffect(() => {
    document.title = `${bidName} - DemandStar`;
    addUpdateMetaTag('og:title', `DemandStar - ${bidName}`);
    addUpdateMetaTag('og:url', document.location.href);
    addUpdateMetaTag('description', `${agencyName} bid opportunity: ${bidName}`);
    addUpdateMetaTag('keywords', `DemandStar, ${agencyName}, government, bid opportunities`);
  }, [bidName, agencyName]);

  useEffect(() => {
    if (bidId) getLimitedAgencyDocuments({ bidId });
  }, [bidId, getLimitedAgencyDocuments]);

  useEffect(() => {
    if (bidId && bidExternalStatusType === BidExternalStatusType.Awarded)
      getLimitedAgencyAwards({ bidId });
  }, [bidExternalStatusType, bidId, getLimitedAgencyAwards]);

  useEffect(() => {
    if (bidId) getLimitedAgencyCommodities({ bidId, type: 'Bid' });
  }, [bidId, getLimitedAgencyCommodities]);
  useEffect(() => {
    if (auth && auth?.token && auth?.userName && auth?.memberType && bidId) {
      history.push(
        `/${
          auth?.memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
        }/bids/${bidId}/details`,
      );
    }
  }, [auth, history, bidId]);

  useEffect(() => {
    let getSelfDeclarationsAPICALL = true;
    if (filtered === 'Yes' && selfdeclaredattributes.length === 0 && getSelfDeclarationsAPICALL) {
      getLimitedAgencySelfDeclarations();
    }
    return () => {
      getSelfDeclarationsAPICALL = false;
    };
  }, [getLimitedAgencySelfDeclarations, filtered, selfdeclaredattributes.length]);

  const toggle = (tab: string) => {
    if (activeTab !== tab && bidId) {
      history.push(`/limited/bids/${bidId}/${tab}`);
      setActiveTab(tab);
    }
  };

  // Construct an SEO link for 'return' to search results (if the user navigated from the seo page)
  function getSeoSourceLink() {
    const label = seoPageTitleFull || 'Awarded Bids';
    const link = seoSearchUrl || limitedPaths.browseBids;
    return (
      <>
        {'Browse '}
        <DSLink href={link} title={label}>
          {`${label}`}
        </DSLink>
      </>
    );
  }

  return (
    <>
      <LimitedHeader />
      <div className='unAuthWrapper unAuthTabWrapper'>
        <div className='container'>
          <div className='row detailViewWrapper'>
            <div className='col'>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'details' })}
                    onClick={() => {
                      toggle('details');
                    }}
                  >
                    Bid Details
                  </NavLink>
                </NavItem>
                {showPlanholders === true ? (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === 'planholder' })}
                      onClick={() => {
                        toggle('planholder');
                      }}
                    >
                      Planholder
                    </NavLink>
                  </NavItem>
                ) : null}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='details'>
                  <div className='row justify-content-center' id='limitedDetailPage'>
                    <div className='col-lg-12'>
                      <h1
                        className='d-flex align-items-sm-start align-items-md-center'
                        data-testid='limited.bids.details'
                        id='limitedBidTitle'
                      >
                        <label className='flex1 mr-5 mb-0'>
                          <span className='arrowWrapper'>{bidName}</span>
                        </label>
                        <i className={'tag status'}>{bidExternalStatus}</i>
                      </h1>
                      {getSeoSourceLink()}
                      <div className='d-block mt-3'>
                        <Buttons
                          text='Download Bid Package'
                          title='Download Bid Package'
                          classNames='bttn-primary callAction '
                          icon='mdi-download'
                          onClick={() => {
                            setLimitedDetails({
                              signinmodal: true,
                              orderBidPackage: isExternalBid ? false : true,
                            });
                            sessionStorage.removeItem('selectedBidDocId');
                          }}
                        />
                      </div>
                      <h2 className='sectionHeaders'>Bid Details</h2>
                      <div className='innerColIndent'>
                        <LimitedBidsInfo {...props} />
                      </div>
                      {filtered === 'Yes' &&
                      (filteredStates ||
                        filteredCities ||
                        filteredDeclarations ||
                        filteredPrograms ||
                        selfdeclaredattributes) ? (
                        <>
                          <h2 className='sectionHeaders'>Broadcast List Filtered</h2>
                          <div className='innerColIndent'>
                            <BroadcastFilter
                              filteredStates={filteredStates}
                              filteredCities={filteredCities}
                              filteredDeclarations={filteredDeclarations}
                              filteredPrograms={filteredProgramsname}
                              selfdeclaredattributes={selfdeclaredattributes}
                            />
                          </div>
                        </>
                      ) : null}
                      <Awardees
                        awardees={bidsawards}
                        bidExternalStatusType={bidExternalStatusType || BidExternalStatusType.None}
                      />
                      <h2 className='sectionHeaders'>Scope of Work</h2>
                      <div className='innerColIndent'>
                        {scopeOfWork.length > 0 ? (
                          <ExpandCollapse data={scopeOfWork} innerhtml={true} shared={shared} />
                        ) : (
                          <NoResult message='No Scope Of Work Available' />
                        )}
                      </div>
                      <h2 className='sectionHeaders'>Documents</h2>
                      <div className='innerColIndent'>
                        {isExternalBid ? (
                          <>
                            <p>
                              Documents for this bid are hosted on another electronic bidding
                              service. To download documents, please register here:{' '}
                              <a
                                href={convertExternalLinks(externalAgencyURL, 'detail', externalId)}
                              >
                                Click here
                              </a>
                            </p>
                          </>
                        ) : (
                          <>
                            <Buttons
                              text={documentLabels.downloadAllDocuments}
                              classNames='bttn-primary callAction downloadDocs'
                              icon='mdi-download'
                              onClick={() => {
                                setLimitedDetails({
                                  signinmodal: true,
                                  orderBidPackage: isExternalBid ? false : true,
                                });
                                sessionStorage.removeItem('selectedBidDocId');
                              }}
                            />
                            <div className='table-responsive'>
                              <LimitedBidsDocuments {...props} />
                            </div>
                          </>
                        )}
                      </div>
                      <h2 className='sectionHeaders'>Distribution Info</h2>
                      <div className='innerColIndent'>
                        <LimitedDistributionInfo {...props} />
                      </div>
                      <h2 className='sectionHeaders'>Publications</h2>
                      <div className='innerColIndent'>
                        {publications.length > 0 ? (
                          <>
                            {publications.map(
                              (pub: { publication: string; publishDate: string }, id: number) => (
                                <p key={id}>
                                  {pub.publication} {deprecatedGetDate(pub.publishDate)}
                                </p>
                              ),
                            )}
                          </>
                        ) : null}
                        <>
                          <span className='staticLink' title='View Legal Ad' onClick={handleClick}>
                            View Legal Ad
                          </span>
                          <ModalPopUp title={'Legal Ad'} closeModal={handleClick} isOpen={clicked}>
                            <LimitedlegalAdContent {...props} handleClick={handleClick} />
                          </ModalPopUp>
                        </>
                      </div>
                      <h2 className='sectionHeaders'>Pre-Bid Conference </h2>
                      <div className='innerColIndent'>
                        {pbcMemo ? (
                          pbcMemo
                            .split('\n')
                            .map((memo: string, id: number) => <p key={id}>{memo}</p>)
                        ) : (
                          <NoResult message='No Pre-Bid Conference Available' />
                        )}
                      </div>
                      <h2 className='sectionHeaders'>Commodity Codes</h2>
                      <div className='innerColIndent clearfix'>
                        {bidscommodities.length > 0 ? (
                          <CommodityExpand commodities={bidscommodities} shared={shared} />
                        ) : (
                          <tr>
                            <td colSpan={9}>
                              <NoResult
                                message={
                                  commodities_internalloader
                                    ? loadingMsg
                                    : 'Select Commodity Codes to receive bid notifications.'
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
                {showPlanholders === true ? (
                  <TabPane tabId='planholder'>
                    {activeTab === 'planholder' ? (
                      <div className='row justify-content-center'>
                        <div className='col-lg-12'>
                          <h3 className='arrowWrapper'>Planholder</h3>
                          {seoPageTitleFull}
                          <div className='innerColIndent clearfix'>
                            <LimitedBidsPlanholders {...props} bidId={bidId} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </TabPane>
                ) : null}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
      {signinmodal ? <SigninPopup size='md' {...props} /> : null}
    </>
  );
}

const connector = connect(
  (state: any) => ({
    limited: state.limited,
    shared: state.shared,
    bidssummary: state.bidssummary,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BidsDetails);
