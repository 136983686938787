import React, { memo } from 'react';
import { loadingMsg, noresultFound } from '../../../../utils/texts';
import {
  deprecatedGetDateTimeZoneConverted,
  formatPrice,
  formattedPhoneNumber,
} from '../../../../utils/helpers';
import { Table } from 'reactstrap';
import { Buttons, NoResult } from '../../../customcontrols/index';

import { limitedbiddetailsTypes } from '../../../../types/limited';
const tdWidth = {
  width: '100px',
};

interface propsTypes {
  bidssummary: any;
  handleClick: any;
  limited: limitedbiddetailsTypes;
}

const LimitedlegalAdContent = (props: propsTypes) => {
  const { bidssummary, handleClick, limited } = props;
  const { bidslegal, legalAddLoading, bidsdetails } = limited;
  const { results = {} } = bidssummary;
  const { writerInfo = {}, memberInfo = {} } = bidssummary.writerInfo;

  const {
    agencyName = '',
    documentHandlerType = '',
    fulfillmentInstructions = '',
    shownAmount = '',
    legalAdCostInfo = '',
    name = '',
    displayDocCosts = '',
    displayBudgetToSuppliers = '',
    projectEstimatedBudget = '',
    memberBidTypeDesc = '',
    bidName = '',
    bidBond = '',
    memberBidType = '',
    bidNumber = '',
    bidSequenceNumber = '',
    bidYear = '',
    initials = '',
    pbcMemo = '',
    dueDateTime = '',
    legalAdAdditional = '',
    legalAdClosing = '',
    legalAdIntro = '',
    legalAdDueDateTime = '',
    timeZoneShortName = '',
    firstName = '',
    lastName = '',
    // memberPhoneNumber = '',
    phoneNumber = '',
    scopeOfWork = '',
    phoneExtension = '',
    publications = [],
  } = bidslegal;

  const { tzfn } = results;

  const { isExternalBid, contactInfoVisibility, contactInfo, contactName } = bidsdetails || {};

  if (agencyName) {
    const orderingInstructions =
      documentHandlerType === 'AG' || documentHandlerType === 'TP' ? (
        <>
          <h4 className='lineHR'>Ordering Instructions </h4>
          <div className='innerColIndent'>
            <p>{fulfillmentInstructions}</p>
          </div>
          <h4 className='lineHR'>Document Cost</h4>
          <div className='innerColIndent'>
            {' '}
            <p>{documentHandlerType === 'TP' && shownAmount > 0 ? shownAmount : null}</p>
            <p>{legalAdCostInfo}</p>
          </div>
        </>
      ) : (
        <>
          <h4 className='lineHR'>Ordering Instructions</h4>
          <div className='innerColIndent'>
            <p>
              This package can be downloaded from {name} on our website at
              http://www.demandstar.com.
            </p>
          </div>
          {displayDocCosts ? (
            <>
              {' '}
              <h4 className='lineHR'>Document Cost</h4>
              <div className='innerColIndent'>
                <p>{legalAdCostInfo}</p>
              </div>
            </>
          ) : null}
        </>
      );

    const estimatedBudget =
      displayBudgetToSuppliers && projectEstimatedBudget > 0 ? (
        <>
          <h4 className='lineHR'>Project Estimated Budget</h4>
          <div className='innerColIndent'>
            <p>{projectEstimatedBudget ? `${formatPrice(projectEstimatedBudget)}` : ''}</p>
          </div>
        </>
      ) : null;

    const preBidProposal = (
      <>
        {pbcMemo ? (
          <>
            <h4 className='lineHR'>Pre/Bid Proposal Conference</h4>
            <div className='innerColIndent'>
              <p>{pbcMemo}</p>
            </div>
          </>
        ) : null}
      </>
    );

    const dueDate = (
      <>
        <h4 className='lineHR'>Due Date/Time</h4>{' '}
        <div className='innerColIndent'>
          <p>
            {dueDateTime ? (
              <>
                {deprecatedGetDateTimeZoneConverted(dueDateTime, tzfn)} {timeZoneShortName}
              </>
            ) : (
              dueDateTime
            )}
          </p>
        </div>
      </>
    );
    const bidWriter = firstName ? (
      <>
        <tr>
          <th style={tdWidth}>Contact</th>
          <td>
            {firstName} {lastName}, {writerInfo.jt}
          </td>
        </tr>
        <tr>
          <th style={tdWidth}>Phone</th>
          <td>{phoneNumber && formattedPhoneNumber(phoneNumber, phoneExtension)}</td>
        </tr>
      </>
    ) : null;

    const bonfireContactInfo =
      contactInfoVisibility === 1 ? (
        <>
          <tr>
            <th style={tdWidth}>Contact</th>
            <td>{contactName}</td>
          </tr>
          <tr>
            <th style={tdWidth}>Email</th>
            <td>{contactInfo}</td>
          </tr>
        </>
      ) : null;

    const bidBondComponent = bidBond ? (
      <>
        <h4 className='lineHR'>Bid Bond</h4>
        <div className='innerColIndent'>
          <p> {bidBond} </p>
        </div>
      </>
    ) : null;

    const additionalNotes =
      legalAdAdditional || legalAdClosing || (isExternalBid && contactInfoVisibility === 1) ? (
        <>
          <h4 className='lineHR'>Additional Notes</h4>
          {legalAdAdditional || legalAdClosing ? (
            <div className='innerColIndent'>
              <p>{legalAdAdditional}</p>
              <p>{legalAdClosing}</p>
            </div>
          ) : null}
        </>
      ) : null;

    return (
      <div className='legalAdsWrapper'>
        <header>
          <h5>{agencyName}</h5>
          {memberInfo.a1 ? (
            <p>
              {`${memberInfo.a1 ? memberInfo.a1 : ''} ${memberInfo.a2 ? ', ' : ''} ${
                memberInfo.a2 ? memberInfo.a2 : ''
              }`}
              <br />
              {`${memberInfo.ct ? memberInfo.ct : ''}
							${memberInfo.st ? ', ' : ''}
							${memberInfo.st ? memberInfo.st : ''}
							${memberInfo.pc ? ', ' : ''}
							${memberInfo.pc ? memberInfo.pc : ''}`}
            </p>
          ) : null}
          <h6>{memberBidTypeDesc ? memberBidTypeDesc : null}</h6>
          <h6>{bidName}</h6>
          <h6>
            {memberBidType +
              '-' +
              bidNumber +
              '-' +
              bidSequenceNumber +
              '-' +
              bidYear +
              '/' +
              initials}
          </h6>
          <p className='mb-0'>{legalAdIntro ? legalAdIntro : null}</p>
        </header>
        <main>
          {scopeOfWork ? (
            <>
              <h4 className='lineHR'>Scope of Work</h4>
              <div className='innerColIndent'>
                <>
                  <div dangerouslySetInnerHTML={{ __html: scopeOfWork }} />
                </>
              </div>
            </>
          ) : null}
          {orderingInstructions}
          {bidBondComponent}
          {estimatedBudget}
          {preBidProposal}
          {dueDate}
          <div className='innerColIndent'>
            <p>{agencyName}</p>
            <p>{legalAdDueDateTime}</p>
          </div>
          {additionalNotes}
        </main>
        <footer>
          <Table borderless className='tableData'>
            {!isExternalBid ? bidWriter : bonfireContactInfo}
            {publications && publications.length > 0 ? (
              <tr>
                <th>Publications</th>
                <td>
                  {publications.map((publication: { publication: string }, i: number) => (
                    <span key={i}>
                      {publication.publication}
                      {', '}
                    </span>
                  ))}
                </td>
              </tr>
            ) : null}
          </Table>
        </footer>
        <Buttons classNames='bttn-primary float-right mt-3' text='Close' onClick={handleClick} />
      </div>
    );
  } else {
    return <NoResult message={legalAddLoading === true ? loadingMsg : noresultFound} />;
  }
};

export default memo(LimitedlegalAdContent);
