import React, { memo } from 'react';
import { addbidParamType } from '../../../../types/addbid';
import { Buttons } from '../../../customcontrols';

interface propType {
  addbid: addbidParamType;
  setAddBidDetails?: any;
  reBroadcastBidToSuppliers?: any;
  match?: any;
}

const BroadcastBtns = (props: propType) => {
  const { addbid, setAddBidDetails, reBroadcastBidToSuppliers } = props;
  const {
    broadcastListssearch,
    currentBroadcastPage = 'broadcastlist',
    broadcastSupplimentalSupplier = [],
  } = addbid;
  const newSuppliersCount = broadcastListssearch.filter(item => item.broadcasted === false).length;
  const bidId = parseInt(props.match.params.bidId);
  return (
    <>
      {currentBroadcastPage === 'broadcastlist' ? (
        <div className='d-flex justify-content-between mt-5'>
          <Buttons
            classNames='bttn-secondary float-left'
            text={'Cancel'}
            title={'Cancel'}
            onClick={() => {
              setAddBidDetails({ additionalBroadcastModal: false });
            }}
            type='button'
          />
          <Buttons
            classNames='bttn-primary float-right'
            text={'Broadcast Now'}
            title={'Broadcast Now'}
            onClick={() => reBroadcastBidToSuppliers({ id: bidId })}
            type='button'
            disable={newSuppliersCount || broadcastSupplimentalSupplier.length ? false : true}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-between mt-5'>
          <Buttons
            classNames='bttn-secondary float-left'
            text={'Back to Commodity Code'}
            title={'Back to Commodity Code'}
            onClick={() => {
              setAddBidDetails({ currentBroadcastPage: 'broadcastlist' });
            }}
            type='button'
          />
          <Buttons
            classNames='bttn-primary float-right'
            text={'Broadcast To New Suppliers Now'}
            title={'Broadcast To New Suppliers Now'}
            onClick={() => reBroadcastBidToSuppliers({ id: bidId })}
            type='button'
            disable={newSuppliersCount || broadcastSupplimentalSupplier.length ? false : true}
          />
        </div>
      )}
    </>
  );
};

export default memo(BroadcastBtns);
