import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { CheckBox } from '../customcontrols';
import { formatPrice, sumObjectFields } from '../../utils/helpers';
import { quoteBreakdownStatus } from '../../utils/constants';
import { DeprecatedRadio } from '../customcontrols/index';
import { LineItemType, QuoteSummaryType } from '../../types/quotedetailstypedef';

const divStyle = {
  td1: { width: '35px' },
  td2: { width: '330px' },
  td3: { width: '240' },
};

interface PropsTypes {
  setQuoteSummaryDetails?: any;
  pagefor?: string;
  type?: string | number;
  QuoueSummaryGetCurrentSupplierinfo?: any;
  getSelfDeclarations?: any;
  getAddBidActivePrograms?: any;
  quoteSummary?: QuoteSummaryType;
  breakDownlist?: LineItemType[];
  userpagefor?: string;
  setSelectedLineItem?: any;
}

const BreakdownList = (props: PropsTypes) => {
  const {
    setQuoteSummaryDetails,
    pagefor,
    type = 1,
    QuoueSummaryGetCurrentSupplierinfo,
    getSelfDeclarations,
    getAddBidActivePrograms,
    quoteSummary,
    breakDownlist = [],
    userpagefor,
    setSelectedLineItem,
  } = props;

  const { suppliers = [], selectedResponseItem = '', lineItems = [] } = quoteSummary || {};

  const SelectSuppliers = (value: any, index: number, item: any, purposefor: any) => {
    if (pagefor === 'suppliers') {
      const newbreakDownlist = breakDownlist.map((item, indexs) => {
        if (indexs === index) {
          item.selected = value;
        }
        return item;
      });
      if (value) setSelectedLineItem([]);
      setQuoteSummaryDetails({
        selectedbreakdownlist: newbreakDownlist.filter(item => item.selected),
        breakDownlist: newbreakDownlist,
      });
    }
  };

  const setCurrentSupplierFn = (item: any) => {
    if (item) {
      if (quoteBreakdownStatus.includes(item.responseType)) {
        QuoueSummaryGetCurrentSupplierinfo(item);
      }
      if (item.memberId) {
        const value = { isCurrentMember: false, memberId: item.memberId };
        getSelfDeclarations(value);
        getAddBidActivePrograms(value);
      }

      suppliers.map(sItem => {
        if (sItem.responseId === item.responseId) {
          item.price = sItem.price;
          item.responseAbbr = sItem.responseAbbr;
        }
        return item;
      });

      setQuoteSummaryDetails({ currentSupplier: item, lineitemsdetailsmodal: true });
    } else {
      setQuoteSummaryDetails({ lineitemsdetailsmodal: false });
    }
  };

  const SelectSuppliersRadioFn = (item: any) => {
    const newitem = lineItems
      .filter(list => list.quoteItemId === item.quoteItemId)
      .map(items => ({ ...items, ...item, selected: true }));

    setSelectedLineItem(newitem);
    setQuoteSummaryDetails({
      selectedbreakdownlist: newitem,
      selectedResponseItem: item,
    });
  };

  return (
    <>
      <Table className='tableHData'>
        <tr className='vTop'>
          <th style={divStyle.td1}>#</th>
          <th style={divStyle.td2}>{pagefor === 'suppliers' ? 'Description' : 'Suppliers'}</th>
          <th>Quantity</th>
          <th className='text-right'>
            Price <small>(Per UOM)</small>
          </th>
          <th className='text-right'>Subtotals</th>
          {type === 1 && <th style={divStyle.td3}>Award Status</th>}
        </tr>
        {breakDownlist.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            {pagefor === 'suppliers' ? (
              <td>
                {item.description}
                {item.altDescription && (
                  <>
                    <br />
                    <br /> <strong>Supplier quoted alternate : </strong> {item.altDescription}
                  </>
                )}
              </td>
            ) : (
              <td>
                <span className='staticLink' onClick={() => setCurrentSupplierFn(item)}>
                  {item.supplierName}
                </span>
              </td>
            )}

            <td>
              {item.quantity} {item.uom}
            </td>
            <td className='text-right'>{item.unitPrice && `$${formatPrice(item.unitPrice, 3)}`}</td>
            <td className='text-right'>{item.subTotal && `$${formatPrice(item.subTotal, 2)}`}</td>
            {type === 1 && (
              <td>
                {item.awarded ? <i className='tag status ml-0'>Awarded</i> : null}
                {item.awardedToOther ? 'Awarded to Another Supplier' : null}
                {userpagefor === 'suppliers' ? null : (
                  <>
                    {item.showAwardButton ? (
                      pagefor === 'suppliers' ? (
                        <CheckBox
                          handleChecked={(name: string, value: any) =>
                            SelectSuppliers(value, index, item, 'select')
                          }
                          checked={item.selected}
                        />
                      ) : (
                        <DeprecatedRadio
                          handleSelect={() => SelectSuppliersRadioFn(item)}
                          defaultChecked={
                            item.responseItemId === selectedResponseItem.responseItemId
                          }
                        />
                      )
                    ) : null}
                  </>
                )}
              </td>
            )}
          </tr>
        ))}
      </Table>
      {pagefor === 'suppliers' ? (
        <Table className='tableHData'>
          <tr>
            <td width='465'>Subtotals will be rounded up to the nearest cent</td>
            <td></td>
            <td>
              <b>Total</b>
            </td>
            <td className='text-right'>
              <b>${formatPrice(sumObjectFields(breakDownlist, 'subTotal'))}</b>
            </td>
            {/* <td width='88' className='d-none d-md-block d-lg-none' />
										<td width='96' className='d-none d-lg-block d-xl-none' /> */}
            <td width='240' />
          </tr>
        </Table>
      ) : null}
    </>
  );
};

export default memo(BreakdownList);
