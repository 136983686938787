import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import dayjs from 'dayjs';

import { ContractDocument, ContractDocumentStatus } from '../../contract-management.d';

import * as documentServices from '../../../../service/documents';
import * as services from './ContractDocuments.services';

import { contractDetailsState } from '../../useContractDetails';
import { ContractDocumentType } from '../../contract-management.d';
import { getAllContracts } from '../../ContractManagement.services';
import { tryCatchLog } from 'src/utils/errors';

const contractDocumentTypesSelector = selector<ContractDocumentType[]>({
  key: 'contractDocumentTypesSelector',
  get: async () => {
    return await tryCatchLog(async () => {
      const response = await services.getContractDocumentTypes();
      return response || [];
    }, 'getDocumentTypes() => contractDocumentTypesSelector');
  },
});

export const contractDocumentTypesState = atom<ContractDocumentType[]>({
  key: 'contractDocumentTypesState',
  default: contractDocumentTypesSelector,
});

export const contractDocumentsQuerySelector = selectorFamily<
  ContractDocument[] | undefined,
  string | undefined
>({
  key: 'contractDocumentsQuerySelector',
  get:
    contractId =>
    async ({ get }) => {
      const docTypes = get(contractDocumentTypesState);
      const contractDetails = get(contractDetailsState(contractId));
      const contractDocuments = contractDetails?.documents?.filter(id => !!id);

      if (!contractDocuments) return [];

      const documentIds = contractDocuments?.map(d => d.documentId || '');

      if (!documentIds.length) return [];

      const documents = await tryCatchLog(async () => {
        return await documentServices.getDocumentList(documentIds);
      }, `contractDocumentsQuerySelector(${contractId}) => getDocumentList`);

      return contractDocuments.map(cd => {
        const document = documents.find(d => d.id === cd.documentId);
        const awardee =
          cd.awardee || contractDetails?.awardeeContacts?.find(ac => ac.id === cd.awardeeContactId);
        const type = cd.documentType || docTypes.find(dt => dt.id === cd.documentTypeId);
        return {
          id: cd.documentId || '',
          contractId: contractDetails?.id || '',
          contract: contractDetails?.name || '',
          document: document,
          dtCreated: dayjs(document?.dtCreated),
          dtUpdated: dayjs(document?.dtUpdated),
          public: cd.isPublic,
          source: awardee?.name || '',
          status: ContractDocumentStatus.Uploaded, // TODO: figure out document requests
          title: document?.title || document?.name,
          type: type?.title || 'Other',
        };
      });
    },
});

/**
 * @param {string} contractId
 */
export const contractDocumentsState = atomFamily<
  ContractDocument[] | undefined,
  string | undefined // contractId
>({
  key: 'contractDocumentsState',
  default: contractDocumentsQuerySelector,
});

// This is a bit hefty, so I want to initialize/refresh it more intentionally
export const allContractDocumentsQuery = selector<ContractDocument[]>({
  key: 'allContractDocumentsQuery',
  get: async () => {
    const docTypes = await tryCatchLog(async () => {
      const response = await services.getContractDocumentTypes();
      return response || [];
    }, 'getDocumentTypes() => allContractDocumentsQuery');

    const allContracts = await tryCatchLog(async () => {
      const contracts = await getAllContracts();
      return contracts.map(c => ({
        ...c,
        awardeeContacts: c.awardeeContacts ?? [],
        startDate: dayjs(c.startDate),
        endDate: dayjs(c.endDate),
      }));
    }, 'allContractsSelector => getAllContracts');

    const allDocMetaData = allContracts.flatMap(contract => {
      return (
        contract.documents?.map(doc => {
          const awardee =
            doc.awardee || contract?.awardeeContacts?.find(ac => ac.id === doc.awardeeContactId);
          const type = doc.documentType || docTypes.find(dt => dt.id === doc.documentTypeId);
          return {
            id: doc.documentId,
            contractId: contract.id,
            contract: contract.name,
            public: doc.isPublic,
            source: awardee?.name || '',
            status: ContractDocumentStatus.Uploaded,
            type: type?.title || 'Other',
          };
        }) || []
      );
    });
    const allDocIds = allDocMetaData.map(doc => doc.id);

    if (!allDocIds.length) return [];

    const allDocuments = await tryCatchLog(async () => {
      return await documentServices.getDocumentList(allDocIds);
    }, 'refreshAllContractDocuments => getDocumentList');

    const mergedDocs = allDocMetaData.map(doc => {
      const document = allDocuments.find(d => d.id === doc.id);
      return {
        ...doc,
        document: document,
        dtCreated: dayjs(document?.dtCreated),
        dtUpdated: dayjs(document?.dtUpdated),
        title: document?.title || document?.name,
      };
    });

    return mergedDocs;
  },
});

// This is a bit hefty, so I want to initialize/refresh it more intentionally
export const allContractDocumentsState = atom<ContractDocument[]>({
  key: 'allContractDocumentsState',
  default: allContractDocumentsQuery,
});

/** Track the selected item for edit/delete. */
export const selectedContractDocumentState = atom<ContractDocument | null>({
  key: 'selectedContractDocumentState',
  default: null,
});
