import { ReactNode } from 'react';
import styled from 'styled-components';

import { DSButton, DSButtonGroup } from '@demandstar/components/button';
import { Margin, P } from '@demandstar/components/styles';

import { ModalPopUp, ModalPopUpProps } from './ModalPopUp';

/** This interface is to enforce that if onlyConfirm is true, we do not allow
 * cancelAction, cancelText, or size to passed into the component.
 *
 * cancelAction and cancelText would be useless because we do not display a cancel button.
 *
 * size we want to be consistently 'md' sitewide for OK dialog boxes.
 */

/**
 * @example Valid props:
 *  <ConfirmModal
 *    onlyConfirm={true}
 *    onConfirm={approve}
 *    closeModal={toggleClose}
 *    title={'Confirm'}
 *    isOpen={modalOpen}
 *  >
 *    Do you confirm?
 *  </ConfirmModal>
 */

/**
 * @example Invalid props:
 *  <ConfirmModal
 *    onlyConfirm={true}
 *    onConfirm={approve}
 *    cancelText={'Go Back'} // This text would not be seen.
 *    closeModal={toggleClose}
 *    title={'Confirm'}
 *    isOpen={modalOpen}
 *    size={'xxl'} // This is too big for a single button dialog box.
 *  >
 *    Do you confirm?
 *  </ConfirmModal>
 *  />
 */

/**
 * @param {function}          onConfirm - onClick for the approve button.
 * @param {string='Confirm'}  confirmText - optional button text.
 *                            Will be 'OK' if 'onlyConfirm' is true.
 * @param {function=}         cancelAction - onClick for the cancel button.
 *                            If not passed in, we use closeModal instead.
 * @param {string='Cancel'}   cancelText - optional button text.
 * @param {function}          closeModal - function that closes the modal.
 * @param {ReactNode}         children - the message in the dialog box. Can be JSX.Element or string.
 * @param {boolean}           isOpen - whether or not the modal is open.
 * @param {boolean=}          onlyConfirm - if true, we do not use the cancel button.
 * @param {ModalSize=}        size - the size of the modal.
 *                            will be 'lg' if not passed in, or 'md' for 'onlyConfirm' dialog
 * @param {string=}           subText - optional additional text.
 * @param {string}            title - title text of the modal.
 * */

type BaseConfirmModalProps = ConfirmDialogProps &
  Omit<ModalPopUpProps, 'className' | 'backdrop' | 'children'>;
interface CancelableConfirmModalProps extends BaseConfirmModalProps {
  onlyConfirm?: false;
}
interface NonCancelableModalProps extends BaseConfirmModalProps {
  onlyConfirm: true;
  // if onlyConfirm is true, we should do the following:
  cancelAction?: never;
  cancelText?: never;
  size?: never;
}

export type ConfirmModalProps = CancelableConfirmModalProps | NonCancelableModalProps;

const ConfirmMessage = styled.div`
  margin-bottom: ${Margin.Base};
`;

export function ConfirmModal(props: ConfirmModalProps) {
  const {
    cancelAction,
    cancelText,
    children,
    closeModal,
    confirmText,
    dataTestId,
    danger,
    isOpen,
    onConfirm,
    onlyConfirm,
    subText,
    title,
  } = props;

  const size = props.onlyConfirm ? 'md' : props.size || 'md';

  return (
    <ModalPopUp title={title} size={size} isOpen={isOpen} closeModal={closeModal}>
      <ConfirmDialog
        cancelAction={cancelAction}
        cancelText={cancelText}
        closeModal={closeModal}
        confirmText={confirmText}
        danger={danger}
        dataTestId={dataTestId}
        onConfirm={onConfirm}
        onlyConfirm={onlyConfirm}
        subText={subText}
      >
        {children}
      </ConfirmDialog>
    </ModalPopUp>
  );
}

export interface ConfirmDialogProps {
  cancelAction?: () => void;
  cancelText?: string;
  children: ReactNode;
  closeModal: () => void;
  confirmText?: string;
  /** danger or danger='delete' will turn the button red. 'delete' changes default `confirmText` to "Delete"  */
  danger?: true | 'delete';
  dataTestId?: string;
  onConfirm: () => void;
  onlyConfirm?: boolean;
  subText?: string;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    cancelAction,
    cancelText = 'Cancel',
    children,
    closeModal,
    danger,
    dataTestId,
    onConfirm,
    onlyConfirm,
    subText,
  } = props;

  const confirmText =
    props.confirmText || (danger === 'delete' ? 'Delete' : onlyConfirm ? 'OK' : 'Confirm');

  function testId(str: string) {
    return `${dataTestId || 'confirm-dialog.modal'}.${str}`;
  }

  function cancel() {
    if (cancelAction) {
      cancelAction();
    } else {
      closeModal();
    }
  }

  return (
    <>
      <ConfirmMessage data-testid={testId('head')}>{children}</ConfirmMessage>
      {subText ? <P data-testid={testId('text')}>{subText}</P> : ''}
      <DSButtonGroup>
        {!onlyConfirm && (
          <DSButton
            type={'secondary'}
            title={cancelText}
            onClick={cancel}
            dataTestId={testId('cancel')}
          >
            {cancelText}
          </DSButton>
        )}
        <DSButton
          title={confirmText}
          onClick={onConfirm}
          dataTestId={testId('approve')}
          type={danger ? 'danger' : 'primary'}
        >
          {confirmText}
        </DSButton>
      </DSButtonGroup>
    </>
  );
}
