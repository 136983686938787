import { sortDirections } from '../../utils/constants';
import * as actionTypes from '../actionTypes';
import { payloadTypes } from '../../types/actiontypedef';
import { bidsStateTypes } from '../../types/bids';

export const defaultFilter = {
  showBids: '',
  filterOrdered: false,
  location: '',
  locationText: '',
  locationType: '',
  radius: '',
  industry: [],
  industryText: '',
  states: [],
  stateText: '',
  bidStatus: '',
  bidIdentifier: '',
  fiscalYear: '',
  bidName: '',
  agencyMemberId: '',
  agencyText: '',
  dueDateTime: '',
  startDueDate: '',
  endDueDate: '',
  additionalShowBids: [],
  myBids: false,
  IncludeExternalBids: true,
  bidsNotified: false,
  orderedBids: false,
  watchedBids: false,
  commodityMatches: false,
  ebiddingAvailable: false,
};

const sortingDefaultFilter = {
  sortBy: 'broadCastDate',
  sortOrder: sortDirections.DESC,
};

export const initialBidsState: bidsStateTypes = {
  filters: { ...defaultFilter },
  labelFilters: { ...defaultFilter },
  currentFilter: { ...defaultFilter },
  sortingDefaultFilter: sortingDefaultFilter,
  upcomingBidIds: '',
  incompletebidid: '',
  results: [],
  total: 0,
  currentPage: 1,
  initialRequest: true,
  bidscurrentPage: 1,
  counterLoaded: true,
  incompleteconditionslist: [],
  industry: '',
  showBids: '',
  selectedBidIds: '',
  loadedBids: [],
  noCommodityCodesBidsResults: false,
  isBidsLoaded: false,
  completedOffsets: [],
};

export const reducer = (state = initialBidsState, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.LOAD_BID_RESULT.SUCCESS:
      state = {
        ...state,
        results: action.payload.result,
        total: action.payload.total,
        sortingDefaultFilter: {
          sortBy: action.payload.parameters.sortBy,
          sortOrder: action.payload.parameters.sortOrder,
        },
        initialRequest: action.payload.parameters.initialRequest,
        currentPage: 1,
        isBidsLoaded: true,
        completedOffsets: [1],
      };
      break;
    case actionTypes.LOAD_NO_LOADER_BID_RESULT.SUCCESS:
      state = {
        ...state,
        results: [...state.results, ...action.payload.result],
        completedOffsets: [...state.completedOffsets, action.payload.currentOffset],
      };
      break;
    case actionTypes.BID_LIST_LOAD_FILTERS.ACTION:
      state = {
        ...state,
        filters: {
          ...defaultFilter,
          industry: [...action.payload.userSelectedIndustries],
        },
        currentFilter: {
          ...defaultFilter,
          industry: [...action.payload.userSelectedIndustries],
        },
      };
      break;
    case actionTypes.LOAD_BID_RESULT.FAILURE:
      state = { ...state, results: [], total: 0 };
      break;
    case actionTypes.BID_LIST_CLEAR_FILTER.ACTION:
      state = {
        ...state,
        filters: {
          ...defaultFilter,
          industry: [...action.payload.userSelectedIndustries],
        },
        currentFilter: {
          ...defaultFilter,
          industry: [...action.payload.userSelectedIndustries],
        },
      };
      break;
    case actionTypes.BID_LIST_SET_FILTER.ACTION:
      state = {
        ...state,
        currentFilter: {
          ...state.filters,
        },
      };
      break;
    case actionTypes.BID_LIST_FILTER_CHANGE.ACTION:
      state = {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.payload,
        },
      };
      break;
    case actionTypes.BID_WATCH.SUCCESS:
      state = {
        ...state,
        results: [...action.payload],
      };
      break;
    case actionTypes.SET_BID_DETAILS.TRIGGER:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case actionTypes.RESET_BIDS.TRIGGER:
      state = initialBidsState;
      break;
    case actionTypes.GET_BIDS_CONDITION_SPENDING.SUCCESS:
      state = { ...state, incompleteconditionslist: action.payload };
      break;
    default:
      break;
  }
  return state;
};
