import { ChangeEvent, FocusEvent, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';

import { AllowedTextInputType } from '../inputs';
import { BaseTextInput } from './DSTextInputStyles';
import { Inactive } from '../../types';

type DSTextInputProps = FieldProps &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'autoFocus' | 'placeholder' | 'onFocus' | 'maxLength' | 'name'
  > &
  Inactive & {
    /** value required for controlled input */
    value: string;
    /** optional isNumeric */
    isNumeric?: boolean;
    /** optional notApplicable boolean */
    notApplicable?: boolean;
    /** onBlur which takes the string directly */
    onBlur?: (value: string) => void;
    /** onChange which takes the string directly */
    onChange?: (value: string) => void;
    /** function which adds N/A button and controls what happens onClick */
    onNAClick?: () => void;
    /** paired down HTML type */
    type?: AllowedTextInputType;
  };

/** Text Input for use without React Hook Form
 * @example <DSTextInput
              name='docTitle'
              value={doc.docTitle}
              onChange={(value: string) => updatePendingDocTitle(doc.id, value)}
              maxLength={100}
            ></DSTextInput>
 * */
export const DSTextInput = forwardRef(
  (props: DSTextInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      autoFocus,
      dataTestId,
      inactive,
      isNumeric,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      type = 'text',
      value,
    } = props;

    function change(event: ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;
        onChange(value.trimStart());
      }
    }

    function blur(event: FocusEvent<HTMLInputElement>): void {
      if (onBlur) {
        const value = event.target.value;
        onBlur(value.trim());
      }
    }

    return (
      <FieldContainer {...getFieldContainerProps(props)}>
        <BaseTextInput
          readOnly={inactive}
          autoFocus={autoFocus}
          data-testid={dataTestId}
          id={name}
          maxLength={maxLength}
          name={name}
          onBlur={blur}
          onChange={change}
          onFocus={onFocus}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
          isNumeric={isNumeric || type === 'number'}
        />
      </FieldContainer>
    );
  },
);
