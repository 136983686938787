import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import {
  ActivityRequest,
  getActivitiesNotificationsMemberTypes,
  getActivitiesNotificationsTypes,
  getActivitiesPlanholdersTypes,
  getActivitiesQuotesTypes,
  getActivitiesSearchTypes,
  getActivitiesUsersTypes,
  getActivitiesWatchsTypes,
  getBidActivityReportTypes,
  GetEBidderActivitiesParameters,
  getQuoteActivityReportTypes,
} from '../../types';

// const activitiestID = '8ba595d1-d8a5-4645-a65e-cb57c0d5af55'

function* watchgetActivitiesNotifications() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_NOTIFICATIONS.TRIGGER, getActivitiesNotifications);
}

function* getActivitiesNotifications(action: getActivitiesNotificationsTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, notificationStatus } = action.payload;
    const finalvalue: any = { page, size, type: 'notification' };
    if (notificationStatus?.value) {
      finalvalue.status = notificationStatus?.value;
    }
    const response = yield call(Api.getActivitiesAll, finalvalue);
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_notifications } = activities;
        const { /*page,*/ result /*, size, total*/ } = activities_notifications;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.accounts = response.data.result.filter(
          (item: { type: string }) => item.type === 'Account',
        );
        // response.data.result = response.data.result.filter(item => item.type !== 'Account')
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetActivitiesNotificationsMember() {
  yield takeLatest(
    actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.TRIGGER,
    getActivitiesNotificationsMember,
  );
}

function* getActivitiesNotificationsMember(action: getActivitiesNotificationsMemberTypes): any {
  yield put({
    type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.REQUEST,
    meta: action.payload,
  });
  try {
    const { page, size, pagination } = action.payload;
    const response = yield call(Api.getActivitiesAllMember, { page, size, type: 'notification' });
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_notifications_member } = activities;
        const { /*page,*/ result /*, size, total*/ } = activities_notifications_member;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({
        type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_NOTIFICATIONS_MEMBER.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetActivitiesWatchs() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_WATCHS.TRIGGER, getActivitiesWatchs);
}

function* getActivitiesWatchs(action: getActivitiesWatchsTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_WATCHS.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, bitWatchStatus } = action.payload;
    const finalvalue: any = { page, size };
    if (bitWatchStatus?.value) finalvalue.status = bitWatchStatus?.value;

    const response = yield call(Api.getActivitiesWatchs, finalvalue);

    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_watchs } = activities;
        const { /*page, */ result /*, size, total*/ } = activities_watchs;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_WATCHS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_WATCHS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_WATCHS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetActivitiesPlanholders() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_PLANHOLDERS.TRIGGER, getActivitiesPlanholders);
}

function* getActivitiesPlanholders(action: getActivitiesPlanholdersTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_PLANHOLDERS.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, planholderStatus } = action.payload;
    const finalvalue: any = { page, size, type: 'planholder' };
    if (planholderStatus?.value) finalvalue.status = planholderStatus?.value;

    const response = yield call(Api.getActivitiesAll, finalvalue);
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_planholders } = activities;
        const { /*page,*/ result /*, size, total*/ } = activities_planholders;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_PLANHOLDERS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_PLANHOLDERS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_PLANHOLDERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetActivitiesUsers() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_USERS.TRIGGER, getActivitiesUsers);
}

function* getActivitiesUsers(action: getActivitiesUsersTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_USERS.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, userStatus } = action.payload;
    const request: ActivityRequest = { page, size, type: 'user' };
    if (userStatus?.value) request.status = userStatus?.value;

    const response = yield call(Api.getUserActivityReport, request);
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_users } = activities;
        const { /*page,*/ result /*, size, total*/ } = activities_users;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: string }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_USERS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_USERS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_USERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
function* watchgetActivitiesEbidders() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_EBIDDERS.TRIGGER, getActivitiesEbidders);
}

function* getActivitiesEbidders(action: GetEBidderActivitiesParameters): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_EBIDDERS.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, ebidproposalStatus } = action.payload;
    const finalvalue: any = { page, size, type: 'bidder' };
    if (ebidproposalStatus?.value) finalvalue.status = ebidproposalStatus?.value;

    const response = yield call(Api.getActivitiesAll, finalvalue);
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_ebidders } = activities;
        const { /*page, */ result /*, size, total*/ } = activities_ebidders;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_EBIDDERS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_EBIDDERS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_EBIDDERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetActivitiesQuotes() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_QUOTES.TRIGGER, getActivitiesQuotes);
}

function* getActivitiesQuotes(action: getActivitiesQuotesTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_QUOTES.REQUEST, meta: action.payload });
  try {
    const { page, size, pagination, quotesStatus } = action.payload;
    const finalvalue: any = { page, size, type: 'quote' };
    if (quotesStatus?.value) finalvalue.status = quotesStatus?.value;

    const response = yield call(Api.getActivitiesAll, finalvalue);
    if (response.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const { activities_quotes } = activities;
        const { /*page, */ result /*, size, total*/ } = activities_quotes;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      yield put({ type: actionTypes.GET_ACTIVITIES_QUOTES.SUCCESS, payload: response.data });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_QUOTES.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_QUOTES.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetActivitiesSearch() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_SEARCH.TRIGGER, getActivitiesSearch);
}

function* getActivitiesSearch(action: getActivitiesSearchTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_SEARCH.REQUEST, meta: action.payload });
  try {
    const {
      page,
      size,
      type,
      pagination,
      search,
      searchSelect,
      pagefor,
      notificationStatus,
      bitWatchStatus,
      planholderStatus,
      ebidproposalStatus,
      quotesStatus,
      userStatus,
    } = action.payload;
    const finalvalue: any = { page, size, type };
    if (searchSelect?.value === 'title') finalvalue.title = search;
    if (searchSelect?.value === 'id') finalvalue.identifier = search;
    if (searchSelect?.value === 'supplier') finalvalue.supplier = search;
    if (notificationStatus?.value && pagefor === 'notificationsbid')
      finalvalue.status = notificationStatus?.value;
    if (bitWatchStatus?.value && pagefor === 'bidwatch') finalvalue.status = bitWatchStatus?.value;
    if (planholderStatus?.value && pagefor === 'planholders')
      finalvalue.status = planholderStatus?.value;
    if (ebidproposalStatus?.value && pagefor === 'ebidresponse')
      finalvalue.status = ebidproposalStatus?.value;
    if (quotesStatus?.value && pagefor === 'quotes') finalvalue.status = quotesStatus?.value;
    if (userStatus?.value && pagefor === 'users') {
      finalvalue.status = userStatus?.value;
    }
    let APICALL = Api.getActivitiesAll;
    if (pagefor === 'bidwatch') APICALL = Api.getActivitiesWatchs;

    const response =
      pagefor === 'users'
        ? yield call(Api.getUserActivityReport, finalvalue)
        : yield call(APICALL, finalvalue);
    if (response?.data) {
      if (pagination) {
        const activities = yield select(state => state.activities);
        const {
          activities_notifications_search,
          activities_watchs_search,
          activities_planholders_search,
          activities_ebidders_search,
          activities_quotes_search,
          activities_users_search,
        } = activities;

        let list = [] as any;
        switch (pagefor) {
          case 'notificationsbid':
            list = activities_notifications_search;
            break;
          case 'bidwatch':
            list = activities_watchs_search;
            break;
          case 'planholders':
            list = activities_planholders_search;
            break;
          case 'ebidresponse':
            list = activities_ebidders_search;
            break;
          case 'quotes':
            list = activities_quotes_search;
            break;
          case 'users':
            list = activities_users_search;
            break;
          default:
            list = [];
        }
        const { /*page,*/ result /*, size, total*/ } = list;
        response.data.result = [...Array.from(new Set([...result, ...response.data.result]))];
      }
      if (response.data.result.length > 0) {
        response.data.result.sort((a: { dateOfAction: any }, b: { dateOfAction: string }) =>
          b.dateOfAction.localeCompare(a.dateOfAction),
        );
      }
      let payload: any = '';
      switch (pagefor) {
        case 'notificationsbid':
          payload = { activities_notifications_search: response.data };
          break;
        case 'bidwatch':
          payload = { activities_watchs_search: response.data };
          break;
        case 'planholders':
          payload = { activities_planholders_search: response.data };
          break;
        case 'ebidresponse':
          payload = { activities_ebidders_search: response.data };
          break;
        case 'quotes':
          payload = { activities_quotes_search: response.data };
          break;
        case 'users':
          payload = { activities_users_search: response.data };
          break;
        default:
          payload = '';
      }
      yield put({ type: actionTypes.SET_ACTIVITIES_DETAILS.TRIGGER, payload });
      yield put({ type: actionTypes.GET_ACTIVITIES_SEARCH.SUCCESS });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_SEARCH.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_SEARCH.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
function* watchGetBidActivitiesReport() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_BIDREPORT.TRIGGER, getBidActivitiesReport);
}

function* getBidActivitiesReport(action: getBidActivityReportTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_BIDREPORT.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getBidActivityReport, action.payload);
    if (response.data) {
      yield put({
        type: actionTypes.GET_ACTIVITIES_BIDREPORT.SUCCESS,
        payload: { data: response.data.result, search: action.payload.search },
      });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_BIDREPORT.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_BIDREPORT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchExportBidActivityReport() {
  yield takeLatest(actionTypes.EXPORT_ACTIVITIES_BIDREPORT.TRIGGER, exportBidActivityReport);
}

function* exportBidActivityReport(action: getBidActivityReportTypes) {
  yield put({ type: actionTypes.EXPORT_ACTIVITIES_BIDREPORT.REQUEST, meta: action.payload });
  try {
    yield call(Api.exportBidActivityReport, action.payload);
    yield put({ type: actionTypes.EXPORT_ACTIVITIES_BIDREPORT.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_ACTIVITIES_BIDREPORT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchGetQuoteActivitiesReport() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_QUOTEREPORT.TRIGGER, getQuoteActivitiesReport);
}

function* getQuoteActivitiesReport(action: getQuoteActivityReportTypes): any {
  yield put({ type: actionTypes.GET_ACTIVITIES_QUOTEREPORT.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.getQuoteActivityReport, action.payload);
    if (response.data) {
      yield put({
        type: actionTypes.GET_ACTIVITIES_QUOTEREPORT.SUCCESS,
        payload: { data: response.data.result, search: action.payload.search },
      });
    } else {
      yield put({ type: actionTypes.GET_ACTIVITIES_QUOTEREPORT.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVITIES_QUOTEREPORT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchExportQuoteActivityReport() {
  yield takeLatest(actionTypes.EXPORT_ACTIVITIES_QUOTEREPORT.TRIGGER, exportQuoteActivityReport);
}

function* exportQuoteActivityReport(action: getQuoteActivityReportTypes) {
  yield put({ type: actionTypes.EXPORT_ACTIVITIES_QUOTEREPORT.REQUEST, meta: action.payload });
  try {
    yield call(Api.exportQuoteActivityReport, action.payload);
    yield put({ type: actionTypes.EXPORT_ACTIVITIES_QUOTEREPORT.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_ACTIVITIES_QUOTEREPORT.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

export function* activitiesSaga() {
  yield fork(watchgetActivitiesNotifications);
  yield fork(watchgetActivitiesNotificationsMember);
  yield fork(watchgetActivitiesWatchs);
  yield fork(watchgetActivitiesPlanholders);
  yield fork(watchgetActivitiesEbidders);
  yield fork(watchgetActivitiesQuotes);
  yield fork(watchgetActivitiesSearch);
  yield fork(watchGetBidActivitiesReport);
  yield fork(watchExportBidActivityReport);
  yield fork(watchGetQuoteActivitiesReport);
  yield fork(watchExportQuoteActivityReport);
  yield fork(watchGetActivitiesUsers);
}
