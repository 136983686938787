import { emailVal } from '@demandstar/components/utils';

import * as texts from './AddReminder.texts';

/** convert a comma separated string into an array. */
export function getEmailArray(emailString: string): string[] {
  const x = emailString.split(',').map(email => email.trim());
  return x;
}

/** check the value of all emails in an array. */
export function validateEmailArray(emailString: string) {
  const emails = getEmailArray(emailString);
  const invalidIndex = emails.findIndex(email => {
    return !emailVal.test(email);
  });
  if (invalidIndex > -1) return texts.form.errors.sendEmail;
  return true;
}
