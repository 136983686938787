import { ReactNode } from 'react';
import styled from 'styled-components';

import { Flex, FlexContainer, Margin } from '@demandstar/components/styles';
import { Label } from '@demandstar/components/label/Label';

import * as texts from './ReviewYourContract.texts';
import { Assert, useAssert } from 'src/utils/helpers';
import { ContractDetails } from 'src/features/contract-management/contract-management.d';
import { useContractDetails } from '../../../useContractDetails';

const StyledRow = styled(FlexContainer)`
  margin-top: ${Margin.Less};
`;

type ContractDetailsRowProps =
  | {
      /**a key found both in the ContractDetails interface and texts.labels */
      field: keyof typeof texts.labels & keyof ContractDetails;
      label?: never;
      children?: never;
    }
  | {
      label: keyof typeof texts.labels;
      children: ReactNode;
      field?: never;
    };

/** Shows a simple row summarizing Contract Details contents.
 * @example <ContractDetailsRow field={'contractId'} />
 * @example
 *  <ContractDetailsRow field={'contactPhone'}>
 *    <DSPhone>{`${contractDetails?.contactPhone}`}</DSPhone>
 *  </ContractDetailsRow>
 */
export const ContractDetailsRow = ({ field, children, label }: ContractDetailsRowProps) => {
  const { contractDetails } = useContractDetails();
  useAssert(!!contractDetails, 'Contract Details should be loaded.');

  if (field) {
    return (
      <StyledRow>
        <Flex>
          <Label htmlFor={field}>{`${texts.labels[field]}:`}</Label>
        </Flex>
        <Flex grow={3}>{contractDetails[field]}</Flex>
      </StyledRow>
    );
  }
  Assert(label, 'if there is no field, there must be a label.');
  return (
    <StyledRow>
      <Flex>
        <Label htmlFor={label}>{`${texts.labels[label]}:`}</Label>
      </Flex>
      <Flex grow={3}>{children}</Flex>
    </StyledRow>
  );
};
