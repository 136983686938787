import styled from 'styled-components';

export const ProductSelectionWrapper = styled.div`
  min-width: 18rem;
  padding: 2rem;
  width: 66.66%;
  flex-grow: 2;
`;

export const UpsellSectionWrapper = styled.div`
  min-width: 9rem;
  padding: 1rem;
  width: 33.33%;
  flex-grow: 4;
`;
