import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FontFamily, FontWeight, LineHeight, ParagraphFontSize, TextColor } from '../styles';

export type DSLinkType = 'error' | 'contrast' | 'base';
interface BaseLinkProps {
  large?: boolean;
  type?: DSLinkType;
}

function linkColor({ type }: BaseLinkProps) {
  switch (type) {
    case 'contrast':
      return TextColor.ContrastAction;
    case 'error':
      return TextColor.Error;
    default:
      return TextColor.Action;
  }
}

function linkHoverColor({ type }: BaseLinkProps) {
  switch (type) {
    case 'contrast':
      return TextColor.ActionHover;
    case 'error':
      return TextColor.Error;
    default:
      return TextColor.ActionHover;
  }
}

function linkStyle(props: BaseLinkProps) {
  return `
  font-family: ${FontFamily.Base};
  font-weight: ${FontWeight.Bold};
  font-style: normal;
  font-size: ${props.large ? ParagraphFontSize.Large : ParagraphFontSize.Base};
  line-height: ${LineHeight.Base};
  color: ${linkColor(props)};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${linkHoverColor(props)};
  }
`;
}

export const BaseLinkTo = styled(Link)<BaseLinkProps>`
  ${props => linkStyle(props)}
`;
export const BaseLinkA = styled.a<BaseLinkProps>`
  ${props => linkStyle(props)}
`;
export const BaseLinkSpan = styled.span<BaseLinkProps>`
  ${props => linkStyle(props)}
`;
