import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import { Buttons, CheckBox, DeprecatedInput } from '../../customcontrols/index';
import { AddBidState } from 'src/store/reducers/addbid';
import { AddBidWizardButtons } from './AddBidWizardButtons';
import { loadingMsg } from '../../../utils/texts';
import { sharedTypes } from '../../../types/shared';
import { toastFn } from '../../../utils/helpers';
import { usaDateFormat } from '../../../utils/constants';
import { useForceUpdate } from '../../../utils/helperHooks';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardPage } from '../../../types/wizard';

import AddBidControl from '../breadcrumb/buyer/addbidcontrol';
import NoResult from '../../customcontrols/noresult';

const initial = { title: '', date1: moment().format(usaDateFormat), date2: '' };
const initialPub = { index: '', allow: false };

const tdWidth = { width: '100' };

interface PublicationsProps {
  // TODO: Replace anys
  addbid?: AddBidState;
  bidId?: number;
  currentBidName?: string;
  currentwizard: WizardPage;
  getAddBidPublications?: any;
  pristine?: Record<string, unknown>;
  setAddBidDetails?: any;
  shared?: sharedTypes;
  SkipNext?: any;
  submitAddBidMoveNext?: any;
  submitAddBidUpdateBidPublications?: any;
  submitting?: Record<string, unknown>;
  valid?: Record<string, unknown>;
}

export const Publications = (props: PublicationsProps) => {
  const forceUpdate = useForceUpdate();
  const {
    addbid,
    bidId,
    currentBidName,
    currentwizard,
    getAddBidPublications,
    pristine,
    setAddBidDetails,
    shared,
    SkipNext,
    submitAddBidMoveNext,
    submitAddBidUpdateBidPublications,
    submitting,
    valid,
  } = props;
  const { publicationslist, editable, halfWayCancel } = addbid || {};
  const { internalLoader } = shared || {};
  const [singlePublication, setSinglePublication] = useState(initial);
  const [editPub, setEditPub] = useState(initialPub);
  const [newPublication, setAddNewPublication] = useState(false);

  useEffect(() => {
    if (bidId) getAddBidPublications({ bidId });
  }, [getAddBidPublications, bidId]);

  useEffect(() => {
    return () => {
      setAddBidDetails({ halfWayCancel: false });
    };
  }, [setAddBidDetails]);

  useSetBreadcrumb(
    {
      component: (
        <AddBidControl valid={valid} pristine={pristine} submitting={submitting} bidId={bidId} />
      ),
      page: currentBidName ? 'Agency Bid Update' : 'Agency Bid Creation',
      title: currentBidName
        ? `Update Bid - ${currentwizard?.name}`
        : `Bid Creation - ${currentwizard?.name}`,
      altname: currentBidName
        ? `Update Bid - ${currentwizard?.name}`
        : `Bid Creation - ${currentwizard?.name}`,
      name: currentBidName ? currentBidName : 'Bid Creation',
      id: bidId,
      menuactive: 'bids',
    },
    currentwizard !== undefined,
  );

  const setAddPublication = () => {
    setSinglePublication(initial);
    setEditPub({ index: '', allow: false });
  };

  const addNewPublication = () => {
    setAddPublication();
    setAddNewPublication(true);
  };

  const changePublication = (name: string, value: string) => {
    setSinglePublication({ ...singlePublication, [name]: value });
    if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
  };

  const { title = '' } = singlePublication;

  const addPublication = () => {
    if (singlePublication.title.length > 0) {
      if (title.trim() /* && (date1 || date2) */) {
        const savedList =
          (publicationslist && publicationslist.filter(item => item.title === title)) || [];
        if (savedList && savedList.length === 0) {
          setSinglePublication(initial);
          const valuelist =
            publicationslist && publicationslist.length > 0
              ? [singlePublication, ...publicationslist]
              : [singlePublication];
          setAddBidDetails({ publicationslist: valuelist });
          setAddPublication();
          setAddNewPublication(false);
        } else {
          toastFn('error', 'Name already exists', 'save publication already exists');
        }
      } else {
        toastFn('error', 'At least one date required', 'save publication already exists');
      }
    }
  };

  const editPublication = (
    // ! FIXME: turn this into a number
    index: any,
    item: {
      title: string;
      date1: string;
      date2: string;
    },
  ) => {
    const { title = '', date2 = '' } = item;
    setSinglePublication({ title, date1: moment().format(usaDateFormat), date2 });
    setEditPub({ index: index, allow: true });
  };

  const saveEditPublication = () => {
    const savedList =
      (publicationslist &&
        publicationslist.filter(
          (item, index) => item.title === title && index !== parseInt(editPub.index),
        )) ||
      [];
    if (savedList.length === 0) {
      const valueList =
        publicationslist &&
        publicationslist.map((item, index) => {
          if (index === parseInt(editPub.index)) {
            item = singlePublication;
          }
          return item;
        });
      setAddBidDetails({ publicationslist: valueList });
      setAddPublication();
      if (!halfWayCancel) setAddBidDetails({ halfWayCancel: true });
    } else {
      toastFn('error', 'Name already exists', 'save publication already exist');
    }
  };

  const updatePublicationList = (status: boolean, index: number) => {
    if (publicationslist) {
      const publicationOldList = [...publicationslist];
      if (status === true) {
        publicationOldList[index].date1 = moment().format(usaDateFormat);
        publicationOldList[index].date2 = '';
      } else {
        publicationOldList[index].date1 = '';
        publicationOldList[index].date2 = '';
      }
      setAddBidDetails({ publicationslist: publicationOldList, halfWayCancel: true });
      forceUpdate();
    }
  };

  const onSubmitFn = (data: any) => {
    if (data) {
      if (halfWayCancel) {
        if (editable === 'all') {
          submitAddBidUpdateBidPublications({ bidId });
        } else {
          SkipNext();
        }
      } else {
        submitAddBidMoveNext({ type: 'Bid', Id: bidId, stepId: 5 });
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={onSubmitFn}>
      {formikProps => {
        const { handleSubmit } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            {editable === 'all' && (
              <span
                className='bttn-secondary small float-right'
                title='Add Publication'
                onClick={addNewPublication}
              >
                <i className='mdi mdi-plus mr-1' />
                Add Publication
              </span>
            )}

            <p>Enter all publications you will use for this bid</p>
            <div className='innerColIndent'>
              <Table className='tableHData'>
                <thead>
                  <tr>
                    <th colSpan={2}>Publication</th>
                    {editable === 'all' ? <th style={tdWidth}>Action</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {newPublication && (
                    <tr>
                      <td colSpan={2}>
                        <DeprecatedInput
                          label='Publication Title'
                          name='title'
                          type='text'
                          classNames='class'
                          value={title}
                          placeholder='Publication Title'
                          handleChange={(name: string, value: string) =>
                            changePublication(name, value)
                          }
                          maxLength={50}
                        />
                      </td>
                      <td>
                        <Buttons
                          classNames='bttn-primary small mb-1 mr-1'
                          text='Save'
                          title='Save'
                          type='button'
                          onClick={addPublication}
                          disable={!title.trim()}
                        />
                        <Buttons
                          classNames='bttn-secondary small'
                          text='Cancel'
                          title='Cancel'
                          type='button'
                          onClick={() => {
                            setSinglePublication(initial);
                            setAddNewPublication(false);
                            setEditPub({ index: '', allow: false });
                          }}
                        />
                      </td>
                    </tr>
                  )}

                  {publicationslist && publicationslist.length > 0 ? (
                    <>
                      {publicationslist.map((item, index) => (
                        <tr key={index}>
                          <td width='40px'>
                            <CheckBox
                              title=''
                              handleChecked={(name: string, value: any) => {
                                updatePublicationList(value, index);
                              }}
                              checked={!!(item.date1 || item.date2)}
                              value={item.date1 || item.date2}
                              disabled={
                                (editable && !['all', 'active'].includes(editable)) || !editable
                              }
                            />
                          </td>
                          <td>
                            {editPub.allow && index === parseInt(editPub.index) ? (
                              <DeprecatedInput
                                label='Publication Title'
                                name='title'
                                type='text'
                                classNames='class'
                                value={title}
                                placeholder='Publication Title'
                                handleChange={(name: string, value: string) =>
                                  changePublication(name, value)
                                }
                                maxLength={50}
                              />
                            ) : (
                              item.title
                            )}
                          </td>
                          {editable === 'all' ? (
                            <td>
                              {editPub.allow ? (
                                <>
                                  {index === parseInt(editPub.index) ? (
                                    <>
                                      <Buttons
                                        classNames='bttn-primary small mb-1 mr-1'
                                        text='Save'
                                        title='Save'
                                        type='button'
                                        onClick={() => {
                                          if (singlePublication.title.length > 0) {
                                            saveEditPublication();
                                          }
                                        }}
                                        disable={!singlePublication.title.trim()}
                                      />
                                      <Buttons
                                        classNames='bttn-secondary small'
                                        text='Cancel'
                                        title='Cancel'
                                        type='button'
                                        onClick={() => {
                                          setSinglePublication(initial);
                                          setEditPub({ index: '', allow: false });
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </>
                              ) : newPublication === false ? (
                                <>
                                  <span
                                    className='mdi mdi-square-edit-outline mdi-24px staticLink mr-3'
                                    title='Edit'
                                    onClick={() => editPublication(index, item)}
                                  />
                                </>
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <NoResult
                          message={internalLoader ? loadingMsg : 'No Publications Available'}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <AddBidWizardButtons currentWizard={props.currentwizard} submit={handleSubmit} />
          </form>
        );
      }}
    </Formik>
  );
};
