import { Assert } from '@demandstar/components/utils';

import { BidSummaryLabels } from '../../../shared/constants/bids';
import { BidSummaryPanelView } from './BidSummaryPanelView';
import { PanelLabel } from '../../../shared/styles';
import { useIdFromRoute } from 'src/shared/hooks/useIdFromRoute';

export function BidSummaryPanel() {
  const { bidId } = useIdFromRoute();
  Assert(bidId, 'We can expect a bidId from route', 'ResponseSummaryPanel.tsx');
  return (
    <>
      <PanelLabel>{BidSummaryLabels.title}</PanelLabel>
      <BidSummaryPanelView bidId={bidId} />
    </>
  );
}
