import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../store/actions';
import BidTypes from './bidtypes';
import EBidDocuments from './ebiddocuments';
import GeneralInformation from './generalinformation';
import LegalAds from './legalads';
import PageSettings from './pagesettings';
import Publications from './publications';

import { agencyRegistrationParamType } from '../../../types/agencyregistration';
import { sharedParamType } from '../../../types/commoditycodes';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardContainer } from '../../customcontrols/wizard/WizardContainer';
import { WizardPage } from '../../../types/wizard';

interface AgencyRegistrationProps extends PropsFromRedux {
  agencyregistration: agencyRegistrationParamType;
  shared: sharedParamType;
  match: any;
  form: any;
  getStateList: any;
  getAddAgencyLegal: any;
  getAddAgencyPublications: any;
  getGeneralInfoIntialValues: any;
  getTimezoneList: any;
  resetAllFormValues: any;
  setSharedDetails: any;
}

function AgencyRegistration(props: AgencyRegistrationProps) {
  const {
    agencyregistration,
    shared,
    getAddAgencyLegal,
    getAddAgencyPublications,
    getGeneralInfoIntialValues,
    getTimezoneList,
    resetAllFormValues,
    setSharedDetails,
  } = props;
  const { form, ...otherprops } = props;
  const {
    agencyRegistrationWizard = [],
    wizardcurrentpage = 1,
    legalAds = '',
    generalInfoFormInitialValues,
  } = agencyregistration || {};
  const { stateslist = [], sharedFormInitialValues = {}, formPage = '' } = shared || {};
  const [forminitialValues, setFormInitialValues] = useState(generalInfoFormInitialValues);
  const currentwizard =
    agencyRegistrationWizard.find(item => item.id === wizardcurrentpage) || ({} as WizardPage);
  const memberId = props.match.params.memberId || '';

  const FilterStates =
    (generalInfoFormInitialValues.country &&
      stateslist.filter(
        item => item.countryId === parseInt(generalInfoFormInitialValues.country.value),
      )) ||
    ([] as any);

  useEffect(() => {
    if (formPage && formPage === 'agencyregistration') {
      setFormInitialValues(sharedFormInitialValues);
      setSharedDetails({ formPage: '' });
    }
  }, [formPage, setFormInitialValues, setSharedDetails, sharedFormInitialValues]);

  useEffect(() => {
    setFormInitialValues(generalInfoFormInitialValues);
  }, [generalInfoFormInitialValues]);

  useEffect(() => {
    if (!memberId) {
      resetAllFormValues({});
    }
  }, [resetAllFormValues, memberId]);

  useEffect(() => {
    getTimezoneList({});
  }, [getTimezoneList]);

  useEffect(() => {
    if (memberId) getGeneralInfoIntialValues({ buyerId: memberId });
  }, [getGeneralInfoIntialValues, memberId]);

  useEffect(() => {
    if (memberId) getAddAgencyLegal({ memberId });
  }, [getAddAgencyLegal, memberId]);

  useEffect(() => {
    if (memberId) getAddAgencyPublications({ buyerId: memberId });
  }, [getAddAgencyPublications, memberId]);

  const component = {
    'General Information': (
      <GeneralInformation
        {...otherprops}
        initialValues={forminitialValues}
        stateslist={FilterStates}
        currentwizard={currentwizard}
        setFormInitialValues={(data: any) => setFormInitialValues(data)}
      />
    ),
    Publications: <Publications {...props} currentwizard={currentwizard} />,
    'Bid Types': <BidTypes {...props} currentwizard={currentwizard} />,
    'eBidding Documents': <EBidDocuments {...props} currentwizard={currentwizard} />,
    'Legal Ads': (
      <LegalAds {...otherprops} initialValues={legalAds} currentwizard={currentwizard} />
    ),
    'Agency Page Settings': <PageSettings {...otherprops} currentwizard={currentwizard} />,
  } as any;

  const [current] = agencyRegistrationWizard
    .filter(item => item.id === wizardcurrentpage)
    .map(item => item.name);

  const [currentcomponent] = Object.keys(component)
    .filter(item => item === current)
    .map(item => component[item]);

  useSetBreadcrumb({
    component: '',
    page: 'Agency Creation',
    title: `Agency Creation - ${currentwizard.name}`,
    altname: `Agency Creation - ${currentwizard.name}`,
    name: 'Agency Creation',
    id: 'AgCR',
    menuactive: 'agency',
  });

  return (
    <WizardContainer
      navTitle={'Agency Creation Progress'}
      header={'Add Agency'}
      currentWizard={currentwizard}
      wizard={agencyRegistrationWizard}
    >
      {currentcomponent}
    </WizardContainer>
  );
}

const connector = connect(
  (state: any) => ({
    agencyregistration: state.agencyregistration,
    shared: state.shared,
    accountinfo: state.accountinfo,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(AgencyRegistration));
