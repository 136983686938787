import React from 'react';
import { useQuery } from '@tanstack/react-query'; 
import { getdashboarddata } from '../../store/services/shared';
import { STALE_TIMES } from 'src/utils/constants/queryConstants';

const GetDashboardData = (data: any) => {
  return getdashboarddata(data);
};

export const useGetDashboardData = (data: any) => {
  return useQuery({
    queryKey: ['dashboardData'],
    queryFn: () => GetDashboardData(data), 
    staleTime: STALE_TIMES.THIRTY_MINUTES,
  });
};
