interface StyledRegistrationPageProps {
  children: React.ReactChild;
}

export const StyledRegistrationPage = ({ children }: StyledRegistrationPageProps) => {
  return (
    <div className='unAuthWrapper'>
      <div className='container pt-5'>
        <div className='row d-flex justify-content-center'>{children}</div>
      </div>
    </div>
  );
};
