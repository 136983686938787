import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as broadcastListsApi from '../services/broadcastlists';
import * as Api from '../services/addbid';
import { sortDirections, userEvent } from '../../utils/constants';
import { getOptimizedCommodityCodes, SortingFn, toastFn } from '../../utils/helpers';
import { broadcastlistaddlistTypes, broadcastlistsagaTypes } from '../../types/broadcastlist';

const broadcastListstoastID = '0e433f80-1100-4116-aefe-774d1a4786db';

function* watchgetBroadcastListGetLists() {
  yield takeLatest(actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.TRIGGER, getBroadcastListGetLists);
}

function* getBroadcastListGetLists(action: broadcastlistsagaTypes): any {
  yield put({ type: actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.REQUEST, meta: action.payload });

  try {
    const response = yield call(broadcastListsApi.getPreBuiltBroadcastLists, action.payload);
    let payload = response.data.result;
    payload = SortingFn(payload, sortDirections.ASC, 'name');
    const broadcastListID = payload.length > 0 ? payload[0].listId : 0;
    payload = {
      preBuiltBroadCastList: payload,
      selectedbroadcastlist: payload.length > 0 ? payload[0] : '',
    };
    if (broadcastListID) {
      yield put({
        type: actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.TRIGGER,
        payload: { id: broadcastListID },
      });
    }
    yield put({ type: actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', broadcastListstoastID);
  }
}

function* watchgetBroadcastListBroadcastMembers() {
  yield takeLatest(
    actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.TRIGGER,
    getBroadcastListBroadcastMembers,
  );
}

function* getBroadcastListBroadcastMembers(action: broadcastlistsagaTypes): any {
  yield put({
    type: actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.REQUEST,
    meta: action.payload,
  });
  try {
    const response = yield call(broadcastListsApi.getBroadcastMembers, action.payload);
    yield put({
      type: actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.SUCCESS,
      payload: response.data.result || [],
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchgetBroadcastListSearchVendors() {
  yield takeLatest(
    actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.TRIGGER,
    getBroadcastListSearchVendors,
  );
}

function* getBroadcastListSearchVendors(action: { payload: any }): any {
  yield put({ type: actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.REQUEST, meta: action.payload });
  try {
    const broadcastList = yield select(state => state.broadcastList);
    const auth = action.payload.auth;
    const { broadcastFilter = {} } = broadcastList;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);

    const { memberId } = auth;
    const finaldata = {
      memberId,
      rowCount: 1000,
      listType: 'BroadcastList',
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration
              .map(
                (item: { attributeType: string; value: string | number }) =>
                  item.attributeType || item.value,
              )
              .join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string | number }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(broadcastListsApi.SearchVendorsBroadcastList, finaldata);
    const data = response.data.result || [];
    if (data) {
      const payload = { broadcastListssearch: data, broadcastListssearchShow: true };
      yield put({ type: actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.SUCCESS, payload });
      yield put({
        type: actionTypes.SET_COMMODITY_CODES.TRIGGER,
        payload: { searchedCommodityCodes: [], searchText: '' },
      });
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { commodityAlert: false },
      });
    } else {
      yield put({ type: actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.FAILURE });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_BROADCAST_LIST_SEARCH_VENDORS.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', broadcastListstoastID);
  }
}

function* watchsubmitBroadcastListAddList() {
  yield takeLatest(actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.TRIGGER, submitBroadcastListAddList);
}

function* submitBroadcastListAddList(action: broadcastlistaddlistTypes): any {
  yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.REQUEST, meta: action.payload });
  try {
    const { replaceIfExists = false, type } = action.payload;
    const broadcastList = yield select(state => state.broadcastList);
    const commoditycodes = yield select(state => state.commoditycodes);
    const { commoditieslist = [] } = commoditycodes || {};
    let filterData = {};
    const generatenewlist: Array<number | string> = getOptimizedCommodityCodes(commoditieslist);
    const {
      broadcastFilter = {},
      newlistname = '',
      selectedbroadcastlist,
      newlistmodalfor,
    } = broadcastList;
    const {
      filterState = [],
      filterCity = [],
      filterDeclaration = [],
      filterPrograms = [],
    } = broadcastFilter;
    const broadcastListId = newlistmodalfor === 'edit' ? selectedbroadcastlist?.listId : 0;
    const auth = action.payload.auth;
    const finaldataFilters = {
      MemberId: auth && auth?.memberId,
      ListID: selectedbroadcastlist?.listId,
    };
    if (replaceIfExists) {
      const response = yield call(
        broadcastListsApi.getSelectedCommoditiesFiltersforBL,
        finaldataFilters,
      ) || '';
      filterData = response.data.result || {};
    }
    const finaldata = {
      type: type,
      name: newlistmodalfor === 'edit' ? selectedbroadcastlist?.name : newlistname.trim(),
      broadcastListId,
      rowCount: 1000,
      replaceIfExists,
      listType: 'BroadcastList',
      cityList: filterCity && filterCity.length > 0 ? filterCity.join() : '',
      commodityIdList: [...Array.from(new Set([...generatenewlist]))].join(','),
      declaredAttributeList:
        filterDeclaration && filterDeclaration.length > 0
          ? filterDeclaration.map((item: { attributeType: string }) => item.attributeType).join()
          : '',
      programIdList:
        filterPrograms && filterPrograms.length > 0
          ? filterPrograms.map((item: { programId: string | number }) => item.programId).join()
          : '',
      stateList:
        filterState && filterState.length > 0
          ? filterState.map((item: { abbr: string }) => item.abbr).join()
          : '',
    };

    const response = yield call(broadcastListsApi.AddBroadcastList, finaldata);
    const data = response.data.result;
    if (data && data.status) {
      if (data.listId) {
        const payload = {
          savelistmodal: false,
          newlistname: '',
          savelistExistmodal: false,
          newlistmodal: false,
          confirmmodal: false,
          confirmChanges: false,
          newlistmodalfor: '',
        };
        yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.SUCCESS, payload });
        const payloadEvent = {
          eventId: userEvent.DeleteSavedList,
          parentId: finaldataFilters?.ListID,
          value: filterData,
        };
        yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
        yield put({ type: actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.TRIGGER });
      } else {
        yield put({ type: actionTypes.GET_BROADCAST_LIST_SAVED_LISTS.TRIGGER });
        toastFn('error', 'Name already exists', broadcastListstoastID);
      }
    } else {
      yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.FAILURE });
      toastFn('error', 'Failed', broadcastListstoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_BROADCAST_LIST_ADD_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', broadcastListstoastID);
  }
}

function* watchsubmitBroadcastListDeleteList() {
  yield takeLatest(
    actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.TRIGGER,
    submitBroadcastListDeleteList,
  );
}

function* submitBroadcastListDeleteList(action: broadcastlistaddlistTypes): any {
  yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.submitAddBidDeleteList, action.payload);
    const data = response.data.result || {};
    if (data && data.status) {
      const broadcastList = yield select(state => state.broadcastList);
      const { preBuiltBroadCastList = [] } = broadcastList;
      const payload =
        preBuiltBroadCastList.filter(
          (item: { listId: string | number }) => item.listId !== action.payload.listId,
        ) || [];
      if (payload.length > 0) {
        const broadcastListID = payload[0].listId;
        if (broadcastListID) {
          yield put({
            type: actionTypes.GET_BROADCAST_LIST_BROADCAST_MEMBERS.TRIGGER,
            payload: { id: broadcastListID },
          });
        }
      }
      yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.SUCCESS, payload });
      const payloadEvent = {
        eventId: userEvent.DeleteSavedList,
        parentId: action.payload.listId,
        value: false,
      };
      yield put({ type: actionTypes.SUBMIT_TRACK_EVENTS.TRIGGER, payload: payloadEvent });
      toastFn('success', 'Deleted', broadcastListstoastID);
    } else {
      yield put({ type: actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.FAILURE });
      toastFn('error', 'Failed', broadcastListstoastID);
    }
  } catch (error) {
    yield put({
      type: actionTypes.SUBMIT_BROADCAST_LIST_DELETE_LIST.FAILURE,
      payload: { payload: action.payload, error },
    });
    toastFn('error', 'Failed', broadcastListstoastID);
  }
}

export function* broadcastListsSaga() {
  yield fork(watchgetBroadcastListGetLists);
  yield fork(watchgetBroadcastListBroadcastMembers);
  yield fork(watchgetBroadcastListSearchVendors);
  yield fork(watchsubmitBroadcastListAddList);
  yield fork(watchsubmitBroadcastListDeleteList);
}
