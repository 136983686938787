import { Dayjs } from 'dayjs';

import { SearchInputProps } from './DSSearchInput';
import { UrlHandling } from '../types';

export enum SearchInputType {
  Checkbox,
  Date,
  DateRange,
  Text,
  /** TODO: Add a Numeric Type when DSInputNumeric is created */
  // Numeric,
  MultipleSelect,
  Select,
}

export enum SearchInputWidth {
  Full = '100%',
  ThreeFourth = 'calc(75% - 0.375rem)',
  TwoThird = 'calc(66.67% - 0.5rem)',
  Half = 'calc(50% - 0.75rem)',
  Third = 'calc(33.33% - 1rem)',
  Fourth = 'calc(25% - 1.125rem)',
}

export type ValidFilterValue =
  | string
  | number
  | Dayjs
  | Record<string, unknown>
  | Record<string, unknown>[]
  | any
  | boolean;

export type AppliedFilter<T extends Record<string, unknown>> = {
  /** Human-readable display for the filter, leave blank for `value-only` filter displays */
  displayName?: string;
  /** The human-readable display for the filter value to display */
  displayValue?: string;
  /** The name for the input & a key of the filters object */
  name: keyof T;
  /** selected value */
  value?: T[keyof T];
  /** The type of search input to render */
  inputType: SearchInputType;
  /** If the filter is readonly */
  readOnly?: boolean;
};

export type SearchFilter<
  ValueType extends FilterType[keyof FilterType],
  FilterType extends Record<string, ValidFilterValue>,
> = Omit<SearchInputProps<ValueType>, 'name' | 'setFilterValue'> & {
  /** indicates this filter should only be shown on advanced search */
  advancedOnly?: boolean;
  /** Name to use on applied filter pill, if applicable */
  displayName?: string;
  /** Function to convert the user's selected value into a user-readable value for applied filter pills */
  getDisplayValue?: (value: ValueType | undefined) => string | undefined;
  /** The desired width for the input, inputs will stack as the screen shrinks */
  width?: SearchInputWidth;
  /** The input's name property, as well as the attribute the value will be sent to on the search function */
  name: keyof FilterType & string;
  /** Optional message to clarify search parameters */
  message?: string;
  /** Optional handling methods for URL */
  urlHandling?: UrlHandling<ValueType>;
};

export type SearchFilters<FilterType extends Record<string, ValidFilterValue>> = SearchFilter<
  FilterType[keyof FilterType],
  FilterType
>[];
