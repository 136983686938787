export const comingSoonHeadline = 'Coming soon to DemandStar: Contract Sharing';
export const comingSoonInfo =
  'Search for and share piggybacking and cooperative contract opportunities with other governments in your area and nationwide.';
export const contractAdded = ' has been successfully added to DemandStar.';
export const importButton = 'See your awarded bids';
export const importHeader = 'Import Awards';
export const importInfo =
  "You've previously awarded bids on DemandStar. Would you like to add them to Contract Management? Select an award to begin.";
export const mainGraphHeader = 'Monthly Spend';
export const primaryCalloutHeader = 'Suppliers Awarded';
export const secondaryCalloutHeader = 'Total Bids Awarded';
export const scheduleReminders = 'Schedule some reminders for this contract';
