import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { defaultNumericId } from '@demandstar/components/constants';

import { DocumentDownloadType } from 'src/types';

import { documentDownloadInProgressState } from 'src/store/recoil/documentState';
import { downloadResponseDocument } from 'src/store/services';

interface DownloadLinkConfig {
  idField: string;
  downloadIdField: string;
  type?: DocumentDownloadType;
}
interface UseDownloadProps {
  fieldConfig?: DownloadLinkConfig;
}

export function useDownload(props?: UseDownloadProps) {
  const { fieldConfig } = props ?? {};
  const [documentDownloading, setDocumentDownloading] = useRecoilState(
    documentDownloadInProgressState,
  );

  const { idField = '', downloadIdField = '', type = 'Bid' } = fieldConfig ?? {};

  const handleDownloadLinkClick = useCallback(
    async (cellData: Record<string, number | DocumentDownloadType>) => {
      const id = (cellData[idField] as number) || defaultNumericId;
      const docId = (cellData[downloadIdField] as number) || defaultNumericId;
      if (!documentDownloading) {
        setDocumentDownloading(true);
        downloadResponseDocument({ docId, id, type });
        setDocumentDownloading(false);
      }
    },
    [documentDownloading, downloadIdField, idField, setDocumentDownloading, type],
  );

  return { handleDownloadLinkClick };
}
