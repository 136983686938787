//TOREFACTOR

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { memo } from 'react';

import * as actionCreators from '../../../../store/actions';
import { BidExternalStatusType, BidInternalStatusType } from '../../../../utils/constants';
import { addbidParamType } from '../../../../types/addbid';
import { bidssummaryParamType } from '../../../../types/biddetail';
import history from '../../../../utils/history';
import { WizardPage } from '../../../../types/wizard';

type AddBidControlProps = {
  addbid?: addbidParamType;
  bidId?: number; //parent
  bidssummary?: bidssummaryParamType;
  setAddBidDetails?: any;
  /** @deprecated this does nothing. */
  addbidebidreduxForm?: any;
  /** @deprecated this does nothing. */
  pristine?: Record<string, unknown>;
  /** @deprecated this does nothing. */
  pristineOpt?: boolean;
  /** @deprecated this does nothing. */
  submitting?: Record<string, unknown>;
  /** @deprecated this does nothing. */
  valid?: Record<string, unknown>;
};

const AddBidControl = (props: AddBidControlProps) => {
  const {
    addbid,
    bidId, //parent
    bidssummary,
    setAddBidDetails,
  } = props;
  const { results } = bidssummary || {};
  const {
    bidExternalStatusType = BidExternalStatusType.None,
    bidInternalStatusType = BidInternalStatusType.None,
  } = results || {};
  const { addbidwizard = [] } = addbid || {};

  const currentwizard =
    (addbidwizard && addbidwizard.find(item => item.status === 'current')) || ({} as WizardPage);

  let savebtnText = '';
  if (bidId) {
    if (
      bidExternalStatusType === BidExternalStatusType.Upcoming &&
      bidInternalStatusType === BidInternalStatusType.Pending
    ) {
      savebtnText = 'Save & Finish Later';
    } else {
      savebtnText = 'Save & Exit';
    }
  } else {
    savebtnText = 'Save & Finish Later';
  }

  const cancel = () => {
    if (bidId) {
      history.push(`/buyers/bids/${bidId}`);
    } else {
      history.goBack();
    }
  };

  const SaveFinishLater = () => {
    document.getElementById('saveAndFinishLater')?.click();
  };

  return (
    <>
      {currentwizard && currentwizard.id < addbidwizard.length ? (
        <li title={savebtnText}>
          <span
            className='staticLink'
            onClick={() => {
              setAddBidDetails({ addbidwizardpartial: true });
              SaveFinishLater();
            }}
          >
            <i className='mdi mdi-content-save-outline mr-1' /> {savebtnText}
          </span>
        </li>
      ) : null}

      <li title='Cancel'>
        <span className='staticLink' onClick={() => cancel()}>
          <i className='mdi mdi-close mr-1' />
          Cancel
        </span>
      </li>
    </>
  );
};

export default connect(
  (state: any) => ({ addbid: state.addbid, bidssummary: state.bidssummary }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(AddBidControl));
