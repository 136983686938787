import React from 'react';

interface TextAreaInputTypes {
  parentClass?: string;
  label: string;
  optional: boolean;
  error: boolean;
  name: string;
  value: string;
  disabled: boolean;
  classNames?: string;
  placeholder: string;
  maxLength?: any;
  remainShow?: boolean;
  form: any;
  field: any;
  onChange: any;
  onBlur: any;
}

function TextArea(props: TextAreaInputTypes) {
  const {
    onChange = () => {},
    onBlur = () => {},
    label,
    optional = false,
    disabled,
    placeholder,
    parentClass = '',
    maxLength,
    form = {},
    field = {},
    classNames = '',
    remainShow = false,
  } = props;

  const { errors = false, touched = false /* , setFieldTouched */ } = form;
  const { name = '', value = '' } = field;

  const onChangeFn = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    onChange(name, value.trimStart());
    // setFieldTouched(name, true, false)
  };

  const onBlurFn = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    onBlur(name, value.trimStart());
    // setFieldTouched(name, true, false)
  };

  return (
    <>
      <div
        className={
          'inputWrapper clearfix ' +
          parentClass +
          (errors[name] && touched[name] ? ' errorWrapper' : '')
        }
      >
        <div>
          <label>{label}</label>
          <textarea
            {...field}
            // type='textarea'
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChangeFn}
            onBlur={onBlurFn}
            maxLength={maxLength}
            value={value}
          ></textarea>
        </div>
        {remainShow ? (
          <span className='textArea'>
            {maxLength - value.length} Characters Remaining{' '}
            {optional ? '(optional)' : optional ? `(${optional})` : ''}{' '}
          </span>
        ) : optional ? (
          <span>(optional)</span>
        ) : optional ? (
          <span>({optional})</span>
        ) : (
          ''
        )}
        <div className={'errorMsg textArea'}>
          {errors[name] && touched[name] && <span>{errors[name]}</span>}
        </div>
      </div>
    </>
  );
}

const renderTextAreaField = (fieldvalues: any) => <TextArea {...fieldvalues} />;

export default renderTextAreaField;
