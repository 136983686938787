import { OnClick, Title } from '@demandstar/components/types';
import { DSLink } from '@demandstar/components/link';

import { Pages } from '../../../../../utils/constants';

export type BidDetailsLinkProps = OnClick &
  Title & {
    bidId: number;
    type?: 'limited' | 'buyers' | 'suppliers';
    children: string;
    newTab?: boolean;
  };

const typePaths = {
  limited: Pages.limitedBids,
  buyers: Pages.buyerBids,
  suppliers: Pages.supplierBids,
};

/** Wrap this around a clickable string and it will turn it into a link to the bid Details page.
 * @example <BidDetailsLink bidId={bidId} type='buyers'>Foo</BidDetailsLink>
 */
export const BidDetailsLink = (props: BidDetailsLinkProps) => {
  const { bidId, children, title, type = 'limited', newTab } = props;
  const path = `${typePaths[type]}/${bidId}/details`;

  if (newTab) {
    return (
      <DSLink href={path} title={title} newTab>
        {children}
      </DSLink>
    );
  }

  return (
    <DSLink to={path} title={title}>
      {children}
    </DSLink>
  );
};
