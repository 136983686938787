import styled from 'styled-components';

import {
  BackgroundColor,
  BorderRadius,
  DeprecatedColors,
  FlexContainer,
  FontFamily,
  FontWeight,
  getMaximumLayoutWidth,
  HeaderFontSize,
  LineHeight,
  Margin,
  Padding,
  TextColor,
} from '../styles';
import { Layout } from '../types';

export const BreadcrumbBar = styled.div`
  background: ${BackgroundColor.BaseTeal};
  display: flex;
  flex-flow: row;
  font-family: ${FontFamily.Base};
  font-size: ${HeaderFontSize.H6};
  font-weight: ${FontWeight.Bold};
  justify-content: center;
  margin-top: -0.25rem;
  padding: ${Padding.None} ${Padding.Base};
  position: sticky;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: ${Margin.None};
  }
`;

export const BreadcrumbContainer = styled(FlexContainer)<Layout>`
  display: flex;
  display: flex;
  justify-content: space-between;
  max-width: ${getMaximumLayoutWidth};
  width: 100%;
`;

export const BreadcrumbList = styled.ul`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  list-style-type: none;
  margin: ${Margin.None};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${Padding.Less} ${Padding.None};

  & li {
    color: ${TextColor.ContrastAction};
    display: inline-flex;
    flex: 0 0 auto;
    line-height: ${LineHeight.Subheader};
    margin: ${Margin.None} 0.25rem;
    overflow: hidden;
    width: auto;

    &:not(:last-child) {
      max-width: 20rem;
      text-decoration-line: underline;

      & i {
        font-size: ${HeaderFontSize.H5};
        &.mdi-home-variant-outline {
          margin-right: ${Margin.Small};
        }
      }
    }

    & span {
      cursor: pointer;
      margin-right: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-child {
      flex: 1 1 0;
      color: ${TextColor.Contrast};
    }
  }
`;

export const BreadcrumbComponent = styled.ul`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  list-style-type: none;
  margin: ${Margin.None};
  padding: ${Padding.Less} ${Padding.None};

  & button {
    height: 2.25rem;
    padding: ${Padding.Small} ${Padding.Less};
  }

  & li {
    background: ${DeprecatedColors.BlueGreenMedium};
    border-radius: ${BorderRadius.Eased};
    color: ${TextColor.Contrast};
    display: inline-flex;
    line-height: ${LineHeight.Subheader};
    padding: ${Padding.None} ${Padding.Less};

    &:not(:last-child) {
      margin-right: ${Margin.Less};
    }

    & span.staticLink {
      color: ${TextColor.Contrast} !important;
      font-weight: ${FontWeight.Bold};

      &:hover {
        color: ${TextColor.Contrast} !important;
      }
    }

    & .dropdown {
      & > button {
        background: none;
        border: none;
        padding: 0;

        &:focus,
        &:active:focus {
          box-shadow: none;
        }

        &.btn-secondary:active {
          &:focus {
            box-shadow: none;
          }

          background: none;
        }
      }

      & .dropdown-menu {
        margin-left: -${Margin.Base};
        margin-top: ${Margin.Less};

        & .dropdown-item:active {
          background: ${BackgroundColor.BaseGray};
        }
      }
    }
  }
`;
