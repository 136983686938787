import jwt from 'jsonwebtoken';

import { AuthToken } from './token';
import { constants } from './settings';

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, value: unknown, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; domain=${constants.cookieDomain}; expires=${date.toUTCString()}`;
  }

  document.cookie =
    cname + '=' + (value || '') + expires + '; Secure' + '; SameSite=Strict' /*+ '; HttpOnly' */ + '; path=/';
};

export const deleteCookie = (name: string) => {
  const expDate = new Date();
  expDate.setTime(expDate.getTime() - 86400000); //-1 day
  const value = `""; domain=${constants.cookieDomain}; expires=${expDate.toUTCString()};path=/`;
  document.cookie = name + '=' + value;
};

export const validateCookie = (name: string) => {
  const token = getCookie(name);
  const tokenData = jwt.decode(token) as AuthToken;
  // Assert(tokenData, 'We should not get a null value when decoding tokenData', 'cookie.ts');
  const { mi, mt } = tokenData;
  return mi && mt ? true : false;
};
