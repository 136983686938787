import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import {
  AppliedFilter,
  SearchFilter,
  SearchFilters,
  SearchInputType,
  SearchInputWidth,
  ValidFilterValue,
} from './DSSearch.d';
import { FlexContainer, H4 } from '../styles';
import { getUrlValue, setUrlValue, useDebouncedState } from '../utils';
import {
  StyledSearchInput,
  StyledSearchInputRow,
  ToggleAdvancedSearchIcon,
} from './DSSearch.styles';
import { DataTestId } from '../types';
import { DSAlert } from '../alert';
import { DSAppliedFilters } from '../applied-filters';
import { DSButton } from '../button';
import { DSLink } from '../link';
import { DSLoadingPanel } from '../loading-panel';
import { DSSearchInput } from './DSSearchInput';
import { DSSearchResults } from './DSSearchResults';
import { DSSortProps } from '../sort';
import { hasSearchValue } from './DSSearch.utils';
import { PaginationControlProps } from '../paginate/DSPaginationControl';
import { Status } from '../constants';
import { ToggleExpandIcon } from '../icon/ToggleExpandIcon';

export type SearchProps<
  /** The object passed to the search function */
  FilterType extends Record<string, ValidFilterValue>,
  /** The result received from the search function */
  ResultType extends Record<string, unknown>,
> = DataTestId & {
  /** Length of time in milliseconds to debounce search results, default 500 */
  debounceDelay?: number;
  /** The message to display if no records meet search criteria */
  emptyMessage?: React.ReactChild;
  /**
   * The filters that should be rendered for search
   * Type parameter must be a type found in `FilterType` properties
   */
  filters: SearchFilters<FilterType>;
  /**
   * Should search occur on value change? Default true
   * If false, debouncing will not be used & an "Apply" button will be added
   * Use when search is very expensive in place of lengthy debounces
   */
  isLive?: boolean;
  /** The content to display while search results are loading, defaults to DSLoadingPanel */
  loadingDisplay?: React.ReactNode;
  /** Override for pagination props */
  pagination?: Omit<PaginationControlProps, 'data' | 'children'>;
  /**
   * Either the property of the result that should be used as its `key` in the list
   * or a function that takes the result object & results a key value
   */
  recordKey: keyof ResultType | ((record: ResultType) => React.Key);
  /** The renderer for a single "row" of the result list
   * @warning if passing a function, be sure to memoize it.
   */
  renderResult?: React.ComponentType<ResultType>;
  /** The renderer for a data set
   * @warning if passing a function, be sure to memoize it.
   */
  renderResultSet?: React.ComponentType<{ data: ResultType[] }>;
  /** The function to be called for searching */
  search: (filters: Partial<FilterType>) => Promise<ResultType[]> | ResultType[];
  /** Optional sort parameters */
  sort?: Omit<DSSortProps<ResultType>, 'data' | 'children' | 'pagination'>;
};

export function DSSearch<
  FilterType extends Record<string, ValidFilterValue>,
  ResultType extends Record<string, unknown>,
>({
  dataTestId = 'ds-search',
  emptyMessage,
  debounceDelay = 500,
  filters,
  isLive = true,
  loadingDisplay,
  pagination,
  recordKey,
  renderResult,
  renderResultSet,
  search,
  sort,
}: SearchProps<FilterType, ResultType>) {
  const delay = useMemo(() => {
    return isLive ? debounceDelay : 0;
  }, [debounceDelay, isLive]);

  const {
    location: { search: urlSearch },
  } = useHistory();
  const urlSearchParameters = useMemo(() => new URLSearchParams(urlSearch), [urlSearch]);

  const defaultValues: AppliedFilter<FilterType>[] = useMemo(() => {
    return filters
      .map(f => {
        const urlValue = f.urlHandling
          ? getUrlValue(urlSearchParameters, f.urlHandling)
          : f.defaultValue;
        return {
          displayName: f.displayName,
          displayValue: f.getDisplayValue
            ? f.getDisplayValue(urlValue || f.defaultValue)
            : undefined,
          name: f.name,
          value: urlValue || f.defaultValue,
          inputType: f.inputType,
          readOnly: f.readOnly,
        };
      })
      .filter(f => hasSearchValue(f));
  }, [filters, urlSearchParameters]);

  const defaultSort = useMemo(() => {
    if (!sort) return undefined;
    if (sort.defaultSort) return sort.defaultSort;

    const urlSort = urlSearchParameters.get('sort');
    return sort?.sortOptions?.find(s => s.label === urlSort);
  }, [sort, urlSearchParameters]);

  const [debouncedDefaultSet, setDefaultSet, defaultSet] = useDebouncedState<
    Partial<Record<keyof FilterType & string, true>>
  >({});
  const [filtersToClear, setFiltersToClear] = useState<string[]>([]);
  const [debouncedFilters, setAppliedFilters, appliedFilters] = useDebouncedState<
    AppliedFilter<FilterType>[]
  >(defaultValues, delay, filtersUnchanged);
  const [defaultDebouncedSet, setDefaultDebouncedSet] = useState(false);

  const [results, setResults] = useState<ResultType[]>();
  const [error, setError] = useState(false);
  const [hasAdvanced, setHasAdvanced] = useState(false); // whether to show advanced/basic buttons
  const [isAdvanced, setIsAdvanced] = useState(false); // whether to expand advanced options

  const isFilterLoading = useMemo(() => {
    return filters.some(f => {
      if (
        [SearchInputType.Select, SearchInputType.MultipleSelect].includes(f.inputType) &&
        f.options
      )
        if ('state' in f.options) return f.options.state === 'loading';

      return false;
    });
  }, [filters]);

  const [isLoading, setIsLoading] = useDebouncedState(isLive && isFilterLoading);

  const debouncedFilterValue = useMemo(() => {
    const value = {} as Partial<FilterType>;

    debouncedFilters.forEach(filter => {
      value[filter.name] = filter.value;
    });

    return value;
  }, [debouncedFilters]);

  const updateResults = useCallback(async () => {
    let didErrorOccur = false;
    setIsLoading(true);
    try {
      const newResults = await search(debouncedFilterValue);
      setResults(newResults);
    } catch {
      setResults([]);
      didErrorOccur = true;
    } finally {
      setError(didErrorOccur);
      setIsLoading(false);
    }
  }, [debouncedFilterValue, search, setIsLoading]);

  const setFilterValue = useCallback(
    (
      {
        displayName,
        getDisplayValue,
        name,
        inputType,
        urlHandling,
        readOnly,
      }: SearchFilter<FilterType[keyof FilterType], FilterType>,
      value?: FilterType[keyof FilterType],
    ) => {
      const filter: AppliedFilter<FilterType> = {
        displayName: displayName,
        displayValue: getDisplayValue ? getDisplayValue(value) : undefined,
        name,
        value,
        inputType,
        readOnly,
      };

      if (urlHandling) {
        setUrlValue(urlSearchParameters, urlHandling, value);
      }

      // Remove last filter value from existing set of filters
      let currentFilters = appliedFilters.filter(f => f.name !== filter.name);

      // If filter value is truthy, add it back to the list with new value
      if (hasSearchValue(filter)) {
        currentFilters = [...currentFilters, filter];
      }
      setAppliedFilters(currentFilters);
    },
    [appliedFilters, setAppliedFilters, urlSearchParameters],
  );

  const clearFilter = useCallback(
    (name: string) => {
      const clearingFilter = filters.find(f => f.name === name);
      const currentFilters = appliedFilters.filter(f => f.name !== name);

      if (clearingFilter?.urlHandling) {
        setUrlValue(urlSearchParameters, clearingFilter?.urlHandling, undefined);
      }

      setFiltersToClear([...filtersToClear, name]);
      setAppliedFilters(currentFilters);
    },
    [appliedFilters, filters, filtersToClear, setAppliedFilters, urlSearchParameters],
  );

  const IconComponent = () => {
    return <ToggleExpandIcon color='currentColor' />;
  };

  useEffect(() => {
    if (isLive && !isFilterLoading) updateResults();
  }, [isFilterLoading, isLive, updateResults]);

  useEffect(() => {
    if (Object.keys(defaultSet).length < filters.length) {
      const update = [] as (keyof FilterType & string)[];
      const loadedFilters = filters
        .filter(
          f =>
            !(
              [SearchInputType.Select, SearchInputType.MultipleSelect].includes(f.inputType) &&
              f.options &&
              'state' in f.options &&
              f.options.state === 'loading'
            ),
        )
        .map(f => f.name);

      const newDefaultSet = {
        ...defaultSet,
      };

      loadedFilters.forEach(f => {
        if (!defaultSet[f]) {
          update.push(f);
          newDefaultSet[f] = true;
        }
      });

      if (update.length) {
        setDefaultSet(newDefaultSet);
        setAppliedFilters([
          ...appliedFilters.filter(f => typeof f.name !== 'string' || !update.includes(f.name)),
          ...defaultValues.filter(f => typeof f.name === 'string' && update.includes(f.name)),
        ]);
        setFiltersToClear([...filtersToClear, ...update]);
      }
    }
  }, [
    appliedFilters,
    defaultSet,
    defaultValues,
    filters,
    filtersToClear,
    setAppliedFilters,
    setDefaultSet,
  ]);

  /**
   * A bit of a hacky workaround- Adding a filter name to this array
   * will force a re-render & remove it from the DOM, then will add it back.
   * This achieves the result of "resetting" a filter
   */
  useEffect(() => {
    if (filtersToClear.length) {
      setFiltersToClear([]);
    }
  }, [filtersToClear]);

  /** Determine if there is advanced search */
  useEffect(() => {
    if (filters.find(f => f.advancedOnly)) setHasAdvanced(true);
  }, [filters]);

  /**
   *  This is a hack to set applied filters for backend/GraphQL searches by URL
   *  The pagination value is only sent with backend searches, and debouncedFilters was getting set before defaultValues
   *  This fixes that timing issue and ensures once debouncedFilters gets set properly, filters can be removed.
   */
  useEffect(() => {
    if (
      pagination &&
      defaultValues.length > 0 &&
      debouncedFilters.length === 0 &&
      !defaultDebouncedSet
    ) {
      setAppliedFilters(defaultValues);
      setDefaultDebouncedSet(true);
    }
  }, [debouncedFilters.length, defaultDebouncedSet, defaultValues, pagination, setAppliedFilters]);

  function filtersUnchanged<T extends Record<string, ValidFilterValue>>(
    oldFilters: AppliedFilter<T>[],
    newFilters: AppliedFilter<T>[],
  ) {
    // Easy check, length mismatch means something changed
    if (oldFilters.length !== newFilters.length) {
      return false;
    }

    for (let i = 0; i < oldFilters?.length; i++) {
      const check = oldFilters[i];
      const match = newFilters.find(f => f.name === check.name && f.value === check.value);

      if (!match) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      <H4>{'Filter & Search'}</H4>
      {/** Filters */}
      <StyledSearchInputRow wrap={'wrap'} justifyContent={'space-between'}>
        {filters.map(filter => {
          const clearing = filtersToClear.includes(filter.name.toString());
          const defaultValue = !debouncedDefaultSet[filter.name]
            ? defaultValues.find(dv => dv.name === filter.name)?.value
            : undefined;
          return (
            !clearing &&
            (!hasAdvanced ||
              (hasAdvanced && !isAdvanced && !filter.advancedOnly) ||
              (hasAdvanced && isAdvanced)) && (
              <StyledSearchInput width={filter.width ?? SearchInputWidth.Half} key={filter.name}>
                <DSSearchInput
                  dataTestId={filter.dataTestId}
                  defaultValue={defaultValue}
                  inputType={filter.inputType}
                  label={filter.label}
                  name={filter.name}
                  options={filter.options}
                  optionLabel={filter.optionLabel}
                  setFilterValue={value => setFilterValue(filter, value)}
                  message={filter.message}
                  readOnly={filter.readOnly}
                />
              </StyledSearchInput>
            )
          );
        })}
      </StyledSearchInputRow>
      {/** Optional button for `!isLive` search */}
      {!isLive && (
        <FlexContainer justifyContent={'end'}>
          <DSButton onClick={updateResults} title={'Apply'} dataTestId={`${dataTestId}-apply-btn`}>
            Apply
          </DSButton>
        </FlexContainer>
      )}
      {hasAdvanced && (
        <FlexContainer justifyContent={'end'} columnGap={false}>
          {isAdvanced ? (
            <ToggleAdvancedSearchIcon>
              <DSLink
                dataTestId={`${dataTestId}-basic-search`}
                icon={IconComponent}
                onClick={() => setIsAdvanced(!isAdvanced)}
                title={'Show Basic Search'}
              >
                Basic Search
              </DSLink>
            </ToggleAdvancedSearchIcon>
          ) : (
            <DSLink
              dataTestId={`${dataTestId}-advanced-search`}
              icon={IconComponent}
              onClick={() => setIsAdvanced(!isAdvanced)}
              title={'Show Advanced Search'}
            >
              Advanced Search
            </DSLink>
          )}
        </FlexContainer>
      )}

      {/** Applied Filters Display, library component */}
      <DSAppliedFilters
        clearFilter={clearFilter}
        appliedFilters={appliedFilters.map(filter => ({
          name: filter.name.toString(),
          displayName: filter.displayName,
          displayValue: filter.displayValue,
          readOnly: filter.readOnly,
        }))}
      />

      {/** Error, if one occurred */}
      {error && (
        <FlexContainer>
          <DSAlert header={'Error'} type={Status.Error} dataTestId={`${dataTestId}-error-alert`}>
            {'An error occurred while searching. Please refresh the page & try again.'}
          </DSAlert>
        </FlexContainer>
      )}

      {isLoading ? (
        loadingDisplay || <DSLoadingPanel data-testid={`${dataTestId}-loading-results`} />
      ) : (
        <DSSearchResults
          dataTestId={`${dataTestId}-results`}
          emptyMessage={emptyMessage}
          error={error}
          pagination={pagination}
          recordKey={recordKey}
          renderResult={renderResult}
          renderResultSet={renderResultSet}
          results={results || []}
          sort={{
            ...sort,
            defaultSort,
          }}
        />
      )}
    </>
  );
}
