import { defaultMaximumContractCount } from '../../contract-management.helpers';

export const addContract = 'Add a Contract to DemandStar';
export const addContractExplanatoryText = `DemandStar invites you to manage up to ${defaultMaximumContractCount} contracts on our platform, and we've made it easy to bring things into the system. Choose below whether you'd like to add a new contract from DemandStar's award process, or an existing contract from outside the platform.`;
export const addExternalContract = 'Add a Contract from Outside DemandStar';
export const addAwardedContract = 'Add a recently awarded contract';
export const awardBid = 'Award a Bid';
export const emptyMessage = 'No matching results found';
export const filters = {
  search: 'Search by Name',
} as const;
export const recordLabels = {
  brodcast: 'Broadcast',
  due: 'Due',
  id: 'ID',
} as const;
export const sortLabels = {
  broadcast: 'Broadcast Date',
  due: 'Due Date',
  id: 'ID',
  name: 'Name',
} as const;
