import React from 'react';

/** @deprecated - use DSButtons instead */
interface ButtonProps {
  text: string | JSX.Element | (string | JSX.Element)[];
  classNames?: string;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disable?: boolean | string | undefined | Record<string, unknown>;
  type?: 'submit' | 'reset' | 'button';
  icon?: string;
  title?: string;
  id?: string;
  testid?: string;
}

/** @deprecated - use DSButtons instead */
function Buttons(props: ButtonProps) {
  // and I'd like for this to be just Button - DC
  return (
    <>
      <button
        className={props.classNames}
        name={props.name}
        onClick={props.onClick}
        disabled={props.disable ? true : false}
        title={props.title}
        type={props.type || 'button'}
        id={props.id}
        data-testid={props.testid}
      >
        {props.icon && <i className={'mr-2 mdi ' + props.icon} />}
        {props.text}
      </button>
    </>
  );
}

export default Buttons;
