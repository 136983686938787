import { atom } from 'recoil';

import { AwardeeDraft } from '../bid-awardees';

/**
 * Set up an ID to be set as a selector dependency
 * to kick off additional selector service calls
 * when changed by user interaction.
 */
export const selectedBidAwardeeState = atom<AwardeeDraft | null>({
  key: 'selectedBidAwardeeState',
  default: null,
});
