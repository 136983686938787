import Select, { components } from 'react-select';
import { memo } from 'react';
import { SelectComponentsProps } from 'react-select/src/Select';

import { Colors } from '../../shared/styles';
import Dropdown from './dropdown';

interface SelectBoxProps extends SelectComponentsProps {
  options?: any[]; // DropdownItem or string array? it's any for now.
  onChange?: any;
  parentClass?: string;
  label?: string;
  input?: any;
  children?: JSX.Element | never[];
  reduxform?: any;
  defaultValue?: string;
  meta?: any;
  disabled?: string;
  search?: any;
  clear?: boolean;
  reactselect?: boolean;
  mutli?: boolean;
  name?: string;
  value?: any;
  handleSelect?: (name: string, value: any) => void;
  placeholder?: string;
  optional?: boolean;
  pageFor?: string;
  columnFocusUpdate?: any;
  dataTestId?: string;
  fieldRef?: any; // optional pass-through ref for `react-hook-form`
}

/** @deprecated use DSSelect or DSSelectField */
function SelectBox(props: SelectBoxProps) {
  const onOptionChange = (id: any, key: any, item: any) => {
    const { options, onChange } = props;
    if (options) {
      onChange(key, item.selected ? '' : options[id].value);
    }
  };
  const {
    children,
    clear = false,
    columnFocusUpdate,
    dataTestId,
    defaultValue = '',
    disabled,
    fieldRef,
    handleSelect = () => {},
    input,
    label,
    meta = {},
    mutli = false,
    name = '',
    optional = false,
    options = [] /*onChange, test */,
    pageFor = '',
    parentClass,
    placeholder = '',
    reactselect = false,
    reduxform,
    search,
    value = '',
  } = props;
  const { touched, error } = meta;

  const { Option, SingleValue } = components;
  const IconOption = (props: any) => (
    <Option
      {...props}
      className={
        ![true, false].includes(props.data.ac) || props.data.ac === true ? '' : 'text-red200'
      }
    >
      {(props.data && props.data.value) ===
      (props.selectProps && props.selectProps.value && props.selectProps.value.value) ? (
        <span aria-hidden='true' className='mdi mdi-check-bold'></span>
      ) : (
        <span aria-hidden='true' className='ml-4'></span>
      )}
      {' ' + props.data.label}
    </Option>
  );
  const ValueOption = (props: any) => (
    <SingleValue {...props}>
      {/*{props.data.value && <span aria-hidden='true' className='mdi mdi-check-bold'></span>}*/}
      {' ' + props.data.label}
    </SingleValue>
  );
  const style1 = {
    control: (base: any) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
    }),
  };

  const reactSelected = {
    option: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: state.isSelected ? 'rgba(51, 149, 164, 0.2)' : null,
        color: Colors.blueGreenMedium,
        fontStyle:
          ![true, false].includes(state.data.ac) || state.data.ac === true ? 'normal' : 'italic',
      };
    },
    singleValue: (provided: any, state: any) => {
      return {
        ...provided,
        color: Colors.blueGreenMedium,
        fontStyle:
          ![true, false].includes(state.data.ac) || state.data.ac === true ? 'normal' : 'italic',
      };
    },
  };
  const arrow = {
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
  };

  const handleFocus = (value: any) => {
    if (pageFor === 'mapping') {
      columnFocusUpdate();
    }
  };

  return (
    <>
      {reduxform ? (
        <>
          <div
            className={
              'react-select clearfix ' +
              parentClass +
              (touched && error ? ' errorWrapper' : '') +
              (disabled === 'true' ? ' disable' : '') +
              (optional ? ' optional' : '')
            }
            data-testid={dataTestId || 'type.reduxform'}
          >
            <div>
              <label htmlFor={name} aria-labelledby={name}>
                {label}
              </label>
              <Select
                {...input}
                {...props}
                className='custom-react-select'
                components={{ Option: IconOption, SingleValue: ValueOption }}
                data-testid={dataTestId}
                defaultValue={defaultValue}
                isClearable={clear}
                isDisabled={disabled === 'true' ? true : false}
                isSearchable={search}
                onBlur={() => input.onBlur(input.value)}
                onChange={(value: any) => input.onChange(value)}
                options={options}
                placeholder={placeholder ? placeholder : 'Select...'}
                ref={fieldRef}
                styles={{ ...style1, ...arrow, ...reactSelected }}
              />
            </div>
            {optional === true ? (
              <span>(optional)</span>
            ) : optional ? (
              <span>({optional})</span>
            ) : (
              ''
            )}
            <div className='errorMsg'>
              {touched && error ? <>{touched && error && <span>{error}</span>}</> : null}
            </div>
          </div>
        </>
      ) : (
        <>
          {reactselect ? (
            <>
              <div
                data-testid={dataTestId || 'type.reactselect'}
                className={
                  'react-select clearfix ' + parentClass + (touched && error ? ' errorWrapper' : '')
                }
              >
                <div>
                  <label htmlFor={label} aria-labelledby={label}>
                    {label}
                  </label>
                  <Select
                    className='custom-react-select'
                    components={{ Option: IconOption, SingleValue: ValueOption }}
                    data-testid={dataTestId}
                    defaultValue={defaultValue}
                    input={{ ...input, name: { label } }}
                    inputId={label}
                    isClearable={clear}
                    isDisabled={disabled === 'true' ? true : false}
                    isMulti={mutli}
                    isSearchable={search}
                    name={label}
                    onChange={(value: any) => handleSelect(name, value)}
                    onFocus={(value: any) => handleFocus(value)}
                    options={options}
                    ref={fieldRef}
                    styles={{ ...style1, ...arrow, ...reactSelected }}
                    value={value}
                    {...props}
                  />
                </div>
                {optional === true ? (
                  <span>(optional)</span>
                ) : optional ? (
                  <span>({optional})</span>
                ) : (
                  ''
                )}
                <div className='errorMsg'>
                  {touched && error ? <>{touched && error && <span>{error}</span>}</> : null}
                </div>
              </div>
            </>
          ) : (
            <div
              data-testid={dataTestId || 'type.dropdown'}
              className={
                'selectWrapper clearfix ' + parentClass + (touched && error ? ' errorWrapper' : '')
              }
            >
              <div>
                <label>{label}</label>
                <Dropdown
                  input={input}
                  list={options}
                  reduxform={reduxform}
                  ref={fieldRef}
                  resetThenSet={onOptionChange}
                  title='Select'
                >
                  {children}
                </Dropdown>
              </div>
              {optional === true ? (
                <span>(optional)</span>
              ) : optional ? (
                <span>({optional})</span>
              ) : (
                ''
              )}
              <div className='errorMsg'>
                {touched && error ? (
                  <>{touched && error && <span data-testid='error.message'>{error}</span>}</>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

/** @deprecated use DSSelect instead*/
export default memo(SelectBox);
